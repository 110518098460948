import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ServiceAction, AuthAction } from '../../actions';
import { InfinteScroll } from '../../components/common/InfinteScroll';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Loading } from '../common/Loading';
import ServiceCard from './ServiceCard';
import {
	LeadingActions,
	SwipeableList,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
	Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

const ServiceList = ({ user, GetServiceAll, items, totalCount, isLoading, favServices, GetFavServiceAll, UpdateUserFav, showFav = false }) => {

	const onlyOpenIssue = user.rguardSettingHideClosedIssue || false;
	const [target, setTarget] = useState(null);
	const [page, setPage] = useState(1);
	const [itemperpage, setItemPage] = useState(30);
	const [viewFav, setViewFav] = useState(showFav);
	const [list, setList] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (viewFav != showFav) {
			setViewFav(showFav);
			setPage(1);
			if (page == 1)
				loadItems(page);
		}
	}, [showFav]);

	useEffect(() => {
		loadItems(page);
	}, [page]);

	useEffect(() => {
		setList([...items]);
	}, [items]);


	useEffect(() => {
		if (viewFav) {
			dispatch({
				type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
				title: "Favorite Services",
				count: list.length.toLocaleString(),
			});
		}
	}, [list]);

	const loadItems = (pg) => {
		if (showFav) {
			if (onlyOpenIssue) {
				GetFavServiceAll({ itemperpage: 100, page: 1, match: { 'status': { '$ne': 'Close' } } });
			} else {
				GetFavServiceAll({ itemperpage: 100, page: 1 });
			}
		} else {
			if (onlyOpenIssue) {
				GetServiceAll({ itemperpage: itemperpage, page: pg, match: { 'status': { '$ne': 'Close' } } }, true);
			} else {
				GetServiceAll({ itemperpage: itemperpage, page: pg }, true);
			}
		}
	}

	const isEnd = () => { return totalCount <= page * itemperpage; }

	InfinteScroll({
		target,
		onIntersect: ([{ isIntersecting }]) => {
			if (isIntersecting && !isLoading && !isEnd()) {
				setPage(page + 1);
			}
		}
	});

	const removeArr = (id) => {
		const idx = favServices.indexOf(id);
		if (idx > -1) {
			favServices.splice(idx, 1);
			if (showFav)
				setList([...list.filter(x => x.ticketKey != id)])
		}
		return favServices;
	}

	const fav = (isFav, ticketKey) => {
		UpdateUserFav("service", isFav ?
			{ favoriteServices: removeArr(ticketKey) } :
			{ favoriteServices: [...favServices, ticketKey] });
	}

	const updateFav = (ticketKey) => {
		fav(favServices.filter(x => x == ticketKey).length > 0 ? true : false, ticketKey)
	}

	const showMap = (item) => {

	}

	const updateStatus = (item) => {
		dispatch(ServiceAction.UpdateStatus(item._id, { status: item.status == "Open" ? "Close" : "Open" }))
	}

	const leadingActions = (isFav, ticketKey, item) => (
		<LeadingActions>
			<SwipeAction onClick={() => updateFav(ticketKey)}>
				<div className={isFav ? "bg-danger text-center" : "bg-dark text-center"} style={{ width: "80px" }}>
					<ion-icon size="large" name={isFav ? "heart" : "heart-outline"}></ion-icon>
				</div>
			</SwipeAction>
		</LeadingActions>
	);

	const trailingActions = ({ item }) => (
		<TrailingActions>
			<SwipeAction onClick={() => showMap(item)}>
				<div className="bg-success text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="map-outline"></ion-icon>
				</div>
			</SwipeAction>
		</TrailingActions>
	);

	return (
		<>
			<SwipeableList type={ListType.IOS} fullSwipe={false} className="listview image-listview">
				{list.map((item, index) =>
					<SwipeableListItem key={index}
						className={item.status == "Close" ? "status-close" : ""}
						fullSwipe={false}
						leadingActions={leadingActions(favServices.filter(x => x == item.ticketKey).length > 0, item.ticketKey, item)}>
						<ServiceCard item={item} />
					</SwipeableListItem>
				)}
			</SwipeableList>
			{isLoading && <Loading />}
			<div ref={setTarget} className='last-item' />
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var items = state.ServiceReducer.services;
	var totalCount = state.ServiceReducer.totalservicecount;
	var isLoading = state.ServiceReducer.isLoading;

	var favServices = state.FavoriteReducer.service;
	return { user, items, totalCount, isLoading, favServices };
};

const mapDispatch = (dispatch) => ({
	GetServiceAll: (filter, append) => dispatch(ServiceAction.GetServiceAll(filter, append)),
	GetFavServiceAll: (filter) => dispatch(ServiceAction.GetFavServiceAll(filter)),
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
});

export default connect(mapState, mapDispatch)(ServiceList);
