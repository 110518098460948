import { DashboardConstant } from '../actions/constants/DashboardConstant';
const initialState = 
{ 
    dashboardDS : {}, 
    productDS : {}, 
    serviceDS : {}, 
};
export const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DashboardConstant.UPDATE:
            switch(action.type){
                case "dashboard" : return {...state, dashboardDS: action.data };
                case "product" : return {...state, productDS: action.data };
                case "service" : return {...state, serviceDS: action.data };
            }
        default:
            return state
    }
}