import { NotificationConstant } from '../actions/constants/NotificationConstant';
const initialState = 
{ 
    list : [],
    isLoading : false,
};
export const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NotificationConstant.GET_ALL:
            return {...state, list : action.list };
        case NotificationConstant.UNREAD:
        case NotificationConstant.READ:
            return {...state, 
				list : state.list.map((u) => (u._id == action.item._id ? action.item : u)) 
			};
        case NotificationConstant.READALL:
            for (var i = 0; i < state.list.length; i++) {
                state.list[i].isRead = true;
            }
            return {...state, 
				list : state.list 
			};
        case NotificationConstant.DELETEALL:
            return {...state, list : [] };
        case NotificationConstant.DELETE:
            return {...state,
				list : state.list.filter((u) => u._id != action._id) 
			};
        case NotificationConstant.DELETEREAD:
            return {...state,
				list : state.list.filter((u) => !u.isRead) 
			};
        case NotificationConstant.LOADING:
            return {...state, isLoading : true}
        case NotificationConstant.LOADED:
            return {...state, isLoading : false}
        default:
            return state
    }
}