import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { RayTable, RayTreeview, AdminUserListHeader } from '../../components/common';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { AdminUserAction } from '../../actions';
import CustomHeader from '../../components/common/CustomHeader';
import { CustomFieldAction } from '../../actions';
import { AdminFunctions } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';

const AdminUserList = ({ customs, GetUserAll, users, filter, user, viewTree, companies }) => {

	const [wHg, setwHg] = useState(300);
	const [wAll, setwAll] = useState(window.innerWidth);
	const [selList, setSelList] = useState([]);
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });

	const [exheader, setExHeader] = useState([]);
	const [header, setHeader] = useState([]);
	const [ready, setReady] = useState(false);
	const [showCHModal, setShowCHModal] = useState(false);
	const [dfilters, setdFilters] = useState(false);

	const dispatch = useDispatch();

	const options = {
		search: true,
		keyCol: 'adminid',
		linkto: '/Admin/AdminUserInfo',
		linkkey: 'adminid',
		filterpageoption: {
			filterDateOptions: [
				{ code: 'createDate', label: 'Registerd' },
			],
		},
		filterColumns: [
			{ col: 'adminType', val: 'ALL' },
			{ col: 'function', val: 'ALL' },
		],
		sort: { createDate: -1 },
		defaultSort: 'createDate',
	};

	const getWidth = () => setwAll(window.innerWidth);

	const checked = (list) => {
		setSelList(list);
	};

	useEffect(() => {
		setReady(header.length > 0);
	}, [header]);

	useEffect(() => {
		if (viewTree) {
			setwHg(300);
		} else {
			setwHg(0);
		}
		//setFilterCompanyId({});
	}, [viewTree]);

	useEffect(() => {
		// window.addEventListener('resize', getWidth);
		// setwAll(window.innerWidth);
		//GetCustomAll();
		GetUserAll();
		dispatch({ type: ProgramConstant.VIEWTREE, val: true });
	}, []);

	useEffect(() => {
		const newfilters = [];
		customs.filter(m => m.group.includes("USER")).map(x => {
			if (x.valuetype == "FIXLIST") {
				var mList = [];
				x.listvals.map(l => mList.push({ code: l, title: l }));
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title, fixlist: mList });
			} else {
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title });
			}
		});
		setdFilters(newfilters);
	}, [customs]);

	useEffect(() => {
		setReady(header.length > 0);
	}, [header]);

	useEffect(() => {
		let tempHeader = AdminUserListHeader;

		if (user.adminType == 'S')
			tempHeader = [...tempHeader, { name: 'lastLoginDate', label: 'Last Login', type: 'DT' }];

		setHeader(exheader.length > 0 ? tempHeader.concat(exheader) : tempHeader);
	}, [exheader]);

	useEffect(() => {
		if (dfilters.length > 0) {
			setExHeader(user.adminheaders?.users ?
				user.adminheaders.users.filter(x => dfilters.map(m => m.code).includes(x.name)) : []);
		} else {
			setExHeader([]);
		}
	}, [user.adminheaders?.users, dfilters]);

	const genUserData = () => {
		const data = users.map((u) => {
			u.setname = u.firstName + ' ' + u.lastName;
			u.companyName = u.companyInfo && u.companyInfo.name;
			u.country = u.companyInfo && u.companyInfo.country;
			u.function = AdminFunctions.filter(x => x.code == u.function)[0] ? AdminFunctions.filter(x => x.code == u.function)[0].title : u.function;
			return u;
		});

		return data;
	};

	const onChangeTreeviewItem = (item) => {
		setFilterCompanyId({ companyId: item.id });
		setFilterCompany(item);
	}

	const updateViewTree = (bl) => {
		dispatch({ type: ProgramConstant.VIEWTREE, val: bl });
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('user')}
					{companies.length > 1 && <button className="btn btn-secondary btn-sm mt-0 ms-1" onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>}
				</h2>
				<div className="ray-right">
					<button onClick={() => setShowCHModal(true)} className="btn btn-sm mt-0">
						<ion-icon name="options-outline"></ion-icon> {t('customheaders')}
					</button>
					<Link to="/Admin/AdminUserAdd" className="btn btn-primary btn-sm mt-0">
						<ion-icon name="add-outline"></ion-icon>
						{t('adduser')}
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{(viewTree && user.adminType != "U") &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>}
				{ready && <div className="section m-1 p-0">
					<div className="card p-2">
						<div className={user.adminType != "U" ? "fl" : ""}
							style={viewTree && user.adminType != "U" ? { width: wAll - (viewTree ? 354 : 68) } : {}}>
							<RayTable
								data={genUserData()}
								addFilter={filterCompanyId}
								columns={header}
								checkBoxCallback={checked}
								options={options} />
						</div>
						<div className="clearfix"></div>
					</div>
				</div>}
			</div>
			<CustomHeader
				extratrakey={"USEREXTRAKEY"}
				title={"User List Header"}
				extratrakeyname={"extradata"}
				exHeader={exheader}
				adminheaderprefix={"users"}
				showModal={showCHModal}
				callbackClose={() => setShowCHModal(false)}
				defaultFilter={dfilters || []} />
		</>
	);
};

const mapState = (state) => {
	var users = state.AdminUserReducer.users;
	var user = state.AuthReducer.user;
	var filter = state.AdminUserReducer.filter;
	var viewTree = state.ProgramReducer.viewtree;
	var companies = state.CompanyReducer.companies;
	const customs = state.CustomFieldReducer.items;
	return { customs, users, filter, user, viewTree, companies };
};

const mapDispatch = (dispatch) => ({
	GetUserAll: () => dispatch(AdminUserAction.GetUserAll()),
	//GetCustomAll: () => dispatch(CustomFieldAction.GetAll()),
});

export default connect(mapState, mapDispatch)(AdminUserList);
