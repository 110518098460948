import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SiteAction } from '../../actions/SiteAction';
import { RayForm, RayFormValidation } from '../../components/common';
import { SpecialityTypes } from '../../config/RayCodes';
import { CountryCode } from '../../config';
import { useTranslation } from 'react-i18next';

const SiteCustomerInfo = ({ siteId, siteReducer, GetSite, UpdateSiteInfo, editable, siteInfo }) => {
	const [t] = useTranslation();
	const [errors, setErrors] = useState([]);
	const [site, setSite] = useState(siteInfo);

	useEffect(() => {
		if (siteReducer._id)
			setSite(siteReducer);
	}, [siteReducer]);

	useEffect(() => {
		if (siteId)
			GetSite(siteId);
	}, []);

	const form_controls = [
		{
			title: "", items: [
				{
					type: "input",
					name: "customerFirstName",
					label: t('firstname'),
					val: site.customerFirstName,
					isEditable: editable, size: 12
				},
			]
		},
		{
			title: "", items: [
				{
					type: "input",
					name: "customerLastName",
					label: t('lastname'),
					val: site.customerLastName,
					isEditable: editable, size: 12
				},
			]
		},
		{
			title: "", items: [
				{
					type: "input",
					name: "customerHp",
					label: t('mobile'),
					val: site.customerHp,
					isEditable: editable, size: 12
				},
			]
		},
		{
			title: "", items: [
				{
					type: "email",
					name: "customerEmail",
					label: t('email'),
					val: site.customerEmail,
					isEditable: editable, size: 12
				},
			]
		},
	]

	return (
		<RayForm
			controls={form_controls}
			action={UpdateSiteInfo}
			actionkey={site && site._id}
			errors={errors} />
	);
};

const mapState = (state) => {
	const siteReducer = state.SiteReducer.site;
	return { siteReducer };
}

const mapDispatch = dispatch => ({
	UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data)),
	GetSite: (_id) => dispatch(SiteAction.GetSite(_id))
})

export default connect(mapState, mapDispatch)(SiteCustomerInfo);