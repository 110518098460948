import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppConfig, JWT } from '../../config';
import axios from 'axios';
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import SiteSurveyPdf from '../../config/SiteSurveyPdf.txt';
import InstallationPdf from '../../config/InstallationPdf.txt';
import CCTPdf from '../../config/CCR.txt';

import { CountryCode } from '../../config/CountryCode';
import { siteSurveyColumn, installationColumn } from '../../config/ReportColumnPdf';
import { Translate as t } from '../../config/Translate';
import produthistoryreport from '../../config/producthistoryreport.txt';

export const ExportPdfReport = ({ reportType, infos, css, auto = true }) => {
  // survey 정보 가져와서 Document 생성
  // button click 으로 PDFDownloadLink 생성
  // const [ t, i18n ] = useTranslation();

  const installGeneratePDF = async () => {
    console.log(infos);
    var installationDrawing = await getReportAttachs(infos);
    var installHtmlString = '';
    fetch(InstallationPdf).then(response => response.text())
      .then(text => {
        installHtmlString = text;
        installationColumn.map((x) => {
          installHtmlString = installHtmlString.replaceAll('{' + x.title + '}', t(x.title));
          if (x.column in infos) {
            if (x.column == "customerFirstName") {
              installHtmlString = installHtmlString.replace('{ir.' + x.column + '}', infos.customerFirstName + ' ' + infos.customerLastName);
            } else {
              installHtmlString = installHtmlString.replace('{ir.' + x.column + '}', convertCodeData(x.column, infos[x.column]));
            }
          }

          installHtmlString = installHtmlString.replace("{ir.installerCountry}", "Japan");
          installHtmlString = installHtmlString.replace("{company}", "Company");
          installHtmlString = installHtmlString.replace("{ir.installerPhone}", "");
          installHtmlString = installHtmlString.replace("{ir.siteName}", "梅山先生");
          installHtmlString = installHtmlString.replace("{ir.customerFirstName}", "梅山先生");
          installHtmlString = installHtmlString.replace("{ir.type}", "");
          installHtmlString = installHtmlString.replace("{ir.siteSpecialty}", "");
          installHtmlString = installHtmlString.replace("{ir.siteTel}", "");
          installHtmlString = installHtmlString.replace("{ir.customerEmail}", "");
          installHtmlString = installHtmlString.replace("{ir.customerHp}", "");
          installHtmlString = installHtmlString.replace("{ir.siteAddress}", "");
          installHtmlString = installHtmlString.replace("{ir.siteZipCode}", "");
          installHtmlString = installHtmlString.replace("{ir.siteCountry}", "Japan");
          installHtmlString = installHtmlString.replace("{ir.option}", "160V-SCS(CT/PX/DX)");
          installHtmlString = installHtmlString.replace("{ir.networkStaticIp}", "");
          installHtmlString = installHtmlString.replace("{ir.rayIoVersion}", "");
          installHtmlString = installHtmlString.replace("{ir.rayScanVersion}", "");
          installHtmlString = installHtmlString.replace("{ir.smartdentVersion}", "");
          installHtmlString = installHtmlString.replace("{ir.rayServerVersion}", "");
          installHtmlString = installHtmlString.replace("{ir.ondemandVersion}", "");
          installHtmlString = installHtmlString.replace("{rayserverversion}", "RAYServer Version");
          installHtmlString = installHtmlString.replace("{ondemand3dversion}", "OnDemand3D Version");
          installHtmlString = installHtmlString.replace("{application}", "Application");
          installHtmlString = installHtmlString.replace("{ir.model}", "RAYSCAN α+ (NEW)");          
          installHtmlString = installHtmlString.replace("Sung-Tae Kim", "RAY Japan");
          // installHtmlString = installHtmlString.replace("st.kim", "Sung-Tae Kim");
          

          // pacsList
          if (x.column == 'pacsList') {
            if (infos.pacsList.length > 0) {
              for (let i in infos.pacsList) {
                //type, title, ip, port
                installHtmlString = installHtmlString.replace('{ir.pacs-' + infos.pacsList[i].type + '-title' + i + '}', infos.pacsList[i].title);
                installHtmlString = installHtmlString.replace('{ir.pacs-' + infos.pacsList[i].type + '-ip' + i + '}', infos.pacsList[i].ip);
                installHtmlString = installHtmlString.replace('{ir.pacs-' + infos.pacsList[i].type + '-port' + i + '}', infos.pacsList[i].port);
                i++;
              }
            }
            for (let i = 0; i < 3; i++) {
              installHtmlString = installHtmlString.replace('{ir.pacs-mwl-title' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-mwl-ip' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-mwl-port' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-dicom-title' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-dicom-ip' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-dicom-port' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-scp-title' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-scp-ip' + i + '}', '');
              installHtmlString = installHtmlString.replace('{ir.pacs-scp-port' + i + '}', '');
            }

          }

          // packing
          if (x.column == "packing") {
            if (installationDrawing.length > 0) {
              var shockImage = [];
              var tiltImage = [];
              installationDrawing.map((s) => {
                if (s.type == "shock") {
                  shockImage.push(s.path)
                  installHtmlString = installHtmlString.replace('{ir.shock}', shockImage);
                } else if (s.type == "tilt") {
                  tiltImage.push(s.path)
                  installHtmlString = installHtmlString.replace('{ir.tilt}', tiltImage);
                }
              })
            }
            installHtmlString = installHtmlString.replace('{ir.shock}', '');
            installHtmlString = installHtmlString.replace('{ir.tilt}', '');
          }

          // image quality
          if (x.column == "imagequality") {
            if (installationDrawing.length > 0) {
              var panoImage = [];
              var cbctImage = [];
              var cephImage = [];
              installationDrawing.map((t) => {
                if (t.type == "pano") {
                  panoImage.push(t.path)
                  installHtmlString = installHtmlString.replace('{ir.iq_pano}', panoImage);
                } else if (t.type == "ct") {
                  cbctImage.push(t.path)
                  installHtmlString = installHtmlString.replace('{ir.iq_cbct}', cbctImage);
                } else if (t.type == "ceph") {
                  cephImage.push(t.path)
                  installHtmlString = installHtmlString.replace('{ir.iq_ceph}', cephImage);
                }
              })
            }
            installHtmlString = installHtmlString.replace('{ir.iq_pano}', '');
            installHtmlString = installHtmlString.replace('{ir.iq_cbct}', '');
            installHtmlString = installHtmlString.replace('{ir.iq_ceph}', '');
          }

          // signature
          if (x.column == "signatures") {
            if (infos.signatures.length > 0) {
              infos.signatures.map((s) => {
                if (s.type == "customer") {
                  installHtmlString = installHtmlString.replace('{ir.sig_customer}', "<img style='width:324px;height:123px;' class='img-fluid' alt='' crossorigin='anonymous' src='" + s.path + "'/>")
                  installHtmlString = installHtmlString.replace('{ir.sig_customer_updateDate}', convertCodeData('createDate', s.createDate));
                } else {
                  installHtmlString = installHtmlString.replace('{ir.sig_installer}', "<img style='width:324px;height:123px;' class='img-fluid' alt='' crossorigin='anonymous' src='" + s.path + "'/>")
                  installHtmlString = installHtmlString.replace('{ir.sig_installer_updateDate}', convertCodeData('createDate', s.createDate));
                }
              });
            }
            installHtmlString = installHtmlString.replace('{ir.sig_customer}', '');
            installHtmlString = installHtmlString.replace('{ir.sig_installer}', '');
            installHtmlString = installHtmlString.replace('{ir.sig_customer_updateDate}', '');
            installHtmlString = installHtmlString.replace('{ir.sig_installer_updateDate}', '');
          }

          // Regulatory Document
          if (x.column == "regulatorydocument") {
            if (installationDrawing.length > 0) {
              var docImage = [];
              installationDrawing.map((r) =>
                r.type == "doc" &&
                docImage.push(r.path)
              )
              installHtmlString = installHtmlString.replace('{ir.reg_doc}', docImage);
            }
            installHtmlString = installHtmlString.replace('{ir.reg_doc}', '');
          }

        });

        var filename = "IR-" + getCountryCode(infos.siteCountry) + "-" + infos.sn + "-" + infos.siteName + "-" + moment.utc(infos.createDate == "0" ? 0 : infos.createDate).local().format("YYYYMMDD");
        exportPDF(installHtmlString, filename);
      });
  }

  const siteSurveyGeneratePDF = async () => {
    var siteSurveyHtmlString = '';
    var surveyDrawing = await getReportAttachs(infos);
    // { title: '레포트 컬럼 항목(config/lang 의 key )', column: infos object 의 key }
    fetch(SiteSurveyPdf).then(response => response.text())
      .then(text => {
        siteSurveyHtmlString = text;
        siteSurveyColumn.map((x) => {
          siteSurveyHtmlString = siteSurveyHtmlString.replaceAll('{' + x.title + '}', t(x.title));
          if (x.column in infos) {
            if (x.column == "customerFirstName") {
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.' + x.column + '}', infos.customerFirstName + ' ' + infos.customerLastName);
            } else if (x.column == "additionalRioSensorSize1") {
              var sensorsize1 = (infos.additionalRioSensorSize1 == "" || infos.additionalRioSensorSize1 == null || infos.additionalRioSensorSize1 == "0") ? "" : "(Size1 / " + infos.additionalRioSensorSize1 + " EA)";
              var sensorsize2 = (infos.additionalRioSensorSize2 == "" || infos.additionalRioSensorSize2 == null || infos.additionalRioSensorSize2 == "0") ? "" : "(Size2 / " + infos.additionalRioSensorSize2 + " EA)";
              var sensortotal = (sensorsize1 == "" ? "" : sensorsize1 + ", ") + (sensorsize2 == "" ? "" : sensorsize2);
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.riosensorQty}', sensorsize1 + sensorsize2);
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.riosensorSizeTotal}', sensortotal);
            } else if (x.column == "additionalRioScanStandard") {
              var scanstandard = (infos.additionalRioScanStandard == "" || infos.additionalRioScanStandard == null || infos.additionalRioScanStandard == "0") ? "" : "(Standard / " + infos.additionalRioScanStandard + " EA)";
              var scanocclusal = (infos.additionalRioScanOcctusal == "" || infos.additionalRioScanOcctusal == null || infos.additionalRioScanOcctusal == "0") ? "" : "(Occlusal / " + infos.additionalRioScanOcctusal + " EA)";
              var scantotal = (scanstandard == "" ? "" : scanstandard + ", ") + (scanocclusal == "" ? "" : scanocclusal);
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.rioscanQty}', scanstandard + scanocclusal);
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.rioscanTotal}', scantotal);
            }
            else {
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.' + x.column + '}', convertCodeData(x.column, infos[x.column]));
            }

            if (x.column == 'previousUnitsExtraOralList') {
              if (infos.previousUnitsExtraOralList.length > 0) {
                for (let i in infos.previousUnitsExtraOralList) {
                  siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsExtraOralBrand' + i + '}', infos.previousUnitsExtraOralList[i].brand);
                  siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsExtraOralProduct' + i + '}', infos.previousUnitsExtraOralList[i].product);
                  i++;
                }
              }
              for (let i = 0; i < 3; i++) {
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsExtraOralBrand' + i + '}', '');
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsExtraOralProduct' + i + '}', '');
              }
            }

            if (x.column == 'previousUnitsIntraOralList') {
              for (let i in infos.previousUnitsIntraOralList) {
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsIntraOralBrand' + i + '}', infos.previousUnitsIntraOralList[i].brand);
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsIntraOralProduct' + i + '}', infos.previousUnitsIntraOralList[i].product);
                i++;
              }
              for (let i = 0; i < 3; i++) {
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsIntraOralBrand' + i + '}', '');
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.pUnitsIntraOralProduct' + i + '}', '');
              }
            }

            if (x.column == 'monitorList') {
              var monitorInch = '';
              var monitorQuantity = '';
              for (let m in infos.monitorList) {
                monitorInch += infos.monitorList[m].inch + ' ';
                monitorQuantity += infos.monitorList[m].quantity + ' ';
              }
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.monitorOtherInches}', monitorInch);
              siteSurveyHtmlString = siteSurveyHtmlString.replace('{ss.monitorOtherQuantity}', monitorQuantity);
            }

            if (x.column == "attachments") {
              if (infos.attachments.length > 0) {
                siteSurveyHtmlString = siteSurveyHtmlString.replace('{sss.attachments}', surveyDrawing.join("<br />"));
              }
            }
          }
        });

        var filename = "PR-" + getCountryCode(infos.siteCountry) + "-" + infos.siteName + "-" + moment.utc(infos.createDate == "0" ? 0 : infos.createDate).local().format("YYYYMMDD");
        exportPDF(siteSurveyHtmlString, filename);
      });
  }

  const convertCodeData = (columnName, inputData) => {

    console.log(columnName + "/" + inputData);

    if (columnName == "siteCountry")
      return getCountryName(inputData);
    else if (columnName == "scheduleDate" || columnName == "trainingDate" || columnName == "deliveryDate" || columnName == "installationDate" || columnName == "createDate")
      return moment.utc(inputData == "0" ? 0 : inputData).local().format("YYYY-MM-DD");
    else if (columnName == "") {
      return "";
    }
    else {
      return inputData;
    }
  }

  const producthistoryGeneratePDF = async () => {
    var productHistoryHtmlString = '';
    var str = "";
    var companyCountry = "";
    var siteName = "";
    var filename = "";
    var data = {
      ...infos
    }

    var serviceReplacements = [];
    console.log(infos.serviceReplacements);
    infos.serviceReplacements.map(x => {
      serviceReplacements.push("<tr>" +
        "<td class='text-center'>" + moment.utc(x.registDate > 0 ? x.registDate : 0).local().format('YYYY-MM-DD') + "</td>" +
        "<td class='text-center'>" + x.warrantyYN + "</td>" +
        "<td class='text-center'>" + x.partscost + "</td>" +
        "<td class='text-center'>" + x.laborcost + "</td>" +
        "<td class='text-center'>" + x.vat + "</td>" +
        "<td class='text-center'>" + x.estimate + "</td>" +
        "<td class='text-center'>" + x.parts + "</td>" +
        "<td class='text-center'>" + x.etc + "</td>" +
        "<td class='text-center'>" + x.proofnumber + "</td>" +
        "</tr>");
    })

    var snhistorieList = [];
    infos.snhistorielist.map(x => {
      snhistorieList.push("<tr class 'text-center'>" +
        "<td>" + moment.utc(x.updated > 0 ? x.updated : 0).local().format('YYYY-MM-DD') + "</td>" +
        "<td>" + x.sn + "</td>" +
        "<td>" + x.user + "</td>" +
        "</tr>");
    })

    data = { ...data, snhistorylist: snhistorieList.join(""), partReplacements: serviceReplacements.join("") }

    siteName = infos.sitename ? infos.sitename : "";
    filename = "PR-" + infos.sn + "-" + siteName + "-" + moment().format('YYYYMMDD');
    fetch(produthistoryreport).then(response => response.text())
      .then(text => {
        str = text;
        var reV = /\{pr\.[a-z]+\}/gi;
        var foundV = str.match(reV);
        foundV.map(x => {
          var key = x.replace("{pr.", "").replace("}", "");
          if (data.hasOwnProperty(key)) {
            str = str.replaceAll(x, data[key] == null ? "" : data[key]);
          } else {
            str = str.replaceAll(x, "");
          }
        })

        var reC = /\{prCheck\.[a-z]+\}/gi;
        var foundC = str.match(reC);
        foundC.map(x => {
          var checkKey = x.replace("{prCheck.", "").replace("}", "");
          if (data.hasOwnProperty(checkKey)) {
            str = str.replaceAll(x, data[checkKey] == true ? "checked" : "");
          }
        })

        exportPDF_productHistory(str, filename);
      });
  }

  // 넘어온 리스트 데이터 확인 후 생성
  const generatePDF = () => {
    if (reportType == 'sitesurvey') {
      siteSurveyGeneratePDF();
    } else if (reportType == 'ccr') {
      ccrGeneratePDF();
    } else if (reportType == 'ProductHistory') {
      producthistoryGeneratePDF();
    }
    else {
      installGeneratePDF();
    }
  }

  const exportPDF = (generatedHtml, filename) => {
    const opt = {
      margin: 0.4,
      filename: filename,
      image: { type: 'jpeg', quality: 0.98 }, // Exported image quality and format
      html2canvas: { scrollY: 0, scale: 3, useCORS: true }, // useCORS is very important to solve the problem of cross-domain images in the document
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', useCORS: true },
    };
    if (generatedHtml) {
      html2pdf().set(opt).from(generatedHtml).save(); // export
    }
  }

  const exportPDF_productHistory = (generatedhtml, filename) => {
    const options = {
      margin: 0.4,
      filename: filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scrollY: 0, scale: 3, useCORS: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape', useCORS: true },
    };
    if (generatedhtml) {
      html2pdf().set(options).from(generatedhtml).save();
    }
  }

  return (
    <>
      <button className={css || "btn btn-primary btn-block btn-lg"} onClick={auto && generatePDF}>{t('reportexport')}
      </button>
    </>
  )
};

const getReportAttachs = async (item) => {
  var ext = [".JPG", ".PNG", ".JPEG"];
  var attList = [];
  for (const x of item.attachments) {
    //if(x.path.toUpperCase().endsWith("JPG") || x.path.toUpperCase().endsWith("JPEG")){
    const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
      path: x.path,
      ctype: "image/" + x.path.slice((x.path.lastIndexOf(".") - 1 >>> 0) + 2)
    }, JWT());
    if (ret.data.status == "success") {
      if (x.type == "shock" || x.type == "tilt") {
        attList.push({ type: x.type, path: "<img src='" + ret.data.data + "'  style='max-width:365px;max-height:270px;' class='img-fluid'  style='width: 100%;margin-bottom:16px;' crossorigin='anonymous' alt='" + x.name + "'/>" });
      } else if (x.type == "pano" || x.type == "ct" || x.type == "ceph") {
        attList.push({ type: x.type, path: "<img src='" + ret.data.data + "'  style='max-width:90%;height:320px;' class='img-fluid'  style='width: 100%;margin-bottom:16px;' crossorigin='anonymous' alt='" + x.name + "'/>" });
      } else if (x.type == "doc") {
        attList.push({ type: x.type, path: "<img src='" + ret.data.data + "'  style='max-width:880px;max-height:970px;' class='img-fluid'  style='width: 100%;margin-bottom:16px;' crossorigin='anonymous' alt='" + x.name + "'/>" });
      }
      else {
        attList.push("<img src='" + ret.data.data + "'  style='height:370px;width:440px;' class='img-fluid'  style='width: 100%;margin-bottom:16px;' crossorigin='anonymous' alt='" + x.name + "'/>");
      }
    }
    //}
  }
  return attList;
}

export const ExportPdfReportToEmail = ({ reportType, infos }) => {
  useEffect(() => {
    const [t, i18n] = useTranslation();
    return true;
  }, [])
};

const getCountryName = (code) => {
  if (CountryCode.filter(item => code == item.code).length > 0) {
    return CountryCode.find(item => code == item.code).title;
  } else {
    return code;
  }
}

const getCountryCode = (title) => {
  if (CountryCode.filter(item => title == item.title).length > 0) {
    return CountryCode.find(item => title == item.title).code;
  } else {
    return title;
  }
}

const getAttachs = async (item) => {
  var ext = [".JPG", ".PNG", ".JPEG"];
  var attList = [];
  for (const x of item.attachments) {
    //if(x.path.toUpperCase().endsWith("JPG") || x.path.toUpperCase().endsWith("JPEG")){
    const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
      path: x.path,
      ctype: "image/" + x.path.slice((x.path.lastIndexOf(".") - 1 >>> 0) + 2)
    }, JWT());
    if (ret.data.status == "success") {
      attList.push("<img src='" + ret.data.data + "' style='width: 100%;margin-bottom:16px;' crossorigin='anonymous' alt='" + x.name + "'/>");
    }
    //}
  }
  return attList;
}

export const ccrGeneratePDF = async (infos, t, filename = "") => {
  var str = '';
  //fetch(CCTPdfTest).then(response => response.text())
  //.then(text => {
  //  exportPDF("test", text);
  //});
  //return;
  //filename = "CSR-AU-RA71840033-Strathfield Medical Imaging -20210907";
  var companyCountry = "";
  var siteName = "";
  var data = {
    ...infos,
    spcountry: infos.company ? getCountryName(infos.company.country) : "",
    assigneeName: infos.assigneeInfo ? infos.assigneeInfo.firstName + " " + infos.assigneeInfo.lastName : "",
    assigneeInfoTel: infos.assigneeInfo ? infos.assigneeInfo.tel : ""
  };

  data = {
    ...data,
    companyname: infos.company ? infos.company.name : "",
    companycontactTel: infos.company ? infos.company.contactTel : "",
    companyaddress: infos.company ? ((infos.company.address || "") + " " + (infos.company.addressExtra || "")) : "",
  };
  companyCountry = infos.company ? infos.company.country : "";

  data = {
    ...data,
    siteName: infos.site ? infos.site.sitename : "",
    siteMobile: infos.site ? infos.site.customerHp : "",
    customerName: infos.site ? ((infos.site.customerFirstName || "") + " " + (infos.site.customerLastName || "")) : "",
    customerMobile: infos.site ? infos.site.customerHp : "",
    siteAddress: infos.site ? (infos.site.address + " " + infos.site.addressExtra) : "",
    siteZipCode: infos.site ? infos.site.zipcode : "",
    siteCountry: infos.site ? (infos.site.country ? getCountryName(infos.site.country) : "") : "",
  };
  siteName = infos.site ? infos.site.sitename : "";

  data = {
    ...data,
    createDate: moment.utc(infos.effectiveDate > 0 ? infos.effectiveDate : infos.createDate).local().format('YYYY-MM-DD'),
    servicecategory: infos.category ? (infos.category + " > " + infos.categoryDetail) : "",
  };

  var attList = await getAttachs(infos);
  filename = "CSR-" + companyCountry + "-" + infos.sn + "-" + siteName + "-" + moment().format('YYYYMMDD');

  var commentList = [];
  infos.comments.map(x => {
    commentList.push("<div style='padding: 8px 0px;'>" +
      "<span style='font-weight: bold;'>" + x.creator + "</span>&nbsp;/&nbsp;" +
      "<span>" + moment.utc(x.createDate).local().format('YYYY-MM-DD HH:mm:ss') + "</span><br/>" +
      "<span>" + x.comment + "</span>" +
      "</div>");
  })
  var partList = [];
  infos.replacements.map(x => {
    partList.push("<div>" +
      "<span>" + x.title + " &lt; " + x.subtitle + "</span><br/>" +
      ((x.oldsn || x.oldsn) ? "<span>" + x.oldsn + " &lt; " + x.newsn + "</span>" : "") +
      "</div>");
  })

  data = {
    ...data,
    attachments: attList.join("<br />"),
    engineersRemarks: infos.engineersRemarks || "",
    pendingReason: infos.pendingReason || "",
    sigpath: infos.sigpath || "",
    servicerendereds: commentList.join(""),
    partreplacements: partList.join(""),
  };
  fetch(CCTPdf).then(response => response.text())
    .then(text => {
      str = text;
      var reT = /\{tt\.[a-z]+\}/gi;
      var foundT = str.match(reT);
      foundT.map(x => {
        str = str.replaceAll(x, t(x.replace("{tt.", "").replace("}", "")))
      })

      var reV = /\{ir\.[a-z]+\}/gi;
      var foundV = str.match(reV);
      foundV.map(x => {
        var key = x.replace("{ir.", "").replace("}", "");
        if (data.hasOwnProperty(key)) {
          str = str.replaceAll(x, data[key]);
        }
      })
      console.log(str);

      exportPDF(filename, str);
    });
}

const exportPDF = (filename, generatedHtml) => {
  const opt = {
    margin: 0.4,
    filename: filename || "Exported PDF",
    image: { type: 'jpeg', quality: 0.98 }, // Exported image quality and format
    html2canvas: { scrollY: 0, scale: 3, useCORS: true }, // useCORS is very important to solve the problem of cross-domain images in the document
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', useCORS: true },
  };
  if (generatedHtml) {
    html2pdf().set(opt).from(generatedHtml).save(); // export
  }
}