import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NoRecord } from '../../pages/HomePage';
import { RayTable, AdminProductQCListHeader, RayChartLine } from '../common';

const ProductInfoQC = ({ product, showtable = true, days = 14, height = 400 }) => {

	const [list, setList] = useState([]);
	//const [chartData, setChartData] = useState([]);
	//const [chartCategory, setChartCategory] = useState([]);
	//const [legends, setLegends] = useState([]);
	//const [chartFilter, setChartFilter] = useState({ 
	//	date : "", modality : ""
	//});

	const options = {
		search: false,
		keyCol: "_id",
		filterColumns: [],
		defaultSort: "timestamp",
		defaultSortType: -1
	}

	const getReorderedList = () => {
		list.sort(function (a, b) {
			return b.timestamp - a.timestamp;
		});
		setList([...list]);
	}

	useEffect(() => {
		getReorderedList();
	}, [list]);

	useEffect(() => {
		setList(product.qcs);
	}, [product]);

	{/*
	useEffect(() => {
		//makeData();
	}, []);

	useEffect(() => {
		//drawChart();
	}, [legends, chartCategory]);
	const makeData = () => {
		var category = [];
		list.map((value) => {
			Object.keys(value).map(x => {
				if (!category.includes(x) && x.toLowerCase() != "activationtime" && x != "inspectorname" && x != "deviceserial" && x != "timestamp" && x != "_id"){
					category.push(x)
				}
			})
		});

		var leg = [];
		category.map(x => {
			leg.push({ l : x, s : true });
		})

		console.log(category);
		setLegends(leg);
		setChartCategory(category);
	}

	const drawChart = () => {
		if(chartCategory.length == 0)
			return;
		var result = [];
		var hideList = [];

		if(legends){
			legends.map(x => { if(!x.s) hideList.push(x.l) });
			console.log("-----------");
			console.log(hideList);
			console.log("-----------");
		}
		chartCategory.map((x) => {
			if(!hideList.includes(x))
				result.push({ id : x, data : []})
		})

		console.log("-----------");
		console.log(hideList);
		console.log(result);
		console.log("-----------");

		list.map((value) => {
			var localDate = moment.utc(value.timestamp).local().format("MM/DD");
			Object.keys(value).map(x => {
				if ( !hideList.includes(x) && x.toLowerCase() != "activationtime" && x != "inspectorname" && x != "deviceserial" && x != "timestamp" && x != "_id"){
					var item = result.filter( n => n.id == x);
					item[0].data.push({ x : localDate, y : value[x]})
				}
			})
		});
		setChartData(result);
	}

	const handleClick = (item) => {
		setChartFilter({ 
			date : item.data.odate, 
			modality : item.id
		});
		console.log(item);
	}

	const chartOptions = {
		height : height,
		handleClick : handleClick
	}
	*/}

	return (
		<div className="section">
			{/*<div className="card mb-1">
				<RayChartLine data={chartData} category={chartCategory} 
					callbackLegends={callbackLegends}
					indexBy="date" options={chartOptions} />
			</div>*/}
			{(showtable && list.length > 0) && <div className="card">
				<div className="card-body">
					<RayTable data={list} columns={AdminProductQCListHeader} options={options} />
				</div>
			</div>}
			{(showtable && list.length == 0) && <NoRecord />}
		</div>
	);
};

export default ProductInfoQC;