import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayCodeAction, ServiceAction, ProductAction } from '../../actions';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const ServiceClaimCategory = ({ service, pInfo, UpdateService, callback, clist }) => {
	// const [t] = useTranslation();
	const [codes, setCodes] = useState([]);
	const [comboParent, setComboParent] = useState([]);
	const [combo, setCombo] = useState([]);
	const [info, setInfo] = useState({});
	const [hide, setHide] = useState(true);

	const [showOther, setShowOther] = useState(false);
	const [otherText, setOtherText] = useState();
	const [otherSaveShow, setotherSaveShow] = useState(false);

	useEffect(() => {
		console.log('CLAIMTYPE', clist?.filter(x => x.type === 'CLAIMTYPE'));
		setCodes(clist?.filter(x => x.type === 'CLAIMTYPE'));
		//RayCodeAction.GetAllByFilter({ type: "CLAIMTYPE" }).then(x => setCodes(x));
	}, [clist])

	useEffect(() => {
		if (codes.length > 0 && pInfo?.model) {
			SetCodeData();
		}
	}, [codes, pInfo])

	const SetCodeData = () => {
		var groups = [];
		codes.filter(hasOwnFilter).map(x => groups.push(x.group));
		const newList = [...new Set(groups)];
		setComboParent(newList);
		if (!service._id) {
			setInfo({ ...info, ["category"]: newList[0] });
		} else {
			setInfo({
				category: service.category,
				categoryDetail: service.categoryDetail
			});
		}
	}

	const hasOwnFilter = (x) => {
		if(codes.find(c => c.category === pInfo.model)){
			return x.category === pInfo.model;
		}else{
			return !x.category;
		}
	}

	useEffect(() => {
		if (!info.category || codes.length == 0)
			return;

		if (info.category != "Other") {
			setShowOther(false);
			var list = [];
			list.push({ code: "", name: "Select a detail category" });
			codes.filter(hasOwnFilter).sort((a, b) => {
				if (a.sortnumber > b.sortnumber)
					return 1;

				if (a.sortnumber < b.sortnumber)
					return -1;

				return 0;
			}).map(x => {
				if (x.group == info.category)
					list.push(x);
			});
			setCombo(list);

			if (!service._id) {
				setInfo({ ...info, ["categoryDetail"]: list[0].code });
			}
		} else {
			setShowOther(true);
		}
	}, [info.category])

	useEffect(() => {
		if (codes.length == 0)
			return;

		if (service._id) {
			if (info.category != "Other") {
				if (info.categoryDetail && info.category && service.categoryDetail != info.categoryDetail) {
					update();
				}
			}
		} else {
			callback && callback(info);
		}
	}, [info.categoryDetail])

	const changeCombo = (e) => {
		var item = {};
		codes.map(x => {
			if (x.code == e.target.value && x.group == info.category)
				item = x;
		});
		setInfo({ ...info, ["categoryDetail"]: item.code });
	}

	const changeComboParent = (e) => {
		setInfo({ ...info, ["category"]: e.target.value });
	}

	const update = () => {
		UpdateService(service._id, info);
		setHide(true);
	}

	const chkandsubmit = () => {
		if (info.categoryDetail.length > 0) {
			if (service._id) {
				if (info.category && service.categoryDetail != info.categoryDetail) {
					update();
				}
			} else {
				callback && callback(info);
			}
		}
	}

	const detailName = (code) => {
		const result = codes.find(item => {
			return item.code == code
		});

		return result ? result.name : ''
	};

	return (
		<div>
			<div className="card-header" onClick={() => setHide(!hide)}>
				{t('claimcategory')}
				<div className="float-end ms-2">
					<ion-icon name={hide ? "chevron-down-outline" : "chevron-up-outline"}></ion-icon>
				</div>
				{hide && <div className="float-end">
					{info.category || "-"} / {detailName(info.categoryDetail) || "-"}
				</div>}
			</div>
			{!hide && <div className="card-body">
				<select className="form-control mb-1"
					value={info.category}
					onChange={(e) => changeComboParent(e)}>
					{comboParent.map(x => <option value={x}>{x}</option>)}
				</select>
				{showOther && <>
					<input type="text" className="form-control mb-1"
						value={info.categoryDetail}
						onChange={(e) => { setInfo({ ...info, categoryDetail: e.target.value }); setotherSaveShow(e.target.value.length > 0) }} />
					{(service._id && otherSaveShow) && <button className="btn btn-block btn-primary" onClick={() => chkandsubmit()}>{t('update')}</button>}
				</>}
				{!showOther && <select className="form-control"
					value={info.categoryDetail}
					onChange={(e) => changeCombo(e)}>
					{combo.map(x => <option value={x.code}>{x.name}</option>)}
				</select>}
			</div>}
		</div>
	);
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const clist = state.RayCodeReducer.items;
	return { service, clist };
};

const mapDispatch = (dispatch) => ({
	UpdateService: (_id, info) => dispatch(ServiceAction.UpdateService(_id, info)),
});

export default connect(mapState, mapDispatch)(ServiceClaimCategory);
