import { DeviceModels, LanguageCode } from '../../config';
import { connect } from 'react-redux';
import { RayForm } from '../common';
import { ErrorCodeAction } from '../../actions/ErrorCodeAction';
import React, { useEffect, useState } from 'react';

const ErrorCodeInfoSolution = ({ errorCode, errorCodeInfo, GetInfoOne, AddInfo, UpdateInfo, RemoveInfo }) => {

	const [isAddProcess, setIsAddProcess] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isNew, setIsNew] = useState(true);
	const [addAttachment, setAddAttachment] = useState(false);
	const [newAttachment, setNewAttachment] = useState({ title: "", path: "" });
	const [isDelete, setIsDelete] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [editErrorCodeInfo, setEditErrorCodeInfo] = useState({});
	const [hasLang, setHasLang] = useState([]);
	const [solutions, setSolutions] = useState([]);
	const [attachments, setAttachments] = useState([]);

	useEffect(() => {
		GetInfoOne(errorCode._id);
	}, [])

	useEffect(() => {
		if (isAddProcess) {
			setIsAddProcess(false);
			if (isDelete) {
				setIsDelete(false);
				setIsEdit(false);
			} else {
				editLanguage(editErrorCodeInfo.lang);
			}
		}
		setHasLang(errorCodeInfo.map(x => x.lang));
		setIsLoading(false);
	}, [errorCodeInfo])

	const handleUpdateData = (e) => {
		const { name, value } = e.target;
		setEditErrorCodeInfo({ ...editErrorCodeInfo, [name]: value });
		if (!isNew && editErrorCodeInfo[name] != value)
			AddorUpdateData({ [name]: value });
	}

	const insertSolution = (e) => {
		if (e.key === 'Enter') {
			const newSolution = {
				title: e.target.value.trim(),
				useCount: 0,
				resolvedCount: 0,
				registered: new Date()
			};
			e.target.value = "";
			setSolutions([...solutions, newSolution]);
			if (!isNew)
				AddorUpdateData({ solutions: [...solutions, newSolution] });
		}
	}

	const insertAttachment = () => {
		if (newAttachment.path != "" && newAttachment.title != "") {
			setAttachments([...attachments, newAttachment]);
			AddorUpdateData({ attachments: [...attachments, newAttachment] });
		}
	}

	const reOrder = (index, delta) => {
		var newIndex = index + delta;
		if (newIndex < 0 || newIndex == solutions.length) return;
		var indexes = [index, newIndex].sort();
		solutions.splice(indexes[0], 2, solutions[indexes[1]], solutions[indexes[0]]);
		if (!isNew)
			AddorUpdateData({ solutions: [...solutions] });
	};

	const delitem = (idx) => {
		solutions.splice(idx, 1);
		if (!isNew)
			AddorUpdateData({ solutions: [...solutions] });
	};

	const delAttachment = (idx) => {
		attachments.splice(idx, 1);
		if (!isNew)
			AddorUpdateData({ attachments: [...attachments] });
	};

	const AddorUpdateData = (obj) => {
		UpdateInfo(editErrorCodeInfo._id, obj);
	}

	const addSolution = () => {
		setIsAddProcess(true);
		AddInfo({
			errorCodeId: errorCode._id,
			errorCode: errorCode.errorCode,
			model: errorCode.model,
			lang: editErrorCodeInfo.lang,
			definition: editErrorCodeInfo.definition,
			cause: editErrorCodeInfo.cause,
			solutions: solutions,
			attachments: attachments,
		});
	}

	const removeSolution = () => {
		setIsAddProcess(true);
		setIsDelete(true);
		RemoveInfo(editErrorCodeInfo._id);
	}

	const editLanguage = (lng) => {
		setAddAttachment(false);
		if (lng) {
			const item = errorCodeInfo.filter(x => x.lang == lng);
			setEditErrorCodeInfo(item[0]);
			setSolutions(item[0].solutions);
			setAttachments(item[0].attachments);
			setIsNew(false);
		} else {
			setNewAttachment({ title: "", path: "" });
			setEditErrorCodeInfo({ lang: LanguageCode[0].code, definition: "", cause: "" });
			setSolutions([]);
			setAttachments([]);
			setIsNew(true);
		}
		setIsEdit(true);
	}

	const getLanguageTitle = (code) => {
		const lng = LanguageCode.filter(y => y.code == code.trim());
		return lng.length > 0 ? lng[0].title : code;
	}

	const getLangs = () => {
		if (isNew) {
			return LanguageCode.filter(l => !hasLang.includes(l.code));
		} else {
			return LanguageCode;
		}
	}

	const handleChangeAttachInfo = (e) => {
		const { name, value } = e.target;
		setNewAttachment({ ...newAttachment, [name]: value });
	}

	const handleChangeTitle = (idx, e) => {
		solutions[idx].title = e.target.value;
		setSolutions([...solutions]);
	}

	const handleUpdateTitle = (idx, e) => {
		if (e.key === 'Enter') {
			solutions[idx].title = e.target.value;
			e.target.value = "";
			setSolutions([...solutions]);
			if (!isNew)
				AddorUpdateData({ solutions: [...solutions] });
		}
	}

	const form_controls = [
		{
			title: "", items: [
				{
					type: "select", name: "lang", label: "Language",
					isEditable: isNew ? true : false,
					val: editErrorCodeInfo.lang || "eng", list: getLangs(), size: 12
				},
			]
		},
		{
			title: "", items: [
				{
					type: "input", name: "definition", label: "Definition", isEditable: true,
					val: editErrorCodeInfo.definition, size: 12
				},
			]
		},
		{
			title: "", items: [
				{
					type: "input", name: "cause", label: "Cause of Problem", isEditable: true,
					val: editErrorCodeInfo.cause, size: 12
				},
			]
		},
	]

	return (
		<>
			{isLoading && <div className="">Loading...</div>}
			{!isLoading &&
				<div className="row">
					<div className="col-md-3">
						<div className="list-group">
							<a className={isNew ? "list-group-item active" : "list-group-item"} onClick={() => editLanguage("")}>
								Add New
							</a>
							{errorCodeInfo.map(x =>
								<a className={editErrorCodeInfo.lang == x.lang ? "list-group-item active" : "list-group-item"}
									onClick={() => editLanguage(x.lang)}>{getLanguageTitle(x.lang)}
								</a>)
							}
						</div>
					</div>
					{isEdit &&
						<div className="col-md-9">
							<div className="card">
								<div className="card-body">
									<h5 className="m-l-5">Information</h5>
									<RayForm
										controls={form_controls}
										isForm={isNew}
										handleUpdateData={handleUpdateData} />
									<h5 className="m-l-5">Solutions</h5>
									<ul className="list-group">
										{solutions.map((s, idx) => {
											return (
												<li className="list-group-item p-a-8" key="{idx}">
													<div className="form-group form-group-default input-group m-b-0">
														<div className="form-input-group">
															<input type="text" value={s.title} className="form-control"
																onChange={(e) => handleChangeTitle(idx, e)}
																onKeyPress={(e) => handleUpdateTitle(idx, e)}
															/>
														</div>
														<div className="input-group-append ">
															<span className="input-group-text m-r-5">Used : {s.useCount}</span>
															<span className="input-group-text m-r-5">Resolved : {s.resolvedCount}</span>
															<div className="btn-group">
																{idx > 0 &&
																	<button className="m-t-0 btn btn-sm" onClick={() => reOrder(idx, -1)}>
																		<span className="material-icons fs-16">expand_less</span>
																	</button>
																}
																{idx < solutions.length - 1 &&
																	<button className="m-t-0 btn btn-sm" onClick={() => reOrder(idx, 1)}>
																		<span class="material-icons fs-16">expand_more</span>
																	</button>
																}
															</div>
															<button className="m-t-0 m-l-5 btn btn-sm bg-danger" onClick={() => delitem(idx)}>
																<span class="material-icons text-white fs-16">delete</span>
															</button>
														</div>
													</div>
												</li>
											)
										})}
										<li className="list-group-item p-a-8">
											<input type="text" className="form-control" placeholder="Add New Solution"
												onKeyPress={(e) => insertSolution(e)} />
										</li>
										{solutions.length == 0 && <span className='alert'>No Solution</span>}
									</ul>
									<h5 className="m-l-5">Attachments
										<button className="float-right btn btn-sm btn-primary m-t-0 p-l-0 p-r-0"
											onClick={() => setAddAttachment(true)}><span class="material-icons">add</span></button>
									</h5>
									<ul className="list-group">
										{attachments.map((s, idx) => {
											return (
												<li className="list-group-item p-a-8" key="{idx}">
													<a href={s.path} target="_blank">
														<span className="material-icons fs-16">attach_file</span>
														{s.title}
													</a>
													<div className="float-right">
														<button className="m-t-0 m-l-5 btn btn-sm bg-danger" onClick={() => delAttachment(idx)}>
															<span class="material-icons text-white fs-16">delete</span>
														</button>
													</div>
												</li>
											)
										})}
										{addAttachment && <li className="list-group-item p-a-8">
											<div className="form-group">
												<label>Title</label>
												<input type="text" className="form-control"
													name="title"
													value={newAttachment.title}
													onChange={(e) => handleChangeAttachInfo(e)}
													placeholder="Add New Attachment Title" />
											</div>
											<div className="form-group">
												<label>Path</label>
												<input type="text" className="form-control"
													name="path"
													value={newAttachment.path}
													onChange={(e) => handleChangeAttachInfo(e)}
													placeholder="Add New Attachment Path" />
											</div>
											<button className="btn btn-primary" onClick={() => insertAttachment()}>Add Attachment</button>
											<button className="btn btn-default m-l-5" onClick={() => setAddAttachment(false)}>Cancel</button>
										</li>}
										{attachments.length == 0 && <span className='alert'>No Attachment</span>}
									</ul>
								</div>
							</div>
							{editErrorCodeInfo._id && <button className="btn btn-outline-danger" onClick={() => removeSolution()}>Delete this language</button>}
							{!editErrorCodeInfo._id && <button className="btn btn-primary" onClick={() => addSolution()}>Add Solution</button>}
						</div>
					}
				</div>
			}
		</>
	);
};

const mapState = (state) => {
	const isUpdateRes = state.ErrorCodeReducer.isUpdateRes;
	const errorCodeInfo = state.ErrorCodeReducer.errorCodeInfo;
	return { errorCodeInfo, isUpdateRes };
};

const mapDispatch = (dispatch) => ({
	GetInfoOne: (errorCodeId) => dispatch(ErrorCodeAction.GetInfoOne(errorCodeId)),
	AddInfo: (errorCodeInfo) => dispatch(ErrorCodeAction.AddInfo(errorCodeInfo)),
	RemoveInfo: (errorCodeInfo) => dispatch(ErrorCodeAction.DeleteInfo(errorCodeInfo)),
	UpdateInfo: (_id, errorCodeInfo) => dispatch(ErrorCodeAction.UpdateInfo(_id, errorCodeInfo)),
	ClearUpdateResult: () => dispatch(ErrorCodeAction.ClearUpdateResult()),

});

export default connect(mapState, mapDispatch)(ErrorCodeInfoSolution);