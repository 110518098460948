import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayForm, RayFormValidation } from '../../components/common';
import { history } from '../../history';
import SiteCard from '../../components/site/SiteCard';
import ProductCard from '../../components/product/ProductCard';
import { ServiceAction, ProductAction, SiteAction, ErrorCodeAction } from '../../actions';
import ServiceClaimCategory from './ServiceClaimCategory';
import ServiceInfoAttachments from './ServiceInfoAttachments';
import { InputBasicCheckbox, InputBasicDateTime, InputSelect2Search } from '../common/Inputs';
import { isDOA, isDIM, Flag } from '../../components/common/CommonViews';
import { Translate as t } from '../../config/Translate';

var createservicesearchcallCount = 4;
const ServiceCreate = ({ service, apierrors, errorCodes, Create, ErrorCodeList, callbackCreated, sn = "", siteId = "", withBack = false }) => {
	const [isAddProcess, setIsAddProcess] = useState(false);
	const [newService, setNewService] = useState({});
	const [errors, setErrors] = useState([]);
	const [searchView, setSearchView] = useState(true);
	const [search, setSearch] = useState("");
	const [product, setProduct] = useState([]);
	const [searched, setSearched] = useState(false);
	const [sites, setSites] = useState([]);
	const [pInfo, setpInfo] = useState({ sn: "" });
	const [sInfo, setSInfo] = useState({ siteId: "" });
	const [nService, setNService] = useState({});
	const [errorCodeList, setErrorCodeList] = useState([]);
	const [supportRequest, setSupportRequest] = useState(false);
	const [uploadList, setUploadList] = useState([]);

	useEffect(() => {
		if (isAddProcess) {
			callbackCreated && callbackCreated(service);
		}
	}, [service]);

	useEffect(() => {
		if (isAddProcess && uploadList.length == 0) {
			callbackCreated && callbackCreated(nService);
		}
	}, [nService]);

	useEffect(() => {
		setErrors(apierrors);
	}, [apierrors]);

	useEffect(() => {
		ErrorCodeList();
		if (sn) {
			getProduct(sn);		
		}
	}, []);

	useEffect(() => {
		const result = [{ code: '-', title: 'None' }];
		errorCodes?.forEach(item => {
			if (item.model?.includes(pInfo?.model) && item.lang == 'eng')
				result.push({ code: item.errorCode, title: item.errorCode });
		});
		setErrorCodeList(result);

	}, [errorCodes, pInfo]);

	useEffect(() => {
		if (pInfo?.sn) {
			setNewService({ ...newService, sn: pInfo.sn, model : pInfo.model });
			setSearchView(false);
		}
	}, [pInfo]);

	const getSite = async (sId) => {
		const ret = await SiteAction.GetSiteLocal(sId);
		setSInfo(ret);
	}

	const getProduct = async (sn) => {
		const ret = await ProductAction.GetProductLocal(sn);

		if (ret?.siteId) {
			setNewService({ ...newService, siteId: ret.siteId, sn: ret.sn, model : ret.model });
			getSite(ret.siteId);
		}
		setpInfo(ret);
	}

	const handleChangeData = (e) => {
		const { name, value } = e.target;

		if (name == 'supportRequestToggle') {
			if (!value) {
				delete newService['supportRequest'];
				delete newService['status'];
				delete newService['statusType'];

				setNewService(newService);
			}
		} else if (name == 'supportRequest') {
			setNewService({ ...newService, supportRequest: value, status: 'Remote', statusType: 'OPEN' });
		} else if (name == 'errorCode') {
			setNewService({ ...newService, [name]: value.replace('-', '') });
		} else {
			setNewService({ ...newService, [name]: value });
		}

		console.log(newService);
	};

	const checkSupportRequest = (e) => {
		setSupportRequest(!supportRequest);
		handleChangeData(e);
	}

	const form_controls = [
		{ title: '', items: [{ type: 'input', name: 'summary', label: t('titlerequired'), size: 12, required: true }] },
		{ title: '', items: [{ type: 'datetime', name: 'effectiveDate', label: t('effectivedate'), size: 12, onlyDate: true }] },
		{ title: '', items: [{ type: 'textarea', name: 'description', label: t('servicereport'), size: 12 }] },
	];

	const validAdd = () => {
		const errValidate = RayFormValidation(form_controls, newService);

		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsAddProcess(true);
			createService(newService);
		}
	};

	const createService = async (newService) => {
		setNService(await ServiceAction.CreateServiceLocal(newService));
	}

	const searchProduct = async () => {
		var filter = {};
		if (search) {
			filter['match'] = { sn: { $regex: search.trim().toUpperCase(), $options: 'si' } };
		}

		if (Object.keys(filter).length > 0) {
			const ret = await ProductAction.GetProductDirect(filter);
			setProduct(ret.list);
		}
		createservicesearchcallCount++;
	}

	const searchSite = async () => {
		const ret = await SiteAction.GetSearchSiteAll(['sitename', 'customerFirstName', 'customerLastName'], search.trim());
		if (ret.data.status == 'success') {
			setSites(ret.data.data.list);
		} else {
			setSites([]);
		}
		createservicesearchcallCount++;
	}

	const searchGo = (e) => {
		createservicesearchcallCount = 0;
		e.preventDefault();
		if (search.length >= 3) {
			searchProduct();
			searchSite();
		}
		setSearched(true);
	}

	const setPrd = (prd) => {
		getProduct(prd?.sn);
	}

	const updateSearchKey = (val) => {
		setSearch(val);
		setSearched(false);
	}

	const callback = (info) => {
		setNewService({ ...newService, category: info.category, categoryDetail: info.categoryDetail });
	}

	const isOnline = () => {
		return pInfo.isConnected ?
			<ion-icon name="wifi" className="text-primary md hydrated" style={{ pointerEvents: 'none' }}></ion-icon> :
			<ion-icon name="wifi-outline" className="text-muted md hydrated" style={{ pointerEvents: 'none' }} ></ion-icon>;
	}

	return (
		<>
			{searchView && <>
				<div className="sch p-2 bg-white mb-1">
					<form className="search-form" onSubmit={(e) => searchGo(e)}>
						<div className="form-group searchbox">
							<input className="form-control fs-22"
								type="text"
								onChange={(e) => updateSearchKey(e.target.value)}
								placeholder={t('snorsitename')}
								value={search} />
							<i className="input-icon">
								<ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
							</i>
						</div>
						{search.length < 3 && <button className="btn btn-primary btn-block disabled" onClick={(e) => searchGo(e)}>{t('search')}</button>}
						{search.length >= 3 && <button className="btn btn-primary btn-block" onClick={(e) => searchGo(e)}>{t('search')}</button>}
					</form>
				</div>
				{product?.length > 0 &&
					<div className="section mb-2">
						<div className="card mt-2">
							<div className="card-header">
								<h4 className="mb-0">
									{t('product')}
									<span className="small"> ({product.length.toLocaleString()})</span>
								</h4>
							</div>
							<ul className="listview link-listview">
								{product.map(x => <li key={x.sn} onClick={() => setPrd(x)}>
									<ProductCard item={x} showOnly={true} />
								</li>)}
							</ul>
						</div>
					</div>
				}
				{sites.length > 0 &&
					<div className="section mb-2">
						<div className="card">
							<div className="card-header">
								<h4 className="mb-0">
									{t('site')}
									<span className="small"> ({sites.length.toLocaleString()})</span>
								</h4>
							</div>
							<ul className="listview link-listview">
								{sites.map(x => <li className="border-bottom" onClick={() => setPrd(x.product)}>
									<SiteCard item={x} showOnly={true} />
								</li>)}
							</ul>
						</div>
					</div>
				}
				{(!product?.length && !sites?.length && searched && search) &&
					<div className="section mb-2 mt-1">
						<div className="card">
							<div className="card-body text-center">
								{createservicesearchcallCount < 2 ? t('Please wait...') : t('norecord')}
							</div>
						</div>
					</div>
				}
			</>}
			{!searchView && <>
				<div className="card me-1 ms-1 mt-1">
					<div className="item p-2">
						<div className='in'>
							<strong>{isOnline()} {pInfo.sn} {isDOA(pInfo)} {isDIM(pInfo)} </strong>
							<div>
								<strong>{Flag(sInfo.country)}{sInfo.sitename} </strong>
							</div>
							<div className="text-muted">{pInfo.model} {pInfo.option && "-" + pInfo.option}</div>
						</div>
					</div>
				</div>
				<div className="card me-1 ms-1 mt-1 section">
					<div className="item p-2">
						<InputSelect2Search
							options={{
								type: "select2",
								name: "errorCode",
								label: <div>{t('errorcode')} { errorCodeList.length > 1 && <span className='small text-muted'>({errorCodeList.length - 1})</span>}</div>,
								list: errorCodeList,
								val: null,
								required: false
							}}
							editable={true}
							onchange={handleChangeData} />
					</div>
				</div>

				<RayForm controls={form_controls} isForm={true} handleChangeData={handleChangeData} errors={errors} />

				<div className="card me-1 ms-1 mt-1">
					<ServiceClaimCategory service={nService} pInfo={pInfo} callback={callback} />
				</div>

				<div className="card me-1 ms-1 mt-1">
					<ServiceInfoAttachments propService={nService} uploadLater={true} callback={(uploadList) => setUploadList(uploadList)} />
				</div>

				<div className="card me-1 ms-1 mt-1">
					<div>
						<div className="card-header">
							{t('supportrequest')}
							<div className='float-end'>
								<InputBasicCheckbox options={{ name: 'supportRequestToggle', val: supportRequest }} editable={true} onchange={checkSupportRequest} />
							</div>
						</div>

						{supportRequest && <div className='card-body pb-1 pt-1'>
							<InputBasicDateTime
								options={{ name: 'supportRequest', label: '' }}
								editable={true}
								onchange={handleChangeData}
							/>
						</div>}
					</div>
				</div>

				<div className="section">
					<button className="btn btn-primary btn-block" onClick={() => validAdd()}> {t('submit')} </button>
					{withBack && <button className="btn btn-secondary btn-block" onClick={(e) => history.goBack()}> {t('back')} </button>}
					<button className="btn btn-secondary btn-block" onClick={() => setSearchView(true)}> {t('cancel')} </button>
				</div>
			</>}
		</>
	);
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const apierrors = state.AlertReducer.errors;
	const errorCodes = state.ErrorCodeReducer.errorCodes;
	return { apierrors, service, errorCodes };
};

const mapDispatch = (dispatch) => ({
	Create: (data) => dispatch(ServiceAction.CreateService(data)),
	ErrorCodeList: () => dispatch(ErrorCodeAction.GetList())
});

export default connect(mapState, mapDispatch)(ServiceCreate);
