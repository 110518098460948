import { SiteSurveyConstant } from '../actions/constants/SiteSurveyConstant';
const initialState = {
    siteSurveys : [],
    siteSurvey : {},
    filterDateOptions : null,
    totalSiteSurveyCount : 0,
    isLoading : false
};
export const SiteSurveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SiteSurveyConstant.GET_SITESURVEYLIST:
            return {
                ...state, 
                siteSurveys : (action.append && action.result.page != 1) ? 
                    [...state.siteSurveys, ...action.result.list] : 
                    action.result.list,
                filterOptions : action.result.filterOptions,
                filterDateOptions : action.result.filterDateOptions,
                totalSiteSurveyCount : action.result.totalcount
            };
        case SiteSurveyConstant.GET_SITESURVEY:
            return {...state, siteSurvey : action.siteSurvey };
        case SiteSurveyConstant.NEW:
            return {...state, siteSurvey : { siteSurveyStep : 1 } };
        case SiteSurveyConstant.DELETE:
            return {...state, 
                siteSurveys : state.siteSurveys.filter(u => u._id !== action.delsitesurveyid), 
                isUpdateRes : true
            };
        case SiteSurveyConstant.UPDATE:
            return {...state, 
                siteSurveys : state.surveys.map(u => u._id == action.siteSurvey._id ? action.siteSurvey : u),
                siteSurvey : action.siteSurvey
            };
        case SiteSurveyConstant.CREATE:
            return {...state, 
                siteSurvey : action.siteSurvey
            };
        case SiteSurveyConstant.LOADING:
            return {...state, isLoading : true}
        case SiteSurveyConstant.LOADED:
            return {...state, isLoading : false}
        case SiteSurveyConstant.CLEAR:
            return { ...state, siteSurveys : [] };
        case SiteSurveyConstant.SITESURVEY_UPDATE_ADD_ATTACHMENT:
            return {...state,
                siteSurveys : state.siteSurveys.map(u => u._id == action.siteSurvey._id ? action.siteSurvey : u),
                siteSurvey : action.siteSurvey
            };
        case SiteSurveyConstant.SITESURVEY_UPDATE_DEL_ATTACHMENT:
            return {...state,
                siteSurveys : state.siteSurveys.map(u => u._id == action.siteSurvey._id ? action.siteSurvey : u),
                siteSurvey : action.siteSurvey
            };
        default:
            return state
    }
}