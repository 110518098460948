import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { ServiceAction, UserAction } from '../../actions';
import { RayForm } from '../../components/common';
import { csrBlob } from '../../config/csr';
import moment from 'moment';

const AdminServiceExportCSR = ({ service, GetServiceByTicket }) => {
    const [data, setData] = useState({
        grade: 'A',
        selectedType: 'claim',
        claim: 'option1',
        claimReason: '',
        service: 'option1',
        serviceReason: '',
        solved: 'pass',
        hardware: 'pass',
        noise: 'pass',
        software: 'pass',
        acq: 'pass',
        logfile: 'X',
    });
    const [hideClaim, setHideClaim] = useState(false);
    const [hideReason, setHideReason] = useState(true);

    const [users, setUsers] = useState([]);

    const [hideService, setHideService] = useState(true);
    const [hideServiceReason, setHideServiceReason] = useState(true);

    useEffect(async () => {
        const item = history.state.state.item;
        setUsers(await UserAction.getAllUserInfo());
        GetServiceByTicket(item.ticketKey);
    }, []);

    useEffect(async () => {

        console.log(service);

        service && setData({
            ...data,
            companyId: service.companyId,
            companyName: service.companyname,
            parentCompanyId: service.company?.parentCompanyId,
            ticketKey: service.ticketKey,
            effectiveDate: service.effectiveDate,
            customerName: service.site ? service.site.sitename : '',
            customerTel: service.site ? service.site.tel : '',
            customerAddress: service.site ? service.site.address : '',
            model: service.model,
            option: service.option,
            sn: service.sn,
            installedDate: service.installedDate,
            summary: service.summary,
            creator: getUserName(service.creator) ? getUserName(service.creator) : '',
            description: service.description,
            assignee: getUserName(service.assignee) ? getUserName(service.assignee) : '',
            closer: getUserName(service.closer) ? getUserName(service.closer) : '',
            attachments: service.attachments,            
            replacements: service.replacements,
            rev: localStorage.getItem('rev') || '',
            closedDate: service.closedDate ? service.closedDate : 0,
            createDate : service.createDate
        })
    }, [service]);

    useEffect(() => {
        if (data.selectedType == 'claim') {
            setHideClaim(false);
            setHideService(true);

            if (!hideServiceReason)
                setHideServiceReason(true);
        }
        else {
            setHideClaim(true);
            setHideService(false);

            if (!hideReason)
                setHideReason(true);

        }
    }, [data.selectedType]);

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const exportCSR = async () => {
        if (data.rev)
            localStorage.setItem('rev', data.rev);

        const blob = await csrBlob(data);

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        a.href = url;
        a.download = `${moment(data.createDate).format('YYYYMMDD')}_서비스처리서_${data.ticketKey.substring(0, 2)}_${data.sn}.docx`;
        a.click();
        window.URL.revokeObjectURL(url);        
    }

    const getUserName = (adminid) => {
        return users.filter(x => x.adminid == adminid).map(y => {
            return y.name ? y.name : `${y.firstName} ${y.lastName}`;
        })[0];
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Services - Export CSR</h2>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <RayForm controls={[
                    {
                        title: "구분",
                        items: [                       
                            {
                                type: "select", name: "service",  isEditable: true, val: data.service, required: true,  list: [
                                    { code: 'option1', title: '서비스' },
                                    { code: 'option2', title: '사용자 부주의' },
                                    { code: 'option3', title: '사용자 재교육' },
                                    { code: 'option4', title: '기타' },
                                ]
                            },
                            {
                                type: 'input', name: 'serviceReason', label: '기타 - 사유', isEditable: true, val: data.serviceReason, hide: hideServiceReason
                            },
                        ]
                    }]} 
                    handleChangeData={handleChangeData} />

                <RayForm controls={[
                    {
                        title: "조치결과",
                        items: [
                            {
                                type: "select", name: "solved", label: '접수 이슈 사항 해결 여부', isEditable: true, val: data.solved, required: true, list: [
                                    { code: 'pass', title: '합격' },
                                    { code: 'fail', title: '불합격' }
                                ]
                            },
                            {
                                type: "select", name: "hardware", label: '해당 제품 별 하드웨어 기본 동작 점검', isEditable: true, val: data.hardware, required: true, list: [
                                    { code: 'pass', title: '합격' },
                                    { code: 'fail', title: '불합격' }
                                ]
                            },
                            {
                                type: "select", name: "software", label: '해당 제품 별 소프트웨어 기본 동작 점검', isEditable: true, val: data.noise, required: true, list: [
                                    { code: 'pass', title: '합격' },
                                    { code: 'fail', title: '불합격' }
                                ]
                            },                                                      
                        ]
                    }
                ]} handleChangeData={handleChangeData} />

                <RayForm controls={[
                    {
                        title: "Log file",
                        items: [
                            {
                                type: "select", name: "logfile", label: '', isEditable: true, val: data.logfile, required: true,
                                list: [
                                    { code: 'O', title: '확보' },
                                    { code: 'X', title: '미확보' },
                                    { code: '-', title: '불필요' },
                                ]
                            },
                        ]
                    }
                ]} handleChangeData={handleChangeData} />

                <RayForm controls={[{
                    title: 'Rev',
                    items: [
                        { type: 'input', name: 'rev', label: '', isEditable: true, val: data.rev }
                    ]
                }]} handleChangeData={handleChangeData} />

                <div className="section">
                    <button className="btn btn-primary btn-block" onClick={() => exportCSR()} disabled={!data.ticketKey}>Export</button>

                    <button className="btn btn-secondary btn-block" onClick={() => history.back()}>Back</button>
                </div>
            </div>
        </>
    )
}


const mapState = (state) => {
    return {
        service: state.ServiceReducer.service,
    }
}

const mapDispatch = (dispatch) => ({
    GetServiceByTicket: (ticketKey) => dispatch(ServiceAction.GetServiceByTicketKey(ticketKey)),
});

export default connect(mapState, mapDispatch)(AdminServiceExportCSR);
