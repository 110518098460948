import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayTable } from '../../components/common';
import { RayDataWarListHeader } from '../../components/common/TableHeader';
import { RayDataAction } from '../../actions/RayDataAction';
import { CompanyAction } from '../../actions/CompanyAction';
import RayDataAddUpdate from '../../components/raydata/RayDataAddUpdate';
import { Translate as t } from '../../config/Translate';
import { UserType } from '../../config';

const AdminRayDataList = ({ GetAll, items, companyList }) => {
	const [type, setType] = useState("war");
	const [showModal, setShowModal] = useState(false);
	const [showType, setShowType] = useState("list");
	const [item, setItem] = useState({});
	const [options, setOptions] = useState({
		search: true,
		keyCol: '_id',
		filterColumns: [
			{ col: 'companyName', val: 'ALL' },
		],
	});
	const [user, setUser] = useState([]);
	const [isHQ, setIsHQ] = useState(false);

	const [list, setList] = useState([]);
	const [newList, setNewList] = useState([]);
	
	const [listMode, setListMode] = useState('ALL');

	useEffect(() => {
		const uData = JSON.parse(localStorage.getItem("user"));
		setUser(uData);
		setIsHQ(UserType(uData.companyId) === 'HQ');
		//GetCompany();
	}, []);

	useEffect(() => {
		switch (type) {
			case "war": setOptions({ ...options, linkkey: "companyName" }); break;
		}
	}, [type]);

	useEffect(() => {
		// makeList();
		makeNewList();
	}, [items, listMode]);

	useEffect(() => {
		const companyId = isHQ ? '' : user.companyId;
		GetAll("war", companyId);
	}, [companyList, isHQ]);

	useEffect(() => {		
		return () => setShowModal(true);
	}, [item]);

	const selected = (e) => {				
		// setItem(isHQ ? { ...e } : { ...items.find(x => x._id === e.bId) });
		setItem(e);
	}	

	const addNew = () => { setItem({}); }

	const makeList = () => {
		const bList = items.filter(x => x.category === 'BRANCH');

		const exists = [];

		// const list = items.filter(x => x.category === 'HQ').map(x => {
			const list = items.map(x => {
			var n = companyList.filter(y => y.companyId === x.companyId);
			if (n.length > 0)
				x["companyName"] = n[0].name;

			const branchData = bList.find(b => x.companyId === b.companyId && isEqualArr(b.models, x.models));

			if (branchData) {
				x["bId"] = branchData._id;
				exists.push(branchData._id);
			} else {
				x["bId"] = "";
			}

			// PRODUCT INSTALL
			const productInstall = x.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '';
			let piBranch = branchData ? branchData.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '' : ''
			const piResult = listMode === "ALL" && productInstall + `${piBranch ? ` (${piBranch})` : ''}` || listMode === "HQ" && productInstall || listMode === "BRANCH" && piBranch;
			x["productWarInstallMonth"] = piResult;

			// PRODUCT SHIPMENT
			const productShipment = x.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '';
			let psBranch = branchData ? branchData.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '' : ''
			const psResult = listMode === "ALL" && productShipment + `${psBranch ? ` (${psBranch})` : ''}` || listMode === "HQ" && productShipment || listMode === "BRANCH" && psBranch;
			x["productWarShipMonth"] = psResult;

			// MAIN PARTS INSTALL 
			const mainPartsInstall = x.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '';
			let miBranch = branchData ? branchData.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '' : ''
			const miResult = listMode === "ALL" && mainPartsInstall + `${miBranch ? ` (${miBranch})` : ''}` || listMode === "HQ" && mainPartsInstall || listMode === "BRANCH" && miBranch;
			x["partWarInstallMonth"] = miResult;

			// MAIN PARTS SHIPMENT
			const mainPartsShipment = x.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '';
			let msBranch = branchData ? branchData.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '' : ''
			const msResult = listMode === "ALL" && mainPartsShipment + `${msBranch ? ` (${msBranch})` : ''}` || listMode === "HQ" && mainPartsShipment || listMode === "BRANCH" && msBranch;
			x["partWarShipMonth"] = msResult;

			return x;
		});

		const branchList = bList.filter(x => !exists.includes(x._id)).map(x => {
			var n = companyList.filter(y => y.companyId === x.companyId);
			if (n.length > 0)
				x["companyName"] = n[0].name;

			x["bid"] = x._id;

			// PRODUCT INSTALL
			let piBranch = x.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '';
			const piResult = listMode === "ALL" && `(${piBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && piBranch;
			x["productWarInstallMonth"] = piResult;

			// PRODUCT SHIPMENT
			let psBranch = x.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '';
			const psResult = listMode === "ALL" && `(${psBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && psBranch;
			x["productWarShipMonth"] = psResult;

			// MAIN PARTS INSTALL 
			let miBranch = x.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '';
			const miResult = listMode === "ALL" && `(${miBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && miBranch;
			x["partWarInstallMonth"] = miResult;

			// MAIN PARTS SHIPMENT
			let msBranch = x.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '';
			const msResult = listMode === "ALL" && `(${msBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && msBranch;
			x["partWarShipMonth"] = msResult;

			return x;
		});

		console.log('branchList', branchList);

		// list.push(branchList);

		// console.log('list', list);

		// console.log('bList', bList.filter(x => !exists.includes(x._id)).map(x => {
		// 	var n = companyList.filter(y => y.companyId === x.companyId);
		// 	if (n.length > 0)
		// 		x["companyName"] = n[0].name;

		// 	x["bid"] = x._id;

		// 	// PRODUCT INSTALL
		// 	let piBranch = x.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '';
		// 	const piResult = listMode === "ALL" && `(${piBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && piBranch;
		// 	x["productWarInstallMonth"] = piResult;

		// 	// PRODUCT SHIPMENT
		// 	let psBranch = x.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'Product').sort((a, b) => b.to - a.to)[0]?.months || '';
		// 	const psResult = listMode === "ALL" && `(${psBranch})` || listMode === "HQ" && productShipment || listMode === "BRANCH" && psBranch;
		// 	x["productWarShipMonth"] = psResult;

		// 	// MAIN PARTS INSTALL 
		// 	let miBranch = x.standards.filter(y => y.baseDate === 'InstalledDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '';
		// 	const miResult = listMode === "ALL" && `(${miBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && miBranch;
		// 	x["partWarInstallMonth"] = miResult;
	
		// 	// MAIN PARTS SHIPMENT
		// 	let msBranch = x.standards.filter(y => y.baseDate === 'ShipmentDate' && y.type === 'MainParts').sort((a, b) => b.to - a.to)[0]?.months || '';
		// 	const msResult = listMode === "ALL" && `${msBranch})` || listMode === "HQ" && '' || listMode === "BRANCH" && msBranch;
		// 	x["partWarShipMonth"] = msResult;

		// 	return x;
		// }));

		setList(list);
	}

	const makeNewList = () => {
		setNewList(items.map(x => {
			return {
				...x,
				companyName: companyList.find(y => y.companyId === x.companyId)?.name,
				productWarrantyByInstalledDate: warrantyByListMode("product", "InstalledDate", x),
				productWarrantyByShipmentDate: warrantyByListMode("product", "ShipmentDate", x),
				mainPartsWarrantyByInstalledDate: warrantyByListMode("mainParts", "InstalledDate", x),
				mainPartsWarrantyByShipmentDate: warrantyByListMode("mainParts", "ShipmentDate", x),
			}
		}));
	}

	const warrantyByListMode = (product_type, date_type, data) => {
		let result = '';

		if (listMode == "ALL") {
			result += data[product_type + 'WarrantyBy' + date_type + "HQ"] || '';
			if (data[product_type + "WarrantyBy" + date_type + "Branch"])
				result += `(${data[product_type + "WarrantyBy" + date_type + "Branch"]})`;
		}

		if (listMode == "HQ")
			result += data[product_type + 'WarrantyBy' + date_type + "HQ"] || '';

		if (listMode == "BRANCH")
			result += data[product_type + 'WarrantyBy' + date_type + "Branch"] ? '(' + data[product_type + 'WarrantyBy' + date_type + "Branch"] + ')' : '';

		return result;
	}	

	const getColumns = () => {
		switch (type) {
			case "war":
				return RayDataWarListHeader;
		}
	}

	const isEqualArr = (arr1, arr2) => {
		if (arr1.length !== arr2.length)
			return false;

		const sortedArr1 = arr1.slice().sort();
		const sortedArr2 = arr2.slice().sort();

		for (let i = 0; i < sortedArr1.length; i++) {
			if (sortedArr1[i] !== sortedArr2[i])
				return false;
		}

		return true;
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{!user.isMegagen ? t('raydatalist') : 'MegagenData 목록'}</h2>
				<div className="ray-right">
					<select style={{ verticalAlign: 'middle', marginRight: '10px' }} onChange={(e) => setListMode(e.target.value)}>
						<option value={'ALL'}>HQ + Branch</option>
						<option value={'HQ'}>HQ</option>
						<option value={'BRANCH'}>Branch</option>
					</select>

					<button className="btn btn-primary btn-sm mt-0" onClick={() => addNew()}>
						<ion-icon name="add-outline"></ion-icon>
						{t('addcode')}
					</button>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section mt-1 mb-1">
					<div className="wide-block pt-2 pb-2">
						<RayTable
							// data={list.filter(x => user.companyId === 'RAY02091' ? x.companyId === 'RAY02091' : x.companyId === x.companyId)}
							data={newList.filter(x => user.companyId === 'RAY02091' ? x.companyId === 'RAY02091' : x.companyId === x.companyId)}
							rowSelectCallback={(e) => selected(e)}
							callback={() => setShowType("list")}
							columns={getColumns()}
							options={options} />
					</div>
				</div>
				<RayDataAddUpdate
					data={item}
					type={type}
					showModal={showModal}
					isHQ={isHQ}
					callbackClose={(e) => { setShowModal(false) }} />
			</div>
		</>
	);
};

const mapState = (state) => {
	var items = state.RayDataReducer.items;
	var companyList = state.CompanyReducer.companies;
	return { items, companyList };
};

const mapDispatch = (dispatch) => ({
	GetAll: (dataType, companyId) => dispatch(RayDataAction.GetAll(dataType, companyId)),
	//GetCompany: () => dispatch(CompanyAction.GetCompany()),
});

export default connect(mapState, mapDispatch)(AdminRayDataList);