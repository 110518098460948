import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { InstallationAction } from '../../actions';
import { Modal } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const RaySignaturePad = ({ sigType, reportId, sigPath, Signature, onchange, view }) => {
    // const [ t, i18n ] = useTranslation();
    const [showModal, setState] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const [imageID, setImageID] = useState(null);
    const [installerValue, SetInstaller] = useState('');
    const [customerValue, SetCustomer] = useState('');
    const sigCanvas = useRef({});

    const [showSigPlaceHolder, setSigPlaceHolder] = useState(true);
    const [showSigPad, setSigPad] = useState(false);


    // Installation, Service 구분해야함

    useEffect(() => {
        if (sigPath) {
            const sigP = sigPath.signatures.filter((obj) => { return obj.type == sigType.toLowerCase() });
            if (sigP.length > 0) {
                setImageURL(sigP[0].path);
                setImageID(sigP[0]._id);
            }
            SetInstaller(sigPath.signatureName == "" ? sigPath.installerName : sigPath.signatureName);
            SetCustomer(sigPath.customerSignatureName);
        }
    }, []);

    const handleOpenModal = () => {
        setState(true);
        setSigPad(false);
        setSigPlaceHolder(true);
    };

    const handleCloseModal = () => {
        setState(false);
        setSigShow();
    };

    const handleSaveSignature = () => {
        var imageURL2 = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setImageURL(imageURL2);
        setState(false);
        Signature(reportId, "ADD", {
            attachmentType: "signature",
            attachmentSubType: sigType.toLowerCase(),
            path: imageURL2,
            signatureName: sigType == "Installer" ? installerValue : customerValue
        });
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setSigShow();
    };

    const setSigShow = () => {
        setSigPad(false);
        setSigPlaceHolder(true);
    }

    const delSignature = (image_id) => {
        // delete by signature Type
        setImageURL(null);
        Signature(reportId, "DEL", {
            attachmentType: "signature",
            attachmentSubType: sigType.toLowerCase(),
            imageId: image_id
        });
    };

    const updateValue = (e) => {
        SetInstaller(e.target.value);
        onchange && onchange(e);
    };

    const customerUpdateValue = (e) => {
        SetCustomer(e.target.value);
        onchange && onchange(e);
    };

    const handelShowSig = () => {
        setSigPlaceHolder(false);
        setSigPad(true);
    }

    return (
        <>
            {view != "view" &&
                <>
                    <button className="btn btn-block btn-secondary btn-block btn-lg" onClick={handleOpenModal}>{t(sigType.toLowerCase() + 'sig')}</button>
                    <Modal
                        show={showModal}
                        centered >
                        <Modal.Header style={{ display: "block" }}>
                            {sigType == "Installer" ? (
                                <div className="fl">
                                    ( <input type="text" style={{ display: "inline", width: "150px" }} id="inputInstallerSigName" value={installerValue} onChange={updateValue} /> ) {t('installersigcommnet')} <label className="right">{ }</label>
                                </div>
                            ) : (
                                <div>
                                    I ( <input type="text" style={{ display: "inline", width: "150px" }} id="inputCustomerSigName" value={customerValue} onChange={customerUpdateValue} /> ) {t('customersigcomment')}<label class="right"></label>
                                </div>
                            )}

                        </Modal.Header>
                        <Modal.Body className="p-0 m-0">
                            <div style={{ position: 'relative' }}>
                                {!showSigPad &&
                                    <>
                                        <div style={{ position: 'absolute', width: 500, top: '45%', left: '30%', color: 'gray', fontSize: 30 }}>
                                            Signature Here
                                        </div>
                                    </>
                                }
                                <SignaturePad
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                    ref={sigCanvas}
                                    onBegin={() => {
                                        setSigPad(true);
                                    }}
                                />
                            </div>

                            <div style={{ textAlign: 'center', margin: 10 }}>
                                <button className="btn btn-primary btn-sm mt-0" onClick={handleSaveSignature}>{t('save')}</button>&nbsp;
                                <button className="btn btn-primary btn-sm mt-0" onClick={handleClearSignature}>{t('clear')}</button>&nbsp;
                                <button className="btn btn-primary btn-sm mt-0" onClick={handleCloseModal}>{t('close')}</button>
                            </div>


                        </Modal.Body>
                    </Modal>
                </>}
            {(imageURL && view != "view") && (
                <>
                    <div className="float-end mt-1">
                        <a onClick={() => delSignature(imageID)}>
                            <ion-icon name="close-outline" size="large" />
                        </a>
                    </div>
                    <img
                        src={imageURL}
                        alt="my signature"
                        style={{
                            display: "block",
                            margin: "0 auto",
                            width: "300px"
                        }}
                    />
                </>
            )}
            {(imageURL && view == "view") &&
                <>
                    <div className="section-title bdB2">{t(sigType)}</div>
                    <img
                        src={imageURL}
                        alt="my signature"
                        style={{
                            display: "block",
                            margin: "0 auto",
                            width: "300px"
                        }}
                    />
                </>
            }

        </>
    );
};

const mapState = (state) => {
    const sigPath = state.InstallationReducer.installation;
    return { sigPath };
}

const mapDispatch = dispatch => ({
    Signature: (_id, type, data) => dispatch(InstallationAction.Signature(_id, type, data)),
})

export default connect(mapState, mapDispatch)(RaySignaturePad);