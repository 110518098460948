import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { TranslateConstant } from './constants/TranslateConstant';
import { AlertAction } from './AlertAction';

const Create = (data) => async (dispatch) => {
    const ret = await axios.post(AppConfig.API_SERVER_PATH + "/translate", {
        type: "regist",
        data,
        who: JSON.parse(localStorage.getItem('user')).adminid
    }, JWT());

    if (ret.data.status == 'success') {
        dispatch({ type: TranslateConstant.REGIST_TRANSLATE_DATA, translate: ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.err));
    }
}

const Modify = (data) => async (dispatch) => {
    const adminId = JSON.parse(localStorage.getItem('user')).adminid;
    const ret = await axios.post(AppConfig.API_SERVER_PATH + "/translate", { type: 'modify', data, who: adminId }, JWT());

    if (ret.data.status == 'success') {
        dispatch({ type: TranslateConstant.MODIFY_TRANSLATE_DATA, translate: ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.err));
    }
}

const GetAll = () => async (dispatch) => {
    const ret = await axios.get(AppConfig.API_SERVER_PATH + "/translate/", JWT());

    if (ret.data.status == 'success') {
        dispatch({ type: TranslateConstant.GET_TRANSLATE_DATA, translate: ret.data.data });
    }
    else
        dispatch(AlertAction.Error(ret.data.err));
}

const SearchByLanguageDirect = async (language) => {
    const aggregate = [];
	aggregate.push({ '$match' : { isDel: false, language: language } });
	aggregate.push({
		'$project': {
            _id : 0,
			title: 1,
			language: 1,
			contents: 1
		}
	});
	const data = {
		data : {
			type: "translates",
			aggregate,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
    //const ret = await axios.get(AppConfig.API_SERVER_PATH + "/translate/" + language);
    if (ret.data.status == 'success')
        return ret.data.data;
}

const SearchByLanguage = () => async (dispatch) => {
    const user = localStorage.getItem('user');
    const language = JSON.parse(user).rguardSettingLanguage;

    const ret = await axios.get(AppConfig.API_SERVER_PATH + "/translate/" + language);
    if (ret.data.status == 'success') {
        dispatch({ type: TranslateConstant.SEARCH_TRANSLATE_DATA, translate: ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.err));
    }
}

export const TranslateAction = {
    Create,
    Modify,
    GetAll,
    SearchByLanguage,
    SearchByLanguageDirect
};
