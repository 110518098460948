import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import { CalendarConstant } from '../../actions/constants/CalendarConstant';
import { CalendarAction } from '../../actions';
import ScheduleCreate from '../../components/schedule/ScheduleCreate';
import { Translate as t } from '../../config/Translate';

const AdminCalendar = ({ items, GetList }) => {

    const [month, setMonth] = useState(moment().format("YYYY-MM-DD"))
    const [sched, setSched] = useState([])
    const [showAS, setShowAS] = useState(false);
    const [height, setHeight] = useState(window.innerHeight - 240);
    const dispatch = useDispatch();

    useEffect(() => {
        window.onresize = updateHeight;
    }, [])

    useEffect(() => {
        var arr = []
        items.map(x => {
            arr.push({
                id: x._id,
                title: (x.title ? "[" + x.title + "]" : "") + (x.sitename ? x.sitename : ''),
                start: x.start,
                end: x.end
            })
        })
        setSched(arr);
    }, [items])

    useEffect(() => {
        console.log(sched);
    }, [sched]);

    const updateHeight = () => {
        setHeight(window.innerHeight - 200);
    }

    const changedMonth = (e) => {
        var start = moment.utc(e.start).valueOf();
        var end = moment.utc(e.end).valueOf();
        GetList({
            start: { '$gte': start },
            start: { '$lte': end },
        });
    }

    const selectSche = (e) => {
        var f = items.filter(x => x._id == e.event.id);
        if (f.length > 0) {
            dispatch({ type: CalendarConstant.SET_NEW, item: f[0] });
            setShowAS(true);
        }
    }

    const ASNew = (e) => {
        dispatch({
            type: CalendarConstant.SET_NEW, item: {
                type: "Pre-install",
                color: "bg-warning",
                start: moment(e.date).clone().valueOf(),
                finish: moment(e.date).clone().add(4, "hours").valueOf()
            }
        });
        setShowAS(true);
    }

    return (
        <>
            <div className="extraHeader pb-0">
                <h2 className="mb-0">{t('calendar')}</h2>
                <div className="ray-right">
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height pb-0" style={{ background: "white" }}>
                <div className="section mt-1 mb-1">
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        dayMaxEvents={true}
                        initialDate={month}
                        contentHeight={height}
                        eventColor={"black"}
                        dateClick={e => ASNew(e)}
                        eventClick={e => selectSche(e)}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short'
                        }}
                        initialView="dayGridMonth"
                        datesSet={e => changedMonth(e)}
                        events={sched}
                    />
                    <ScheduleCreate isFull={false} showAS={showAS} callback={() => setShowAS(false)} />
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    const items = state.CalendarReducer.items;
    return { items };
}

const mapDispatch = dispatch => ({
    GetList: (filter) => dispatch(CalendarAction.GetAllByFilter(filter)),
})

export default connect(mapState, mapDispatch)(AdminCalendar);
