import React, { useState, useEffect } from 'react';
import { history } from '../../history';
import { AuthAction, TranslateAction } from '../../actions';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";

import '../../asset/css/admin.css';

const AdminLoginPage = ({ user, loggingIn, isLoading, isAdmin, login }) => {
	const [inputs, setInputs] = useState({ username: '', password: '' });
	const { username, password } = inputs;
	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		if (loggingIn) {
			if (user.rguardSettingLanguage) {
				TranslateAction.SearchByLanguageDirect(user.rguardSettingLanguage).then(translate => {
					localStorage.setItem('translate', JSON.stringify(translate));
				});
			} else {
				TranslateAction.SearchByLanguageDirect('eng').then(translate => {
					localStorage.setItem('translate', JSON.stringify(translate));
				});
			}

			history.push('/Admin/Dashboard');
		}
	}, [loggingIn]);

	useEffect(() => { 
		console.log('isLoading==============================');
		console.log(isLoading);
	}, [isLoading])

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const handleSubmit = (e) => {
		setSubmitted(true);
		e.preventDefault();
		if (username && password) {
			login(username, password);
		}
	};

	return (
		<div className="row">
			<div className="col-md-4"></div>
			<div className="col-md-4" style={{ paddingTop: "52px" }}>
				<div className="card bg-light border-info" style={{ border: "1px solid" }}>
					<div className="card-header">
						<h2 className="card-title text-center mb0">ADMIN LOGIN</h2>
					</div>
					<div className="card-body">
						<form method="post" className="form" onSubmit={handleSubmit}>
							<div className="form-group form-group-default">
								<label className={username ? "fade" : ""}>Username</label>
								<input type="text" className="form-control input-md"
									name="username"
									value={username}
									autoComplete="off"
									onChange={handleChange} />
							</div>
							<div className="form-group form-group-default">
								<label className={password ? "fade" : ""}>Password</label>
								<input type="password" className="form-control input-md"
									name="password"
									value={password}
									autoComplete="off"
									onChange={handleChange} />
							</div>
						</form>
						{(submitted && !username && <div className="alert">username is required.</div>) ||
							(submitted && !password && <div className="alert">password is required.</div>)}
						<button type="button" className="btn btn-primary btn-block" onClick={handleSubmit}> 
						{
							isLoading &&
							<Loader
								type='TailSpin'
								color='#fff'
								width={20}
								height={20}
								visible={true}								
								style={{ display: 'inline-block', marginRight: '10px' }} />
						}
						로그인 </button>
					</div>
				</div>
			</div>
			<div className="col-md-4"></div>
		</div>
	);
};

const mapState = (state) => {
	return {
		loggingIn: state.AuthReducer.loggingIn,
		user: state.AuthReducer.user,
		isLoading: state.AuthReducer.isLoading
	};
};

const mapDispatch = (dispatch) => ({
	login: (username, password, isAdmin) => dispatch(AuthAction.Login(username, password, true)),
	logout: () => dispatch(AuthAction.Logout()),
});

export default connect(mapState, mapDispatch)(AdminLoginPage);
