import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SiteAction } from '../../actions/SiteAction';
import { RayForm, RayFormValidation } from '../../components/common';
import { history } from '../../history';
import { SpecialityTypes } from '../../config/RayCodes';
import { CountryCode } from '../../config';

const AdminSiteAdd = ({ site, AddSite }) => {

    const [isAddProcess, setIsAddProcess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [newsite, setNewSite] = useState({
        speciality: SpecialityTypes[0].code,
        country: CountryCode[0].code
    });

    useEffect(() => {
        if (isAddProcess) {
            history.push("/Admin/AdminSiteList");
        }
    }, [site])

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setNewSite({ ...newsite, [name]: value });
    }

    const form_controls = [
        {
            title: "Site Information", items: [
                { type: "input", name: "sitename", label: "Site Name", val: newsite.sitename, required: true },
                { type: "select", name: "speciality", label: "Speciality", val: newsite.speciality, list: SpecialityTypes }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "tel", label: "Tel", val: newsite.tel },
                { type: "tel", name: "fax", label: "Fax", val: newsite.fax }
            ]
        },
        {
            title: "", items: [
                { type: "email", name: "email", label: "Email", val: newsite.email },
                { type: "tel", name: "hp", label: "Mobile", val: newsite.hp }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "address", label: "Address", size: 12, val: newsite.address },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "addressExtra", label: "Address Extra", size: 12, val: newsite.addressExtra }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "zipcode", label: "Zip", val: newsite.zipcode },
                { type: "select", name: "country", label: "Country", val: newsite.country, list: CountryCode }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "website", label: "WebSite", val: newsite.website, size: 12 }
            ]
        },
        {
            title: "Customer Information", items: [
                { type: "input", name: "customerFirstName", label: "First Name", val: newsite.customerFirstName },
                { type: "input", name: "customerLastName", label: "Last Name", val: newsite.customerLastName }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "customerHp", label: "Customer Mobile", val: newsite.customerHp },
                { type: "email", name: "customerEmail", label: "Customer Email", val: newsite.customerEmail }
            ]
        }
    ]

    const validAdd = () => {
        const errValidate = RayFormValidation(form_controls, newsite);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            setIsAddProcess(true);
            AddSite(newsite);
        }
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Add New Site</h2>
                <div className="ray-right">
                    <Link to="/Admin/AdminSiteList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <RayForm
                    controls={form_controls}
                    isForm={true}
                    handleChangeData={handleChangeData}
                    errors={errors} />
                <div className="section">
                    <button className="btn btn-primary" onClick={() => validAdd()}>Add</button>
                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    const site = state.SiteReducer.site;
    return { site };
}

const mapDispatch = dispatch => ({
    AddSite: (site) => dispatch(SiteAction.AddSite(site)),
})
export default connect(mapState, mapDispatch)(AdminSiteAdd);