import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ProductAction, AlertAction, AuthAction } from '../../actions';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { RayForm, RayFormValidation } from '../common';
import { DeviceModels } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';

const ProductErrorInfo = ({ errorCodeInfo, sn, productErrorCodeId, nocard = false }) => {
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [contols, setControls] = useState([]);
	const dispatch = useDispatch();

	const callbackclick = (v) => {
		dispatch({ type: ProgramConstant.ERRORCODELOOKUP_SHOW, eCode: v })
	}
	const showKey = [
		{ label: t('errorcode'), value: "code", link: isAdmin ? "/Admin/AdminErrorCodeInfo/" : "ERRORCODELOOKUP", callbackclick: callbackclick },
		{ label: t('begin'), value: "begin", type: "DT" },
		{ label: t('end'), value: "end", type: "DT" },
		{ label: t('durationtime'), value: "duration" },
		{ label: t('closer'), value: "closer" },
		{ label: t('customerfeedback'), value: "commentForCustomer" },
		{ label: t('description'), value: "description" },
		{ label: t('registered'), value: "timestamp", type: "DT" }
	]

	const getProductError = async () => {
		errorCodeInfo = await ProductAction.GetProductErrorId(sn, productErrorCodeId);
		setUpControl();
	}

	const getVal = (x) => {
		if (x.value == "duration") {
			return moment.duration(parseInt(errorCodeInfo[x.value]), 'seconds').humanize();
		} else if (x.type == "DT") {
			var rList = [];
			rList.push(moment.utc(errorCodeInfo[x.value]).local().fromNow());
			rList.push(<span className="text-muted small">({moment.utc(errorCodeInfo[x.value]).local().format('MM/DD HH:mm')})</span>);
			return rList;
		} else {
			return errorCodeInfo[x.value];
		}
	}

	const setUpControl = () => {
		var objs = [];
		showKey.map(x => {
			if (errorCodeInfo[x.value]) {
				var obj = {
					title: '', items: [{
						type: 'input',
						name: x.value,
						label: x.label,
						viewLinkPath: x.link,
						callbackclick: x.callbackclick,
						val: getVal(x),
						size: 12
					}]
				};
				objs.push(obj);
			}
		});
		//objs[0].title = "Error Information";

		setControls(objs);
	}

	useEffect(() => {
		if (errorCodeInfo) {
			setUpControl();
		} else if (productErrorCodeId) {
			getProductError();
		}
	}, []);

	return <RayForm controls={contols} nocard={nocard} />;
};

export default ProductErrorInfo;