import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ServiceAction, AdminUserAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';

export const ServiceJiraInfo = ({ issueInfo, nocard = false }) => {

	const getDescription = () => {
		var ret = [];
		issueInfo.fields.description.content.map(x => {
			x.content.map(y => ret.push(y.text))
		})
		return ret.join("\n");
	}

	const form_controls = [
		{
			title: "", items: [
				{
					type: "input", name: "creator", label: "Summary",
					directLink: process.env.REACT_APP_JIRA_LINK + "browse/" + issueInfo.key, size: 12,
					appendix: issueInfo.fields.project.name,
					val: issueInfo.fields.summary
				},
				{
					type: "textarea", name: "description", label: "Description", size: 12,
					val: getDescription()
				},
				{
					type: "input", name: "status", label: "Status", size: 12,
					jirastatus: issueInfo.fields.status.statusCategory.key,
					val: issueInfo.fields.status.name
				},
				{
					type: "input", name: "assignee", label: "Assignee", size: 12,
					val: issueInfo.fields.assignee.displayName
				},
				{
					type: "input", name: "created", label: "Created", size: 12,
					val: moment(issueInfo.fields.created).fromNow()
				},
				{
					type: "input", name: "updated", label: "Updated", size: 12,
					val: moment(issueInfo.fields.updated).fromNow()
				},
			]
		}
	]

	return (
		<div className="mb-1">
			<h3 className="p-1">Jira</h3>
			<RayForm controls={form_controls} nocard={nocard} />
		</div>
	);
};
