import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { RayForm } from '../../components/common';
import { JiraAction } from '../../actions/JiraAction';
import { ServiceAction } from '../../actions/ServiceAction';

const ServiceModalJiraCreate = ({ service, info, callbackClose, issue, Add, UpdateStatus }) => {

	const [reuqest, setRequest] = useState(false);

	useEffect(() => {
		if (reuqest) {
			console.log(issue);
			UpdateStatus(service._id, { jirakey: issue.key, status: 'Escalate' });
			setRequest(false);
			callbackClose();
		}
	}, [issue])

	const [jira, setJira] = useState({
		summary: "",
		description: ""
	});

	const form_controls = [
		{
			title: "", items: [
				{ type: "input", name: "summary", label: "Summary", val: jira.summary, required: true },
				{ type: "textarea", name: "description", label: "Description", val: jira.description, required: true },
			]
		}
	]

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setJira({ ...jira, [name]: value });
	};

	const handleSubmit = () => {
		if (jira.summary && jira.description) {
			var data = {
				fields: {
					project: { key: process.env.REACT_APP_JIRA_PROJECT_KEY },
					summary: jira.summary,
					description: {
						type: 'doc',
						version: 1,
						content: [{
							type: "paragraph",
							content: [{
								text: jira.description,
								type: "text"
							}]
						}]
					},
					issuetype: {
						name: process.env.REACT_APP_JIRA_PROJECT_ISSUETYPE_NAME
					},
				}
			}
			data["fields"][process.env.REACT_APP_JIRA_SERVICE_LINK_CUSTOMFILEDID] = process.env.REACT_APP_SITE_URL + "/Admin/AdminService/" + service.ticketKey
			setRequest(true);
			Add(data);
		}
	}

	return (
		<Modal show={info.showModal} onHide={() => callbackClose()}>
			<Modal.Header>
				<h2 className="modal-title">{info.title}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<RayForm
					controls={form_controls}
					isForm={true}
					nocard={true}
					handleChangeData={handleChangeData} />
			</Modal.Body>
			<Modal.Footer>
				<button className={reuqest ? "btn btn-block btn-primary disabled" : "btn btn-block btn-primary"} onClick={() => handleSubmit()}>Submit</button>
				<button className={reuqest ? "btn btn-block btn-dark disabled" : "btn btn-block btn-dark"} onClick={() => callbackClose()}>Close</button>
			</Modal.Footer>
		</Modal>
	);
}

const mapState = (state) => {
	const issue = state.JiraReducer.issue;
	return { issue };
};

const mapDispatch = (dispatch) => ({
	Add: (data) => dispatch(JiraAction.Add(data)),
	UpdateStatus: (_id, data) => dispatch(ServiceAction.UpdateStatus(_id, data))
});

export default connect(mapState, mapDispatch)(ServiceModalJiraCreate);