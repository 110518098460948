import React, { useEffect, useState } from 'react';
import { DeviceModels } from '../../config';
import { InputBasicSelect } from "../../components/common/Inputs";
import { connect } from 'react-redux';
import { RayCodeAction, GuideAction } from '../../actions';
import GuideSettingWizard from '../../components/guide/GuideSettingWizard';
import { Translate as t } from '../../config/Translate';

const InstallationGuide = ({ user, clist }) => {
    const [codes, setCodes] = useState([]);
    const [models, setModels] = useState([]);
    const [search, setSearch] = useState({
        modelName: '',
        optionName: ''
    });
    const [result, setResult] = useState({
        guideList: [],
        cephList: []
    });

    useEffect(() => {
        const modelName = !user.isMegagen ? DeviceModels[0].code : 'R2 Studio Q';
        setSearch({ modelName, optionName: '' });
        setCodes(clist?.filter(x => x.type === 'PRODUCTOPTION' && x.group === modelName));
        //RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION", group: modelName }).then(x => setCodes(x));
    }, [clist]);

    useEffect(() => {
        if (!user.isMegagen)
            setModels(DeviceModels);
        else {
            setModels(DeviceModels.filter(x => x.code == "R2 Studio Q"));
        }
    }, [DeviceModels]);

    useEffect(() => {
        setResult({ ...result, guideList: [], cephList: [] });
        searchGuideList();
    }, [search]);

    const handleChangeData = (e) => {
        setCodes(clist?.filter(x => x.type === 'PRODUCTOPTION' && x.group === e.target.value));
        //RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION", group: e.target.value }).then(x => setCodes(x));
        if (codes.length > 0)
            setSearch({ ...search, modelName: e.target.value, optionName: codes[0].code });
    }

    const handlechangeOptionData = (e) => {
        setSearch({ ...search, modelName: search.modelName, optionName: e.target.value });
    }

    const searchGuideList = async () => {
        const list = await GuideAction.GetList(search.modelName, "settingwizard", "eng" || "eng");
        if (list.length > 0) {
            const lists = list[0].attachments.filter(x => x.ceph == "NOCEPH");
            const sublists = list[0].attachments.filter(x => x.ceph != "NOCEPH" && x.ceph == search.optionName) // option - catagory
            setResult({ ...result, guideList: lists, cephList: sublists.length > 0 ? sublists : [] });
        }
    }

    return (
        <>
            <div className="card mt-2 mb-2">
                <div className="card-body">
                    <InputBasicSelect
                        options={{ name: "model", val: search.modelName, list: models, label: t('model'), size: "full" }}
                        isForm={true}
                        editable={true}
                        onchange={handleChangeData} />
                    <div className="form-group basic" aria-required="true">
                        <label className="label">{t('option')}</label>
                        {codes.length > 0 && <select className="form-control" name="option"
                            onChange={(e) => handlechangeOptionData(e)}>
                            {codes.map(x => <option value={x.category}>{x.name}</option>)}
                        </select>}
                        {codes.length == 0 && <div className="alert">No Data</div >}
                    </div>
                </div>
            </div>
            <button className="btn btn-outline-primary btn-block" hidden onClick={searchGuideList}>{t('search')}</button>
            <GuideSettingWizard result={result} search={search} />
        </>
    )
}


const mapState = state => {
    const user = state.AuthReducer.user;
	const clist = state.RayCodeReducer.items;
    return { user, clist };
}

export default connect(mapState)(InstallationGuide);