import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CommonAction } from '../../actions/CommonAction';
import { DeviceModels } from '../../config';
import ProductView from '../../pages/product/ProductView';
import { Translate as t } from '../../config/Translate';

const GoogleMaps = ({ onMapLoad, options, height = 300, Lat, Lng, markerList,
	callback, callbackRandomMove, posClick, pin }) => {

	const [mapCluster, setmapCluster] = useState({});
	const [map, setMap] = useState({});
	const zoom = 16;

	useEffect(() => {
		onScriptLoad();
	}, [Lat, Lng]);

	useEffect(() => {
		reloadMaker();
	}, [markerList]);

	useEffect(() => {
		if (pin) {
			map.panTo(mapCluster.markers_[pin].getPosition());
			map.zoom != zoom && map.setZoom(zoom);
			posClick(mapCluster.markers_[pin]._id, mapCluster.markers_[pin].sn);
			setTimeout(function () {
				callbackRandomMove();
			}, 10000);
		}
	}, [pin]);

	const reloadMaker = () => {
		if (Object.keys(mapCluster).length > 0) {
			mapCluster.clearMarkers();

			const markers = markerList.map((location) => {
				var marker = new window.google.maps.Marker({
					position: location,
					label: { fontWeight: 'bold', fontSize: '14px', text: location.title },
					_id: location._id,
					sn: location.sn,
				});
				marker.addListener('click', function () {
					map.panTo(marker.getPosition());
					map.zoom != zoom && map.setZoom(zoom);
					posClick(marker._id, marker.sn);
				});
				return marker;
			});

			mapCluster.addMarkers(markers);
			setmapCluster(mapCluster);

			if (markerList.length == 1) {
				map.panTo(markers[0].getPosition());
				map.zoom != zoom && map.setZoom(zoom);
				posClick(markers[0]._id, markers[0].sn);
			} else {
				map.panTo(new google.maps.LatLng(25.904949, 152.035368));
				map.setZoom(2.8);
			}
		}
	}

	const onScriptLoad = async () => {
		const map = new window.google.maps.Map(
			document.getElementById("myMapGoogle"),
			options,
			options.center = new google.maps.LatLng(0, 152),
		);
		if (!map) {
			callback(false);
			return;
		} else {
			callback(true);
		}

		if (markerList) {
			if (Object.keys(mapCluster).length > 0) 
				mapCluster.clearMarkers();

			const markers = markerList.map((location) => {
				var marker = new window.google.maps.Marker({
					position: location,
					label: { fontWeight: 'bold', fontSize: '14px', text: location.title },
					_id: location._id,
					sn: location.sn,
				});
				marker.addListener('click', function () {
					map.panTo(marker.getPosition());
					map.zoom != zoom && map.setZoom(zoom);
					posClick(marker._id, marker.sn);
				});
				return marker;
			});

			const tmpmapCluster = new window.MarkerClusterer(map, markers, {
				imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
			});

			setmapCluster(tmpmapCluster);

			if (markerList.length == 1) {
				map.panTo(markers[0].getPosition());
				map.zoom != zoom && map.setZoom(zoom);
				posClick(markers[0]._id, markers[0].sn);
			} else {
				map.panTo(new google.maps.LatLng(25.904949, 152.035368));
				map.setZoom(2.8);
			}
		}

		setMap(map);
		onMapLoad(map);
	}

	return <div style={{ height: height }} id="myMapGoogle" />
}

const Map = ({ showSub, showChart, automapplay, user }) => {

	const [availGoogle, setAvailGoogle] = useState(true);

	const [showType, setShowType] = useState('installed');
	const [settings, setSettings] = useState({});
	const [marks, setMarks] = useState([]);
	const [isMapOk, setIsMapOk] = useState(true);
	const [height, setvHg] = useState(300);
	const [scope, setScope] = useState("");
	const [device, setDevice] = useState("");
	const [search, setSearch] = useState("");
	const [aggre, setAggre] = useState({});
	const [map, setMap] = useState({});
	const [sn, setSN] = useState("");
	const [timer, setTimer] = useState({});
	const [pin, setPin] = useState(null);
	const [isPlay, setIsPlay] = useState(automapplay || false);
	const [taiwanSales, setTaiwanSales] = useState(false);

	const getHeight = () => setvHg(window.innerHeight);
	
	const show = (type) => {
		setShowType(type);
		setSN("");
	}

	useEffect(() => {
		setAvailGoogle(!window.google ? false : true);
	}, []);

	useEffect(() => {
		let interval;

		if (!interval) {
			if (showType.indexOf('service') > -1)
				makeAggreService(showType);
			else
				makeAggre(showType);
		}

		interval = setInterval(() => {
			if (showType.indexOf('service') > -1)
				makeAggreService(showType);
			else
				makeAggre(showType);
		}, 1000 * 60 * 10); // 10min

		return () => {
			clearInterval(interval);
		}
	}, [showType, device, scope]);

	useEffect(() => {
		setSettings(user.settings);
		setTaiwanSales(user.companyId === 'RAY00016' && user.adminType === 'U' && user.function === 'Sales')
	}, [user]);

	useEffect(() => {
		if (isMapOk) {
			if (!automapplay)
				return;

			setTimeout(function () {
				randomMove();
			}, 5000);
		}
	}, [isMapOk, automapplay])

	useEffect(() => {
		setDevice(device);
	}, [device])

	useEffect(() => {
		getItemData(aggre);
	}, [aggre]);

	useEffect(() => {
		window.addEventListener('resize', getHeight);
		setvHg(window.innerHeight);
	}, [])

	const mapOk = (bl) => {
		setIsMapOk(bl);
	}

	const makeAggreService = (showType) => {
		var ret = {
			type: 'product'
		}
		var aggrList = [];
		aggrList.push({ '$match': { 'siteId': { '$exists': true } } });
		aggrList.push({ '$match': { 'siteId': { '$ne': "" } } });
		aggrList.push({
			$lookup: {
				from: 'services',
				localField: 'sn',
				foreignField: 'sn',
				as: 'service'
			}
		})
		aggrList.push({
			$addFields: {
				'service': { "$arrayElemAt": ["$service", 0] }
			}
		});
		if (device)
			aggrList.push({ '$match': { model: device } });

		if (showType == "servicenew") {
			aggrList.push({ '$match': { 'service.status': { '$ne': 'Close' } } });
			if (scope) {
				aggrList.push({
					'$match': {
						'service.createDate': {
							'$gte': moment
								.utc(moment().utc())
								.add(-1 * scope, 'days').unix() * 1000
						}
					}
				});
			}
		}
		if (showType == "serviceoverdue") {
			aggrList.push({ '$match': { 'service.status': { '$ne': 'Close' } } });
			if (scope) {
				aggrList.push({
					'$match': {
						'service.createDate': {
							'$gte': moment
								.utc(moment().utc())
								.add(-1 * (settings.dsservicecardduration || 30), 'days').unix() * 1000
						}
					}
				});
			}
		}
		aggrList.push({
			$lookup: {
				from: 'sites',
				localField: 'siteId',
				foreignField: 'siteId',
				as: 'site'
			}
		})
		aggrList.push({
			$addFields: {
				'site': { "$arrayElemAt": ["$site", 0] }
			}
		});
		aggrList.push({
			$project: {
				'sn': 1,
				'site._id': 1,
				'site.lat': 1,
				'site.lng': 1,
				'site.sitename': 1,
			},
		});
		ret["aggregate"] = aggrList;

		setAggre(ret);
	}

	const makeAggre = () => {
		var ret = {
			type: 'product'
		}
		var aggrList = [];
		aggrList.push({ '$match': { 'siteId': { '$exists': true } } });
		aggrList.push({ '$match': { 'siteId': { '$ne': "" } } });
		if (device)
			aggrList.push({ '$match': { model: device } });
		if (showType == "error") {
			aggrList.push({ '$match': { 'installedDate': { $gt: 1065398400000 }, 'errs.closer': null, 'errs.end': null, } });
			if (scope) {
				aggrList.push({ '$match': { 'errs.begin': { '$gte': moment.utc(moment().utc()).add(-1 * scope, 'days').unix() * 1000 } } });
			}
			
		}
		if (showType == "installed") {
			if (scope)
				aggrList.push({
					'$match': {
						'installedDate': { '$gte': moment.utc(moment().utc()).add(-1 * scope, 'days').unix() * 1000 },
						'siteId': { $ne: '' }
					}
				});
		}
		aggrList.push({
			$lookup: {
				from: 'sites',
				localField: 'siteId',
				foreignField: 'siteId',
				as: 'site'
			}
		})
		aggrList.push({
			$addFields: {
				'site': { "$arrayElemAt": ["$site", 0] }
			}
		});
		aggrList.push({
			$project: {
				'sn': 1,
				'site._id': 1,
				'site.lat': 1,
				'site.lng': 1,
				'site.sitename': 1,
			},
		});
		ret["aggregate"] = aggrList;

		setAggre(ret);
	}

	const getItemData = async (query) => {
		if (query.aggregate) {
			var list = [];
			const ret = await CommonAction.GetList(query);
			var i = 0;
			ret.data.map(x => {
				if (x.site) {
					if (x.site.lng && x.site.lat) {
						list.push({
							lat: parseFloat(x.site.lat),
							lng: parseFloat(x.site.lng),
							title: x.site.sitename,
							_id: x.site._id,
							sn: x.sn,
						});
					}
				}
			});
			setMarks(list);
		}
	}

	const dayScopes = [
		{ code: "", title: t('alltime') },
		{ code: "1", title: "Last 1 Day" },
		{ code: "7", title: "Last 1 Week" },
		{ code: "14", title: "Last 2 Weeks" },
		{ code: "30", title: "Last 1 Month" },
		{ code: "60", title: "Last 2 Months" },
		{ code: "90", title: "Last 3 Months" },
	]

	const devices = [{ code: "", title: t('alldevices') }, ...DeviceModels.filter(x => user.isMegagen ? x.code == "R2 Studio Q" : x.code)];

	const posClick = (siteId, sn) => {
		setSN(sn);
	}

	const cancelSearch = () => {
		setSN("");
		setSearch("");
		makeAggre(showType);
	}

	const goSearch = () => {
		if (search) {
			var ret = {
				type: 'product'
			}
			var aggrList = [];
			aggrList.push({ '$match': { 'siteId': { '$exists': true } } });
			aggrList.push({ '$match': { 'siteId': { '$ne': "" } } });
			aggrList.push(
				{
					GEN: "LIKESEARCH",
					COLS: ["sn", "sitename"],
					VAL: search
				});
			aggrList.push({
				$lookup: {
					from: 'sites',
					localField: 'siteId',
					foreignField: 'siteId',
					as: 'site'
				}
			})
			aggrList.push({
				$addFields: {
					'site': { "$arrayElemAt": ["$site", 0] }
				}
			});
			aggrList.push({
				$project: {
					'sn': 1,
					'site._id': 1,
					'site.lat': 1,
					'site.lng': 1,
					'site.sitename': 1,
				},
			});
			ret["aggregate"] = aggrList;
			setAggre({ ...ret });
		}
	}

	useEffect(() => {
		makeAggre(showType);
	}, []);

	const randomMove = () => {
		if (automapplay && marks.length > 1 && !showSub) {
			const num = Math.floor(Math.random() * (marks.length - 1 - 0) + 0);
			setPin(num);
		}
	}

	return (
		<div className="section p-0">
			{showSub && <div className="section ps-1 pe-1 bg-default">
				<div className="float-start mb-1 mt-1">
					<div className="input-group input-group-sm">
						<input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} />
						<button className="btn btn-primary mt-0" onClick={() => goSearch()}>{t('search')}</button>
						<button className="btn btn-secondary mt-0" onClick={() => cancelSearch()}>{t('clear')}</button>
					</div>
				</div>
				<div className="float-end mb-1 mt-1">
					<div className="input-group input-group-sm">
						<select
							onChange={(e) => setDevice(e.target.value)}
							value={device}
							className="form-control">
							{devices.map(x => <option value={x.code} key={x.code}>{x.title}</option>)}
						</select>
						<select
							onChange={(e) => setScope(e.target.value)}
							value={scope}
							className="form-control" >
							{dayScopes.map(x => <option value={x.code} key={x.code}>{x.title}</option>)}
						</select>
						<button className={`btn btn-${showType === 'installed' ? 'primary' : 'dark'} mt-0`} onClick={() => show("installed")}>{t('installed')}</button>
						<button className={`btn btn-${showType === 'servicenew' ? 'primary' : 'dark'} mt-0`} onClick={() => show("servicenew")}>{t('servicenew')}</button>
						<button className={`btn btn-${showType === 'serviceoverdue' ? 'primary' : 'dark'} mt-0`} onClick={() => show("serviceoverdue")}>{t('serviceoverdue')}</button>
						<button className={`btn btn-${showType === 'error' ? 'primary' : 'dark'} mt-0`} onClick={() => show("error")}>{t('error')}</button>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>}
			<div className="section ps-0 pe-0">
				{
					availGoogle &&
					<GoogleMaps
						onMapLoad={(map) => { setMap(map); }}
						callback={(bl) => mapOk(bl)}
						posClick={(siteId, sn) => posClick(siteId, sn)}
						markerList={marks}
						pin={pin}
						callbackRandomMove={randomMove}
						height={height - (showSub ? 130 : 65)}
						options={{
							zoom: 2.2,
							draggable: true,
							mapTypeControl: false,
							streetViewControl: false,
							rotateControl: false,
							scaleControl: false,
							zoomControl: false,
							fullscreenControl: false
						}}
					/>
				}
				
				{(sn && !taiwanSales) && <ProductView _sn={sn} />}
			</div>
		</div>
	);
};

const mapState = (state) => {
	var showSub = state.ProgramReducer.showSub;
	var showChart = state.ProgramReducer.showChart;
	var chartType = state.ProgramReducer.chartType;
	var automapplay = state.ProgramReducer.automapplay;
	var user = state.AuthReducer.user;
	return { showSub, showChart, chartType, automapplay, user };
};

export default connect(mapState)(Map);
