import { ProductConstant } from '../actions/constants/ProductConstant';

const initialState = {
	products: [],
	orisninfo: {},
	statistics: [],
	savedFilters: [],
	producterrasshow: false,
	filterOptions: null,
	filterDateOptions: null,
	filters: null,
	dsfilters: [],
	sort: null,
	producterrs: [],
	producterrsmatch: [],
	producterr: { errs: {} },
	producterrasshow: false,
	filterErrOptions: null,
	filterErrDateOptions: null,
	errfilters: null,
	errsort: null,
	page: 1,
	errpage: 1,
	viewtype: "All",
	totalproductcount: 0,
	totalproducterrcount: 0,
	product: {},
	isUpdateRes: false,
	isLoading: false,
	isErrLoading: false,
};

export const ProductReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProductConstant.UPDATE_DSFILTER:
			return { ...state, dsfilters: action.dsfilters };
		case ProductConstant.SET_VIEWTYPE_STOCK:
			return { ...state, viewtype: "Stock" };
		case ProductConstant.SET_VIEWTYPE_ALL:
			return { ...state, viewtype: "All" };
		case ProductConstant.GET_FAV_PRODUCTLIST:
		case ProductConstant.GET_PRODUCTLIST:
			return {
				...state,
				products: (action.append && action.result.page != 1) ? [...state.products, ...action.result.list] : action.result.list,
				totalproductcount: action.result.totalcount,
				filterOptions: action.result.filterOptions,
				filterDateOptions: action.result.filterDateOptions,
				filters: action.result.filters,
				page: action.result.page,
				sort: action.result.sort,
				viewtype: action.vtype,
				isUpdateRes: false
			};
		case ProductConstant.GET_ERRORS:
			return {
				...state,
				producterrs: (action.append && action.result.page != 1) ? [...state.producterrs, ...action.result.list] : action.result.list,
				totalproducterrcount: action.result.totalcount,
				filterErrOptions: action.result.filterOptions,
				filterErrDateOptions: action.result.filterErrDateOptions,
				producterrsmatch: action.result.match || {},
				errfilters: action.result.filters,
				errsort: action.result.sort,
				errpage: action.result.page,
				isUpdateRes: false
			};
		case ProductConstant.GET_ERROR:
			return {
				...state,
				producterr: action.result.data,
				isUpdateRes: false
			};
		case ProductConstant.GET_STATISTICS:
			return { ...state, statistics: action.result }
		case ProductConstant.GET_SAVED_FILTERS:
			return { ...state, savedFilters: action.result }
			return {
				...state,
				producterrs: (action.append && action.result.page != 1) ?
					[...state.producterrs, ...action.result.list] :
					action.result.list,
				totalproducterrcount: action.result.totalcount,
				filterErrOptions: action.result.filterOptions,
				errfilters: action.result.filters,
				sort: action.result.sort,
				isUpdateRes: false
			};
		case ProductConstant.BULKUPDATE_PRODUCTINFO:
			return {
				...state,
				products: state.products.map((u) => {
					const item = action.products.filter(p => p.sn == u.sn);
					return item.length > 0 ? item[0] : u;
				}),
				isUpdateRes: true,
			};
		case ProductConstant.UPDATE_PRODUCTERRORINFO:
			if (action.result.list.length > 0) {
				const item = action.result.list[0];
				const newproducts = action.isClose ?
					state.producterrs.filter((u) => (u.errs._id != item.errs._id)) :
					state.producterrs.map((u) => (u.errs._id == item.errs._id ? item : u))
				return {
					...state,
					producterrs: newproducts,
					producterr: { errs: {} },
					producterrasshow: false
				};
			} else {
				return {
					...state,
					producterr: { errs: {} },
					producterrasshow: false
				};
			}
		case ProductConstant.SET_ERROREDIT:
			return {
				...state,
				producterr: action.err,
				producterrasshow: action.errshow,
			};
		case ProductConstant.SET_ERROREDIT_HIDE:
			return {
				...state,
				producterr: { errs: {} },
				producterrasshow: false,
			};
		case ProductConstant.UPDATE_PRODUCTINFO:
			return {
				...state,
				products: state.products.map((u) => (u._id == action.product._id ? action.product : u)),
				product: action.product,
				isUpdateRes: true,
			};
		case ProductConstant.GET_PRODUCT_OLD:
			return {
				...state,
				product: {...action.product, old : true},
				orisninfo : action.orisninfo
			};
		case ProductConstant.GET_PRODUCT:
		case ProductConstant.SET_PRODUCT_WARRANTY:
			return {
				...state,
				product: action.product
			};
		case ProductConstant.REGIST_PRODUCTINFO:
			return {
				...state,
				products: [...state.products, action.product],
				product: action.product,
				isUpdateRes: true
			};		
		case ProductConstant.DELETE_PRODUCT:
			return { ...state, products: state.products.filter((u) => u._id !== action.delproductid), isUpdateRes: true };
		case ProductConstant.CLEAR_UPDATERES:
			return { ...state, isUpdateRes: false };
		case ProductConstant.LOADING:
			return { ...state, isLoading: true }
		case ProductConstant.LOADED:
			return { ...state, isLoading: false }
		case ProductConstant.ERR_LOADING:
			return { ...state, isErrLoading: true }
		case ProductConstant.ERR_LOADED:
			return { ...state, isErrLoading: false }
		default:
			return state;
	}
};
