import { InstallationConstant } from '../actions/constants/InstallationConstant';

const initialState = {
    installations : [],
    installation : {},
    userTraining : {},
    filterDateOptions : null,
    totalInstallationCount : 0,
    isLoading : false
};
export const InstallationReducer = (state = initialState, action) => {
    switch (action.type) {
        case InstallationConstant.GET_INSTALLATIONLIST:
            return {
                ...state, 
                installations : (action.append && action.result.page != 1) ? 
                    [...state.installations, ...action.result.list] : 
                    action.result.list,
                filterOptions : action.result.filterOptions,
                filterDateOptions : action.result.filterDateOptions,
                totalInstallationCount : action.result.totalcount
            };
        case InstallationConstant.GET_INSTALLATION:
            return {...state, installation : action.installation };
        case InstallationConstant.NEW:
            var user = JSON.parse(localStorage.getItem('user'));
            console.log(user);
            return {...state, installation : { 
                installerName : user.firstName + " " + user.lastName,
                installationDate : new Date().getTime(),
                installationStep : 1 
            } };
        case InstallationConstant.DELETE:
            return {...state, 
                installations : state.installations.filter(u => u._id !== action.delinstallationid), 
                isUpdateRes : true
            };
        case InstallationConstant.UPDATE:
            return {...state, 
                installations : state.installations.map(u => u._id == action.installation._id ? action.installation : u),
                installation : action.installation
            };
        case InstallationConstant.CREATE:
            return {...state, 
                installation : action.installation
            };
        case InstallationConstant.LOADING:
            return {...state, isLoading : true}
        case InstallationConstant.LOADED:
            return {...state, isLoading : false}
        case InstallationConstant.CLEAR:
            return { ...state, installations : [] };
        case InstallationConstant.INSTALLATION_UPDATE_SIG:
            return {...state, installation : action.installation };
        case InstallationConstant.INSTALLATION_UPDATE_ADD_ATTACHMENT:
            return {...state,
                installations : state.installations.map(u => u._id == action.installation._id ? action.installation : u),
                installation : action.installation
            }
        case InstallationConstant.INSTALLATION_UPDATE_DEL_ATTACHMENT:
            return {...state,
                installations : state.installations.map(u => u._id == action.installation._id ? action.installation : u),
                installation : action.installation
            }
        default:
            return state
    }
}