export const ProductConstant = {
	GET_PRODUCTLIST: 'GET_PRODUCTLIST',
	GET_PRODUCT: 'GET_PRODUCT',
	GET_PRODUCT_OLD: 'GET_PRODUCT_OLD',
	UPDATE_PRODUCTINFO: 'UPDATE_PRODUCTINFO',
	UPDATE_PRODUCTWARRANTY: 'UPDATE_PRODUCTWARRANTY',
	BULKUPDATE_PRODUCTINFO: 'BULKUPDATE_PRODUCTINFO',
	UPDATE_PRODUCTERRORINFO: 'UPDATE_PRODUCTERRORINFO',
	REGIST_PRODUCTINFO: 'REGIST_PRODUCTINFO',
	DELETE_PRODUCT: 'DELETE_PRODUCT',
	CLEAR_UPDATERES: 'CLEAR_UPDATERES',
	UPDATE_FAVS: 'UPDATE_FAVS',
	GET_FAV_PRODUCTLIST: 'GET_FAV_PRODUCTLIST',
	ADD_FAV_PRODUCTINFO: 'ADD_FAV_PRODUCTINFO',
	REMOVE_FAV_PRODUCTINFO: 'REMOVE_FAV_PRODUCTINFO',
	SET_ERROREDIT: 'SET_ERROREDIT',
	SET_ERROREDIT_HIDE: 'SET_ERROREDIT_HIDE',

	SET_VIEWTYPE_ALL: 'SET_VIEWTYPE_ALL',
	SET_VIEWTYPE_STOCK: 'SET_VIEWTYPE_STOCK',

	GET_STATISTICS: 'GET_STATISTICS',
	GET_SAVED_FILTERS: 'GET_SAVED_FILTERS',
	GET_ERRORS: 'GET_PRODUCTERRORS',
	GET_ERROR: 'GET_PRODUCTERROR',
	LOADING: 'PRD_LOADING',
	LOADED: 'PRD_LOADED',
	ERR_LOADING: 'PRDERR_LOADING',
	ERR_LOADED: 'PRDERR_LOADED',

	UPDATE_DSFILTER: 'PRODUCT_UPDATE_DSFILTER',

	SET_PRODUCT_WARRANTY: 'SET_PRODUCT_WARRANTY'
};