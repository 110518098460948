import { ServiceConstant } from '../actions/constants/ServiceConstant';

const initialState = {
	services: [],
	prdservices: [],
	filterOptions: [],
	dsfilters: [],
	filterDateOptions: null,
	totalservicecount: 0,
	service: {
		comments: [],
		histories: [],
		product: {}
	},
	isUpdateRes: false,
	isLoading: false,
	servicematch:[]
};

export const ServiceReducer = (state = initialState, action) => {
	switch (action.type) {
		case ServiceConstant.UPDATE_DSFILTER:
			return { ...state, dsfilters: action.dsfilters };
		case ServiceConstant.SERVICE_GET_ALL:
			return {
				...state,
				services: (action.append && action.result.page != 1) ? [...state.services, ...action.result.list] : action.result.list,
				filterDateOptions: action.result.filterDateOptions,
				totalservicecount: action.result.totalcount,
				filterOptions: action.result.filterOptions,
				servicematch: action.result.match || {},
				isUpdateRes: false,

			};
		case ServiceConstant.SERVICE_PRODUCT_GET_ALL:
			return { ...state, prdservices: action.result };
		case ServiceConstant.SERVICE_GET_ONE:
			return {
				...state,
				service: action.service
			};
		case ServiceConstant.SERVICE_UPDATE_INFO_BULK:
			return {
				...state,
				services: state.services.map((u) => {
					const item = action.services.filter(p => p._id == u._id);
					return item.length > 0 ? item[0] : u;
				}),
				isUpdateRes: true,
			};
		case ServiceConstant.SERVICE_UPDATE_INFO:
			return {
				...state,
				services: state.services.map((u) => (u._id == action.service._id ? action.service : u)),
				prdservices: state.prdservices.map((u) => (u._id == action.service._id ? action.service : u)),
				service: action.service,
				isUpdateRes: true,
			};
		case ServiceConstant.SERVICE_CREATE:
			if (action.service.productErrorCodeId) {
				//console.log(state.errorCodes);
				//state.errorCodes.map(u => u._id == action.service.productErrorCodeId ? 
				//	action.errorCode.serviceKey = action.service.ticketKey :
				//	u)
			}
			return {
				...state,
				services: [...state.services, action.service],
				service: action.service,
				isUpdateRes: true
			};
		case ServiceConstant.ADDUPDATE:
			return { ...state, service: action.item };
		case ServiceConstant.SERVICE_DELETE:
			return {
				...state,
				services: state.services.filter((u) => u._id !== action.delserviceId),
				prdservices: state.prdservices.filter((u) => u._id !== action.delserviceId),
				isUpdateRes: true
			};
		case ServiceConstant.SERVICE_CLEAR_UPDATERES:
			return { ...state, isUpdateRes: false };
		case ServiceConstant.LOADING:
			return { ...state, isLoading: true }
		case ServiceConstant.LOADED:
			return { ...state, isLoading: false }
		case ServiceConstant.CLEAER:
			return {
				...state, service: {
					comments: [],
					histories: [],
					product: {}
				}
			}
		case ServiceConstant.SERVICE_GET_REPLACEMENTS:
			return { ...state, partreplacements: action.result };
		default:
			return state;
	}
};
