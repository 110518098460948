import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppConfig } from '../../config';
import axios from 'axios';
import { SiteAction, AlertAction } from '../../actions';
import { history } from '../../history';

const AdminSiteDelete = ({ site, isUpdateRes, DeleteSite, ClearUpdateResult }) => {

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [delCheckResult, setDelCheckResult] = useState({ products: [] });

    useEffect(() => {
        setIsLoading(true)
        if (site._id) {
            SiteAction.GetInstalledProduct(site._id).then((prd) => {
                setDelCheckResult({ ...delCheckResult, products: prd });
                setIsLoading(false)
            });
        }
    }, []);

    useEffect(() => {
        if (isUpdateRes) {
            ClearUpdateResult();
            history.goBack();
        }
    }, [isUpdateRes]);

    const handleDeleteSite = (e) => {
        DeleteSite(site._id);
    }

    return (
        <div className="section">
            {isLoading && <span>Loading...</span>}
            {!isLoading &&
                <>
                    {delCheckResult.products.length > 0 && (
                        <>
                            <div className="card bg-danger text-white m-b-0">
                                <div className="card-header">
                                    <div className="card-title">경고!</div>
                                </div>
                                <div className="card-body">
                                    <h4>아래의 사유로 {site.sitename}을/를 삭제할 수 없습니다</h4>
                                </div>
                            </div>
                            <ul className="list-group">
                                {delCheckResult.products.map(x => {
                                    return <li className="list-group-item"> <Link to={"/Admin/AdminProductInfo/" + x._id}>{x.sn}({x.model})</Link>이/가 이 사이트에 설치되어 있습니다.</li>
                                })}
                            </ul>
                        </>
                    )}
                    {delCheckResult.products.length == 0 && (
                        <>
                            <div className="card bg-danger text-white">
                                <div className="card-header">
                                    <div className="card-title">경고!</div>
                                </div>
                                <div className="card-body">
                                    <h4>즉시 반영됩니다!!!</h4>
                                    <p>이 작업은 되돌릴 수 없는 작업입니다.</p>
                                    <p>정말 삭제하시겠습니까?</p>
                                </div>
                            </div>
                            <button className="btn btn-outline-danger" onClick={handleDeleteSite}>Delete Site</button>
                        </>
                    )}
                </>
            }
        </div>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.SiteReducer.isUpdateRes;
    return { isUpdateRes };
}

const mapDispatch = dispatch => ({
    ClearUpdateResult: () => dispatch(SiteAction.ClearUpdateResult()),
    DeleteSite: (siteid) => dispatch(SiteAction.DeleteSite(siteid))
})

export default connect(mapState, mapDispatch)(AdminSiteDelete);