import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    InputBasicText,
    InputBasicEmail,
    InputBasicTel,
    InputBasicSelect,
    InputBasicNumber,
    InputBasicRadio,
    InputBasicTextArea,
    InputBasicCheckbox,
    InputBasicDateTime,
    InputBasicSelectInput,
    InputCombo,
    InputSelect2,
    InputSelect2Add,
    InputBasicPassword,
    InputSelect2Search
} from "./Inputs";
import InputLabelUser from "./InputLabelUser";

const RayForm = ({ controls, handleChangeData = null, handleUpdateData = null, errors,
    showAllFields = false, isSummary = false, _sn, nocard = false, search = "", showPin = false,
    onpinupdate = null, nosection = false,
    readOnly = false, action = "", showOnly = false, actionkey = "", isForm = false, css = "" }) => {

    const [controlList, setControlList] = useState([]);

    const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
    const showItem = (cont) => {
        if (!cont.isEditable) {
            if (cont.showexist && !cont.val)
                return false;
        }
        else if (cont.hide) {
            return false;
        }

        return true;
    }

    const setControls = () => {
        var list = [];
        var section = [];
        var title = "";

        controls.map(x => {
            if (x.title != "") {
                list.push({ title: title, items: section });
                if (isForm) {
                    section = x.items;
                    title = x.title;
                } else {
                    if (!showAllFields) {
                        section = x.items.filter(x => x.val || x.isEditable);
                        title = x.title;
                    } else {
                        section = x.items;
                        title = x.title;
                    }
                }
            } else {
                if (isForm) {
                    section = section.concat(x.items);
                } else {
                    if (showAllFields) {
                        section = section.concat(x.items);
                    } else {
                        section = section.concat(x.items.filter(x => x.val || x.isEditable));
                    }
                }
            }
        })
        list.push({ title: title, items: section });
        setControlList(list);
    }

    useEffect(() => {
        setControls();
    }, [controls, showAllFields])

    const renderFormControls = (idx, cont) => {
        return (
            <React.Fragment key={`controls_${idx}`}>
                {cont.type == "input" && showItem(cont) && <InputBasicText
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "number" && showItem(cont) && <InputBasicNumber
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "datetime" && showItem(cont) && <InputBasicDateTime
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={false}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "email" && showItem(cont) && <InputBasicEmail
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "tel" && showItem(cont) && <InputBasicTel
                    options={cont}
                    errs={errors}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "password" && <InputBasicPassword
                    options={cont}
                    errs={errors}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "select" && showItem(cont) && <InputBasicSelect
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "selectinput" && showItem(cont) && <InputBasicSelectInput
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "select2" && showItem(cont) && <InputSelect2
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData}
                    isCounrty={cont.isCounrty}
                />}
                {cont.type == "select2add" && showItem(cont) && <InputSelect2Add
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "select2search" && showItem(cont) && <InputSelect2Search
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    isCounrty={cont.isCounrty}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData}
                    oninputchange={cont.oninputchange}
                />}
                {cont.type == "combo" && showItem(cont) && <InputCombo
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "radio" && showItem(cont) && <InputBasicRadio
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    errs={errors}
                    editable={isForm || cont.isEditable}
                    readOnly={readOnly}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "checkbox" && showItem(cont) && <InputBasicCheckbox
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    errs={errors}
                    editable={isForm || cont.isEditable}
                    readOnly={readOnly}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "textarea" && showItem(cont) && <InputBasicTextArea
                    options={cont}
                    action={cont.action || action}
                    actionkey={actionkey}
                    showOnly={showOnly}
                    isForm={isForm}
                    inline={!isAdmin}
                    editable={isForm || cont.isEditable}
                    errs={errors}
                    onpinupdate={onpinupdate}
                    onupdate={handleUpdateData}
                    onchange={handleChangeData} />}
                {cont.type == "labeluser" && showItem(cont) && <InputLabelUser
                    showOnly={showOnly}
                    onpinupdate={onpinupdate}
                    inline={!isAdmin}
                    options={cont} />}
            </React.Fragment>
        );
    }

    const renderNormalForm = (controlList, row, idx, _sn, nocard) => {
        return (
            <div className={nosection ? "" : (controlList[0].title == "" ? "rayform section mt-1" : "rayform section")} key={`rnf_${idx}`}>
                {row.link && <div className="mt-2 mb-2"><Link to={row.link.to}>{row.link.text}</Link></div>}
                {row.title && <div className="section-title">{row.title}</div>}
                {row.items.length > 0 && (
                    <div className={nocard ? "" : "card"}
                        style={_sn && {
                            boxShadow: "4px 4px 4px darkgrey"
                        }}>
                        <div className={nocard ? "p-1" : "card-body pb-1 pt-1"}>
                            <ul className={css ? css : "listview simple-listview no-space border-0"}>
                                {row.items.map((cont, idx) => {
                                    if (hasSearch(cont))
                                        return renderFormControls(idx, cont);
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderSummaryForm = (controlList, row, idx, _sn) => {
        return (
            <>
                {row.title !== "" && <div class="section-title bdB2">{row.title}</div>}
                <div className="pb-1 pt-1">
                    {row.items.length > 0 && (
                        <ul className="listview simple-listview no-space border-0">
                            {row.items.map((cont, idx) => {
                                if (hasSearch(cont))
                                    return renderFormControls(idx, cont);
                            })}
                        </ul>
                    )}
                </div>
            </>
        )
    }

    const hasSearch = (cont) => {
        if (search) {
            if (cont.type == "input" || cont.type == "checkbox") {
                if (cont.val) {
                    if (cont.val.toUpperCase().indexOf(search.toUpperCase()) > -1)
                        return true
                }
                if (cont.label.toUpperCase().indexOf(search.toUpperCase()) > -1)
                    return true
            }
            return false;
        } else {
            return true;
        }
    }

    return (
        <>
            {controlList.map((row, idx) => {
                if (row.items.length == 0) return;
                if (isSummary) {
                    return renderSummaryForm(controlList, row, idx, _sn, nocard);
                } else {
                    return renderNormalForm(controlList, row, idx, _sn, nocard);
                }
            })}
        </>
    )
}
export default RayForm;