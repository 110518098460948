import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
    InputBasicText,
    InputSelect2
} from "../../components/common/Inputs";

import { TranslateAction } from '../../actions';
import { RayTranslateLanguages } from '../../config/RayCodes';
import { ModalConfirm } from '../../components/common/ModalConfirm';
import { RayTable } from '../../components/common';
import { Translate as t } from '../../config/Translate';

const AdminTranslate = ({ Create, Modify, GetAll, translateData }) => {
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        GetAll();
    }, [])

    useEffect(() => {
        setList(translateData);
    }, [translateData]);

    useEffect(() => {
        if (selectedData.type == "modify" || selectedData.type == 'create') {
            setShowModal(true);
        }

        if (selectedData.type == "delete") {
            setShowDeleteModal(true);
        }

    }, [selectedData])

    const searchFilter = (e) => {
        if (e.target.tagName == 'SELECT')
            setFilter({ ...filter, language: e.target.value });

        if (e.target.tagName == 'INPUT')
            setFilter({ ...filter, contents: e.target.value });
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">{`${t('translate')} ${t('data')}`}</h2>
                <div className="ray-right">
                    <button className="btn btn-primary btn-sm" onClick={() => setSelectedData({ type: 'create' })}>
                        <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon>{t('addtranslatedata')}
                    </button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1">
                    <div className="wide-block pt-2 pb-2">
                        <div className="row">
                            <div className="col-3 pt-1"></div>
                            <div className="col-9 mb-1">
                                <div className="float-end">
                                    <div className="input-group">
                                        <select className="form-control" onChange={(e) => searchFilter(e)}>
                                            <option value="ALL">{t('all')}</option>
                                            {RayTranslateLanguages.map(item => <option value={item.code}>{item.title}</option>)}
                                        </select>
                                        <input type="text" className="form-control" placeholder={t('search')} onKeyUp={(e) => searchFilter(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-bordered mb-1">
                                <thead>
                                    <tr>
                                        <th>{t('title')}</th>
                                        <th>{t('contents')}</th>
                                        <th>{t('language')}</th>
                                        <th>-</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.filter(lst => {
                                            // return (filter.language == "ALL" || !filter.language) ? lst.language == lst.language : filter.language == lst.language
                                            return filter.language == lst.language

                                        }).filter(lst => {
                                            return !filter.contents || filter.contents.trim() == '' ? lst.contents == lst.contents : (lst.title.indexOf(filter.contents) > -1 || lst.contents.indexOf(filter.contents) > -1)
                                        })
                                            .map(item => {
                                                return (
                                                    <tr>
                                                        <td>{item.title}</td>
                                                        <td>{item.contents}</td>
                                                        <td>{RayTranslateLanguages.find(lang => lang.code == item.language).title}</td>
                                                        <td className='text-center' style={{ width: '200px' }}>
                                                            <button className='btn btn-secondary btn-sm' onClick={() => setSelectedData({ type: "modify", _id: item._id, title: item.title, contents: item.contents, language: item.language, isDel: false })}>{t('edit')}</button>&nbsp;
                                                            <button className='btn btn-secondary btn-sm' onClick={() => setSelectedData({ type: "delete", _id: item._id, title: item.title, contents: item.contents, language: item.language, isDel: true })}>{t('delete')}</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }

                                    <ModalConfirm info={{
                                        showModal: showDeleteModal,
                                        content: 'Are you sure you want to delete it?',
                                        actions: [{
                                            css: '',
                                            callback: () => {
                                                Modify(selectedData);
                                                setShowDeleteModal(false);
                                            }
                                            , title: t('delete')
                                        }],
                                        title: t('delete')
                                    }} callbackClose={() => setShowDeleteModal(false)} />

                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col ps-1"></div>
                            <div className="col pe-1 text-end"></div>
                        </div>
                    </div>
                </div>
            </div>
            <AddTranslateModal
                selectedData={selectedData}
                showModal={showModal}
                create={(data) => Create(data)}
                modify={(data) => Modify(data)}
                closeModal={() => setShowModal(false)}
            />
        </>
    )
};

const AddTranslateModal = ({ showModal, selectedData, create, modify, closeModal }) => {

    const [data, setData] = useState({
        _id: '',
        title: '',
        contents: '',
        language: '',
        isDel: false
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const doAction = () => {
        if (selectedData.type == "create")
            create(data);

        if (selectedData.type == "modify")
            modify(data);
    }

    const type = selectedData.type;

    useEffect(() => {
        setData({
            _id: selectedData._id ? selectedData._id : '',
            title: selectedData.title ? selectedData.title : '',
            contents: selectedData.contents ? selectedData.contents : '',
            language: selectedData.language ? selectedData.language : '',
            isDel: selectedData.isDel ? selectedData.isDel : false
        })
    }, [selectedData]);

    return (
        <Modal show={showModal} size="lg" onHide={() => { closeModal(); setData({ title: '', contents: '', language: '' }) }}>
            <Modal.Header>
                <h2 className='modal-title'>{type == 'create' ? 'Add' : 'Modify'} Translate Data</h2>
            </Modal.Header>
            <Modal.Body className='p-2'>
                <InputSelect2 options={{
                    type: "select",
                    name: "language",
                    label: t('language'),
                    val: data && data.language,
                    list: RayTranslateLanguages,
                    required: true
                }}
                    onchange={onChange} editable={true} />

                <InputBasicText options={{
                    type: 'input',
                    name: 'title',
                    label: t('title'),
                    val: data && data.title,
                    required: true
                }}
                    onchange={onChange} editable={true} />

                <InputBasicText options={{
                    type: 'input',
                    name: 'contents',
                    label: t('contents'),
                    val: data && data.contents,
                    required: false
                }}
                    onchange={onChange} editable={true} />
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-block btn-success mb-0' onClick={() => {
                    if (!data.language) {
                        alert('Please select a language');
                    } else {
                        doAction(data);
                        setData({ title: '', contents: '', language: '', isDel: false });
                        closeModal();
                    }
                }}>{type == 'create' ? t('add') : t('modify')}</button>
                <button className='btn btn-block btn-dark' onClick={() => {
                    setData({ title: '', contents: '', language: '', isDel: false });
                    closeModal()
                }}>{t('cancel')}</button>
            </Modal.Footer>
        </Modal>
    );
}

const mapState = (state) => {
    const translateData = state.TranslateReducer.translate;

    return { translateData };
};

const mapDispatch = (dispatch) => ({
    Create: (data) => dispatch(TranslateAction.Create(data)),
    Modify: (data) => dispatch(TranslateAction.Modify(data)),
    GetAll: () => dispatch(TranslateAction.GetAll())
});

export default connect(mapState, mapDispatch)(AdminTranslate);
