import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductAction, AlertAction, AuthAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { DeviceModels } from '../../config/RayCodes';
import { useTranslation } from 'react-i18next';

const ProductInfo = ({ prd, productInfos, UpdateProductInfo, editable, showsn = false }) => {
	const [t] = useTranslation();
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [product, setProduct] = useState({});
	const [products, setProducts] = useState(productInfos);

	useEffect(() => {
		setProducts(productInfos);
	}, [productInfos]);

	const form_controls = [
		{
			title: '',
			items: [
				{
					type: 'input',
					name: 'sn',
					label: t('sn'),
					val: product.sn,
					viewLinkPath: isAdmin ? "/Admin/AdminProductInfo/" : "/Product/",
					required: true
				},
				{
					type: 'select',
					name: 'model',
					label: t('model'),
					val: product.model,
					list: DeviceModels,
					required: true,
					isEditable: editable,
				},
				{
					type: 'input',
					name: 'option',
					label: t('option'),
					val: product.option,
					isEditable: editable,
				},
				{ type: 'input', name: 'installedDate', label: t('installdate'), val: product.installedDate, valtype: 'date' },
				{
					type: 'input',
					name: 'lastConnectedDate',
					label: t('lastconnect'),
					val: product.lastConnectedDate,
					valtype: 'date'
				},
				{ type: 'input', name: 'registered', label: t('registered'), val: product.registered, valtype: 'date' }
			]
		},
	];

	useEffect(() => {
		makeExtraElements();
	}, []);

	const makeExtraElements = () => {
		if (product.system) {
			var obj = { title: 'System Information', items: [] };
			var keyList = Object.keys(product.system);
			for (let i in keyList) {
				var key = keyList[i];
				obj.items.push({
					type: 'input',
					name: key,
					label: key.charAt(0).toUpperCase() + key.slice(1),
					val: product.system[key],
				});
				i++;
				if (i % 2 == 0) {
					form_controls.push(obj);
					obj = { title: '', items: [] };
				}
			}
			obj.items.length > 0 && form_controls.push(obj);
		}
	};

	const getControl = () => {
		if (!editable && !showsn) {
			return [{ title: "", items: form_controls[0].items.filter(x => x.name != "sn") }];
		} else {
			return form_controls;
		}
	}

	const setItem = (x) => setProduct(x._id == product._id ? {} : x);

	return (
		<div className="section inset">
			{productInfos?.map(x => {
				return (
					<div className="accordion mt-1">
						<h2 className="accordion-header">
							<button className="accordion-button" onClick={() => setItem(x)}>
								<strong>
									{x.sn} <small> {x.model} / {x.option}</small>
								</strong>
							</button>
						</h2>
						{product._id == x._id && <RayForm action={UpdateProductInfo} actionkey={product._id} controls={getControl()} nocard={true} />}
					</div>
				)
			})}
		</div>
	)
};

const mapState = (state) => {
	const prd = state.ProductReducer.product;
	return { prd };
};

const mapDispatch = (dispatch) => ({
	UpdateProductInfo: (_id, data) => dispatch(ProductAction.UpdateProductInfo(_id, data)),
});

export default connect(mapState, mapDispatch)(ProductInfo);
