import { useDispatch } from 'react-redux';
import SiteList from '../../components/site/SiteList';
import { AppHeader } from '../../components/common/LeftMenu';
import { AlertConstant } from '../../actions/constants/AlertConstant';
import React, { useEffect, useState } from 'react';
import ActionSheet from '../../components/common/ActionSheet';

const SiteListView = () => {

	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: AlertConstant.SET_TOP, opt: { title: "Sites", type: "normal" } });
	}, [])

	return (
		<>
			<div className="appHeader bg-primary text-light" onClick={() => setShow(true)}>
				<AppHeader title="Sites" />
				<div className="right">
				</div>
			</div>
			<div id="appCapsule">
				<div className="section mt-2 mb-2">
					<div className="card">
						<SiteList />
					</div>
				</div>
			</div>
			<ActionSheet isShow={show} closed={() => setShow(false)} />
		</>
	);
};

export default SiteListView;