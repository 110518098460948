import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { slide as Menu } from 'react-burger-menu'
import { AuthAction } from '../../actions/AuthAction';
import HomeAlaram from '../home/HomeAlaram';
import { history } from '../../history';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Translate as t } from '../../config/Translate';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { UserType } from '../../config';

const AdminLeftMenu = ({ isOpen, Logout, su, groupList, GetGroupList }) => {
    const dispatch = useDispatch();
    const uData = JSON.parse(localStorage.getItem('user'));

    const [menuOpen, setMenuOpen] = useState(false);
    const [rayDataTitle, setRayDataTitle] = useState(t('raydata'));
    const [orderableCompany, setOrderableCompany] = useState([]);


    useEffect(() => {
        if (groupList.length == 0)
            GetGroupList();
    }, []);

    useEffect(() => {
        if (uData.companyId == 'RAY02091') {
            setRayDataTitle('MegagenData');
        }
    }, [uData]);

    useEffect(() => {
        setMenuOpen(isOpen);
    }, [isOpen]);


    useEffect(() => {
        const companies = [];
        groupList.forEach(x => companies.push(...x.company));
        setOrderableCompany(companies);
    }, [groupList])
    
    const goMenu = (menu) => {
        dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
        history.push(menu.link);
    }

    const goLinkmenu = (menu) => {
        dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
        history.push(menu.listlink);
    }

    const closeMN = () => {
        dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
    }

    const handleStateChange = (state) => {
        if (!state.isOpen)
            dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
    }

    const goTech = () => {
        dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
        window.location = "/";
    }

    const menu1 = [
        { link: "/Admin/AdminProductDashboard", title: t('product'), icon: "laptop-outline", listlink: "/Admin/AdminProductList" },
        { link: "/Admin/AdminServiceDashboard", title: t('service'), icon: "key-outline", listlink: "/Admin/AdminServiceList" },
        { link: "/Admin/AdminErrorDashboard", title: t('errors'), icon: "bug-outline", listlink: "/Admin/AdminErrorList" },
        { link: "/Admin/AdminSiteDashboard", title: t('site'), icon: "navigate-outline", listlink: "/Admin/AdminSiteList" },
        { link: "/Admin/AdminWarrantyDashboard", title: t('warranty'), icon: "server-outline" },
        { link: "/Admin/AdminSiteSurveyList", title: t('sitesurvey'), icon: "reader-outline" },
        { link: "/Admin/AdminInstallationList", title: t('installreport'), icon: "file-tray-stacked-outline" },
        { link: "/Admin/AdminCalendar", title: t('calendar'), icon: "calendar" },
    ]
    const menu2 = [
        { link: "/Admin/AdminProductStockList", title: t('stock'), icon: "layers-outline" },
        { link: "/Admin/AdminCustomFieldList", title: t('customfield'), icon: "bookmark-outline" },
        { link: "/Admin/AdminUserList", title: t('user'), icon: "navigate-outline" },
        { link: "/Admin/AdminCompanyList", title: t('company'), icon: "business-outline" },

        { link: "/Admin/AdminErrorCodeList", title: t('errorcode'), icon: "receipt-outline", suonly: false },
        { link: "/Admin/AdminRayDataList", title: rayDataTitle, icon: "server-outline", suonly: true },
        { link: "/Admin/AdminRayCode", title: t('raycode'), icon: "server-outline", suonly: true },
        { link: "/Admin/AdminProductPart", title: t('productpart'), icon: "hammer-outline", suonly: true },
        { link: "/Admin/AdminTranslate", title: t('translate'), icon: "sync-outline", suonly: true },
    ]

    if (orderableCompany.includes(uData.companyId) || uData.adminid == 'jeongho.lee') 
        menu1.push({ link: "/Admin/AdminSparePartsCompany", title: 'Spare Parts', icon: "hammer-outline" });

    if (uData.companyId === 'RAY00001' || uData.adminid == 'jeongho.lee')
        menu2.push({ link: "/Admin/AdminSpareParts", title: 'SpareParts Management', icon: "hammer-outline" });

    return (
        <>
            {uData && <Menu isOpen={menuOpen}
                outerContainerId={"App"}
                id="sidebarPanel"
                pageWrapId={"appCapsule"}
                disableAutoFocus
                onStateChange={(state) => handleStateChange(state)}
                customBurgerIcon={false}
                className={"panelbox"}
                menuClassName={"modal-dialog"}
                itemListClassName={"modal-content"}>
                <div className="modal-body p-0">
                    <div className="profileBox pe-2 pb-2 pt-2 ps-1 bg-primary">
                        <div className="raycur me-1" onClick={closeMN}>
                            <ion-icon name="arrow-back-outline" size="large"></ion-icon>
                        </div>
                        <div className="float-start text-white">
                            <h2 className="text-white">{uData.adminid}</h2>
                            <div className="text-white">{uData.firstName} {uData.lastName}</div>
                        </div>
                        <div style={{ right: 4, position: "absolute" }} className="me-2">
                            <HomeAlaram size="large" css="headerButton text-white" callback={closeMN} />
                        </div>
                    </div>
                    <div className="listview-title mt-1">{t('information')}</div>
                    <ul className="listview flush transparent no-line image-listview">
                        {menu1.map((m, idx) =>
                            <li key={idx} className={m.listlink && "lilistlink"}>
                                <a onClick={() => goMenu(m)} className="item">
                                    <div className="icon-box bg-success">
                                        <ion-icon name={m.icon}></ion-icon>
                                    </div>
                                    <div className="in">
                                        {m.title}
                                    </div>
                                </a>
                                {m.listlink && <a onClick={() => goLinkmenu(m)} className="raylinkicon">
                                    <ion-icon name="list-outline"></ion-icon>
                                </a>}
                            </li>
                        )}
                    </ul>
                    <div className="listview-title mt-1">{t('data')}</div>
                    <ul className="listview flush transparent no-line image-listview">
                        {menu2.filter(m => (!m.suonly || (UserType(uData.companyId) !== 'DEALER' && m.link === '/Admin/AdminRayDataList') || (m.suonly && su))).map((m, idx) =>
                            <li key={idx}>
                                <a onClick={() => goMenu(m)} className="item">
                                    <div className="icon-box bg-primary">
                                        <ion-icon name={m.icon}></ion-icon>
                                    </div>
                                    <div className="in">
                                        {m.title}
                                    </div>
                                </a>
                            </li>
                        )}
                    </ul>
                    <div className="listview-title mt-1">{t('etc')}</div>
                    <ul className="listview flush transparent no-line image-listview">
                        <li>
                            <a onClick={() => goTech()} className="item">
                                <div className="icon-box bg-secondary">
                                    <ion-icon name="phone-portrait-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    {t('techview')}
                                </div>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => Logout()} className="item">
                                <div className="icon-box bg-secondary">
                                    <ion-icon name="log-out-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    {t('signout')}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </Menu>}
        </>
    );
};

const mapState = (state) => {
    var isOpen = state.ProgramReducer.menuOpen;
    var su = state.AuthReducer.su;
    var groupList = state.SparePartsReducer.groupList;
    return { isOpen, su, groupList };
};

const mapDispatch = dispatch => ({
    Logout: () => dispatch(AuthAction.Logout()),
    GetGroupList: () => dispatch(SparePartsAction.GetGroupList())
})

export default connect(mapState, mapDispatch)(AdminLeftMenu);
