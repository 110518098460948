import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isDOA, isDIM } from '../../components/common/CommonViews';
import { connect } from 'react-redux';
import { ProductAction, AlertAction, AuthAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { DeviceModels } from '../../config/RayCodes';

const ProductCard = ({ item, UpdateUserFav, favList, showOnly = false }) => {

	const isFav = favList ? favList.includes(item.sn) : false;
	useEffect(() => {
	}, []);

	const showDateTime = (r) => {
		var rList = [];
		rList.push(moment.utc(r).local().fromNow());
		rList.push(<small>({moment.utc(r).local().format('YYYY-MM-DD HH:mm:ss')})</small>);
		return rList;
	}

	const getIcon = () => {
		return "";
		return isFav ?
			<ion-icon name="heart" className="text-primary md hydrated"></ion-icon> :
			<ion-icon name="heart-outline" className="text-dark md hydrated"></ion-icon>;
	}

	const isOnline = () => {
		return item.isConnected ?
			<ion-icon name="wifi" className="text-primary md hydrated" style={{ pointerEvents: 'none' }}></ion-icon> :
			<ion-icon name="wifi-outline" className="text-muted md hydrated" style={{ pointerEvents: 'none' }} ></ion-icon>;
	}

	const removeArr = (sn) => {
		const idx = favList.indexOf(sn);
		if (idx > -1) {
			favList.splice(idx, 1);
		}
		return favList;
	}

	const fav = () => {
		UpdateUserFav("sn", isFav ?
			{ favorites: removeArr(item.sn) } :
			{ favorites: [...favList, item.sn] });
	}

	return (
		<>
			{showOnly && <div className="item p-2">
				<div className="in">
					<div>
						<strong>{isOnline()} {item.sn} {isDOA(item)} {isDIM(item)} {getIcon()}</strong>
						<div className="text-muted">{item.model} {item.option && "-" + item.option}</div>
					</div>
				</div>
			</div>}
			{!showOnly && <Link to={"/Product/" + item.sn} className="item p-2">
				<div className="in">
					<div>
						<strong>{isOnline()} {item.sn} {isDOA(item)} {isDIM(item)} {getIcon()}</strong>
						<div className="text-muted">{item.model} {item.option && "-" + item.option}</div>
					</div>
				</div>
			</Link>}
		</>
	)
};

const mapState = (state) => {
	const favList = state.FavoriteReducer.sn;
	return { favList };
}

const mapDispatch = dispatch => ({
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
})

export default connect(mapState, mapDispatch)(ProductCard);
