import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { ErrorCodeConstant } from './constants/ErrorCodeConstant';
import { AlertAction } from './AlertAction';
import { ErrorCodeReducer } from '../reducers/ErrorCodeReducer';

const GetList = () => async (dispatch) => {
	dispatch({ type: ErrorCodeConstant.LOADING });
	const aggregate = [];
	aggregate.push({ '$match': { isDel: false } });
	aggregate.push({
		'$project': {
			'errorCode' : 1,
			'lang' : 1,
			'model' : 1,
			'occurs' : 1,
			'like' : 1,
			'technote' : 1,
		}
	});
	const data = {
		data : {
			type: "errorcode",
			aggregate,
			passfilter: true,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
	if (ret.data.status == 'success') {
		console.log(ret.data.data);
		dispatch({ type: ErrorCodeConstant.ERRORCODE_GET_LIST, errorCodes: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ErrorCodeConstant.LOADED });
};

const GetOne = (errorCode, lang) => async (dispatch) => {
	dispatch({ type: ErrorCodeConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/errorcode/' + errorCode + '/' + lang, JWT());
	if (ret.data.status == 'success') {
		if (ret.data) {
			dispatch({ type: ErrorCodeConstant.ERRORCODE_GET_ONE, errorCode: ret.data.data });
		} else {
			dispatch(AlertAction.Error(ret.data));
		}
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ErrorCodeConstant.LOADED });
};

const GetOneById = (_id) => async (dispatch) => {
	dispatch({ type: ErrorCodeConstant.LOADING });

	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/errorcode/' + _id, JWT());

	if (ret.data.status == 'success') {
		if (ret.data) {
			dispatch({ type: ErrorCodeConstant.ERRORCODE_GET_ONE, errorCode: ret.data.data });
		} else {
			dispatch(AlertAction.Error(ret.data));
		}
	} else {
		dispatch(AlertAction.Error(ret.data));
	}

	dispatch({ type: ErrorCodeConstant.LOADED });
};

const GetOneOnlyErrorCode = async (model, errorCode) => {
	const ret = await axios.post(
		AppConfig.API_SERVER_PATH + '/errorcode/' + errorCode,
		{ type: 'getbyonlyerrorcode', model: model },
		JWT()
	);
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		AlertAction.Error(ret.data);
		return [];
	}
};

const Add = (errorCode) => async (dispatch) => {
	dispatch({ type: ErrorCodeConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'create',
		data: errorCode,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/errorcode', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success('Created!'));
		dispatch({ type: ErrorCodeConstant.ERRORCODE_ADD, errorCode: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg('Create Failed'));
	}
	dispatch({ type: ErrorCodeConstant.LOADED });
};

const Update = (errorCode, lang, errorCodeInfo, type = "update") => async (dispatch) => {
	dispatch({ type: ErrorCodeConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type,
		data: errorCodeInfo,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/errorcode/' + errorCode + '/' + lang, data, JWT());

	console.log("ret", ret);

	if (ret.data.status == 'success') {
		dispatch({ type: ErrorCodeConstant.ERRORCODE_UPDATE, errorCode: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ErrorCodeConstant.LOADED });
};


const Delete = (errorCode, lang) => async (dispatch) => {
	dispatch({ type: ErrorCodeConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/errorcode/' + errorCode + '/' + lang, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success('Deleted!'));
		dispatch({ type: ErrorCodeConstant.ERRORCODE_DELETE, delitem: { errorCode: errorCode, lang: lang } });
	} else {
		dispatch(AlertAction.ErrorMsg('Delete Failed'));
	}
	dispatch({ type: ErrorCodeConstant.LOADED });
};

const GetComboList = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/errorcode/getcombo', JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const ClearUpdateResult = () => (dispatch) => {
	dispatch(success());
	function success() {
		return { type: ErrorCodeConstant.ERRORCODE_CLEAR_UPDATERES };
	}
};

export const ErrorCodeAction = {
	GetList,
	GetOne,
	GetOneById,
	GetOneOnlyErrorCode,
	Add,
	Update,
	Delete,
	ClearUpdateResult,
	GetComboList,
};
