import { RayTable, RayTreeview, AdminSparePartsOutListHeader } from '../../components/common';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { history } from '../../history';
import { ExportExcelClass } from '../../components/common/Exports';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import moment from 'moment';
import { CountryCode } from '../../config';

const AdminSparePartsOutList = ({ outList, viewTree, spareParts, GetAllPartsList, GetOutList }) => {
    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [wHg, setwHg] = useState(300);
    const [wAll, setwAll] = useState(window.innerWidth);
    const [companyId, setCompanyId] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (data.length === 0)
            GetOutList();

        if (spareParts.length === 0)
            GetAllPartsList();

        dispatch({ type: ProgramConstant.VIEWTREE, val: true });
    }, []);

    useEffect(() => {
        const list = outList.filter(x => x?.companyId === (companyId ? companyId : x.companyId)).map(x => {
            return {
                ...x,
                type: (x?.dealerOut ? x.dealerOut.chargeOrFree : (x.orderInfo?.type == 'CHARGE' ? '유상' : '무상')),
                orderStatus: (GetOrderStatus(x.orderInfo?.status) || ''),
                ciMailSent: x?.ciMailSendTime?.length > 0 ? moment(Math.max(...x.ciMailSendTime)).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        });
        setData(list);
    }, [outList, companyId]);

    useEffect(() => {
        const result = [];

        data.forEach(x => x.partsList.map(y => {
            const { _id, ...rest } = y;
            result.push({ _id: x._id, ...rest });
        }));

        const exportSource = result.map(x => {
            const current_data = data.find(y => y._id === x._id);

            const piPublishTime = current_data?.orderInfo ? moment(current_data.orderInfo.piPublishTime).format("YYYY-MM-DD") : moment(current_data.dealerOut.piPublishTime).format("YYYY-MM-DD");

            return {
                country: CountryCode.find(z => z.code === current_data?.country)?.title || '',
                companyName: current_data.companyName,                
                piPublishTime,
                registDate: moment(current_data.createDate).format("YYYY-MM-DD"),
                registMonth: moment(current_data.createDate).format("MM"),
                registYear: moment(current_data.createDate).format("YYYY"),
                shipmentDate: current_data.shipmentDate ? moment(current_data.shipmentDate).format("YYYY-MM-DD") : '',
                shipmentMonth: current_data.shipmentDate ? moment(current_data.shipmentDate).format("MM") : '',
                shipmentYear: current_data.shipmentDate ? moment(current_data.shipmentDate).format("YYYY") : '',
                partsSecuringTime: current_data.partsSecuringTime,
                goodOrBad: '회수불필요',
                shipper: current_data.shipmentWorker,
                forwardingAgent: current_data.forwardingAgent,
                incoterms: 'EXW',
                invoiceNumber: current_data.invoiceNumber || '',
                model: current_data.model || '',
                productSN: current_data.productSN || '',
                modelReleasedDate: '',
                modelReleasedYear: '',
                installedDate: current_data.installedDate ? moment(current_data.installedDate).format("YYYY-MM-DD") : '',
                mainIssue: '',
                faultsOrEtc: '',
                section: spareParts.find(y => y.serviceCode === x.serviceCode)?.section,
                type: spareParts.find(y => y.serviceCode === x.serviceCode)?.type,
                serviceCode: x.serviceCode,
                servicePartName: x.servicePartName,
                bomCode: '',
                bomName: '',
                sn: x.sn || '',
                quantity: x.quantity,
                remark: '',
                workingDepartment: current_data.workingDepartment || '',
                gbNum: current_data?.ccrNumber,
                gaNum: current_data?.draftNumber,
                partsProcureTime: current_data.partsProcureTime,
                partsProcureDelayReason: current_data.partsProcureDelayReason || '',
                etcReason: '',
                ciNumber: current_data?.ci?.invoiceNumber || '',
                piNumber: current_data?.ci?.piNumber || '',
                currencyUnit: current_data?.ci?.currencyUnit || '',
                partsProcureDuration: current_data.partsProcureDuration,
                price: x.price,
                amount: parseInt(x.price) * parseInt(x.quantity),
                etc1: '',
                etc2: ''
            }
        });

        setExportData({
            items: exportSource,
            css: "btn btn-secondary m-0",
            filename: "출고이력_" + moment().format("YYYYMMDDhhmmss"),
            sheettitle: "Sheet",
            headers: [
                { name: 'country', label: 'Country' },
                { name: 'companyName', label: 'Site Name' },
                { name: 'companyName', label: '배송지' },
                { name: 'piPublishTime', label: '접수일(유상,PI발행일)' },
                { name: 'registDate', label: '접수일(자재기안출고)' },
                { name: 'registMonth', label: '접수월' },
                { name: 'registYear', label: '접수년도' },
                { name: 'shipmentDate', label: 'Data Shipped' },
                { name: 'shipmentMonth', label: '발송월' },
                { name: 'shipmentYear', label: '발송년' },
                { name: 'partsSecuringTime', label: '자재조달시간' },
                { name: 'goodOrBad', label: '양품/부적합' },
                { name: 'shipper', label: 'Shipper' },
                { name: 'forwardingAgent', label: '운송사' },
                { name: 'incoterms', label: '인코텀즈' },
                { name: 'invoiceNumber', label: '송장번호' },
                { name: 'model', label: 'Model Name' },
                { name: 'productSN', label: 'Serial Number' },
                { name: 'modelReleasedDate', label: 'Model 출고일' },
                { name: 'modelReleasedYear', label: 'Model 출고년도' },
                { name: 'installedDate', label: 'Model 설치일' },
                { name: 'mainIssue', label: 'Main Issue' },
                { name: 'faultsOrEtc', label: '장애/기타' },
                { name: 'section', label: 'Section' },
                { name: 'type', label: 'Type' },
                { name: 'serviceCode', label: 'SERVICE CODE' },
                { name: 'servicePartName', label: 'Service Part Name' },
                { name: 'bomCode', label: 'M-BOM CODE' },
                { name: 'bomName', label: 'BOM Name' },
                { name: 'sn', label: 'Serial Number' },
                { name: 'quantity', label: 'Quantity' },
                { name: 'remark', label: 'Remark' },
                { name: 'workingDepartment', label: '불출' },
                { name: 'gbNum', label: '고불처번호' },
                { name: 'gaNum', label: '기안번호' },
                { name: 'partsProcureDuration', label: '자재조달기간분류' },
                { name: 'partsProcureDelayReason', label: '자재조달 늦은사유' },
                { name: 'etcReason', label: '기타사유' },
                { name: 'ciNumber', label: 'CI NO' },
                { name: 'piNumber', label: 'PI NO' },
                { name: 'currencyUnit', label: '통화' },
                { name: 'price', label: '단가' },
                { name: 'amount', label: '합계' },
                { name: 'etc1', label: '면장/세금계산서발행' },
                { name: 'etc2', label: '입금현황' },
            ]
        });
    }, [data]);

    useEffect(() => {
        if (viewTree)
            setwHg(300);
        else
            setwHg(0);
    }, [viewTree]);

    const options = {
        search: true,
        keyCol: "_id",
        filterColumns: [{ col: 'type', val: 'ALL' }],
        searchCols: ['companyName'],
        defaultSort: { section: 1 },
        defaultSortType: -1,
        hideListCount: true,
        filterpageoption: {
            filterDateOptions: [{ code: 'createDate', label: '출고일' }, { code: 'shipmentDate', label: '선적일' }],
            page: 1,
        },
        itemperpage: 30
    }

    const rowSelect = (item) => {
        history.push('/Admin/AdminSparePartsOutDetail/' + item._id);
    }

    const onChangeTreeviewItem = (item) => {
        setCompanyId(item.id == 0 ? '' : item.id);
    }

    const GetOrderStatus = (val) => {
        switch (val?.toLowerCase()) {
            case "order":
            case "ordered":
                return "ORDERED";

            case "send":
            case "sended":
                return "SENT";

            case "pi_publish":
                return "PI_PUBLISHED";

            case "pi_confirm":
                return "PI_CONFIRMED";

            case "ci_publish":
                return "CI_PUBLISHED";

            case "ci_confirm":
                return "CI_CONFIRMED";

            case "receive":
                return "RECEIVED";

            case "cancel":
                return "CANCELED";

            default: ''
        }
    }


    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">출고이력</h2>
                <div className="ray-right">
                    <button className="btn btn-sm mt-0 btn-primary" onClick={()=> history.push('/Admin/AdminSparePartsDealerOutRegist')}>딜러출고등록</button>&nbsp;

                    <ExportExcelClass
                        data={exportData}
                        disabled={false}
                        css={"btn btn-primary btn-sm mt-0"}
                        title={"Export Data"} />&nbsp;

                    <button className="btn btn-sm mt-0 btn-primary" onClick={() => history.push('/Admin/AdminSpareParts')}>뒤로가기</button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
                    <div className='p-1'>
                        <RayTreeview onClickItem={onChangeTreeviewItem} />
                    </div>
                </div>
                <div className="section m-1 p-0">
                    <div className="card p-2">
                        <div className={"fl"}
                            style={{ width: wAll - (viewTree ? 354 : 68) + 'px' }}>
                            <RayTable
                                data={data}
                                columns={AdminSparePartsOutListHeader}
                                options={options}
                                rowSelectCallback={rowSelect}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    const outList = state.SparePartsReducer.outList;
    const viewTree = state.ProgramReducer.viewtree;
    const spareParts = state.SparePartsReducer.spareParts;
    return { outList, viewTree, spareParts };
};

const mapDispatch = (dispatch) => ({
    GetAllPartsList: () => dispatch(SparePartsAction.GetList()),
    GetOutList: () => dispatch(SparePartsAction.GetOutList()),
});

export default connect(mapState, mapDispatch)(AdminSparePartsOutList);
