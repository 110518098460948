import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CommonAction, CompanyAction, ProductAction, RayCodeAction, SiteAction } from '../../actions';
import { RayForm } from '../common';
import { Translate as t } from '../../config/Translate';
import { Branch, UserType } from '../../config';
import moment from 'moment';

const ProductInfo = ({ user, productInfo, UpdateProductInfo, clist, editable, showsn = false, _sn, companies, cancelcallback = null, nocard = false }) => {
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');

	const [product, setProduct] = useState(productInfo);
	const [model, setModel] = useState([]);
	const [option, setOption] = useState([]);
	const [sites, setSites] = useState([]);
	const [userType, setUserType] = useState('DEALER');
	const [warranty, setWarranty] = useState(0);
	const [branchWarranty, setBranchWarranty] = useState(0);

	useEffect(() => {
		//RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" }).then(x => {
		const x = clist?.filter(x => x.type === 'PRODUCTOPTION');
		let models = [];
		let options = [];
		x.forEach(item => {
			if (!models.includes(item.group))
				models.push({ code: item.group, title: item.group });

			options.push({ group: item.group, code: item.code, title: item.name });
		});

		models.push({ code: 'RAYSCAN S', title: 'RAYSCAN S' });

		setModel([...new Set(models.map(JSON.stringify))].map(JSON.parse));
		setOption(options);

		// CompanyAction.GetCompanyLocal().then(x => {
		// 	const temp = [];
		// 	x.forEach(item => { temp.push({ code: item.companyId, title: item.name }) });
		// 	setCompanies(temp);
		// });
	}, [clist]);

	useEffect(() => {
		if(product.companyId)
			getSites();
		// SiteAction.GetSitesByCompanyId(product.companyId).then(x => {
		// 	setSites(x.map(item => {
		// 		return { code: item.siteId, title: item.sitename }
		// 	}));
		// });
	}, [product.companyId]);

	const getSites = async () => {
		const res = await CommonAction.GetRecord({
			data : {
				type : "site",
				filter : { companyId: product.companyId },
				cols : { 'siteId' : 1, 'sitename' : 1}
			}
		});
		console.log('sites', res);
	}

	useEffect(() => {
		setUserType(UserType(user.companyId));
	}, [user]);

	useEffect(() => {
		setProduct(productInfo);

		let msPeriod = product.waranties?.find(x => x.category === 'wartype_ms' && x.dest === "warproduct_product")?.period || 0;

		if (msPeriod > 0) {
			productInfo.exWarranty?.filter(x => x.type === 'HQ').forEach(x => {
				if (x.dest.includes('warproduct_product'))
					msPeriod = moment(msPeriod).add(x.amount, x.amountType);
			});
		}

		setWarranty(msPeriod);
		
		let bsPeriod = product.waranties?.find(x => x.category === 'wartype_bs' && x.dest === "warproduct_product")?.period || 0;
		
		if (bsPeriod > 0) {
			productInfo.exWarranty?.filter(x => x.type === 'BRANCH').forEach(x => {
				if (x.dest.includes('warproduct_product'))
				bsPeriod = moment(bsPeriod).add(x.amount, x.amountType);
			});
		}

		setBranchWarranty(bsPeriod);

	}, [productInfo, userType]);

	const onChanges = (e) => {
		if (e.target.name == 'model') {
			const options = option.filter(x => x.group == (e.target.value === 'RAYSCAN S' ? 'RAYSCAN Studio' : e.target.value));
			setProduct({ ...product, model: e.target.value, option: options[0].title, codes: options[0].code });
		}
		else if (e.target.name == 'installedDate' || e.target.name == 'shipmentdate' || e.target.name == 'siteId') {
			let updateInfo = { ...product, [e.target.name]: e.target.value };

			if (e.target.name == 'siteId')
				updateInfo = { ...updateInfo, 'sitename': sites.find(x => x.code == e.target.value)?.title || '' };

			setProduct(updateInfo);
		}
		else if (e.target.name === 'companyId') {
			setProduct({ ...product, [e.target.name]: e.target.value, siteId: '', sitename: '' });
		}
		else {
			setProduct({ ...product, option: e.target[e.target.selectedIndex].text, codes: e.target.value });
		}
	}

	const updateProductInfo = () => {
		let codes;

		if (product.model && product.option && !product.codes) {
			const result = option.filter(x => { return x.group == product.model && x.title == product.option });
			codes = result.length && result[0].code;
		} else {
			codes = product.codes;
		}

		UpdateProductInfo(product._id, {
			sn: product.sn,
			model: product.model,
			option: product.option,
			codes,
			installedDate: product.installedDate,
			shipmentdate: product.shipmentdate,
			companyId: product.companyId,
			siteId: product.siteId,
			sitename: product.sitename
		});
	}

	var form_controls = [
		{
			title: '',
			items: [
				{
					type: 'input',
					name: 'sn',
					label: t('sn'),
					val: product.sn,
					extra: product,
					viewLinkPath: isAdmin ? "/Admin/AdminProductInfo/" : "/Product/",
					isEditable: false,
					required: true
				},
				{
					type: 'select',
					name: 'model',
					label: t('model'),
					val: product.model,
					list: model.filter(x => user.isMegagen ? x.code == 'R2 Studio Q' : x.code == x.code),
					required: true,
					isEditable: editable ? user && userType == "HQ" && user?.email == "hyunseok.jeong@raymedical.co.kr" : editable,
					onchange: onChanges
				},
				{
					type: 'select',
					name: 'option',
					label: t('option'),
					val: product.codes,
					list: option.filter(x => x.group == (product.model === 'RAYSCAN S' ? 'RAYSCAN Studio' : product.model)),
					required: true,
					isEditable: editable ? user && userType == "HQ" && user?.email == "hyunseok.jeong@raymedical.co.kr" : editable,
					onchange: onChanges
				},
				{
					type: 'datetime',
					name: 'installedDate',
					label: t('installeddate'),
					val: product.installedDate || 0,
					valtype: 'date',
					onlyDate: true,
					isEditable: user.adminType == 'S' && editable,
					onchange: onChanges
				},
				{
					type: 'datetime',
					name: 'shipmentdate',
					label: t('shipmentdate'),
					val: product.shipmentdate || 0,
					valtype: 'date',
					onlyDate: true,
					isEditable: user.adminType == 'S' && editable,
					onchange: onChanges
				},
				{
					type: 'input',
					name: 'lastConnectedDate',
					label: t('lastconnected'),
					val: product.lastConnectedDate,
					valtype: 'date'
				},
				{
					type: 'input',
					name: 'registered',
					label: t('registered'),
					val: product.registered,
					valtype: 'date',
					onlyDate: true
				},
				{
					type: 'input',
					name: 'warrantyperiod',
					label: t('warranty'),
					val: userType === 'DEALER' ? 0 : warranty,
					valtype: 'date',
					isEditable: false,
					onlyDate: true,
				},
				{
					type: 'input',
					name: 'branchwarrantyperiod',
					label: t(userType === 'DEALER' ? 'warranty' : 'branchwarranty'),
					val: branchWarranty,
					valtype: 'date',
					isEditable: false,
					onlyDate: true
				},
				{
					type: 'select2',
					name: 'companyId',
					label: t('operator'),
					val: product.companyId,
					list: companies.map(x => ({ code: x.companyId, title: x.name })),
					isEditable: user.adminType == 'S' && editable,
					onchange: onChanges
				},
				{
					type: 'select2search',
					name: 'siteId',
					label: t('site'),
					val: product.siteId,
					list: sites.length === 0 ? [{ code: product.site?.siteId, title: product.site?.sitename }] : sites,
					isEditable: user.adminType == 'S' && editable,
					extra: product.site,
					viewLinkPath: isAdmin ? "/Admin/AdminSiteInfo/" : "/Site/",
					viewLinkUseId: product.siteId,
					oninputchange: (e) => {
						if (e.target.value.length >= 2) {
							SiteAction.GetSiteAllLocal({
								type: "get",
								data: { "projects": { "siteId": 1, "sitename": 1 }, "searchCols": ["sitename"], "searchKey": e.target.value }
							}).then(x => {
								const list = [];
								x.data.data?.list.map(item => list.push({ code: item.siteId, title: item.sitename }));
								list ? setSites(list) : setSites([]);
							})
						}
					},
					onchange: onChanges
				}
			]
		},
	];

	useEffect(() => {
		makeExtraElements();
	}, []);

	const makeExtraElements = () => {
		if (product.system) {
			var obj = { title: 'System Information', items: [] };
			var keyList = Object.keys(product.system);
			for (let i in keyList) {
				var key = keyList[i];
				obj.items.push({
					type: 'input',
					name: key,
					label: key.charAt(0).toUpperCase() + key.slice(1),
					val: product.system[key],
				});
				i++;
				if (i % 2 == 0) {
					form_controls.push(obj);
					obj = { title: '', items: [] };
				}
			}
			obj.items.length > 0 && form_controls.push(obj);
		}
	};

	const getControl = () => {
		if (!editable && !showsn) {
			return [{ title: "", items: form_controls[0].items.filter(x => x.name != "sn") }];
		}
		else if (!location.pathname.toLowerCase().startsWith('/admin/')) {
			return [{ title: "", items: form_controls[0].items.filter(x => x.name != "siteId") }];
		}
		else {
			return form_controls;
		}
	}

	return (
		<>
			<RayForm controls={getControl()} handleChangeData={onChanges} nocard={nocard} />
			{editable &&
				<div className="ps-1 pe-1">
					<button className="btn btn-primary btn-block" type="button" onClick={() => { updateProductInfo(); cancelcallback() }}>{t('update')}</button>
					<button className="btn btn-secondary btn-block" type="button" onClick={() => { setProduct(productInfo); setSites([]); cancelcallback() }}>{t('cancel')}</button>
				</div>
			}
		</>
	)
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const prd = state.ProductReducer.product;
	const clist = state.RayCodeReducer.items;
	const companies = state.CompanyReducer.companies;
	return { user, prd, clist, companies };
};

const mapDispatch = (dispatch) => ({
	UpdateProductInfo: (_id, data) => dispatch(ProductAction.UpdateProductInfo(_id, data)),
});

export default connect(mapState, mapDispatch)(ProductInfo);
