import React, { useState, useEffect } from 'react';
import { DeviceModels } from '../../config';
import { S3DownloadButton } from '../common/Buttons';
import { useTranslation } from 'react-i18next';

const ErrorCodeView = ({ errorCodeInfo }) => {
    
    const { t } = useTranslation();

    useEffect(() => {
        console.log("errorCodeInfo : =======", errorCodeInfo);
    }, [errorCodeInfo])

    const getListVal = (vals) => {
        var result = [];
        const items = vals.map(val => {
            const tm = DeviceModels.filter(x => x.code == val);
            if (tm.length > 0)
                return tm[0].title;
        })
        if (items.length > 0) {
            items.map(x => result.push(<span className="label label-danger fs-12 text-white m-r-5 m-b-5 inline">{x}</span>));
        } else {
            result.push(<span className="label label-danger fs-12 text-white m-b-5 inline">N/A</span>);
        }
        return result;
    }

    const getOnlyName = (s) => {
        if (s.indexOf(" - ") > -1) {
            var arr = s.split(" - ");
            return arr[1];
        } else {
            return s;
        }
    }

    return (
        <>
            {/* 
            <div className="m-b-15">
                <label className="m-b-5 bold">Model</label>
                <div className="">{getListVal(errorCodeInfo.model)}</div>
            </div>
            */}
            <div className="mb-2">
                <div className="content-header">{t('definition')}</div>
                <div><strong>{errorCodeInfo.definition}</strong></div>
            </div>
            <div className="mb-2">
                <div className="content-header">{t('causeofproblem')}</div>
                <div><strong>{errorCodeInfo.cause}</strong></div>
            </div>
            <div className="mb-2">
                <div className="content-header">{t('solutions')}</div>
                <ul className="listview simple-listview">
                    {errorCodeInfo.solutions.map((s, idx) => {
                        return (
                            <li className="ps-0 pe-0" key={idx}>
                                <div className="in">
                                    {idx + 1}. {getOnlyName(s.title)}
                                </div>
                                {s.resolvedCount > 0 &&
                                    <div className="chip chip-media ms-1 float-end">
                                        <i className="chip-icon bg-primary">
                                            <ion-icon name="thumbs-up-outline"></ion-icon>
                                        </i>
                                        <span className="chip-label">{s.resolvedCount}</span>
                                    </div>
                                }
                            </li>
                        )
                    })}
                    {errorCodeInfo.solutions.length == 0 && <span className='alert'>{t('norecord')}</span>}
                </ul>
            </div>
            <div>
                {errorCodeInfo.attachments.map((s, idx) => {
                    return (
                        <>
                            <S3DownloadButton
                                text={s.resolvedCount}
                                path={s.path}
                                filename={s.name}
                                css="btn btn-success btn-block"
                                tsg={true} />
                        </>
                    )
                })}
                {errorCodeInfo.attachments.length == 0 && <span className='alert'>{t('noattach')}</span>}
            </div>
        </>
    );
};

export default ErrorCodeView;