import { useTranslation } from 'react-i18next';
import { RayTable } from '../../components/common';
import { AdminSparePartsOrderListHeader } from '../../components/common/TableHeader';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { Modal } from 'react-bootstrap';

const SparePartsOrderList = ({ orderList, GetOrderList, sparePartsList, GetSparePartsList, groupList, outContents, GetGroupList, GetOutContents, ChangeStatus }) => {
    const [companyId, setCompanyId] = useState('');
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});
    const [currency, setCurrency] = useState({ mark: '', unit: '' });
    const [amount, setAmount] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const { search } = useLocation();

    const units = [
        { code: 'USD', title: '$' },
        { code: 'EUR', title: '€' },
        { code: 'JPY', title: '￥' },
        { code: 'CNY', title: '￥' }
    ];

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setCompanyId(user.companyId);
        GetOrderList(user.companyId);
        GetSparePartsList();
        GetGroupList();
    }, []);

    useEffect(() => {
        console.log('selected=====================================');
        console.log(selected);
     }, [selected]);

    useEffect(() => {
        const result = new URLSearchParams(search);

        if (result.size > 0) {
            const orderId = result.get("orderId");
            const item = orderList.find(x => x._id === orderId);

            if (item) {
                setSelected({
                    _id: item._id,
                    type: item.type,
                    status: item.status,
                    orderDate: moment(item.orderDate).format("YYYY-MM-DD HH:mm:ss"),
                    address: item.address,
                    orderer: item.orderer,
                    orderList: item.orderList,
                    email: item.email,
                    pi: item?.pi,
                });
            }
        }
    }, [search, orderList]);

    useEffect(() => {
        if (companyId) {
            const unit = groupList.filter(x => x.company.includes(companyId))[0]?.currencyUnit;
            setCurrency({ unit, mark: units.filter(x => x.code == unit)[0]?.title });
        }
    }, [companyId, groupList]);
    
    useEffect(() => {
        if (selected?.orderList) {
            let val = 0;
            selected?.orderList.forEach(x => {
                val += (x.price * x.quantity);
            });

            setAmount(val);
        }
    }, [selected?.orderList]);


    useEffect(() => {
        console.log('outContents', outContents);
    }, [outContents]);

    useEffect(() => {
        setList(orderList.map(x => {
            return {
                _id: x._id,
                // orderDate: moment(x.orderDate).format("YYYY-MM-DD HH:mm:ss"),
                orderDate: x.orderDate,
                type: x.type,
                orderer: x.orderer,
                status: GetOrderStatus(x.status),
                address: x.address,
                orderList: x.orderList,
                pi: x?.pi,
            }
        }));

        if (selected.hasOwnProperty('_id')) {
            const selectedId = selected._id;
            const filtered = orderList.filter(x => x._id == selectedId);
            if (filtered.length > 0) {
                setSelected({ ...selected, status: filtered[0].status });
            }
        }
    }, [orderList]);

    const options = {        
        keyCol: "code",
        filterColumns: [                
            { col: 'type', val: 'ALL' },
            { col: 'status', val: 'ALL' },
        ],
        defaultSort: "orderDate",
        defaultSortType: -1,
        hideListCount: true,
        filterpageoption: {
            filterDateOptions: [{ code: 'orderDate', label: 'Order Date' }],
            page: 1,
        },
        itemperpage: 20
    }

    const rowSelect = (item) => {    
        setSelected({
            _id: item._id,
            type: item.type,
            status: item.status,
            orderDate: moment(item.orderDate).format('YYYY-MM-DD HH:mm'),
            address: item.address,
            orderer: item.orderer,
            orderList: item.orderList,
            email: item.email,
            pi: item?.pi,
        });   
    }

    useEffect(() => {
        if (selected.status === 'SEND' || selected.status === 'CI_PUBLISH' || selected.status === 'CI_CONFIRM')
            GetOutContents(selected._id);
    }, [selected._id]);

    const getPartsName = (partsId) => {
        return (sparePartsList.filter(x => x._id == partsId)[0])?.servicePartName;
    }

    const orderCancel = () => {
        if (confirm('Are you sure you want to cancel your order?'))
            ChangeStatus(companyId, selected._id, 'CANCEL');
    }

    const received = () => {
        if (confirm('Have you received the Spare Parts you ordered?'))
            ChangeStatus(companyId, selected._id, 'RECEIVE');
    }

    const download = async (path, filename, downFileName) => {
        if (path && downFileName) {
            if(downFileName.toLowerCase() === '.pdf')
                downFileName = 'download.pdf';

            const url = await getUrl(path, downFileName);
            if (url) {
                location.download = url;
                location.href = url;
            }
        }
    }

    const getUrl = async (path, filename) => {
        const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
            path,
            name: filename,
        }, JWT());

        if (ret.data.status == "success")
            return ret.data.data;
        else
            return ''
    }

    const accept = (type) => {
        if (confirm(`Have you reviewed and agreed to the ${type} regarding your order?`))
            ChangeStatus(companyId, selected._id, `${type}_CONFIRM`);
    }

    const InvoiceTrackingURL = (forwardingAgent, invoiceNumber) => {
        let invoiceLink = '';

        switch (forwardingAgent) {
            case "TNT": invoiceLink = `http://www.tnt.com/webtracker/tracking.do?searchType=CON&cons=${invoiceNumber}`; break;
            case "DHL": invoiceLink = `http://www.dhl.com/en/express/tracking.html?AWB=${invoiceNumber}&brand=DHL`; break;
            case "Fedex": invoiceLink = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${invoiceNumber}`; break;
            case "UPS": invoiceLink = `http://www.ups.com/WebTracking/track?InquiryNumber1=${invoiceNumber}`; break;
        }

        return invoiceLink;
    }

    const GetOrderStatus = (val) => {
        switch (val.toLowerCase()) {
            case "order":
            case "ordered":
                return "ORDERED";

            case "send":
            case "sended":
                return "SENT";

            case "pi_publish":
                return "PI_PUBLISHED";

            case "pi_confirm":
                return "PI_CONFIRMED";

            case "ci_publish":
                return "CI_PUBLISHED";

            case "ci_confirm":
                return "CI_CONFIRMED";

            case "receive":
                return "RECEIVED";

            case "cancel":
                return "CANCELED";
        }
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Orders</h2>
                <div className="ray-right">
                    <Link to={"/Admin/AdminSparePartsCompany"} className="btn btn-primary btn-sm">Back</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className='row m-1 p-0'>
                    <div className="col-4 m-0 p-1">
                        <div className='card p-2'>
                            <RayTable
                                data={list}
                                columns={AdminSparePartsOrderListHeader}
                                options={options}
                                rowSelectCallback={rowSelect}                                
                            />
                        </div>
                    </div>

                    <div className='col-5 m-0 p-1'>
                        <div className='card p-2'>

                        <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">Order Type</label>
                                    <div className="form-content multi">
                                        <strong>{selected.type}</strong>                                        
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">Status</label>
                                    <div className="form-content multi">
                                        <strong>{selected.status}</strong>
                                        {
                                            (selected.status == 'ORDERED') &&
                                            <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => orderCancel()}>Order Cancel</button>
                                        }
                                        {
                                            selected.status == 'CI_CONFIRMED' &&
                                            <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => received()}>Received</button>
                                        }                                        
                                        {
                                            selected.status === 'PI_PUBLISHED' &&
                                            <>
                                                <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => download(selected.pi?.s3path, selected.pi?.savedFileName, selected.pi?.originFileName)}>Show PI</button>
                                                <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => accept('PI')} >Accept PI</button>
                                            </>
                                        }
                                        {
                                            selected.status === 'PI_CONFIRMED' &&
                                            <>
                                                <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => download(selected.pi?.s3path, selected.pi?.savedFileName, selected.pi?.originFileName)}>Download PI</button>
                                            </>
                                        }
                                        {
                                            selected.status === 'CI_PUBLISHED' &&
                                            <>
                                                <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => download(outContents.ci?.s3path, selected.ci?.savedFileName, outContents.ci?.originFileName)}>Show CI</button>
                                                {/* <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => console.log(outContents)}>Show CI</button> */}
                                                <button className='btn btn-sm btn-primary mx-1 mt-0' onClick={() => accept('CI')} >Accept CI</button>
                                            </>
                                        }                                        
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">Order Date</label>
                                    <div className="form-content multi">
                                        {selected.orderDate}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">Order List</label>
                                    <div className="form-content multi">
                                        {
                                            (selected && selected.orderList) &&
                                            <>
                                                {selected.type == 'CHARGE' && <div className='order-list-unit'>({currency.unit}{currency.mark})</div>}
                                                <table className='order-list-table'>
                                                    <thead>
                                                        <tr>
                                                            <td>NAME</td>
                                                            <td>QTY</td>
                                                            {selected.type == 'CHARGE' && <td>PRICE</td>} 
                                                            <td>MEMO</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            selected?.orderList?.map((x, idx) => {
                                                                return (
                                                                    <tr key={`order_list_${idx}`}>
                                                                        <td>{getPartsName(x.partsId)}</td>
                                                                        <td>{x.quantity}</td>
                                                                        {selected.type == 'CHARGE' && <td>{x.price}</td>}
                                                                        <td>{x.memo}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                                {selected.type == 'CHARGE' && <div className='order-list-amount'> Amount : {amount}{currency.mark}</div>}
                                                
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                selected.status === 'SEND' &&
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Shipping Information</label>
                                        <div className="form-content multi">
                                            {outContents?.forwardingAgent}
                                            {
                                                outContents?.invoiceNumber &&
                                                    <>&nbsp;(<a href={InvoiceTrackingURL(outContents.forwardingAgent, outContents.invoiceNumber)} target='_blank'>{outContents.invoiceNumber}</a>)</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">Recipient Information</label>
                                    <div className="form-content multi">
                                         {selected.address}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">Receiver</label>
                                    <div className="form-content multi">
                                        {selected.orderer} 
                                        {
                                            selected.email && <>({selected.email})</>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PIForm showModal={showModal} closeModal={() => setShowModal(false)} url={"test.pdf"} />
        </>
    )
}

const PIForm = ({ showModal, closeModal, url }) => {
    const [t, i18n] = useTranslation();

    return (
        <Modal show={showModal} onHide={() => closeModal()} size='lg' dialogClassName="action-sheet" scrollable={true}>
            <Modal.Header>
                <Modal.Title><h2 className="modal-title">PI Viewer</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {url}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => closeModal()}>{t('close')}</button>
            </Modal.Footer>
        </Modal>
    )
}

const mapState = (state) => {
    const orderList = state.SparePartsReducer.orderList;
    const sparePartsList = state.SparePartsReducer.spareParts;
    const groupList = state.SparePartsReducer.groupList;
    const outContents = state.SparePartsReducer.outContents;
    return { orderList, sparePartsList, groupList, outContents };
};

const mapDispatch = (dispatch) => ({
    GetOrderList: (companyId) => dispatch(SparePartsAction.OrderList(companyId)),
    GetSparePartsList: () => dispatch(SparePartsAction.GetList({})),
    GetGroupList: () => dispatch(SparePartsAction.GetGroupList()),
    GetOutContents: (orderId) => dispatch(SparePartsAction.GetOutContentsByOrderId(orderId)),
    ChangeStatus: (companyId, _id, status) => dispatch(SparePartsAction.ChangeStatus(companyId, _id, status))
});

export default connect(mapState, mapDispatch)(SparePartsOrderList);
