import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Sitemap } from '../../config';
import { history } from '../../history';
import { AlertAction, AuthAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';
import { AppHeader } from '../../components/common';

const UpdatePassword = ({ AlertError, AlertOk, isAlertClose }) => {

    const [t] = useTranslation();
    const [inputs, setInputs] = useState({
        password: '',
        newpassword: '',
        confirmnewpassword: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [result, setResult] = useState(false);
    const [errors, setErrors] = useState([]);

    const { password, newpassword, confirmnewpassword } = inputs;

    const form_controls = [
        {
            title: "", items: [
                {
                    type: "password",
                    name: "password",
                    required: true,
                    label: t('currentpassword'),
                    val: password
                },
                {
                    type: "password",
                    name: 'newpassword',
                    required: true,
                    label: t('newpassword'),
                    val: newpassword
                },
                {
                    type: "password",
                    name: "confirmnewpassword",
                    required: true,
                    label: t('confirmpassword'),
                    val: confirmnewpassword
                },
            ]
        }
    ]

    useEffect(() => {
        if (result)
            history.push("/MyInfo");
    }, [result]);

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errValidate = RayFormValidation(form_controls, inputs);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            if (newpassword != confirmnewpassword) {                
                setErrors([{ name: "confirmnewpassword", msg: "Please check password" }]);
                return;
            }
            setSubmitted(true);
            AuthAction.UpdatePasswordModify(inputs).then((ret) => {

                console.log(ret);

                if (ret) {
                    setResult(true);
                    AlertOk("OK");
                } else {
                    setSubmitted(false);
                    AlertError("Fail");
                }
            });
        }
    }

    return (
        <>
            <div className="appHeader no-border">
                <AppHeader title="Update Password" />
                <div className="right">
                </div>
            </div>
            <div id="appCapsule">
                <RayForm controls={form_controls} handleChangeData={handleChangeData} errors={errors} isForm={true} />
                <div className="section">
                    <button onClick={handleSubmit} className="btn btn-primary btn-block">{t('submit')}</button>
                </div>
            </div>
        </>
    );
}

const mapState = state => {
    return { isAlertClose: state.AlertReducer.isClose };
}

const mapDispatch = dispatch => ({
    AlertError: message => dispatch(AlertAction.Error(message)),
    AlertOk: message => dispatch(AlertAction.Success(message)),
})

export default connect(mapState, mapDispatch)(UpdatePassword);