import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { RayChartBar } from '../components/common';
import { CommonAction } from '../actions/CommonAction';
import { CompanyAction } from '../actions/CompanyAction';
import { NoRecord } from '../pages/HomePage';
import { Loading } from '../components/common/Loading';

const WidgetBarChart = ({ name, aggregate,
	legengtitle, xtype = "day", listname = "", GetAllCompany, companies,
	category, indexBy, options = {}, title = "", height = 500, days = 14, setting = null }) => {

	const [count, setCount] = useState([]);
	const [hasData, setHasData] = useState(false);
	const [callCount, setCallCount] = useState(0);
	const [isFetch, setIsFetch] = useState(true);
	const [chartData, setChartData] = useState([]);
	const [chartCategory, setChartCategory] = useState([]);

	useEffect(() => {
		if (listname == "COMPANY" && companies.length == 0) {
			GetAllCompany();
		} else {
			getDataStart();
		}
	}, [])

	useEffect(() => {
		if (companies.length > 0)
			getDataStart();
	}, [companies])

	useEffect(() => {
		getDataStart();
	}, [days]);

	const getDataStart = () => {
		if (name) {
			var savedData = JSON.parse(localStorage.getItem(name));
			if (savedData) {
				if (new Date().getTime() - savedData.timestamp > 60 * 60 * 1000)
					savedData = null;
			}
			getData(savedData ? savedData.data : null);
		} else {
			getData(null);
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => getData(null), 60 * 60 * 1000);
		return () => clearTimeout(timer);
	}, [callCount])

	const getData = (savedData) => {
		if (name) {
			if (savedData) {
				makeData(savedData);
			} else {
				CommonAction.GetCountAction(aggregate).then(ret => {
					const sData = {
						timestamp: new Date().getTime(),
						data: ret.data
					}
					localStorage.setItem(name, JSON.stringify(sData));
					makeData(ret.data);
				})

			}
		} else {
			CommonAction.GetCountAction(aggregate).then(ret => {
				makeData(ret.data);
			})

		}
		setIsFetch(false);
		setCallCount(callCount > 100 ? 0 : callCount + 1);
	}

	const makeData = (list) => {
		setHasData(list.length > 0);
		var category = [];
		if (listname == "COMPANY") {
			list.map((x) => {
				var com = companies.filter(y => y.companyId == x._id.val);
				if (com.length > 0) {
					x._id.val = com[0].name;
				}
			})
		}
		list.map((x) => {
			if (!category.includes(x._id.val || legengtitle || "Item"))
				category.push(x._id.val || legengtitle || "Item")
		})
		setChartCategory(category);

		var result = [];
		if (xtype == "day") {
			for (var i = (days - 1); i >= 0; i--) {
				var localDate = moment().add(-1 * i, 'days');
				var obj = { date: localDate.format("MM/DD"), odate: localDate.format("YYYY-MM-DD") };
				category.map(m => {
					obj[m] = 0;
				});
				result.push(obj)
			}
		} else if (xtype == "month") {
			for (var i = (days - 1); i >= 0; i--) {
				var localDate = moment().add(-1 * i, 'months');
				var obj = { date: localDate.format("YYYY/MM"), odate: localDate.format("YYYY-MM") };
				category.map(m => {
					obj[m] = 0;
				});
				result.push(obj)
			}
		}
		list.map((value) => {
			var foundIndex = result.findIndex(x => x.odate == value._id.date);
			if (foundIndex > -1) {
				result[foundIndex][value._id.val || legengtitle || "Item"] += value.count;
			}
		});

		setChartData(result);
	}

	const handleClick = (item) => {
		console.log(item);
	}

	const chartOptions = {
		...options,
		height: height,
		handleClick: handleClick
	}

	return (
		<div className="mb-2">
			<div className="card">
				<div className="card-header">
					{title && <h5 className="card-title">
						{title}
						{setting && <a className='btn btn-sm mt-0 right' onClick={setting}><ion-icon name="settings-outline"></ion-icon></a>}
					</h5>}
				</div>
				{hasData && <div className={"card-body"}>
					{isFetch && <Loading />}
					{!isFetch && <RayChartBar data={chartData} category={chartCategory} indexBy="date" options={chartOptions} />}
				</div>}
				{!isFetch && !hasData && <NoRecord />}
			</div>
		</div>
	)
};

const mapState = (state) => {
	var companies = state.CompanyReducer.companies;
	return { companies };
};

const mapDispatch = (dispatch) => ({
	//GetAllCompany: (filter) => dispatch(CompanyAction.GetCompany()),
});

export default connect(mapState, mapDispatch)(WidgetBarChart);