import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LeftMenuItems } from '../../config';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { history } from '../../history';
import { slide as Menu } from 'react-burger-menu'
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Modal } from 'react-bootstrap';
import { Translate as t } from '../../config/Translate';

export const AppLoader = () => {
    return (
        <div id="loader">
            <img src="assets/img/logo-icon.png" alt="icon" className="loading-icon" />
        </div>
    )
}

export const AppHeader = ({ title, subtitle = "", prev = true, titleprefix = "", suffix = "", leadIcon = null, item = null }) => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="left">
                {prev && <a href onClick={() => history.goBack()} className="headerButton goBack">
                    <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
                </a>}
                {(leadIcon && item) && React.createElement(leadIcon, { item: item, showText: false })}
                {titleprefix && titleprefix}
                {title && <div className="pageTitle">
                    <strong>{title}</strong>
                    {subtitle && <div className="text-muted m-0" style={{ lineHeight: "80%" }}>{subtitle}</div>}
                </div>}
                {suffix && suffix}
                {!title && <div className="text-muted"><strong>RAYGuard</strong> <span>Tech</span></div>}
            </div>
        </>
    )
}

const SideMenu = ({ isOpen, user }) => {

    const dispatch = useDispatch();
    const uData = JSON.parse(localStorage.getItem('user'));
    // const [t] = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setMenuOpen(isOpen);
    }, [isOpen]);

    const goMenu = (menu) => {
        dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
        history.push(menu.path);
    }

    const closeModal = () => {
        dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
    }

    const goUrl = (url) => {
        history.push(url);
        closeModal();
    }

    return (
        <Modal show={menuOpen} fullscreen={true} className="modalbox menu-all" onHide={() => closeModal()} dialogClassName="modal-fullscreen">
            <Modal.Header>
                <div className="profileBox">
                    <div className="image-wrapper">
                        <a onClick={() => goUrl("/MyInfo")}><img src={uData?.avatar || "/assets/img/avatar_128_w.png"} alt="image" className="imaged w48" /></a>
                    </div>
                    <div className="in">
                        <strong>{uData?.adminid}</strong>
                        <div className="text-muted">{uData.firstName} {uData.lastName}</div>
                    </div>
                    <a href className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal" onClick={() => closeModal()}>
                        <ion-icon name="close-outline" role="img" className="md hydrated" aria-label="close outline"></ion-icon>
                    </a>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ul className="listview flush transparent image-listview mt-1 no-line">
                    {LeftMenuItems.map((menu, index) => {
                        return (
                            <li key={index}>
                                <a onClick={() => goMenu(menu)} className="item">
                                    <div className="icon-box text-primary">
                                        <ion-icon name={menu.class} style={{ pointerEvents: 'none' }}></ion-icon>
                                    </div>
                                    <div className="in">
                                        {t(menu.menutitle)}
                                    </div>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </Modal.Body>
            <div className="appFooter mt-2 fixed-bar">
                <div className="mb-2 mt-2 ">
                    {/* {user.companyInfo && user.companyInfo.country} */}
                    <a href="https://www.iubenda.com/privacy-policy/85310967/legal" className="iubenda-nostyle iubenda-embed " title="Privacy Policy ">{t('privacy')}</a><script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>
                    {user.companyInfo && (
                        user.companyInfo.country == "KR" ? <a href="terms/terms.html" className="" target='_blank'>{t('terms')}</a>
                            : <a href='terms/terms_kr.html' className="" target="_blank">{t('terms')}</a>)}
                    <a id="aPortal" href="https://partner.raymedical.com/?autologin_code=eg5pnG7EAYs9kFFtsIzkq7IunvgZEB"><i className="md-square-right" aria-hidden="true" title="Portal"></i>Ray Partner (Portal)</a>
                </div>
                <div className="copyright">
                    {t('allright')}
                </div>
            </div>
        </Modal>
    )
}

const mapState = (state) => {
    var isOpen = state.ProgramReducer.menuOpen;
    var user = state.AuthReducer.user;
    return { isOpen, user };
};

export default connect(mapState)(SideMenu);
