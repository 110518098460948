import moment from 'moment';
import React, { useEffect, useState } from 'react';

const ProductInfoAutoDiagnosisItem = ({ item, showheader = true, showindex = 0 }) => {
	const [result, setResult] = useState([]);
	const [show, setShow] = useState(!showheader);

	useEffect(() => {
		makeResult();
	}, []);

	useEffect(() => {
		setShow((showindex == 0 && true) || false);
	}, [showindex])

	const makeResult = () => {
		console.log("=================================");
		console.log(item);
		console.log("=================================");

		var list = [];
		item.result.map(x => {
			if (!x.key)
				return;
			list.push({ name: x.key, subname: x.keysub || "", Respected: x.val.Respected, Received: x.val.Received, Result: x.val.Result });
		});
		setResult(list);
	}

	const getName = (x) => {
		var ret = []
		ret.push(x.Result.toUpperCase() === "TRUE" ? <ion-icon name='checkmark-circle-outline'></ion-icon> : <ion-icon name='alert-circle-outline'></ion-icon>);
		ret.push(<span className='ms-1'>{titleCase(x.name) + (x.subname && " - " + x.subname)}</span>);
		return ret;
	}

	const getTrueFalse = (filter) => {
		return item.result.filter(x => {
			if (x.val) {
				return x.val.Result ? x.val.Result.toUpperCase() == filter : false;
			}
		})
	}

	const titleCase = s => s == "PnPCapabilities" ? "PnP Capabilities" : s
		.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
		.replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);

	console.log(item);

	return (
		<>
			<div className="accordion-header">
				<button className="accordion-button text-primary collapsed" onClick={() => setShow(!show)}>
					{showheader &&
						<div className="w100">
							<div className="fl">
								{!show && <ion-icon name="chevron-forward-outline"></ion-icon>}
								{show && <ion-icon name="chevron-down-outline"></ion-icon>}
							</div>
							<div className="fl">
								{moment.utc(item.activationTime).local().format('YYYY-MM-DD HH:mm:ss')}
							</div>
							<div className="float-end">
								{item.totalresult && <><span className="text-success">{item.totalresult}</span><span> / </span></>}
								<span className="text-primary">{"Pass : " + getTrueFalse("TRUE").length}</span>
								<span> / </span>
								<span className="text-danger">{"Fail : " + getTrueFalse("FALSE").length}</span>
							</div>
						</div>
					}
				</button>
			</div>
			<div className={show ? "accordion-collapse collapse show" : "accordion-collapse collapse"}>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>Name</th>
							<th>Respected</th>
							<th>Received</th>
						</tr>
					</thead>
					<tbody>
						{result.map(x => {
							if (!x.Result)
								return;
							if (!showheader) {
								if (x.name.toUpperCase().indexOf("WIN") > -1)
									return;
							}
							return (
								<tr className={x.Result.toUpperCase() == "TRUE" ? "" : "bg-danger"}>
									<td>{getName(x)}</td>
									<td>{x.Respected}</td>
									<td>{x.Received}</td>
								</tr>
							)
						}
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default ProductInfoAutoDiagnosisItem;