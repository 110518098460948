import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { RayTable, AdminProductWarantyListHeader, AdminProductWarantyAllTypeListHeader } from '../common';
import { RayCodeAction } from '../../actions';
import { useTranslation } from 'react-i18next';
import ProductWarrantyAdd from './ProductWarrantyAdd';

const ProductInfoWaranty = ({ product, clist }) => {

	const [t] = useTranslation();
	const [codeList, setCodeList] = useState([]);
	const [warType, setWarType] = useState([]);
	const [nowWarType, setNowWarType] = useState("");
	const [list, setList] = useState([]);
	const [header, setHeader] = useState([]);
	const [war, setWar] = useState({});
	const [productCode, setProductCode] = useState("");

	const [showModal, setShowModal] = useState(false);
	const [item, setItem] = useState({});

	const options = {
		keyCol: "_id",
		filterColumns: []
	}

	useEffect(() => {
		setCodes();
	}, [clist]);

	useEffect(() => {
		setNowWarType(warType.code);
	}, [warType]);

	useEffect(() => {
		return () => {
			setShowModal(true);
		}
	}, [item]);

	useEffect(() => {
		setWarType(codeList.filter(x => x.category == "Type").map(x => { return { name: x.name, code: x.code } }));
		var tmp = codeList.filter(x => x.group == "WARRANTYPRODUCT" && x.category == "Product");
		setProductCode(tmp.length > 0 ? tmp[0].code : "");
	}, [codeList]);

	useEffect(() => {
		makeHeader();
	}, [productCode]);

	useEffect(() => {
		makeList();
	}, [header, product]);

	const setCodes = () => {
		console.log('WARRANTY', clist?.filter(x => x.type === 'WARRANTY'));
		setCodeList(clist?.filter(x => x.type === 'WARRANTY'));
		//setCodeList(await RayCodeAction.GetAllByFilter({ type: "WARRANTY" }));
	}

	const getWars = (wType) => {
		var data = [...product.waranties?.filter(x => x.category == wType).map(x => {
			return { ...x, code: getCategoryByCode(x.dest) }
		})];
		return data.sort((a, b) => {
			if (a.code > b.code) return -1;
			if (a.code < b.code) return 1;
			return 0;
		});
	}

	const makeHeader = () => {
		var data = [
			{ name: 'categoryTitle', label: 'Category' },
		];
		if (productCode != "")
			data.push({ name: productCode, label: 'Product', type: "TF", align: "text-center" });
		codeList.filter(x => x.category == "Part").map(x => {
			data.push({ name: x.code, label: x.name, type: "TF", align: "text-center" });
		})
		data.push({ name: 'amountTitle', label: 'Period' });
		data.push({ name: 'who', label: 'Operator' });
		data.push({ name: 'why', label: 'Reason' });
		data.push({ name: 'timestamp', label: 'Time', type: "DT" });
		data.push({
			name: '', label: '', type: "BUTTON", options: {
				title: "Manage",
				paramType: "callback",
			}
		});
		setHeader(data);
	}

	const manCallback = (item) => {
		setItem({ ...item, oriwhy: item.why, why: "" });
	}

	const makeList = () => {
		var data = [];
		if (!product.warantylog) {
			return;
		}
		if (product.warantylog.length > 0) {
			product.warantylog.map(x => {
				var item = {
					...x,
					categoryTitle: getName(x.category),
					amountTitle: x.amount + x.amounttype,
				}
				if (productCode != "")
					item[productCode] = item.dest.includes(productCode)
				codeList.filter(x => x.category == "Part").map(x => {
					item[x.code] = item.dest.includes(x.code);
				})
				data.push(item)
			});
		}
	}

	const getCategoryByCode = (val) => {
		var n = codeList.filter(x => x.code == val);
		return n.length > 0 ? n[0].category : "";
	}

	const getName = (val) => {
		var n = codeList.filter(x => x.code == val);
		return n.length > 0 ? n[0].name : "";
	}

	return <div className="section">
		{product.waranties && <div>
			<div className="row mb-1">
				{warType.map((w, idx) => <div className="col m-0 p-1">
					<div className="card">
						<div className="card-header">
							<div className="card-title m-0">{w.name}</div>
						</div>
						{getWars(w.code).length > 0 &&
							<ul className="listview simple-listview">
								{getWars(w.code).map((x, idx) => {
									return <li key={"war_idx"} className="border-bottom">
										<div> {getName(x.dest)} </div>
										<div className="">
											<span className={x.period > new Date().getTime() ? "badge badge-primary me-1" : "badge badge-danger me-1"}>
												{moment(x.period).fromNow()}
											</span>
											<span>
												{moment(x.period).format("YYYY-MM-DD")}
											</span>
										</div>
									</li>
								})}
							</ul>}
						{getWars(w.code).length == 0 && <div className="card-body">
							<h5>No Record</h5>
						</div>}
					</div>
					{product.waranties.length > 0 && <button
						className="btn btn-primary btn-block btn-sm"
						onClick={(e) => setItem({ category: w.code, whenkey: "warwhen_custom", whykey: "warwhy_custom" })}><ion-icon name="add"></ion-icon>Add</button>}
				</div>)}
			</div>
			<div className="card">
				<div className="card-header">
					<div className="card-title m-0">Warranty History</div>
				</div>
				<div className="card-body">
					<RayTable
						data={list}
						onlytable={true}
						columns={header}
						calllback={manCallback}
						options={options} />
				</div>
			</div>
		</div>}
		{!product.waranties &&
			<div className="card bg-info text-white">
				<div className="card-header">
					<div className="card-title">No records found</div>
				</div>
				<div className="card-body">
					<h4>Warranty 데이터가 존재하지 않습니다.</h4>
				</div>
			</div>
		}
		<ProductWarrantyAdd
			data={item}
			codeList={codeList}
			showModal={showModal}
			callbackClose={(e) => { setShowModal(false) }} />
	</div>
}

const mapState = (state) => {
	const product = state.ProductReducer.product;
	const clist = state.RayCodeReducer.items;
	return { clist };
};

export default connect(mapState)(ProductInfoWaranty);