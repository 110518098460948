import axios from 'axios';
import moment from 'moment';
import { AppConfig, JWT } from '../config';
import { ProductConstant } from './constants/ProductConstant';
import { ProgramConstant } from './constants/ProgramConstant';
import { AlertAction } from './AlertAction';
import { ProductReducer } from '../reducers/ProductReducer';
import { AuthAction } from './AuthAction';
import { RayDataAction } from './RayDataAction';
import { store } from '../store';

const GetStatistics = (currentPage, listCountPerPage, filter) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = { type: 'get', data: { currentPage, listCountPerPage, filter } };

	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/statistics", data, JWT());

	if (ret.data.status === 'success') {
		dispatch({ type: ProductConstant.GET_STATISTICS, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
}

const SaveStatisticsFilter = (title, filterType, condition) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });

	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/statistics", { type: 'save', data: { title, filterType, condition } }, JWT());

	if (ret.data.status === 'success') {
		dispatch({ type: ProductConstant.GET_SAVED_FILTERS, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}

	dispatch({ type: ProductConstant.LOADED });
}

const GetSavedFilters = () => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });

	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/statistics", { type: 'filterList' }, JWT());

	if (ret.data.status === 'success') {
		dispatch({ type: ProductConstant.GET_SAVED_FILTERS, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
}

const DeleteSavedFilter = (id) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });

	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/statistics", { type: 'delete', data: { id } }, JWT());

	if (ret.data.status === 'success') {
		dispatch({ type: ProductConstant.GET_SAVED_FILTERS, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}

	dispatch({ type: ProductConstant.LOADED });
}

const GetProductAllStock = (filter, append = false) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });

	const showmodel = store.getState().ProgramReducer.showmodel;
	const data = showmodel[0] !== '' ? 
		{ type: "getstock", data: {...filter, match : {...filter.match, model : { '$in' : showmodel }} } } : 
		{ type: "getstock", data: filter}

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	const vtype = "Stock";
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_PRODUCTLIST, result: ret.data.data, append, vtype });
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Products",
			count: ret.data.data.totalcount
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const GetProductAll = (filter, append = false) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });

	const showmodel = store.getState().ProgramReducer.showmodel;
	const data = showmodel[0] !== '' ? 
		{ type: "get", data: {...filter, match : {...filter.match, model : { '$in' : showmodel }} } } : 
		{ type: "get", data: filter}

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	const vtype = "All";
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_PRODUCTLIST, result: ret.data.data, append, vtype });
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Products",
			count: ret.data.data.totalcount
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const GetFavProductAll = (filter) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const uData = JSON.parse(localStorage.getItem('user'));
	const data = {
		type: "get",
		data: { ...filter, match: { sn: { '$in': uData.favorites } } }
		//data : {...filter}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_FAV_PRODUCTLIST, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

// const GetErrorCount = (days) => async (dispatch) => {
// 	dispatch({ type: ProductConstant.ERR_LOADING });
// 	const daybefore = days > 0 ? moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 : 0;
// 	const data = {
// 		type: "geterrorcount",
// 		data:  daybefore
// 	}
// 	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
// 	if (ret.data.status == 'success') {
// 		dispatch({ type: ProgramConstant.SET_ERRORS, list: ret.data.data });
// 	} else {
// 		dispatch(AlertAction.Error(ret.data));
// 	}
// 	dispatch({ type: ProductConstant.ERR_LOADED });
// };

const GetErrorCount = async (days) => {
	const daybefore = days > 0 ? moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 : 0;	
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [];
	aggregate.push({ '$unwind': '$errs' });
	if(showmodel[0] !== ''){
		aggregate.push({
			'$match' : {
				'model' : { '$in' : showmodel} ,
				'errs.closer': null,
				'errs.end': null,
				'errs.begin': { "$gte": daybefore == 0 ? 1065398400000 : daybefore },
				'installedDate': { '$gt': 1065398400000 }
			}
		});
	}else{
		aggregate.push({
			'$match' : {
				'errs.closer': null,
				'errs.end': null,
				'errs.begin': { "$gte": daybefore == 0 ? 1065398400000 : daybefore },
				'installedDate': { '$gt': 1065398400000 }
			}
		});
	}
	aggregate.push({
		'$project': {
			waranties: 0,
			autoDiagnoses: 0,
			alignments: 0,
			acquisitions: 0,
			cautions: 0,
			qcs: 0,
			histories: 0,
			sitehistories: 0,
		}
	});
	const data = {
		data : {
			type: "product",
			aggregate,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		AlertAction.Error(ret.data);
	}
};

const GetAllErrorList = (filter, showtype, append) => async (dispatch) => {
	dispatch({ type: ProductConstant.ERR_LOADING });

	const showmodel = store.getState().ProgramReducer.showmodel;
	const data = showmodel[0] !== '' ? 
		{ type: "getallerrorlists", data: {...filter, showtype: showtype, match : {...filter.match, model : { '$in' : showmodel }} } } : 
		{ type: "getallerrorlists", data: { ...filter, showtype: showtype }}

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_ERRORS, result: ret.data.data, append });
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Errors",
			count: ret.data.data.totalcount
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.ERR_LOADED });
};

const GetErrorList = (filter, append) => async (dispatch) => {
	dispatch({ type: ProductConstant.ERR_LOADING });
	const data = {
		type: "geterrorlists",
		data: { ...filter }
	}

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_ERRORS, result: ret.data.data, append });
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Errors",
			count: ret.data.data.totalcount
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.ERR_LOADED });
};

const GetErrors = (filter, append) => async (dispatch) => {
	dispatch({ type: ProductConstant.ERR_LOADING });
	const data = {
		type: "geterrors",
		data: { ...filter }
	}

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_ERRORS, result: ret.data.data, append });
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Errors",
			count: ret.data.data.totalcount
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.ERR_LOADED });
};

const GetProductLocal = async (sn) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/product/' + sn, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data
	}
	return null;
};

const GetProductOld = (sn, sninfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		type: "getold",
		data: {}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_PRODUCT_OLD, product: ret.data.data, orisninfo: sninfo });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const GetProduct = (sn) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });

	await RayDataAction.SetWarranty(sn, "HQ", "CT");

	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/product/' + sn, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_PRODUCT, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const UpdateProductWarranty = (sn, info) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });	
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateAddWarranty',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update Warranty!"));
		dispatch({ type: ProductConstant.UPDATE_PRODUCTINFO, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const AddExtendedWarranty = (sn, data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, {		
		type: 'addExtendedWarranty',
		data
	}, JWT()); 

	dispatch({ type: ProductConstant.SET_PRODUCT_WARRANTY, product: ret.data.data });
}

const UpdateProductSite = (sn, nowSiteId, newSiteId) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateSite',
		data: { nowSiteId: nowSiteId, newSiteId: newSiteId },
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update Site!"));
		dispatch({ type: ProductConstant.UPDATE_PRODUCTINFO, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const RequestErrorLogFile = (sn, errId) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'requestErrorLogs',
		data: { errorId: errId },
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.UPDATE_PRODUCTINFO, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const BulkUpdateProductInfo = (sns, productInfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updatebulk',
		list: sns,
		data: productInfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.BULKUPDATE_PRODUCTINFO, products: ret.data.data.list });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const BulkUpdateEachProductInfo = (list) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateeachbulk',
		list: list,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.BULKUPDATE_PRODUCTINFO, products: ret.data.data.list });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const UpdateProductInfo = (sn, productInfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: productInfo,
	};

	if (productInfo.hasOwnProperty("companyId"))
		await RayDataAction.SetWarranty(data.sn, "HQ", "CT");

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: ProductConstant.UPDATE_PRODUCTINFO, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const CloseProductError = (sn, errInfo, extra) => async (dispatch) => {
	dispatch({ type: ProductConstant.ERR_LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'closeError',
		data: errInfo,
		extra: extra,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.UPDATE_PRODUCTERRORINFO, result: ret.data.data, isClose: errInfo.hasOwnProperty("closer") });
		dispatch({ type: ProgramConstant.UPDATE_PRODUCTERRORINFO, result: ret.data.data, isClose: errInfo.hasOwnProperty("closer") });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.ERR_LOADED });
};

const UpdateProductError = (sn, errInfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.ERR_LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateError',
		data: errInfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.UPDATE_PRODUCTERRORINFO, result: ret.data.data, isClose: errInfo.hasOwnProperty("closer") });
		dispatch({ type: ProgramConstant.UPDATE_PRODUCTERRORINFO, result: ret.data.data, isClose: errInfo.hasOwnProperty("closer") });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.ERR_LOADED });
};

const RegistProductInfo = (productInfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'create',
		data: productInfo,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: ProductConstant.REGIST_PRODUCTINFO, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const DeleteProduct = (sn) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: ProductConstant.DELETE_PRODUCT, delproductid: sn });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const ClearUpdateResult = () => (dispatch) => {
	dispatch({ type: ProductConstant.CLEAR_UPDATERES });
};

const UpdateListFilter = (filter) => async (dispatch) => {
	function success(product) {
		return { type: ProductConstant.UPDATE_LISTFILTER, filter };
	}
};

const ChangeSN = async (sn, body) => {
	const data = {
		type: "changesn",
		who: JSON.parse(localStorage.getItem('user')).adminid,
		data: body
	}
	return await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
}

const GetProductErrorId = async (sn, producterrorCodeId) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/product/getproducterrorid/' + sn + "/" + producterrorCodeId, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}

const GetComboList = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/product/getcombo', JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}

const GetProductDirect = async (filter) => {
	const data = { type: "get", data: filter }
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	}
	return [];
};

const GetProductAllLocal = async (filter) => {
	const data = { type: "get", data: filter }
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data
	}
};

const GetProductsLocal = async (snList) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', { type: 'list' }, JWT());
	if (ret.data.status == 'success')
		return ret.data.data;
}

const GetProductRecentInstall = (days) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [];
	const fin = moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000;
	if(showmodel[0] !== ''){
		aggregate.push({
			'$match' : {
				'model' : { '$in' : showmodel} ,
				'installedDate': { '$gte': fin },
				'siteId': { '$ne': '' }
			}
		});
	}else{
		aggregate.push({
			'$match' : {
				'installedDate': { '$gte': fin },
				'siteId': { '$ne': '' }
			}
		});
	}
	aggregate.push({
		'$project' : {
			'sn': 1,
			'option': 1,
			'doa' : 1,
			'dim' : 1,
		}
	});
	const data = {
		data : { type: "product", aggregate }
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProgramConstant.SET_RECENTINSTALL, list: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const GetProductActiveCount = (days) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = { type: "getcount" }
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());

	console.log('ret',ret);

	if (ret.data.status == 'success') {
		dispatch({ type: ProgramConstant.SET_TOTALPRDCOUNT, data: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const Comment = (prodcutId, type, commentData) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type,
		data: commentData
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + prodcutId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch({
			type: type == "ADD" ?
				ProductConstant.UPDATE_PRODUCTINFO :
				ProductConstant.UPDATE_PRODUCTINFO, product: ret.data.data
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const GetErrorListLocal = async (filter) => {
	const data = {
		type: "geterrorlists",
		data: { ...filter }
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	}
};

const BulkUpdateTransferInfo = (sns, transferInfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updatetransfer',
		list: sns,
		data: transferInfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.BULKUPDATE_PRODUCTINFO, products: ret.data.data.list });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const UpdateProductHistoryReport = (sn, productHistoryInfo) => async (dispatch) => {
	dispatch({ type: ProductConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateProductHistory',
		data: productHistoryInfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: ProductConstant.UPDATE_PRODUCTINFO, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductConstant.LOADED });
};

const GetReducer = (state) => {
	return state.ProductReducer;
};

const SetProductWarranty = (sn, data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product/' + sn, {
		type: 'setproductwarranty',
		data,
		who: JSON.parse(localStorage.getItem('user')).adminid
	}, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: ProductConstant.GET_PRODUCT, product: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
}

const RegistProductSiteByUser = async (data) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/siteregistbyuser', { data });
	return ret;
}

export const ProductAction = {
	GetProductAll,
	GetProductAllStock,
	GetFavProductAll,
	UpdateProductSite,
	GetProductDirect,
	GetProduct,
	UpdateProductInfo,
	RegistProductInfo,
	UpdateListFilter,
	ClearUpdateResult,
	GetProductErrorId,
	RequestErrorLogFile,
	DeleteProduct,
	GetReducer,
	GetComboList,
	GetErrors,
	GetErrorCount,
	GetProductLocal,
	UpdateProductError,
	GetStatistics,
	GetSavedFilters,
	CloseProductError,
	SaveStatisticsFilter,
	DeleteSavedFilter,
	GetProductRecentInstall,
	GetProductActiveCount,
	BulkUpdateProductInfo,
	BulkUpdateEachProductInfo,
	Comment,
	GetErrorList,
	UpdateProductWarranty,
	GetProductAllLocal,
	GetErrorListLocal,
	GetProductOld,
	GetAllErrorList,
	ChangeSN,
	BulkUpdateTransferInfo,
	UpdateProductHistoryReport,
	SetProductWarranty,
	AddExtendedWarranty,
	RegistProductSiteByUser
};
