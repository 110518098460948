import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { ProductAction, AuthAction } from '../../actions';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { InfinteScroll } from '../../components/common/InfinteScroll';
import { history } from '../../history';
import { Loading } from '../common/Loading';
import ProductCard from './ProductCard';
import {
	LeadingActions,
	SwipeableList,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
	Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

const ProductList = ({ GetProductAll, items, totalCount, isLoading, favProducts, GetFavProductAll, UpdateUserFav, showFav = false, isNewInstall = false, lastDays = 7 }) => {

	const dispatch = useDispatch();
	const [target, setTarget] = useState(null);
	const [page, setPage] = useState(1);
	const [itemperpage, setItemPage] = useState(30);
	const [viewFav, setViewFav] = useState(showFav);
	const [list, setList] = useState([]);
	const getTime = () => { return moment.utc(moment().utc()).add(-1 * lastDays, 'days').unix() * 1000 }

	useEffect(() => {
		if (viewFav != showFav) {
			setViewFav(showFav);
			setPage(1);
			if (page == 1)
				loadItems(page);
		}
	}, [showFav]);

	useEffect(() => {
		loadItems(page);
	}, [page]);

	useEffect(() => {
		setList([...items]);

		console.log(items);

	}, [items]);

	useEffect(() => {
		if (viewFav) {
			dispatch({
				type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
				title: "Favorite Products",
				count: list.length.toLocaleString(),
			});
		}
	}, [list]);

	const loadItems = (pg) => {
		if (showFav) {
			GetFavProductAll({ itemperpage: 100, page: 1 });
		} else {
			if (isNewInstall) {
				GetProductAll({
					match: { 'installedDate': { $gte: getTime() } },
					itemperpage: itemperpage,
					page: pg
				}, true);
			} else {
				GetProductAll({ itemperpage: itemperpage, page: pg }, true);
			}
		}
	}

	const isEnd = () => { return totalCount <= page * itemperpage; }

	InfinteScroll({
		target,
		onIntersect: ([{ isIntersecting }]) => {
			if (isIntersecting && !isLoading && !isEnd()) {
				setPage(page + 1);
			}
		}
	});

	const removeArr = (id) => {
		const idx = favProducts.indexOf(id);
		if (idx > -1) {
			favProducts.splice(idx, 1);
			if (showFav)
				setList([...list.filter(x => x.sn != id)])
		}
		return favProducts;
	}

	const fav = (isFav, sn) => {
		UpdateUserFav("sn", isFav ?
			{ favorites: removeArr(sn) } :
			{ favorites: [...favProducts, sn] });
	}

	const updateFav = (sn) => {
		console.log(sn);
		fav(favProducts.filter(x => x == sn).length > 0 ? true : false, sn)
	}

	const makeAs = (item) => {
		history.push({
			pathname: "/ServiceAddNew",
			state: {
				refer: "PRODUCTNORMAL",
				item: null,
				extraItem: item.sn,
			},
		});
	}

	const leadingActions = (isFav, sn) => (
		<LeadingActions>
			<SwipeAction onClick={() => updateFav(sn)}>
				<div className={isFav ? "bg-danger text-center" : "bg-dark text-center"} style={{ width: "80px" }}>
					<ion-icon size="large" name={isFav ? "heart" : "heart-outline"}></ion-icon>
				</div>
			</SwipeAction>
		</LeadingActions>
	);

	const trailingActions = ({ item }) => (
		<TrailingActions>
			<SwipeAction onClick={() => makeAs(item)}>
				<div className="bg-info text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="build-outline"></ion-icon>
				</div>
			</SwipeAction>
		</TrailingActions>
	);

	return (
		<>
			<SwipeableList type={ListType.IOS} fullSwipe={false} className="listview image-listview">
				{list.map((item, index) =>
					<SwipeableListItem key={index}
						fullSwipe={false}
						leadingActions={leadingActions(favProducts.filter(x => x == item.sn).length > 0, item.sn)}
						trailingActions={trailingActions({ item })}>
						<ProductCard item={item} />
					</SwipeableListItem>
				)}
			</SwipeableList>
			{isLoading && <Loading />}
			<div ref={setTarget} className='last-item' />
		</>
	);
};

const mapState = (state) => {
	var items = state.ProductReducer.products;
	var totalCount = state.ProductReducer.totalproductcount;
	var isLoading = state.ProductReducer.isLoading;

	var favProducts = state.FavoriteReducer.sn;
	return { items, totalCount, isLoading, favProducts };
};

const mapDispatch = (dispatch) => ({
	GetProductAll: (filter, append) => dispatch(ProductAction.GetProductAll(filter, append)),
	GetFavProductAll: (filter) => dispatch(ProductAction.GetFavProductAll(filter)),
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
});

export default connect(mapState, mapDispatch)(ProductList);