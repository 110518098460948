import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProductAction } from '../../actions';
import { history } from '../../history';
import { Loading } from '../common/Loading';

const ProductDelete = ({ product, isUpdateRes, DeleteProduct, ClearUpdateResult }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [delCheckResult, setDelCheckResult] = useState({
        hasSite: product.siteId,
        errCount: product.errs,
        acqCount: product.acquisitions,
        warCount: product.waranties
    });

    useEffect(() => {
        setIsLoading(false)
        if (isUpdateRes) {
            ClearUpdateResult();
            history.push("/Admin/AdminProductList");
        }
    }, [isUpdateRes]);

    return (
        <>
            {/* {delCheckResult.hasSite && (
                <>
                    <div className="card bg-danger text-white">
                        <div className="card-header">
                            <div className="card-title">경고!</div>
                        </div>
                        <div className="card-body">
                            <h4>아래의 사유로 {product.sn}을/를 삭제할 수 없습니다</h4>
                        </div>
                        <ul className="list-group">
                            <li className="list-group-item"> <Link to={"/Admin/AdminSiteInfo/" + product.siteId}>{product.site.sitename}</Link>에 설치된 장비입니다.  </li>
                        </ul>
                    </div>
                </>
            )} */}
            {product && product._id && (
                <>
                    <div className="card bg-danger text-white">
                        <div className="card-header">
                            <div className="card-title">경고!</div>
                        </div>
                        <div className="card-body">
                            <h4>즉시 반영됩니다!!!</h4>
                            <p>이 작업은 되돌릴 수 없는 작업입니다.</p>
                            <p>정말 삭제하시겠습니까?</p>
                            <ul className="list-group">
                                {delCheckResult.errCount > 0 && <li className="list-group-item"> {delCheckResult.errCount}건의 Error 데이터도 같이 삭제됩니다.</li>}
                                {delCheckResult.acqCount > 0 && <li className="list-group-item"> {delCheckResult.acqCount}건의 Acquisition 데이터도 같이 삭제됩니다.</li>}
                                {delCheckResult.warCount > 0 && <li className="list-group-item"> {delCheckResult.warCount}건의 Waranty 데이터도 같이 삭제됩니다.</li>}
                            </ul>
                        </div>
                    </div>
                    <button className="btn btn-outline-danger" onClick={() =>{ setIsLoading(true); DeleteProduct(product._id); }}>Delete Product</button>
                </>
            )}
            {isLoading && <div className="" style={{ marginTop : 120 }}>
				<Loading />
			</div>}
        </>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.ProductReducer.isUpdateRes;
    return { isUpdateRes };
}

const mapDispatch = dispatch => ({
    ClearUpdateResult: () => dispatch(ProductAction.ClearUpdateResult()),
    DeleteProduct: (_id) => dispatch(ProductAction.DeleteProduct(_id))
})

export default connect(mapState, mapDispatch)(ProductDelete);