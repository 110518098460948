import { CustomFieldConstant } from '../actions/constants/CustomFieldConstant';

const initialState = {
	items : [],
	item : {},
    isLoading : false,
	totalproductcount: 0,
};

export const CustomFieldReducer = (state = initialState, action) => {
	switch (action.type) {
		case CustomFieldConstant.GET_LIST:
			return { ...state, items : [...action.items] }
		case CustomFieldConstant.UPDATE:
		case CustomFieldConstant.GET:
			return {
				...state,
				items: state.items.map((u) => (u._id == action.item._id ? action.item : u)),
				item: action.item,
			};
		case CustomFieldConstant.CREATE:
			return {
				...state,
				items : [...state.items, action.item],
				item: action.item,
			};
		case CustomFieldConstant.DELETE:
			return { ...state, items: state.items.filter((u) => u._id !== action._id), item : {}};
		case CustomFieldConstant.ADDUPDATE:
			return { ...state, item: action.item };
        case CustomFieldConstant.LOADING:
            return {...state, isLoading : true}
        case CustomFieldConstant.LOADED:
            return {...state, isLoading : false}
		default:
			return state;
	}
};
