import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { RayTable, RayTreeview, AdminErrorListHeader } from '../../components/common';
import { ProductAction } from '../../actions/ProductAction';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import CustomHeader from '../../components/common/CustomHeader';

import { Translate as t } from '../../config/Translate';

const AdminErrorList = ({ su, user, GetErrorList, CloseProductError, producterrs, filterDateOptions, producterrsmatch,
	totalproducterrcount, filterErrOptions, errfilters, errsort, companies, viewTree }) => {

	const [showModal, setShowModal] = useState(false);
	const [bulkList, setBulkList] = useState([]);
	const [bulkType, setBulkType] = useState("");

	const [header, setHeader] = useState([]);
	const [exheader, setExHeader] = useState([]);
	const [ready, setReady] = useState(false);

	const [filterData, setFilterData] = useState([]);
	const [showCHModal, setShowCHModal] = useState(false);

	const [wHg, setwHg] = useState(300);
	const [wAll, setwAll] = useState(window.innerWidth);
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });
	const dispatch = useDispatch();

	const filterpage = {
		filters: errfilters || {},
		filterOptions: filterErrOptions || [],
		filterDateOptions: filterDateOptions || [
			{ code: 'errs.begin', label: 'Begin' },
		],
		exheader: [],
		searchCols: ['errs.code', 'model', 'option', 'sn', 'site.sitename'],
		sort: errsort || { 'errs.begin': -1 },
		searchKey: "",
		page: 1,
		itemperpage: 30
	};

	var options = {
		isShowCheckBox: true,
		search: true,
		keyCol: 'errs._id',
		defaultSort: filterpage.sort && Object.keys(filterpage.sort)[0],
		defaultSortType: filterpage.sort && Object.values(filterpage.sort)[0],
		linkto: '/Admin/AdminErrorInfo',
		linkkey: ['errs._id', 'errs.code', 'sn'],
		getList: GetErrorList,
		filterpageoption: filterpage,
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalproducterrcount,
		filterOptions: filterErrOptions
	};

	useEffect(() => {
		user.adminType != "U" && updateViewTree(true);
	}, []);

	useEffect(() => {
		setReady(header.length > 0);
	}, [header]);

	useEffect(() => {
		setExHeader(user.adminheaders?.errors ? user.adminheaders.errors : []);
	}, [user.adminheaders?.errors]);

	useEffect(() => {
		setHeader(exheader.length > 0 ? AdminErrorListHeader.concat(exheader) : AdminErrorListHeader);
	}, [exheader]);

	useEffect(() => {
		if (viewTree) {
			setwHg(300);
		} else {
			setwHg(0);
		}
	}, [viewTree]);

	const getWidth = () => setwAll(window.innerWidth);

	useEffect(() => {
		// window.addEventListener('resize', getWidth);
		// setwAll(window.innerWidth);
	}, []);

	const onChangeTreeviewItem = (item) => {
		setFilterCompanyId({ companyId: item.id });
		setFilterCompany(item);
	}

	const checkedList = (list) => {
		setBulkList(list);
	}

	const defaultFilter = [
		//STRING, DATETIME, BOOLEAN
		{ code: "company.country", name: "Country", type: "COUNTRY" },
		{ code: "companyId", name: "Operator", type: "OPERATOR" },
		{ code: "codes", name: "Codes", type: "STRING" },
		{ code: "option", name: "Option", type: "OPTION" },
		{ code: "lastConnectedDate", name: "Last Connected Date", type: "DATETIME" },
		{ code: "lastDisconnectedDate", name: "Last Disconnected Date", type: "DATETIME" },
		{ code: "installedDate", name: "Install Date", type: "DATETIME" },
		{ code: "productTWId", name: "Thingworx ID", type: "STRING" },
		{ code: "sitename", name: "Site Name", type: "STRING" },
		{ code: "isConnected", name: "Is Connected", type: "BOOLEAN" },
		{ code: "dim", name: "DIM", type: "BOOLEAN" }, //STRING, DATETIME, BOOLEAN
		{ code: "doa", name: "DOA", type: "BOOLEAN" },
		{ code: "registerd", name: "Registered", type: "DATETIME" },
		{ code: "manufactured", name: "Manufactured Date", type: "DATETIME" },
	]

	const updateViewTree = (bl) => {
		dispatch({ type: ProgramConstant.VIEWTREE, val: bl });
	}
	const toggle = [
		{ code: true, title: "All", filter: {} },
		{ code: false, title: "Not Closed", filter: { 'errs.closer': null } }
	];

	const getMatchToggle = () => {
		var m = toggle.filter(x => JSON.stringify(x.filter) == JSON.stringify(producterrsmatch));
		if (m.length > 0) {
			return m[0].code;
		}
		return false;
	}

	const selectedClose = () => {
		if (confirm('Would you like to close the selected errors?')) {
			for (let i = 0; i < bulkList.length; i++) {
				const sn = producterrs.find(x => x.errs._id === bulkList[i])?.sn;
				CloseProductError(sn, { errId: bulkList[i], closer: user.adminid, end: new Date().getTime() });
			}
		}
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">Error List
					{companies.length > 1 && <button className="btn btn-secondary btn-sm mt-0 ms-1" onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>}
				</h2>
				<div className="ray-right">
					<button onClick={() => setShowCHModal(true)} className="btn btn-sm mt-0">
						<ion-icon name="options-outline"></ion-icon> {t('customheaders')}
					</button>
					<Link to="/Admin/AdminErrorDashboard" className="btn btn-sm mt-0">
						<ion-icon name="desktop-outline"></ion-icon> {t('dashboard')}
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{(viewTree && user.adminType != "U") &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>}
				<div className="section m-1 p-0">
					<div className="card p-2">
						<div className={user.adminType != "U" ? "fl" : ""}
							style={viewTree && user.adminType != "U" ? { width: wAll - (viewTree ? 354 : 68) } : {}}>
							{ready && <RayTable
								data={producterrs}
								addFilter={filterCompanyId}
								listtype="producterr"
								toggle={toggle}
								toggleval={getMatchToggle()}
								exportlocalaction={ProductAction.GetErrorListLocal}
								exheader={exheader.map(x => x.code)}
								checkBoxCallback={(list) => checkedList(list)}
								columns={header}
								options={options}
								bulkActions={[
									{ title: "Close Selected", action: "CLOSEALL" },
								]}
								bulkActionDo={selectedClose}
							/>}
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			</div>
			<CustomHeader
				extratrakey={"PRODUCTEXTRAKEY"}
				title={"Product Error List Header"}
				extratrakeyname={"extradata"}
				exHeader={exheader}
				adminheaderprefix={"errors"}
				showModal={showCHModal}
				callbackClose={() => setShowCHModal(false)}
				defaultFilter={defaultFilter} />
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var su = state.AuthReducer.su;
	var viewTree = state.ProgramReducer.viewtree;
	var companies = state.CompanyReducer.companies;
	var producterrs = state.ProductReducer.producterrs;
	var totalproducterrcount = state.ProductReducer.totalproducterrcount;
	var filterErrOptions = state.ProductReducer.filterErrOptions;
	var filterDateOptions = state.ProductReducer.filterErrDateOptions;
	var producterrsmatch = state.ProductReducer.producterrsmatch;
	var errfilters = state.ProductReducer.errfilters;
	var errsort = state.ProductReducer.errsort;
	return { user, su, producterrs, totalproducterrcount, producterrsmatch, filterErrOptions, errfilters, errsort, filterDateOptions, viewTree, companies };
};

const mapDispatch = (dispatch) => ({
	GetErrorList: (filter, showtype) => dispatch(ProductAction.GetAllErrorList(filter, showtype)),
	CloseProductError: (sn, errInfo) => dispatch(ProductAction.CloseProductError(sn, errInfo))
});

export default connect(mapState, mapDispatch)(AdminErrorList);