import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { AuthAction } from '../../actions';

const DeepSearchFilterSave = ({ showModal, callbackClose, type, callbackSave, filter, filterset, user, UpdateUserAdminFilters }) => {
	const [filters, setFilters] = useState([]);
	const [data, setData] = useState({
		type: type,
		title: "",
		description: "",
		filter: filter
	});

	useEffect(() => {
		setData({ ...data, type: type });
	}, [type]);

	useEffect(() => {
		setFilters(user.filters);
	}, [user.filters]);

	useEffect(() => {
		setData({ ...data, filter: filter });
	}, [filter]);

	useEffect(() => {
		setData(filterset);
	}, [filterset]);

	const btnSave = () => {
		var newfilters = [];
		if (filterset.id) {
			filters.map(x => {
				newfilters.push((x.id == filterset.id ? data : x))
			})
		} else {
			newfilters = [...filters, { ...data, type: type, id: new Date().valueOf() }]
		}
		UpdateUserAdminFilters({ filters: newfilters });
		callbackSave(data);
		callbackClose();
	}

	return (
		<Modal show={showModal} onHide={() => callbackClose(false)}>
			<Modal.Header>
				<h2 className="modal-title">{data.id ? "Update Filter" : "Add New Filter"}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<ul className="listview simple-listview no-space border-0 m-2">
					<div className="form-group basic">
						<div className="input-wrapper">
							<label className="label">FilterName</label>
							<input className="form-control"
								onChange={(e) => setData({ ...data, title: e.target.value })}
								defaultValue={data.title} />
						</div>
					</div>
					<div className="form-group basic">
						<div className="input-wrapper">
							<label className="label">Description</label>
							<textarea className="form-control"
								rows={5}
								onChange={(e) => setData({ ...data, title: e.target.value })}
								defaultValue={data.description}></textarea>
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<button
					className={data.title != "" ? "btn btn-block btn-primary mb-0" : "btn btn-block btn-primary disabled mb-0"}
					onClick={() => { btnSave(data); }}>Save Filter</button>
				<button className="btn btn-block btn-dark" onClick={() => callbackClose(false)}>Cancel</button>
			</Modal.Footer>
		</Modal>
	);
}

const mapState = (state) => {
	var user = state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminFilters: (uinfo) => dispatch(AuthAction.UpdateUserAdminFilters(uinfo)),
});

export default connect(mapState, mapDispatch)(DeepSearchFilterSave);