import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { AppHeader } from '../../components/common/LeftMenu';
import { history } from '../../history';
import { NotificationAction } from '../../actions/NotificationAction';
import { Modal } from 'react-bootstrap';
import { Translate as t } from '../../config/Translate';

import {
	LeadingActions,
	SwipeableList,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
	Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { NoRecord } from '../HomePage';

const NotyCard = ({ item, readCallback }) => {

	const excepts = ["updater", "updateDate", "scheId"];
	const getIcon = () => {
		switch (item.notiType) {
			case "COMMENT": return <div className="icon-box bg-primary float-start"><ion-icon name="chatbubble-outline"></ion-icon></div>;
			case "SCHEDULE": return <div className="icon-box bg-success float-start"><ion-icon name="calendar-outline"></ion-icon></div>;
			case "FAIL": return <div className="icon-box bg-danger float-start"><ion-icon name="close-outline"></ion-icon></div>;
			case "DOCUMENT": return <div className="icon-box bg-info float-start"><ion-icon name="close-outline"></ion-icon></div>;
			case "WARNING": return <div className="icon-box bg-warning float-start"><ion-icon name="alert-outline"></ion-icon></div>;
			case "INFO": return <div className="icon-box bg-info float-start"><ion-icon name="information-outline"></ion-icon></div>;
		}
	}

	const goLink = () => {
		readCallback(item);
	}

	const fromCamel = (v) => {
		const d = v.replace(/([a-z])([A-Z])/g, '$1 $2');
		return d.charAt(0).toUpperCase() + d.slice(1);
	}

	const kVal = (key, val) => {
		if (key.toUpperCase().indexOf("DATE") > -1)
			return moment(val).format("YYYY-MM-DD HH:mm");
		return val;
	}

	const getMessage = (msg) => {
		try {
			const json = JSON.parse(msg);
			var arr = [];
			Object.keys(json).forEach(function (key) {
				if (!excepts.includes(key))
					arr.push(fromCamel(key) + " : " + kVal(key, json[key]));
				//console.log('Key : ' + key + ', Value : ' + data[key])
			})
			console.log(arr);
			return arr.join(", ");
		} catch (e) {
			return msg;
		}
	}

	return (
		<li className={item.isRead ? "p-2" : "active p-2"}>
			<a onClick={() => goLink()} className="item">
				{getIcon()}
				<div className="in">
					<div>
						<div className="mb-05"><strong>{item.title}</strong></div>
						<div className="text-small mb-05">{getMessage(item.content)}</div>
						<div className="text-xsmall">{moment.utc(item.createDate).local().format('YYYY-MM-DD HH:mm:ss')}</div>
					</div>
					{!item.isRead && <div className="swipeable-list-right"><span className="badge badge-primary badge-empty"></span></div>}
				</div>
			</a>
		</li>
	)
}

const MyNotification = ({ list, GetAll, Read, UnRead, Delete, ReadAll, DeleteRead, DeleteAll, isLoading }) => {

	//const [t, i18n] = useTranslation();

	const [isAdmin, setIsAdmin] = useState(location.pathname.toLowerCase().startsWith('/admin/'));
	const [showAS, setShowAS] = useState(false);
	const [asType, setASType] = useState("");
	const [hasNew, setHasNew] = useState(false);

	useEffect(() => {
		setShowAS(false);
		setHasNew(list.filter(x => !x.isRead).length > 0);
	}, [isLoading])

	useEffect(() => {
		setHasNew(list.filter(x => !x.isRead).length > 0);
	}, [list]);

	useEffect(() => {
		GetAll();
	}, []);

	const read = (item) => {
		Read(item._id);
		console.log("/" + (isAdmin ? "Admin/Admin" + item.type : item.type) + "/" + item.refKey);
		history.push("/" + (isAdmin ? "Admin/Admin" + item.type : item.type) + "/" + item.refKey);
	}

	const leadingActions = ({ item }) => (
		<LeadingActions>
			<SwipeAction onClick={() => item.isRead ? UnRead(item._id) : Read(item._id)}>
				<div className="bg-primary text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name={item.isRead ? "mail-outline" : "mail-unread-outline"}></ion-icon>
				</div>
			</SwipeAction>
		</LeadingActions>
	);

	const trailingActions = ({ item }) => (
		<TrailingActions>
			<SwipeAction onClick={() => Delete(item._id)} destructive={true}>
				<div className="bg-danger text-center" style={{ width: "80px" }}>
					<ion-icon name="trash-outline"></ion-icon>
				</div>
			</SwipeAction>
		</TrailingActions>
	);

	const AS = (type) => {
		setASType(type);
		setShowAS(true);
	}

	return (
		<>
			{!isAdmin && <div className="appHeader no-border bg-white">
				<AppHeader title={t('notifications')} />
				<div className="right">
					{list.length > 0 && <a onClick={() => AS('read')} className="headerButton">
						<ion-icon name="notifications-outline"></ion-icon>
						{hasNew && <span className="badge badge-primary badge-empty"></span>}
					</a>}
				</div>
			</div>}

			<div id="appCapsule" className="full-height">
				{list.length === 0 && <NoRecord title={t('nonotification')} contents={t('notificationnotfound')} />}
				{list.length > 0 && <div className="section full">
					<ul className="listview image-listview flush">
						<SwipeableList type={ListType.ANDROID} fullSwipe={true}>
							{list.sort((a, b) => b.createDate - a.createDate).map((item, index) =>
								<SwipeableListItem key={item._id}
									fullSwipe={false}
									leadingActions={leadingActions({ item })}
									trailingActions={trailingActions({ item })}>
									<NotyCard item={item} readCallback={(item) => read(item)} />
								</SwipeableListItem>
							)}
						</SwipeableList>
					</ul>
				</div>
				}
			</div>

			<Modal show={showAS} className="action-sheet" dialogClassName="menu-all">
				<Modal.Body>
					<div className="border-bottom p-2">
						<h2 className="modal-title text-left">
							{t('managenotifications')}
						</h2>
					</div>
					<ul className="listview flush transparent image-listview mt-1 no-line">
						<li>
							<a href className="item" onClick={() => ReadAll()}>
								<div className="icon-box text-primary">
									<ion-icon name="mail-unread-outline"></ion-icon>
								</div>
								<div className="in">{t('readall')}</div>
							</a>
						</li>
						<li className="border-top">
							<a href className="item" onClick={() => DeleteAll()}>
								<div className="icon-box text-danger">
									<ion-icon name="trash-outline"></ion-icon>
								</div>
								<div className="in">{t('deleteall')}</div>
							</a>
						</li>
						<li className="border-top">
							<a href className="item" onClick={() => DeleteRead()}>
								<div className="icon-box text-danger">
									<ion-icon name="trash-outline"></ion-icon>
								</div>
								<div className="in">{t('deleteread')}</div>
							</a>
						</li>
						<li className="border-top">
							<a href className="item" onClick={() => setShowAS(false)}>
								<div className="icon-box text-danger">
									<ion-icon name="exit-outline"></ion-icon>
								</div>
								<div className="in">{t('cancel')}</div>
							</a>
						</li>
					</ul>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapState = (state) => {
	const list = state.NotificationReducer.list;
	const isLoading = state.NotificationReducer.isLoading;
	return { list, isLoading };
}

const mapDispatch = (dispatch) => ({
	GetAll: () => dispatch(NotificationAction.GetAll()),
	Read: (_id) => dispatch(NotificationAction.Read(_id)),
	UnRead: (_id) => dispatch(NotificationAction.UnRead(_id)),
	Delete: (_id) => dispatch(NotificationAction.Delete(_id)),
	ReadAll: () => dispatch(NotificationAction.ReadAll()),
	DeleteRead: () => dispatch(NotificationAction.DeleteRead()),
	DeleteAll: () => dispatch(NotificationAction.DeleteAll()),
});

export default connect(mapState, mapDispatch)(MyNotification);