import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LanguageCode } from '../../config';
import { AdminFunctions, AdminTypes, AdminPosition } from '../../config/RayCodes';
import { AlertAction, AdminUserAction, CompanyAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';
import { history } from '../../history';

const AdminUserAdd = ({ companyList, RegistUserInfo, ClearUpdateResult, apierrors, isUpdateRes }) => {
    const [errors, setErrors] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [user, setUser] = useState({
        isUse: true,
        adminType: AdminTypes[2].code,
        function: AdminFunctions[0].code,
        language: "eng",
        position: AdminPosition[0].code,
        password: "ray12345",
        passwordConfirm: "ray12345"
    });

    const [currentUserInfo, setCurrentUserInfo] = useState({});

    const [csLink, setCsLink] = useState("");

    useEffect(() => {
        setCurrentUserInfo(JSON.parse(localStorage.getItem('user')));
    }, []);

    const setCompanySelect = () => {
        const cList = [];
        companyList.map((c) => cList.push({ code: c.companyId, title: c.name }));
        setCompanies(cList);
        setUser({ ...user, companyId: cList[0].code });
    }

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "adminid", label: "Amdin ID", val: user.adminid, required: true },
                { type: "select2", name: "companyId", label: "Company", val: user.companyId, list: companies, required: true },
            ]
        },
        {
            title: "", items: [
                { type: "password", name: "password", label: "Password", val: "ray12345", required: true },
                { type: "password", name: "passwordConfirm", label: "Password Confirm", val: "ray12345", required: true },
            ]
        },
        {
            title: "", items: [
                {
                    type: "select",
                    name: "adminType",
                    label: "Authority",
                    val: user.adminType,
                    list: AdminTypes.filter(x => {
                        return currentUserInfo.adminType == 'S' && (x.code == 'S' || x.code == 'M' || x.code == 'U') ||
                            currentUserInfo.adminType == 'M' && (x.code == 'M' || x.code == 'U') ||
                            currentUserInfo.adminType == 'U' && x.code == 'U'
                    }),
                    required: true
                },
                { type: "select", name: "function", label: "Role", val: user.function, list: AdminFunctions, required: true },
                { type: "select", name: "position", label: "Position", val: user.position, list: AdminPosition, required: true }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "firstName", label: "First Name", val: user.firstName, required: true },
                { type: "input", name: "lastName", label: "Last Name", val: user.lastName, required: true },
            ]
        },
        {
            title: "", items: [
                { type: "email", name: "email", label: "Email", val: user.email, required: true },
                { type: "select", name: "language", label: "Language", val: user.language, list: LanguageCode, required: true },
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "hp", label: "Mobile", val: user.hp },
                { type: "tel", name: "tel", label: "Tel", val: user.tel },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "csPortalLink", label: "CS Portal Link", size: 12, val: csLink },
            ]
        },
    ]

    useEffect(() => {
        //GetCompany();
    }, []);

    useEffect(() => {
        if (companyList.length > 0)
            setCompanySelect();
    }, [companyList]);

    useEffect(() => {
        setErrors(apierrors);
    }, [apierrors]);

    useEffect(() => {
        if (isUpdateRes) {
            ClearUpdateResult();
            history.push("/Admin/AdminUserList");
        }
    }, [isUpdateRes]);

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        if (name == "companyId") {
            const orgType = companyList.filter(x => x.companyId == value)[0].org;
            if (orgType != "Branch" || orgType != "HQ") {
                setCsLink("https://partner.raymedical.com/?autologin_code=IKMqLobcisfFYxvQge6rzEGo1DZfLJ");
                setUser({ ...user, ["csPortalLink"]: "https://partner.raymedical.com/?autologin_code=IKMqLobcisfFYxvQge6rzEGo1DZfLJ" });
            } else {
                setCsLink("https://partner.raymedical.com/?autologin_code=hkggy1y3eLES9kmAYMhiQBjoWdHGta");
                setUser({ ...user, ["csPortalLink"]: "https://partner.raymedical.com/?autologin_code=hkggy1y3eLES9kmAYMhiQBjoWdHGta" });
            }
        }

        if (name == "csPortalLink") {
            setCsLink(value);
        }

        setUser({ ...user, [name]: value });
    };

    const handleRegist = (e) => {
        const errValidate = RayFormValidation(form_controls, user);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            if (user.password == user.passwordConfirm) {
                RegistUserInfo(user);
            } else {
                setErrors([{ name: "passwordConfirm", msg: "패스워드를 확인해주세요." }]);
            }
        }
    };

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Add User</h2>
                <div className="ray-right">
                    <Link className="btn btn-outline-default m-l-5" to="/Admin/CompanyList">Back</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1">
                    <RayForm
                        controls={form_controls}
                        isForm={true}
                        handleChangeData={handleChangeData}
                        errors={errors} />
                    <button className="btn btn-outline-primary ms-2 me-2" onClick={handleRegist}>Add User</button>
                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.AdminUserReducer.isUpdateRes;
    const companyList = state.CompanyReducer.companies;
    const apierrors = state.AlertReducer.errors;

    return { companyList, isUpdateRes, apierrors };
}

const mapDispatch = dispatch => ({
    RegistUserInfo: (userinfo) => dispatch(AdminUserAction.RegistUserInfo(userinfo)),
    ResultSuccess: (msg) => dispatch(AlertAction.Success(msg)),
    ResultError: (msg) => dispatch(AlertAction.Error(msg)),
    //GetCompany: () => dispatch(CompanyAction.GetCompany()),
    ClearUpdateResult: () => dispatch(AdminUserAction.ClearUpdateResult())
})

export default connect(mapState, mapDispatch)(AdminUserAdd);