import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppHeader } from '../components/common';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import HomeCardHello from '../components/home/HomeCardHello';
import HomeCardProducts from '../components/home/HomeCardProducts';
import HomeCard from '../components/home/HomeCard';
import HomeAlaram from '../components/home/HomeAlaram';
import { ProductAction, ServiceAction, CalendarAction, SiteSurveyAction, InstallationAction, TranslateAction } from '../actions';
import ProductErrorCard from '../components/product/ProductErrorCard';
import ServiceCard from '../components/service/ServiceCard';
import { CalendarConstant } from '../actions/constants/CalendarConstant';
import { ProgramConstant } from '../actions/constants/ProgramConstant';
import ScheduleCreate from '../components/schedule/ScheduleCreate';
import moment from 'moment';
import { CalSchHome } from './mytask/MyCalendar';
import ProductCard from '../components/product/ProductCard';
import SurveyList from '../components/sitesurvey/SurveyList';
import InstallationList from '../components/installation/InstallationList';
import { Loading } from '../components/common/Loading';
import { Translate as t } from '../config/Translate';
import { InputCombo, InputSelect2, InputSelect2WithGroup } from '../components/common/Inputs';

const NoRecord = ({ title, contents, widthsection = false, _sn }) => {
	return (
		<div className={widthsection ? "section" : ""}>
			<div className="card"
				style={
					_sn ? {
						boxShadow: "4px 4px 4px darkgrey",
					} : {}
				}>
				<div className="card-body pdB0">
					<div className="mt-3 mb-5 no-data">
						<div className="iconbox mb-1">
							{/* <ion-icon name="checkmark-outline" role="img" className="md hydrated" aria-label="checkmark outline"></ion-icon> */}
							<ion-icon name="sync-outline" role="img" className="md hydrated" aria-label="checkmark outline"></ion-icon>
						</div>
						<h2 className="mb-1">{title || t('norecords')}</h2>
						<p>{contents || t('therecorddoesnotexist')}</p>
					</div>
				</div>
			</div>
		</div>
	)
}
const HomePage = ({ showtab, user, homeSplide,
	homeListErr, homeListSched, homeListOverdue,
	GetProductRecentInstall, GetProductActiveCount,
	homeSSUnfinishedCount, homeINSTUnfinishedCount, showmodel,
	homeListRecentInstall, clist,
	GetErrorCount, GetAllHomeOverdueByFilter, translate }) => {

	const beforDays = user?.rguardSettingHomeErrorScope == undefined ? 1 : user?.rguardSettingHomeErrorScope;
	const schedScope = user?.rguardSettingHomeUpcomingScope || 7;
	const overdueDays = user?.rguardSettingHomeOverdueScope == undefined ? 7 : user?.rguardSettingHomeOverdueScope;
	const recentInstalldays = user?.rguardSettingHomeRecentInstallScope || 7;

	// const [t] = useTranslation();

	const dispatch = useDispatch();
	const [showAS, setShowAS] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showType, setShowType] = useState(showtab);
	const [errCount, setErrCount] = useState([]);
	const [overDue, setOverDue] = useState([]);
	const [docCount, setDocCount] = useState(0);
	const [serviceCount, setServiceCount] = useState([]);
	const [schedCount, setSchedCount] = useState([]);
	const [recentInstall, setRecentInstall] = useState([]);
	const [dListS, setDListS] = useState([]);
	const [dListI, setDListI] = useState([]);
	const [showselmodel, setshowselmodel] = useState(false);
	const [splideIndex, setSplideIndex] = useState(homeSplide);
	const [sort, setSort] = useState("asc");

	const [tab, setTab] = useState("survey");

	useEffect(() => {
		setLoading(true);
		getErrCount();
		getServiceCount();
		getScheCount();
		getRecentInstall();
		getDocCount();
		setLoading(false);
	}, [showmodel]);

	// useEffect(() => { setErrCount(homeListErr); setLoading(false); }, [homeListErr]);
	useEffect(() => {
		homeListOverdue.sort((a, b) => b.updateDate - a.updateDate);
		setOverDue(homeListOverdue);
		setLoading(false);
	}, [homeListOverdue]);
	// useEffect(() => { setSchedCount(homeListSched); setLoading(false); }, [homeListSched]);
	useEffect(() => { setShowType(showtab); setLoading(false); }, [showtab]);
	useEffect(() => { setRecentInstall(homeListRecentInstall); setLoading(false); }, [homeListRecentInstall]);
	// useEffect(() => { setDocCount(homeINSTUnfinishedCount + homeSSUnfinishedCount); setLoading(false); }, [homeSSUnfinishedCount, homeINSTUnfinishedCount]);

	const getScheCount = async () => {
		setSchedCount(-1);
		const fin = moment
			.utc(moment().utc())
			.add(1 * schedScope, 'days').unix() * 1000
		var start = moment().utcOffset(0);
		start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		start = moment(start).unix() * 1000
		const ret = await CalendarAction.GetAllHomeByFilter({ start: { '$gte': start, '$lte': fin } });
		setSchedCount(ret);
		//GetAllHomeSched({ start: { '$gte': start, '$lte': fin } });
	}

	const getDocCount = async () => {
		setDListS(-1);
		setDListI(-1);
		const inprog_survey = await SiteSurveyAction.GetCountNotFinished();
		const inprog_install = await InstallationAction.GetCountNotFinished();
		setDListS(inprog_survey);
		setDListI(inprog_install);
	}

	const getRecentInstall = async () => {
		GetProductRecentInstall(recentInstalldays);
	}

	const getErrCount = async () => {
		setErrCount(-1);
		const ret = await ProductAction.GetErrorCount(beforDays);
		setErrCount(ret);
		//GetErrorCount(beforDays);
	}

	const getServiceCount = async () => {
		setServiceCount(-1);
		setOverDue(-1);
		let list = await ServiceAction.GetServiceOpenCount(beforDays);
		if (list) {
			list.sort((a, b) => b.updateDate - a.updateDate);
			setServiceCount(list);
			
			const fin = overdueDays > 0 ? moment.utc(moment().utc()).add(-1 * overdueDays, 'days').unix() * 1000 : 4102444800000;
			setOverDue(list.filter(x => x.createDate <= fin));
		}
	}

	const getSortServiceList = () => {
		setSort(sort == "asc" ? "desc" : "asc");
		return sort == "asc" ?
			serviceCount.sort((a, b) => a.updateDate - b.updateDate) :
			serviceCount.sort((a, b) => b.updateDate - a.updateDate);
	}

	const getSortOverdueList = () => {
		setSort(sort == "asc" ? "desc" : "asc");
		return sort == "asc" ?
			overDue.sort((a, b) => a.updateDate - b.updateDate) :
			overDue.sort((a, b) => b.updateDate - a.updateDate);
	}

	function errSort(valuePath, array, sortType) {
		let path = valuePath.split('.')
		return sortType == "asc" ?
			array.sort((a, b) => getValue(b, path) - getValue(a, path)) :
			array.sort((a, b) => getValue(a, path) - getValue(b, path));

		function getValue(obj, path) {
			path.forEach(path => obj = obj[path])
			return obj;
		}
	}

	const showList = (tp) => {
		if (tp != showtab) {
			setLoading(true);
			dispatch({ type: ProgramConstant.SHOW_TAB_MAIN, tp });
		}
	}

	const schCallback = (item) => {
		dispatch({ type: CalendarConstant.SET_NEW, item: item });
		setShowAS(true);
	}

	const tabList = [
		{ code: "survey", title: t("preinstallreport"), icon: "" },
		{ code: "installation", title: t("installation"), icon: "" },
	]

	const goAdmin = () => {
		window.location = "/Admin/AdminServiceList";
	}

	const dayDays = (daycount) => {
		return daycount == 1 ? t('day') : t('days');
	}

	const getModels = () => {
		return [
			{ code : '', title : 'All Model' }, 
			...[...new Set(clist?.filter(x => x.type === 'PRODUCTOPTION').map(x => x.group))].map(x => ({ code : x, title : x }))
		];
	}

	console.log(showmodel);
	
	return (
		<>
			<div className="appHeader full-color text-dark main">
				<AppHeader prev={false} />
				<div className="right">
					<a className="headerButton" onClick={() => goAdmin()}>
						<ion-icon className="icon md hydrated" size="" name="desktop-outline" role="img" aria-label="desktop outline"></ion-icon>
					</a>
					<HomeAlaram />
				</div>
			</div>
			<div id="appCapsule">
				<div className='section'>
					{user && <HomeCard
						beforDays={beforDays}
						overDue={overDue.length}
						serviceCount={serviceCount ? serviceCount.length : 0}
						schedCount={schedCount === -1 ? '...' : (schedCount?.length || 0)}
						recentInstalldays={recentInstalldays}
						errCount={errCount === -1 ? '...' : errCount?.length}
						docCount={(dListI === -1 && dListS === -1) ? 
							'...' :
							(dListI ? dListI?.length : 0) + (dListS ? dListS?.length : 0)}
						docssCount={docCount}
						docinstCount={docCount}
						recentInstallCount={recentInstall?.length || 0}
						callback={showList}
					/>}
				</div>
				{loading && <Loading />}
				{!loading && <>
					{showselmodel && <div className="section mt-2 mb-2">
						<InputSelect2WithGroup options={{
							name: "showmodel",
							list: getModels(),
							isMulti : true,
							onlyitems : true,
							val: showmodel || [''],
						}} onchange={(e) => {
							let sm = ['']
							if(e.target.value.length > 1){
								if(e.target.value[e.target.value.length - 1] === ''){
									sm = [''];
								}else{
									sm = e.target.value.filter(n => n !== '')
								}
							}
							dispatch({ type: ProgramConstant.SHOW_MODEL, showmodel : sm });
						}  } editable={true} style />
						<button className="btn btn-sm btn-block btn-secondary m-0 me-1 py-4"
							onClick={() => setshowselmodel(!showselmodel)}>
							{t('close')}
						</button>
					</div>}
					{!showselmodel && <>
						{showType == "overdue" && <div className="section mt-2 mb-2">
							<div className='p-0 row align-items-center'>
								<div className='col'>
									<div className="section-title">{t('overdue')} ({overdueDays == 0 ? t('all') : overdueDays + dayDays(overdueDays)})</div>
								</div>
								<div className='col d-flex align-items-center justify-content-end'>
									<div className='text-right ms-1'>
										<button className="btn btn-sm btn-warning m-0 me-1 py-2"
											onClick={() => setshowselmodel(!showselmodel)}>
											{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
										</button>
										<button className="btn btn-sm btn-secondary m-0"
											onClick={(e) => getSortOverdueList()}>
											{t('sort')} : {sort == "asc" && <ion-icon name="arrow-down-outline"></ion-icon>}
											{sort == "desc" && <ion-icon name="arrow-up-outline"></ion-icon>}
										</button>
									</div>
								</div>
							</div>
							{overDue === -1 && <NoRecord title={t('wait')} contents={t('pleasewaitasecond')} />}
							{overDue.length === 0 && <NoRecord title={t('noservices')} contents={t('nooverduetasks')} />}
							{overDue.length > 0 && <>
								{overDue.map(x => <ServiceCard item={x} key={"service" + x._id} byHome={true} css="badge-danger" />)}
							</>}
						</div>}
						{showType == "service" &&
							<div className="section mt-2 mb-2">
								<div className='p-0 row align-items-center'>
									<div className='col'>
										<div className="section-title">{t('serviceongoing')}</div>
									</div>
									<div className='col d-flex align-items-center justify-content-end'>
										<div className='text-right ms-1'>
											<button className="btn btn-sm btn-warning m-0 me-1 py-2"
												onClick={() => setshowselmodel(!showselmodel)}>
												{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
											</button>
											<button className="btn btn-sm btn-secondary m-0 py-2"
												onClick={(e) => getSortServiceList()}>
												{t('sort')} {sort == "asc" && <ion-icon name="arrow-down-outline"></ion-icon>}
												{sort == "desc" && <ion-icon name="arrow-up-outline"></ion-icon>}
											</button>
										</div>
									</div>
								</div>
								{serviceCount === -1 && <NoRecord title={t('wait')} contents={t('pleasewaitasecond')} />}
								{serviceCount.length === 0 && <NoRecord title={t('noservices')} contents={t('nooverduetasks')} />}
								{serviceCount.length > 0 && <>{serviceCount.map(x => <ServiceCard item={x} key={"service" + x._id} byHome={true} />)}</>}
							</div>}
						{showType == "sched" && <div className="section mt-2 mb-2">
							<div className='p-0 row align-items-center'>
								<div className='col'>
									<div className="section-title">{t('schedules')} ({schedScope} {dayDays(schedScope)})</div>
								</div>
								<div className='col d-flex align-items-center justify-content-end'>
									<div className='text-right ms-1'>
										<button className="btn btn-sm btn-warning m-0 me-1 py-2"
											onClick={() => setshowselmodel(!showselmodel)}>
											{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
										</button>
									</div>
								</div>
							</div>
							{/* {schedCount.length === 0 && <NoRecord title={t('calnoschedule')} contents={t('noupcomingschedule') + ' ' + schedScope + " " + dayDays(schedScope)} />} */}
							{schedCount.length === 0 && <NoRecord title={t('calnoschedule')} />}
							{schedCount.length > 0 && <CalSchHome items={schedCount} schCallback={(item) => schCallback(item)} standAlone={true} />}
						</div>}
						{showType == "err" && <div className="section mt-2 mb-2">
							<div className='p-0 row align-items-center'>
								<div className='col'>
									<div className="section-title">{t('errors')} ({beforDays == 0 ? t('all') : beforDays + ' Days'})</div>
								</div>
								<div className='col d-flex align-items-center justify-content-end'>
									<div className='text-right ms-1'>
										<button className="btn btn-sm btn-warning m-0 me-1 py-2"
											onClick={() => setshowselmodel(!showselmodel)}>
											{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
										</button>
									</div>
								</div>
							</div>
							{errCount === -1 && <NoRecord title={t('wait')} contents={t('pleasewaitasecond')} />}
							{errCount.length === 0 && <NoRecord title={t('noerrors')} contents={t('noerrorsoccurred') + beforDays + " " + dayDays(beforDays)} />}
							{errCount.length > 0 && <>
								{errCount.map(x => <ProductErrorCard item={x} key={x._id} />)}
							</>}
						</div>}
						{showType == "doc" && <div className="section mt-2 mb-2">
							<div className='p-0 row align-items-center'>
								<div className='col'>
								</div>
								<div className='col d-flex align-items-center justify-content-end'>
									<div className='text-right ms-1'>
										<button className="btn btn-sm btn-warning m-0 me-1 py-2"
											onClick={() => setshowselmodel(!showselmodel)}>
											{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
										</button>
									</div>
								</div>
							</div>
							<ul className="nav nav-tabs style2 mt-1 mb-2 shadow">
								{tabList.map(x =>
									<li className="nav-item">
										<button key={x.code}
											className={tab == x.code ? "nav-link active" : "nav-link"}
											onClick={() => { setTab(x.code); }}>
											{x.title}
										</button>
									</li>
								)}
							</ul>
							<div className="mt-2 mb-2">
								{tab === "survey" && <SurveyList showAS={showAS} callbackAS={() => setShowAS(false)} fixList={dListS === -1 ? [] : dListS} fix={true} />}
								{tab === "installation" && <InstallationList showAS={showAS} callbackAS={() => setShowAS(false)} fixList={dListI === -1 ? [] : dListI} fix={true} />}
							</div>
						</div>}
						{showType == "recentInstall" && <div className="section mt-2 mb-2">
							<div className='p-0 row align-items-center'>
								<div className='col'>
									<div className="section-title">{t('newinstalled')} ({recentInstalldays} {dayDays(recentInstalldays)})</div>
								</div>
								<div className='col d-flex align-items-center justify-content-end'>
									<div className='text-right ms-1'>
										<button className="btn btn-sm btn-warning m-0 me-1 py-2"
											onClick={() => setshowselmodel(!showselmodel)}>
											{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
										</button>
									</div>
								</div>
							</div>
							{recentInstall.length === 0 && <NoRecord title={t('norecords')} contents={t('noinstalledproduct')} />}
							{recentInstall.length > 0 && <div className="">
								{recentInstall.map(x => <div className="card mb-1">
									<ProductCard item={x} key={"recentprd" + x._id} />
								</div>)}
							</div>}
						</div>}
					</>}
				</>}
			</div>
			<ScheduleCreate showAS={showAS} callback={() => setShowAS(false)} />
		</>
	);
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const homeSplide = state.ProgramReducer.homeSplide;
	const showmodel = state.ProgramReducer.showmodel;
	const showtab = state.ProgramReducer.showtabmain;
	const homeListErr = state.ProgramReducer.homeListErr;
	const homeListService = state.ProgramReducer.homeListService;
	const homeListSched = state.ProgramReducer.homeListSched;
	const homeListOverdue = state.ProgramReducer.homeListOverdue;
	const homeListRecentInstall = state.ProgramReducer.homeListRecentInstall;
	const clist = state.RayCodeReducer.items;

	const homeSSUnfinishedCount = state.ProgramReducer.homeListTotalSSUnfinishedCount;
	const homeINSTUnfinishedCount = state.ProgramReducer.homeListTotalINSTUnfinishedCount;

	return {
		user, showtab, homeSplide, showmodel,
		homeListErr, homeListService, homeListSched, homeListOverdue, homeListRecentInstall,
		homeSSUnfinishedCount, homeINSTUnfinishedCount, clist
	};
};

const mapDispatch = dispatch => ({
	GetErrorCount: beforDays => dispatch(ProductAction.GetErrorCount(beforDays)),
	GetProductRecentInstall: days => dispatch(ProductAction.GetProductRecentInstall(days)),
	GetProductActiveCount: () => dispatch(ProductAction.GetProductActiveCount()),
	//GetAllHomeSched: filter => dispatch(CalendarAction.GetAllHomeByFilter(filter)),
	//GetCountNotFinishedInstall: () => dispatch(InstallationAction.GetCountNotFinished()),
	GetAllHomeOverdueByFilter: days => dispatch(ServiceAction.GetAllHomeOverdueByFilter(days)),
});

export default connect(mapState, mapDispatch)(HomePage);
export {
	NoRecord
}
