/*
    RAY00003 : Unknown
    RAY00010 : Ray America
    RAY00013 : Ray Europe GmbH
    RAY00015 : Ray Japan
    RAY00100 : Ray China
    RAY00016 : RAY TAIWAN
    RAY02077 : RAY France
*/

export const PIContents = [
    {
        companyId: ['RAY00010', 'RAY00016', 'RAY00003'],
        deliveryDetails: [
            { seq: 0, contents: 'Delivery date: Delivery date will be informed upon received of signed proforma invoice.' }
        ],
        paymentTerm: [
            { seq: 0, contents: '100% T/T 180 days from B/L Date' },
            { seq: 1, contents: 'Wire transfer Bank copy for payment should be sent to Ray before shipping from factory' },
            { seq: 2, contents: 'Terms of Delivery : EXW' },
        ],
        paymentDetails: [
            { seq: 0, contents: 'Bank : Woori Bank 203 Hoehyon-dong, 1-gka, Chung-gku, Seoul 100-792, Korea' },
            { seq: 1, contents: 'Branch : BUNDANG PARK TOWN BRANCH' },
            { seq: 2, contents: 'Swift Code : HVBKKRSE XXX' },
            { seq: 3, contents: 'Account Name : RAY Co., Ltd.' },
            { seq: 4, contents: 'Account Number : 1081-700-444830' },
        ]
    },
    {
        companyId: ['RAY00100'],
        deliveryDetails: [
            { seq: 0, contents: 'Delivery Date: Delivery date will be reconfirmed upon received of payment.' },
            { seq: 1, contents: 'Delivery Terms : EXW' },
        ],
        paymentTerm: [
            { seq: 0, contents: '100% T/T at 60 days from B/L date.' },
            { seq: 1, contents: 'The exact amount on the proforma invoice shall be deposited to Ray\'s account.Sender\'s charges shall be covered by you.' },
            { seq: 2, contents: 'It is required to state \'Ref. No. of proforma invoice\' on the remittance slip.' },
            { seq: 2, contents: 'Wire Transfer Bank Copy for payment should be sent to Ray right after the remittance.' },
        ],
        paymentDetails: [
            { seq: 0, contents: 'Bank : Woori Bank 203 Hoehyon-dong, 1-gka, Chung-gku, Seoul 100-792, Korea' },
            { seq: 1, contents: 'Branch : BUNDANG PARK TOWN BRANCH' },
            { seq: 2, contents: 'Swift Code : HVBKKRSE XXX' },
            { seq: 3, contents: 'Account Name : RAY Co., Ltd.' },
            { seq: 4, contents: 'Account Number : 1081-700-444830' },
        ]
    },
    {
        companyId: ['RAY00015'],
        deliveryDetails: [
            { seq: 0, contents: 'Delivery date: Delivery date will be informed upon received of signed proforma invoice.' },            
        ],
        paymentTerm: [
            { seq: 0, contents: '100% T/T 180 days from B/L Date' },
            { seq: 1, contents: 'Wire transfer Bank copy for payment should be sent to Ray before shipping from factory' },
            { seq: 2, contents: 'Terms of Delivery : EXW' },            
        ],
        paymentDetails: [
            { seq: 0, contents: 'Bank : INDUSTRIAL BANK OF KOREA' },
            { seq: 1, contents: 'Branch: SUWON BANKING CENTER' },
            { seq: 2, contents: 'Swift Code: IBKOKRSEXXX' },
            { seq: 3, contents: 'Account Name : RAY Co., Ltd.' },
            { seq: 4, contents: 'Account Number: 014-550740-56-00017' },
        ]
    },
    {
        companyId: ['RAY00013'],
        deliveryDetails: [
            { seq: 0, contents: 'Delivery date: Delivery date will be informed upon received of signed proforma invoice.' },            
        ],
        paymentTerm: [
            { seq: 0, contents: '100% T/T 180 days from B/L Date' },
            { seq: 1, contents: 'Wire transfer Bank copy for payment should be sent to Ray before shipping from factory' },            
        ],
        paymentDetails: [
            { seq: 0, contents: 'Bank : Woori Bank 203 Hoehyon-dong, 1-gka, Chung-gku, Seoul 100-792, Korea' },
            { seq: 1, contents: 'Branch : BUNDANG PARK TOWN BRANCH' },
            { seq: 2, contents: 'Swift Code : HVBKKRSE XXX' },
            { seq: 3, contents: 'Account Name : RAY Co., Ltd.' },
            { seq: 4, contents: 'Account Number : 1081-200-547245' },
        ]
    },
]