import React from "react";
import ReactExport from "react-export-excel";
import moment from 'moment';
import { CountryCode } from '../../config/CountryCode';
import { Translate as t } from '../../config/Translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class ExportExcelClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			proc: false,
			css: this.props.css || "btn btn-lg btn-primary rayexport m-0"
		};
	}
	render() {
		if (this.props.data) {
			return (
				<ExcelFile filename={this.props.data.filename}
					element={<button className={this.state.css}
						disabled={this.state.proc ? true : this.props.disabled}>{this.state.proc ? "Exporting..." : (this.props.title || t('export'))}</button>}>
					<ExcelSheet data={this.props.data.items} name={this.props.data.sheettitle}>
						{
							this.props.data.headers && this.props.data.headers.map((x, idx) => <ExcelColumn
								key={idx}
								label={x.label}
								alignment={x.alignment && x.alignment}
								value={x.name} />)
						}
					</ExcelSheet>
				</ExcelFile>
			);
		} else {
			return <></>
		}
	}
}

export class ExportExcelHideElementClass extends React.Component {
	render() {
		const valueFormat = (format, value) => {
			let result = "";

			if (format == "COUNTRY") {
				CountryCode.map(item => {
					if (item.code == value)
						result = item.title;
				});
			}
			else if (format == "DT")
				result = moment(value).format("YYYY-MM-DD HH:mm:ss");
			else
				result = value;

			return result;
		}

		if (this.props.data.items) {
			const data = this.props.data.items.map(item => {
				var result = {};

				this.props.data.headers.forEach(header => {
					if (header.name.indexOf('.') > -1) {
						const p = header.name.split('.')[0];
						const c = header.name.split('.')[1];
						result[`${p}_${c}`] = item.hasOwnProperty(p) ? valueFormat(header.type, item[p][c]) : '';
					} else {
						result[header.name] = valueFormat(header.type, item[header.name]);
					}
				});

				return result;
			});

			const header = this.props.data.headers.map(item => {
				return { ...item, name: item.name.replace('.', '_') }
			})

			return (
				<ExcelFile filename={this.props.data.filename} hideElement={true} >
					<ExcelSheet data={this.props.data.items} name={this.props.data.sheettitle}>
						{
							header.map(x => <ExcelColumn
								label={x.label}
								alignment={x.alignment && x.alignment}
								value={x.name} />)
						}
					</ExcelSheet>
				</ExcelFile>
			);
		} else {
			return <></>
		}
	}
}