import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const UserSimpleCard = ({ user }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const getKey = () => (typeof yourVariable === 'object' && yourVariable !== null) ? user.adminid : user;
	const getLink = () => isAdmin ? "/Admin/AdminUserInfo/" + getKey() : "/UserInfo/" + getKey();
	
	return (
		<Link to={getLink()}>{getKey()}</Link>
	)
}

export const ProductLinkText = ({ product }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const getKey = () => (typeof yourVariable === 'object' && yourVariable !== null) ? product.sn : product;
	const getLink = () => isAdmin ? "/Admin/AdminProductInfo/" + getKey() : "/ProductInfo/" + getKey();
	
	return (
		<Link to={getLink()}>{getKey()}</Link>
	)
}