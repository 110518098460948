import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductAction, AlertAction, AuthAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { DeviceModels } from '../../config/RayCodes';
import { useTranslation } from 'react-i18next';
import { RayCodeAction } from '../../actions';
import { Translate as t } from '../../config/Translate';

const ProductFavInfo = ({ favList, productInfo, editable, clist }) => {

	//const [ t ] = useTranslation();
	const [codeList, setCodeList] = useState([]);
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [product, setProduct] = useState(productInfo);
	const [table, setTable] = useState([]);
	const [hasRow, setHasRow] = useState(false);
	const [favItems, setFavItems] = useState([]);

	useEffect(() => {
		setFavItems(favList || []);
	}, [favList]);

	useEffect(() => {		
		setCodes();
	}, [clist]);

	const setCodes = async () => {
		setCodeList(clist.filter(x => x.type === 'PRODUCTEXTRAKEY'));
		//setCodeList(await RayCodeAction.GetAllByFilter({ type: "PRODUCTEXTRAKEY" }));
	}

	useEffect(() => {
		makeList();
	}, [codeList, favItems]);

	const getFType = (m) => {
		if (m.datatype == "STRING") {
			return "input"
		} else if (m.datatype == "DATETIME") {
			return "datetime"
		} else if (m.datatype == "BOOLEAN") {
			return "checkbox"
		}
		return "input"
	}

	const groupBy = (items, key) => items.reduce(
		(result, item) => ({ ...result, [item[key]]: [...(result[item[key]] || []), item,], }), {},
	);

	const makeList = () => {
		var items = [];
		var objList = groupBy(codeList, "group");
		Object.keys(objList).map(t => {
			items = items.concat(
				objList[t].filter(y => favItems.includes("extradata." + y.code)).map(m => {
					var ftype = getFType(m);
					return {
						type: ftype,
						name: m.code,
						label: m.name,
						val: productInfo.extradata && productInfo.extradata[m.code],
						inline: true,
						isEditable: editable
					}
				})
			)
		})
		setTable([{ title: "", items: items }]);
		setHasRow(items.length > 0);
	}

	return <>
		{hasRow && <div className="mb-1">
			<RayForm controls={table} showAllFields={true} nosection={true} />
		</div>}
	</>
};

const mapState = (state) => {
	const prd = state.ProductReducer.product;
	var favList = state.FavoriteReducer.productoptions;
	const clist = state.RayCodeReducer.items;
	return { prd, favList, clist };
};

export default connect(mapState)(ProductFavInfo);
