import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ProductAction } from '../../actions';
import { UserSimpleCard } from '../../components/common/SimpleLabel';
import { Translate as t } from '../../config/Translate';

const ProductComments = ({ product, Comment }) => {
	const [comment, setComment] = useState("");
	const [showAddbox, setShowAddbox] = useState(false);

	const handleChangeData = (e) => {
		setComment(e.target.value);
	}
	const AddComment = () => {
		const commentData = {
			comment: comment
		}
		setComment("");
		setShowAddbox(false);
		Comment(product._id, "addComment", commentData);
	}

	const removeComment = (commentId) => {
		const commentData = {
			commentId: commentId,
		}
		Comment(product._id, "delComment", commentData);
	}

	const showDateTime = (r) => {
		var rList = [];
		rList.push(moment.utc(r).local().fromNow());
		rList.push(<small key='sm_00'>({moment.utc(r).local().format('YYYY-MM-DD HH:mm:ss')})</small>);
		return rList;
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(<React.Fragment key={`n_${idx}`}>{line}<br /></React.Fragment>);
			})
		} else {
			return "N/A";
		}
		return html;
	}

	return (
		<>
			{product.comments &&
				<div className="timeline ms-3">
					{product.comments.map((c, idx) =>
						<div key={`comment_${idx}`} className="item">
							<div className="dot bg-primary"></div>
							<div className="content">
								<div className="title">
									<UserSimpleCard user={c.creator} />/ {showDateTime(c.createDate)}
								</div>
								<h4 className="text">{nl2br(c.comment)}</h4>
							</div>
						</div>
					)}
				</div>
			}
			{!product.orisn && <>
				{!showAddbox &&
					<div className="p-1">
						{!product.comments && <div className="text-muted mt-1 mb-1">{t('nocomments')}</div>}
						<button className="btn btn-primary btn-block" value="" onClick={(e) => setShowAddbox(true)}>{t('addcomments')}</button>
					</div>
				}
				{showAddbox &&
					<div className="m-b-5 clearfix p-1">
						<textarea name="comment" className="form-control h-140"
							onChange={(e) => handleChangeData(e)}
							value={comment}></textarea>
						<div className="btn-group btn-block">
							<button className="btn btn-dark" value="" onClick={(e) => setShowAddbox(false)}>{t('cancel')}</button>
							<button className="btn btn-primary" value="" onClick={(e) => AddComment()}>{t('add')}</button>
						</div>
					</div>
				}
			</>}
		</>
	);
};

const mapState = (state) => {
	const product = state.ProductReducer.product;
	return { product };
}

const mapDispatch = dispatch => ({
	Comment: (_id, type, data) => dispatch(ProductAction.Comment(_id, type, data)),
})

export default connect(mapState, mapDispatch)(ProductComments);