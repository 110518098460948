import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthAction } from '../../actions/AuthAction';
import { RayForm, RayFormValidation } from '../../components/common';
import { LanguageCode, CountryCode } from '../../config';
import { AdminPosition } from '../../config/RayCodes';
import { AlertAction } from '../../actions';
import { Translate as t } from '../../config/Translate';
// import { S3Upload } from '../../components/common/FileUpload';

const UserAccount = ({ user, editable, callback, UpdateUser, isUpdateRes, ClearUpdateResult, Logout, ErrorMsg }) => {

    const loc = useHistory();
    // const [t, i18n] = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [uploadDone, setUploadDone] = useState()
    const [countryName, setCountryName] = useState("");
    const [errors, setErrors] = useState([]);
    const [isEditable, setIsEditable] = useState(editable);
    const [err, setErr] = useState("")
    const avatarPastPath = "https://ray-data.s3.ap-northeast-2.amazonaws.com/";
    const [positionName, setPositionName] = useState("");

    useEffect(() => {
        setIsEditable(editable);
    }, [editable])

    const [inputs, setInputs] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        hp: user.hp,
        tel: user.tel && user.tel,
        language: user.language,
        position: user.position,
        companyInfo: {
            name: user.companyInfo.name,
            address: user.companyInfo.address,
            tel: user.companyInfo.tel,
            fax: user.companyInfo.fax,
            email: user.companyInfo.email,
        }
    });

    const form_controls = [
        {
            title: t('requiredinfo'), items: [
                { type: "input", name: "firstName", label: t('firstnamerequired'), val: inputs.firstName, required: true, isEditable: isEditable },
                { type: "input", name: "lastName", label: t('lastnamerequired'), val: inputs.lastName, required: true, isEditable: isEditable },
            ], editinfo: true
        },
        {
            title: "", items: [
                { type: "email", name: "email", label: t('email'), val: inputs.email, required: true, isEditable: isEditable },
                { type: "tel", name: "hp", label: t('mobile'), val: inputs.hp, isEditable: isEditable },
                { type: "select", name: "country", label: t('country'), val: countryName, list: CountryCode },
                { type: "select", name: "position", label: t('Position'), val: positionName, list: AdminPosition },
            ], editinfo: true
        },

        {
            title: t('optionalinfo'), items: [
                { type: "email", name: "companyInfo.email", label: t('email'), val: inputs.companyInfo.email },
            ], editinfo: false
        },
        {
            title: "", items: [
                { type: "input", name: "companyInfo.tel", label: t('tel'), val: inputs.companyInfo.tel },
                { type: "input", name: "companyInfo.fax", label: t('fax'), val: inputs.companyInfo.fax },
            ], editinfo: false
        },
        {
            title: "", items: [
                { type: "input", name: "companyInfo.address", label: t('fulladdress'), val: inputs.companyInfo.address, size: 12 },
            ], editinfo: false
        },
    ]

    useEffect(() => {
        const cName = CountryCode.filter((obj) => { return obj.code == user.companyInfo.country; });
        if (cName.length)
            setCountryName(cName[0].code);
        if (isUpdateRes) {
            setIsEditable(false);
            ClearUpdateResult();
        }

        const pName = AdminPosition.filter((obj) => { return obj.code == user.position; });
        if(pName.length)
            setPositionName(pName[0].code);

    }, [user]);

    function handleChangeData(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleUpdateClick = (e) => {
        e.preventDefault();
        const errValidate = RayFormValidation(form_controls, inputs);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            setSubmitted(true);
            UpdateUser(inputs);
            callback();
        }
    }

    const uploadStart = (file) => {
        if (!file[0])
            return;
        var formData = new FormData()
        formData.append("file", file[0])
        formData.append("type", "normal");
        formData.append("acl", "public-read");
        formData.append("path", "avatar/");
      
        // S3Upload("avatar/", file[0]).then(res => {
        //     UpdateUser({ avatar: avatarPastPath + res.s3path });
        // });
        
        axios.post(AppConfig.API_SERVER_PATH + "/file/upload", formData, {
            headers: { "Content-Type": "multipart/form-data", "token": localStorage.getItem('userToken') },
            //onUploadProgress: data => {
            //	setProgress(Math.round((100 * data.loaded) / data.total))
            //}
        }).then(res => {
            if (res.data.status == "success") {
                UpdateUser({ avatar: avatarPastPath + res.data.data.path.s3path });
                //setUploadDone(res.data.data.path);
            } else if (res.message) {
                ErrorMsg("File upload failed");
            } else if (res.data.data.path.err) {
                ErrorMsg("File upload failed");
            }
        });
    }

    const hiddenFileInput = React.useRef(null);

    const handleChange = e => {
        uploadStart(e.target.files)
    };

    const handleClick = e => {
        hiddenFileInput.current.click();
    };

    return (
        <>
            <div className="section mt-4 mb-2 text-center">
                <div className="avatar-section">
                    <a onClick={(e) => handleClick(e)}>
                        {user.avatar && <img src={user.avatar} alt="avatar" className="imaged w100 rounded" />}
                        {!user.avatar && <img src="/assets/img/avatar_default_none.png" alt="avatar" className="imaged w100 rounded" />}
                        <span className="button">
                            <ion-icon name="camera-outline" role="img" className="md hydrated" aria-label="camera outline"></ion-icon>
                        </span>
                    </a>
                    <input type="file"
                        name={"file"}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                        onChange={e => uploadStart(e.target.files)} />
                </div>
            </div>
            <RayForm
                controls={isEditable ? form_controls.filter(c => c.editinfo) : form_controls}
                handleChangeData={handleChangeData}
                readOnly={!isEditable}
                errors={errors} />

            {!isEditable &&
                <div className="section mb-2">
                    <Link className="btn btn-primary btn-block mt-2" to="/UpdatePassword">{t('changepassword')}</Link>
                    <button className="btn btn-dark btn-block mt-1" onClick={() => Logout()}>{t('signout')}</button>
                </div>}

            {isEditable &&
                <div className="section mt-2 mb-2">
                    <button onClick={(e) => handleUpdateClick(e)}
                        className="btn btn-primary btn-block">{t('update')}</button>
                    <button onClick={(e) => callback(e)}
                        className="btn btn-dark btn-block">{t('cancel')}</button>
                </div>
            }
        </>
    )
}

const mapState = state => {
    const user = AuthAction.getUserInfo(state);
    const isUpdateRes = state.AuthReducer.isUpdateRes;
    const language = LanguageCode.filter(function (obj) { return obj.code == user.language; })
    const languageName = language ? language[0].title : "";
    return {
        user,
        isUpdateRes,
        languageName
    };
}

const mapDispatch = dispatch => ({
    UpdateUser: userInfo => dispatch(AuthAction.UpdateUser(userInfo)),
    Logout: () => dispatch(AuthAction.Logout()),
    ErrorMsg: (msg) => dispatch(AlertAction.ErrorMsg(msg)),
    ClearUpdateResult: () => dispatch(AuthAction.ClearUpdateResult())
})

export default connect(mapState, mapDispatch)(UserAccount);