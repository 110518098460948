export const ServiceConstant = {
	SERVICE_CREATE: 'SERVICE_CREATE',
	SERVICE_GET_ALL: 'SERVICE_GET_ALL',
	SERVICE_GET_ALL_TRASH: 'SERVICE_GET_ALL_TRASH',
	SERVICE_PRODUCT_GET_ALL: 'SERVICE_PRODUCT_GET_ALL',
	SERVICE_GET_ONE: 'SERVICE_GET_ONE',
	SERVICE_UPDATE_INFO: 'SERVICE_UPDATE_INFO',
	SERVICE_UPDATE_INFO_MULTI: 'SERVICE_UPDATE_INFO_MULTI',
	SERVICE_UPDATE_INFO_BULK: 'SERVICE_UPDATE_INFO_BULK',
	SERVICE_UPDATE_STATUS: 'SERVICE_UPDATE_STATUS',
	SERVICE_UPDATE_SIG: 'SERVICE_UPDATE_SIG',
	SERVICE_SENDMNAIL: 'SERVICE_SENDMNAIL',
	SERVICE_ADD_REPLACEMENT: 'SERVICE_ADD_REPLACEMENT',
	SERVICE_DEL_REPLACEMENT: 'SERVICE_DEL_REPLACEMENT',
	SERVICE_ADD_ATTACHMENT: 'SERVICE_ADD_ATTACHMENT',
	SERVICE_DEL_ATTACHMENT: 'SERVICE_DEL_ATTACHMENT',
	SERVICE_MOVE_TRASH: 'SERVICE_MOVE_TRASH',
	SERVICE_DELETE: 'SERVICE_DELETE',
	LOADING: 'SERVICE_LOADING',
	LOADED: 'SERVICE_LOADED',
	ADDUPDATE: 'SERVICE_ADDUPDATE',
	UPDATE_DSFILTER: 'SERVICE_UPDATE_DSFILTER',
	CLEAER: 'SERVICE_CLEAER',
	SERVICE_GET_REPLACEMENTS: 'SESRVICE_GET_REPLACEMENTS',
};
