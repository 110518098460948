import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayTable, CustomFieldListHeader } from '../../components/common';
import { CustomFieldAction } from '../../actions/CustomFieldAction';
import CustomFieldModal from '../../components/common/CustomFieldModal';
import { Translate as t } from '../../config/Translate';

const AdminCustomField = ({ items, AddUpdate }) => {

	const [showModal, setShowModal] = useState(false);
	const [item, setItem] = useState({});

	const options = {
		search: true,
		keyCol: '_id',
		linkkey: 'code',
		searchCols: ['group', 'code', 'title'],
		filterColumns: [
			{ col: 'type', val: 'ALL' },
		],
		defaultSort: 'code',
	};

	const selected = (e) => {
		console.log(e);
		setItem(e);
		setShowModal(true);
	}

	const addNew = () => {
		setItem({});
		setShowModal(true);
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">
					{t('customfieldlist')}
				</h2>
				<div className="ray-right">
					<button className="btn btn-primary btn-sm mt-0" onClick={() => addNew()}>
						<ion-icon name="add-outline"></ion-icon>
						{t('addcustomfield')}
					</button>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section mt-1 mb-1">
					<div className="wide-block pt-2 pb-2">
						<RayTable
							data={items}
							rowSelectCallback={(e) => selected(e)}
							callback={() => setShowType("list")}
							columns={CustomFieldListHeader}
							options={options} />
					</div>
				</div>
			</div>
			<CustomFieldModal
				showModal={showModal}
				data={item}
				callbackClose={() => setShowModal(false)} />
		</>
	);
};

const mapState = (state) => {
	var items = state.CustomFieldReducer.items;
	return { items };
};

const mapDispatch = (dispatch) => ({
	//GetAll: (filter) => dispatch(CustomFieldAction.GetAll(filter)),
	AddUpdate: (item) => dispatch(CustomFieldAction.AddUpdate(item)),
});

export default connect(mapState, mapDispatch)(AdminCustomField);