import React from 'react';
import { connect } from 'react-redux';

import { RayTable, AdminServiceAdminListHeader } from '../common';
import { ServiceAction } from '../../actions/ServiceAction';

const ServiceByErrorCode = ({ user, errorCode, GetServiceAll, services, totalservicecount, filterOptions }) => {
	const filterpage = {
		filters: {},
		match: { 'errorCode': errorCode },
		filterOptions: ['status', 'claimCategory'],
		searchCols: ['summary', 'ticketKey', 'sn'],
		sort: { updateDate: -1 },
		searchKey: "",
		page: 1,
		itemperpage: user.settings.dashboardtablelistcount || 30
	};

	const options = {
		search: true,
		keyCol: 'ticketKey',
		defaultSort: Object.keys(filterpage.sort)[0],
		defaultSortType: Object.values(filterpage.sort)[0],
		linkto: '/Admin/AdminService',
		getList: GetServiceAll,
		linkkey: 'summary',
		filterpageoption: filterpage,
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalservicecount,
		filterOptions: filterOptions
	};

	return (
		<div className="section">
			<div className="card">
				<div className="card-body">
					<RayTable
						data={services}
						columns={AdminServiceAdminListHeader}
						options={options} />
				</div>
			</div>
		</div>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var services = state.ServiceReducer.services;
	var totalservicecount = state.ServiceReducer.totalservicecount;
	var filterOptions = state.ServiceReducer.filterOptions;
	return { user, services, totalservicecount, filterOptions };
};

const mapDispatch = (dispatch) => ({
	GetServiceAll: (filter) => dispatch(ServiceAction.GetServiceAll(filter)),
});

export default connect(mapState, mapDispatch)(ServiceByErrorCode);