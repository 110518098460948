export const TopUserMenuItems = [
    { menutitle : "myaccount", path : "/UserAccount", class : "icon md-account-box" },
    { menutitle : "settings", path : "/UserSettings", class : "icon md-settings" }
]
export const HomeQuickMenuItems = [
    { menutitle : "preinstallreport", path : "#", icon : "/base/assets/images/ico_survey@2x.png" },
    { menutitle : "installreport", path : "#", icon : "/base/assets/images/ico_install@2x.png" },
    { menutitle : "service", path : "#", icon : "/base/assets/images/ico_service@2x.png" },
    { menutitle : "mytask", path : "#", icon : "/base/assets/images/ico_check@2x.png" }
]
export const LeftMenuItems = [
    { menutitle : "reports", path : "/ReportManager", class : "document-text-outline" },
    { menutitle : "guides", path : "/Guides", class : "book-outline" },
    { menutitle : "schedule", path : "/Calendar", class : "calendar-outline" },
    { menutitle : "settings", path : "/MyInfo", class : "settings-outline" },
]