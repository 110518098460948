import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../common/Loading';
import { SiteAction } from '../../actions/SiteAction';
import SiteCard from './SiteCard';

const SiteFavList = ({ GetFavSiteAll, sites, favsite, isLoading }) => {

	useEffect(() => {
		GetFavSiteAll();
	}, [favsite])

	return (
		<>
			{isLoading && <Loading />}
			<ul className="listview link-listview">
				{sites.map((item, index) =>
					<li key={index}>
						<SiteCard item={item} />
					</li>
				)}
			</ul>
		</>
	);
};

const mapState = (state) => {
	var sites = state.FavoriteReducer.sites;
	var favsite = state.FavoriteReducer.site;
	var isLoading = state.SiteReducer.isLoading;
	return { sites, favsite, isLoading };
};

const mapDispatch = (dispatch) => ({
	GetFavSiteAll: (filter) => dispatch(SiteAction.GetFavSiteAll(filter)),
});

export default connect(mapState, mapDispatch)(SiteFavList);