import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthAction } from '../../actions/AuthAction';
import { TopUserMenuItems } from '../../config';
import { AuthReducer } from '../../reducers/AuthReducer';

const TopMenu = ({ isLogin, firstName, lastName, logout }) => {

    //const [t, i18n] = useTranslation();

    useEffect(() => {
        if (!isLogin) {
            if (location.pathname.toLowerCase().startsWith('/admin/')) {
                history.push("/Admin/Login");
            } else {
                history.push("/Login");
            }
        }
        console.log(isLogin);
    }, [isLogin]);

    return (
        <div className="header light">
            <div>
                <div className="brand inline"> </div>
            </div>
            <div className="d-flex align-items-center">
                <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                    <li className="nav-item dropdown">
                        <button className="header-icon btn-link"
                            type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" role="button">
                            <span><i className="icon md-account"></i>&nbsp;&nbsp;{firstName} {lastName}&nbsp;&nbsp;<i className="icon md-chevron-down"></i></span>
                        </button>
                        <div className="dropdown-menu" role="menu">
                            {
                                TopUserMenuItems.map((menu, index) => {
                                    return (
                                        <Link className="dropdown-item m-r-10" key={menu.menutitle} to={menu.path} role="menuitem">
                                            {t(menu.menutitle)}
                                        </Link>
                                    )
                                })
                            }
                            <div className="dropdown-divider" role="presentation"></div>
                            <a className="dropdown-item" onClick={logout} role="menuitem"><i className="icon md-power" aria-hidden="true"></i> {t("signout")}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

const mapState = state => {
    const userInfo = AuthAction.getUserInfo(state);
    return {
        adminid: userInfo.adminid,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        isLogin: state.AuthReducer.loggingIn
    };
}

const mapDispatch = dispatch => ({
    logout: () => dispatch(AuthAction.Logout()),
})

export default connect(mapState, mapDispatch)(TopMenu);