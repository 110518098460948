import React from 'react';
import { GuideItem } from './GuideInstallation';

const GuideSettingWizard = ({ user, result, search }) => {
	return (
		<>
			{result.guideList.length > 0 &&
				<div className="">
					<div className="section-title">{search.modelName}</div>
					{result.guideList &&
						<ul className="listview image-listview inset m-0">
							{result.guideList.map((x) =>
								<li>
									<GuideItem text={x.title} path={x.filePath} filename={x.fileName} />
								</li>
							)}
						</ul>
					}
				</div>
			}
			{result.cephList.length > 0 &&
				<div className="">
					<div className="section-title">Cephalometric</div>
					<ul className="listview image-listview inset m-0">
						{result.cephList.map((x) =>
							<li>
								<GuideItem text={x.title} path={x.filePath} filename={x.fileName} />
							</li>
						)}
					</ul>
				</div>
			}
			{result.guideList.length == 0 && <span className="text-muted">No result</span>}
		</>
	)
}

export default GuideSettingWizard;