import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { RayFormValidation, RayForm, RayCodeListHeader } from '../../components/common';
import { RayDataAction } from '../../actions/RayDataAction';
import { CompanyAction } from '../../actions/CompanyAction';
import { DeviceModels, WarrantyProductType, WarrantyUPKitModel } from '../../config/RayCodes';
import { ProductAction } from '../../actions';

const ProductWarrantyAdd = ({ product, codeList, data, callbackClose, showModal, UpdateProductWarranty }) => {

	const [request, setRequest] = useState(false);
	const [val, setVal] = useState({});
	const [errors, setErrors] = useState([]);
	const [info, setInfo] = useState({});
	const [addamounttype, setAddAmountType] = useState("afterexpire");
	const [isNew, setIsNew] = useState(true);
	const [controls, setControls] = useState([]);

	useEffect(() => {
		setErrors([]);
		setIsNew(!data.hasOwnProperty("_id"));
		setInfo(data);
	}, [data])

	useEffect(() => {
		if (request) {
			setRequest(false);
			callbackClose();
		}
	}, [product])

	useEffect(() => {
		getControls();
	}, [info])

	useEffect(() => {
		if (showModal) {
			setVal({});
		}
	}, [showModal]);

	var getControls = () => {
		var ctrl = [{
			title: '', items: [
				{
					type: 'select2', name: 'dest', label: 'Product/Part', val: info && info.dest, isEditable: isNew,
					list: getSelect("WARRANTYPRODUCT"),
					isMulti: true, required: true
				},
			]
		}];

		ctrl[0].items.push(
			{ type: 'number', name: 'amount', label: 'Period', val: info && info.amount, isEditable: isNew, required: true },
			{
				type: 'select2', name: 'amounttype', label: 'Period Unit', val: info && info.amounttype, isEditable: isNew, list: [
					{ code: "d", title: "day" },
					{ code: "M", title: "month" },
					{ code: "y", title: "year" }
				], required: true
			},
		);

		if (isNew) {
			ctrl[0].items.push(
				{ type: 'textarea', name: 'oriwhy', label: 'Original Reason', val: info && info.oriwhy, isEditable: false },
				{ type: 'textarea', name: 'why', label: 'Reason', val: info && info.why, isEditable: true, required: true },
			)
		} else {
			ctrl[0].items.push(
				{ type: 'textarea', name: 'why', label: 'Reason', val: info && info.why, isEditable: true, required: true },
			)
		}
		setControls(ctrl);
	}

	const handleSubmit = (isAdd) => {
		const errValidate = RayFormValidation(controls, info);
		console.log(errValidate);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setRequest(true);
			UpdateProductWarranty(product._id, isAdd ? info : {
				dest: info.dest,
				amount: -1 * info.amount,
				amounttype: info.amounttype,
				category: info.category,
				whenkey: info.whenkey,
				whykey: info.whykey,
				why: info.why,
			})
		}
	}

	const getTitle = () => {
		var tt = codeList.filter(x => x.code == data.category && x.group == "WARRANTYTYPE");
		if (tt.length > 0) {
			return tt[0].name
		}
		return "";
	}

	const updateVal = (e) => {
		const { name, value } = e.target;
		if (name == "addtype") {
			setAddAmountType(value);
		}
		setInfo({ ...info, [name]: value })
	}

	const getSelect = (type) => {
		return codeList.filter(x => x.group == type).map(x => {
			return { code: x.code, title: x.name }
		})
	}

	return (
		<Modal show={showModal} onHide={() => callbackClose()} size="lg">
			<Modal.Header>
				<h2 className="modal-title">{getTitle()}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<RayForm
					handleChangeData={updateVal}
					controls={controls}
					errors={errors}
					nocard={true} />
			</Modal.Body>
			<Modal.Footer>
				{isNew && <button className={request ? "btn btn-block btn-primary disabled" : "btn btn-block btn-primary"} onClick={() => handleSubmit(true)}>
					Add
				</button>}
				{!isNew && <button className={request ? "btn btn-block btn-warning disabled" : "btn btn-block btn-warning"} onClick={() => handleSubmit(false)}>
					Remove
				</button>}
				<button className={request ? "btn btn-block btn-dark disabled" : "btn btn-block btn-dark"} onClick={() => callbackClose()}>Close</button>
			</Modal.Footer>
		</Modal>
	);
}

const mapState = (state) => {
	var product = state.ProductReducer.product;
	return { product };
};

const mapDispatch = (dispatch) => ({
	UpdateProductWarranty: (_id, info) => dispatch(ProductAction.UpdateProductWarranty(_id, info)),
});

export default connect(mapState, mapDispatch)(ProductWarrantyAdd);