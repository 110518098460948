import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { history } from '../../history';
import { useParams, } from 'react-router-dom';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { InputBasicText, InputSelect2, InputBasicNumber } from '../../components/common/Inputs';
import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { FileUpload } from '../../components/common/FileUpload';
import { DeviceModels } from '../../config';

const AdminSparePartsDetailInfo = ({ partsInfo, partsList, GetOne, GetList, Update }) => {
    const { _id } = useParams();

    const [editMode, setEditMode] = useState(false);
    const [originItem, setOriginItem] = useState({});
    const [item, setItem] = useState({});
    const [imageUrl, setImageUrl] = useState('');
    const [modifyData, setModifyData] = useState({});

    useEffect(() => {
        if (_id)
            GetOne(_id);
        else
            history.push('/Admin/AdminSpareParts');
    }, []);

    useEffect(() => {
        console.log(item);
    }, [item]);

    useEffect(() => {
        if (partsList.length === 0)
            GetList();
    }, [partsList]);

    useEffect(() => {
        if (partsInfo?.picture?.path)
            getUrl(partsInfo.picture.path).then(url => setImageUrl(url));

        setOriginItem(partsInfo);
        setItem(partsInfo);
        setEditMode(false);
    }, [partsInfo]);

    useEffect(() => {
        if (item.picture?.path) 
            getUrl(item.picture.path).then(url => setImageUrl(url));
        else
            setImageUrl('');

        if (item.isDel)
            history.push('/Admin/AdminSpareParts');
    }, [item]);

    const handleChangeData = (e) => {
        setItem({ ...item, [e.target.name]: e.target.value });
    }

    const updateData = () => {
        Update(item);
    }

    const getUrl = async (s3path) => {
        const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", { path: s3path, ctype: "image/" + s3path.slice((s3path.lastIndexOf(".") - 1 >>> 0) + 2) }, JWT());

        if (ret.data.status == "success") {
            return ret.data.data;
        }
    }

    const cancelEditMode = () => {
        setItem(originItem);

        if (originItem?.picture?.path)
            getUrl(originItem.picture.path).then(url => setImageUrl(url));

        setEditMode(false);
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title m-0 float-start">파트정보</div>
                <div className='float-end'>
                    {
                        !editMode &&
                        <a className='btn btn-secondary btn-sm mt-0 right' onClick={() => setEditMode(true)}>수정</a>
                    }
                    {
                        editMode &&
                        <>
                            <a className='btn btn-primary btn-sm mt-0 mr-1' onClick={() => updateData()}>확인</a>&nbsp;
                            <a className='btn btn-secondary btn-sm mt-0 right' onClick={() => cancelEditMode()}>취소</a>
                        </>
                    }

                </div>
            </div>

            <div className="rayform section mt-1">
                <div className="card">
                    <div className="card-body pb-1 pt-1">
                        <ul className="listview simple-listview no-space border-0">
                            <div>
                                <InputSelect2
                                    options={{
                                        type: "select2",
                                        name: "model",
                                        label: "모델",
                                        list: [...DeviceModels, { code: 'Other', title: 'Other' }],                                        
                                        isMulti:true,
                                        val: item?.model || [],
                                    }}
                                    editable={editMode}
                                    onchange={handleChangeData} />                                
                            </div>

                            <div>
                                <div style={{ width: 'calc(40% - 5px)', display: 'inline-block' }}>                                    
                                    <InputSelect2
                                        options={{
                                            type: "select2",
                                            name: "section",
                                            label: "Section",
                                            required: true,
                                            list: Array.from(new Set(partsList.map(x => x.section))).map(x => { return { code: x, title: x } }),
                                            val: item.section || ''
                                        }}
                                        editable={editMode}
                                        onchange={handleChangeData} />
                                </div>
                                <div style={{ width: 'calc(60% - 5px)', display: 'inline-block', marginLeft: '10px' }}>                                    
                                    <InputSelect2
                                        options={{
                                            type: "select2",
                                            name: "type",
                                            label: "Type",
                                            required: true,
                                            list: Array.from(new Set(partsList.map(x => x.type))).map(x => { return { code: x, title: x } }),
                                            val: item.type || ''
                                        }}
                                        editable={editMode}
                                        onchange={handleChangeData} />
                                </div>
                            </div>
                            
                            <div>
                                <div style={{ width: 'calc(40% - 5px)', display: 'inline-block' }}>
                                    <InputBasicText options={{ 'name': 'serviceCode', val: item.serviceCode, label: 'Service Code' }} editable={editMode} onchange={handleChangeData} />
                                </div>
                                <div style={{ width: 'calc(60% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                    <InputBasicText options={{ 'name': 'servicePartName', val: item.servicePartName, label: 'Service Part Name' }} editable={editMode} onchange={handleChangeData} />
                                </div>
                            </div>
                            
                            <div>
                                <div style={{ width: 'calc(40% - 5px)', display: 'inline-block' }}>
                                    <InputBasicText options={{ 'name': 'bomCode', val: item.bomCode, label: 'BOM Code' }} editable={editMode} onchange={handleChangeData} />
                                </div>
                                <div style={{ width: 'calc(60% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                    <InputBasicText options={{ 'name': 'bomName', val: item.bomName, label: 'BOM Name' }} editable={editMode} onchange={handleChangeData} />
                                </div>
                            </div>
                            
                            <InputBasicText options={{ 'name': 'rev', val: item.rev, label: 'Rev' }} editable={editMode} onchange={handleChangeData} />
                            <InputBasicText options={{ 'name': 'hsCode', val: item.hsCode, label: 'HS Code' }} editable={editMode} onchange={handleChangeData} />
                            <InputBasicNumber options={{ 'name': 'defaultCount', val: item.defaultCount, label: 'Default Count' }} editable={editMode} onchange={handleChangeData} />
                            
                            <div>
                                <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                    <InputBasicText options={{ 'name': 'price.usd', val: item.price?.usd, label: 'Price (USD($))' }} editable={editMode}
                                        onchange={(e) => setItem({ ...item, price: { ...item.price, usd: e.target.value } })} />
                                </div>
                                <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                    <InputBasicText options={{ 'name': 'price.eur', val: item.price?.eur, label: 'Price (EUR(€))' }} editable={editMode}
                                        onchange={(e) => setItem({ ...item, price: { ...item.price, eur: e.target.value } })} />
                                </div>
                            </div>

                            <div>
                                <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                    <InputBasicText options={{ 'name': 'price.jpy', val: item.price?.jpy, label: 'Price (JPY(￥))' }} editable={editMode}
                                        onchange={(e) => setItem({ ...item, price: { ...item.price, jpy: e.target.value } })} />
                                </div>
                                <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                    <InputBasicText options={{ 'name': 'price.cny', val: item.price?.cny, label: 'Price (CNY(￥))' }} editable={editMode}
                                        onchange={(e) => setItem({ ...item, price: { ...item.price, cny: e.target.value } })} />
                                </div>
                            </div>

                            <div className='form-group basic'>
                                <div className='input-wrapper'>
                                    <label className="label">Picture</label>
                                    <div>
                                        {
                                            imageUrl && (
                                                <div className='form-content multi'>
                                                    <div style={{ display: 'inline-block' }}>
                                                        <a href={imageUrl} target="_blank">{item.picture?.origin}</a>
                                                    </div>
                                                    {
                                                        editMode &&
                                                        (
                                                            <div style={{ display: 'inline-block', fontSize: '20px', verticalAlign: 'middle', paddingLeft: '10px' }}>
                                                                <FileUpload
                                                                    option={{ doneandnew: true }}
                                                                    extraData={{ path: "spareparts/" }}
                                                                    uploadLater={false}
                                                                    callback={(response) => setItem({ ...item, picture: { origin: response.original, path: response.s3path } })}
                                                                    button={<ion-icon name="image-outline" style={{ marginRight: '5px' }}></ion-icon>}
                                                                />
                                                                <a onClick={() => setItem({ ...item, picture: { origin: '', path: '' } })}> <ion-icon name="trash-outline"></ion-icon></a>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            !imageUrl &&
                                            <div>
                                                <i className="text-secondary">N/A</i>
                                                {
                                                    editMode &&
                                                    (
                                                        <div style={{ display: 'inline-block', fontSize: '22px', verticalAlign: 'middle', paddingLeft: '10px' }}>
                                                                <FileUpload
                                                                    option={{ doneandnew: true }}
                                                                    extraData={{ path: "spareparts/" }}
                                                                    uploadLater={false}
                                                                    callback={(response) => setItem({ ...item, picture: { origin: response.original, path: response.s3path } })}
                                                                    button={<ion-icon name="image-outline" style={{ marginRight: '5px' }}></ion-icon>}
                                                                />
                                                            <a onClick={() => setItem({ ...item, picture: { origin: '', path: '' } })}><ion-icon name="trash-outline"></ion-icon></a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapState = (state) => {
    const partsInfo = state.SparePartsReducer.sparePart;
    const partsList = state.SparePartsReducer.spareParts;
    return { partsInfo, partsList };
};

const mapDispatch = (dispatch) => ({
    Update: (data) => dispatch(SparePartsAction.Update(data)),
    AddHistory: (data) => dispatch(SparePartsAction.AddHistory(data)),
    UpdateHistory: (_id, data) => dispatch(SparePartsAction.UpdateHistory(_id, data)),
    GetOne: (_id) => dispatch(SparePartsAction.GetOne(_id)),
    GetList: () => dispatch(SparePartsAction.GetList({}))
});

export default connect(mapState, mapDispatch)(AdminSparePartsDetailInfo);
