import { Translate as t } from '../../config/Translate';

export const AdminUserListHeader = [
	{ name: 'adminType', label: t('authority') },
	{ name: 'adminid', label: t('loginid') },
	{ name: 'setname', label: t('name') },
	{ name: 'function', label: t('role') },
	{ name: 'country', label: t('country') },
	{ name: 'companyInfo.name', label: t('operator') },
	{ name: 'email', label: t('email') }
];
export const AdminCompanyListHeader = [
	{ name: 'country', label: '', showtype: "ONLYFLAG", style: { width: 24 } },
	{ name: 'name', label: 'NAME' },
	{ name: 'org', label: 'ORG.' },
	{ name: 'address', label: 'ADDRESS' },
	{ name: 'contactEmail', label: 'EMAIL' },
	{ name: 'contactHp', label: 'MOBILE' },
	{ name: 'contactTel', label: 'TEL' },
];
export const AdminProductListHeader = [
	{ name: 'sn', label: t('sn') },
	{ name: 'site.sitename', label: t('sitename'), link: '/Admin/AdminSiteInfo', linkdata: 'site.siteId' },
	{ name: 'model', label: t('model') },
	{ name: 'company.name', label: t('company') },
	// { name: 'acquisitionsCNT', label: t('acquisition') },
	// { name: 'errsCNT', label: t('error') },
	{ name: 'cnt_acquisitions', label: t('acquisition') },
	{ name: 'cnt_errs', label: t('error') },
	{ name: 'shipmentdate', label: t('shipmentdate'), type: "DTONLY" },
	{ name: 'installedDate', label: t('installeddate'), type: "DTONLY" },
];
export const AdminProductQCListHeader = [
	{ name: 'timestamp', label: 'Time', type: "DT" },
	{ name: 'cnr', label: 'CNR', min: "cnrmin", max: "cnrmax", type: "PASSFAIL" },
	{ name: 'contrast', label: 'Contrast', min: "contrastmin", max: "contrastmax", type: "PASSFAIL" },
	{ name: 'nyquist', label: 'Nyquist', min: "nyquistmine", max: "nyquistmaxe", type: "PASSFAIL" },
	{ name: 'homogeneity', label: 'Homogeneity', min: "homogeneitymin", max: "homogeneitymax", type: "PASSFAIL" },
	{ name: 'noise', label: 'Noise', min: "noisemin", max: "noisemax", type: "PASSFAIL" },
	{ name: 'ctair', label: 'CTAir', min: "ctairmin", max: "ctairmax", type: "PASSFAIL" },
	{ name: 'ctpmma', label: 'CTPMMA', min: "ctpmmamin", max: "ctpmmamax", type: "PASSFAIL" },
	{ name: 'ctpvc', label: 'CTPVC', min: "ctpvcmin", max: "ctpvcmax", type: "PASSFAIL" },
	{ name: 'mtf10', label: 'MTF10', min: "mtf10min", max: "mtf10max", type: "PASSFAIL" },
	{ name: 'mtf50', label: 'MTF50', min: "mtf50min", max: "mtf50max", type: "PASSFAIL" },
	{ name: 'ai', label: 'AI', min: "aimine", max: "aimaxe", type: "PASSFAIL" },
];
export const AdminProductACQListHeader = [
	{ name: 'activationTime', label: 'Activation Time', type: "DT" },
	{ name: 'confirm', label: 'Confirm' },
	{ name: 'modality', label: 'Modality' },
	{ name: 'protocol', label: 'Protocol' },
	{ name: 'description', label: 'Description' },
	{ name: 'timestamp', label: 'Time', type: "DT" },
];
export const AdminProductWarantyListHeader = [
	{ name: 'timestamp', label: 'Time', type: "DT" },
	{ name: 'user', label: 'User' },
	{ name: 'content', label: 'Content' },
	{ name: 'installedWarranty', label: 'Installed Warranty' },
	{ name: 'cbctDetector', label: 'CBCT' },
	{ name: 'panoDetector', label: 'Pano' },
	{ name: 'cephDecector', label: 'Ceph' },
	{ name: 'xrayGenerator', label: 'Xray' },
	{ name: 'description', label: 'Description' },
];
export const AdminProductWarantyAllTypeListHeader = [
	{ name: 'timestamp', label: 'Time', type: "DT" },
	{ name: 'type', label: 'Type' },
	{ name: 'user', label: 'User' },
	{ name: 'content', label: 'Content' },
	{ name: 'installedWarranty', label: 'Installed Warranty' },
	{ name: 'cbctDetector', label: 'CBCT' },
	{ name: 'panoDetector', label: 'Pano' },
	{ name: 'cephDecector', label: 'Ceph' },
	{ name: 'xrayGenerator', label: 'Xray' },
	{ name: 'description', label: 'Description' },
];
export const AdminErrorListHeader = [
	{ name: 'errs.code', label: t('code') },
	{ name: 'sn', label: t('sn') },
	{ name: 'site.sitename', label: t('sitename'), link: '/Admin/AdminSiteInfo', linkdata: 'site.siteId' },
	{ name: 'model', label: t('model') },
	{ name: 'errs.begin', label: t('begin'), type: "DT" },
	{ name: 'errs.description', label: t('description') },
	{ name: 'errs.emsaction', label: t('pointofoccur') },
	{ name: 'errs.timestamp', label: t('registered'), type: "DT" },
];
export const AdminProductErrorListHeader = [
	{ name: 'code', label: 'Code', link: '/Admin/AdminErrorCodeInfo', linkdata: 'code' },
	{ name: 'closer', label: 'Closer' },
	{ name: 'begin', label: 'Begin', type: "DT" },
	{ name: 'duration', label: 'Duration', type: "SPAN" },
	{ name: 'note', label: 'Note' },
	{ name: 'description', label: 'Description' },
	{ name: 'emsaction', label: 'Action' },
	{ name: 'timestamp', label: 'Time', type: "DT" },
	{ name: 'logRequested', label: 'Log Requested', type: "DT" },
	{
		name: 'addservicebutton',
		type: 'BUTTON',
		options: {
			title: 'Add Service',
			linkPath: '/Admin/AdminServiceAdd',
			paramType: 'param', //param / querystring
			paramRefer: 'PRODUCTERROR',
		},
	},
	{
		name: 'requestlogbutton',
		type: 'EMSLOGBUTTON',
		rederType: 'EMSLOGBUTTON',
		options: {
			title: 'Request Log',
			paramType: 'EMSLOGBUTTON'
		},
		ifshowKeyNull: 'emsrequestlog',
		ifnotshowText: 'Requested',
	},
];

export const AdminProductErrorListHeader2 = [
	// { name: 'code', label: t('errorcode'), link: '/Admin/AdminErrorCodeInfo', linkdata: 'code' },
	{ name: 'code', label: t('errorcode') },
	{ name: 'definition', label: 'Definition' },
	{ name: 'begin', label: 'Begin', type: "DT" },
	// { name: 'lastAcq', label: t('lastacquisition'), type: 'DT' },
	{ name: 'status', label: t('status') },
	{ name: 'duration', label: t('duration') },
	// {
	// 	name: 'requestlogbutton',
	// 	label: t('requestlog'),
	// 	type: 'EMSLOGBUTTON',
	// 	rederType: 'EMSLOGBUTTON',
	// 	options: {
	// 		title: t('requestlog'),
	// 		paramType: 'EMSLOGBUTTON'
	// 	},
	// 	ifshowKeyNull: 'emsrequestlog',
	// 	ifnotshowText: 'Requested',
	// },
	{
		name: 'addservicebutton',
		type: 'BUTTON',
		options: {
			title: 'Add Service',
			linkPath: '/Admin/AdminServiceAdd',
			paramType: 'param',
			paramRefer: 'PRODUCTERROR',
		},
	}

];

export const AdminProductSiteHistory = [
	{ name: 'productUpdateDate', label: 'Time' },
	{ name: 'sitename', label: 'Site', link: '/Admin/AdminSiteInfo', linkdata: 'siteId' },
];
export const ErrorCodeListHeader = [
	{ name: 'errorCode', label: t('errorcode'), style: { width: 120 } },
	{ name: 'language', label: t('language') },
	{ name: 'model', label: t('model'), type : 'TAG' },
	// { name: 'occurs', label: t('occurs') },
	// { name: 'votes', label: t('like') },
	// { name: 'technotecount', label: t('note') },
];
export const ErrorCodeModelListHeader = [
	{
		name: 'errorCode', label: 'Error Code', type: 'CALLBACK_BUTTON',
		options: {
			title: 'errorCode',
			//linkPath: '/Admin/AdminServiceExportCSR',
			paramType: 'callback'
		},
		align: "text-center"
	},
	
	{ name: 'model', label: t('model'), type : 'TAG' },
]
export const RayCodeListHeader = [
	{ name: 'type', label: t('type') },
	{ name: 'sortnumber', label: t('sort') },
	{ name: 'group', label: t('group') },
	{ name: 'code', label: t('code') },
	{ name: 'name', label: t('name') },
	{ name: 'alias', label: 'Assign', type: "TAG", align: "text-left" },
	{ name: 'category', label: t('category') },
	{ name: 'isbuiltin', label: t('builtin'), type: "TF", align: "text-center" },
];
export const CustomFieldListHeader = [
	{ name: 'code', label: t('code') },
	{ name: 'title', label: t('title') },
	{ name: 'group', label: t('group'), type: "TAG", align: "text-left" },
	{ name: 'valuetype', label: t('typevalue') },
];
export const SiteListHeader = [
	{ name: 'sitename', label: t('site'), type: 'TITLE' },
	{ name: 'sns', label: t('product'), arraylink: '/Admin/AdminProductInfo', type: 'ARRAYLINK' },
	{ name: 'customername', label: t('customer'), insortable: true, type: "SITEFIRSTLAST" },
	{ name: 'speciality', label: t('specialty'), insortable: true },
	{ name: 'country', label: t('country'), insortable: true },
	{ name: 'address', label: t('address'), insortable: true, type: "SITEADDR" },
	{ name: 'email', label: t('email'), insortable: true, type: "SITEEMAIL" },
	{ name: 'createDate', label: t('registered'), type: "DT" },
];
export const InstallationListHeader = [
	{ name: 'isSubmit', label: t('status'), itemlabel: "reportstatus", type: "TF", align: "text-center", style: { width: 32 } },
	{ name: 'site.country', label: t('country'), type: "COUNTRY" },
	{ name: 'operator', label: t('operator') },
	{ name: 'sn', label: t('sn') },
	{ name: 'site.sitename', label: t('site') },
	{ name: 'product.model', label: t('model') },
	{ name: 'product.option', label: t('option') },
	{ name: 'installationStep', label: t('progress'), type: "PROGRESS", max: 8, align: "text-center", itemlabel: "report", modifylink: "/Installation/", itemlink: "/Admin/AdminInstallationInfo/", itemlinkkey: "_id", itemview: "isSubmit", style: { width: 200 } },
	{ name: 'createDate', label: t('registered'), type: "DT" },
];
export const SiteSurveyListHeader = [
	{ name: 'isSubmit', label: t('status'), itemlabel: "reportstatus", type: "TF", align: "text-center", style: { width: 32 } },
	{ name: 'siteCountry', label: t('country'), type: "COUNTRY" },
	{ name: 'operator', label: t('operator') },
	{ name: 'siteSitename', label: t('site') },
	{ name: 'model', label: t('model') },
	{ name: 'option', label: t('option') },
	{ name: 'siteSurveyStep', label: t('progress'), type: "PROGRESS", max: 6, align: "text-center", itemlabel: "report", modifylink: "/SiteSurvey/", itemlink: "/Admin/AdminSiteSurveyInfo/", itemlinkkey: "_id", itemview: "isSubmit", style: { width: 200 } },
	{ name: 'createDate', label: t('registered'), type: "DT" },
];
export const AdminServiceAdminListHeader = [
	{ name: 'ticketNationCode', label: t('country') },
	{ name: 'ticketKey', label: t('ticketnumber'), link: '/Admin/AdminService', linkdata: 'ticketKey' },
	{ name: 'summary', label: t('summary') },
	{ name: 'sn', label: t('sn'), link: '/Admin/AdminProductInfo', linkdata: 'sn' },
	{ name: 'errorCode', label: t('errorcode'), link: '/Admin/AdminErrorCodeInfo', linkdata: 'errorCode' },
	{ name: 'status', label: t('status'), type: "STATUS" },
	{ name: 'assignee', label: t('assigned') },
	{ name: 'creator', label: t('reporter') },
	{ name: 'createDate', label: t('createddate'), type: "DT" },
	{ name: 'closedDate', label: t('closed'), type: "DT" },
	{ name: 'updateDate', label: 'UPDATED', type: "DT" },
];

export const AdminServiceAdminListHeader2 = [
	{ name: 'status', label: t('status'), type: "STATUS" },
	{ name: 'ticketKey', label: t('ticketnumber') },
	{ name: 'errorCode', label: t('errorcode'), link: '/Admin/AdminErrorCodeInfo', linkdata: 'errorCode' },
	{ name: 'summary', label: t('subject') },
	{ name: 'replacements', label: t('partreplaced'), type: 'ARRAYLIST' },
	{ name: 'createDate', label: t('reportdate'), type: "DT" },
	{ name: 'closedDate', label: t('closed'), type: "DT" },
	{ name: 'warrantyInOut', label: t('warrantyinout') },
	{ name: 'creator', label: t('submitted') },
	{ name: 'assignee', label: t('assigned') },
];

export const AdminServiceListHeader = [
	{ name: 'status', label: t('status'), type: 'STATUS' },
	{ name: 'ticketKey', label: t('ticketnumber'), link: '/Admin/AdminService', linkdata: 'ticketKey' },
	{ name: 'companyId', label: t('company') },
	{ name: 'sn', label: t('sn') },
	{ name: 'site.sitename', label: t('sitename'), link: '/Admin/AdminSiteInfo', linkdata: 'site.siteId' },
	{ name: 'model', label: t('model') },
	{ name: 'option', label: t('option') },
	{ name: 'installedDate', label: t('installed'), type: 'DT' },
	{ name: 'summary', label: t('subject') },
	{ name: 'replacements', label: t('partreplaced'), type: 'ARRAYLIST' },
	{ name: 'doa', label: 'DOA', type: 'DOADIM' },
	{ name: 'dim', label: 'DIM', type: 'DOADIM' },
	{ name: 'creator', label: t('submitted') },
	{ name: 'assignee', label: t('assigned') },
	{
		name: 'exportcsr', label: 'Service Report', type: 'BUTTON',
		options: {
			title: 'Export',
			linkPath: '/Admin/AdminServiceExportCSR',
			paramType: 'param'
		},
		align: "text-center"
	},
	{ name: 'closedDate', label: t('closeddate'), type: 'DT' }
];

export const ProductPartListHeader = [
	{ name: 'type', label: t('type') },
	{ name: 'code', label: t('code') },
	{ name: 'name', label: t('name') },
	{ name: 'models', label: t('model'), type: "TAG", align: "text-left" },
	{ name: 'platformInfo', label: t('platform') },
];

export const StatisticsProductHeader = [
	{ name: "sn", label: 'Serial No.' },
	{ name: 'sites.country', label: 'Country', type: 'COUNTRY' },
	{ name: 'sites.sitename', label: 'Site' },
	{ name: 'model', label: 'Model' },
	{ name: 'option', label: 'Option' },
	{ name: 'installedDate', label: 'Installed Date', type: "DT" },
	{ name: 'lastConnectedDate', label: 'Last Connected Date', type: "DT" },
];
export const StatisticsErrorHeader = [
	{ name: "sn", label: 'Serial No.' },
	{ name: 'sites.country', label: 'Country', type: 'COUNTRY' },
	{ name: 'sites.sitename', label: 'Site' },
	{ name: 'model', label: 'Model' },
	{ name: 'option', label: 'Option' },
	{ name: 'errs.code', label: 'Code' },
	{ name: 'errs.begin', label: 'Begin', type: "DT" },
	{ name: 'errs.end', label: 'End', type: "DT" },
	{ name: 'errs.closer', label: 'Closer' },
	{ name: 'errs.duration', label: 'Duration', type: "SPAN" },
	{ name: 'errs.note', label: 'Note' },
	{ name: 'errs.description', label: 'Description', type: "INFO" }
];
export const StatisticsAcquisitionHeader = [
	{ name: "sn", label: 'Serial No.' },
	{ name: 'sites.country', label: 'Country', type: 'COUNTRY' },
	{ name: 'sites.sitename', label: 'Site' },
	{ name: 'model', label: 'Model' },
	{ name: 'option', label: 'Option' },
	{ name: 'acquisitions.activationTime', label: 'Activation Time', type: "DT" },
	{ name: 'acquisitions.confirm', label: 'Confirm' },
	{ name: 'acquisitions.modality', label: 'Modality' },
	{ name: 'acquisitions.protocol', label: 'Protocol' },
	{ name: 'acquisitions.description', label: 'Description' }
];

export const RayDataWarListHeader = [
	{ name: 'companyName', label: t('operator'), align: "text-center" },
	{ name: 'warrantyType', label: t('warrantytype'), align: "text-center" },
	{ name: 'models', label: t('model'), type: "TAG", align: "text-left" },
	{ name: 'productWarrantyByInstalledDate', label: t('warrantyinstallationdate'), align: "text-center" },
	{ name: 'productWarrantyByShipmentDate', label: t('warrantyshipmentdate'), align: "text-center" },
	{ name: 'mainPartsWarrantyByInstalledDate', label: t('partwarrantyinstallationdate'), align: "text-center" },
	{ name: 'mainPartsWarrantyByShipmentDate', label: t('partwarrantyshipmentdate'), align: "text-center" },	
];

// export const RayDataWarListHeader = [
// 	{ name: 'companyName', label: t('operator'), align: "text-center" },
// 	{ name: 'wartype', label: t('warrantytype'), align: "text-center" },
// 	{ name: 'models', label: t('model'), type: "TAG", align: "text-left" },
// 	{ name: 'productWarInstallMonth', label: t('warrantyinstallationdate'), align: "text-center" },
// 	{ name: 'productWarShipMonth', label: t('warrantyshipmentdate'), align: "text-center" },
// 	{ name: 'partWarInstallMonth', label: t('partwarrantyinstallationdate'), align: "text-center" },
// 	{ name: 'partWarShipMonth', label: t('partwarrantyshipmentdate'), align: "text-center" },
// 	// { name: 'active', label: t('active'), type: "TF", align: "text-center" },
// ];

export const AdminMinServiceAdminListHeader = [
	{ name: 'errorCode', label: t('errorcode'), link: '/Admin/AdminErrorCodeInfo', linkdata: 'errorCode' },
	{ name: 'status', label: t('status'), type: "STATUS" },
	{ name: 'ticketKey', label: t('ticketnumber') },
	{ name: 'summary', label: t('summary') },
	{ name: 'sn', label: t('sn'), link: '/Admin/AdminProductInfo', linkdata: 'sn' },
];

export const AdminSparePartsListHeader = [
	{ name: 'model', label: 'Model', type: 'TAG' },
	{ name: 'section', label: 'Section' },
	{ name: 'type', label: 'Type' },
	{ name: 'serviceCode', label: 'Service Code' },
	{ name: 'servicePartName', label: 'Service Part Name' },
	{ name: 'bomCode', label: 'BOM Code' },
	{ name: 'bomName', label: 'BOM Name' },
	{ name: 'rev', label: 'Rev', align: "text-center" },
	{ name: 'defaultCount', label: '적정수량', align: "text-center" },
	// { name: 'in', label: '입고', align: "text-center" },
	// { name: 'out', label: '출고', align: "text-center" },
	{ name: 'ordered', label: '주문수량', align: "text-center" },
	{ name: 'remainCount', label: '잔여수량', align: "text-center" },
];

export const AdminSparePartsStockHeader = [
	{ name: 'model', label: 'Model', type: 'TAG' },
	{ name: 'section', label: 'Section' },
	{ name: 'type', label: 'Type' },
	{ name: 'serviceCode', label: 'Service Code' },
	{ name: 'servicePartName', label: 'Service Part Name' },
	{ name: 'orderCount', label: 'Ordered' },	
	{ name: 'amount', label: 'Stock' }
];

export const AdminSparePartsOrderListHeader = [
	{ name: 'orderDate', label: 'Order Date', align: 'text-center', type: 'DT' },
	{ name: 'type', label: 'Order Type', align:'text-center' },
	{ name: 'orderer', label: 'Orderer', align:'text-center' },
	{ name: 'status', label: 'Order Status', align: 'text-center' }
]

export const AdminSparePartsOrderListManagerHeader = [
	{ name: 'companyName', label: '업체명' },
	{ name: 'orderDate', label: '주문일' , type:'DT'},
	{ name: 'type', label: '구분' },
	{ name: 'orderer', label: '주문자' },
	{ name: 'status', label: '주문상태' }
]

export const AdminSparePartsOutListHeader = [
	{ name: 'type', label: '유상/무상' },
	{ name: 'country', label: '국가', type: 'COUNTRY' },
	{ name: 'companyName', label: '업체' },
	{ name: 'orderStatus', label: '주문상태' },
	{ name: 'ciMailSent', label: 'CI메일발송' },
	{ name: 'createDate', label: '출고일', type: 'DTONLY' },
	{ name: 'receiveDate1', label: '접수일(무상,고불처)', type: 'DTONLY' },
	{ name: 'receiveDate2', label: '접수일(자재출고)', type: 'DTONLY' },
	{ name: 'shipmentDate', label: '선적일', type: 'DTONLY' },
	{ name: 'shipmentWorker', label: '선적작업' },
	{ name: 'productSN', label: '시리얼번호' },
	{ name: 'model', label: '모델' },	
	{ name: 'installedDate', label: '설치일', type: 'DTONLY' },
	{ name: 'partsSecuringTime', label: '자재확보시간' },
	{ name: 'partsProcureDuration', label: '자재출하기간분류' },
	{ name: 'partsProcureTime', label: '자재조달시간' },
	{ name: 'partsProcureDelayReason', label: '자재조달지연사유' },
	{ name: 'forwardingAgent', label: '운송사' },
	{ name: 'invoiceNumber', label: '송장번호' },
	{ name: 'ccrNumber', label: '고불처번호' },
	{ name: 'draftNumber', label: '기안번호' },
]