import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { SiteSurveyConstant } from './constants/SiteSurveyConstant';
import { AlertAction } from './AlertAction';
import { ProgramConstant } from './constants/ProgramConstant';
import { store } from '../store';

const GetSiteSurveyList = (filter, append = false) => async (dispatch) => {
	dispatch({ type : SiteSurveyConstant.LOADING });
    const data = { type : "get", data : filter };
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/sitesurvey', data, JWT());
    
    if(ret.data.status == 'success') {
        dispatch({ type: SiteSurveyConstant.GET_SITESURVEYLIST, result : ret.data.data, append });
    } else {
        dispatch(AlertAction.Error(ret.data.err));
    }
	dispatch({ type : SiteSurveyConstant.LOADED });
};

const GetSiteSurvey = (_id) => async (dispatch) => {
	dispatch({ type : SiteSurveyConstant.LOADING });
    const ret = await axios.get(AppConfig.API_SERVER_PATH + '/sitesurvey/' + _id, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: SiteSurveyConstant.GET_SITESURVEY, siteSurvey : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : SiteSurveyConstant.LOADED });
};

const GetCountNotFinished = async () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [];
	if(showmodel[0] !== ''){
		aggregate.push({ '$match' : { 'model' : { '$in' : showmodel} , isSubmit : false } });
	}else{
		aggregate.push({ '$match' : { isSubmit : false } });
    }
	const data = {
		data : {
			type: "sitesurvey",
			aggregate,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
    if(ret.data.status == 'success') {
        return ret.data.data;
    } else {
        AlertAction.Error(ret.data.error);
    }
};

const CreateSiteSurvey = (info) => async (dispatch) => {
	dispatch({ type : SiteSurveyConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'create',
		data: info,
	};
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/sitesurvey', data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: SiteSurveyConstant.GET_SITESURVEY, siteSurvey : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : SiteSurveyConstant.LOADED });
};

const UpdateSiteSurvey = (_id, info) => async (dispatch) => {
	dispatch({ type : SiteSurveyConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: info,
	};
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/sitesurvey/' + _id, data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: SiteSurveyConstant.GET_SITESURVEY, siteSurvey : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : SiteSurveyConstant.LOADED });
};

const DeleteSiteSurvey = (_id) => async (dispatch) => {
	dispatch({ type : SiteSurveyConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete'
	};
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/sitesurvey/' + _id, data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: SiteSurveyConstant.DELETE, delsitesurveyid : _id });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : SiteSurveyConstant.LOADED });
};

const SendReportMail = (_id, info) => async (dispatch) => {
    dispatch({ type : SiteSurveyConstant.LOADING });
    const data = {
        who: JSON.parse(localStorage.getItem('user')).adminid,
        type: 'sendMail',
        data: info,
    };
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/sitesurvey/' + _id, data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type : SiteSurveyConstant.LOADED});
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : SiteSurveyConstant.LOADED });
}

const Attachment = (_id, type, attachmentData) => async (dispatch) => {
	dispatch({ type : SiteSurveyConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type == "ADD" ? "addAttachment" : "delAttachment",
		data : attachmentData
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/sitesurvey/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update " + type + "!"));
		dispatch({ type: type == "ADD" ? 
			SiteSurveyConstant.SITESURVEY_UPDATE_ADD_ATTACHMENT : 
			SiteSurveyConstant.SITESURVEY_UPDATE_DEL_ATTACHMENT, siteSurvey : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type : SiteSurveyConstant.LOADED });
};

export const SiteSurveyAction = {
    GetSiteSurveyList,
    GetSiteSurvey,
    CreateSiteSurvey,
    UpdateSiteSurvey,
    DeleteSiteSurvey,
    GetCountNotFinished,
    SendReportMail,
    Attachment
};