import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { RayTable, StatisticsProductHeader, StatisticsErrorHeader, StatisticsAcquisitionHeader } from '../../components/common';
import { ProductAction } from '../../actions/ProductAction';
import { ErrorCodeAction, RayCodeAction } from '../../actions';
import Select from 'react-select'
import { CountryCode } from '../../config/CountryCode';

import moment from 'moment';

const AdminProductList = (
    {
        GetProductAll,
        products,
        totalproductcount,
        filterOptions,
        filters,
        sort,
        clist,
        GetErrorCodeList,
        errorCodes,
        GetSavedFilters,
        savedFilter,
        SaveStatisticsFilter,
        DeleteSavedFilter,
        page
    }) => {

    const [statisticsType, setStatisticsType] = useState('Device');
    const [extendFilters, setExtendFilters] = useState([]);

    const [modelAndOptions, setModelAndOptions] = useState([]);
    const [deviceModels, setDeviceModels] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([{ label: 'ALL', value: '' }]);
    const [errorCodeList, setErrorCodeList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [showSaveForm, setShowSaveForm] = useState(false);
    const [filterTitle, setFilterTitle] = useState('');

    const [condition, setCondition] = useState({
        country: '',
        durationValue: 1,
        durationUnit: "days",
        model: '',
        option: '',
        country: '',
        errorCodes: [],
        errorStatus: { closed: true, notclosed: true },
        acqConfirm: { confirm: true, retake: true, reject: true },
        acqModality: [],
        acqProtocol: []
    });

    const filterpage = {
        filters: filters || {},
        filterOptions: filterOptions || [],
        searchCols: [],
        sort: sort || { installedDate: -1 },
        searchKey: "",
        page: 1,
        itemperpage: 30
    };

    const options = {
        search: false,
        keyCol: 'sn',
        defaultSort: filterpage.sort && Object.keys(filterpage.sort)[0],
        defaultSortType: filterpage.sort && Object.values(filterpage.sort)[0],
        linkto: '/Admin/AdminProductInfo',
        getList: extendFilters.length > 0 && GetProductAll,
        linkkey: 'sn',
        filterpageoption: filterpage,
        filters: filterpage.filters,
        itemperpage: filterpage.itemperpage,
        useapi: true,
        totalitemcount: totalproductcount,
        filterOptions: filterOptions
    };

    const statisticsTypes = [
        { label: "Device", value: "Device" },
        { label: "Error", value: "Error" },
        { label: "Acquisition", value: "Acquisition" },
    ];

    useEffect(() => {
        GetSavedFilters();
        GetErrorCodeList();

        countryList.push({ label: 'ALL', value: '' });

        CountryCode.map(item => {
            countryList.push({ label: item.title, value: item.code });
        })

    }, [])

    useEffect(() => {
		console.log('AdminProductList ->', clist?.filter(x => x.type === 'PRODUCTOPTION'));
		const response = clist?.filter(x => x.type === 'PRODUCTOPTION');
        setModelAndOptions(response);
        const items = [...new Set(response.map(item => item.group))];
        deviceModels.push({ label: 'ALL', value: '' });
        items.map((item) => {
            deviceModels.push({ label: item, value: item });
        });

        // RayCodeAction.GetAllByFilter({ "type": "PRODUCTOPTION" }).then((response) => {
        //     setModelAndOptions(response);
        //     const items = [...new Set(response.map(item => item.group))];

        //     deviceModels.push({ label: 'ALL', value: '' });

        //     items.map((item) => {
        //         deviceModels.push({ label: item, value: item });
        //     });
        // });
    }, [clist]);

    useEffect(() => {
        const conditionFilters = [];

        const from = new Date(moment().add(-(isNaN(condition.durationValue) ? 1 : condition.durationValue), condition.durationUnit).format('YYYY-MM-DD') + ' 00:00:00').getTime();
        const to = new Date(moment().format('YYYY-MM-DD') + ' 23:59:59').getTime();

        if (statisticsType === "Device") {
            conditionFilters.push(
                { "$lookup": { "from": "sites", "localField": "siteId", "foreignField": "siteId", "as": "sites" } },
                { "$project": { "_id": false, "sites": { "$arrayElemAt": ["$sites", 0] }, "siteId": true, "lastConnectedDate": true, "installedDate": true, "registered": true, "productTWId": true, "model": true, "sn": true, "isDel": true, "updateDate": true, "option": true, } },
                { "$match": { "installedDate": { "$gte": from, "$lt": to }, "isDel": false } },
                { "$sort": { "installedDate": -1 } }
            );
        } else if (statisticsType === "Error") {
            conditionFilters.push(
                { "$lookup": { "from": "sites", "localField": "siteId", "foreignField": "siteId", "as": "sites" } },
                { "$project": { "_id": false, "sites": { "$arrayElemAt": ["$sites", 0] }, "sn": true, "siteId": true, "model": true, "option": true, "productTWId": true, "errs": true, "isDel": true } },
                { "$unwind": { "path": "$errs" } },
                { "$match": { "errs.begin": { "$gte": from, "$lt": to }, "isDel": false } }
            );

            if (condition.errorCodes.length > 0) {
                conditionFilters.push({ "$match": { "errs.code": { "$in": condition.errorCodes } } });
            }

            if (!condition.errorStatus.closed || !condition.errorStatus.notclosed) {
                if (!condition.errorStatus.closed)
                    conditionFilters.push({ "$match": { 'errs.end': null } });

                if (!condition.errorStatus.notclosed) {
                    conditionFilters.push({ "$match": { "errs.end": { "$gt": 0 } } });
                }
            }

            conditionFilters.push({ "$sort": { "errs.begin": -1 } });

            const list = errorCodes.filter((item) => {
                return item.model.includes(condition.model) && item.lang === 'eng'
            }).map(item => {
                return { label: item.errorCode, value: item.errorCode }
            });

            setErrorCodeList(list);

        } else if (statisticsType === "Acquisition") {
            conditionFilters.push(
                { "$lookup": { "from": "sites", "localField": "siteId", "foreignField": "siteId", "as": "sites" } },
                { "$project": { "_id": false, "sn": true, "sites": { "$arrayElemAt": ["$sites", 0] }, "siteId": true, "model": true, "option": true, "productTWId": true, "acquisitions": true, "isDel": true, } },
                { "$unwind": { "path": "$acquisitions" } },
                { "$match": { "acquisitions.activationTime": { "$gte": from, "$lt": to }, 'isDel': false } }
            );

            if (!(condition.acqConfirm.confirm && condition.acqConfirm.retake && condition.acqConfirm.reject)) {

                let checkedConfirm = [];

                if (condition.acqConfirm.confirm)
                    checkedConfirm.push('confirm')

                if (condition.acqConfirm.retake)
                    checkedConfirm.push('retake');

                if (condition.acqConfirm.reject)
                    checkedConfirm.push('reject');

                conditionFilters.push({ "$match": { 'acquisitions.confirm': { "$in": checkedConfirm } } });
            }

            if (condition.acqModality.length > 0)
                conditionFilters.push({ "$match": { "acquisitions.modality": { "$in": condition.acqModality } } });

            if (condition.acqProtocol.length > 0)
                conditionFilters.push({ "$match": { "acquisitions.protocol": { "$in": condition.acqProtocol } } });

            conditionFilters.push({ "$sort": { "acquisitions.activationTime": -1 } });
        }

        condition.model && conditionFilters.push({ "$match": { "model": condition.model } });
        condition.option && conditionFilters.push({ "$match": { "option": condition.option } });
        condition.country && conditionFilters.push({ "$match": { "sites.country": condition.country } });

        conditionFilters.resetPage = true;
        setExtendFilters(conditionFilters);
    }, [statisticsType, condition]);

    const statisticsTypeChange = (e) => {
        setStatisticsType(e.value);
    }

    const durationChange = (type, e) => {
        if (type === "F") {
            const date = new Date(e.target.value + ' 00:00:00').getTime();
            setCondition({ ...condition, durationFrom: date });
        }

        if (type === "T") {
            const date = new Date(e.target.value + ' 23:59:59').getTime();
            setCondition({ ...condition, durationTo: date });
        }
    }

    const selectDeviceModel = (e) => {
        setCondition({ ...condition, model: e.value, option: '' });

        let optionList = [];

        optionList.push({ label: 'ALL', value: '' })

        modelAndOptions.filter(item => { return item.group == e.value }).forEach((opt => {
            optionList.push({ label: opt.code, value: opt.code });
        }));

        setDeviceOptions(optionList);
    }

    const selectDeviceOption = (e) => {
        setCondition({ ...condition, option: e.value });
    }

    const selectCountry = (e) => {
        setCondition({ ...condition, country: e.value });
    }

    const errorCodeSelect = (selected) => {
        setCondition({ ...condition, errorCodes: selected.map(item => { return item.value }) });
    }

    const errorClosedStatus = (type, e) => {
        setCondition({
            ...condition,
            errorStatus: { ...condition.errorStatus, [type]: e.target.checked }
        });
    }

    const selectModality = (selected) => {
        setCondition({ ...condition, acqModality: selected.map(item => { return item.value }) });
    }

    const selectProtocol = (selected) => {
        setCondition({ ...condition, acqProtocol: selected.map(item => { return item.value }) });
    }

    const acqConfirm = (type, e) => {
        setCondition({
            ...condition,
            acqConfirm: { ...condition.acqConfirm, [type]: e.target.checked }
        });
    }

    const openSaveForm = () => {
        setShowSaveForm(true);
    }

    const closeSaveForm = () => {
        if (window.confirm("close?")) {
            setShowSaveForm(false);
        }
    }

    const saveRequest = () => {
        if (window.confirm("save?")) {
            SaveStatisticsFilter(filterTitle, statisticsType, condition);
            setShowSaveForm(false);
        }
    }

    const filterTitleChange = (e) => {
        setFilterTitle(e.target.value);
    }

    const loadSavedFilter = (filters, e) => {
        if (e.target.tagName.toLowerCase() === "div") {
            if (window.confirm("load?")) {
                setStatisticsType(filters.filterType);
                setCondition(filters.condition);
            }
        }
    }

    const deleteSavedFilter = (id) => {
        if (window.confirm("delete?")) {
            DeleteSavedFilter(id);
        }
    }

    const changeDurationValue = (e) => {
        setCondition({ ...condition, durationValue: e.target.value });
    }

    const selectDurationUnit = (e) => {
        setCondition({ ...condition, durationUnit: e.value });
    }

    const dayStt = [
        { label: 'Days', value: 'days' },
        { label: 'Weeks', value: 'weeks' },
        { label: 'Months', value: 'months' },
    ]

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Condition Search</h2>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1 row">
                    <div className="col-2 wide-block">
                        <div className="text-center p-1 mt-1 bg-dark">
                            SAVED FILTERS
                        </div>
                        {
                            showSaveForm && (
                                <div className="p-2 mt-2 border">
                                    <div>
                                        <input type="text" className="w-100" onChange={filterTitleChange} />
                                    </div>
                                    <div className="text-right mt-1">
                                        <button className="btn btn-primary btn-sm mt-0" type="button" onClick={saveRequest} style={{ marginRight: '4px' }}>SAVE</button>
                                        <button className="btn btn-primary btn-sm mt-0" type="button" onClick={closeSaveForm}>CANCEL</button>
                                    </div>
                                </div>
                            )
                        }

                        {
                            savedFilter.map((item, index) => {
                                let className = "border p-1 mt-1";
                                if (savedFilter.length === (index + 1)) className += "  mb-1";

                                return (
                                    <div className={className} role="button" onClick={(e) => loadSavedFilter(item, e)}>
                                        <div className="pb-1">{item.title}</div>
                                        <div className="text-right text-dark col">
                                            {moment(item.createDate).format("YYYY.MM.DD HH:mm:ss")}&nbsp;
                                            <a href="#" onClick={() => deleteSavedFilter(item._id)}>X</a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="col-10">
                        <div className="wide-block pt-2 pb-2 mb-1">
                            <div className="row">
                                <div className="col-2">
                                    <Select
                                        options={statisticsTypes}
                                        value={statisticsTypes.filter(item => { return item.value === statisticsType })}
                                        onChange={statisticsTypeChange}
                                    />
                                </div>
                                <div className="col-2"><Select options={deviceModels} onChange={selectDeviceModel} value={deviceModels.filter(item => { return item.value === condition.model })} /></div>
                                <div className="col-2"><Select options={deviceOptions} onChange={selectDeviceOption} value={deviceOptions.filter(item => { return item.value === condition.option })} /></div>
                                <div className="col-2"><Select options={countryList} onChange={selectCountry} value={countryList.filter(item => { return item.value === condition.country })} /></div>
                                <div className="col-4 row">
                                    <div className="col-3">
                                        <label className="align-middle">
                                            {
                                                (statisticsType === 'Device' && 'Installed Date') ||
                                                (statisticsType === 'Error' && 'Begin') ||
                                                (statisticsType === 'Acquisition' && 'Activation Time')
                                            }
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <input type="text" className="w-100" onChange={changeDurationValue} value={condition.durationValue} />
                                    </div>
                                    <div className="col">
                                        <Select options={dayStt}
                                            value={dayStt.filter(item => item.value === condition.durationUnit)}
                                            onChange={selectDurationUnit} />
                                    </div>
                                </div>
                            </div>

                            {statisticsType !== 'Device' && (
                                <div className="row mt-2">
                                    {
                                        statisticsType === "Error" &&

                                        (
                                            <>
                                                <div className="col-2">
                                                    <Select
                                                        options={errorCodeList}
                                                        isMulti={true}
                                                        value={condition.errorCodes.map(item => { return { label: item, value: item } })}
                                                        onChange={errorCodeSelect}
                                                        placeholder={'Error Code'} />
                                                </div>

                                                <div className="col-10">
                                                    {
                                                        ['closed', 'notclosed'].map(x =>
                                                            <label className="align-middle" style={{ marginRight: '15px' }}>
                                                                <input type="checkbox" checked={condition.errorStatus[x]} onChange={(e) => errorClosedStatus(x, e)} />{x}
                                                            </label>)
                                                    }
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        statisticsType === 'Acquisition' &&
                                        (
                                            <>
                                                <div className="col-2">
                                                    <Select options={[
                                                        { label: 'CT', value: 'CT' },
                                                        { label: 'Cephalo', value: 'Cephalo' },
                                                        { label: 'Panorama', value: 'Panorama' },
                                                        { label: 'FaceScan', value: 'FaceScan' }
                                                    ]} isMulti={true} placeholder={'Modality'} onChange={selectModality} />
                                                </div><div className="col-2">
                                                    <Select options={[
                                                        { label: 'STANDARD', value: 'STANDARD' },
                                                        { label: 'Facial', value: 'Facial' },
                                                        { label: 'Smile', value: 'Smile' },
                                                        { label: 'Lateral', value: 'Lateral' },
                                                        { label: 'PA', value: 'PA' },
                                                        { label: 'Endo', value: 'Endo' },
                                                        { label: 'Jaw', value: 'Jaw' },
                                                        { label: 'Normal', value: 'Normal' },
                                                        { label: 'Teeth', value: 'Teeth' },
                                                        { label: 'Waters', value: 'Waters' },
                                                        { label: 'Chest', value: 'Chest' },
                                                        { label: 'ORTHOGONAL', value: 'ORTHOGONAL' },
                                                        { label: 'Sinus', value: 'Sinus' },
                                                        { label: 'Large Jaw', value: 'Large Jaw' },
                                                        { label: 'BITE', value: 'BITE' },
                                                        { label: 'TMJ', value: 'TMJ' },
                                                        { label: 'rla', value: 'rla' },
                                                    ]} isMulti={true} placeholder={'Protocol'} onChange={selectProtocol} />
                                                </div>
                                                <div className="col-2">
                                                    {["confirm", "retake", "reject"].map(x => <label>
                                                        <input type="checkbox"
                                                            checked={condition.acqConfirm[x]} onChange={e => acqConfirm(x, e)} />{x}
                                                    </label>
                                                    )}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            )}
                            <div className="row">
                                <div className="col mt-2">
                                    <button className="btn btn-primary btn-sm mt-0" type="button" onClick={openSaveForm}>SAVE FILTER</button>

                                </div>
                            </div>
                        </div>

                        <div className="wide-block pt-2 pb-2">
                            <RayTable
                                data={products}
                                columns={
                                    (statisticsType === 'Device' && StatisticsProductHeader) ||
                                    (statisticsType === 'Error' && StatisticsErrorHeader) ||
                                    (statisticsType === 'Acquisition' && StatisticsAcquisitionHeader)
                                }
                                apiPath='/product'
                                rdpage={page}
                                extendFilters={extendFilters || [{ "$match": { "installedDate": { "$gt": condition.durationFrom, "$lte": condition.durationTo } } }]}
                                options={options} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    var products = state.ProductReducer.products;
    var totalproductcount = state.ProductReducer.totalproductcount;
    var filterOptions = state.ProductReducer.filterOptions;
    var filters = state.ProductReducer.filters;
    var sort = state.ProductReducer.sort;
    var page = state.ProductReducer.page;
    var errorCodes = state.ErrorCodeReducer.errorCodes;
    var savedFilter = state.ProductReducer.savedFilters;
	const clist = state.RayCodeReducer.items;
    return { products, totalproductcount, filterOptions, filters, sort, errorCodes, savedFilter, page, clist };
};

const mapDispatch = (dispatch) => ({
    GetProductAll: (filter) => dispatch(ProductAction.GetProductAll(filter)),
    GetErrorCodeList: () => dispatch(ErrorCodeAction.GetList()),
    GetSavedFilters: () => dispatch(ProductAction.GetSavedFilters()),
    SaveStatisticsFilter: (title, filterType, condition) => dispatch(ProductAction.SaveStatisticsFilter(title, filterType, condition)),
    DeleteSavedFilter: (id) => dispatch(ProductAction.DeleteSavedFilter(id))
});

export default connect(mapState, mapDispatch)(AdminProductList);
