export const ErrorCodeConstant = {
    ERRORCODE_GET_LIST: 'GET_LIST',
    ERRORCODE_GET_ONE: 'GET_ONE',
    ERRORCODE_ADD: 'ADD',
    ERRORCODE_UPDATE: 'UPDATE',
    ERRORCODE_DELETE: 'DELETE',
    ERRORCODE_CLEAR_UPDATERES: 'CLEAR_UPDATERES',
    ERRORCODE_ERROR: 'ERROR',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
};