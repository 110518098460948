import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RayChartPie } from '../components/common';
import { CommonAction } from '../actions/CommonAction';

const WidgetPieChart = ({ name, aggregate, title, subtitle = "", indexBy, options }) => {

	const [callCount, setCallCount] = useState(0);
	const [isFetch, setIsFetch] = useState(true);
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		if(name){
			var savedData = JSON.parse(localStorage.getItem(name));
			if(savedData){
				if(new Date().getTime() - savedData.timestamp > 60 * 60 * 1000)
					savedData = null;
			}
			getData(savedData ? savedData.data : null);
		}else{
			getData(null);
		}
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => getData(null), 60 * 60 * 1000);
		return () => clearTimeout(timer);
	}, [callCount])

	const getData = async (savedData) => {
		setIsFetch(true);
		if(savedData){
			makeData(savedData);
		}else{
			const ret = await CommonAction.GetCountAction(aggregate);
			const sData = {
				timestamp : new Date().getTime(),
				data : ret.data
			}
			if(name)
				localStorage.setItem(name, JSON.stringify(sData));
			makeData(ret.data);
		}
		setIsFetch(false);
		setCallCount(callCount > 100 ? 0 : callCount + 1);
	}

	const makeData = (list) => {
		var result = [];
		list.map((x) => {
            result.push({ id : x._id.label, value : x.value });
		});		
		setChartData(result);
	}

	const handleClick = (item) => {
		console.log(item);
	}

	const chartOptions = {
		height : 180,
		handleClick : handleClick
	}

    return <div className={isFetch ? "fadeLoading mb-2" : "mb-2"}>
			<div className="card">
				<div className="card-header text-center separator">
					<div className="card-title">
						<strong>{title}</strong>
						{subtitle && <h6 className="font-montserrat all-caps">{subtitle}</h6>}
					</div>
				</div>
				{chartData.length > 0 && <RayChartPie data={chartData} options={chartOptions} /> }
				{chartData.length == 0 && <h1 className="text-center" style={{ "font-size" : "48px" }}>0</h1> }
			</div>
		</div>
};

export default WidgetPieChart;