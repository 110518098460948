import React, {useState} from 'react';
import { ResponsivePie } from '@nivo/pie'

const RayChartPie = ({ data, category, indexBy, options }) => {

    return <div style={{height: options.height}}>
        <ResponsivePie
            data={data}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.5}
            padAngle={0.5}
            cornerRadius={2}
            startAngle={-90}
            endAngle={90}
            arcLabel={d => `${d.id}(${d.value})`}
            activeOuterRadiusOffset={8}
            colors={[
                '#38c1ff',
                '#00bcd4',
                '#cddc39',
                '#6ddbdb',
                '#8bc34a',
                '#fad55c',
                '#68c182',
                '#cb94d9',
                '#f5b2d8',
                '#f2aba1',
                '#8ce7ce',
                '#97b4f4',
                '#fabd5c',
                '#f69679',
                '#fdc689',
                '#fff799',
                '#c4df9b',
                '#82ca9c',
                '#76ccc7',
                '#6dcff6',
                '#7da7d9',
                '#8393ca',
                '#8781bd',
                '#a186be',
                '#bd8cbf',
                '#f49ac1',
                '#f5989d',
                '#c7b299',
                '#998675',
                '#c69c6d'
            ]}
            enableArcLinkLabels = {false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
        />
    </div>
};

export default RayChartPie;