import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayCodeAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { Translate as t } from '../../config/Translate';

const RayCodeAdd = ({ raycode, Update, Create, Delete, callback }) => {

	var user = JSON.parse(localStorage.getItem('user'));
	var devadmin = user.adminid == "sungwon.bang";
	const [errors, setErrors] = useState([]);
	const [isUp, setIsUp] = useState(false);
	const [isDel, setIsDel] = useState(false);
	const [item, setItem] = useState(raycode);
	const [editable, setEditable] = useState(true);

	useEffect(() => {
		if (isDel && !raycode._id) {
			setIsDel(false);
			callback();
		} else {
			setItem(raycode)
		}
		if (raycode.isbuiltin)
			setEditable(devadmin || false);
	}, [raycode])

	var form_controls = [
		{
			title: '',
			items: [
				{ type: 'input', name: 'type', label: t('type'), val: item.type, required: true, isEditable: editable },
				{ type: 'input', name: 'group', label: t('group'), val: item.group, isEditable: editable },
				{ type: 'input', name: 'code', label: t('code'), val: item.code, required: true, isEditable: editable },
				{ type: 'input', name: 'name', label: t('name'), val: item.name, required: true, isEditable: editable },
				{ type: 'input', name: 'category', label: t('category'), val: item.category, isEditable: editable },
				{
					type: 'select2', name: 'datatype', label: 'Data Type', val: item.datatype || "STRING", isEditable: editable, list: [
						{ code: "STRING", title: "String" },
						{ code: "DATETIME", title: "DateTime" },
						{ code: "BOOLEAN", title: "True/False" },
					]
				},
				{ type: 'select2add', name: 'alias', label: 'Alias', val: item.alias || [], isEditable: editable, isMulti: true, list: [] },
				{ type: 'number', name: 'sortnumber', label: 'Sort Number', val: item.sortnumber || 0, isEditable: editable },
			],
		},
	];
	if (devadmin) {
		form_controls[0].items.push(
			{ type: 'checkbox', name: 'isbuiltin', label: 'Build-In', val: item.isbuiltin, isEditable: editable },
		);
	}

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	};

	const validAdd = () => {
		const errValidate = RayFormValidation(form_controls, item);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsUp(true);
			Create(item);
		}
	};

	const del = () => {
		setIsDel(true);
		Delete(item._id);
	}

	console.log("raycode", raycode);

	return (
		<>
			{raycode._id && <RayForm controls={form_controls} showAllFields={true} action={Update} actionkey={raycode._id} />}
			{!raycode._id && <RayForm controls={form_controls} isForm={true} handleChangeData={handleChangeData} errors={errors} />}
			{(editable || (!editable && devadmin)) && <div className="section">
				{raycode._id && <button className="btn btn-danger text-white" onClick={() => del()}>{t('delete')}</button>}
				{!raycode._id && <button className="btn btn-primary" onClick={() => validAdd()}>{t('add')}</button>}
				<button className="btn btn-default" onClick={() => callback()}>{t('cancel')}</button>
			</div>}
		</>
	)
};

const mapState = (state) => {
	const raycode = state.RayCodeReducer.item;
	return { raycode };
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(RayCodeAction.Update(_id, data)),
	Delete: (_id) => dispatch(RayCodeAction.Delete(_id)),
	Create: (data) => dispatch(RayCodeAction.Create(data)),
});

export default connect(mapState, mapDispatch)(RayCodeAdd);
