import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CheckValidateValue } from './RayFormValidation';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import DatePicker, { registerLocale } from "react-datepicker";
import { CountryCode } from '../../config/CountryCode';
import { Flag, isCon, isDIM, isDOA } from './CommonViews';
import { ServiceStatusChip } from '../service/ServiceCard';
import { Dropdown } from 'react-bootstrap'
import { Translate as t } from '../../config/Translate';

import { ko, ja, enUS, es, de, zhTW, zhCN, fr } from 'date-fns/locale';

const user = JSON.parse(localStorage.getItem('user'));

if (user) {
    registerLocale("locale", user.rguardSettingLanguage ? (user.rguardSettingLanguage == 'kor' && ko ||
        user.rguardSettingLanguage == 'jpn' && ja ||
        user.rguardSettingLanguage == 'eng' && enUS ||
        user.rguardSettingLanguage == 'deu' && de ||
        user.rguardSettingLanguage == 'spa' && es ||
        user.rguardSettingLanguage == 'cmn' && zhCN ||
        user.rguardSettingLanguage == 'zhh' && zhTW ||
        user.rguardSettingLanguage == 'fre' && fr) : enUS
    )
}else{
    registerLocale("locale", enUS);
}

const chkDateTimeText = (r, onlyDate = false) => {
    var rList = [];
    rList.push(moment.utc(r).local().format(!onlyDate ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'));
    rList.push(<span key={'dt_0'} className="text-muted"> ({moment.utc(r).local().fromNow()})</span>);

    return rList;
};

const viewText = (r, options, list = null) => {
    var rList = [];
    const countKeys = ["country"];
    if (r) {
        if (options.appendix) {
            rList.push(<div key={'badge_0'}><span className="badge badge-primary">{options.appendix}</span></div>);
        }
        if (options.viewLinkPath) {
            const compareKey = options.viewCompareKey || 'title';
            var linkId = options.viewLinkUseId || options.val;
            if (list) {
                const item = list.filter((x) => x[compareKey] == linkId && x.code != '');
                linkId = item.length > 0 ? item[0][options.viewLinkUseId || 'code'] : linkId;
            }
            if (linkId) {
                var nr = options.valtype == 'date' ? chkDateTimeText(r, options.onlyDate) : r;
                rList.push(<React.Fragment key={'link_0'}>
                    {options.callbackclick && <span className='raycur text-primary' onClick={() => options.callbackclick(linkId)}>{nr}</span>}
                    {!options.callbackclick && <Link to={options.viewLinkPath + linkId}>{nr}</Link>}
                </React.Fragment>);
            } else {
                rList.push(options.valtype == 'date' ? chkDateTimeText(r, options.onlyDate) : r);
            }
        } else if (options.directLink) {
            rList.push(<a key={'a_0'} href={options.directLink} target='_blank'>{r} <ion-icon name="open-outline"></ion-icon></a>);
        } else if (options.jirastatus) {
            var cname = "chip-icon bg-primary";
            var iname = "square-outline";
            switch (options.jirastatus) {
                case "new":
                    cname = "chip-icon bg-primary";
                    iname = "square-outline";
                    break;
                case "indeterminate":
                    cname = "chip-icon bg-warning";
                    iname = "play-outline";
                    break;
                case "done":
                    cname = "chip-icon bg-success";
                    iname = "checkbox-outline";
                    break;
            }
            rList.push(<div key='chip_0' className="chip chip-media">
                <i className={cname}><ion-icon name={iname}></ion-icon></i>
                <span className="chip-label">{r}</span>
            </div>)
        } else {
            if (options.valtype == 'date' || options.type == 'datetime') {
                rList.push(chkDateTimeText(r, options.onlyDate));
            } else if (countKeys.includes(options.name) && options.val.length == 2) {
                rList.push(<React.Fragment key={`img_0`}>
                    <img src={"/assets/img/flags/" + options.val + ".png"} style={{ height: 24 }} />&nbsp;
                    {CountryCode.find(item => options.val == item.code).title}
                </ React.Fragment>);
            } else if (options.type == 'email') {
                rList.push(<a key={'a_0'} href={"mailto:" + r}><ion-icon name="mail-outline"></ion-icon> {r}</a>);
            } else {
                rList.push(r);
            }
        }
    } else {
        rList.push(options.startval || r || <i key={'na_0'} className="text-secondary">N/A</i>);
    }

    return rList;
};

export const InputBasicText = ({
    options,
    onchange,
    onupdate,
    onpinupdate,
    editable = false,
    errs = [],
    action,
    showOnly = false,
    actionkey = '',
    isForm = false,
    inline = false,
    nullval = '',
}) => {
    const [orgValue, SetOrgValue] = useState('');
    const [preValue, SetPreValue] = useState('');
    const [errMsg, SetErrMsg] = useState('');
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);    

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter((x) => x.name == options.name);
        if (err.length > 0) SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        SetErrMsg(options.required && e.target.value.trim() == '' ? options.label + ' is required' : '');
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    };

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm) return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == '') {
            SetPreValue(orgValue);
            if (action) {
                var obj = {};
                if (options.name.indexOf('.') > -1) {
                    var arr = options.name.split('.');
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    };

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm) return 'fase';

        if (showOnly) return ' text-white fs-12 ';

        return 'label';
    };

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {
                            options.required && <label style={{ color: 'red', display: 'block', float: 'left' }} >* &nbsp;</label>
                        }
                        <label className={labelCss()}>{options.label} {options.required && '(' + t('required') + ')'}</label>
                        <input
                            type="text"
                            placeholder={options.placeholder && options.placeholder}
                            className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            name={options.name}
                            autoComplete="off"
                            value={orgValue}
                            onChange={updateValue} />
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">
                            {options.name == "sn" && <> {isCon(options.extra)}</>}
                            {(options.name == "sitename" && options.extra) && <> {Flag(options.extra.country)}</>}
                            {viewText(orgValue, options) || options.nullval || 'N/A'}
                            {options.name == "sn" && <> {isDOA(options.extra)} {isDIM(options.extra)}</>}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export const InputBasicEmail = ({
    options,
    onchange,
    onupdate,
    onpinupdate,
    editable = false,
    errs = [],
    action,
    showOnly = false,
    actionkey = '',
    isForm = false,
    inline = false,
}) => {
    const [orgValue, SetOrgValue] = useState('');
    const [preValue, SetPreValue] = useState('');
    const [errMsg, SetErrMsg] = useState('');
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter((x) => x.name == options.name);
        if (err.length > 0) SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg('');
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    };

    const getClass = () => {
        if (isForm) return 'form-group basic form-group basic-default ' + (options.required && 'required');

        if (editable) {
            var className = 'form-group basic form-group basic-default ray-editable';
            if (isFocus) {
                className += options.required ? ' required ' : '';
                className += errMsg ? ' has-error ' : '';
                return className;
            } else {
                return (className += ' b-transparent');
            }
        }
        return 'form-group basic form-group basic-default b-transparent p-l-0 ' + (showOnly ? ' bg-info m-b-0' : '');
    };

    const checkVal = (e) => {
        if (isForm) return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == '') {
            if (action) {
                var obj = {};
                if (options.name.indexOf('.') > -1) {
                    var arr = options.name.split('.');
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    };

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm) return 'fase';

        if (showOnly) return ' text-white fs-12 ';

        return 'label';
    };

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <input
                            type="email"
                            placeholder={options.placeholder && options.placeholder}
                            className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            name={options.name}
                            autoComplete="off"
                            value={orgValue}
                            onChange={updateValue} />
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{viewText(orgValue, options) || options.nullval || 'N/A'}</div>
                    </div>
                </div>
            }
        </>
    );
};

export const InputBasicTel = ({
    options,
    onchange,
    onupdate,
    onpinupdate,
    editable = false,
    errs = [],
    action,
    showOnly = false,
    actionkey = '',
    isForm = false,
    inline = false,
}) => {
    const [orgValue, SetOrgValue] = useState('');
    const [preValue, SetPreValue] = useState('');
    const [errMsg, SetErrMsg] = useState('');
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter((x) => x.name == options.name);
        if (err.length > 0) SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg('');
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    };

    const getClass = () => {
        if (isForm) return 'form-group basic form-group basic-default ' + (options.required && 'required');

        if (editable) {
            var className = 'form-group basic form-group basic-default ray-editable';
            if (isFocus) {
                className += options.required ? ' required ' : '';
                className += errMsg ? ' has-error ' : '';
                return className;
            } else {
                return (className += ' b-transparent');
            }
        }
        return 'form-group basic form-group basic-default b-transparent p-l-0 ' + (showOnly ? ' bg-info m-b-0' : '');
    };

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm) return;

        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == '') {
            if (action) {
                var obj = {};
                if (options.name.indexOf('.') > -1) {
                    var arr = options.name.split('.');
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    };

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm) return 'fase';

        if (showOnly) return ' text-white fs-12 ';

        return 'label';
    };

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <input
                            type="text"
                            placeholder={options.placeholder && options.placeholder}
                            className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            name={options.name}
                            autoComplete="off"
                            value={orgValue}
                            onChange={updateValue} />
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{viewText(orgValue, options) || options.nullval || 'N/A'}</div>
                    </div>
                </div>
            }
        </>
    );
};

export const InputBasicPassword = ({ options,
    inline = false,
    onpinupdate,
    onchange, errs = [] }) => {

    const [orgValue, SetOrgValue] = useState(options.val);
    const [errMsg, SetErrMsg] = useState("");

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    }

    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <label className="label">{options.label}</label>
                <input type="password" className="form-control"
                    name={options.name}
                    value={orgValue}
                    autoComplete="off"
                    onChange={updateValue}
                    aria-required="true"
                    aria-invalid="true" />
            </div>
            {errMsg && <div className="input-info">{errMsg}</div>}
        </div>
    )
}

export const InputBasicTextArea = ({ options, onchange,
    inline = false,
    onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState("");
    const [preValue, SetPreValue] = useState("");
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg("");
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    }

    const nl2br = (text) => {
        var html = [];
        if (text) {
            text.split('\n').map((line, idx) => {
                html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
            })
        } else {
            return "N/A";
        }
        return html;
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return "";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <textarea className="form-control" rows="3"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            autoComplete="off"
                            name={options.name}
                            defaultValue={orgValue}
                            onChange={updateValue}></textarea>
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{nl2br(orgValue)}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputBasicSelect = ({ options, onchange,
    inline = false,
    onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState("");
    const [preValue, SetPreValue] = useState("");
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg("");
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        const item = options.list.filter(x => x.code == orgValue);
        if (item.length > 0) {
            if (options.label == "Status") {
                return <ServiceStatusChip item={{ status: item[0].title }} />
            } else {
                return viewText(item[0].title, options);
            }
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <select className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            onChange={updateValue}
                            name={options.name}
                            value={orgValue}>
                            {options.list.map((item) =>
                                <option value={item.code} key={item.code}>{item.title}</option>
                            )}
                        </select>
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputBasicCheckbox = ({ options, onchange,
    inline = false,
    onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState(options.val);

    useEffect(() => {
        SetOrgValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        SetOrgValue(Boolean(e.target.checked));
        var obj = {};
        obj[options.name] = Boolean(e.target.checked);
        action && action(actionkey, obj);
        onchange && onchange({ target: { name: options.name, value: Boolean(e.target.checked) } });
    }

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    const getListVal = () => orgValue === true ? "Yes" : (orgValue === false ? "No" : "N/A");

    return (
        <>
            {editable &&
                <>
                    {options.onlyCheck &&
                        <div className={options.css || "item nopadd"}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id={options.name}
                                    onFocus={() => { !isForm && SetIsFocus(true) }}
                                    onBlur={(e) => checkVal(e)}
                                    checked={orgValue.toString() == "true"}
                                    name={options.name}
                                    value={orgValue}
                                    onChange={updateValue} />
                                <label className="form-check-label" htmlFor={options.name}>{options.label}</label>
                            </div>
                        </div>
                    }
                    {!options.onlyCheck &&
                        <div className={options.css || "item nopadd"}>
                            <div className="in">
                                <div className="label"><strong>{options.label}</strong></div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox"
                                        id={options.name}
                                        onFocus={() => { !isForm && SetIsFocus(true) }}
                                        onBlur={(e) => checkVal(e)}
                                        checked={orgValue && orgValue.toString() == "true"}
                                        name={options.name}
                                        value={orgValue}
                                        onChange={updateValue} />
                                    {!options.onlyCheck && <label class="form-check-label" htmlFor={options.name}></label>}
                                    {options.onlyCheck && <label className="form-check-label" htmlFor={options.name}>{options.label}</label>}
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputBasicRadio = ({ options, onchange,
    inline = false,
    onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState("");
    const [preValue, SetPreValue] = useState("");
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg("");
        SetOrgValue(e.target.value);
        var obj = {};
        if (options.name.indexOf(".") > -1) {
            var arr = options.name.split(".");
            var obj2 = {};
            obj2[arr[1]] = orgValue;
            obj[arr[0]] = obj2;
            action && action(actionkey, obj);
        } else {
            obj[options.name] = orgValue;
            action && action(actionkey, obj);
        }
        onchange && onchange(e);
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
    }

    const getListVal = () => {
        const item = options.list.filter(x => x.code == (typeof orgValue == "boolean" ? orgValue.toString() : orgValue));
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <div className={inline ? "" : `col-md-${options.size ? options.size : '6'}`}
            onMouseEnter={() => { !isForm && SetIsView(false) }}>
            <div className={getClass()} aria-required="true">
                <label className={labelCss()}>{options.label}</label>
                {editable && (
                    options.list.map((item, idx) => {
                        return (
                            <div className="form-check form-check-inline"
                                key={item.code}>
                                <input type="radio" id={options.name + idx}
                                    onFocus={() => { !isForm && SetIsFocus(true) }}
                                    onBlur={(e) => checkVal(e)}
                                    checked={orgValue && orgValue.toString() == item.code}
                                    name={options.name}
                                    className="p-r-10"
                                    value={item.code}
                                    onChange={updateValue} />
                                <label className="form-check-label mr8" htmlFor={options.name + idx}>{item.title}</label>
                            </div>
                        )
                    })
                )}
                {!editable &&
                    <div className="form-group basic">
                        <div className="input-wrapper">
                            {options.showpin && <div className="item p-0 m-0" >
                                <div onClick={() => onpinupdate(options)}
                                    className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                    <ion-icon name="pin-outline"></ion-icon>
                                </div>
                                <label className={labelCss()}>
                                    {options.label}
                                </label>
                            </div>}
                            {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                            <div className="form-content multi">{getListVal()}</div>
                        </div>
                    </div>
                }
            </div>
            {errMsg && <label className="error">{errMsg}</label>}
        </div>
    )
}

export const InputCombo = ({ options, onchange, onupdate, editable = false, errs = [], action,
    inline = false, onpinupdate,
    showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState("");
    const [preValue, SetPreValue] = useState("");
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);
    const [list, SetList] = useState([]);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        var items = await options.getCombo();
        if (options.startval) {
            items = [{ code: "", title: options.startval }, ...items];
        }
        SetList(items);
    }

    useEffect(() => {
        SetOrgValue(options.val || []);
        SetPreValue(options.val || []);
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg("");
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        const item = list.filter(x => x.code == orgValue);
        if (item.length > 0) {
            return viewText(item[0].title, options, item);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <select className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            onChange={updateValue}
                            name={options.name}
                            value={orgValue}>
                            {list.map((item) =>
                                <option value={item.code} key={item.code}>{item.title}</option>
                            )}
                        </select>
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputSelect2 = ({ options, onchange,
    inline = false, onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false, isCounrty = false }) => {

    const onlyOne = options.isMulti ? options.isMulti == "Y" : true;
    const [orgValue, SetOrgValue] = useState(onlyOne ? {} : []);
    const [preValue, SetPreValue] = useState(onlyOne ? {} : []);
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    const selectAllOption = {
        code: "<SELECT_ALL>",
        title: "All Items"
    };

    useEffect(() => {
        if (onlyOne) {
            var item = options.list.filter(x => x.code == options.val);
            if (item.length > 0) {
                SetOrgValue(item[0]);
                SetPreValue(item[0]);
            }
        } else {
            SetOrgValue(options.val);
            SetPreValue(options.val);
        }
        setShowOptions(options.minlen ? false : true)
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e, actionMeta) => {
        SetPreValue(e);
        const { action, option, removedValue } = actionMeta;
        console.log(action, option, removedValue);

        if (action === "select-option" && option.code === selectAllOption.code) {
            SetPreValue(options.list.map(x => x.code));
            onchange && onchange({ target: { name: options.name, value: options.list.map(x => x.code) } });
        } else if ((action === "deselect-option" && option.code === selectAllOption.code) ||
            (action === "remove-value" && removedValue.code === selectAllOption.code)) {
            SetPreValue([]);
            onchange && onchange({ target: { name: options.name, value: [] } });
        } else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
            var newlist = options.list.filter(({ code }) => value !== options.code);
            SetPreValue(newlist.map(x => x.code));
            onchange && onchange(newlist.map(x => x.code));
        } else {
            SetPreValue(e.map(x => x.code) || []);
            onchange && onchange({ target: { name: options.name, value: e.map(x => x.code) || [] } });
        }
    }

    const updateValueOne = (e) => {
        SetPreValue(e);
        onchange && onchange({ target: { name: options.name, value: e.code || {} } });
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default of-initial " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable of-initial ";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default of-initial b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate({ target: { name: options.name, value: preValue || [] } });
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = preValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = preValue;
                    action(actionkey, obj);
                }
            }
        }
    }

    const checkValOne = (e) => {
        onupdate && onupdate({ target: { name: options.name, value: preValue.code || "" } });
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue.code != preValue.code && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = preValue.code;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = preValue.code;
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        var result = [];
        if (preValue == undefined)
            return;
        const items = preValue.map(val => {
            const tm = options.list.filter(x => x.code == val);
            if (tm.length > 0)
                return tm[0].title;
        })
        if (items.length > 0) {
            items.map(x => result.push(<div className="chip chip-outline me-1"><span className="chip-label pe-1">{x}</span></div>));
        } else {
            result.push(<i className="text-secondary">N/A</i>);
        }
        return result;
    }

    const getListOneVal = () => {
        const item = options.list.filter(x => x.code == orgValue.code);
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        {onlyOne &&
                            <Select
                                onBlur={(e) => checkValOne(e)}
                                value={preValue}
                                getOptionLabel={option => option.title}
                                getOptionValue={option => option.code}
                                formatOptionLabel={isCounrty && (option => (
                                    <div className='country-option'>
                                        {Flag(option.code)}
                                        <span>{option.title}</span>
                                    </div>
                                ))}
                                placeholder={options.placeholder && options.placeholder}
                                onInputChange={(e) => options.minlen && setShowOptions(e.length > (options.minlen || 0))}
                                onChange={updateValueOne}
                                options={showOptions ? options.list : []}
                            />}
                        {!onlyOne &&
                            <Select
                                isMulti
                                onBlur={(e) => checkVal(e)}
                                value={options.list.filter(obj => preValue ? preValue.includes(obj.code) : false)}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.code}
                                onInputChange={(e) => options.minlen && setShowOptions(e.length > (options.minlen || 0))}
                                onChange={updateValue}
                                options={showOptions ? (options.onlyitems ? [...options.list] : [selectAllOption, ...options.list]) : []}
                            />}
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{onlyOne ? getListOneVal() : getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}


export const InputSelect2WithGroup = ({ options, onchange,
    inline = false, onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false, isCounrty = false }) => {

    const onlyOne = options.isMulti ? options.isMulti == "Y" : true;
    const [orgValue, SetOrgValue] = useState(onlyOne ? {} : []);
    const [preValue, SetPreValue] = useState(onlyOne ? {} : []);
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    const selectAllOption = {
        code: "<SELECT_ALL>",
        title: "All Items"
    };

    useEffect(() => {
        if (onlyOne) {
            var item = options.list.filter(x => x.code == options.val);
            if (item.length > 0) {
                SetOrgValue(item[0]);
                SetPreValue(item[0]);
            }
        } else {
            SetOrgValue(options.val);
            SetPreValue(options.val);
        }
        setShowOptions(options.minlen ? false : true)
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e, actionMeta) => {
        SetPreValue(e);
        const { action, option, removedValue } = actionMeta;
        console.log(action, option, removedValue);

        if (action === "select-option" && option.code === selectAllOption.code) {
            SetPreValue(options.list.map(x => x.code));
            onchange && onchange({ target: { name: options.name, value: options.list.map(x => x.code) } });
        } else if ((action === "deselect-option" && option.code === selectAllOption.code) ||
            (action === "remove-value" && removedValue.code === selectAllOption.code)) {
            SetPreValue([]);
            onchange && onchange({ target: { name: options.name, value: [] } });
        } else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
            var newlist = options.list.filter(({ code }) => value !== options.code);
            SetPreValue(newlist.map(x => x.code));
            onchange && onchange(newlist.map(x => x.code));
        } else {
            SetPreValue(e.map(x => x.code) || []);
            onchange && onchange({ target: { name: options.name, value: e.map(x => x.code) || [] } });
        }
    }

    const updateValueOne = (e) => {
        SetPreValue(e);
        onchange && onchange({ target: { name: options.name, value: e.code || {} } });
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default of-initial " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable of-initial ";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default of-initial b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate({ target: { name: options.name, value: preValue || [] } });
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = preValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = preValue;
                    action(actionkey, obj);
                }
            }
        }
    }

    const checkValOne = (e) => {
        onupdate && onupdate({ target: { name: options.name, value: preValue.code || "" } });
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue.code != preValue.code && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = preValue.code;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = preValue.code;
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        var result = [];
        if (preValue == undefined)
            return;
        const items = preValue.map(val => {
            const tm = options.list.filter(x => x.code == val);
            if (tm.length > 0)
                return tm[0].title;
        })
        if (items.length > 0) {
            items.map(x => result.push(<div className="chip chip-outline me-1"><span className="chip-label pe-1">{x}</span></div>));
        } else {
            result.push(<i className="text-secondary">N/A</i>);
        }
        return result;
    }

    const getListOneVal = () => {
        const item = options.list.filter(x => x.code == orgValue.code);
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        {onlyOne &&
                            <Select
                                onBlur={(e) => checkValOne(e)}
                                value={preValue}
                                getOptionLabel={option => option.title}
                                getOptionValue={option => option.code}
                                formatOptionLabel={isCounrty && (option => (
                                    <div className='country-option'>
                                        {Flag(option.code)}
                                        <span>{option.title}</span>
                                    </div>
                                ))}
                                placeholder={options.placeholder && options.placeholder}
                                onInputChange={(e) => options.minlen && setShowOptions(e.length > (options.minlen || 0))}
                                onChange={updateValueOne}
                                options={showOptions ? options.list : []}
                            />}
                        {!onlyOne &&
                            <Select
                                isMulti
                                onBlur={(e) => checkVal(e)}
                                value={options.list.filter(obj => preValue ? preValue.includes(obj.code) : false)}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.code}
                                onInputChange={(e) => options.minlen && setShowOptions(e.length > (options.minlen || 0))}
                                onChange={updateValue}
                                options={showOptions ? (options.onlyitems ? [...options.list] : [selectAllOption, ...options.list]) : []}
                            />}
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{onlyOne ? getListOneVal() : getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputBasicDateTime = ({
    options,
    onchange,
    onupdate,
    onpinupdate,
    editable = false,
    errs = [],
    action,
    showOnly = false,
    actionkey = '',
    isForm = false,
    inline = false,
    nullval = '',
}) => {
    const [orgValue, SetOrgValue] = useState('');
    const [preValue, SetPreValue] = useState('');
    const [errMsg, SetErrMsg] = useState('');
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        if (options.val) {
            SetOrgValue(new Date(options.val));
            SetPreValue(new Date(options.val));
        }
    }, [options]);

    useEffect(() => {
        var err = errs.filter((x) => x.name == options.name);
        if (err.length > 0) SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        try {
            SetOrgValue(e);
            onchange && onchange({ target: { name: options.name, value: e.getTime() } });
        } catch (err) {

        }
    };

    const checkVal = (e) => {
        if (orgValue instanceof Date) {
            onupdate && onupdate({ target: { name: options.name, value: orgValue.getTime() } });
        } else {
            onupdate && onupdate({ target: { name: options.name, value: "" } });
        }
        if (isForm) return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == '') {
            SetPreValue(orgValue);
            if (action) {
                var obj = {};
                if (options.name.indexOf('.') > -1) {
                    var arr = options.name.split('.');
                    var obj2 = {};
                    obj2[arr[1]] = new Date(orgValue).getTime();
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = new Date(orgValue).getTime();
                    action(actionkey, obj);
                }
            }
        }
    };

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm) return 'fase';

        if (showOnly) return ' text-white fs-12 ';

        return 'label';
    };

    return (
        <>
            {editable &&
                <>
                    {inline &&
                        <>
                            <label className={labelCss()}>{options.label}</label>
                            {options.onlyDate && <DatePicker
                                className="form-control"
                                dateFormat={options.dateFormat || "MMMM d, yyyy"}
                                selected={orgValue}
                                onChange={(date) => updateValue(date)}
                                locale="locale"
                                todayButton="Today"
                            />}
                            {!options.onlyDate && <DatePicker
                                className="form-control"
                                showTimeSelect
                                dateFormat={options.dateFormat || "MMMM d, yyyy HH:00"}
                                timeIntervals={60}
                                selected={orgValue}
                                onChange={(date) => updateValue(date)}
                                locale="locale"
                                todayButton="Today"
                            />}
                        </>}
                    {!inline &&
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className={labelCss()}>{options.label}</label>
                                <div className="w100">
                                    {options.onlyDate && <DatePicker
                                        className="form-control"
                                        dateFormat={options.dateFormat || "MMMM d, yyyy"}
                                        selected={orgValue}
                                        onChange={(date) => updateValue(date)}
                                        locale="locale"
                                        todayButton="Today"
                                    />}
                                    {!options.onlyDate && <DatePicker
                                        className="form-control"
                                        onBlur={(e) => checkVal(e)}
                                        showTimeSelect
                                        dateFormat={options.dateFormat || "MMMM d, yyyy HH:00"}
                                        timeIntervals={60}
                                        selected={orgValue}
                                        onChange={(date) => updateValue(date)}
                                        locale="locale"
                                        todayButton="Today"
                                    />}
                                </div>
                            </div>
                            {errMsg && <div className="input-info">{errMsg}</div>}
                        </div>
                    }
                </>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{viewText(orgValue, options) || options.nullval || 'N/A'}</div>
                    </div>
                </div>
            }
        </>
    );
};

export const InputBasicNumber = ({
    options,
    onchange,
    onupdate,
    onpinupdate,
    editable = false,
    errs = [],
    action,
    showOnly = false,
    actionkey = '',
    isForm = false,
    inline = false,
    nullval = '',
    positive = false,
}) => {
    const [orgValue, SetOrgValue] = useState('');
    const [preValue, SetPreValue] = useState('');
    const [errMsg, SetErrMsg] = useState('');
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
    }, [options]);

    useEffect(() => {
        var err = errs.filter((x) => x.name == options.name);
        if (err.length > 0) SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        SetErrMsg(options.required && e.target.value.trim() == '' ? options.label + ' is required' : '');
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    };

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm) return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == '') {
            SetPreValue(orgValue);
            if (action) {
                var obj = {};
                if (options.name.indexOf('.') > -1) {
                    var arr = options.name.split('.');
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    };

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm) return 'fase';

        if (showOnly) return ' text-white fs-12 ';

        return '';
    };

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <input
                            type="number"
                            placeholder={options.placeholder && options.placeholder}
                            className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            name={options.name}
                            autoComplete="off"
                            value={orgValue}
                            onChange={updateValue}
                            min={options.positive && 0}
                        />
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{viewText(orgValue, options) || options.nullval || 'N/A'}</div>
                    </div>
                </div>
            }
        </>
    );
};

export const InputBasicSelectInput = ({ options, onchange,
    inline = false, onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState("");
    const [preValue, SetPreValue] = useState("");
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);
    const [showInput, SetShowInput] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val);
        SetPreValue(options.val);
        SetShowInput(options.list.filter(x => x.code == options.val).length == 0 || options.val == "");
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        const err = CheckValidateValue({ ...options, val: e.target.value });
        err ? SetErrMsg(err.msg) : SetErrMsg("");
        SetOrgValue(e.target.value);
        onchange && onchange(e);
    }

    const getClass = () => {
        if (isForm)
            return "form-group basic form-group basic-default " + (options.required && "required");

        if (editable) {
            var className = "form-group basic form-group basic-default ray-editable";
            if (isFocus) {
                className += options.required ? " required " : "";
                className += errMsg ? " has-error " : "";
                return className;
            } else {
                return className += " b-transparent";
            }
        }
        return "form-group basic form-group basic-default b-transparent p-l-0 " + (showOnly ? " bg-info m-b-0" : "");
    }

    const checkVal = (e) => {
        onupdate && onupdate(e);
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = orgValue;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = orgValue;
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        const item = options.list.filter(x => x.code == orgValue);
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <select className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            onChange={updateValue}
                            name={options.name}
                            value={showInput ? "" : orgValue}>
                            {options.list.map((item) =>
                                <option value={item.code} key={item.code}>{item.title}</option>
                            )}
                        </select>
                        {showInput && <input
                            type="text"
                            placeholder={options.placeholder && options.placeholder}
                            className="form-control"
                            onFocus={() => { !isForm && SetIsFocus(true) }}
                            onBlur={(e) => checkVal(e)}
                            name={options.name}
                            autoComplete="off"
                            value={orgValue}
                            onChange={updateValue} />}
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputSelect2Search = ({ options, onchange, oninputchange,
    inline = false, onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {
    const onlyOne = options.isMulti ? options.isMulti == "Y" : true;
    const [orgValue, SetOrgValue] = useState({});
    const [preValue, SetPreValue] = useState({});
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        var item = options.list.filter(x => x.code == options.val);
        if (item.length > 0) {
            SetOrgValue(item[0]);
            SetPreValue(item[0]);
        }
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        SetPreValue(e);
        onchange && onchange({ target: { name: options.name, value: e.code || {} } });
    }

    const inputChange = (e) => {
        oninputchange && oninputchange({ target: { name: options.name, value: e || {} } });
    }

    const checkVal = (e) => {
        onupdate && onupdate({ target: { name: options.name, value: preValue.code || "" } });
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue.code != preValue.code && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = preValue.code;
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = preValue.code;
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        const item = options.list.filter(x => x.code == orgValue.code);
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const getListOneVal = () => {
        const item = options.list.filter(x => x.code == orgValue.code);
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <Select
                            onBlur={(e) => checkVal(e)}
                            value={preValue}
                            getOptionLabel={option => option.title}
                            getOptionValue={option => option.code}
                            onChange={updateValue}
                            onInputChange={inputChange}
                            options={[...options.list]}
                            noOptionsMessage={() => t('nooptions')}
                        />
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{onlyOne ? getListOneVal() : getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputDateFilter = ({ options, onchange, oninputchange,
    inline = false, disabled = false, onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const [orgValue, SetOrgValue] = useState('');
    const [preValue, SetPreValue] = useState('');
    const [errMsg, SetErrMsg] = useState('');
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    const [showDD, setShowDD] = useState(false);
    const [dtOptionVal, setDtOptionVal] = useState(options.val);

    useEffect(() => {
        if (options.val) {
            setDtOptionVal(options.val);
        }
    }, [options]);

    useEffect(() => {
        var err = errs.filter((x) => x.name == options.name);
        if (err.length > 0) SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e) => {
        SetOrgValue(e);
        onchange && onchange({ target: { name: options.name, value: e.getTime() } });
    };

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm) return 'fase';

        if (showOnly) return ' text-white fs-12 ';

        return 'label';
    };

    const apply = () => {
        onchange && onchange(dtOptionVal)
        setShowDD(false);
    }

    const ClearOption = () => {
        onchange && onchange({ ...dtOptionVal, opt: "" })
        setShowDD(false);
    }

    const getPeriodMsg = (m) => {
        switch (m) {
            case "d": return "Days";
            case "w": return "Weeks";
            case "M": return "Months";
        }
    }

    const toggleCss = () => {
        var r = dtOptionVal.opt ? "primary" : "success";
        return disabled ? r + " disabled" : r
    }

    return (
        <>
            {editable &&
                <>
                    <Dropdown show={showDD} className="me-1">
                        <Dropdown.Toggle variant={toggleCss()}
                            className={dtOptionVal.opt ? "mt-0 dtfilter dtfilter_dpi" : "mt-0 dtfilter"} size="md" id="dropdown-basic" onClick={(e) => setShowDD(!showDD)}>
                            <div style={dtOptionVal.opt ? { fontSize: 14 } : {}}>{options.label}</div>
                            {dtOptionVal.opt == "latest" && <div style={{ fontSize: 12 }}>
                                <span>Last {dtOptionVal.lastVal}{getPeriodMsg(dtOptionVal.lastValOpt)}</span>
                            </div>}
                            {dtOptionVal.opt == "fixdate" && <div style={{ fontSize: 14 }}>
                                <span>{moment(dtOptionVal.s).format("YYYY-MM-DD")} ~ {moment(dtOptionVal.e).format("YYYY-MM-DD")}</span>
                            </div>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu as="Span">
                            <Dropdown.Item style={{ width: 300 }} as="div">
                                <button className="btn btn-sm m-0 p-0" onClick={(e) => setDtOptionVal({ ...dtOptionVal, opt: "latest" })} >
                                    {dtOptionVal.opt === "latest" && <ion-icon name="checkbox-outline"></ion-icon>}
                                    {dtOptionVal.opt !== "latest" && <ion-icon name="square-outline"></ion-icon>}
                                </button>
                                <span className="me-1">Last</span>
                                <input type="number" min="1" max="100"
                                    value={dtOptionVal.lastVal}
                                    onChange={(e) => setDtOptionVal({ ...dtOptionVal, lastVal: e.target.value })}
                                    className="form-control" style={{ width: 80 }}></input>
                                <select className="form-control"
                                    onChange={(e) => setDtOptionVal({ ...dtOptionVal, lastValOpt: e.target.value })}
                                    style={{ width: 80 }}
                                    value={dtOptionVal.lastValOpt}>
                                    <option value="d">Days</option>
                                    <option value="w">Weeks</option>
                                    <option value="M">Months</option>
                                </select>
                            </Dropdown.Item>
                            <Dropdown.Item style={{ width: 300 }} as="div">
                                <button className="btn btn-sm m-0 p-0" onClick={(e) => setDtOptionVal({ ...dtOptionVal, opt: "fixdate" })} >
                                    {dtOptionVal.opt === "fixdate" && <ion-icon name="checkbox-outline"></ion-icon>}
                                    {dtOptionVal.opt !== "fixdate" && <ion-icon name="square-outline"></ion-icon>}
                                </button>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={moment(dtOptionVal.s).toDate()}
                                    onChange={(date) => {
                                        if (moment(date).startOf('day').valueOf() != dtOptionVal.s)
                                            setDtOptionVal({ ...dtOptionVal, s: moment(date).startOf('day').valueOf() })
                                        // locale = "locale"
                                    }} /> ~
                                <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={moment(dtOptionVal.e).toDate()}
                                    onChange={(date) => {
                                        if (moment(date).startOf('day').valueOf() != dtOptionVal.e)
                                            setDtOptionVal({ ...dtOptionVal, e: moment(date).startOf('day').valueOf() })
                                        // locale = "locale"
                                    }} />
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item style={{ width: 300 }} as="div" onClick={(e) => apply()}>
                                <ion-icon name="play-outline"></ion-icon> Apply
                            </Dropdown.Item>
                            <Dropdown.Item style={{ width: 300 }} as="div" onClick={(e) => ClearOption()}>
                                <ion-icon name="trash-bin-outline"></ion-icon> Clear
                            </Dropdown.Item>
                            <Dropdown.Item style={{ width: 300 }} as="div" onClick={(e) => setShowDD(false)}>
                                <ion-icon name="close-outline"></ion-icon> Close
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{onlyOne ? getListOneVal() : getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}

export const InputSelect2Add = ({ options, onchange,
    inline = false, onpinupdate,
    onupdate, editable = false, errs = [], action, showOnly = false, actionkey = "", isForm = false }) => {

    const onlyOne = options.isMulti ? options.isMulti == "Y" : true;
    const [orgValue, SetOrgValue] = useState([]);
    const [preValue, SetPreValue] = useState([]);
    const [errMsg, SetErrMsg] = useState("");
    const [isView, SetIsView] = useState(isForm ? false : true);
    const [isFocus, SetIsFocus] = useState(false);

    useEffect(() => {
        SetOrgValue(options.val.map(x => { return { label: x, value: x } }));
        SetPreValue(options.val.map(x => { return { label: x, value: x } }));
    }, [options]);

    useEffect(() => {
        var err = errs.filter(x => x.name == options.name);
        if (err.length > 0)
            SetErrMsg(err[0].msg);
    }, [errs]);

    const updateValue = (e, actionMeta) => {
        SetPreValue(e);
        onchange && onchange({ target: { name: options.name, value: e.map(x => x.value) || [] } });
    }

    const checkVal = (e) => {
        onupdate && onupdate({ target: { name: options.name, value: preValue.map(x => x.value) || [] } });
        if (isForm)
            return;
        SetIsFocus(false);
        SetIsView(true);
        if (orgValue != preValue && errMsg == "") {
            if (action) {
                var obj = {};
                if (options.name.indexOf(".") > -1) {
                    var arr = options.name.split(".");
                    var obj2 = {};
                    obj2[arr[1]] = preValue.map(x => x.value);
                    obj[arr[0]] = obj2;
                    action(actionkey, obj);
                } else {
                    obj[options.name] = preValue.map(x => x.value);
                    action(actionkey, obj);
                }
            }
        }
    }

    const getListVal = () => {
        var result = [];
        if (preValue.length > 0) {
            preValue.map(x => result.push(<div className="chip chip-outline me-1"><span className="chip-label pe-1">{x.value}</span></div>));
        } else {
            result.push(<i className="text-secondary">N/A</i>);
        }
        return result;
    }

    const getListOneVal = () => {
        const item = options.list.filter(x => x.code == orgValue.code);
        if (item.length > 0) {
            return viewText(item[0].title, options);
        } else {
            return options.startval || "N/A";
        }
    }

    const labelCss = () => {
        if (editable && !isView && isFocus && !isForm)
            return "fase";

        if (showOnly)
            return " text-white fs-12 ";

        return "label";
    }

    return (
        <>
            {editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className={labelCss()}>{options.label}</label>
                        <Creatable
                            isMulti
                            value={preValue}
                            onBlur={(e) => checkVal(e)}
                            onChange={updateValue} />
                    </div>
                    {errMsg && <div className="input-info">{errMsg}</div>}
                </div>
            }
            {!editable &&
                <div className="form-group basic">
                    <div className="input-wrapper">
                        {options.showpin && <div className="item p-0 m-0" >
                            <div onClick={() => onpinupdate(options)}
                                className={options.pinned ? "raycur icon-box bg-primary" : "raycur icon-box bg-secondary"}>
                                <ion-icon name="pin-outline"></ion-icon>
                            </div>
                            <label className={labelCss()}>
                                {options.label}
                            </label>
                        </div>}
                        {!options.showpin && <label className={labelCss()}> {options.label} </label>}
                        <div className="form-content multi">{onlyOne ? getListOneVal() : getListVal()}</div>
                    </div>
                </div>
            }
        </>
    )
}
