import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CountryCode } from '../../config';
//import { SpecialityTypes } from '../../config/RayCodes';
import { InstallationAction, ErrorCodeAction, ProductAction, SiteAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const InstallationStep1 = ({ info, site, UpdateSite, GetSite, Update, isSummary = false, user }) => {
	// const [ t, i18n ] = useTranslation();
	const [errors, setErrors] = useState([]);
	const [installation, setInstallation] = useState(info);
	const [siteInfo, setSiteInfo] = useState(site);
	const [updateSiteInfo, setUpdateSiteInfo] = useState({});

	const SpecialityTypes = [
		{ code: "GP", title: t('generalpractitioner') },
		{ code: "Pros", title: t('prosthodontist') },
		{ code: "Endo", title: t('Endodontist') },
		{ code: "Perio", title: t('periodontist') },
		{ code: "Ortho", title: t('orthodontist') },
		{ code: "Ped", title: t('pediatricdentist') },
		{ code: "Oral", title: t('oralandmaxillofacialsurgeon') },
		{ code: "Public", title: t('publichealthdentist') },
		{ code: "Rad", title: t('radiologist') },
		{ code: "Other", title: t('other') },
	]

	useEffect(() => {
		setSiteInfo(site);
	}, [site]);

	useEffect(() => {
		if (info.siteId) {
			GetSite(info.siteId);
			setInstallation(info);
		}
	}, [info]);

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setSiteInfo({ ...siteInfo, [name]: value });
		setUpdateSiteInfo({ ...updateSiteInfo, [name]: value });
	};

	const form_controls = [
		{
			title: t('practice'), items: [
				{ type: "input", name: "sitename", label: t('sitename'), val: siteInfo.sitename, isEditable: !isSummary },
				{ type: "select", name: "speciality", label: t('specialty'), val: siteInfo.speciality, list: SpecialityTypes, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "tel", name: "tel", label: t('tel'), val: siteInfo.tel, isEditable: !isSummary },
				{ type: "tel", name: "fax", label: t('fax'), val: siteInfo.fax, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "email", name: "email", label: t('email'), val: siteInfo.email, isEditable: !isSummary },
				{ type: "tel", name: "hp", label: t('mobile'), val: siteInfo.hp, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "address", label: t('address'), val: siteInfo.address, isEditable: !isSummary },
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "zipcode", label: t('zipcode'), val: siteInfo.zipcode, isEditable: !isSummary },
				{ type: "select", name: "country", label: t('country'), val: !siteInfo.country ? user.companyInfo.country : siteInfo.country, list: CountryCode, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "website", label: t('website'), val: siteInfo.website, isEditable: !isSummary }
			]
		},

		{
			title: t('customer'), items: [
				{ type: "input", name: "customerFirstName", label: t('firstname'), val: siteInfo.customerFirstName, isEditable: !isSummary },
				{ type: "input", name: "customerLastName", label: t('lastname'), val: siteInfo.customerLastName, isEditable: !isSummary },
				{ type: "email", name: "customerEmail", label: t('email'), val: siteInfo.customerEmail, isEditable: !isSummary },
				{ type: "tel", name: "customerHp", label: t('mobile'), val: siteInfo.customerHp, isEditable: !isSummary }
			]
		},
	];

	const Prev = (e) => {
		Update(installation._id, { ...installation, installationStep: 1 });
	};

	const validNext = () => {
		const errValidate = RayFormValidation(form_controls, site);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			UpdateSite(installation.siteId, updateSiteInfo);
			Update(installation._id, { ...installation, installationStep: 3 });
		}
	};

	return (
		<>
			<RayForm controls={form_controls} isSummary={isSummary} isForm={!isSummary} handleChangeData={handleChangeData} errors={errors} />
			{!isSummary && <div className="section mt-1 mb-2">
				<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
				<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
			</div>}
		</>
	);
};

const mapState = (state) => {
	const site = state.SiteReducer.site;
	const user = state.AuthReducer.user;
	return { site, user };
}

const mapDispatch = (dispatch) => ({
	GetSite: (siteId) => dispatch(SiteAction.GetSite(siteId)),
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	UpdateSite: (siteId, data) => dispatch(SiteAction.UpdateSiteInfo(siteId, data)),
});

export default connect(mapState, mapDispatch)(InstallationStep1);