import { AlertConstant } from '../actions/constants/AlertConstant';
const initialState = 
{ 
    type : "", 
    message : "",
    simplemessage : "",
    errors : [],
    isClose : false,
    menuOpen : false,
    topOption : {
        title : "RayGuard",
        type : "normal"
    },
};
export const AlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case AlertConstant.SUCCESS:
            return {  
                type: 'alert-success', 
                message: action.message, 
                errors : [],
                isClose : false 
            };
        case AlertConstant.ERRORMSG:
            return {  
                type: 'alert-danger', 
                message: action.message, 
                errors : [],
                isClose : false 
            };
        case AlertConstant.ERROR:
            if(typeof action.data == "string"){
                action.data = { errmsg : action.data, errors : [] }
            }
            return {...state, 
                type: 'alert-danger',
                message: action.data, 
                simplemessage : action.message || "",
                errors : action.data,
                isClose : false 
            };
        case AlertConstant.CLEAR:
            return initialState;
        case AlertConstant.CLOSED:
            return {...state, isClose : true };
        case AlertConstant.SIDEMENU_CLOSE:
            return {...state, menuOpen : false };
        case AlertConstant.SIDEMENU_OPEN:
            return {...state, menuOpen : true };
        case AlertConstant.SET_TOP:
            return {...state, topOption : action.opt };
        default:
            return state
    }
}