import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { NotificationConstant } from './constants/NotificationConstant';
import { AlertAction } from './AlertAction';

const GetAll = () => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/notification', JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: NotificationConstant.GET_ALL, list : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

const Read = (_id) => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/notification/' + _id, { type : "read" }, JWT());

	console.log('ret', ret);

	if (ret.data?.status == 'success') {
		dispatch(AlertAction.Success("Mark to read!"));
		dispatch({ type: NotificationConstant.READ, item : ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

const UnRead = (_id) => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/notification/' + _id, { type : "unread" }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Mark to unread!"));
		dispatch({ type: NotificationConstant.READ, item : ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

const ReadAll = () => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/notification', { type : "readall" }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Mark to read all!"));
		dispatch({ type: NotificationConstant.READALL });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

const DeleteAll = () => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/notification', { type : "deleteall" }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Delete all!"));
		dispatch({ type: NotificationConstant.DELETEALL });
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

const DeleteRead = () => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/notification', { type : "deleteread" }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Delete notifications!"));
		dispatch({ type: NotificationConstant.DELETEREAD });
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

const Delete = (_id) => async (dispatch) => {
	dispatch({ type: NotificationConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/notification/' + _id, { type: 'delete' }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: NotificationConstant.DELETE, _id : _id });
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
	dispatch({ type: NotificationConstant.LOADED });
};

export const NotificationAction = {
	GetAll,
	Read,
	UnRead,
	ReadAll,
	DeleteAll,
	DeleteRead,
	Delete,
};
