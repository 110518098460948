import React, { useEffect, useState } from 'react';
import { RayDelButton, S3DownloadButton } from './Buttons';
import { Link } from 'react-router-dom';
import { AppConfig, JWT } from '../../config';
import axios from 'axios';
import moment from 'moment';
import { Translate as t } from '../../config/Translate';
import AWS from 'aws-sdk';

AWS.config.update({ accessKeyId: AppConfig.S3_ID, secretAccessKey: AppConfig.S3_KEY, region: AppConfig.S3_REGION });
// const s3 = new AWS.S3({ params: { Bucket: AppConfig.S3_BUCKET }, region: AppConfig.S3_REGION });

export const FileUpload = ({ option = {}, extraData = {}, callback, basepath = "", uploadLater, doUpStart, callbackUpListChanged, button = null }) => {
	//const [t] = useTranslation();
	const [filevalue, setFilevalue] = useState("")
	const [progress, setProgress] = useState()
	const [uploadDone, setUploadDone] = useState()
	const [err, setErr] = useState("")
	const [upList, setUpLoad] = useState([])

	const uploadStart = (file) => {
		for (let i = 0; i < file.length; i++) {
			setErr("");
			var formData = new FormData();
			formData.append("file", file[i])
			var hasTypeKey = false;
			for (var key in extraData) {
				if (key == "type")
					hasTypeKey = true;

				formData.append(key, extraData[key]);
			}
			hasTypeKey || formData.append("type", "normal");

			const path = extraData?.path;

			S3Upload(path, file[i]).then(res => {
				if (!option.doneandnew)
					setUploadDone(res);

				setFilevalue("");
				callback && callback(res);
			});

			// axios.post(AppConfig.API_SERVER_PATH + "/file/upload", formData, {
			// 	headers: { "Content-Type": "multipart/form-data", "token": localStorage.getItem('userToken') },
			// 	onUploadProgress: data => {
			// 		setProgress(Math.round((100 * data.loaded) / data.total))
			// 	}
			// }).then(res => {
			// 	if (res.data.status == "success") {
			// 		if (!option.doneandnew) {
			// 			setUploadDone(res.data.data.path);
			// 		}
			// 		setFilevalue("");
			// 		callback && callback(res.data.data.path);
			// 	} else if (res.message) {
			// 		setErr(res.message);
			// 	} else if (res.data.data.path.err) {
			// 		setErr(res.data.data.path.err);
			// 	}
			// 	setProgress();
			// });
		}
	}

	const delItem = () => {
		setUploadDone();
		callback && callback();
	}

	const hiddenFileInput = React.useRef(null);

	const handleChange = e => {
		uploadStart(e.target.files)
	};

	const handleClick = e => {
		hiddenFileInput.current.click();
	};

	useEffect(() => {
		if (doUpStart && upList.length > 0) {
			uploadStart(upList)
		}
	}, [doUpStart])

	useEffect(() => {
		callbackUpListChanged && callbackUpListChanged(upList)
	}, [upList])

	return (
		<>
			{uploadDone && <FileItem fileInfo={uploadDone} callback={() => delItem()} />
			}
			{!uploadDone &&
				<>
					<a onClick={(e) => handleClick(e)}>
						{!button ? <button className="btn btn-primary btn-block mt-0">{option.label || t('selectafile')}</button> : button}
					</a>

					<input type="file"
						name={option.name || "file"}
						value={filevalue}
						ref={hiddenFileInput}
						style={{ display: 'none' }}
						onChange={e => uploadLater ? setUpLoad([...upList, ...e.target.files]) : uploadStart(e.target.files)}
						multiple
					/>
					{progress && <div>
						<div className="progress">
							<div className="progress-bar-success" style={{ width: `${progress}%` }}></div>
						</div>
					</div>}
					{err && <div className="alert alert-danger">{err}</div>}
				</>
			}
		</>
	)
}

export const FileItem = ({ fileInfo = {}, callback, showDel = true }) => {
	const [url, setUrl] = useState("");
	const [isAdmin, setIsAdmin] = useState(location.pathname.toLowerCase().startsWith('/admin/'));

	useEffect(() => {
		if (fileInfo.s3path && fileInfo.original) {
			var ext = ["JPG", "PNG", "JPEG"];
			if (ext.includes(fileInfo.s3path.slice((fileInfo.s3path.lastIndexOf(".") - 1 >>> 0) + 2).toUpperCase())) {
				getUrl();
			}
		}
	}, [fileInfo])

	const delFile = async () => {
		await axios.post(AppConfig.API_SERVER_PATH + "/file/delete", { path: fileInfo.s3path }, JWT());
		callback && callback(fileInfo);
	}

	const getUrl = async () => {
		const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
			path: fileInfo.s3path,
			ctype: "image/" + fileInfo.s3path.slice((fileInfo.s3path.lastIndexOf(".") - 1 >>> 0) + 2)
		}, JWT());

		if (ret.data.status == "success") {
			setUrl(ret.data.data);
		}
	}

	return (
		<>
			{(fileInfo && !url) &&
				<>
					<div>
						<S3DownloadButton
							text={fileInfo.name || fileInfo.original}
							path={fileInfo.s3path}
							filename={fileInfo.original}
							css="text-black" />
						<div className='text small'>
							<Link to={(isAdmin ? "/Admin/AdminUserInfo/" : "/UserInfo/") + fileInfo.creator}>{fileInfo.creatorName}</Link> / {moment(fileInfo.createDate).format('YYYY-MM-DD HH:mm:ss')}
						</div>
					</div>

					{showDel && <div className="rayrighttop"><RayDelButton callback={() => delFile()} css="btn btn-sm btn-danger float-end mt-0" /></div>}
				</>
			}
			{(fileInfo && url) &&
				<>
					{isAdmin && <div>
						<a href={url} target='_blank'><img src={url} style={{ maxWidth: 180, width: "100%" }} className='img-fluid fl pe-1' crossOrigin='anonymous' alt={fileInfo.name} /></a>
						<div className='text small'>
							<div>{fileInfo.name}</div>
							<Link to={(isAdmin ? "/Admin/AdminUserInfo/" : "/UserInfo/") + fileInfo.creator}>{fileInfo.creatorName}</Link> / {moment(fileInfo.createDate).format('YYYY-MM-DD HH:mm:ss')}
						</div>
					</div>}
					{!isAdmin && <img src={url} style={{ width: "100%" }} className='img-fluid' crossOrigin='anonymous' alt={fileInfo.name} />}
					{showDel && <div className="rayrighttop"><RayDelButton callback={() => delFile()} css="btn btn-sm btn-danger float-end mt-0" /></div>}
				</>
			}
		</>
	)
}


export const S3Upload = async (path, file, option = {}) => {
	let original = !option.isBuffer ? file.name.replace(/^.*[\\\/]/, '') : option.filename;
	let filename = !option?.keepFileName ? new Date().getTime().toString() + "." + (file.name?.split('.').pop() || option.filename?.split('.').pop()) : original;

	AWS.S3.ManagedUpload.maxTotalParts = 100;

	const upload = new AWS.S3.ManagedUpload({
		params: {
			Bucket: AppConfig.S3_BUCKET,
			Key: path + filename,
			Body: file
		}
	})

	return new Promise((resolve, reject) => {
		upload.send((err, data) => {
			if (err) {
				console.error('Upload error:', err);
				reject(err);
			} else {
				// console.log('Upload complete:', data);
				resolve({ s3path: path + filename, filename, original, err: "" });
			}
		})
	})
}