import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { SiteAction, AuthAction } from '../../actions';
import SiteInfo from '../../components/site/SiteInfo';
import SiteCustomerInfo from '../../components/site/SiteCustomerInfo';
import ProductInfo from '../../components/product/ProductInfo';
import { AppHeader, RayMaps } from '../../components/common';
import ScheduleCreate from '../../components/schedule/ScheduleCreate';
import { CalendarConstant } from '../../actions/constants/CalendarConstant';
import moment from 'moment';
import ProductListInfoBySite from '../../components/product/ProductListInfoBySite';
import { Translate as t } from '../../config/Translate';

const SiteView = ({ user, GetSite, UpdateUserFav, site, favList }) => {

	const dispatch = useDispatch();
	const [showAS, setShowAS] = useState(false);
	//const [ t, i18n ] = useTranslation();
	const { siteId, view } = useParams();
	const [maintab, setMainTab] = useState(view || "info");
	const [isLoading, setIsLoading] = useState(true);
	const [editable, setEditable] = useState(false);
	const [isFav, setIsFav] = useState(false);
	const [isMapOk, setIsMapOk] = useState(true);
	const [showMap, setShowMap] = useState(user.rguardSettingDefaultShowMap);

	useEffect(() => {
		setIsLoading(false)
		setIsFav(favList ? favList.includes(site._id) : false);
	}, [site])

	useEffect(() => {
		setIsFav(favList ? favList.includes(site._id) : false);
	}, [favList])

	useEffect(() => {
		GetSite(siteId);
	}, [])

	const removeArr = (id) => {
		const idx = favList.indexOf(id);
		if (idx > -1) {
			favList.splice(idx, 1);
		}
		return favList;
	}

	const fav = () => {
		UpdateUserFav("site", isFav ?
			{ favoriteSites: removeArr(site._id) } :
			{ favoriteSites: [...favList, site._id] });
	}

	const mainTabList = [
		{ code: "info", title: "Overview", icon: "info" },
		{ code: "customer", title: "Customer", icon: "info" },
		{ code: "product", title: "Product", icon: "visibility" },
	]

	const tabDisable = (code) => {
		if (code == "product" && !site.products)
			return true;
	}

	const setSchedule = () => {
		dispatch({
			type: CalendarConstant.SET_NEW, item: {
				type: "On-site",
				color: "bg-success",
				start: moment().clone().add(4, "hours").valueOf(),
				finish: moment().clone().add(5, "hours").valueOf(),
				sn: site.product.sn,
				sitename: site.sitename,
				siteId: site.siteId,
				model: site.product.model,
				option: site.product.option
			}
		});
		setShowAS(true);
	}

	const mapOk = (bl) => {
		setIsMapOk(bl);
	}

	return (
		<>
			<div className="appHeader no-border">
				<AppHeader title={site.sitename} />
				<div className="right">
					{site.product && <a href onClick={() => setSchedule()} className="headerButton">
						<ion-icon name="calendar-outline"></ion-icon>
					</a>}
					<a href onClick={() => setEditable(!editable)} className="headerButton">
						<ion-icon name="create-outline"></ion-icon>
					</a>
					<a href onClick={() => fav()} className="headerButton">
						<ion-icon name={isFav ? "heart" : "heart-outline"}></ion-icon>
					</a>
				</div>
			</div>
			<div id="appCapsule">
				<div className="section cal-header pe-1 ps-1 pb-1">
					<ul className="nav nav-tabs style2 mt-1 mb-1">
						{mainTabList.map(x =>
							<li className="nav-item">
								<button key={x.code}
									disabled={tabDisable(x.code)}
									className={maintab == x.code ? "nav-link active" : "nav-link"} onClick={() => { setMainTab(x.code); }}>
									{x.title}
								</button>
							</li>
						)}
					</ul>
				</div>
				<div style={{ marginTop: 71 }}>
					{(!editable && showMap && isMapOk && maintab != "product") && <div className="ms-1 me-1">
						{site._id && <RayMaps
							options={{
								zoom: 18,
								draggable: true,
								mapTypeControl: false,
								streetViewControl: false,
								rotateControl: false,
								scaleControl: false,
								zoomControl: false
							}}
							callback={(bl) => mapOk(bl)}
							onMapLoad={map => { }}
							country={site.country}
							Lat={site.lat}
							Lng={site.lng}
							markerList={[]}
						/>
						}
					</div>}
					<div>
						{maintab == "info" && site.siteId && <SiteInfo siteInfo={site} editable={editable} />}
						{maintab == "customer" && site.siteId && <SiteCustomerInfo siteInfo={site} editable={editable} />}
						{maintab == "product" && site.products && <ProductListInfoBySite productInfos={site.products} editable={editable} showsn={true} />}
					</div>
				</div>
			</div>
			<ScheduleCreate showAS={showAS} callback={() => setShowAS(false)} />
		</>
	)
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	var site = state.SiteReducer.site;
	const favList = state.FavoriteReducer.site;
	return { user, site, favList };
};

const mapDispatch = dispatch => ({
	GetSite: (_id) => dispatch(SiteAction.GetSite(_id)),
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
})

export default connect(mapState, mapDispatch)(SiteView);