import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthAction } from '../../actions/AuthAction';
import { AppHeader } from '../../components/common';
import { history } from '../../history';
import { InputBasicPassword } from '../../components/common/Inputs';

const PasswordConfirm = () => {

    const [ errors, setErrors ] = useState([]);
    const [ inputs, setInputs ] = useState({ password: '' });
    const [ submitted, setSubmitted ] = useState(false);
    const { password } = inputs;

    const [ t ] = useTranslation();

    useEffect(() => { 
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(password){
            setErrors([]);
            AuthAction.checkPassword(password).then((ret) => {
                ret ? 
                    history.push({ pathname:"/MyInfo", state: { auth : true } }) : 
                    setErrors([{ name : "password", msg : "Incorrect ID or Password"}]);
                setSubmitted(false);
            });
        }
    }

    const passwordOptions = {
        name : "password" ,
        label : t("password"),
        required : true,
        val : password,
        errs : errors,
        size : "6" 
    }

    return (
        <>
            <div className="appHeader bg-primary text-light">
				<AppHeader title={t("checkpwcomment")} />
                <div className="right">
					<a href onClick={() => setEditable(!editable)} className="headerButton">
						<ion-icon name="create-outline"></ion-icon>
					</a>
                </div>
            </div>
			<div id="appCapsule">
                <div className="section">
                    <div className="section-title">{t("checkpwcomment")}</div>
                    <div className="card">
                        <div className="card-body">
                            <form method="post" role="form" autoComplete="off">
                                <InputBasicPassword options={passwordOptions} onchange={handleChange} /> 
                                <div className="form-group">
                                    <button type="button" 
                                        className={"btn btn-primary waves-effect waves-classic" + (submitted ? " disabled" : "")}
                                        onClick={handleSubmit}>{t("submit")}</button>
                                    <Link to="/UserAccount" className="btn btn-default">{t("backtomyaccount")}</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordConfirm;