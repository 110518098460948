import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Translate as t } from '../../config/Translate';

const SurveyStep5 = ({ info }) => {
	// const [t] = useTranslation();

	return (
		<>
			<div className="section text-center mt-2 mb-2">
				<div className="card">
					<div className="card-body">
						<div className="listed-detail p-4">
							<div className="icon-wrapper">
								<div className="iconbox bg-success">
									<ion-icon name="checkmark-sharp" role="img" className="md hydrated" aria-label="checkmark sharp"></ion-icon>
								</div>
							</div>
							<h2 className="mt-2">{t('preinstallationcomplete')}</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="section">
				<div>
					<Link to="/Calendar" className="btn btn-block btn-primary btn-lg">{t('calendar')}</Link>
					<Link to="/" className="btn btn-block btn-outline-primary btn-lg">{t('home')}</Link>
				</div>
			</div>
		</>
	);
};

export default SurveyStep5;