import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppHeader } from '../../components/common';
import { RayForm, RayFormValidation } from '../../components/common';
import { LanguageCode, Sitemap, CountryCode } from '../../config';
import { UserAction } from '../../actions/UserAction';
import { Translate as t } from '../../config/Translate';

export const UserInfo = () => {

    const { adminid } = useParams();
    //const [ t, i18n ] = useTranslation();

    const [inputs, setInputs] = useState({});
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        hp: "",
        tel: "",
        language: "",
    });
    const [companyInfo, setCompanyInfo] = useState({
        name: "",
        address: "",
        tel: "",
        fax: "",
        email: "",
    });
    const [countryName, setCountryName] = useState("");

    const isEditable = false;

    useEffect(() => {
        getUserInfo();
    }, [])

    const getUserInfo = async () => {
        const usr = await UserAction.getUserInfo(adminid);
        console.log(usr);
        setUser(usr);
        setCompanyInfo(usr.companyInfo);
    }

    useEffect(() => {
        setInputs({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            hp: user.hp,
            tel: user.tel && user.tel,
            language: user.language,
            companyInfoname: companyInfo.name || "",
            companyInfoaddress: companyInfo.address || "",
            companyInfotel: companyInfo.tel || "",
            companyInfofax: companyInfo.fax || "",
            companyInfoemail: companyInfo.email || "",
        });
        const cName = CountryCode.filter((obj) => { return obj.code == companyInfo.country; });
        if (cName.length)
            setCountryName(cName[0].title);
    }, [user]);

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "firstName", label: "First Name", val: inputs.firstName, required: true, isEditable: isEditable },
                { type: "input", name: "lastName", label: "Last Name", val: inputs.lastName, required: true, isEditable: isEditable },
            ], editinfo: true
        },
        {
            title: "", items: [
                { type: "email", name: "email", label: "Email", val: inputs.email, required: true, isEditable: isEditable },
                { type: "tel", name: "hp", label: "Mobile", val: inputs.hp, isEditable: isEditable },
            ], editinfo: true
        },
        {
            title: "", items: [
                { type: "select", name: "language", label: "Language", val: inputs.language, list: LanguageCode, isEditable: isEditable },
            ], editinfo: true
        },
        {
            title: "", items: [
                { type: "select", name: "country", label: "Country", val: countryName, list: CountryCode },
                { type: "email", name: "companyInfo.email", label: "Email", val: inputs.companyInfoemail },
            ], editinfo: false
        },
        {
            title: "", items: [
                { type: "input", name: "companyInfo.tel", label: "Tel", val: inputs.companyInfotel },
                { type: "input", name: "companyInfo.fax", label: "Fax", val: inputs.companyInfofax },
            ], editinfo: false
        },
        {
            title: "", items: [
                { type: "input", name: "companyInfo.address", label: "Address", val: inputs.companyInfoaddress, size: 12 },
            ], editinfo: false
        },
    ]

    return (
        <>
            <div className="appHeader no-border">
                <AppHeader title={adminid} />
                <div className="right">
                </div>
            </div>
            <div id="appCapsule">
                <div className="section mt-2 mb-2 text-center">
                    <div className="avatar-section">
                        {user.avatar && <img src={user.avatar} alt="avatar" className="imaged w100 rounded" />}
                        {!user.avatar && <img src="/assets/img/avatar_default_none.png" alt="avatar" className="imaged w100 rounded" />}
                    </div>
                </div>
                <div className="mb-2">
                    <RayForm
                        controls={isEditable ? form_controls.filter(c => c.editinfo) : form_controls}
                        readOnly={!isEditable} />
                </div>
            </div>
        </>
    )
}