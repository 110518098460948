import { RayTable } from '../../components/common';
import { AdminSparePartsOrderListManagerHeader } from '../../components/common/TableHeader';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import html2pdf from 'html2pdf.js'
import { AppConfig, JWT } from '../../config';
import axios from 'axios';
import { history } from '../../history';
import { PIContents } from '../../config/PIContents';
// import { S3Upload } from '../../components/common/FileUpload';

const SparePartsOrderListManager = ({ orderList, GetOrderList, sparePartsList, GetSparePartsList, groupList, outContents, GetGroupList, GetOutContents, ChangeStatus }) => {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});
    const [currency, setCurrency] = useState({ mark: '', unit: '' });
    const [amount, setAmount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const units = [
        { code: 'USD', title: '$' },
        { code: 'EUR', title: '€' },
        { code: 'JPY', title: '￥' },
        { code: 'CNY', title: '￥' }
    ];

    useEffect(() => {
        // if (!orderList || orderList.length == 0)
        GetOrderList();

        // if (!sparePartsList || sparePartsList.length == 0)
        GetSparePartsList();

        // if (!groupList || groupList.length == 0)
        GetGroupList();
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const unit = groupList.filter(x => x.company.includes(user.companyId))[0]?.currencyUnit;
        setCurrency({ unit, mark: units.filter(x => x.code == unit)[0]?.title });
    }, [selected]);

    useEffect(() => {
        if (selected?.orderList) {
            let val = 0;
            selected?.orderList.forEach(x => {
                val += (x.price * x.quantity);
            });

            setAmount(val);
        }
    }, [selected?.orderList]);

    useEffect(() => {
        setList(orderList.map(x => {
            return {
                _id: x._id,
                companyId: x.companyId,
                companyName: x.companyInfo.name,
                orderDate: x.orderDate,
                type: x.type,
                orderer: x.orderer,
                status: GetOrderStatus(x.status),
                address: x.address,
                tel: x.tel,
                orderList: x.orderList,
                pi: x?.pi,
            }
        }));
    }, [orderList]);

    const options = {
        keyCol: "code",
        filterColumns: [
            { col: 'companyName', val: 'ALL' },
            { col: 'type', val: 'ALL' },
            { col: 'status', val: 'ALL' },
        ],
        defaultSort: 'orderDate',
        defaultSortType: -1,
        hideListCount: true,
        filterpageoption: {
            filterDateOptions: [{ code: 'orderDate', label: '주문일' }],
            page: 1,
        },
        itemperpage: 20
    }

    const rowSelect = (item) => {
        const selectedUnit = groupList.find(x => x.company.indexOf(item.companyId) > -1)?.currencyUnit;
        const unitInfo = units.find(x => x.code === selectedUnit);

        setSelected({
            _id: item._id,
            companyId: item.companyId,
            companyName: item.companyName,
            status: item.status,
            type: item.type,
            orderDate: moment(item.orderDate).format("YYYY-MM-DD HH:mm:SS"),
            address: item.address,
            tel: item.tel,
            orderer: item.orderer,
            orderList: item.orderList,
            email: item.email,
            pi: item?.pi,
            currency: { unit: unitInfo?.code, mark: unitInfo?.title }
        });
    }

    useEffect(() => {
        if (selected.status === 'SEND')
            GetOutContents(selected._id);
    }, [selected._id]);

    const getPartsName = (partsId) => {
        return (sparePartsList.filter(x => x._id == partsId)[0]).servicePartName;
    }

    const downloadPI = async (path, filename) => {
        const url = await getUrl(path, filename);

        if (url)
            location.href = url;

    }

    const getUrl = async (path, filename) => {
        const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
            path,
            name: filename,
        }, JWT());

        if (ret.data.status == "success")
            return ret.data.data;
        else
            return ''
    }

    const InvoiceTrackingURL = (forwardingAgent, invoiceNumber) => {
        let invoiceLink = '';

        switch (forwardingAgent) {
            case "TNT": invoiceLink = `http://www.tnt.com/webtracker/tracking.do?searchType=CON&cons=${invoiceNumber}`; break;
            case "DHL": invoiceLink = `http://www.dhl.com/en/express/tracking.html?AWB=${invoiceNumber}&brand=DHL`; break;
            case "Fedex": invoiceLink = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${invoiceNumber}`; break;
            case "UPS": invoiceLink = `http://www.ups.com/WebTracking/track?InquiryNumber1=${invoiceNumber}`; break;
        }

        return invoiceLink;
    }

    const GetOrderStatus = (val) => {
        switch (val.toLowerCase()) {
            case "order":
            case "ordered":
                return "ORDERED";

            case "send":
            case "sended":
                return "SENT";

            case "pi_publish":
                return "PI_PUBLISHED";

            case "pi_confirm":
                return "PI_CONFIRMED";

            case "ci_publish":
                return "CI_PUBLISHED";

            case "ci_confirm":
                return "CI_CONFIRMED";

            case "receive":
                return "RECEIVED";

            case "cancel":
                return "CANCELED";
        }
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">주문목록</h2>
                <div className="ray-right">
                    <Link to={"/Admin/AdminSpareParts"} className="btn btn-primary btn-sm">뒤로가기</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className='row m-1 p-0'>
                    <div className="col-5 m-0 p-1">
                        <div className='card p-2'>
                            <RayTable
                                data={list}
                                columns={AdminSparePartsOrderListManagerHeader}
                                options={options}
                                rowSelectCallback={rowSelect}
                            />
                        </div> 
                    </div>

                    <div className='col-5 m-0 p-1'>
                        <div className='card p-2'>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">구분</label>
                                    <div className="form-content multi">
                                        <strong>{selected.type}</strong>
                                    </div>
                                </div>
                            </div>


                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">업체명</label>
                                    <div className="form-content multi">
                                        <strong>{selected.companyName}</strong>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">주문상태</label>
                                    <div className="form-content multi">
                                        <strong>{selected.status}</strong>&nbsp;
                                        {
                                            (selected.status === 'PI_PUBLISH' || selected.status === 'PI_CONFIRM') &&
                                            <>
                                                <button
                                                    className='btn btn-primary btn-sm mt-0'
                                                    onClick={() => downloadPI(selected.pi.s3path, selected.pi.originFileName)}>PI확인</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">주문일</label>
                                    <div className="form-content multi">
                                        {selected.orderDate}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">주문목록</label>
                                    <div className="form-content multi">
                                        {
                                            (selected && selected.orderList) &&
                                            <>
                                                {selected.type == 'CHARGE' && <div className='order-list-unit'>({selected.currency.unit}{selected.currency.mark})</div>}
                                                <table className='order-list-table'>
                                                    <thead>
                                                        <tr>
                                                            <td>NAME</td>
                                                            <td>QTY</td>
                                                            {selected.type == 'CHARGE' && <td>PRICE</td>}
                                                            <td>MEMO</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            selected?.orderList?.map((x, idx) => {
                                                                return (
                                                                    <tr key={`order_list_${idx}`}>
                                                                        <td>{getPartsName(x.partsId)}</td>
                                                                        <td>{x.quantity}</td>
                                                                        {selected.type == 'CHARGE' && <td>{x.price}</td>}
                                                                        <td>{x.memo}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                {selected.type == "CHARGE" && <div className='order-list-amount'> 합계 : {amount}{selected.currency.mark}</div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                selected.status === 'SEND' &&
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">선적정보</label>
                                        <div className="form-content multi">
                                            {outContents?.forwardingAgent}
                                            {
                                                outContents?.invoiceNumber &&
                                                <>&nbsp;(<a href={InvoiceTrackingURL(outContents.forwardingAgent, outContents.invoiceNumber)} target='_blank'>{outContents.invoiceNumber}</a>)</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">주소</label>
                                    <div className="form-content multi">
                                        {selected.address}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label">주문자 </label>
                                    <div className="form-content multi">
                                        {selected.orderer}
                                        {
                                            selected.email &&
                                            <>({selected.email})</>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                selected.status === 'ORDERED' &&
                                <div className='text-right'>
                                    {
                                        selected.type === 'CHARGE' &&
                                        <button type='button' className='btn btn-primary' onClick={() => setShowModal(true)} >PI 발행</button>
                                    }
                                    {
                                        selected.type === 'FREE' &&
                                        <button type='button' className='btn btn-primary' onClick={() => history.push(`/Admin/AdminSparePartsOutRegist/${selected._id}`)}>출고등록</button>
                                    }
                                </div>
                            }
                            {
                                selected.status === 'PI_CONFIRMED' &&
                                <div className='text-right'>
                                    <button type='button' className='btn btn-primary' onClick={() => history.push(`/Admin/AdminSparePartsOutRegist/${selected._id}`)}>출고등록</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PIForm
                order={selected}
                amount={amount}
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                changeStatus={(companyId, _id, status, pi) => ChangeStatus(companyId, _id, status, pi)}
            />
        </>
    )
}

const PIForm = ({ order, amount, showModal, closeModal, changeStatus }) => {
    const pdfOption = {
        margin: 0.3,
        filename: "",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scrollY: 0, scale: 3, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', useCORS: true },
        pagebreak: { avoid: 'tr', mode: 'css' }
    }

    const [data, setData] = useState({
        orderId: '',
        hq: {
            address: '221, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea',
            tel: '+82-70-7601-0964',
            fax: '+82-2-6280-5534',
            email: 'ray_cs@raymedical.co.kr'
        },
        company: { name: '', address: '', tel: '' },
        publish_date: moment().valueOf(),
        refNo: '',
        currency: { unit: '', mark: '' },
        registInfo: { date: '', refNo: '' },
        item: [],
        deliveryDetails: [],
        paymentTerm: [],
        paymentDetails: [],
        remarks: ''
    });

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setData({
            ...data,
            orderId: order._id,
            company: {
                ...data.company,
                companyId: order.companyId,
                name: order.companyName,
                address: order.address,
                tel: order.tel,
            },
            currency: order.currency,
            deliveryDetails: PIContents.find(x => x.companyId.includes(order.companyId) > -1).deliveryDetails,
            paymentTerm: PIContents.find(x => x.companyId.includes(order.companyId) > -1).paymentTerm,
            paymentDetails: PIContents.find(x => x.companyId.includes(order.companyId) > -1).paymentDetails,
        });
    }, [order]);

    const piPublish = () => {
        if (!data.refNo)
            alert("Ref No를 입력해주세요.");
        else {
            if (confirm('PI가 발행되면 수정이 불가능합니다.\n진행하시겠습니까?')) {
                setShowLoader(true);
                const el = document.getElementsByClassName('pi-preview-container')[0];

                const filename = data.refNo + '.pdf';
                pdfOption.filename = filename;

                html2pdf().set(pdfOption).from(el).outputPdf('arraybuffer').then((buffer) => {
                    // S3Upload("spareparts/pi/", new Blob([buffer]), { isBuffer: true, filename: filename || 'download.pdf' }).then(res => {
                    //     const pi = {
                    //         refNo: data.refNo,
                    //         s3path: res.s3path,
                    //         savedFileName: res.filename,
                    //         originFileName: res.original,
                    //     };

                    //     changeStatus(data.company.companyId, data.orderId, 'PI_PUBLISH', pi);
                    //     closeModal();
                    // });

                    const formData = new FormData();

                    formData.append("file", new Blob([buffer]), filename + '.pdf');
                    formData.append("type", "normal");
                    formData.append("path", "spareparts/pi/");

                    axios.post(AppConfig.API_SERVER_PATH + "/file/upload", formData, {
                        headers: { "Content-Type": "multipart/form-data", "token": localStorage.getItem('userToken') },
                    }).then(res => {
                        if (res.data.status === "success") {
                            const pi = {
                                refNo: data.refNo,
                                s3path: res.data.data.path.s3path,
                                savedFileName: res.data.data.path.filename,
                                originFileName: res.data.data.path.original,
                            };

                            changeStatus(data.company.companyId, data.orderId, 'PI_PUBLISH', pi);
                            closeModal();
                        }
                        else if (res.message) {
                            alert(res.message);
                        }
                        else if (res.data.data.path.err) {
                            alert(res.data.data.path.err);
                        }
                    });

                    setShowLoader(false);
                });
            }
        }
    }

    const exportPdf = () => {
        if (!data.refNo) {
            alert("Ref No를 입력해주세요.");
        } else {
            setShowLoader(true);

            pdfOption.filename = data.refNo;

            const el = document.getElementsByClassName('pi-preview-container')[0];
            html2pdf().set(pdfOption).from(el).save().then(() => {
                setShowLoader(false);
            });
        }
    }

    return (
        <Modal show={showModal} onHide={() => closeModal()} size='lg' dialogClassName="action-sheet" scrollable={true}>
            <Modal.Header>
                <Modal.Title><h2 className="modal-title">PI 발행</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pi-preview-container" style={{ fontSize: '15px' }}>
                    <div className="pi-company-info">{data.hq.address}</div>
                    <div className="pi-company-info">TEL: {data.hq.tel} / FAX: {data.hq.fax} / E-mail: {data.hq.email}</div>

                    <div className="pi-title">PROFORMA INVOICE</div>

                    <div style={{ marginBottom: '35px' }}>
                        <div style={{ display: 'inline-block', width: '50%' }}>
                            <div>Messrs :</div>
                            <div><b>{data.company.name}</b></div>
                        </div><div style={{ display: 'inline-block', width: '50%', textAlign: 'right' }}>
                            <div>Date : {moment(data.publish_date).format('DD / MM / YYYY')}</div>
                            <div>
                                Ref.No: {data.refNo}

                            </div>
                        </div>
                        <div>{data.company.address}</div>
                        <div>TEL : {data.company.tel}</div>
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <div style={{ display: 'inline-block', width: '50%' }}>
                            1.ITEM
                        </div><div style={{ display: 'inline-block', width: '50%', textAlign: 'right' }}>
                            ({data.currency?.unit}{data.currency?.mark})
                        </div>
                    </div>

                    <table className="pi-item-list">
                        <thead>
                            <tr>
                                <td>No.</td>
                                <td colSpan={2}>DESCRIPTION</td>
                                <td colSpan={2}>Q'TY</td>
                                <td>U/PRICE</td>
                                <td>AMOUNT</td>
                                <td>TOTAL</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                order.orderList?.map((x, idx) => {
                                    return (
                                        <tr key={`order_${idx}`}>
                                            <td className="pi-item text-center">{idx + 1}</td>
                                            <td className="pi-item-divide-1 text-center">{x.serviceCode}</td>
                                            <td className="pi-item-divide-2" style={{ paddingLeft: '10px' }}>{x.servicePartName}</td>
                                            <td className="pi-item-divide-1 text-center" style={{ width: '50px' }}>{x.quantity}</td>
                                            <td className="pi-item-divide-2 text-center" style={{ width: '30px' }}>EA</td>
                                            <td className="pi-item text-center">{x.price} {data.currency?.mark}</td>
                                            <td className="pi-item text-center">{x.price * x.quantity} {data.currency?.mark}</td>
                                            {
                                                idx === 0 &&
                                                <td rowSpan={order.orderList.length} className="pi-item text-center">
                                                    {amount + ' ' + data.currency?.mark}
                                                </td>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <div style={{ marginTop: '35px' }}>
                        <div>2. Delivery Details</div>
                        {
                            data.deliveryDetails.map((x, idx) => {
                                return (
                                    <div key={`dd_${idx}`}>- {x.contents}</div>
                                )
                            })
                        }
                    </div>

                    <div style={{ marginTop: '35px' }}>
                        <div>3. Payment Term</div>
                        {
                            data.paymentTerm.map((x, idx) => {
                                return (
                                    <div key={`pt_${idx}`}>- {x.contents}</div>
                                )
                            })
                        }
                    </div>

                    <div style={{ marginTop: '35px' }}>
                        <div>4. Payment Details</div>
                        {
                            data.paymentDetails.map((x, idx) => {
                                return (
                                    <div key={`pd_${idx}`}>- {x.contents}</div>
                                )
                            })
                        }
                    </div>

                    <div style={{ marginTop: '35px' }}>
                        {
                            data.remarks &&
                            <>
                                <div>5. Remarks</div>
                                <div dangerouslySetInnerHTML={{ __html: data.remarks.replace(/\n/g, '<br />') }}></div>
                            </>
                        }
                    </div>

                    <div style={{ marginTop: '60px', textAlign: 'center' }}>
                        <div style={{ width: '180px', textAlign: 'center', display: 'inline-block', verticalAlign: 'top', marginRight: '150px' }}>
                            <strong>Accepted by</strong>
                            <div style={{borderBottom:"2px solid #000", height:'80px'}}>
                            </div>
                        </div>

                        <div style={{ width: '180px', textAlign: 'center', display: 'inline-block', marginLeft: '150px' }}>
                            <strong>Yours faithfully,</strong>
                            <div style={{ borderBottom: "2px solid #000", height: '80px', textAlign: 'center' }}>
                                <img src='/assets/img/sign2.png' style={{ width: '110px', height: 'auto', }} />
                            </div>
                            <strong style={{ fontSize: '12px' }}>Yong Kyu Lim / Director</strong>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: 'calc(50% - 10px)', display: 'inline-block' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <input
                            type="text"
                            value={data.refNo}
                            style={{ width: '100%', padding: '6px 5px', verticalAlign: 'middle', outline: 'none' }}
                            onChange={(e) => setData({ ...data, refNo: e.target.value })}
                            placeholder='Ref No.'
                        />
                    </div>
                    <div>
                        <textarea
                            rows={3}
                            name='remarks'
                            style={{ width: '100%', resize: 'none', outline: 'none' }}
                            onChange={(e) => setData({ ...data, remarks: e.target.value })}
                            placeholder='Remarks'
                        />
                    </div>
                </div><div style={{ width: 'calc(50% - 10px)', display: 'inline-block', textAlign: 'right' }}>
                    {
                        showLoader &&
                        <>
                            <Loader
                                type='TailSpin'
                                color='#000'
                                width={25}
                                height={25}
                                visible={true}
                                radius={10}
                                style={{ display: 'inline-block', marginRight: '10px' }} />
                            잠시만 기다려주세요...
                        </>
                    }

                    {
                        !showLoader &&
                        <>
                            <button className="btn btn-primary" onClick={() => piPublish()}>PI 발행</button>&nbsp;
                            <button className="btn btn-primary" onClick={() => exportPdf()}>PDF 다운로드</button>&nbsp;
                            <button className="btn btn-secondary" onClick={() => closeModal()}>닫기</button>
                        </>
                    }

                </div>
            </Modal.Footer>
        </Modal>
    )
}

const mapState = (state) => {
    const orderList = state.SparePartsReducer.orderList;
    const sparePartsList = state.SparePartsReducer.spareParts;
    const groupList = state.SparePartsReducer.groupList;
    const outContents = state.SparePartsReducer.outContents;
    return { orderList, sparePartsList, groupList, outContents };
};

const mapDispatch = (dispatch) => ({
    GetOrderList: (companyId) => dispatch(SparePartsAction.OrderList(companyId)),
    GetSparePartsList: () => dispatch(SparePartsAction.GetList({})),
    GetGroupList: () => dispatch(SparePartsAction.GetGroupList()),
    GetOutContents: (orderId) => dispatch(SparePartsAction.GetOutContentsByOrderId(orderId)),
    ChangeStatus: (companyId, _id, status, pi) => dispatch(SparePartsAction.ChangeStatus(companyId, _id, status, pi))
});

export default connect(mapState, mapDispatch)(SparePartsOrderListManager);
