import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { AppHeader } from '../../components/common';
import ServiceCreateByErrorCode from '../../components/service/ServiceCreateByErrorCode';
import ServiceCreate from '../../components/service/ServiceCreate';
import { Translate as t } from '../../config/Translate';

const ServiceAddNew = (props) => {

	// const [t, i18n] = useTranslation();

	const { refer, item, extraItem } = (props.location && props.location.state) || {};

	const callbackCreated = (service) => {
		history.push('/Service/' + service.ticketKey);
	};

	const getReferExtra = (itemname) => {
		switch (refer) {
			case "PRODUCTERROR":
				if (itemname == "product")
					return extraItem;
			case "PRODUCTNORMAL":
				if (itemname == "product")
					return extraItem;
			case "SITENORMAL":
				if (itemname == "site")
					return extraItem;
		}
		return {};
	}

	return (
		<>
			<div className="appHeader no-border">
				<AppHeader title={t('addnewservice')} />
				<div className="right">
				</div>
			</div>
			<div id="appCapsule">
				{refer == "PRODUCTERROR" && (
					<ServiceCreateByErrorCode
						callbackCreated={callbackCreated}
						referErrorCode={item}
						referProduct={getReferExtra("product")}
						referSite={getReferExtra("site")}
					/>
				)}
				{refer != "PRODUCTERROR" && (
					<>
						<ServiceCreate
							sn={extraItem || ""}
							siteId={item || ""}
							callbackCreated={callbackCreated} />
					</>
				)}
			</div>
		</>
	);
};

export default ServiceAddNew;