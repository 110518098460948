import { combineReducers } from 'redux';
import { AlertReducer } from './AlertReducer';
import { AuthReducer } from './AuthReducer';
import { AdminUserReducer } from './AdminUserReducer';
import { CompanyReducer } from './CompanyReducer';
import { ProductReducer } from './ProductReducer';
import { SiteReducer } from './SiteReducer';
import { ServiceReducer } from './ServiceReducer';
import { SiteSurveyReducer } from './SiteSurveyReducer';
import { ErrorCodeReducer } from './ErrorCodeReducer';
import { FavoriteReducer } from './FavoriteReducer';
import { RayCodeReducer } from './RayCodeReducer';
import { RayDataReducer } from './RayDataReducer';
import { ProductPartReducer } from './ProductPartReducer';
import { ProgramReducer } from './ProgramReducer';
import { InstallationReducer } from './InstallationReducer';
import { NotificationReducer } from './NotificationReducer';
import { CalendarReducer } from './CalendarReducer';
import { DashboardReducer } from './DashboardReducer';
import { CustomFieldReducer } from './CustomFieldReducer';
import { JiraReducer } from './JiraReducer';
import { TranslateReducer } from './TranslateReducer';
import { SparePartsReducer } from './SparePartsReducer';

const RootReducer = combineReducers({
	AlertReducer,
	AuthReducer,
	ProductReducer,
	AdminUserReducer,
	CompanyReducer,
	SiteReducer,
	ServiceReducer,
	SiteSurveyReducer,
	ErrorCodeReducer,
	FavoriteReducer,
	RayCodeReducer,
	RayDataReducer,
	ProductPartReducer,
	ProgramReducer,
	InstallationReducer,
	NotificationReducer,
	CalendarReducer,
	DashboardReducer,
	CustomFieldReducer,
	JiraReducer,
	TranslateReducer,
	SparePartsReducer
});
export default RootReducer;
