import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AuthAction, TranslateAction } from '../../actions';
import { LanguageCode, TimeZoneCode, Sitemap } from '../../config';
import { RayForm, RayFormValidation } from '../../components/common';
import { Translate as t } from '../../config/Translate';

import moment from 'moment';
import localeEN from 'moment/locale/en-gb';
import localeZHCN from 'moment/locale/zh-cn';
import localeZHTW from 'moment/locale/zh-tw';
import localeSP from 'moment/locale/es-us';
import localeDE from 'moment/locale/de';
import localeKO from 'moment/locale/ko';
import localeJA from 'moment/locale/ja';
import localeFR from 'moment/locale/fr';

import { AuthReducer } from '../../reducers/AuthReducer';

const UserSettings = ({ user, UpdateUser, isUpdateRes, ClearUpdateResult, version }) => {

    const [i18n] = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState([]);

    const AvailableLang = [
        { code: "deu", title: "German" },
        { code: "eng", title: "English" },
        { code: "spa", title: "Spanish" },
        { code: "cmn", title: "Chinese - Simplified" },
        { code: "zhh", title: "Chinese - Traditional" },
        { code: "jpn", title: "Japanese" },
        { code: "kor", title: "Korean" },
        { code: "fre", title: "French" }
    ]

    const AvailableLangEmail = [
        { code: "deu", title: "German" },
        { code: "eng", title: "English" },
        { code: "spa", title: "Spanish" },
        { code: "cmn", title: "Chinese - Simplified" },
        { code: "zhh", title: "Chinese - Traditional" },
        { code: "jpn", title: "Japanese" },
        { code: "kor", title: "Korean" },
    ]

    const ImageRes = [
        { code: "O", title: "Original" },
        { code: "H", title: "High (1440x1080)" },
        { code: "S", title: "Standard (960x720)" }
    ]

    const [inputs, setInputs] = useState({
        rguardSettingLanguage: user.rguardSettingLanguage || "eng",
        rguardSettingEmailReportLanguage: user.rguardSettingEmailReportLanguage || "eng",
        rguardSettingTimezone: user.rguardSettingTimezone || (new Date()).getTimezoneOffset(),
        rguardSettingImgResolution: user.rguardSettingImgResolution || "S",
        rguardSettingDarkMode: user.rguardSettingDarkMode || false,
        rguardSettingDefaultShowMap: user.rguardSettingDefaultShowMap,
        rguardSettingHomeErrorScope: user.rguardSettingHomeErrorScope == undefined ? 1 : user.rguardSettingHomeErrorScope,
        rguardSettingHomeUpcomingScope: user.rguardSettingHomeUpcomingScope || 7,
        rguardSettingHomeOverdueScope: user.rguardSettingHomeOverdueScope == undefined ? 14 : user.rguardSettingHomeOverdueScope,
        rguardSettingHomeRecentInstallScope: user.rguardSettingHomeRecentInstallScope || 7,
        rguardSettingDefaultFav: user.rguardSettingDefaultFav || false,
        rguardSettingHideClosedIssue: user.rguardSettingHideClosedIssue || false
    });

    useEffect(() => {
        console.log("userSetting : ==", user);
    }, [])

    const errorDayOptions = [
        { code: 1, title: "1 " + t('day') },
        { code: 7, title: "1 " + t('week') },
        { code: 14, title: "2 " + t('weeks') },
        { code: 30, title: "1 " + t('month') },
        { code: 0, title: t('all') }
    ]

    const upComingScope = [
        { code: 7, title: "1 " + t('week') },
        { code: 14, title: "2 " + t('weeks') },
        { code: 30, title: "1 " + t('month') }
    ]

    const overDueScope = [
        { code: 7, title: "1 " + t('week') },
        { code: 14, title: "2 " + t('weeks') },
        { code: 30, title: "1 " + t('month') },
        { code: 60, title: "2 " + t('month') },
        { code: 90, title: "3 " + t('month') },
        { code: 0, title: t('all') }
    ]

    const recentInstallScope = [
        { code: 7, title: "1 " + t('week') },
        { code: 14, title: "2 " + t('weeks') },
        { code: 30, title: "1 " + t('month') },
    ]

    const form_controls = [
        // { title: "Theme", items : [
        //     { 
        //         type : "checkbox", 
        //         name : "rguardSettingDarkMode", 
        //         label : "Dark Mode", 
        //         val : inputs.rguardSettingDarkMode || false },
        // ] },
        {
            title: t('preferences'), items: [
                {
                    type: "select",
                    list: AvailableLang,
                    name: "rguardSettingLanguage",
                    label: t('systemlanguage'),
                    val: inputs.rguardSettingLanguage
                },
                {
                    type: "select",
                    list: AvailableLangEmail,
                    name: "rguardSettingEmailReportLanguage",
                    label: t('email') + " / " + t('report') + " - " + t('language'),
                    val: inputs.rguardSettingEmailReportLanguage
                },
                {
                    type: "select",
                    list: TimeZoneCode(),
                    name: "rguardSettingTimezone",
                    label: t('timezone'),
                    val: inputs.rguardSettingTimezone
                },
                // { 
                //     type : "select", 
                //     list : ImageRes,
                //     name : "rguardSettingImgResolution", 
                //     label : t('imageresolution'), 
                //     val : inputs.rguardSettingImgResolution },
                {
                    type: "checkbox",
                    name: "rguardSettingDefaultShowMap",
                    label: t('defaultmapview'),
                    val: inputs.rguardSettingDefaultShowMap
                }]
        },
        {
            title: t('displayinterval'), items: [
                {
                    type: "select",
                    list: errorDayOptions,
                    name: "rguardSettingHomeErrorScope",
                    label: t('error'),
                    val: inputs.rguardSettingHomeErrorScope
                },
                {
                    type: "select",
                    list: overDueScope,
                    name: "rguardSettingHomeOverdueScope",
                    label: t('serviceoverdue'),
                    val: inputs.rguardSettingHomeOverdueScope
                },
                {
                    type: "select",
                    list: upComingScope,
                    name: "rguardSettingHomeUpcomingScope",
                    label: t('schedule'),
                    val: inputs.rguardSettingHomeUpcomingScope
                },
                {
                    type: "select",
                    list: recentInstallScope,
                    name: "rguardSettingHomeRecentInstallScope",
                    label: t('newinstalled'),
                    val: inputs.rguardSettingHomeRecentInstallScope
                },
            ]
        },
        // { title: t('search'), items : [
        //     { 
        //         type : "checkbox", 
        //         name : "rguardSettingDefaultFav", 
        //         label : t('favoriteview'), 
        //         val : inputs.rguardSettingDefaultFav || false },
        //     { 
        //         type : "checkbox", 
        //         css : "item nopadd bdT1",
        //         name : "rguardSettingHideClosedIssue", 
        //         label : t('hidecloseissues'), 
        //         val : inputs.rguardSettingHideClosedIssue || false },
        // ] }
    ]

    useEffect(() => {
        if (isUpdateRes) {
            // i18n.changeLanguage(user.rguardSettingLanguage);
            switch (user.rguardSettingLanguage) {
                case "deu": moment.updateLocale("de", localeDE); break;
                case "eng": moment.updateLocale("en-gb", localeEN); break;
                case "spa": moment.updateLocale("es-us", localeSP); break;
                case "cmn": moment.updateLocale("zh-cn", localeZHCN); break;
                case "zhh": moment.updateLocale("zh-tw", localeZHTW); break;
                case "jpn": moment.updateLocale("ja", localeJA); break;
                case "kor": moment.updateLocale("ko", localeKO); break;
                case "fre": moment.updateLocale("fr", localeFR); break;
            }
            ClearUpdateResult();
        }
    }, [user]);

    function handleChangeData(e) {
        var { name, value } = e.target;
        if (name == "rguardSettingDarkMode") {
            if (value == true) {
                document.body.classList.add('dark-mode');
            } else {
                document.body.classList.remove('dark-mode');
            }
        }
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleUpdateClick = (e) => {
        e.preventDefault();
        console.log("handleUpdateClick : -======", inputs);
        const errValidate = RayFormValidation(form_controls, inputs);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            setSubmitted(true);
            UpdateUser(inputs);

            TranslateAction.SearchByLanguageDirect(inputs.rguardSettingLanguage).then(translate => {
                localStorage.setItem('translate', JSON.stringify(translate));
            });
        }
    }

    return (
        <div className="mb-2">
            <RayForm
                controls={form_controls}
                showAllFields={true}
                handleChangeData={handleChangeData}
                errors={errors}
                isForm={true}
            />
            <div className="text-muted p-2 text-center">App. Version : v{version}</div>
            <div className="section mb-2">
                <button onClick={handleUpdateClick} className="btn btn-primary btn-block">{t('save')}</button>
            </div>
        </div>
    )
}

const mapState = state => {
    const user = AuthAction.getUserInfo(state);
    const isUpdateRes = state.AuthReducer.isUpdateRes;
    const version = state.ProgramReducer.appVersion;
    return { user, isUpdateRes, version };
}

const mapDispatch = dispatch => ({
    UpdateUser: userInfo => dispatch(AuthAction.UpdateUser(userInfo)),
    ClearUpdateResult: () => dispatch(AuthAction.ClearUpdateResult())
})

export default connect(mapState, mapDispatch)(UserSettings);