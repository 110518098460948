import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { AppHeader } from '../../components/common';
import ProductErrorList from '../../components/product/ProductErrorList';
import ProductFavList from '../../components/product/ProductFavList';
import SiteFavList from '../../components/site/SiteFavList';
import { Translate as t } from '../../config/Translate';

const MyFavorite = ({ }) => {
	const { view } = useParams();
	const [tab, setTab] = useState(view || "product");
	//const [ t, i18n ] = useTranslation();

	useEffect(() => {
	}, []);

	const tabList = [
		{ code: "product", title: t("product"), icon: "support_agent" },
		{ code: "site", title: t("site"), icon: "error_outline" },
		{ code: "service", title: t("service"), icon: "error_outline" },
	]

	return (
		<>
			<div className="appHeader bg-primary text-light">
				<AppHeader title="Monitoring" />
				<div className="right">
				</div>
			</div>
			<div id="appCapsule">
				<div className="section mt-2 mb-2">
					<div className="card mb-2">
						<div className="card-body">
							<ul className="nav nav-tabs style1">
								{tabList.map((x, idx) =>
									<li className="nav-item" key={idx}>
										<button key={x.code}
											className={tab == x.code ? "nav-link active" : "nav-link"}
											onClick={() => { setTab(x.code); history.push("/MyFavorite/" + x.code); }}>
											{x.title}
										</button>
									</li>
								)}
							</ul>
						</div>
					</div>
					{tab == "product" && <ProductFavList />}
					{tab == "site" && <SiteFavList />}
					{tab == "errorlist" && <ProductErrorList />}
				</div>
			</div>
		</>
	)
}

export default MyFavorite;