import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { InputSelect2, InputBasicText } from '../common/Inputs';
import { RayCodeAction } from '../../actions/RayCodeAction';
import DatePicker from "react-datepicker";
import { ProductConstant } from '../../actions/constants/ProductConstant';
import DeepSearchFilterSave from './DeepSearchFilterSave';
import { AdminUserAction, AuthAction, CompanyAction, ProductPartAction } from '../../actions';
import { RayDelButton } from './Buttons';
import { CountryCode } from '../../config';
import { RayStatuses } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';

const DeepSearch = ({ filterData, nowuserfilter, defaultFilter, callbackDs, callbackDSClose, savefiltertype = "product",
	UpdateUserAdminFilters, user, callbackchangeuserfilter, companies, users, GetUserAll, GetPartAll, clist,
	dispatchType = ProductConstant.UPDATE_DSFILTER, partitems,
	extratrakey, extratrakeyname, dsfilters }) => {

	const [showModal, setShowModal] = useState(false);
	const [options, setOptions] = useState([]);
	const [operators, setOperators] = useState([]);
	const [adminusers, setAdminUsers] = useState([]);
	const [allfilter, setAllFilter] = useState([]);
	const [seloptions, setSelOptions] = useState({});
	const [selval, setSelVal] = useState("");
	const [prdOptionList, setPrdOptionList] = useState([]);
	const [selprdoption, setSelPrdOption] = useState("");
	const [selcountry, setSelCountry] = useState("");
	const [selpartlist, setSelPartList] = useState([]);
	const [selclaim, setSelClaim] = useState("");
	const [fixListItem, setFixListItem] = useState([]);
	const [selFixListItem, setSelFixListItem] = useState("");
	const [parts, setParts] = useState([]);
	const [claims, setClaims] = useState([]);
	const [seluser, setSelUser] = useState("");
	const [selstatus, setSelStatus] = useState("");
	const [seloperator, setSelOperator] = useState("");
	const [selvaltf, setSelValTF] = useState(true);
	const [valtype, setValType] = useState("STRING");
	const [filter, setFilter] = useState(dsfilters);
	const [codeList, setCodeList] = useState([]);
	const [dtOptionVal, setDtOptionVal] = useState({ opt: "last", lastVal: 7, lastValOpt: "d" });
	const dispatch = useDispatch();
	const [warOptionList, setWarOptionList] = useState([]);
	const [selwarranty, setWarranty] = useState("");
	const [warProductList, setWarProductList] = useState([]);
	const [selwarrantyproduct, setWarrantyProduct] = useState("");

	useEffect(() => {
		setAllFilter(user.filters);
	}, [user.filters]);

	useEffect(() => {
		setCodes();
	}, [clist]);

	useEffect(() => {
		if (partitems.length == 0) {
			GetPartAll();
		} else {
			var clist = []
			partitems.map(x => clist.filter(y => y.code == x.type).length > 0 || clist.push({ code: x.type, title: x.type }))
			setParts(clist);
		}
	}, [partitems]);

	useEffect(() => {
		if (users.length == 0) {
			GetUserAll();
		} else {
			var clist = []
			users.map(x => clist.push({ code: x.adminid, title: x.adminid }))
			setAdminUsers(clist);
		}
	}, [users]);

	useEffect(() => {
		if (companies.length == 0) {
			//GetCompany();
		} else {
			var clist = []
			companies.map(x => clist.push({ code: x.companyId, title: x.name }))
			setOperators(clist);
		}
	}, [companies]);

	useEffect(() => {
		nowuserfilter.filter && setFilter(nowuserfilter.filter);
	}, [nowuserfilter]);

	useEffect(() => {
		var oList = []
		codeList.map(x => {
			oList.push({ code: x.code, title: x.name });
		});
		setOptions(oList);
	}, [codeList]);

	const setCodes = async () => {
		var cList = [];
		var citems = clist?.filter(x => x.type === 'CLAIMTYPE'); // await RayCodeAction.GetAllByFilter({ type: "CLAIMTYPE" });
		citems.filter(x => cList.filter(y => y.code == x.group).length > 0 || cList.push({ code: x.group, title: x.group }));
		setClaims(cList);

		var filterList = [...defaultFilter];
		//var extradataList = await RayCodeAction.GetAllByFilter({ type : extratrakey });
		var extradataListtemp = clist?.filter(x => x.type === extratrakey); // await RayCodeAction.GetAllByFilter({ type: extratrakey });
		var extradataList = extradataListtemp.sort((a, b) => a.sortnumber - b.sortnumber);

		//extradataList.filter(x => filterList.push({ code: extratrakeyname + "." + x.code, name: x.name, type: x.datatype }));
		extradataList.filter(x => filterList.push({ code: x.code, name: x.name, type: x.datatype}))
		setCodeList(filterList);

		var optList = [];
		var oList = clist?.filter(x => x.type === 'PRODUCTOPTION');  //await RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" });
		oList.filter(x => optList.push({ code: x.name, title: x.name, type: 'OPTION' }));
		setPrdOptionList(optList);

		var wcOlist = [];
		var cOlist = clist?.filter(x => x.type === 'WARRANTY' && x.group === 'WARRANTYTYPE'); //await RayCodeAction.GetAllByFilter({ type: "WARRANTY", group: "WARRANTYTYPE" });
		cOlist.filter(x => wcOlist.push({ code: x.code, title: x.name, type: 'OPTION' }));
		setWarOptionList(wcOlist);

		var wpOlist = [];
		var pOlist = clist?.filter(x => x.type === 'WARRANTY' && x.group === 'WARRANTYPRODUCT'); // await RayCodeAction.GetAllByFilter({ type: "WARRANTY", group: "WARRANTYPRODUCT" });
		pOlist.filter(x => wpOlist.push({ code: x.code, title: x.name, type: 'OPTION' }));
		setWarProductList(wpOlist);
	}

	const onchange = (e) => {
		const { name, value } = e.target;
		var nc = codeList.filter(x => x.code == value)[0];
		console.log("onChange : ======", codeList);
		console.log("onchange value : ====", name + " : " + value);
		setValType(nc.type);
		setSelOptions({ code: name, title: value });
		setFixListItem(nc.type == "FIXLIST" ? nc.fixlist : []);
	}

	const getPeriodMsg = (m) => {
		switch (m) {
			case "d": return "Days";
			case "w": return "Weeks";
			case "M": return "Months";
		}
	}

	const addFilter = () => {
		if (valtype == "STRING" || valtype == "NUMBER" || valtype == "OPTION" || valtype == "ERRORCODES" || valtype == "PARTLIST" || valtype == "CLAIM" || valtype == "WARRANTYTYPE" || valtype == "WARRANTYPRODUCT") {
			setFilter([...filter, { key: seloptions.title, val: selval, valstr: selval, type: "STRING" }])
		} else if (valtype == "OPERATOR" || valtype == "COUNTRY") {
			var op = (valtype == "OPERATOR" ? operators : CountryCode).filter(x => x.code == selval)
			setFilter([...filter, { key: seloptions.title, val: selval, valstr: op[0].title, type: "STRING" }])
		} else if (valtype == "STATUS") {
			var op = RayStatuses.filter(x => x.code == selval)
			setFilter([...filter, { key: seloptions.title, val: selval, valstr: selval, type: "STRING" }])
		} else if (valtype == "USER") {
			var op = adminusers.filter(x => x.code == selval)
			setFilter([...filter, { key: seloptions.title, val: selval, valstr: selval, type: "STRING" }])
		} else if (valtype == "LIKESTRING") {
			setFilter([...filter, { key: seloptions.title, val: selval, valstr: selval, type: "LIKESTRING" }])
		} else if (valtype == "FIXLIST") {
			setFilter([...filter, { key: seloptions.title, val: selval, valstr: selval, type: "FIXLIST" }])
		} else if (valtype == "DATETIME" || valtype == "DTONLY") {
			if (dtOptionVal.opt == "fix") {
				setFilter([...filter, { key: seloptions.title, val: dtOptionVal, valstr: moment(dtOptionVal.s).format("YYYY-MM-DD") + " ~ " + moment(dtOptionVal.e).format("YYYY-MM-DD"), type: "DATETIME" }])
			} else {
				setFilter([...filter, { key: seloptions.title, val: dtOptionVal, valstr: "Last " + dtOptionVal.lastVal + " " + getPeriodMsg(dtOptionVal.lastValOpt), type: "DATETIME" }])
			}
		} else {
			setFilter([...filter, { key: seloptions.title, val: selvaltf, valstr: selvaltf ? "True" : "False", type: "BOOLEAN" }])
		}
		setValType("STRING");
		setSelOptions({ code: "", title: "" });
		setSelVal("");
	}

	const delFilter = (f) => {
		setFilter(filter.filter(x => x.key != f.key))
	}

	const btnSearch = () => {
		dispatch({ type: dispatchType, dsfilters: filter });
		//callbackDs(filter); 
		//callbackDSClose(false);
	}

	const btnShowFilter = () => {
		setShowModal(true);
	}

	const btnClear = () => {
		setFilter([]);
		dispatch({ type: dispatchType, dsfilters: [] });
		callbackDSClose(false);
		//callbackDs(filter); 
	}

	const getFilterOptionList = () => {
		return options.filter(x => !filter.map(m => m.key).includes(x.code))
	}

	const btnDelFilter = () => {
		UpdateUserAdminFilters({ filters: allfilter.filter(x => x.id != nowuserfilter.id) });
		callbackchangeuserfilter({});
		btnClear();
	}

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="mb-0">{t('deepsearch')} {nowuserfilter.title && " - " + nowuserfilter.title}</h3>
			</div>
			<div className="">
				<table className="table">
					<tbody>
						<tr>
							<td style={{ width: '50%', verticalAlign: "top" }}>
								<div>
									<div className="section">
										<div className="section-title">{t('addfilter')}</div>
										<ul className="listview simple-listview no-space border-0">
											<InputSelect2 options={{
												label: t('selectkey'),
												name: "seloptions",
												list: getFilterOptionList(),
												val: seloptions,
											}} onchange={onchange} editable={true} />
											{valtype == "OPERATOR" && <InputSelect2 options={{
												label: "Country",
												name: "seloperator",
												list: operators,
												val: seloperator,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "COUNTRY" && <InputSelect2 options={{
												label: "Country",
												name: "selcountry",
												list: CountryCode,
												val: selcountry,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "USER" && <InputSelect2 options={{
												label: "User",
												name: "seluser",
												list: adminusers,
												val: seluser,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "PARTLIST" && <InputSelect2 options={{
												label: "Part",
												name: "selpartlist",
												list: parts,
												val: selpartlist,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "FIXLIST" && <InputSelect2 options={{
												label: "Fixed List",
												name: "selFixListItem",
												list: fixListItem,
												val: selFixListItem,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "STATUS" && <InputSelect2 options={{
												label: "Country",
												name: "selstatus",
												list: RayStatuses,
												val: selstatus,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "CLAIM" && <InputSelect2 options={{
												label: "Claim Category",
												name: "selclaim",
												list: claims,
												val: selclaim,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "OPTION" && <InputSelect2 options={{
												label: "Option",
												name: "seloption",
												list: prdOptionList,
												val: selprdoption,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "WARRANTYTYPE" && <InputSelect2 options={{
												label: "Warranty Category",
												name: "selwarranty",
												list: warOptionList,
												val: selwarranty,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{valtype == "WARRANTYPRODUCT" && <InputSelect2 options={{
												label: "Warranty Product",
												name: "selwarrantyproduct",
												list: warProductList,
												val: selwarrantyproduct,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{(valtype == "STRING" || valtype == "NUMBER" || valtype == "LIKESTRING") && <InputBasicText options={{
												label: t('typevalue'),
												name: "selval",
												val: selval,
											}} onchange={(e) => setSelVal(e.target.value)} editable={true} />}
											{(valtype == "DATETIME" || valtype == "DTONLY") && <div className="item nopadd">
												<div className="in">
													<select className="form-control" style={{ width: 80 }}
														onChange={(e) => setDtOptionVal({ ...dtOptionVal, opt: e.target.value })}>
														<option value="last">Latest</option>
														<option value="fix">Fix</option>
													</select>
													<div>
														{dtOptionVal.opt == "last" && <>
															<select className="form-control float-end"
																onChange={(e) => setDtOptionVal({ ...dtOptionVal, lastValOpt: e.target.value })}
																style={{ width: 80 }}
																value={dtOptionVal.lastValOpt}>
																<option value="d">Days</option>
																<option value="w">Weeks</option>
																<option value="M">Months</option>
															</select>
															<input type="number" min="1" max="100"
																value={dtOptionVal.lastVal}
																onChange={(e) => setDtOptionVal({ ...dtOptionVal, lastVal: e.target.value })}
																className="form-control float-end" style={{ width: 80 }}></input>
														</>}
														{dtOptionVal.opt == "fix" && <>
															<DatePicker
																className="form-control"
																dateFormat="yyyy-MM-dd"
																selected={moment(dtOptionVal.s).toDate()}
																onChange={(date) => {
																	if (moment(date).startOf('day').valueOf() != dtOptionVal.s)
																		setDtOptionVal({ ...dtOptionVal, s: moment(date).startOf('day').valueOf() })
																}} /> ~
															<DatePicker
																className="form-control"
																dateFormat="yyyy-MM-dd"
																selected={moment(dtOptionVal.e).toDate()}
																onChange={(date) => {
																	if (moment(date).startOf('day').valueOf() != dtOptionVal.e)
																		setDtOptionVal({ ...dtOptionVal, e: moment(date).startOf('day').valueOf() })
																}} />
														</>}
													</div>
												</div>
											</div>}
											{valtype == "BOOLEAN" && <div className="item nopadd">
												<div className="in">
													<div className="label"><strong>True/False</strong>{selvaltf}</div>
													<div className="form-check form-switch">
														<input className="form-check-input" type="checkbox"
															checked={selvaltf.toString() == "true"}
															id={"selvaltf"}
															name={"selvaltf"}
															value={selvaltf}
															onChange={(e) => { console.log(e.target.checked); setSelValTF(Boolean(e.target.checked)); }} />
														<label className="form-check-label" htmlFor={"selvaltf"}>selvaltf</label>
													</div>
												</div>
											</div>}
											{valtype == "STRING" && <>
												{(seloptions.code != "" && selval != "" && seloptions != "") && <button className="btn btn-block btn-primary" onClick={(e) => addFilter()}>{t('addfilter')}</button>}
												{!(seloptions.code != "" && selval != "" && seloptions != "") && <button className="btn btn-block btn-primary disabled" onClick={(e) => addFilter()}>{t('addfilter')}</button>}
											</>}
											{valtype != "STRING" && <button className="btn btn-block btn-primary" onClick={(e) => addFilter()}>{t('addfilter')}</button>}
										</ul>
									</div>
								</div>

							</td>
							<td style={{ width: 24 }}>
								<div>
									<ion-icon name="arrow-forward-outline" size="large"></ion-icon>
								</div>

							</td>
							<td style={{ width: '50%', verticalAlign: "top" }}>
								<div>
									<div className="section">
										<div className="section-title">{t('filters')}</div>
										{filter.length == 0 && <div>{t('nofilter')}</div>}
										{(codeList.length > 0 && filter.length > 0) && <ul className="listview simple-listview no-space mb-2 border-top">
											{filter.map((x, idx) => {
												return <li key={idx} className="border-bottom p-0">
													<span>{codeList.filter(y => y.code == x.key)[0].name} = {x.valstr}</span>
													<button className="btn btn-sm btn-danger mt-0" onClick={() => delFilter(x)}>{t('delete')}</button>
												</li>
											})}
										</ul>}
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="card-footer">
				<div className="row">
					<div className="col ps-0">
						<div className="btn-group">
							<button
								className="btn btn-primary mb-0 mt-0"
								onClick={() => btnSearch()}>{t('search')}</button>
							<button
								className={filter.length > 0 ? "btn btn-info mb-0 mt-0" : "btn btn-info disabled mb-0 mt-0"}
								onClick={() => btnShowFilter()}>{t('savefilter')}</button>
							{nowuserfilter && <RayDelButton callback={btnDelFilter} startMsg={t('deletefilter')}
								css="btn btn-danger mb-0 mt-0"
								confirmMsg="Are you sure?" />}
							<button
								className="btn btn-success mb-0 mt-0"
								onClick={() => btnClear()}>{t('clear')}</button>
							<button className="btn btn-dark mt-0" onClick={() => callbackDSClose(false)}>{t('close')}</button>
						</div>
					</div>
				</div>
			</div>
			<DeepSearchFilterSave showModal={showModal}
				type={savefiltertype}
				filter={filter}
				filterset={nowuserfilter}
				callbackClose={() => setShowModal(false)}
				callbackSave={(data) => callbackchangeuserfilter(data)} />
		</div>
	);
}

const mapState = (state) => {
	var user = state.AuthReducer.user;
	//var dsfilters = state.ProductReducer.dsfilters;
	var companies = state.CompanyReducer.companies;
	var users = state.AdminUserReducer.users;
	var partitems = state.ProductPartReducer.items;
	const clist = state.RayCodeReducer.items;
	return { user, users, companies, partitems, clist };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminFilters: (uinfo) => dispatch(AuthAction.UpdateUserAdminFilters(uinfo)),
	GetUserAll: () => dispatch(AdminUserAction.GetUserAll()),
	GetPartAll: () => dispatch(ProductPartAction.GetAll()),
	//GetCompany: () => dispatch(CompanyAction.GetCompany())
});

export default connect(mapState, mapDispatch)(DeepSearch);