import { RayDataConstant } from '../actions/constants/RayDataConstant';

const initialState = {
	items : [],
	item : {},
    isLoading : false,
	totalproductcount: 0,
};

export const RayDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case RayDataConstant.GETALL:
			return { ...state, items : [...action.items] }
		case RayDataConstant.UPDATE:
		case RayDataConstant.GET:
			return {
				...state,
				items: state.items.map((u) => (u._id == action.item._id ? action.item : u)),
				item: action.item,
			};
		case RayDataConstant.CREATE:
			return {
				...state,
				items : [...state.items, action.item],
				item: action.item,
			};
		case RayDataConstant.DELETE:
			return { ...state, items: state.items.filter((u) => u._id !== action._id), item : {}};
		case RayDataConstant.ADDUPDATE:
			return { ...state, item: action.item };
        case RayDataConstant.LOADING:
            return {...state, isLoading : true}
        case RayDataConstant.LOADED:
            return {...state, isLoading : false}
		default:
			return state;
	}
};
