import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { InfinteScroll } from '../../components/common/InfinteScroll';
import { Loading } from '../common/Loading';
import { InstallationAction } from '../../actions/InstallationAction';
import InstallationCard from './InstallationCard';
import { Modal } from 'react-bootstrap';
import { InstallationConstant } from '../../actions/constants/InstallationConstant';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const InstallationList = ({ GetInstallationList, items, totalCount, isLoading, Clear, showAS = false, callbackAS, fixList = null, fix = false }) => {
	// const [ t, i18n ] = useTranslation();
	const [target, setTarget] = useState(null);
	const [page, setPage] = useState(1);
	const [incomplete, setIncomplete] = useState("in");
	const [itemperpage, setItemPage] = useState(30);
	const [search, setSearch] = useState("");
	const [data, setData] = useState([]);
	const [newSearch, setNewSearch] = useState(false);

	useEffect(() => {
		if(fixList){
			setData(fixList);
		}
	}, [fixList]);

	useEffect(() => {
		if(!fix)
			setData(items);
	}, [items]);

	useEffect(() => {
		Reload();
	}, [page]);

	const Reload = () => {
		if(!fix){
			GetInstallationList({
				itemperpage: itemperpage,
				searchCols: ["site.sitename", "sn"],
				searchKey: search,
				page: page,
				match: { isSubmit: incomplete != "in" },
				sort: { createDate: -1 }
			}, true);
		}
	}

	const isEnd = () => { return totalCount <= page * itemperpage; }

	InfinteScroll({
		target,
		onIntersect: ([{ isIntersecting }]) => {
			if (isIntersecting && !isLoading && !isEnd()) {
				setPage(page + 1);
			}
		}
	});

	const submit = () => {
		setPage(1);
		Reload();
		callbackAS();
		Clear();
	}

	const getCss = (i) => i == incomplete ? "btn btn-primary" : "btn btn-outline-primary";
	console.log(fixList);
	console.log(data);

	return (
		<>
			{data.map(item => <div key={item._id}> <InstallationCard item={item} /> </div>)}
			{isLoading && <Loading />}

			<Modal show={showAS} onHide={() => callbackAS()} className="action-sheet">
				<Modal.Header>
					<h5 className="modal-title">{t('option')}</h5>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group m-2">
						<div className="input-wrapper me-4 mb-2">
							<label className="label">Filter</label>
							<div className="btn-group btn-block">
								<button className={getCss("in")} onClick={() => setIncomplete("in")}>{t('incomplete')}</button>
								<button className={getCss("com")} onClick={() => setIncomplete("com")}>{t('complete')}</button>
							</div>
						</div>
						<div className="input-wrapper me-4">
							<label className="label">{t('search')}</label>
							<input type="text" value={search} className="form-control" onChange={(e) => setSearch(e.target.value)} />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-block btn-primary" data-bs-dismiss="modal" onClick={() => submit()}>{t('submit')}</button>
				</Modal.Footer>
			</Modal>

			<div ref={setTarget} className='last-item' />
		</>
	);
};

const mapState = (state) => {
	var items = state.InstallationReducer.installations;
	var totalCount = state.InstallationReducer.totalInstallationCount;
	var isLoading = state.InstallationReducer.isLoading;

	return { items, totalCount, isLoading };
};

const mapDispatch = (dispatch) => ({
	GetInstallationList: (filter, append) => dispatch(InstallationAction.GetInstallationList(filter, append)),
	Clear: () => dispatch({ type: InstallationConstant.CLEAR }),
});

export default connect(mapState, mapDispatch)(InstallationList);