import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginFooter } from '../../components/common/CommonViews';
import { history } from '../../history';
import axios from 'axios';
import { AuthAction } from '../../actions';

const IubendaLogin = ({ user, login, UpdateUser, isUpdateRes, loggingIn }) => {
	const [inputs, setInputs] = useState({ username: '', password: '' });
	const [submitted, setSubmitted] = useState(false);
	const { username, password } = inputs;	
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => { 
		console.log(user);
		console.log(loggingIn);
	}, []);

	useEffect(() => {
		if (loggingIn) {
			if (loggingIn && !user.isIubenda) {
				iubendaUpdate(user);
			}
		}
	}, [loggingIn]);

	const iubendaUpdate = (user) => {
		var datas = {
			subject: {
				email: user.email,
				first_name: user.firstName,
				last_name: user.lastName,
				verified: true
			},
			preferences: { "privacy_policy": true },
			legal_notices: [
				{ "identifier": "privacy_policy" },
				{ "identifier": "cookie_policy" },
				{ "identifire": "terms", "version": "v1" }
			],
			proofs: [{ "content": "proof_content", "form": "proof_form" }]
		};

		try {
			axios.post('https://consent.iubenda.com/consent/', datas, {
				headers: {
					'Content-Type': 'application/json',
					'ApiKey': 'ztt0UUW2CTUlnc5OdhdQzynyNfqzKNBf',
					'Accept': 'application/json',
				}
			})
				.then(function (response) {
					console.log("===== iubenda subject id start ======");
					console.log(response);
					console.log(response.data.subject_id);
					console.log('===== iubenda subject id end ======');
					UpdateUser({ isIubenda: true, isIubendaId: response.data.subject_id });
				})
				.catch(function (error) {

				});
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (isUpdateRes) {
			if (user.rguardSettings) i18n.changeLanguage(user.rguardSettings.language);

			if (localStorage.getItem('mailLink')) {
				const mailLink = localStorage.getItem('mailLink');
				localStorage.removeItem('mailLink');
				history.push(mailLink);
			} else {
				history.push('/Home');
			}
		}
	}, [isUpdateRes]);

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	}

	function handleSubmit(e) {		
		e.preventDefault();
		if (isChecked) {
			setSubmitted(true);
			if (username && password) {
				login(username, password);
			}
		} else {
			alert("Privacy Policy Check.");
		}
	}

	function handleChangeData(e) {		
		const { name, checked } = e.target;
		if (checked) {
			setIsChecked(true);
		} else {
			setIsChecked(false);
		}
	}

	return (
		<>
			<div className="login">
				<div className="appHeader no-border transparent position-absolute">
					<div className="left"> </div>
					<div className="pageTitle"></div>
				</div>
				<div id="appCapsule" className="no-bottom">
					<div className="section mt-5 text-center">
						<h1>RAYGuard <span>Tech</span></h1>						
					</div>
					<div className="section mb-5 p-2">
						<form>
							<div className="card">
								<div className="card-body pb-1">
									<div className="form-group basic">
										<div className="input-wrapper">
											<label className="label">ID</label>
											<input
												type="text"
												className="form-control input-md"
												name="username"
												value={username}
												onChange={handleChange} />
											<i className="clear-input">
												<ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
											</i>
										</div>
									</div>
									<div className="form-group basic">
										<div className="input-wrapper">
											<label className="label">Password</label>
											<input
												type="password"
												className="form-control input-md"
												name="password"
												value={password}
												onChange={handleChange} />
											<i className="clear-input">
												<ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
											</i>
										</div>
									</div>
									<div >
										<input type="checkbox"
											checked={isChecked}
											onChange={handleChangeData} />&nbsp;
										<label className="form-check-label">
											<a href="https://www.iubenda.com/privacy-policy/85310967/legal" className="iubenda-nostyle iubenda-embed" title="Privacy Policy" target='_blank' > Privacy Policy </a><script type="text/javascript" src="https://cdn.iubenda.com/iubenda.js"></script>
											and agree to it including the transfer and use of my personal data.
										</label>
									</div>


									<div className="mt-2 transparent">
										<button
											type="submit"
											className="btn btn-primary btn-block waves-effect waves-classic"
											onClick={handleSubmit} >
											Sign in
										</button>
									</div>
								</div>
							</div>
						</form>
						<div className="text-right mt-2 mb-5">
							<Link to="/ForgotPassword" className="text-secondary">Forgot Your Password?</Link>
						</div>
					</div>
					<LoginFooter />
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	return {
		loggingIn: state.AuthReducer.loggingIn,
		user: state.AuthReducer.user,
		isUpdateRes: state.AuthReducer.isUpdateRes
	};
};

const mapDispatch = (dispatch) => ({
	UpdateUser: userInfo => dispatch(AuthAction.UpdateUser(userInfo)),
	login: (username, password) => dispatch(AuthAction.Login(username, password)),
	logout: () => dispatch(AuthAction.Logout()),
});

export default connect(mapState, mapDispatch)(IubendaLogin);