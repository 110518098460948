import { TranslateConstant } from '../actions/constants/TranslateConstant';

const initialState = {
    translate: [],
    isLoading: false,
};


export const TranslateReducer = (state = initialState, action) => {
    switch (action.type) {
        case TranslateConstant.REGIST_TRANSLATE_DATA:
        case TranslateConstant.MODIFY_TRANSLATE_DATA:
        case TranslateConstant.GET_TRANSLATE_DATA:
            return {
                ...state,
                translate: action.translate
            };
        default:
            return state;
    }
}