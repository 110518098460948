import { AdminSparePartsStockHeader } from '../../components/common/TableHeader';
import { Link } from 'react-router-dom';
import { RayTable } from '../../components/common';

const AdminSparePartsStock = () => {

    const options = {
        search: false,
        keyCol: "code",        
        defaultSort: "section",
        defaultSortType: -1
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Spare Parts Stock</h2>
                <div className="ray-right">                    
                    <button className="btn btn-primary btn-sm">Spare Parts Order</button>
                </div>
            </div>

            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1">
                    <div className="wide-block pt-2 pb-2">
                        <RayTable
                            data={[]}
                            columns={AdminSparePartsStockHeader}
                            options={options}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminSparePartsStock;