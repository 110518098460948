import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../components/common/Loading';
import { ProductAction } from '../../actions/ProductAction';
import ProductCard from './ProductCard';

const ProductFavList = ({ GetFavProductAll, products, sn }) => {

	useEffect(() => {
		GetFavProductAll();
	}, [sn])

	return (
		<>
			<ul className="listview link-listview">
				{products.map((item, index) =>
					<li key={index}>
						<ProductCard item={item} />
					</li>
				)}
			</ul>
		</>
	);
};

const mapState = (state) => {
	var products = state.FavoriteReducer.products;
	var sn = state.FavoriteReducer.sn;
	return { products, sn };
};

const mapDispatch = (dispatch) => ({
	GetFavProductAll: (filter) => dispatch(ProductAction.GetFavProductAll(filter)),
	//GetFavProductAll: (filter) => dispatch(ProductAction.GetProductAll(filter)),
});

export default connect(mapState, mapDispatch)(ProductFavList);