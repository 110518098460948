import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { ProductPartConstant } from './constants/ProductPartConstant';
import { AlertAction } from './AlertAction';

const GetAll = () => async (dispatch) => {
	dispatch({ type: ProductPartConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/part', JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductPartConstant.GET_ALL, items : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductPartConstant.LOADED });
};

const GetAllDirect = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/part', JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const GetAllByFilter = async (filter) => {
	const data = {
		type: 'getfilter',
		data: {...filter, isDel : false},
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/part', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const GetOne = (_id) => async (dispatch) => {
	dispatch({ type: ProductPartConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/part/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProductPartConstant.GET_ONE, item : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ProductPartConstant.LOADED });
};

const Delete = (_id) => async (dispatch) => {
	dispatch({ type: ProductPartConstant.LOADING });
	const data = {
		type: 'delete',
		who: JSON.parse(localStorage.getItem('user')).adminid,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/part/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: ProductPartConstant.DELETE, _id : _id });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductPartConstant.LOADED });
};

const Create = (info) => async (dispatch) => {
	dispatch({ type: ProductPartConstant.LOADING });
	const data = {
		type: 'create',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/part', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: ProductPartConstant.CREATE, item : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductPartConstant.LOADED });
};

const AddUpdate = (info) => async (dispatch) => {
	dispatch({ type: ProductPartConstant.ADDUPDATE, item : info });
};

const Update = (_id, info, type = "update") => async (dispatch) => {
	dispatch({ type: ProductPartConstant.LOADING });
	const data = {
		type: type,
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/part/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: ProductPartConstant.UPDATE, item : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ProductPartConstant.LOADED });
};

export const ProductPartAction = {
	GetAll,
	GetAllDirect,
	GetOne,
	GetAllByFilter,
	Create,
	Update,
	Delete,
	AddUpdate
};
