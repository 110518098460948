import React, { useEffect, useState } from 'react';
import { AppConfig, JWT } from '../../config';
import axios from 'axios';

const GuideItem = ({ text, css, path, filename }) => {

	const [url, setUrl] = useState("");

	useEffect(() => {
		if (filename && path)
			getUrl();
	}, [])

	const getUrl = async () => {
		const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
			path: path,
			name: filename,
		}, JWT());

		if (ret.data.status == "success")
			setUrl(ret.data.data);
	}

	const getNumber = () => {
		if (text.indexOf(". ") > -1) {
			const arr = text.split(". ");
			return arr[0];
		}
		return <ion-icon name="book-outline" className="md hydrated" />
	}

	const getText = () => {
		if (text.indexOf(". ") > -1) {
			const arr = text.split(". ");
			return text.replace(arr[0] + ". ", "");
		}
		return text
	}

	return (
		<>
			{url && <a href={url} target="_blank" className="item">
				<div className="icon-box bg-primary number">{getNumber()}</div>
				<div className="in">{getText()}</div>
			</a>}
			{!url && "..."}
		</>
	)
}

const GuideInstallation = ({ user, result, search }) => {
	return (
		<>
			{result.guideList.length > 0 &&
				<div className="">
					<div className="section-title">{search.modelName}</div>
					{result.guideList &&
						<ul className="listview image-listview inset m-0">
							{result.guideList.map((x) =>
								<li>
									<GuideItem text={x.title} path={x.filePath} filename={x.fileName} />
								</li>
							)}
						</ul>
					}
				</div>
			}
			{result.cephList.length > 0 &&
				<div className="">
					<div className="section-title">Cephalometric</div>
					<ul className="listview image-listview inset m-0">
						{result.cephList.map((x) =>
							<li>
								<GuideItem text={x.title} path={x.filePath} filename={x.fileName} />
							</li>
						)}
					</ul>
				</div>
			}
			{result.guideList.length == 0 && <span className="text-muted">No result</span>}
		</>
	)
}

export default GuideInstallation;
export {
	GuideItem
}