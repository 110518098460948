import React, { useEffect, useState } from 'react';
import { AdminSparePartsListHeader } from '../../components/common/TableHeader';
import { RayTable } from '../../components/common';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { InputBasicNumber, InputBasicText, InputBasicSelect, InputSelect2 } from '../../components/common/Inputs';
import { history } from '../../history';
import { FileUpload } from '../../components/common/FileUpload';
import { CompanyAction } from '../../actions';
import { DeviceModels } from '../../config';

const AdminSpareParts = ({ list, groupList, companyList, GetList, Regist, GetGroupList, RegistGroup, UpdateGroup }) => {
    const [showModal, setShowModal] = useState(false);
    const [showOrderGroupModal, setShowOrderGroupModal] = useState(false);
    const [partsList, setPartsList] = useState([]);

    useEffect(() => {
        GetList();
        GetGroupList();        
    }, []);

    useEffect(() => {
        if (list) {
            setPartsList(list.map(x => {
                let inCount = 0;
                x.in.filter(y => y.isCanceled === false).forEach(z => inCount += z.sn.length + z.noSnQty);

                let outCount = x.outCount;
                const remainCount = inCount - outCount;
                return { ...x, in: inCount, out: outCount, remainCount };
            }));

            if (showModal)
                setShowModal(false);
        }
    }, [list]);

    const options = {
        search: true,
        keyCol: "code",        
        filterColumns: [
            { col: 'model', val: 'ALL' },
            { col: 'section', val: 'ALL' },
            { col: 'type', val: 'ALL' },
        ],
        defaultSort: { section: 1 },
        defaultSortType: 1,
        hideListCount: true
    }

    const rowSelect = (item) => {
        history.push('/Admin/AdminSparePartsDetail/' + item._id);
    }
  
    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Spare Parts Management</h2>
                <div className="ray-right">
                    <button className="btn btn-primary btn-sm" onClick={() => setShowModal(true)}>
                        <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon> 스페어 파트 추가
                    </button>

                    <button className="btn btn-primary btn-sm mx-1" onClick={() => setShowOrderGroupModal(true)}>
                        <ion-icon name="business-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon> 주문그룹 관리
                    </button>

                    <button className="btn btn-primary btn-sm mx-1" onClick={() => history.push('/Admin/AdminSparePartsOrderListManager')}>
                        <ion-icon name="cart-outline" role="img" className="md hydrated" aria-label="cart outline"></ion-icon> 주문목록
                    </button>

                    <button className="btn btn-primary btn-sm mx-1" onClick={() => history.push('/Admin/AdminSparePartsOutList')}>
                        <ion-icon name="exit-outline" role="img" className="md hydrated" aria-label="exit outline"></ion-icon> 출고이력
                    </button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1">
                    <div className="wide-block pt-2 pb-2">
                        <RayTable
                            data={partsList}
                            columns={AdminSparePartsListHeader}
                            options={options}
                            rowSelectCallback={rowSelect}
                        />
                    </div>
                </div>
            </div>
            <AddSparePartsModal partsList={partsList} showModal={showModal} closeModal={() => setShowModal(false)} regist={Regist} />
            <OrderGroupModal
                groupList={groupList}
                companyList={companyList}
                showOrderGroupModal={showOrderGroupModal}
                closeModal={() => setShowOrderGroupModal(false)}
                RegistGroup={(data) => RegistGroup(data)}
                UpdateGroup={(data) => UpdateGroup(data)}
            />
        </>
    )
}

const AddSparePartsModal = ({ partsList, showModal, closeModal, regist }) => {
    const [models, setModels] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        if (models.filter(x => x.code == 'Other').length == 0)
            setModels([...DeviceModels, { code: 'Other', title: 'Other' }]);
     }, [DeviceModels]);

    const handleChangeData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    return (
        <Modal show={showModal}>
            <Modal.Header>
                <h2 className='modal-title'>스페어 파트 추가</h2>
            </Modal.Header>
            <Modal.Body className='p-1'>
                <div className="rayform section">
                    <div className="listview simple-listview no-space border-0">
                        <div className="form-group mt-1" >
                            <div className="input-wrapper">
                                <InputSelect2
                                    options={{
                                        type: "select2",
                                        name: "model",
                                        label: "모델",
                                        list: models,
                                        required: true,
                                        isMulti:true,
                                        val: data.model || [],
                                    }}
                                    editable={true}
                                    onchange={handleChangeData} />
                            </div>                        
                        </div>
                        <div className="form-group mt-1" >
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                <div className="input-wrapper">
                                    <InputSelect2
                                        options={{
                                            type: "select2",
                                            name: "section",
                                            label: "Section",
                                            required: true,
                                            list: Array.from(new Set(partsList.map(x => x.section))).map(x => { return { code: x, title: x } }),
                                            val: data.section || ''
                                        }}
                                        editable={true}
                                        onchange={handleChangeData} />
                                </div>
                            </div>
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                <div className="input-wrapper">
                                    <InputSelect2
                                        options={{
                                            type: "select2",
                                            name: "type",
                                            label: "Type",
                                            required: true,
                                            list: Array.from(new Set(partsList.map(x => x.type))).map(x => { return { code: x, title: x } }),
                                            val: data.type || ''
                                        }}
                                        editable={true}
                                        onchange={handleChangeData} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-1" >
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                <div className="input-wrapper">
                                    <label className="label">Service Code</label>
                                    <InputBasicText options={{ name: 'serviceCode' }}
                                        editable={true}
                                        onchange={handleChangeData}
                                    />
                                </div>
                            </div>
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                <div className="input-wrapper">
                                    <label className="label">Service Part Name</label>
                                    <InputBasicText options={{ name: 'servicePartName' }}
                                        editable={true}
                                        onchange={handleChangeData}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-1" >
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                <div className="input-wrapper">
                                    <label className="label">BOM Code</label>
                                    <InputBasicText options={{ name: 'bomCode' }}
                                        editable={true}
                                        onchange={handleChangeData}
                                    />
                                </div>
                            </div>
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                <div className="input-wrapper">
                                    <label className="label">BOM Name</label>
                                    <InputBasicText options={{ name: 'bomName' }}
                                        editable={true}
                                        onchange={handleChangeData}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-1" >
                            <div className="input-wrapper">
                                <label className="label">Rev</label>
                                <InputBasicText options={{ name: 'rev' }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-1" >
                            <div className="input-wrapper">
                                <label className="label">HS Code</label>
                                <InputBasicText options={{ name: 'hsCode' }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-1" >
                            <div className="input-wrapper">
                                <label className="label">Default Count</label>
                                <InputBasicNumber options={{ name: 'defaultCount', positive: true }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-1" >
                            <div className="input-wrapper">
                                <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                    <label className="label">Price (USD($))</label>
                                    <InputBasicText
                                        options={{ name: 'price.usd' }}
                                        editable={true}
                                        onchange={(e) => setData({ ...data, price: { ...data.price, usd: e.target.value } })} />
                                </div>
                                <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                    <label className="label">Price (EUR(€))</label>
                                    <InputBasicText
                                        options={{ name: 'price.eur' }}
                                        editable={true}
                                        onchange={(e) => setData({ ...data, price: { ...data.price, eur: e.target.value } })} />
                                </div>
                            </div>
                        </div>
                        <div className='form-group mt-1'>
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block' }}>
                                <label className="label">Price (JPY(￥))</label>
                                <InputBasicText
                                    options={{ name: 'price.jpy' }}
                                    editable={true}
                                    onchange={(e) => setData({ ...data, price: { ...data.price, jpy: e.target.value } })} />
                            </div>
                            <div style={{ width: 'calc(50% - 5px)', display: 'inline-block', marginLeft: '10px' }}>
                                <label className="label">Price (CNY(￥))</label>
                                <InputBasicText
                                    options={{ name: 'price.cny' }}
                                    editable={true}
                                    onchange={(e) => setData({ ...data, price: { ...data.price, cny: e.target.value } })} />
                            </div>
                        </div>
                        <div className="form-group mt-1" >
                            <div className="input-wrapper">
                                <label className="label">Picture</label>
                                <div className='mt-1'>
                                    {
                                        !data.picture?.origin &&
                                        <FileUpload
                                            option={{ doneandnew: true }}
                                            extraData={{ path: "spareparts/" }}
                                            uploadLater={false}
                                            callback={(response) => {
                                                setData({ ...data, picture: { origin: response.original, path: response.s3path } });
                                            }}
                                        />
                                    }

                                    {
                                        data.picture?.origin &&
                                        <div>
                                            {data.picture.origin}
                                            <a onClick={() => setData({ ...data, picture: {} })}>
                                                <ion-icon name="trash-outline" style={{ fontSize: '18px', verticalAlign: 'middle', marginLeft: '5px' }}></ion-icon>
                                            </a>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-block btn-success mb-0' onClick={() => regist(data)}>Add</button>
                <button className='btn btn-block btn-dark' onClick={() => closeModal()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

const OrderGroupModal = ({  groupList, companyList, showOrderGroupModal, closeModal, RegistGroup, UpdateGroup }) => {
    const initial = { groupName: '', company: [], currencyUnit: 'USD', address: '', tel: '' };

    const [origin, setOrigin] = useState({});
    const [selected, setSelected] = useState(initial);
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        // if (!companyList)
        //     GetCompanyList();
    }, []);

    useEffect(() => {
        setEditable(false);
    }, [groupList])

    const selectData = (_id)=>{
        const selectedData = groupList.filter(x => x._id == _id)[0];

        setOrigin(selectedData);
        setSelected(selectedData);
    }

    const handleChangeData = (e) => {
        setSelected({ ...selected, [e.target.name]: e.target.value });
    }

    const registOrUpdate = () => {
        if (selected.hasOwnProperty('_id'))
            UpdateGroup(selected);
        else
            RegistGroup(selected);
    }

    const deleteGroup = () => {
        if (confirm('delete?')) {
            UpdateGroup({ _id: selected._id, isDel: true });
            setOrigin(initial);
            setSelected(initial);
        }
    }

    return (
        <Modal show={showOrderGroupModal} size='lg'>
            <Modal.Header>
                <h2 className='modal-title'>주문그룹 관리</h2>
                <a onClick={() => {
                    setOrigin(initial);
                    setSelected(initial);
                    setEditable(false);
                    closeModal();
                }}>
                    <ion-icon name="close-outline" style={{ fontSize: '30px' }}></ion-icon>
                </a>
            </Modal.Header>
            <Modal.Body className='p-2'>
                <div className='list-inline-item align-top' style={{ width: '200px' }}>
                    <ul className='sp-company-list'>
                        {
                            groupList.map((x, idx) => {
                                return (
                                    <li key={`order_group_${idx}`} className={selected?._id === x._id && 'sp-group-selected'}>
                                        <a onClick={() => selectData(x._id)}> {x.groupName}</a>
                                    </li>
                                )
                            })
                        }                      
                    </ul>
                </div>

                <div className='list-inline-item px-1' style={{ width: 'calc(100% - 208px)' }}>
                    <div className='text-right pb-1 px-1'>
                        {
                            !editable &&
                            (
                                <>
                                    <button className='btn btn-primary btn-sm mx-1' onClick={() => { setSelected(initial); setEditable(true) }}>신규등록</button>
                                    {selected.groupName &&
                                        <>
                                            <button className='btn btn-primary btn-sm mx-1' onClick={() => setEditable(true)}>수정</button>
                                        <button className='btn btn-danger btn-sm' onClick={() => deleteGroup()}>삭제</button>
                                        </>
                                    }
                                </>
                            )                            
                        }
                        {
                            editable &&
                            (
                                <>
                                    <button className='btn btn-primary btn-sm mx-1' onClick={() => registOrUpdate()}>확인</button>
                                    <button className='btn btn-secondary btn-sm mx-1' onClick={() => { setSelected(origin); setEditable(false); }}>취소</button>
                                </>
                            )
                        }
                    </div>

                    <div className='p-2'>
                        <InputBasicText options={{ name: 'groupName', label: '그룹명', val: selected.groupName }} editable={editable} onchange={handleChangeData} />
                        <InputSelect2
                            options={{
                                name: 'company',
                                label: '업체',
                                val: selected.company || [],
                                list: companyList.map(x => { return { code: x.companyId, title: x.name } }),
                                isMulti: true
                            }}
                            editable={editable} 
                            onchange={handleChangeData} />
                        <InputBasicSelect options={{
                            name: 'currencyUnit', label: '화폐단위', val: selected.currencyUnit, list: [
                                { code: 'USD', title: 'USD ($)' },
                                { code: 'EUR', title: 'EUR (€)' },
                                { code: 'JPY', title: 'JPY (￥)' },
                                { code: 'CNY', title: 'CNY (￥)' }
                            ]
                        }} editable={editable} onchange={handleChangeData} />
                        <InputBasicText options={{ name: 'address', label: '배송주소', val: selected.address }} editable={editable} onchange={handleChangeData} />
                        <InputBasicText options={{ name: 'tel', label: '전화번호', val: selected.tel }} editable={editable} onchange={handleChangeData} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}

const mapState = (state) => {
    const list = state.SparePartsReducer.spareParts;
    const groupList = state.SparePartsReducer.groupList;
    const companyList = state.CompanyReducer.companies;
    return { list, groupList, companyList };
};

const mapDispatch = (dispatch) => ({
    GetList: () => dispatch(SparePartsAction.GetList({})),
    Regist: (data) => dispatch(SparePartsAction.Regist(data)),
    GetGroupList: () => dispatch(SparePartsAction.GetGroupList()),
    RegistGroup: (data) => dispatch(SparePartsAction.RegistGroup(data)),
    UpdateGroup: (data) => dispatch(SparePartsAction.UpdateGroup(data)),
    //GetCompanyList: () => dispatch(CompanyAction.GetCompany()),
});

export default connect(mapState, mapDispatch)(AdminSpareParts);

