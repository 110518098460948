import React from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../history';
import { ErrorCodeCreate } from '../../components/errorCode';
import { Translate as t } from '../../config/Translate';

const AdminErrorCodeAdd = () => {

    const callbackDone = (errorCode) => {
        history.push("/Admin/AdminErrorCodeInfo/" + errorCode.errorCode + "/" + errorCode.lang);
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">{t('adderrorcode')}</h2>
                <div className="ray-right">
                    <Link to="/Admin/AdminErrorCodeList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <ErrorCodeCreate callbackDone={callbackDone} />
            </div>
        </>
    );
};

export default AdminErrorCodeAdd;
