import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AggrLatestInstalledSite, AggrErrorCountsSite, AggrProductServiceSite, AggrLatestAcqProductSite2, } from '../../config/Aggregates';
import { Translate as t } from '../../config/Translate';
import { Modal } from 'react-bootstrap';

import WidgetCustomTable from '../../widgets/WidgetCustomTable';

import { InputBasicNumber } from '../../components/common/Inputs';
import { AuthAction } from '../../actions';

const AdminSiteDashboard = ({ user, UpdateUserAdminSetting }) => {

	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setData({
			dashboardSettingSiteLastInstalledProducts: user.dashboardSettingSiteLastInstalledProducts || 1,
			dashboardSettingSiteOccurredErrors: user.dashboardSettingSiteOccurredErrors || 1,
			dashboardSettingSiteAcquisitions: user.dashboardSettingSiteAcquisitions || 1,
			dashboardSettingSiteServices: user.dashboardSettingSiteServices || 1,
		})
	}, [user]);

	const openSettingPopup = (key, title) => {
		setSelected({ key, title, value: data[key] });
		setShowModal(true);
	}

	const updateDashboardSetting = () => {
		UpdateUserAdminSetting({ [selected.key]: selected.value });
		setShowModal(false);
	}

	const handleChange = (e) => {
		setSelected({ ...selected, value: e.target.value });
	}

	const initializeUpdateData = () => {
		setShowModal(false);
	}


	const installedPrdheaders = [
		{ name: "country", label: "", type: "COUNTRY", showtype: "ONLYFLAG", style: { width: 30 } },
		{ name: 'sitename', label: t('site'), link: '/Admin/AdminSiteInfo', linkdata: 'siteId' },
		{ name: "sn", label: t('sn') },
		{ name: "installedDate", label: t('installeddate'), type: "DTONLY" },
	];

	const errorPrdheaders = [
		{ name: "site.country", label: "", type: "COUNTRY", showtype: "ONLYFLAG", style: { width: 30 } },
		{ name: 'site.sitename', label: t('site'), link: '/Admin/AdminSiteInfo', linkdata: 'siteId' },
		{ name: "notclosed", label: "Open", align: "text-right", type: "NUMBER", sortoption: true, sort: true },
		{ name: "errs", label: "Closed", align: "text-right", type: "NUMBER", sortoption: true },
		{ name: "allerrs", label: t('all'), align: "text-right", type: "NUMBER", sortoption: true },
		{ name: "", label: "", type: "PROGRESS", values: ["notclosed", "errs"], value: "allerrs", style: { minWidth: 200 } },
	];

	const servicePrdheaders = [
		{ name: "site.country", label: "", type: "COUNTRY", showtype: "ONLYFLAG", style: { width: 25 } },
		{ name: 'site.sitename', label: t('site'), link: '/Admin/AdminSiteInfo', linkdata: 'siteId' },
		{ name: "open", label: "Open", align: "text-right", type: "NUMBER", sortoption: true, sort: true },
		{ name: "close", label: "Closed", align: "text-right", type: "NUMBER", sortoption: true },
		{ name: "all", label: t('all'), align: "text-right", type: "NUMBER", sortoption: true },
		{ name: "", label: "", type: "PROGRESS", values: ["open", "close"], value: "all", style: { minWidth: 200 } },
	];

	const acqPrdheaders = [
		{ name: "country", label: "", type: "COUNTRY", showtype: "ONLYFLAG", style: { width: 30 } },
		{ name: 'sitename', label: t('site'), link: '/Admin/AdminSiteInfo', linkdata: 'siteId' },
		{ name: "subtitle", label: t('model') },
		{ name: "count", label: "Count", align: "text-right", type: "NUMBER" },
		{ name: "", label: "", type: "PROGRESS", value: "count", style: { minWidth: 200 } },
	];

	return (
		<>
			<div className="extraHeader pb-0">
				<h2 className="mb-0">{t('sitedashboard')}</h2>
				<div className="ray-right">
					<Link to="/Admin/AdminSiteList" className="btn btn-sm mt-0">
						<ion-icon name="list-outline"></ion-icon> List
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<WidgetCustomTable
							title={t('lastinstalledproducts')}
							headers={installedPrdheaders}
							aggregate={AggrLatestInstalledSite(data.dashboardSettingSiteLastInstalledProducts)}
							height={280}
							setting={() => openSettingPopup('dashboardSettingSiteLastInstalledProducts', `${t('lastinstalledproducts')} (${t('week')})`)}
						/>
					</div>

					<div className='col'>
						<WidgetCustomTable
							title={`${t('occurrederrors')}`}
							headers={errorPrdheaders}
							aggregate={AggrErrorCountsSite(data.dashboardSettingSiteOccurredErrors)}
							height={280}
							setting={() => openSettingPopup('dashboardSettingSiteOccurredErrors', `${t('occurrederrors')} (${t('week')})`)}
						/>
					</div>
				</div>
				<div className="row p-1 pb-0">
					<div className="col">
						<WidgetCustomTable
							title={`${t('acquisition')}`}
							headers={acqPrdheaders}
							aggregate={AggrLatestAcqProductSite2(data.dashboardSettingSiteAcquisitions)}
							setting={() => openSettingPopup('dashboardSettingSiteAcquisitions', `${t('acquisition')} (${t('week')})`)}
							height={280}
						/>
					</div>
					<div className='col'>
						<WidgetCustomTable
							title={t('service')}
							headers={servicePrdheaders}
							aggregate={AggrProductServiceSite(data.dashboardSettingSiteServices)}
							setting={() => openSettingPopup('dashboardSettingSiteServices', `${t('service')} (${t('week')})`)}
							height={280}
						/>
					</div>
				</div>

				<Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)}>
					<Modal.Header>
						<Modal.Title>{t('settings')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='p-1'>
							<InputBasicNumber
								options={{
									label: selected.title,
									showpin: true,
									val: selected.value,
									positive: true,
								}}
								editable={true}
								onchange={handleChange}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => updateDashboardSetting()}>{t('save')}</button>
						<button className="btn btn-block btn-dark" onClick={() => initializeUpdateData()}>{t('close')}</button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
})

export default connect(mapState, mapDispatch)(AdminSiteDashboard);

