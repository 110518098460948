import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AlertAction, AuthAction } from '../../actions';
import RLib from "../../components/common/RLib"
import { Translate as t } from '../../config/Translate';

const AdminUserEditUpdatePassword = ({ adminid, user, GetUser, ResultSuccess, ResultError }) => {

	const [isLoading, setIsLoading] = useState(true);
	const [errMsg, setErrMsg] = useState("");
	const [errMsgforCheck, setErrMsgforCheck] = useState("");
	const [newpassword, setNewpassword] = useState({
		password: "",
		passwordConfirm: "",
	});

	useEffect(() => {
		setIsLoading(adminid != user.adminid)
	}, [user])

	const handleUpdatePassword = (e) => {
		if (!RLib.ValidatePasswordStrength(newpassword.password)) {
			setErrMsg("The length is 8 characters or more and has a combination of symbols, caps, text.");
			return false;
		} else {
			setErrMsg("");
		}
		if (newpassword.password != newpassword.passwordConfirm) {
			setErrMsgforCheck("Check Password Confirm");
			return false;
		} else {
			setErrMsgforCheck("");
		}
		
		AuthAction.UpdatePasswordByAdmin(user._id, { password: newpassword.password }).then((ret) => {
			setNewpassword({ password: "", passwordConfirm: "", });

			if (ret.status == "success")
				ResultSuccess("OK!");
			else
				ResultError("Fail!");
		});
	}

	const handleChangePassword = (e) => {
		const { name, value } = e.target;
		setNewpassword(newpassword => ({ ...newpassword, [name]: value }));
	}

	return (
		<div className="section">
			<div className="card">
				<div className="card-body">
					<div className="form-group basic">
						<label>New Password</label>
						<input type="password" className="form-control" autoComplete="off"
							name="password"
							onChange={(e) => handleChangePassword(e)}
							value={newpassword.password} />
					</div>
					{errMsg && <label className="error">{errMsg}</label>}
					<div className="form-group basic">
						<label>New Password Confirm</label>
						<input type="password" className="form-control" autoComplete="off"
							name="passwordConfirm"
							onChange={(e) => handleChangePassword(e)}
							value={newpassword.passwordConfirm} />
					</div>
					{errMsgforCheck && <label className="error">{errMsgforCheck}</label>}
					<button className="btn btn-primary btn-sm" onClick={handleUpdatePassword}>{t('update')}</button>
				</div>
			</div>
		</div>
	)
}

const mapState = (state) => {
	const user = state.AdminUserReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	ResultSuccess: (msg) => dispatch(AlertAction.Success(msg)),
	ResultError: (msg) => dispatch(AlertAction.Error(msg)),
});

export default connect(mapState, mapDispatch)(AdminUserEditUpdatePassword);