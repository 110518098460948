import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Loading } from '../common/Loading';
import { ProductAction, ErrorCodeAction } from '../../actions';
import { Modal } from 'react-bootstrap';
import { ProductConstant } from '../../actions/constants/ProductConstant';
import { RayDelButton } from '../common/Buttons';
import { InputBasicCheckbox } from '../common/Inputs';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const ProductErrorEdit = ({ item, user, show, UpdateProductError, CloseProductError }) => {
	//const [ t ] = useTranslation();
	const dispatch = useDispatch();
	const [updating, setUpdating] = useState(false);
	const [showAS, setShowAS] = useState(false);
	const [hasSolution, setHasSolution] = useState(false);
	const [solution, setSolution] = useState({});
	const [helpSolutions, setHelpSolutions] = useState([]);
	const [helpAttachments, setHelpAttachments] = useState([]);
	const [etcShow, setEtcShow] = useState(false);
	const [showCloseForm, setShowCloseForm] = useState(false);
	const [userSolution, setUserSolution] = useState("");
	const [data, setData] = useState({
		errId: item.errs._id,
		begin: item.begin,
		duration: item.duration,
		description: item.description,
		note: item.note
	});

	useEffect(() => {
		if (show) {
			setSolution({});
			setShowCloseForm(false);
			setEtcShow(false);
			setHelpSolutions([]);
			setHelpAttachments([]);
			setUserSolution("");
			setEtcShow(false);
		}
		setShowAS(show);
		setUpdating(!show);
	}, [show]);

	useEffect(() => {
		if (item.errs.code) {
			setData(item.errs);
			searchErrorCode(item.model, item.errs.code);
		}
	}, [item]);

	const update = () => {
		setUpdating(true);
		UpdateProductError(item.sn, {
			errId: item.errs._id,
			description: data.description,
			note: data.note
		});
	}

	const close = () => {
		setUpdating(true);
		var user = JSON.parse(localStorage.getItem('user'));
		//console.log({
		//	errorCodeId : solution._id,
		//	helpSolutions : helpSolutions,
		//	helpAttachments : helpAttachments,
		//	userSolution : (etcShow && userSolution) ? userSolution : ""
		//})
		CloseProductError(item.sn, {
			errId: item.errs._id,
			description: data.description,
			note: data.note,
			closer: user.adminid,
			end: new Date().getTime()
		}, {
			errorCodeId: solution._id,
			helpSolutions: helpSolutions,
			helpAttachments: helpAttachments,
			userSolution: (etcShow && userSolution) ? userSolution : ""
		});
	}

	const hide = () => {
		dispatch({ type: ProductConstant.SET_ERROREDIT_HIDE })
	}

	const searchErrorCode = async (model, eCode) => {
		const list = await ErrorCodeAction.GetOneOnlyErrorCode(model, eCode);
		if (list.length > 0) {
			var viewInfo = list.filter(x => x.lang == user.rguardSettingLanguage);
			if (viewInfo.length > 0) {
				setSolution(viewInfo[0]);
			} else {
				viewInfo = list.filter(x => x.lang == "eng");
				if (viewInfo.length > 0) {
					setSolution(viewInfo && viewInfo[0]);
				} else {
					setSolution(list[0]);
				}
			}
			setHasSolution(true);
			setShowCloseForm(false);
		} else {
			setHasSolution(false);
			setShowCloseForm(true);
			setSolution({
				solutions: [],
				attachments: [],
			});
		}
	}

	const handleChangeData = (type, _id, e) => {
		const { name, checked } = e.target;
		if (type == "solutions") {
			if (checked) {
				setHelpSolutions([...helpSolutions, _id]);
			} else {
				setHelpSolutions(removeArr([...helpSolutions], _id));
			}
		} else {
			if (checked) {
				setHelpAttachments([...helpAttachments, _id]);
			} else {
				setHelpAttachments(removeArr([...helpAttachments], _id));
			}
		}
	}

	const removeArr = (arr, id) => {
		const idx = arr.indexOf(id);
		if (idx > -1) {
			arr.splice(idx, 1);
		}
		return arr;
	}

	const getOnlyName = (s) => {
		if (s.indexOf(" - ") > -1) {
			var arr = s.split(" - ");
			return arr[1];
		} else {
			return s;
		}
	}

	const hasAttach = (list) => {
		if (list) {
			var i = 0;
			list.map(x => i += (x.title ? 1 : 0))
			return i > 0;
		}
		return false;
	}

	return (
		<>
			<Modal show={showAS} onHide={() => hide()} dialogClassName="modal-fullscreen" scrollable={showCloseForm}>
				<Modal.Header>
					<h2 className="modal-title">{item.errs.code} <small>{item.sn}</small></h2>
				</Modal.Header>
				<Modal.Body className="p-0 pt-2">
					{showCloseForm && <div><div className="input-wrapper">
						<h3 className="text-center">Which solution was helpful?</h3>
					</div></div>}
					{!showCloseForm &&
						<div className="form-group basic m-2 pe-4">
							<div className="input-wrapper mb-2">
								<label className="label">{t('occurred')}</label>
								<div className="form-content multi">{moment.utc(data.begin).local().format('YYYY-MM-DD HH:mm:ss')}</div>
							</div>
							<div className="input-wrapper mb-2">
								<label className="label">{t('description')}</label>
								<textarea className="form-control"
									onChange={(e) => setData({ ...data, description: e.target.value })}
									defaultValue={data.description}></textarea>
							</div>
							<div className="input-wrapper mb-2">
								<label className="label">{t('note')}</label>
								<textarea className="form-control"
									onChange={(e) => setData({ ...data, note: e.target.value })}
									defaultValue={data.note}></textarea>
							</div>
						</div>
					}
					{showCloseForm &&
						<div className="wide-block p-0">
							<div className="p-0 mb-2">
								<div className="input-list">
									{solution.solutions &&
										<>
											<div className="p-1 ps-2 mt-1 fw-bold">{t('solution')}</div>
											{solution.solutions.map((s, idx) => {
												return (
													<div className="form-check">
														<input type="checkbox" className="form-check-input"
															checked={helpSolutions.includes(s._id)}
															onChange={(e) => handleChangeData("solutions", s._id, e)}
															id={"optsol" + idx} />
														<label className="form-check-label" htmlFor={"optsol" + idx}>{getOnlyName(s.title)}</label>
													</div>
												)
											})}
										</>
									}
									{hasAttach(solution.attachments) &&
										<>
											<div className="p-1 ps-2 mt-1 fw-bold">{t('attachment')}</div>
											{solution.attachments.map((s, idx) => {
												if (s.title) {
													return (
														<div className="form-check">
															<input type="checkbox" className="form-check-input"
																checked={helpAttachments.includes(s._id)}
																onChange={(e) => handleChangeData("attachments", s._id, e)}
																id={"optattach" + idx} />
															<label className="form-check-label" htmlFor={"optattach" + idx}>{s.title}</label>
														</div>
													)
												}
											})}
										</>
									}
									<div className="p-1 ps-2 mt-1 fw-bold">{t('other')} {t('solution')}</div>
									<div className="form-check">
										<input type="checkbox" className="form-check-input"
											checked={etcShow}
											onChange={() => setEtcShow(!etcShow)}
											id={"opt_etc"}
											name={"opt_etc"} />
										<label className="form-check-label" htmlFor={"opt_etc"}>ETC</label>
									</div>
									{etcShow &&
										<div className="input-wrapper ms-2 me-2 mt-2">
											<label className="label">How did you solve this?</label>
											<input type="text" className="form-control"
												onChange={(e) => setUserSolution(e.target.value)}
												name="etc_solution" value={userSolution} />
										</div>
									}
								</div>
							</div>
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					{!showCloseForm &&
						<>
							<button
								disabled={updating}
								className="btn btn-block btn-primary mb-0"
								onClick={() => update()}>{updating ? "Updating..." : t('save')}</button>
							<button
								onClick={() => setShowCloseForm(true)}
								className="btn btn-block btn-success mb-0">{t('issueclose')}</button>
							<button className="btn btn-block btn-dark" onClick={() => hide()}>{t('close')}</button>
						</>
					}
					{showCloseForm &&
						<>
							<RayDelButton
								callback={(item) => close()}
								startMsg="Issue Close"
								confirmMsg="Are you sure?"
								css="btn btn-block btn-success mb-0" />
							<button className="btn btn-block btn-dark" onClick={() => { hasSolution ? setShowCloseForm(false) : hide() }}>{t('cancel')}</button>
						</>
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var item = state.ProductReducer.producterr;
	var show = state.ProductReducer.producterrasshow;
	return { item, show, user };
};

const mapDispatch = dispatch => ({
	CloseProductError: (sn, data, extra) => dispatch(ProductAction.CloseProductError(sn, data, extra)),
})

export default connect(mapState, mapDispatch)(ProductErrorEdit);