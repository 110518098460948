import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProductAction, AlertAction, AuthAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { history } from '../../history';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const ProductCreate = ({ product, apierrors, RegistProductInfo }) => {
    // const [ t ] = useTranslation();
    const [isAddProcess, setIsAddProcess] = useState(false);
    const [newProduct, setNewProduct] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (isAddProcess) {
            history.push("/Admin/AdminProductInfo/" + product._id);
        }
    }, [product])

    useEffect(() => {
        setErrors(apierrors);
    }, [apierrors]);

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    }

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "sn", label: t('sn'), required: true },
                { type: "input", name: "model", label: t('model'), required: true, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "option", label: t('option'), required: true, isEditable: true },
            ]
        },
    ]

    const validAdd = () => {
        const errValidate = RayFormValidation(form_controls, newProduct);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            setIsAddProcess(true);
            RegistProductInfo(newProduct);
        }
    }

    return (
        <>
            <h2>Add New Product
                <div className="float-right">
                    <Link to="/Admin/AdminProductList" className="btn btn-outline-default m-l-5">{t('productlist')}</Link>
                </div>
            </h2>
            <div className="card">
                <div className="card-body">
                    <RayForm
                        controls={form_controls}
                        isForm={true}
                        handleChangeData={handleChangeData}
                        errors={errors} />
                    <button className="btn btn-primary" onClick={() => validAdd()}>{t('add')}</button>
                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    const product = state.ProductReducer.product;
    const apierrors = state.AlertReducer.errors;
    return { apierrors, product };
}

const mapDispatch = dispatch => ({
    RegistProductInfo: (data) => dispatch(ProductAction.RegistProductInfo(data)),
})

export default connect(mapState, mapDispatch)(ProductCreate);