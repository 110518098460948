import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NoRecord } from '../../pages/HomePage';
import { RayTable, AdminProductACQListHeader, RayChartBar } from '../common';

const ProductInfoAlignment = ({ product, showtable = true }) => {

	const [col, setCols] = useState([]);
	const [colEms, setColEms] = useState([]);
	const [show, setShow] = useState(false);
	const [alignments, setAlignments] = useState([]);
	const [emsalignments, setEmsAlignments] = useState([]);
	const [keyVal, setKeyVal] = useState({});

	const options = {
		keyCol: "_id",
		filterColumns: [],
		defaultSort: "timestamp",
		defaultSortType: -1
	}

	useEffect(() => {
		if (showtable) {
			if (product.emsalignments) {
				makeAlignEms();
			}
			if (product.alignments) {
				makeAlign();
			}
			setShow(true);
		} else {
			makeKeyVal();
		}
	}, [product]);

	var titleCase = s => s
		.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
		.replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);

	const makeAlignEms = () => {
		var list = [];
		product.emsalignments.map(x => {
			list.push(x);
		})

		setEmsAlignments(list);
		setColEms(createColsEMS(list));
	}

	const makeAlign = () => {
		var list = [];
		product.alignments.map(x => {
			if (!x.rawData)
				return;
			var mData = {};
			mData["timestamp"] = x.timestamp;
			var arr = x.rawData.split("////");
			var result = [];
			arr.map(y => {
				var item = y.split("//");
				if (item[0]) {
					if (item[0].indexOf("_VALUE") > -1) {
						return;
					}
					if (item[0].indexOf("_RESULT") > -1 || item[1].toUpperCase() == "RESULT") {
						result.push({ key: makeHeaderName(item[0]), val: item[2] });
					}
				}
			})
			result.map(m => {
				mData[m.key] = m.val;
			})
			mData["_id"] = x._id;
			list.push({ ...mData });
		})

		console.log(list);

		setAlignments(list);
		setCols(createCols(list));
	}

	const makeKeyVal = () => {
		var mData = {};
		product.alignments.map(x => {
			mData["Last Inspection"] = moment.utc(x.timestamp).local().format('YYYY-MM-DD HH:mm:ss');
			var arr = x.rawData.split("////");
			var result = [];
			arr.map(y => {
				var item = y.split("//");
				if (item[0]) {
					if (item[0].indexOf("_VALUE") > -1) {
						return;
					}
					if (item[0].indexOf("_RESULT") > -1 || item[1].toUpperCase() == "RESULT") {
						mData[makeHeaderName(item[0])] = item[2];
					}
				}
			})
		})
		setKeyVal(mData)
	}

	const makeHeaderName = (t) => {
		return t.replace(".", "-")
			.replace(" ", "-")
			.replace("_RESULT", "")
			.replace("Alignment-", "")
			.replace(/-[0-9]-/g, '-')
			.replace(/-/g, ' ')
	}

	const createCols = (items) => {
		var list = [];
		var hasArray = false;
		items.map(x => {
			Object.keys(x).map(y => {
				if (Array.isArray(x[y])) {
					hasArray = true;
					return;
				}
				if (y == "_id") return;
				if (list.filter(m => m.name == y) == 0) {
					list.push({ name: y, label: titleCase(y), type: y.toUpperCase().indexOf("TIME") > -1 ? "DT" : "" });
				}
			});
		})
		if (hasArray)
			list.push({ name: "values", label: "Data", type: "LISTTABLE" });

		return list;
	}

	const createColsEMS = (items) => {
		var list = [];
		var hasArray = false;
		items.map(x => {
			Object.keys(x).map(y => {
				if (Array.isArray(x[y])) {
					hasArray = true;
					return;
				}
				if (y == "_id") return;
				if (list.filter(m => m.name == y) == 0) {
					list.push({ name: y, label: titleCase(y), type: y.toUpperCase().indexOf("TIME") > -1 ? "DT" : "" });
				}
			});
		})
		if (hasArray)
			list.push({ name: "values", label: "Data", type: "LIST" });
		return list;
	}

	console.log("=====");
	console.log(col);
	console.log("=====");

	return (
		<>
			{product.emsalignments &&
				<>
					{!showtable &&
						<>
							<ul className="listview simple-listview">
								{Object.entries(keyVal).map(([k, v]) =>
									<li className={(k == "Last Inspection" || v.toUpperCase() === "PASS") ? "" : "bg-danger"}>
										<strong>{k}</strong>
										<strong>{v}</strong>
									</li>
								)}
							</ul>
						</>
					}
					{showtable &&
						<>
							{show &&
								<div className="section">
									{product.emsalignments &&
										<div className="card">
											<div className="card-body">
												<RayTable
													data={product.emsalignments}
													columns={colEms}
													options={options} />
											</div>
										</div>
									}
								</div>
							}
						</>
					}
				</>
			}
			<>
				{!showtable &&
					<>
						<ul className="listview simple-listview">
							{Object.entries(keyVal).map(([k, v]) =>
								<li className={(k == "Last Inspection" || v.toUpperCase() === "PASS") ? "" : "bg-danger"}>
									<strong>{k}</strong>
									<strong>{v}</strong>
								</li>
							)}
						</ul>
					</>
				}
				{showtable &&
					<>
						{show &&
							<div className="section">
								{product.alignments &&
									<div className="card">
										<div className="card-body t-scroll">
											<RayTable
												data={alignments}
												columns={col}
												options={options} />
										</div>
									</div>
								}
							</div>
						}
					</>
				}
			</>
		</>
	);
};

export default ProductInfoAlignment;