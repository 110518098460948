import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { history } from '../../history';
import { RayTable, ProductPartListHeader } from '../../components/common';
import { ProductPartAction } from '../../actions/ProductPartAction';
import { Translate as t } from '../../config/Translate';

const AdminProductPart = ({ GetAll, items, AddUpdate }) => {

	const [showType, setShowType] = useState("list");

	const options = {
		search: true,
		keyCol: '_id',
		linkkey: 'code',
		filterColumns: [
			{ col: 'type', val: 'ALL' },
		],
		defaultSort: 'code',
	};

	useEffect(() => {
		GetAll();
	}, []);

	const selected = (e) => {
		AddUpdate(e);
		history.push("/Admin/AdminProductPartInfo");
	}

	const addNew = () => {
		AddUpdate({ models: [] });
		history.push("/Admin/AdminProductPartInfo");
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">
					{t('productpartlist')}
				</h2>
				<div className="ray-right">
					<button className="btn btn-primary btn-sm mt-0" onClick={() => addNew()}>
						<ion-icon name="add-outline"></ion-icon>
						{t('addsparepart')}
					</button>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section mt-1 mb-1">
					<div className="wide-block pt-2 pb-2">
						<RayTable
							data={items}
							rowSelectCallback={(e) => selected(e)}
							callback={() => setShowType("list")}
							columns={ProductPartListHeader}
							options={options} />
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var items = state.ProductPartReducer.items;
	return { items };
};

const mapDispatch = (dispatch) => ({
	GetAll: (filter) => dispatch(ProductPartAction.GetAll(filter)),
	AddUpdate: (item) => dispatch(ProductPartAction.AddUpdate(item)),
});

export default connect(mapState, mapDispatch)(AdminProductPart);