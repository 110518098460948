import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ErrorCodeAction } from '../../actions';
import { DeviceModels, LanguageCode } from '../../config';
import { RayForm, RayFormValidation } from '../common';
import { FileAddList } from '../common/FileAddList';
import { Translate as t } from '../../config/Translate';

const ErrorCodeCreate = ({ callbackDone, errorCode, apierrors, Add }) => {

    const [isAddProcess, setIsAddProcess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [newErrorCode, setErrorCode] = useState({
        lang: LanguageCode[0].code,
        model: [],
        solutions: [],
        attachments: [],
    });
    const [attachments, setAttachments] = useState([]);
    const [newAttachment, setNewAttachment] = useState({ title: "", path: "" });

    useEffect(() => {
        if (isAddProcess) {
            callbackDone && callbackDone(errorCode);
        }
    }, [errorCode])

    useEffect(() => {
        setErrors(apierrors);
    }, [apierrors]);

    const insertSolution = (e) => {
        if (e.key === 'Enter') {
            const newSolution = {
                title: e.target.value.trim(),
                useCount: 0,
                resolvedCount: 0,
                registered: new Date().getTime()
            };
            e.target.value = "";
            setErrorCode({ ...newErrorCode, solutions: [...newErrorCode.solutions, newSolution] });
        }
    }

    const insertAttachment = () => {
        if (newAttachment.path != "" && newAttachment.title != "") {
            setErrorCode({ ...newErrorCode, attachments: [...newErrorCode.attachments, newAttachment] });
            setNewAttachment({ title: "", path: "" });
        }
    }

    const reOrder = (index, delta) => {
        var newIndex = index + delta;
        if (newIndex < 0 || newIndex == newErrorCode.solutions.length) return;
        var indexes = [index, newIndex].sort();
        newErrorCode.solutions.splice(indexes[0], 2, newErrorCode.solutions[indexes[1]], newErrorCode.solutions[indexes[0]]);
        setErrorCode({ ...newErrorCode, solutions: newErrorCode.solutions });
    };

    const delitem = (idx) => {
        newErrorCode.solutions.splice(idx, 1);
        setErrorCode({ ...newErrorCode, solutions: newErrorCode.solutions });
    };

    const setNewFile = (files) => {
        setAttachments([...files]);
    }

    const handleAdd = (e) => {
        const errValidate = RayFormValidation(form_controls, newErrorCode);
        console.log(errValidate);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            setIsAddProcess(true);
            Add({ ...newErrorCode, attachments: attachments });
        }
    }

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setErrorCode((newErrorCode) => ({
          ...newErrorCode,
          [name]: name == "errorCode" ? value.toUpperCase() : value,
        }));
    }

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "errorCode", label: t('errorcode'), required: true, isEditable: true, size: 4 },
                {
                    type: "select2", name: "model", label: t('model'), size: 4,
                    val: newErrorCode.model,
                    list: DeviceModels,
                    isMulti: true,
                    required: true,
                    isEditable: true
                },
                {
                    type: "select", name: "lang", label: t('language'),
                    isEditable: true, required: true,
                    val: newErrorCode.lang || "eng", list: LanguageCode, size: 4
                },
            ]
        },
        {
            title: "", items: [
                {
                    type: "input", name: "definition", label: t('definition'), isEditable: true,
                    val: newErrorCode.definition, size: 12
                },
            ]
        },
        {
            title: "", items: [
                {
                    type: "input", name: "cause", label: t('causeofproblem'), isEditable: true,
                    val: newErrorCode.cause, size: 12
                },
            ]
        },
    ]

    return (
        <>
            <RayForm
                controls={form_controls}
                isForm={true}
                handleChangeData={handleChangeData}
                errors={errors} />
            <div className="section">
                <div className="section-title">Solutions</div>
                <div className="">
                    {newErrorCode.solutions.map((s, idx) => {
                        return (
                            <div className="input-group mb-1" key={idx}>
                                <input type="text" value={s.title} className="form-control"
                                    onChange={(e) => handleChangeTitle(idx, e)}
                                    onKeyPress={(e) => handleUpdateTitle(idx, e)} />
                                <button className="m-t-0 m-l-5 btn btn-sm bg-danger" onClick={() => delitem(idx)}>
                                    <span class="material-icons text-white fs-16">delete</span>
                                </button>
                            </div>
                        )
                    })}
                    <div className="list-group-item p-a-8">
                        <input type="text" className="form-control" placeholder="Add New Solution"
                            onKeyPress={(e) => insertSolution(e)} />
                    </div>
                    {newErrorCode.solutions.length == 0 && <span className='alert'>No Solution</span>}
                </div>
                <div className="section-title clearfix">
                    {t('attachments')}
                    <FileAddList itemlist={attachments} basepath="errorcodes/" callback={setNewFile} isForm={true} />
                </div>
                <button className="btn btn-primary btn-block" onClick={() => handleAdd()}>{t('adderrorcode')}</button>
            </div>
        </>
    );
};

const mapState = (state) => {
    const errorCode = state.ErrorCodeReducer.errorCode;
    const apierrors = state.AlertReducer.errors;
    return { errorCode, apierrors };
}

const mapDispatch = dispatch => ({
    Add: (data) => dispatch(ErrorCodeAction.Add(data)),
})

export default connect(mapState, mapDispatch)(ErrorCodeCreate);
