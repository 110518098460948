import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { AppHeader } from '../../components/common/LeftMenu';
import SettingWizardGuide from '../guides/SettingWizardGuide';
import InstallationGuide from '../guides/InstallationGuide';
import { Translate as t } from '../../config/Translate';

const MyGuide = ({ }) => {

	const { view } = useParams();
	const [tab, setTab] = useState(view || "setting");
	//const [ t, i18n ] = useTranslation();

	const tabList = [
		{ code: "setting", title: t('settingwizard'), icon: "support_agent" },
		{ code: "install", title: "Installation", icon: "error_outline" },
	]

	return (
		<>
			<div className="appHeader no-border">
				<AppHeader title={t('guides')} />
			</div>
			<div id="appCapsule" className="full-height">
				<div className="section mb-2 sch-result">
					<ul className="nav nav-tabs style2 mt-1 mb-1">
						{tabList.map((x, idx) =>
							<li className="nav-item" key={idx}>
								<button key={x.code}
									className={tab == x.code ? "nav-link active" : "nav-link"}
									onClick={() => setTab(x.code)}>
									{x.title}
								</button>
							</li>
						)}
					</ul>
					{tab == "setting" && <SettingWizardGuide />}
					{tab == "install" && <InstallationGuide />}
				</div>
			</div>
		</>
	)
}

export default MyGuide;