export const AuthConstant = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	ADMINLOGIN_SUCCESS: 'ADMINLOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGOUT: 'LOGOUT',
	TEST: 'TEST',
	UPDATE_SUCCESS: 'UPDATE_SUCCESS',
	UPDATE_ADMINSETTING: 'UPDATE_ADMINSETTING',
	UPDATE_ADMINFILTERS: 'UPDATE_ADMINFILTERS',
	GETUSERINFO_SUCCESS: 'GETUSERINFO_SUCCESS',
	CLEAR_UPDATERES: 'CLEAR_UPDATERES',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
};
