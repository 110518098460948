import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputBasicTextArea } from '../../components/common/Inputs';
import { FileAddList } from '../../components/common/FileAddList';
import { SiteSurveyAction, CalendarAction, ProductAction } from '../../actions';
import SurveyStep0 from './SurveyStep0';
import SurveyStep1 from './SurveyStep1';
import SurveyStep2 from './SurveyStep2';
import SurveyStep3 from './SurveyStep3';
import { ExportPdfReport, ExportPdfReportToEmail } from '../../components/common/ExportPdfReport';
import { Translate as t } from '../../config/Translate';

const SurveyStep4 = ({ Update, CreateSchedule, info, view = "", SendReportMail, gostep }) => {

	// const [t] = useTranslation();
	const [siteSurvey, setSiteSurvey] = useState({ ...info, siteSurveyStep: 6, isSubmit: true });
	const [showTab, setShowTab] = useState({
		tab1: true,
		tab2: false,
		tab3: false,
		tab4: false,
	});

	useEffect(() => {
		setSiteSurvey(info);
	}, [info]);

	useEffect(() => {
		if (gostep) {
			if (gostep.curStep == 5) {
				CreateSchedule({
					color: "bg-danger",
					finish: siteSurvey.scheduleDate + 1000 * 60 * 60,
					model: siteSurvey.model,
					option: siteSurvey.option,
					siteId: siteSurvey.sitesurveyId,
					sitename: siteSurvey.siteSitename,
					sn: siteSurvey.sn,
					start: siteSurvey.scheduleDate,
					type: "Install"
				});
				Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: gostep.goStepNo });
			}
		}
	}, [gostep])


	const Prev = (e) => {
		Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 4 });
	};

	const validNext = () => {
		CreateSchedule({
			color: "bg-danger",
			finish: siteSurvey.scheduleDate + 1000 * 60 * 60,
			model: siteSurvey.model,
			option: siteSurvey.option,
			siteId: siteSurvey.sitesurveyId,
			sitename: siteSurvey.siteSitename,
			sn: siteSurvey.sn,
			start: siteSurvey.scheduleDate,
			type: "Install"
		});
		Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 6, isSubmit: true });
	};

	const SendEmail = () => {
		//var result = ExportPdfReportToEmail('sitesurvey', info);
		//////SendReportMail(siteSurvey._id, info);
		//console.log(result);
	};

	const Export = () => {
	};

	return (
		<>
			<div className="section mt-1 inset">
				<div className="section-title">{t('summary')}</div>
			</div>
			<div className="section">
				<div className="accordion mt-1">
					<h2 className="accordion-header">
						<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab1: !showTab.tab1 })}>
							<strong>
								<ion-icon name="document-text-outline" className="fl md hydrated" />
								{t('siteinfo')}
							</strong>
						</button>
					</h2>
					{showTab.tab1 &&
						<div className="accordion-collapse collapse show">
							<div className="accordion-body pb-0 pt-0">
								<SurveyStep0 info={siteSurvey} isSummary={true} />
							</div>
						</div>}
				</div>
				<div className="accordion mt-1">
					<h2 className="accordion-header">
						<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab2: !showTab.tab2 })}>
							<strong>
								<ion-icon name="document-text-outline" className="fl md hydrated" />
								{t('productinfo')}
							</strong>
						</button>
					</h2>
					{showTab.tab2 &&
						<div className="accordion-collapse collapse show">
							<div className="accordion-body pb-0 pt-0">
								<SurveyStep1 info={siteSurvey} isSummary={true} />
							</div>
						</div>}
				</div>
				<div className="accordion mt-1">
					<h2 className="accordion-header">
						<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab3: !showTab.tab3 })}>
							<strong>
								<ion-icon name="document-text-outline" className="fl md hydrated" />
								{t('deliveryinfo')}
							</strong>
						</button>
					</h2>
					{showTab.tab3 &&
						<div className="accordion-collapse collapse show">
							<div className="accordion-body pb-0 pt-0">
								<SurveyStep2 info={siteSurvey} isSummary={true} />
							</div>
						</div>}
				</div>
				<div className="accordion mt-1">
					<h2 className="accordion-header">
						<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab4: !showTab.tab4 })}>
							<strong>
								<ion-icon name="document-text-outline" className="fl md hydrated" />
								{t('roomdimensions')}
							</strong>
						</button>
					</h2>
					{showTab.tab4 &&
						<div className="accordion-collapse collapse show">
							<div className="accordion-body pb-0 pt-0">
								<SurveyStep3 info={siteSurvey} isSummary={true} />
							</div>
						</div>}
				</div>
			</div>
			{view == "view" &&
				<div className="mb-3 mt-2 ms-2 me-2">
					{/* <button className="btn btn-primary btn-block btn-lg" onClick={() => SendEmail()}>{t("email")}</button> */}
					<button className="btn btn-primary btn-block btn-lg" onClick={() => Export()}>{t("export")}</button>
					<ExportPdfReport reportType={'sitesurvey'} infos={info} />
					<button className="btn btn-outline-primary btn-block btn-lg" onClick={() => history.back()}>{t("close")}</button>
				</div>
			}
			{view != "view" &&
				<div className="section mt-1 mb-2">
					<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('complete')}</button>
					<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
				</div>
			}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(SiteSurveyAction.UpdateSiteSurvey(_id, data)),
	CreateSchedule: (data) => dispatch(CalendarAction.Create(data)),
	SendReportMail: (_id, data) => dispatch(SiteSurveyAction.SendReportMail(_id, data))
});

export default connect(null, mapDispatch)(SurveyStep4);