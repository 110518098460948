import React, { useState, useEffect } from 'react';
import { RayMaps } from '../../components/common';
import { Modal } from 'react-bootstrap';

const MapView = ({ site, callback, showAS }) => {

	const [vHg, setvHg] = useState(0);
	const [isMapOk, setIsMapOk] = useState(true);

	const getHeight = () => setvHg(window.innerHeight);

	useEffect(() => {
		console.log(site);
	}, [site])

	useEffect(() => {
		window.addEventListener('resize', getHeight);
		setvHg(window.innerHeight);
	}, [])

	const mapOk = (bl) => {
		setIsMapOk(bl);
	}

	return (
		<Modal show={showAS} fullscreen={true} dialogClassName="modal-fullscreen">
			<Modal.Header style={{ display: "block" }}>
				<div className="fl">
					<h3 className="modal-title">
						{site.sitename}
					</h3>
					<p className="text-muted mb-0">{site.address} {site.addressExtra}</p>
				</div>
				<div className="float-end mt-1">
					<a onClick={() => callback()}>
						<ion-icon name="close-outline" size="large" />
					</a>
				</div>
			</Modal.Header>
			<Modal.Body className="p-0 m-0">
				{(site.lat && site.lng && isMapOk) && <RayMaps
					options={{
						zoom: 18,
						draggable: true,
						mapTypeControl: false,
						streetViewControl: false,
						rotateControl: false,
						scaleControl: false,
						zoomControl: false
					}}
					callback={(bl) => mapOk(bl)}
					onMapLoad={map => { }}
					country={site.country}
					height={vHg - 74}
					Lat={site.lat}
					Lng={site.lng}
					markerList={[]}
				/>}
			</Modal.Body>
		</Modal>
	);
};

export default MapView;