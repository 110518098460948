import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayForm } from '../common';
import { ProductAction, RayCodeAction, AuthAction } from '../../actions';
import { Translate as t } from '../../config/Translate';
import CustomFieldValues from '../../components/customfield/CustomFieldValues';

const ProductInfoExtra = ({ UpdateProductInfo, clist, productInfo, editable, favList, UpdateUserFav, UpdateUser, user }) => {

	const [codeList, setCodeList] = useState([]);
	const [tables, setTables] = useState([]);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(true);
	const [edit, setEdit] = useState(false);
	const [showAll, setShowAll] = useState(false);
	const [showOnlyFav, setOnlyFav] = useState(false);
	const [favItems, setFavItems] = useState([]);

	useEffect(() => {
		setCodes();
	}, [clist]);

	useEffect(() => {
		setFavItems(favList || []);
	}, [favList]);

	useEffect(() => {
		if (loading) {
			makeList();
		}
	}, [codeList, favItems, showOnlyFav, search]);

	useEffect(() => {
		setShowAll(user.settings && user.settings.prdextrashopall);
		setOnlyFav(user.settings && user.settings.prdextrafavonly);
	}, [user.settings]);

	const setCodes = async () => {
		setCodeList(clist?.filter(x => x.type === 'PRODUCTEXTRAKEY'));
		//setCodeList(await RayCodeAction.GetAllByFilter({ type: "PRODUCTEXTRAKEY" }));
	}

	const groupBy = (items, key) => items.reduce(
		(result, item) => ({ ...result, [item[key]]: [...(result[item[key]] || []), item,], }), {},
	);

	const getFType = (m) => {
		if (m.datatype == "STRING") {
			return "input"
		} else if (m.datatype == "DATETIME") {
			return "datetime"
		} else if (m.datatype == "BOOLEAN") {
			return "checkbox"
		}
		return "input"
	}

	const makeList = () => {
		var tableList = [];
		var nSearch = search.toUpperCase();
		var objList = groupBy(codeList.filter(x => {
			return nSearch != "" ? (x.name.toUpperCase().indexOf(nSearch) > -1) : true
		}), "group");

		Object.keys(objList).map(t => {
			var cSort = 0;
			if (t == "H/W SN") cSort = 0;
			else if (t == "H/W FW") cSort = 1;
			else if (t == "S/W Version") cSort = 2;
			else if (t == "3D Viewer") cSort = 3;
			else if (t == "Workstation") cSort = 4;
			else if (t == "AditionalItems") cSort = 5;
			else if (t == "Appendix") cSort = 6;
			else if (t == "ETC") cSort = 7;
			else cSort = 8;
			var table = [];
			var item = {
				title: t,
				items: objList[t].filter(y => showOnlyFav ?
					favItems.includes("extradata." + y.code) : true
				).map(m => {
					var ftype = getFType(m);
					return {
						type: ftype,
						name: "extradata." + m.code,
						label: m.name,
						val: productInfo.extradata && productInfo.extradata[m.code],
						inline: true,
						showpin: true,
						pinned: favItems.includes("extradata." + m.code),
						isEditable: editable
					}
				}),
				csort: cSort
			};
			table.push(item)
			tableList.push(table);
		})
		setTables(tableList);
		setLoading(true);
	}

	const handleUpdateData = (e) => {
		const key = e.target.name;
		const val = e.target.value;
		var obj = {};
		obj[key] = val;
		UpdateProductInfo(productInfo._id, { '$set': obj })
	}

	const fav = () => {
	}

	const removeArr = (id) => {
		const idx = favItems.indexOf(id);
		if (idx > -1) {
			favItems.splice(idx, 1);
		}
		return favItems;
	}

	const onpinupdate = (e) => {
		UpdateUserFav("productoptions", e.pinned ?
			{ favoriteProductOptions: removeArr(e.name) } :
			{ favoriteProductOptions: [...favItems, e.name] });
	}

	const showOptions = (type, val) => {
		var obj = {};
		var sobj = {};
		sobj['settings.' + type] = val;
		obj['$set'] = sobj;
		UpdateUser(obj);
	}

	const UpdateExtra = (code, value) => {
		UpdateProductInfo(productInfo._id, {
			customfieldvals: { ...productInfo.customfieldvals, [code]: value }
		});
	}

	return (
		<div className="section">
			<div className="row">
				<div className="col-4">
					<div className="card">
						<div className="card-body">
							{!productInfo.orisn && <button
								className={edit ? "btn btn-secondary btn-block" : "btn btn-primary btn-block"}
								onClick={e => setEdit(!edit)}>
								{edit ? t('locked') : t('unlocked')}
							</button>}
							<div className="form-group basic">
								<div className="input-wrapper">
									<label>{t('search')}</label>
									<input type="input"
										className={loading ? "form-control" : "form-control disabled"}
										onChange={e => setSearch(e.target.value)} value={search} />
								</div>
							</div>
						</div>
					</div>
					<div className="card mt-1">
						<ul className="listview simple-listview transparent flush">
							<li>
								<div><strong>{t('viewallitems')}</strong></div>
								<div className="form-check form-switch" onClick={e => showOptions("prdextrashopall", !showAll)}>
									<input className="form-check-input" type="checkbox" checked={showAll} />
									<label className="form-check-label"></label>
								</div>
							</li>
							<li>
								<div><strong>{t('myfavorites')}</strong></div>
								<div className="form-check form-switch" onClick={e => showOptions("prdextrafavonly", !showOnlyFav)}>
									<input className="form-check-input" type="checkbox" checked={showOnlyFav} />
									<label className="form-check-label"></label>
								</div>
							</li>
						</ul>
					</div>
					<div className="card mt-1">
						<CustomFieldValues model={"PRODUCT"} update={UpdateExtra} data={productInfo.customfieldvals || {}} />
					</div>
				</div>
				<div className="col-8">
					{tables.sort((a, b) => a[0].csort - b[0].csort).map((table) => {
						return <RayForm
							data={productInfo}
							handleUpdateData={handleUpdateData}
							showAllFields={edit || search != "" || showAll || showOnlyFav}
							onpinupdate={onpinupdate}
							controls={table}
							showPin={true}
							isForm={edit} />
					})}
				</div>
			</div>
		</div>
	)
};

const mapState = (state) => {
	const prd = state.ProductReducer.product;
	var favList = state.FavoriteReducer.productoptions;
	var user = state.AuthReducer.user;
	const clist = state.RayCodeReducer.items;
	return { prd, favList, user, clist };
};

const mapDispatch = (dispatch) => ({
	UpdateProductInfo: (_id, data) => dispatch(ProductAction.UpdateProductInfo(_id, data)),
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
	UpdateUser: (data) => dispatch(AuthAction.UpdateUser(data)),
});

export default connect(mapState, mapDispatch)(ProductInfoExtra);
