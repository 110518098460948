import axios from 'axios';
import { AppConfig, JWT } from '../config';

const getUserInfo = async (adminid) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user/' + adminid, JWT());
	return ret.data.data;
};

const getAllUserInfo = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user', JWT());
	return ret.data.data;
}

export const UserAction = {
	getUserInfo,
	getAllUserInfo
};
