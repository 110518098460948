export const CompanyTypes = [
    { code: "Branch", title: "Branch" },
    { code: "DstBranch", title: "Distributor (Branch)" },
    { code: "DstHQ", title: "Distributor (HQ)" },
    { code: "DstHQBranch", title: "Distributor (HQ + Branch)" },
    { code: "HQ", title: "HQ" },
    { code: "SubDstDst", title: "Sub Distributor (Distributor)" },
    { code: "SubDstDstBranch", title: "Sub Distributor [Distributor (Branch)]" }
]

export const AdminTypes = [
    { code: "S", title: "Super Admin" },
    { code: "M", title: "Manager" },
    { code: "U", title: "User" }
]

export const AdminFunctions = [
    { code: "CS", title: "Customer service" },
    { code: "Sales", title: "Sales" },
    { code: "Marketing", title: "Marketing" }
]

export const AdminPosition = [
    { code: "servicemanager", title: "Service manager" },
    { code: "salesmanager", title: "Sales manager" },
    { code: "marketingmanager", title: "Marketing manager"},
    { code: "ceo", title: "CEO" },
    { code: "staff", title: "Staff" }
]

export const WarrantyProductType = [
    { code: "CT", title: "CT" },
    { code: "UP KIT", title: "Ceph UP KIT" },
    { code: "PRD", title: "Product" },
]

export const WarrantyUPKitModel = [
    { code: "Ceph UP KIT", title: "Ceph UP KIT" }
]

export const DeviceModels = [
    { code: "RAYSCAN α (NEW) 2D", title: "RAYSCAN α (NEW) 2D" },
    { code: "RAYSCAN α 2DN", title: "RAYSCAN α 2DN" },
    { code: "RAYSCAN α", title: "RAYSCAN α" },
    { code: "RAYSCAN m+", title: "RAYSCAN m+" },  //RCT710
    { code: "RAYSCAN α (NEW) 3D", title: "RAYSCAN α (NEW) 3D" },
    { code: "RAYSCAN α+", title: "RAYSCAN α+" },  //RCT700
    { code: "RAYSCAN Studio", title: "RAYSCAN Studio" },  //RCT800
    { code: "RAYSCAN m+ (New)", title: "RAYSCAN m+ (New)" },
    { code: "RAYSCAN α+ (NEW)", title: "RAYSCAN α+ (NEW)" },
    { code: "R2 Studio Q", title: "R2 Studio Q" },
    { code: "RAY PreMiere", title: "RAY PreMiere" }, 
]

export const SpecialityTypes = [
    { code: "GP", title: "General Practitioner (GP)" },
    { code: "Pros", title: "Prosthodontist" },
    { code: "Endo", title: "Endodontist" },
    { code: "Perio", title: "Periodontists" },
    { code: "Ortho", title: "Orthodontist" },
    { code: "Ped", title: "Pediatric Dentist" },
    { code: "Oral", title: "Oral and Maxillofacial Surgeon" },
    { code: "Public", title: "Public Health Dentist" },
    { code: "Rad", title: "Radiologist" },
    { code: "Other", title: "Other" },
]

export const RayModels = [
    { code: "Endo", title: "Endodontist" },
    { code: "Foren", title: "Forensic Dentists" },
    { code: "GP", title: "General Practitioner (GP)" },
    { code: "Oral", title: "Oral and Maxillofacial Surgeon" },
    { code: "Ortho", title: "Orthodontist" },
    { code: "Other", title: "Other" },
    { code: "Ped", title: "Pediatric Dentist" },
]

export const RayStatuses = [
    { code: "New", title: "New" },
    { code: "Visit", title: "Visit" },
    { code: "Remote", title: "Remote" },
    { code: "Monitoring", title: "Monitoring" },
    { code: "Escalate", title: "Escalate" },
    { code: "Cancel", title: "Cancel" },
    { code: "Close", title: "Close" },
]

export const RayIssueTypes = [
    { code: "", title: "Common" },
    { code: "ERRORCODE", title: "ErrorCode" },
]

export const RayTranslateLanguages = [
    { code: 'eng', title: 'English' },
    { code: 'kor', title: 'Korean' },
    { code: 'deu', title: 'German' },
    { code: 'spa', title: 'Spanish' },
    { code: 'fre', title: 'French' },
    { code: 'jpn', title: 'Japanese' },
    { code: 'cmn', title: 'Chinese - Simplified' },
    { code: 'zhh', title: 'Chinese - Traditional' },
];

export const GetRayDeviceGuide = (model, ceph) => {
    var spacetxt = "Width door: 800mm (31.5＂) \n Width: 2,138mm (84.2＂) \n Depth: 1,700mm (66.9＂) \n Height: 2,500mm (98.4＂)";
    var imgUrl = "/assets/img/roomdimension/Ceph.JPG";

    if (model == 'RAYSCAN m+' || model == 'RAYSCAN m+ (New)') {
        if (ceph == 'NOCEPH') {
            imgUrl = "/assets/img/roomdimension/mplusnoceph.png";
        } else {
            imgUrl = "/assets/img/roomdimension/mplusceph.png";
        }
    } else {
        if (ceph == "NOCEPH") {
            imgUrl = "/assets/img/roomdimension/No_Ceph.JPG";
        } else {
            imgUrl = "/assets/img/roomdimension/Ceph.JPG";
        }
    }

    return {
        space: spacetxt,
        img: imgUrl,
    }
}

export const RayCsPotalLink = [
    { code: 'lv4', title: 'https://partner.raymedical.com/?autologin_code=IKMqLobcisfFYxvQge6rzEGo1DZfLJ'},
    { code: 'lv7', title: 'https://partner.raymedical.com/?autologin_code=hkggy1y3eLES9kmAYMhiQBjoWdHGta'},
];
