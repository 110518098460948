import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductAction, SiteAction } from '../../actions';
import { InputBasicSelect } from "../../components/common/Inputs";
import { history } from '../../history';

const AdminProductUpdateSite = ({ product, UpdateProductSite, ClearUpdateResult, isUpdateRes }) => {

	const [siteCombo, setSiteCombo] = useState([]);
	const [siteId, setSiteId] = useState(product.siteId || "");
	const [newSiteName, setNewSiteName] = useState(product.sitename || "");
	const [errMsg, setErrMsg] = useState("");

	useEffect(() => {
		GetSitesCombo();
	}, []);
	useEffect(() => {
		if (isUpdateRes) {
			ClearUpdateResult();
			history.push("/Admin/AdminProductInfo/" + product._id + "/info");
		}
	}, [isUpdateRes]);


	const GetSitesCombo = () => {
		const list = [{ code: "", title: "설치 제거" }];
		SiteAction.GetSiteCombo().then((data) => {
			setSiteCombo(list.concat(data));
		});
	}

	const handleChangeData = (e) => {
		setErrMsg("");
		setSiteId(e.target.value);
		setNewSiteName(e.target.text);
	}

	const Update = () => {
		if (product.siteId != siteId) {
			UpdateProductSite(product._id, product.siteId || "", siteId, newSiteName);
		} else {
			setErrMsg("변경된 정보가 없습니다.");
		}
	}

	const option = {
		label: "변경할 Site",
		name: "siteId",
		val: siteId,
		list: siteCombo
	}

	return (
		<>
			<div className="card bg-info text-white">
				<div className="card-header">
					<div className="card-title">Site 변경</div>
				</div>
				<div className="card-body">
					<h4>즉시 반영됩니다!!!</h4>
					<p>Site 변경 작업은 되돌릴 수 없는 작업입니다.</p>
					<p>정말 변경하시겠습니까?</p>
					<div style={{ "marginLeft": "-15px" }}>
						<InputBasicSelect
							options={option}
							isForm={true}
							editable={true}
							onchange={handleChangeData} />
					</div>
					{errMsg && <span className="alert alert-info">{errMsg}</span>}
				</div>
			</div>
			<button className="btn btn-outline-danger" onClick={() => Update()}>Update SIte</button>
		</>
	);
};

const mapState = (state) => {
	const isUpdateRes = state.ProductReducer.isUpdateRes;
	return { isUpdateRes };
}

const mapDispatch = dispatch => ({
	ClearUpdateResult: () => dispatch(ProductAction.ClearUpdateResult()),
	UpdateProductSite: (_id, nowSiteId, newSiteId, newSiteName) => dispatch(ProductAction.UpdateProductSite(_id, nowSiteId, newSiteId, newSiteName))
})

export default connect(mapState, mapDispatch)(AdminProductUpdateSite);