import { ErrorCodeAction, ProductAction } from '../../actions';
import { RayTable, AdminProductErrorListHeader, AdminProductErrorListHeader2 } from '../common';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { NoRecord } from '../../pages/HomePage';
import moment from 'moment';
import { Translate as t } from '../../config/Translate';

const ProductInfoError = ({ errorCodes, product, RequestErrorLogFile, GetAllErrorCodes, _sn }) => {
	const [errs, setErrs] = useState([]);

	const getLogRType = (x) => {
		var type = "Not Requested";
		if (x.hasOwnProperty("emsrequestlog")) {
			if (x.hasOwnProperty("emsresponseresult")) {
				type = x.emsresponseresult ? "Success" : "Fail";
			} else {
				type = "Wait"
			}
		}
		return type;
	}

	const options = {
		search: true,
		keyCol: "code",
		linkto: '/Admin/AdminErrorCodeInfo',
		linkkey: ['code', 'lang'],
		filterColumns: [
			{ col: "code", val: "ALL" },
			{ col: "closer", val: "ALL" },
			{ col: "emslogrtype", val: "ALL" },
		],
		filterpageoption: {
			filterDateOptions: [
				{ code: "begin", label: "Begin" }
			]
		},
		defaultSort: "begin",
		defaultSortType: -1
	}

	const requestLogCallback = (item) => {
		RequestErrorLogFile(product.sn, item._id);
	}

	const getDefinition = (errorCode) => {
		const result = errorCodes.find(x => x.errorCode == errorCode && x.model.includes(product.model));
		return result ? result.definition : '';
	}

	const getLastAcq = (begin) => {
		if (product.acquisitions)
			return product.acquisitions.reverse().find(x => x.activationTime < begin)?.activationTime;
	}

	const getDuration = (begin, end) => {
		if (end) {

			begin = moment(begin);
			end = moment(end);

			const seconds = end.diff(begin, 'seconds');
			const minutes = Math.trunc(seconds / 60);
			const hours = Math.trunc(minutes / 60);
			const days = Math.trunc(hours / 24);

			if (days > 0)
				return `${days} day${(days > 1 ? 's' : '')}`;
			else if (hours > 0)
				return `${hours} hour${(hours > 1 ? 's' : '')}`;
			else if (minutes > 0)
				return `${minutes} minute${(minutes > 1 ? 's' : '')}`;
			else
				return `${seconds} second${(seconds > 1 ? 's' : '')}`;
		} else {
			return '';
		}
	}

	useEffect(() => {
		GetAllErrorCodes();
	}, []);

	useEffect(() => {
		var list = [];
		product.errs.map(x => {
			list.push({
				...x,
				sn: product.sn,
				logRequested: x.emsrequestlog,
				emslogrtype: getLogRType(x),
				definition: getDefinition(x.code),
				// lastAcq: getLastAcq(x.begin),
				status: x.closer ? 'Closed' : 'Not Closed',
				duration: getDuration(x.begin, x.end),
				lang: 'eng'
			})
		})
		setErrs(list);
	}, [product])

	return (
		<div className="section">
			<div className="card">
				<div className="card-body">
					{errs.length > 0 &&
						<RayTable
							data={errs}
							parentData={product}
							calllback={requestLogCallback}
							columns={AdminProductErrorListHeader2}
							options={options} />
					}
					{errs.length == 0 &&
						<NoRecord title={t('noissues')} contents={t('thereisnoissuehistory')} _sn={_sn} widthsection={true} />
					}
				</div>
			</div>
		</div>
	);
};

const mapState = (state) => {
	const errorCodes = state.ErrorCodeReducer.errorCodes;
	return { errorCodes };
};

const mapDispatch = (dispatch) => ({
	RequestErrorLogFile: (sn, errId) => dispatch(ProductAction.RequestErrorLogFile(sn, errId)),
	GetAllErrorCodes: () => dispatch(ErrorCodeAction.GetList())
});

export default connect(mapState, mapDispatch)(ProductInfoError);
