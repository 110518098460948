import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Map from '../../components/common/Map';
import MapB from '../../components/common/MapB';
import Statistics from '../../components/common/Statistics';

const AdminDashboard = ({ dashboardtype }) => {

	const [availGoogle, setAvailGoogle] = useState(false);

	useEffect(() => {
		setAvailGoogle(!window.google ? false : true);		
	},[]);

	return (
		<div id="appCapsule" className="full-height dashboard">
			{
				(dashboardtype == "map" && availGoogle) &&
				<Map callback={(e) => setShow(e)} />
			}
			{
				(dashboardtype == "map" && !availGoogle) &&
				<MapB />
			}

			{dashboardtype == "statistics" && <Statistics callback={(e) => setShow(e)} />}
		</div>
	);
};

const mapState = (state) => {
	var dashboardtype = state.ProgramReducer.dashboardtype;
	var showSub = state.ProgramReducer.showSub;
	return { dashboardtype, showSub };
};

export default connect(mapState)(AdminDashboard);
