import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../../history';
import { AppHeader } from '../../components/common';
import SurveyList from '../../components/sitesurvey/SurveyList';
import InstallationList from '../../components/installation/InstallationList';
import { Translate as t } from '../../config/Translate';

const MyReportManager = () => {

	const { view } = useParams();
	const [tab, setTab] = useState(view || "survey");
	// const [t, i18n] = useTranslation();
	const [showAS, setShowAS] = useState(false);

	useEffect(() => {
	}, []);

	const tabList = [
		{ code: "survey", title: t("preinstallreport"), icon: "" },
		{ code: "installation", title: t("installation"), icon: "" },
	]

	return (
		<>
			<div className="appHeader no-border bg-white">
				<AppHeader title={t('reportmanager')} />
				<div className="right">
					<a href onClick={() => history.push(tab == "survey" ? "/SiteSurvey" : "/Installation")} className="headerButton">
						<ion-icon name="add-outline"></ion-icon>
					</a>
					<a href onClick={() => setShowAS(true)} className="headerButton">
						<ion-icon name="filter-outline"></ion-icon>
					</a>
				</div>
			</div>
			<div id="appCapsule">
				<div className="section mt-2 mb-2">
					<ul className="nav nav-tabs style2 mt-1 mb-1">
						{tabList.map(x =>
							<li className="nav-item">
								<button key={x.code}
									className={tab == x.code ? "nav-link active" : "nav-link"}
									onClick={() => { setTab(x.code); history.push("/ReportManager/" + x.code); }}>
									{x.title}
								</button>
							</li>
						)}
					</ul>
				</div>
				<div className="section mt-2 mb-2">
					{tab == "survey" && <SurveyList showAS={showAS} callbackAS={() => setShowAS(false)} />}
					{tab == "installation" && <InstallationList showAS={showAS} callbackAS={() => setShowAS(false)} />}
					{/*{tab == "installation" && <ProductErrorList />}*/}
				</div>
			</div>
		</>
	)
}

export default MyReportManager;