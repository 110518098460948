import { ProgramConstant } from '../actions/constants/ProgramConstant';
import packageJson from '../../package.json';

var user = JSON.parse(localStorage.getItem('user'));

const initialState = 
{ 
    menuOpen : false,
    showtabmain : "service",
    showfav : false,
    dashboardtype : "statistics",
    homeSplide : 0,
    homeListErr : [],
    homeListService : [],
    homeListSched : [],
    homeListOverdue : [],
    homeListRecentInstall : [],
    homeListTotalPrdCount : 0,
    homeListTotalSSUnfinishedCount : 0,
    homeListTotalINSTUnfinishedCount : 0,
    homeListTotalActivePrdCount : 0,
    errorLookupShow : false,
    searchScreenTitle : "Search",
    searchScreenTitleCount : 0,
    errorLookupCode : "",
    showSub : true,
    automapplay : false,
    showChart : true,
    chartType : "acq",
    productlistisstock : false,
    viewtree : false,
    showErrorActionsId : "",
    searchFilters : { search : "", model : [] },
    appVersion : packageJson.version,
    deviceCategory : 'ALL',
    showmodel : (localStorage.getItem('showmodel') || '').split(',')
};

export const ProgramReducer = (state = initialState, action) => {
    switch (action.type) {
        case ProgramConstant.SET_PRODUCTLISTISSTOCK:
            return {...state, productlistisstock : action.isstock };
        case ProgramConstant.SET_CHARTOPT:
            return {...state, showChart : action.showChart, chartType : action.chartType };
        case ProgramConstant.SET_MAPAUTOPLAY:
            return {...state, automapplay : action.automapplay, showSub : !action.automapplay };
        case ProgramConstant.SET_SHOWSUB:
            return {...state, 
                showSub : action.showSub, 
                automapplay : action.showSub ? false : state.automapplay };
        case ProgramConstant.SET_SPLIDER:
            return {...state, homeSplide : action.index };
        case ProgramConstant.DASHBOARDSHOW_TYPE:
            return {...state, dashboardtype : action.showtype };
        case ProgramConstant.SET_SEARCHSCREEN_TITLE:
            return {...state, 
                searchScreenTitle : action.title,
                searchScreenTitleCount : action.count 
            };
        case ProgramConstant.SET_SS_DOCCOUNT:            
            return {...state,
                homeListTotalSSUnfinishedCount : action.count,
            };
        case ProgramConstant.SET_INST_DOCCOUNT:            
            return {...state,
                homeListTotalINSTUnfinishedCount : action.count,
            };
        case ProgramConstant.SHOW_TAB_MAIN:
            return {...state, showtabmain : action.tp };
        case ProgramConstant.SET_SHEDS:
            return {...state,
                homeListSched : action.list,
            };
        case ProgramConstant.SET_TOTALPRDCOUNT:
            return {...state,
                homeListTotalPrdCount : action.data.total,
                homeListTotalActivePrdCount : action.data.active
            };
        case ProgramConstant.SET_RECENTINSTALL:
            return {...state,
                homeListRecentInstall : action.list
            };
        case ProgramConstant.SET_OVERDUE_SERVICE:
            return {...state,
                homeListOverdue : action.list,
            };
		case ProgramConstant.UPDATE_SHEDS:
			return {
				...state,
				homeListSched: state.homeListSched.map((u) => (u._id == action.item._id ? action.item : u)),
			};
		case ProgramConstant.DELETE_SCH:
			return { ...state, homeListSched: state.homeListSched.filter((u) => u._id !== action._id), item : {}};
        case ProgramConstant.SET_ERRORS:
            return {...state,
                homeListErr : action.list,
            };
        case ProgramConstant.UPDATE_PRODUCTERRORINFO:
			if(action.result.list.length > 0){
				const item = action.result.list[0];
				const newproducts = action.isClose ? 
					state.homeListErr.filter((u) => (u.errs._id != item.errs._id)) : 
					state.homeListErr.map((u) => (u.errs._id == item.errs._id ? item : u))
				return { ...state, 
					homeListErr : newproducts };
			}
        case ProgramConstant.VIEWTREE:
            return {...state, viewtree : action.val };
        case ProgramConstant.SHOW_ALL:
            return {...state, showfav : false };
        case ProgramConstant.SHOW_FAV:
            return {...state, showfav : true };
        case ProgramConstant.SIDEMENU_CLOSE:
            return {...state, menuOpen : false };
        case ProgramConstant.SIDEMENU_OPEN:
            return {...state, menuOpen : true };
        case ProgramConstant.ERRORCODELOOKUP_SHOW:
            return {...state, errorLookupCode : action.eCode, errorLookupShow : true };
        case ProgramConstant.ERRORCODELOOKUP_HIDE:
            return {...state, errorLookupShow : false };
        case ProgramConstant.SETSEARCHFILTERS:
            return {...state, searchFilters : action.filters };
        case ProgramConstant.PRODUCTERROR_SHOWACTIONS:
            return {...state, showErrorActionsId : action.errId };
        case ProgramConstant.DEVICE_CATEGORY:
            return { ...state, deviceCategory: action.deviceCategory };
        case ProgramConstant.SHOW_MODEL:
            localStorage.setItem('showmodel', action.showmodel)
            return {...state, showmodel : action.showmodel };
        default:
            return state
    }
}
