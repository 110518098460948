
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ServiceAction } from '../../actions/ServiceAction';
import { SiteAction } from '../../actions/SiteAction';
import { InputBasicText } from '../common/Inputs';
import { ExportPdfReport } from "../common/ExportPdfReport";
import { RayForm, RayFormValidation } from '../common';
import moment from 'moment';
import { ProductAction } from "../../actions/ProductAction";
import { Loading } from "../common/Loading";
import DatePicker, { registerLocale } from "react-datepicker";
import { set } from "mongoose";

const ProductHistoryReport = ({ showModal, productInfo, GetServiceBySN, items, sites, totalservicecount, GetServiceReplacementsBySN, partreplacements, UpdateProductHistoryReport }) => {
    const [loading, setLoading] = useState(false);
    const [isFocus, SetIsFocus] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [data, setData] = useState({
        errCount: 0,
        serviceCount: 0,
        serviceCharge: "",
        partReplace: 0,
        partCharge: "",
        training: 1,
        productYear: "",
        usePeriod: "",
        userReplace: 0,
        snReplace: 0,
        sitename: "",
        customerFirstName: "",
        model: "",
        option: "",
        sn: "",
        serviceUser: "",
        address: "",
        installedDate: "",
        warrantyPeriod: "",
        installedDate: "",
        serviceReplacements: [],
        snhistorielist: [],
        contact: "",
        pano: false, ct: false, ceph: false, dr: false, nineninecm: false, tentencm: false, thirteencm: false,
        sixteencm: false, twentycm: false, sctwentytwo: false, sctwentyfour: false, OSC: false, OCL: false,
        objectScan: false, webViewer: false
    })

    useEffect(() => {
        productInfo.sn && GetServiceBySN(productInfo.sn);
        productInfo.sn && GetServiceReplacementsBySN(productInfo.sn);
        setSelectedRow("");
    }, [productInfo]);

    useEffect(() => {
        setData({ ...data, serviceCount: totalservicecount && totalservicecount, partReplace: partreplacements && partreplacements.length });
    }, [totalservicecount, partreplacements]);

    useEffect(() => {
        setData({ ...data, serviceReplacements: [] });
        var serviceReplacementsList = [];
        items.map(x => {
            !x.isDel &&
            serviceReplacementsList.push({
                _id: x._id,
                registDate: x.createDate,
                warrantyYN: x.createDate <= (productInfo.waranties[0] && moment.utc(productInfo.waranties.filter(x => x.dest == "warproduct_product")[0].period).local()) ? "IN" : "OUT",
                partscost: "",
                laborcost: "",
                vat: "",
                estimate: "",
                parts: x.replacements && x.replacements.map(y => `${y.title} (${y.oldsn} -> ${y.newsn})\n`),
                etc: "",
                proofnumber: ""
            });
        });

        setData({
            ...data,
            errCount: productInfo.errs.length,
            userReplace: productInfo.sitehistories && productInfo.sitehistories.length,
            snReplace: productInfo.snhistories && productInfo.snhistories.length,
            customerFirstName: sites.customerFirstName,
            address: sites.address,
            productYear: productInfo.manufactured && productInfo.manufactured,
            warrantyPeriod: productInfo.waranties[0] && getLocalTime(productInfo.waranties.filter(x => x.dest == "warproduct_product")[0].period),
            installedDate: productInfo.installedDate && getLocalTime(productInfo.installedDate),
            snhistorielist: [...productInfo.snhistories],
            sitename: productInfo.sitename,
            model: productInfo.model && productInfo.model,
            option: productInfo.option && productInfo.option,
            sn: productInfo.sn && productInfo.sn,
            serviceReplacements: serviceReplacementsList,
            contact: sites.company && sites.company.contactTel && sites.company.contactTel,
            usePeriod: moment.utc(productInfo.installedDate).local().fromNow()
        });
    }, [items]);

    const getLocalTime = (paramdate) => {
        return paramdate && moment.utc(paramdate).local().format("YYYY-MM-DD");
    }

    const rowSelected = (_id) => {
        if (productInfo.historyReport) {
            setSelectedRow(_id);
            const selectedReport = productInfo.historyReport.filter(x => x._id == _id);
            setData(selectedReport[0]);
        }
    }

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const handelAddServicePartList = () => {
        setData({
            ...data, serviceReplacements: [...data.serviceReplacements
                , {
                _id: data.serviceReplacements.length.toString(),
                registDate: "",
                warrantyYN: "",
                partscost: "",
                laborcost: "",
                vat: "",
                estimate: "",
                parts: "",
                etc: "",
                proofnumber: ""
            }
            ]
        });
    }

    const handleDelServicePartList = (id) => {
        const rows = [...data.serviceReplacements];
        rows.splice(id, 1);
        setData({ ...data, serviceReplacements: rows });
    }

    const handleAddSNHistorielist = () => {
        setData({
            ...data, snhistorielist: [...data.snhistorielist, {
                _id: data.snhistorielist.length.toString(),
                updated: new Date(),
                sn: "",
                user: "",
                reason: "",
            }]
        });
    }

    const handleDelSNHistorielist = (id) => {
        const delsnhistoriylist = data.snhistorielist.filter(x => x._id !== id);
        setData({ ...data, snhistorielist: delsnhistoriylist });
    }

    const handleUpdateServicePartList = (e, index) => {
        const { name, value } = e.target;
        let newArr = [...data.serviceReplacements];
        newArr[index] = { ...newArr[index], [name]: value };
        setData({ ...data, serviceReplacements: newArr });
    }

    const handleUpdateSNHistorielist = (e, index) => {
        const { name, value } = e.target;
        let newArr = [...data.snhistorielist];
        newArr[index] = { ...newArr[index], [name]: value };
        setData({ ...data, snhistorielist: newArr });
    }

    const handleUpdateDate = (e, name, index) => {
        if (name == "updated") {
            handleUpdateSNHistorielist({ target: { name: name, value: e.getTime() } }, index);
        } else {
            handleUpdateServicePartList({ target: { name: name, value: e.getTime() } }, index);
        }
    }

    const validSave = () => {
        UpdateProductHistoryReport(productInfo.sn, data);
    };

    const contents_cell_input = {
        width: '90%',
        border: '0px',
        height: '40px'
    };

    const form_controls = [
        {
            title: "고객 정보", items: [
                {
                    type: "input", name: "sitename", label: "병원명",
                    val: data.sitename, isEditable: true
                },
                {
                    type: "input", name: "customerFirstName", label: "고객명",
                    val: data.customerFirstName, isEditable: true
                },
                {
                    type: "input", name: "contact", label: "연락처",
                    val: data.contact, isEditable: true
                },
                {
                    type: "input", name: "address", label: "주소",
                    val: data.address, isEditable: true
                }
            ]
        },
        {
            title: "제품 정보", items: [
                {
                    type: "input", name: "model", label: "모델명",
                    val: data.model, isEditable: true
                },
                {
                    type: "input", name: "option", label: "제품 옵션",
                    val: data.option, isEditable: true
                },
                {
                    type: "input", name: "sn", label: "제품 시리얼 번호",
                    val: data.sn, isEditable: true
                },
                {
                    type: "input", name: "serviceUser", label: "서비스 담당자",
                    val: data.serviceUser, isEditable: true
                },
                {
                    type: "input", name: "installedDate", label: "설치 일자",
                    val: data.installedDate, isEditable: true
                },
                {
                    type: "input", name: "warrantyPeriod", label: "품질보증 만료일",
                    val: data.warrantyPeriod, isEditable: true
                },
            ]
        },
        {
            title: "촬영 모달리티", items: [
                {
                    type: "checkbox", name: "pano",
                    isEditable: true, onlyCheck: true, val: data.pano || false,
                    label: "파노"
                },
                {
                    type: "checkbox", name: "ct",
                    isEditable: true, onlyCheck: true, val: data.ct || false,
                    label: "CT"
                },
                {
                    type: "checkbox", name: "ceph",
                    isEditable: true, onlyCheck: true, val: data.ceph || false,
                    label: "세팔로"
                },
                {
                    type: "checkbox", name: "dr",
                    isEditable: true, onlyCheck: true, val: data.dr || false,
                    label: "DR"
                },
            ]
        },
        {
            title: "FOV (CT)", items: [
                {
                    type: "checkbox", name: "nineninecm",
                    isEditable: true, onlyCheck: true, val: data.nineninecm || false,
                    label: "9x9cm"
                },
                {
                    type: "checkbox", name: "tentencm",
                    isEditable: true, onlyCheck: true, val: data.tentencm || false,
                    label: "10x10cm"
                },
                {
                    type: "checkbox", name: "thirteencm",
                    isEditable: true, onlyCheck: true, val: data.thirteencm || false,
                    label: "4x3cm~13x10cm"
                },
                {
                    type: "checkbox", name: "sixteencm",
                    isEditable: true, onlyCheck: true, val: data.sixteencm || false,
                    label: "4x3cm~16x10cm"
                },
                {
                    type: "checkbox", name: "twentycm",
                    isEditable: true, onlyCheck: true, val: data.twentycm || false,
                    label: "4x3cm~20x10cm"
                }
            ]
        },
        {
            title: "ROI (Ceph / DR)", items: [
                {
                    type: "checkbox", name: "sctwentytwo",
                    isEditable: true, onlyCheck: true, val: data.sctwentytwo || false,
                    label: "SC(22.5cm)"
                },
                {
                    type: "checkbox", name: "sctwentyfour",
                    isEditable: true, onlyCheck: true, val: data.sctwentyfour || false,
                    label: "SC(24cm)"
                },
                {
                    type: "checkbox", name: "OSC",
                    isEditable: true, onlyCheck: true, val: data.OSC || false,
                    label: "OSC(30x25cm)"
                },
                {
                    type: "checkbox", name: "OSC",
                    isEditable: true, onlyCheck: true, val: data.OCL || false,
                    label: "OCL(33x33cm)"
                }
            ]
        },
        {
            title: "추가 옵션", items: [
                {
                    type: "checkbox", name: "objectScan",
                    isEditable: true, onlyCheck: true, val: data.objectScan || false,
                    label: "Object Scan"
                },
                {
                    type: "checkbox", name: "webViewer",
                    isEditable: true, onlyCheck: true, val: data.webViewer || false,
                    label: "웹 뷰어"
                }
            ]
        }
    ];

    return (
        <>
            <div className="section m-1 p-0">
                <div className="rayform section mt-1">
                    <div className="section-title" style={{ marginTop: '120px' }}>Product History Report 이력</div>
                    <div className="user-card" style={{ height: '60%', alignSelf: 'stretch', textAlign: 'center' }}>
                        <div>
                            <table className="table table-bordered mb-1">
                                <thead>
                                    <tr>
                                        <th>등록일</th>
                                        <th>고객명</th>
                                        <th>병원명</th>
                                        <th>서비스 담당자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && <tr><td colSpan={4}><Loading /></td></tr>}
                                    {!loading && !productInfo.historyReport && <tr><td colSpan={4} className="text-center"><i>No data</i></td></tr>}
                                    {productInfo.historyReport && productInfo.historyReport.map((x) => {
                                        return (
                                            <tr key={x._id} className="row_hand" style={(selectedRow == x._id) ? { backgroundColor: "royalblue" } : {}} onClick={() => rowSelected(x._id)}>
                                                <td className="text-center p-0" style={{ height: '35px' }}>{x.createDate && getLocalTime(x.createDate)}</td>
                                                <td className="text-center p-0">{x.customerFirstName}</td>
                                                <td className="text-center p-0">{x.sitename}</td>
                                                <td className="text-center p-0">{x.serviceUser}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="rayform section mt-1">
                    <div className="section-title">요약</div>
                    <div className="user-card" style={{ height: '60%', alignSelf: 'stretch', textAlign: 'center', paddingLeft: '4%', paddingRight: '5%' }}>
                        <div className="uc-footer" style={{ marginTop: "15px" }}>
                            <div className="item">
                                <div className="icon-wrapper bg-danger mb-1">
                                    <ion-icon name="close-outline" role="img" className="md hydrated" aria-label="close outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>에러발생</strong>
                                <div style={{ width: '20%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="errCount"
                                        value={data.errCount}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon-wrapper bg-info mb-1">
                                    <ion-icon name="receipt-outline" role="img" className="md hydrated" aria-label="receipt outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>서비스처리</strong>
                                <div style={{ width: '20%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="serviceCount"
                                        value={data.serviceCount}
                                        onChange={handleChangeData} />
                                </div>
                                <div style={{ width: '30%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="serviceCharge"
                                        value={data.serviceCharge}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon-wrapper bg-warning mb-1">
                                    <ion-icon name="settings-outline" role="img" className="md hydrated" aria-label="alert outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>파트교체</strong>
                                <div style={{ width: '20%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="partReplace"
                                        value={data.partReplace}
                                        onChange={handleChangeData} />
                                </div>
                                <div style={{ width: '30%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="partReplaceCharge"
                                        value={data.partReplaceCharge}
                                        onChange={handleChangeData} />
                                </div>

                            </div>
                            <div className="item">
                                <div className="icon-wrapper bg-warning mb-1">
                                    <ion-icon name="calendar-outline" role="img" className="md hydrated" aria-label="alert outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>사용자 교육</strong>
                                <div style={{ width: '20%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="training"
                                        value={data.training}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                        </div>
                        <div className='uc-footer'></div>
                        <div className='uc-footer'>
                            <div className="item">
                                <div className="icon-wrapper bg-secondary mb-1">
                                    <ion-icon name="calendar-outline" role="img" className="md hydrated" aria-label="calendar outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>형식연도</strong>
                                <div style={{ width: '40%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="productYear"
                                        value={data.productYear && getLocalTime(data.productYear)}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon-wrapper bg-secondary mb-1">
                                    <ion-icon name="settings-outline" role="img" className="md hydrated" aria-label="calendar outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>사용기간</strong>
                                <div style={{ width: '40%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="usePeriod"
                                        value={data.usePeriod}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon-wrapper bg-secondary mb-1">
                                    <ion-icon name="documents-outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>소유자 변경</strong>
                                <div style={{ width: '20%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="userReplace"
                                        value={data.userReplace}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon-wrapper bg-secondary mb-1">
                                    <ion-icon name="documents-outline" style={{ pointerEvents: 'none' }}></ion-icon>
                                </div>
                                <strong>제품 시리얼 변경</strong>
                                <div style={{ width: '20%', margin: 'auto' }}>
                                    <SummaryInput
                                        name="snReplace"
                                        value={data.snReplace}
                                        onChange={handleChangeData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RayForm controls={form_controls} handleChangeData={handleChangeData} />
                <div className="rayform section mt-1">
                    <div className="section-title">서비스 / 파트 교체이력</div>
                    <div className="user-card" style={{ marginTop: '5px', height: '60%', alignSelf: 'stretch', textAlign: 'center' }}>
                        <div className={data.serviceReplacements.length > 10 ? "overflow-auto" : ""} >
                            <table className="table table-bordered mb-1">
                                <thead>
                                    <tr>
                                        <td style={{ width: "40px" }}>
                                            <button className="btn btn-icon btn-info btn-sm me-1" style={{ margin: "0px", height: "20px", width: "20px" }} onClick={() => handelAddServicePartList()}>
                                                <ion-icon name="add-outline"></ion-icon>
                                            </button>
                                        </td>
                                        <th >접수일자</th>
                                        <th >품질보증적용</th>
                                        <th >부품비</th>
                                        <th >공임비</th>
                                        <th >부가세</th>
                                        <th >수리(견적)비용</th>
                                        <th >교체부품</th>
                                        <th >기타</th>
                                        <th >증빙번호</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.serviceReplacements.length == 0 && <tr><td colSpan={10} className="text-center"><i>No data</i></td></tr>}
                                    {data.serviceReplacements.map((x, index) => {
                                        return (
                                            <tr key={index} className="row_hand">
                                                <td style={{ height: "30px" }}>
                                                    <button className="btn btn-icon btn-danger btn-sm me-1" style={{ margin: "0px", height: "20px", width: "20px" }} onClick={() => handleDelServicePartList(index)}>
                                                        <ion-icon name="remove-outline"></ion-icon>
                                                    </button>
                                                </td>
                                                <td className="text-center p-0">
                                                    <DatePicker name="registDate"
                                                        className="form-control"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={x.registDate}
                                                        onChange={(date) => handleUpdateDate(date, "registDate", index)}
                                                        locale="locale" />
                                                </td>
                                                <td className="text-center p-0"><input name="warrantyYN" type={'text'} style={contents_cell_input} value={x.warrantyYN} onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                                <td className="text-center p-0"><input type={'text'} style={contents_cell_input} value={x.partscost} name="partscost" onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                                <td className="text-center p-0"><input type={'text'} style={contents_cell_input} value={x.laborcost} name="laborcost" onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                                <td className="text-center p-0"><input type={'text'} style={contents_cell_input} value={x.vat} name="vat" onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                                <td className="text-center p-0"><input type={'text'} style={contents_cell_input} value={x.estimate} name="estimate" onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                                <td className="text-center p-0"><textarea type={'text'} style={{ overflow: "hidden", border: "0px" }} value={x.parts} name="parts" onChange={(e) => handleUpdateServicePartList(e, index)} ></textarea></td>
                                                <td className="text-center p-0"><input type={'text'} style={contents_cell_input} value={x.etc} name="etc" onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                                <td className="text-center p-0"><input type={'text'} style={contents_cell_input} value={x.proofnumber} name="proofnumber" onChange={(e) => handleUpdateServicePartList(e, index)} ></input></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="rayform section mt-1">
                    <div className="section-title">소유자 / 제품 시리얼 변경이력</div>
                    <div className="user-card" style={{ zIndex: 0, marginTop: '5px', height: '60%', alignSelf: 'stretch', textAlign: 'center' }}>
                        <div className={data.serviceReplacements.length > 10 ? "overflow-auto" : ""} >
                            <table className="table table-bordered mb-1">
                                <thead>
                                    <tr>
                                        <td style={{ width: "40px" }}>
                                            <button className="btn btn-icon btn-info me-1" style={{ margin: "0px", height: "20px", width: "20px" }} onClick={() => handleAddSNHistorielist()}>
                                                <ion-icon name="add-outline"></ion-icon>
                                            </button>
                                        </td>
                                        <th >변경 등록일</th>
                                        <th >제품 시리얼번호 변경</th>
                                        <th >소유자 변경</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.snhistorielist.length == 0 && <tr><td colSpan={4} className="text-center"><i>No data</i></td></tr>}
                                    {data.snhistorielist.map((x, index) => {
                                        return (
                                            <tr key={index} className="row_hand">
                                                <td>
                                                    <button className="btn btn-icon btn-danger btn-sm me-1" style={{ margin: "0px", height: "20px", width: "20px" }} onClick={() => handleDelSNHistorielist(x._id)}>
                                                        <ion-icon name="remove-outline"></ion-icon>
                                                    </button>
                                                </td>
                                                <td className="text-center p-0">
                                                    <DatePicker name="registDate"
                                                        className="form-control"
                                                        popperClassName="date-picker-reports"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={x.updated}
                                                        onChange={(date) => handleUpdateDate(date, "updated", index)}
                                                        locale="locale" />
                                                </td>
                                                <td className="text-center p-0"><input type={'text'} name="sn" style={contents_cell_input} value={x.sn} onChange={(e) => handleUpdateSNHistorielist(e, index)} ></input></td>
                                                <td className="text-center p-0"><input type={'text'} name="user" style={contents_cell_input} value={x.user && x.user} onChange={(e) => handleUpdateSNHistorielist(e, index)} ></input></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div>
                        <button className="btn btn-primary btn-block btn-lg" onClick={() => validSave()}>저장</button>
                    </div>
                    <div>
                        <ExportPdfReport reportType={'ProductHistory'} infos={data} />
                    </div>
                </div>

            </div>
        </>
    )
}


const SummaryInput = ({ name, value, onChange }) => {
    return (
        <>
            <div className="form-group basic">
                <div className="input-wrapper">
                    <input
                        type="text"
                        placeholder={""}
                        className="form-control"
                        name={name}
                        autoComplete="off"
                        value={value}
                        onChange={onChange}
                        style={{ textAlign: "right" }}
                    />
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    var items = state.ServiceReducer.prdservices;
    var productInfo = state.ProductReducer.product;
    var totalservicecount = state.ServiceReducer.totalservicecount;
    var sites = state.SiteReducer.site;
    var partreplacements = state.ServiceReducer.partreplacements;

    return { items, productInfo, totalservicecount, sites, partreplacements };
};

const mapDispatch = (dispatch) => ({
    GetServiceBySN: (sn => dispatch(ServiceAction.GetServiceBySN(sn))),
    GetSite: (_id => dispatch(SiteAction.GetSite(_id))),
    GetServiceReplacementsBySN: (sn => dispatch(ServiceAction.GetServiceReplacementsBySN(sn))),
    UpdateProductHistoryReport: (sn, data) => dispatch(ProductAction.UpdateProductHistoryReport(sn, data))
});

export default connect(mapState, mapDispatch)(ProductHistoryReport);