import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminUserAction } from '../../actions/AdminUserAction';

const viewText = (r, options, list = null) => {
    var rList = [];
    if (r) {
        if (options.viewLinkPath) {
            var linkId = options.val;
            if (list) {
                const item = list.filter(x => x.adminid == linkId);
                linkId = item.length > 0 ? item[0]._id : linkId;
            }
            rList.push(<Link to={options.viewLinkPath + linkId}>{r}</Link>);
        } else {
            rList.push(r);
        }
    } else {
        rList.push(options.startval || r);
    }
    return rList;
}

const InputLabelUser = ({ users, GetUserByAdminId, showOnly, options }) => {

    const [label, setLabel] = useState([]);

    useEffect(() => {
        if (options.val) {
            const u = users.filter(x => x.adminid == options.val);
            if (u.length == 0) {
                console.log(options);
                GetUserByAdminId(options.val);
            } else {
                setLabel(viewText(options.val, options, users));
            }
        }
    }, [options.val]);

    useEffect(() => {
        if (options.val) {
            setLabel(viewText(options.val, options, users));
        }
    }, [users]);

    return (
        <div className={`col-md-${options.size ? options.size : "6"}`}>
            <div className="form-group form-group-default b-transparent m-b-0" aria-required="true">
                <label className={showOnly ? " fs-12 " : ""}>{options.label}</label>
                <span className={showOnly ? "fs-16" : ""}>{label}</span>
            </div>
        </div>
    )
}

const mapState = (state) => {
    const users = state.AdminUserReducer.users;
    return { users };
}

const mapDispatch = dispatch => ({
    GetUserByAdminId: (adminid) => dispatch(AdminUserAction.GetUserInfoByAdminId(adminid)),
})

export default connect(mapState, mapDispatch)(InputLabelUser);