import { CalendarConstant } from '../actions/constants/CalendarConstant';

const initialState = {
	items : [],
	item : {},
    isLoading : false,
	totalproductcount: 0,
};

export const CalendarReducer = (state = initialState, action) => {
	switch (action.type) {
		case CalendarConstant.GET_SCHLIST:
			return { ...state, items : [...action.items] }
		case CalendarConstant.SET_NEW:
			return { ...state, item : {...action.item} }
		case CalendarConstant.UPDATE_SCH:
		case CalendarConstant.GET_SCH:
			return {
				...state,
				items: state.items.map((u) => (u._id == action.item._id ? action.item : u)),
				item: action.item,
			};
		case CalendarConstant.CREATE_SCH:
			return {
				...state,
				items : [...state.items, action.item],
				item: action.item,
			};
		case CalendarConstant.DELETE_SCH:
			return { ...state, items: state.items.filter((u) => u._id !== action._id), item : {}};
        case CalendarConstant.LOADING:
            return {...state, isLoading : true}
        case CalendarConstant.LOADED:
            return {...state, isLoading : false}
		default:
			return state;
	}
};
