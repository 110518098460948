import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoginFooter } from '../../components/common/CommonViews';
import { history } from '../../history';
import { AlertAction, AuthAction } from '../../actions';

const ForgotPassword = ({ AlertError, AlertOk, isAlertClose }) => {

    const [ t ] = useTranslation();
    const [ sessionKey, setSessionKey] = useState("");
    const [ updateOk, setUpdateOk] = useState(false);
    const [ inputs, setInputs ] = useState({ password : "", passwordCheck : "" });
    const { password, passwordCheck } = inputs;

    useEffect(() => { 
        document.body.className = "animsition page-forgot-password layout-full";
        getUrlKey();
        if(isAlertClose && updateOk){
            history.push("/Login");
        }
    }, [isAlertClose]);

    const getUrlKey = () => {
        var nKey = location.search;
        if(nKey.indexOf('key=') == -1){
            AlertError("Invalid URL"); return;
        }
        if(nKey.replace('?key=', '').length != 10){
            AlertError("Invalid URL"); return;
        }
        setSessionKey(nKey.replace('?key=', ''));
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleButtonClick = () => {
        if(password != passwordCheck){
            AlertError("Your new password must be different from the current password");
        }else{
            AuthAction.UpdatePasswordByForgot({...inputs, key : sessionKey}).then((ret) => {
                console.log(ret);
                if(ret.status == "success"){
                    setUpdateOk(true);
                    AlertOk("OK!");
                }else{
                    setUpdateOk(false);
                    AlertError(ret.err);
                }
            });
        }
    }

    return (
        <div className="page vertical-align text-center">
            <div className="page-content vertical-align-middle">
                <h2>{t('resetpassword')}</h2>
                <p>Please enter your new password.</p>
                <form method="post" role="form" autoComplete="off">
                    <div className="form-group form-material floating" data-plugin="formMaterial">
                        <input type="password" className="form-control empty" value={password} name="password" onChange={handleInputChange} />
                        <label className="floating-label">{t('password')}</label>
                    </div>
                    <div className="form-group form-material floating" data-plugin="formMaterial">
                        <input type="password" className="form-control empty" value={passwordCheck} name="passwordCheck" onChange={handleInputChange} />
                        <label className="floating-label">{t('retypepassword')}</label>
                    </div>
                    <div className="form-group">
                        <button type="button"
                            onClick={handleButtonClick}
                            className="btn btn-primary btn-block waves-effect waves-classic">{t('resetpassword')}</button>
                    </div>
                </form>
                <p><Link to="/Login">Back to login</Link></p>
                <LoginFooter css="page-copyright" />
            </div>
        </div>
    )
}

const mapState = state => {
    return{
        isAlertClose : state.AlertReducer.isClose
    };
}

const mapDispatch = dispatch => ({
    AlertError : message => dispatch(AlertAction.Error(message)),
    AlertOk : message => dispatch(AlertAction.Success(message)),
})

export default connect(mapState, mapDispatch)(ForgotPassword);