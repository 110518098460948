import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ServiceAction } from '../../actions';
import ServiceReplacementAddList from './ServiceReplacementAddList';
import { Translate as t } from '../../config/Translate';

const ServiceReplacement = ({ service, UpdateService, callback, onlyContents = false }) => {
	const [list, setList] = useState([]);
	const [hide, setHide] = useState(true);
	const [model, setModel] = useState("");

	useEffect(() => {
		if (!service.replacements) return;
		setList([...service.replacements]);
		setModel(service.product.model);
	}, [service])

	const updateList = (list) => {
		UpdateService(service._id, { replacements: list });
	}

	return (
		<div>
			{onlyContents &&
				<div className="card">
					<div className="card-body">
						<ServiceReplacementAddList callback={(list) => updateList(list)} items={list} model={model} />
					</div>
				</div>
			}
			{!onlyContents && <>
				<div className="card-header" onClick={() => setHide(!hide)}>
					{t('partreplaced')}
					<div className="float-end ms-2">
						<ion-icon name={hide ? "chevron-down-outline" : "chevron-up-outline"}></ion-icon>
					</div>
					{hide && <div className="float-end">
						<span className="badge badge-primary">{list.length}</span>
					</div>}
				</div>
				{!hide && <div className="card-body">
					<ServiceReplacementAddList callback={(list) => updateList(list)} items={list} model={model} />
				</div>}
			</>}
		</div>
	);
};

const mapState = (state) => {
	var service = state.ServiceReducer.service;
	return { service };
};

const mapDispatch = (dispatch) => ({
	UpdateService: (_id, info) => dispatch(ServiceAction.UpdateService(_id, info))
});

export default connect(mapState, mapDispatch)(ServiceReplacement);