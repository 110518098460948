import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import 'react-swipeable-list/dist/styles.css';
import { NoRecord } from '../../pages/HomePage';
import ProductDOAModal from '../../components/product/ProductDOAModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ProductDOA = ({ product, _sn }) => {
	// const [t] = useTranslation();
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [doaView, setDoaView] = useState(false);
	const [list, setList] = useState([]);

	useEffect(() => {
		setList(product.doalogs ? [...product.doalogs].sort((a, b) => b.timestamp - a.timestamp) : []);
	}, [product]);

	const getDot = type => {
		return type ? "dot bg-danger" : "dot bg-primary";
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(
					<React.Fragment key={idx}>
						{line} <br />
					</React.Fragment>
				);
			});
		} else {
			return 'N/A';
		}
		return html;
	};

	const getParts = (item) => {
		if (item.parts) {
			return (
				<>
					{item.parts.map(x =>
						<div className="chip chip-media me-1 mb-1">
							<i className="chip-icon bg-warning">
								<ion-icon name="build" role="img" className="md hydrated" aria-label="chatbubble"></ion-icon>
							</i>
							<span className="chip-label">{x.title} / {x.subtitle}</span>
						</div>)}
				</>)
		} else {
			return "";
		}
	}

	return (
		<>
			{list.length > 0 &&
				<div className="section">
					<div className={_sn || "card"}>
						{!product.orisn && <>
							{(!_sn) &&
								<>
									{product.doa === true &&
										<>
											<button className={product.doa ? "btn btn-block btn-primary" : "btn btn-block btn-warning"} onClick={() => setDoaView(true)}>
												{product.doa ? "Clear DOA" : "Check DOA"}
											</button>
											<ProductDOAModal product={product} byprd={true} setDoaView={setDoaView} doaView={doaView} />
										</>}
								</>}
						</>}
						<div className="timeline ms-3" style={{ marginLeft: "4px !important" }}>
							{list.map((item, idx) =>
								<div className="item" key={idx}>
									<div className={getDot(item.doa)}></div>
									<div className="content">
										<div className="title">
											{item.doa && <span className="badge badge-danger">DOA</span>}
											{!item.doa && <span className="badge badge-primary">{t('clear')}</span>}
											{' '}
											{item.user} <span className="text-muted">{moment.utc(item.timestamp).local().fromNow()}{' '}</span>
										</div>
										<div className="text">
											<div>{getParts(item)}</div>
											{item.comment && <i>{nl2br(item.comment)}</i>}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			}
			{list.length === 0 && <>
				<div className="section">
					{product.doa === true &&
						<>
							<button className="btn btn-block btn-primary" onClick={() => setDoaView(true)}>
								{product.doa ? "Clear DOA" : "Check DOA"}
							</button>
							<ProductDOAModal product={product} byprd={true} setDoaView={setDoaView} doaView={doaView} />
						</>}
				</div>
				<NoRecord />
			</>}
		</>
	);
};

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({
	//GetServiceBySN: (sn) => dispatch(ServiceAction.GetServiceBySN(sn)),
});

export default connect(mapState, mapDispatch)(ProductDOA);
