import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayTable, SiteSurveyListHeader } from '../../components/common';
import { SiteSurveyAction, RayCodeAction } from '../../actions';
import { Translate as t } from '../../config/Translate';

const AdminSiteSurveyList = ({ user, GetSiteSurveyList, siteSurveys, totalSiteSurveyCount, filterOptions, filterDateOptions, clist }) => {
	const [allCodeList, setAllCodeList] = useState([]);

	useEffect(() => {
		setAllCodeList(clist?.filter(x => x.type === 'PRODUCTOPTION'));
		// RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" }).then(x => {
		// 	setAllCodeList([...x]);
		// })
	}, [clist])

	const filterpage = {
		filters: {},
		linkto: '/Admin/AdminSiteSurveyInfo',
		linkkey: 'name',
		filterOptions: ['isSubmit'],
		filterDateOptions: filterDateOptions || [
			{ code: 'createDate', label: 'Registerd' },
		],
		sort: { createDate: -1 },
		searchCols: ['siteSitename'],
		searchKey: "",
		page: 1,
		itemperpage: 30
	};

	const getOption = (code) => {
		const o = allCodeList.filter(x => x.code == code);
		return o.length > 0 ? o[0].name : code;
	}

	const genData = () => {
		return siteSurveys.map((u) => {
			//u.siteCountry = getCountryName(u.siteCountry);
			u.customername = u.customerFirstName && u.customerFirstName + ' ' + u.customerLastName;
			u.address = u.siteAddress;
			u.siteSurveyStep = u.isSubmit ? 6 : u.siteSurveyStep;
			u.option = getOption(u.option);
			return u;
		});
	}

	const options = {
		search: true,
		keyCol: "_id",
		linkto: '/Admin/AdminSiteSurveyInfo',
		linkkey: 'siteSitename',
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		filterpageoption: filterpage,
		getList: GetSiteSurveyList,
		useapi: true,
		totalitemcount: totalSiteSurveyCount,
		filterOptions: filterOptions,
		isFalse: true,
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('sitesurveylist')}</h2>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section mt-1 mb-1">
					<div className="wide-block pt-2 pb-2">
						<RayTable
							data={genData()}
							columns={SiteSurveyListHeader}
							options={options}
							listtype={"sitesurvey"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var siteSurveys = state.SiteSurveyReducer.siteSurveys;
	var totalSiteSurveyCount = state.SiteSurveyReducer.totalSiteSurveyCount;
	var filterOptions = state.SiteSurveyReducer.filterOptions;
	var filterDateOptions = state.SiteSurveyReducer.filterDateOptions;
	const clist = state.RayCodeReducer.items;
	return { user, siteSurveys, totalSiteSurveyCount, filterOptions, filterDateOptions, clist };
}

const mapDispatch = dispatch => ({
	GetSiteSurveyList: (filter) => dispatch(SiteSurveyAction.GetSiteSurveyList(filter))
})

export default connect(mapState, mapDispatch)(AdminSiteSurveyList)