
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RayTreeview, RayForm } from '../../components/common';
import { CompanyAction, AdminUserAction } from '../../actions/';
import { AdminPosition } from '../../config/RayCodes';
import { history } from '../../history';
import { Translate as t } from '../../config/Translate';

const CompanyList = ({ user, companies, viewTree }) => {

	const [selList, setSelList] = useState([]);
	const [rowSel, setrowSel] = useState({});
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });
	const [wHg, setwHg] = useState(300);
	const [selectedCompany, setSelectedCompany] = useState({
		item: [{ country: "", name: "", org: "", parentCompanyId: "" }]
	});
	const [selectedParentCompany, setSelectedParentCompany] = useState({
		item: [{ country: "", name: "", contactEmail: "", contactTel: "" }]
	});
	const [distributors, setDistributor] = useState([]);

	const options = {
		search: true,
		keyCol: "companyId",
		linkto: '/Admin/AdminCompanyInfo',
		linkkey: 'name',
		itemperpage: 30,
		filterColumns: [
			{ col: "org", val: "ALL" },
		]
	}

	const checked = (list) => {
		setSelList(list);
	}

	useEffect(() => {
		if (user.companyInfo.companyId) {
			var selectedParentCompanyId = companies.filter(x => x.companyId == user.companyInfo.companyId)[0]?.parentCompanyId;
			setSelectedCompany({
				item: companies.filter(x => x.companyId == user.companyInfo.companyId)
			});

			setSelectedParentCompany({
				item: selectedParentCompanyId && companies.filter(x => x.companyId == selectedParentCompanyId)
			});

			getDistributor(user.companyInfo.companyId);
		}
	}, []);

	useEffect(() => {
		setSelectedCompany({
			item: companies.filter(x => x.companyId == user.companyId)
		});
	}, [companies]);

	const closeCallback = () => {
		setrowSel({});
	}

	const genTreeviewData = () => {
		const treeNode = []
		treeNode.push({ id: "0", name: "ALL", parent: "" })
		companies.map((u) => {
			treeNode.push({
				id: u.companyId,
				name: u.name,
				parent: u.parentCompanyId || "0"
			});
		});
		return treeNode;
	}

	const getDistributor = async (companyId) => {
		const distributor = []
		const companyUsers = await AdminUserAction.GetUserListByCompanyId(companyId);
		companyUsers.map((u) => {
			distributor.push({
				type: u.position, name: u.firstName + ' ' + u.lastName, label: "test", val: u.email
			});
		});

		setDistributor(distributor);
	}

	const editable = false;
	const onChangeTreeviewItem = async (item) => {
		if (item) {
			setFilterCompanyId({ companyId: item.id });

			var selectedParentCompanyId = companies.filter(x => x.companyId == item.id)[0]?.parentCompanyId;

			setSelectedCompany({
				item: companies.filter(x => x.companyId == item.id)
			});

			setSelectedParentCompany({
				item: selectedParentCompanyId && companies.filter(x => x.companyId == selectedParentCompanyId)
			});
			setFilterCompany(item);
			getDistributor(item.id);
		}
	}

	const updateViewTree = (bl) => {
		//dispatch({type : ProgramConstant.VIEWTREE, val : bl});
	}

	const companyEdit = (ce) => {
		if (!ce) {
			alert('Please select a company that you want to edit.');
		}
		else {
			history.push("/Admin/AdminCompanyInfo/" + ce);
		}
	}

	const form_controls = [
		{
			title: t('companyinformation'), items: [
				{ type: "input", name: "country", label: t('country'), val: companies && (selectedCompany.item[0] && selectedCompany.item[0].country) },
				{ type: "input", name: "name", label: t('organization'), val: companies && (selectedCompany.item[0] && selectedCompany.item[0].name) },
				{ type: "input", name: "parentname", label: "Parent Organization", val: companies && (selectedParentCompany.item && selectedParentCompany.item[0]?.name) },
				{ type: "input", name: "phone", label: t('tel'), val: companies && (selectedCompany.item[0] && selectedCompany.item[0].ownerhp) },
				{ type: "input", name: "address", label: t('address'), val: companies && (selectedCompany.item[0] && selectedCompany.item[0].address) }
			]
		}
	]

	const form_controls_hq =
		!selectedCompany.item[0]?.parentCompanyId == "" ? [{
			title: "Internal (Branch) Contact", items: [
				{ type: "input", name: "Email", label: t('email'), val: "Branch Contact" },
				{ type: "input", name: "Tel", label: t('tel'), val: selectedCompany.item[0].contactTel }
			]
		}
		] : [{
			title: t('hqcontact'), items: [
				{ type: "input", name: "csEmail", label: 'CS', val: "ray_cs@raymedical.co.kr" },
				{ type: "input", name: "serviceEmail", label: 'Sales', val: "ray_sales@raymedical.co.kr" }
			]
		}
		]

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0" style={{ marginRight: '10px' }}>{t('companylist')}</h2>
				{
					companies.length > 1 && user.adminType != 'U' && <button className='btn btn-secondary btn-sm mt-0 ms-1' style={{ marginRight: '10px' }} onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>
				}

				{
					user.adminType != 'U' &&
					<>
						<button className="btn btn-primary btn-sm mt-0 me-1" onClick={() => companyEdit(filterCompany.id)}>{t('editcompany')}</button>
						<div className="ray-right">
							<Link to="/Admin/AdminCompanyAdd" className="btn btn-primary btn-sm mt-0">
								<ion-icon name="add-outline"></ion-icon>
								{t('addcompany')}
							</Link>
						</div>
					</>
				}
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{
					user.adminType != "U" &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>
				}
				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<RayForm controls={form_controls} nocard={false}></RayForm>
						<div className="rayform section mt-1">
							<div className="section-title">{t('distributorcontact')}</div>
							<div className="card">
								<div className="card-body pb-1 pt-1">
									<>
										<ul className="listview simple-listview no-space border-0">
											{distributors.length > 0 && (
												distributors.map((item, idx) => {
													return (
														<div className="form-group basic">
															<div className="input-wrapper">
																<label className="label">
																	{AdminPosition.filter(x => x.code == item.type)[0] ? AdminPosition.filter(x => x.code == item.type)[0].title : t('staff')}
																</label>
																<div className="form-content multi">({item.name})  {item.val}</div>
															</div>
														</div>
													)
												})
											)}
											{distributors.length == 0 && (
												<div><i className='p-1'>Distributor Contact does not exist.</i></div>
											)}
										</ul>
									</>
								</div>
							</div>
						</div>

					</div>
					<div className="col">
						<RayForm controls={form_controls_hq} nocard={false}></RayForm>
					</div>
				</div>

				<div className="section mt-1 mb-1" style={{ display: 'none' }}>
					<div className="wide-block pt-2 pb-2 row">
						<div className="col-md-3">
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var companies = state.CompanyReducer.companies;
	var filter = state.CompanyReducer.filter;
	var viewTree = state.ProgramReducer.viewtree;
	return { user, companies, filter, viewTree };
}

const mapDispatch = dispatch => ({
	//GetCompany: () => dispatch(CompanyAction.GetCompany())
})

export default connect(mapState, mapDispatch)(CompanyList);