import { RayCodeConstant } from '../actions/constants/RayCodeConstant';

const initialState = {
	items : [],
	item : {},
    isLoading : false,
	totalproductcount: 0,
};

export const RayCodeReducer = (state = initialState, action) => {
	switch (action.type) {
		case RayCodeConstant.GET_RAYCODELIST:
			return { ...state, items : [...action.items] }
		case RayCodeConstant.UPDATE_RAYCODE:
		case RayCodeConstant.GET_RAYCODE:
			return {
				...state,
				items: state.items.map((u) => (u._id == action.item._id ? action.item : u)),
				item: action.item,
			};
		case RayCodeConstant.CREATE_RAYCODE:
			return {
				...state,
				items : [...state.items, action.item],
				item: action.item,
			};
		case RayCodeConstant.DELETE_RAYCODE:
			return { ...state, items: state.items.filter((u) => u._id !== action._id), item : {}};
		case RayCodeConstant.ADDUPDATE:
			return { ...state, item: action.item };
        case RayCodeConstant.LOADING:
            return {...state, isLoading : true}
        case RayCodeConstant.LOADED:
            return {...state, isLoading : false}
		default:
			return state;
	}
};
