import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history } from '../../history';
import { EMSLogButton, S3DownloadButton } from './Buttons';
import RayPagination from './RayPagination';
import { isDIM, isDOA, isCon, isNEW } from './CommonViews';
import { CountryCode } from '../../config/CountryCode';
import { LanguageCode } from '../../config/LanguageCode';
import { Loading } from './Loading';
import { ServiceStatusChip } from '../service/ServiceCard';
import { InputDateFilter } from "./Inputs";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import RayTableExport from './RayTableExport';
import { ExportExcelClass } from '../common/Exports';
import { Translate as t } from '../../config/Translate';

const RayTable = ({
	user,
	su = false,
	companies,
	title,
	columns,
	css = "",
	dsfilterCount = 0,
	onlytable = false,
	data,
	calllback,
	exheader = [],
	parentData = null,
	options = {
		filterpageoption: {},
		filterDateOptions: [],
		getList: null,
		responseList: [],
		exheader: [],
		useapi: false,
		linkto: '',
		updateCurPage: null,
		updateFilters: null,
		updateSearchKey: null,
		totalitemcount: 0,
		filters: {},
		filterOptions: [],
		isFalse: false,
	},
	apiPath = "",
	addFilter = "",
	rdpage = "",
	match = "",
	clist,
	nowuserfilter = {},
	exportlocalaction = "",
	conSearch = null,
	bulkActionDo = null,
	bulkActions = [],
	extendFilters = [],
	rowSelectCallback,
	checkBoxCallback,
	listtype = "",
	toggle = null,
	toggleval,
	callbackchangeuserfilter = null,
	ufilters,
	showmodel,
	payloadCallback = null
}) => {
	const [nowData, setNowData] = useState([]);
	const [userfilter, setUserFilter] = useState([]);
	const [nowfilter, setNowFilter] = useState({});
	const [loading, setLoading] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(user.settings ? (user.settings.dashboardtablelistcount || 30) : 30);
	const [allItemCount, setAllItemCount] = useState(options.totalitemcount || 0);
	const [currentPage, setCurrentPage] = useState(1);
	const [skey, setSkey] = useState('');
	const [showExportList, setShowExportList] = useState(false);
	const [sort, setSort] = useState(options.defaultSort ? options.defaultSort : '');
	const [checkList, setCheckList] = useState([]);
	const [sortType, setSortType] = useState(options.defaultSortType ? options.defaultSortType : -1);
	const [filters, setFilters] = useState(options.filterColumns || []);
	const [datefilters, setDateFilters] = useState(
		options.filterpageoption ? (
			options.filterpageoption.hasOwnProperty("filterDateOptions") ?
				options.filterpageoption.filterDateOptions : []
		) : []
	);
	const [filterpage, setfilterpage] = useState(options.filterpageoption || {});
	const [exportData, setExportData] = useState({});
	const [isFirst, setIsFirst] = useState(true);
	const [columnsReady, setColumesReady] = useState(false);

	useEffect(() => {
		var newCols = [];
		var nowCols = JSON.parse(JSON.stringify(columns));

		if (nowData)
			var newDatas = JSON.parse(JSON.stringify(nowData));

		nowCols.map(x => {
			if (x.type != 'BUTTON') {
				if (x.name.indexOf(".") > 0) {
					const newKey = x.name.replace(".", "");
					const keyarr = x.name.split(".");
					newDatas?.map((y) => {
						try {
							y[newKey] = y[keyarr[0]][keyarr[1]];
						} catch { }
						return y
					})
					newCols.push({ ...x, name: newKey })
				}
				else {
					newCols.push(x)
				}
			}
		})
		newCols.map(x => {
			if (x.type == "DT" || x.type == "DATETIME") {
				newDatas?.map(y => {
					y[x.name] = moment(y[x.name]).local().format('YYYY-MM-DD HH:mm:ss');
					return y
				})
			}
			if (x.type == "COMPANY" && companies.length > 0) {
				newDatas?.map(y => {
					var cid = companies.filter(n => n.companyId == y[x.name]);
					if (cid.length > 0) {
						y[x.name] = cid[0].name
					}
					return y;
				})
			}
			if (x.type == 'ARRAYLIST') {
				newDatas?.map(y => {
					y[x.name] = y[x.name].toString();
					return y;
				})
			}
		})

		var url = window.location.href;
		var head = url.split("/")[url.split("/").length - 1];

		let exportExcelData = newDatas?.filter(x => {
			if (checkList.length > 0 && checkList.includes(x[options.keyCol]))
				return x;
		});

		let col = newCols;

		if ((user.companyId === 'RAY00001' || user.adminid === 'jeongho.lee') && head === 'AdminServiceList') {
			exportExcelData = exportExcelData.map(x => {
				return (
					{
						// ticketKey: x.ticketKey,
						ticketKey: moment(x.createDate).format("YYYYMMDD"),
						model: x.model,
						option: x.option,
						country: countryName(x.site?.country),
						sn: x.sn,
						install: x.installedDate > 1065398400000 ? 'Y' : 'N',
						installStartDate: x.installedDate > 1065398400000 ? moment(x.installedDate).format("YYYY-MM-DD") : '',
						installConfirmDate: x.installedDate > 1065398400000 ? moment(x.installedDate).format("YYYY-MM-DD") : '',
						claim: x.summary,
						processContents: x.description,
						submitted: x.creator,
						assigned: x.assignee,
						effectiveDate: x?.effectiveDate ? moment(x.effectiveDate).format('YYYY-MM-DD HH:mm:ss') : '',
						closedDate: x?.closedDate > 0 ? moment(x.closedDate).format('YYYY-MM-DD HH:mm:ss') : '',
						modifyDate: '',
						flag: '',
						status: x.status,
						doadim: (x.doa && 'DOA' || x.dim && 'DIM') || '',
						csType: '',
						grade: '',
						deferReason: '',
						partsReplaces: x.replacementsCNT > 0 ? '교체' : '미교체',
						replacedParts: x?.orgReplacement ? [...new Set(x.orgReplacement.map(x => x.title + ((x?.oldsn || x?.newsn) && ' (' + x?.oldsn + ' => ' + x?.newsn + ')')))].toString() : '',
						chargeOrFree: '',
						invalidRecovery: '',
						sitename: x.sitesitename,
					}
				)
			});

			col = [
				{ name: 'ticketKey', label: '고불처ID' },
				{ name: 'model', label: '구분' },
				{ name: 'option', label: 'Option' },
				{ name: 'country', label: '접수국가' },
				{ name: 'sn', label: 'S/N' },
				{ name: 'sitename', label: '치과명' },
				{ name: 'install', label: 'Install' },
				{ name: 'installStartDate', label: '설치시작일' },
				{ name: 'installConfirmDate', label: '설치확정일' },
				{ name: 'claim', label: '고객불만내용' },
				{ name: 'processContents', label: '처리이력' },
				{ name: 'submitted', label: '접수자' },
				{ name: 'assigned', label: '처리자' },
				{ name: 'effectiveDate', label: '발생일' },
				{ name: 'closedDate', label: '완료일' },
				{ name: 'modifyDate', label: 'Modified' },
				{ name: 'flag', label: 'Flag' },
				{ name: 'status', label: 'Status' },
				{ name: 'doadim', label: '초기불량(DOA/DIM)' },
				{ name: 'csType', label: 'CS구분' },
				{ name: 'grade', label: '분류등급' },
				{ name: 'deferReason', label: '보류사유' },
				{ name: 'partsReplaces', label: '부품교체' },
				{ name: 'replacedParts', label: '교체부품' },
				{ name: 'chargeOrFree', label: '유/무상' },
				{ name: 'invalidRecovery', label: '부적합회수' },
			];
		}

		// setExportData({
		// 	items: exportExcelData,
		// 	css: "btn btn-secondary m-0",
		// 	filename: head.replace("Admin", "").replace("List", " List") + " - Download - " + moment().format("YYYYMMDDhhmmss"),
		// 	sheettitle: "Sheet",
		// 	headers: col
		// });
	}, [nowData, options]);

	useEffect(() => {
		setUserFilter(ufilters || []);
	}, [ufilters]);

	useEffect(() => {
		var projects = {};
		columns.map(x => projects[x.name] = 1);
		if (listtype == "product")
			projects = { ...projects, dim: 1, doa: 1, isConnected: 1 }
		if (listtype == "producterr")
			projects = { ...projects, dim: 1, doa: 1, isConnected: 1, 'errs._id': 1 }
		if (listtype == "site")
			projects = {
				...projects,
				customerFirstName: 1, customerLastName: 1, address: 1, addressExtra: 1, sns : 1,
				customerEmail: 1, email: 1, hp: 1, tel: 1, fax: 1, customerHp: 1, customerTel: 1, customerFax: 1
			}
		if (listtype == "sitesurvey")
			projects = { ...projects, customerFirstName: 1, customerLastName: 1 }

		if (listtype == "service")
			projects = { ...projects, dim: 1, doa: 1, installedDate: 1, effectiveDate: 1, sites: 1, createDate: 1, closedDate: 1, description: 1 }

		setColumesReady(true);
		setfilterpage({ ...filterpage, projects: projects });
	}, [columns]);

	useEffect(() => {
		console.log(filterpage, showmodel);
		if (options.getList && columnsReady) {
			setLoading(true);
			if (toggle && !filterpage.hasOwnProperty("match")) {
				var to = toggle.filter(x => x.code == toggleval);
				options.getList({ ...filterpage, match: to[0].filter });
			} else {
				payloadCallback && payloadCallback(filterpage);
				options.getList(filterpage);
			}
		}
	}, [filterpage, showmodel]);

	useEffect(() => {
		setAllItemCount(options.totalitemcount);
	}, [options.totalitemcount]);

	useEffect(() => {
		if (extendFilters.length > 0) {
			setfilterpage({ ...filterpage, extends: extendFilters, page: extendFilters.resetPage ? 1 : filterpage.page });
		}
	}, [extendFilters]);

	useEffect(() => {
		if (isFirst)
			return;
		if (options.useapi) {
			setfilterpage({ ...filterpage, match: match, page: 1 });
		}
	}, [match]);

	//addFilter는 일단 companyId로만 하는걸로 한다.
	useEffect(() => {
		if (!addFilter.hasOwnProperty("companyId"))
			return;

		if (options.useapi)
			setfilterpage({ ...filterpage, companyId: addFilter.companyId, page: 1 });
		else
			makeDataList();
	}, [addFilter]);

	useEffect(() => {
		setToggle(toggleval);
	}, [toggleval]);

	useEffect(() => {
		const newFilter = [];
		if (!options.filterOptions) return;
		options.filterOptions.map((x) => {
			newFilter.push({
				col: x.name,
				val: x.val || 'ALL',
				options: x.list.map((y) => y._id),
			});
		});

		setFilters(newFilter);
	}, [options.filterOptions]);

	useEffect(() => {
		if (options.useapi) {
			if (isFirst) {
				setIsFirst(false);
			} else {
				setCheckList([]);
				setNowData(data);
				setLoading(false);
			}
		}
	}, [data]);

	useEffect(() => {
		if (!options.useapi) {
			setCheckList([]);
			setLoading(false);
			makeDataList(data);
		}
	}, [data, currentPage, sort, sortType, skey, filters, datefilters]);

	const genUserFilter = (filter) => {
		var mt = {}
		filter.map(x => {
			if (x.type == "STRING")
				mt[x.key] = x.val;
			if (x.type == "DATETIME") {
				mt[x.key] = {};
				if (x.val.opt == "last") {
					mt[x.key]['$gte'] = moment().add(-1 * x.val.lastVal, x.val.lastValOpt).valueOf();
				} else {
					mt[x.key]['$gte'] = x.val.s;
					mt[x.key]['$lte'] = x.val.e;
				}
			}
			if (x.type == "BOOLEAN")
				mt[x.key] = x.val;
		})
		return mt;
	}

	const changeCurPage = (index) => {
		if (options.useapi) {
			updateCurPage(index, skey);
		} else {
			setCurrentPage(index);
		}
	};

	const makeDataList = () => {
		var newData = [...data];
		newData = searchData(newData);
		newData = dtfilteredData(newData);
		newData = filteredData(newData);
		newData = addfilteredData(newData);
		setAllItemCount(newData.length);


		newData.sort((a, b) => (a[sort] > b[sort] ? sortType : sortType * -1));
		setNowData(paginate([...newData]));
	};

	const handleSearch = (e) => {
		const key = e.target.value;
		setSkey(key);
	};

	const handleSearchKeyPress = (e) => {
		if (options.useapi && e.key === 'Enter') updateSearchKey(e.target.value);
	};

	const handleSearchButton = (e) => {
		updateSearchKey(skey)
	};

	const handleDateFilter = (e, code) => {
		const newFilter = [...datefilters];
		newFilter.map(x => {
			if (x.code == code)
				x.val = e
		});

		if (options.useapi) {
			setfilterpage({ ...filterpage, page: 1, filterDateOptions: newFilter });
		} else {
			setDateFilters(newFilter)
		}
	};

	const handleFilter = (e, idx) => {
		const newFilter = [...filters];
		newFilter[idx].val = chkgetTFValue(e.target.value);
		setFilters(newFilter);

		if (options.useapi) {
			var obj = {};
			newFilter.map((x) => {
				x.val == 'ALL' || (obj[x.col] = x.val);
			});
			updateFilters(obj, skey);
		}
	};

	const updateCurPage = (page, search) => {
		setfilterpage({ ...filterpage, page: page, searchKey: search });
	};

	const updateFilters = (filters, search) => {
		setfilterpage({ ...filterpage, page: 1, filters: filters, searchKey: search });
	};

	const updateSearchKey = (search) => {
		setfilterpage({ ...filterpage, page: 1, searchKey: search });
	};

	const updateSortInfo = (sort) => {
		setfilterpage({ ...filterpage, page: 1, sort: sort });
	};

	const setToggle = (val) => {
		if (toggle) {
			var to = toggle.filter(x => x.code == val);
			if (to[0].code != toggleval) {
				setfilterpage({ ...filterpage, match: to[0].filter, page: 1 });
			}
		}
	};

	const addfilteredData = (odata) => {
		var nData = [];
		if (Object.keys(addFilter).length > 0) {
			Object.keys(addFilter).map(key => {
				var k = key;
				var v = addFilter[key];
				if (!v) {
					nData = odata;
					return;
				}
				if (v !== "0") {
					nData = odata.filter(x => x[k] == v);
				} else {
					nData = odata;
				}
			})
			return nData;
		} else {
			return odata;
		}
	};

	const filteredData = (odata) => {
		var nData = [];
		var nFilter = [...filters.filter(x => x.val != "ALL")];

		if (nFilter.length > 0) {
			nData = odata.filter(x => {
				const filterResult = [];
				for (var i = 0; i < nFilter.length; i++) {
					if (Array.isArray(x[nFilter[i].col]))
						filterResult.push(x[nFilter[i].col].includes(nFilter[i].val));
					else
						filterResult.push(x[nFilter[i].col] == nFilter[i].val);
				}
				return filterResult.filter(Boolean).length == nFilter.length;
			})
			return nData;
		} else {
			return odata;
		}
	};

	const dtfilteredData = (odata) => {
		var nData = [];
		var f = false;
		datefilters.map((filter) => {
			if (filter.val) {
				if (filter.val.opt == "latest") {
					f = true;
					nData = odata.filter(item => item[filter.code] > moment.utc(moment().utc()).add(-1 * filter.val.lastVal, filter.val.lastValOpt).unix() * 1000);
				} else if (filter.val.opt == "fixdate") {
					f = true;
					nData = odata.filter(item => item[filter.code] > filter.val.s && item[filter.code] < filter.val.e);
				}
			}
		});

		return f ? nData : odata;
	};

	const paginate = (items) => {
		return items.slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage);
	};

	const searchData = (odata) => {
		var nData = [];
		if (skey.length >= 2) {
			nData = odata.filter(x => {
				var isMatch = false;
				for (var i = 0; i < columns.length; i++) {
					if (x[columns[i].name]) {
						isMatch = x[columns[i].name].toString().toUpperCase().indexOf(skey.toUpperCase()) > -1
						if (isMatch) break;
					}
				}
				return isMatch;
			})
			return nData;
		} else {
			return odata;
		}
	};

	const handleSort = (sortCol) => {
		setSortType(sortType * -1);
		setSort(sortCol);
		if (options.useapi) {
			var obj = {};
			obj[sortCol] = sortType * -1;
			updateSortInfo(obj);
		}
	};

	const isEqaul = (str) => { return str.charAt(str.length - 1) == "e" }

	const minmaxPassFail = (item, col) => {
		var ret = [];
		var passorfail = false;
		var hasminormax = false;
		var alt = "";
		//item[col.min]  = 2;
		//item[col.max] = 30;
		if (!item[col.name])
			return;
		if (item[col.min] && item[col.max]) {
			hasminormax = true;
			passorfail = item[col.name + "pass"] == "Pass";
			if (isEqaul(col.min)) {
				alt = item[col.min] + " <= " + item[col.name] + " <= " + item[col.max];
			} else {
				alt = item[col.min] + " < " + item[col.name] + " < " + item[col.max];
			}
		} else if (item[col.min]) {
			hasminormax = true;
			passorfail = item[col.name + "pass"] == "Pass";
			if (isEqaul(col.min)) {
				alt = "min : " + item[col.min];
			} else {
				alt = "min : " + item[col.min];
			}
		} else if (item[col.max]) {
			hasminormax = true;
			passorfail = item[col.name + "pass"] == "Pass";
			if (isEqaul(col.max)) {
				alt = "max : " + item[col.max];
			} else {
				alt = "max : " + item[col.max];
			}
		}
		if (hasminormax) {
			ret.push(
				<div className="chip chip-media">
					<i className={passorfail ? "chip-icon bg-primary" : "chip-icon bg-danger"}>
						{passorfail && <ion-icon name="checkmark-outline" role="img" className="md hydrated" aria-label="person"></ion-icon>}
						{!passorfail && <ion-icon name="close-outline" role="img" className="md hydrated" aria-label="person"></ion-icon>}
					</i>
					<span title={alt} className="chip-label">{item[col.name]}</span>
				</div>
			)
		} else {
			ret.push(item[col.name])
		}
		return ret;
	};

	const getColGroup = (f, idx) => {
		if (options.useapi) return f.options;
		if (options.groupFilter) {
			if (filters[idx - 1] && filters[idx - 1].val != "ALL") {
				return [
					...new Set(
						data.filter((x) => x[filters[idx - 1].col] == filters[idx - 1].val).map((item) => {
							return item[f.col];
						}, [])
					),
				].sort((a, b) => a > b == -1);
			} else {
				return [
					...new Set(
						data.map((item) => {
							return item[f.col];
						}, [])
					),
				].sort((a, b) => a > b == -1);
			}
		} else {
			return [
				...new Set(
					data.map((item) => {
						return item[f.col];
					}, [])
				),
			].sort((a, b) => a > b == -1);
		}
	};

	const handleCheckBox = (e, key) => {
		var newCheckList = e.target.checked ? [...checkList, key] : checkList.filter((item) => item !== key);
		setCheckList(newCheckList);
		checkBoxCallback && checkBoxCallback(newCheckList);
	};

	const getObjectToString = (data) => Object.keys(data).map(function (key) { return "" + key + "=" + data[key]; }).join(", ")
	const getArrayToString = (data) => {
		var ret = [];
		data.map(x => ret.push(<li className="list-group-item">{getObjectToString(x)}</li>))
		return ret
	}

	const handleCheckBoxAll = (e) => {
		var newCheckList = e.target.checked ? nowData.map((item) => {
			if (options.keyCol.indexOf('.') == -1)
				return item[options.keyCol];
			else
				return item[options.keyCol.split('.')[0]][options.keyCol.split('.')[1]];
		})
			: [];

		setCheckList(newCheckList);
		checkBoxCallback && checkBoxCallback(newCheckList);
	};

	const getStatusChipCss = () => {
		switch (item.status) {
			case "New": setChipCss("icon-box bg-dblue me-05"); setChipIcon("caret-forward-outline"); break;
			case "Visit": setChipCss("icon-box bg-dblue me-05"); setChipIcon("location"); break;
			case "Remote": setChipCss("icon-box bg-primary me-05"); setChipIcon("radio"); break;
			case "TBD": setChipCss("icon-box bg-warning me-05"); setChipIcon("chatbubble-ellipses"); break;
			case "Replacement": setChipCss("icon-box bg-primary me-05"); setChipIcon("build"); break;
			case "Monitoring": setChipCss("icon-box bg-cyan me-05"); setChipIcon("search"); break;
			case "Pending": setChipCss("icon-box bg-yorange me-05"); setChipIcon("pause"); break;
			case "Cancel": setChipCss("icon-box bg-orange me-05"); setChipIcon("close-sharp"); break;
			case "Close": setChipCss("icon-box bg-success me-05"); setChipIcon("checkmark-sharp"); break;
		}
	}

	const getItemText = (item, key, col) => {
		const countKeys = ["country", "ticketNationCode"];
		try {
			var r = '';
			if (key.indexOf('.') > -1) {
				var arr = key.split('.');
				r = item[arr[0]][arr[1]];
			} else {
				r = item[key];
			}
			if (col.type == "DT" || col.type == "DATETIME") {
				r = r ? moment(r).local().format('YYYY-MM-DD HH:mm:ss') : "";
			} else if (col.type == 'DTONLY') {
				r = r ? moment(r).local().format('YYYY-MM-DD') : "";
			}
			else if (col.type == "SPAN" && Number.isInteger(parseInt(r))) {
				r = parseInt(r).toLocaleString();// moment.duration(r, "seconds").humanize();
			} else if (col.type == "PROGRESS") {
				r = getProg(item, key, col);
			} else if (col.type == "OPERATOR") {
				if (companies.length > 0) {
					var com = companies.filter(x => x.companyId == r);
					r = com.length > 0 ? com[0].name : r;
				}
			} else if (col.type == "TF" || col.type == "BOOLEAN") {
				r = getTF(r, col);
			} else if (col.name == "summary") {
				r = <>
					<Link className="me-05" to={"/Admin/AdminService/" + item["ticketKey"]}>{r}</Link>
					{item["commentsCNT"] > 0 && chip("chatbubble-outline", "primary", item["commentsCNT"])}
					{item["attachmentsCNT"] > 0 && chip("attach-outline", "success", item["attachmentsCNT"])}
					{item["replacementsCNT"] > 0 && chip("construct-outline", "danger", item["replacementsCNT"])}
				</>;
			} else if (col.name == "sn") {
				r = <>{isCon(item, 16)}{r} {isNEW(item, moment.utc(moment().utc()).add(-(-1 * user.settings ? (user.settings.dslatestinstalleddays || 14) : 14), 'days').unix() * 1000)}
					{isDOA(item, user.adminType)} {isDIM(item, user.adminType)} </>;
			} else if (col.type == "SITEFIRSTLAST") {
				r = <>{item["customerFirstName"] + " " + (item["customerLastName"] ? item["customerLastName"] : "")}</>;
			} else if (col.type == "SITEADDR") {
				r = <>{item["address"] + " " + (item["addressExtra"] ? item["addressExtra"] : "")}</>;
			} else if (col.type == "SITEEMAIL") {
				r = <>{item["email"] || item["customerEmail"]}</>;
			} else if (col.type == "STATUS") {
				r = <ServiceStatusChip item={{ status: r }} />;
			} else if (col.type == "TITLE") {
				r = r || "[UNDEFINED]";
			} else if (col.type == "COUNTRY" || (countKeys.includes(key) && r.length == 2)) {
				const cname = CountryCode.find(item => r == item.code).title;
				r = <>
					<OverlayTrigger
						placement={'top'}
						overlay={<Tooltip> {cname} </Tooltip>}
					>
						<img src={"/assets/img/flags/" + r + ".png"} style={{ height: 24 }} alt={cname} />
					</OverlayTrigger>
					{col.showtype != "ONLYFLAG" && <>&nbsp;{cname}</>}
				</>
			}
			return r;
		} catch { }
		return "";
	};

	const chip = (icon, color, count) => {
		return <div className="chip chip-media me-05">
			<div className={"chip-icon bg-" + color}>
				<ion-icon name={icon}></ion-icon>
			</div>
			<span className="chip-label">{count}</span>
		</div>;
	}

	const getTF = (value, col) => {
		const size = {
			width: 24,
			height: 24,
		}
		return <>
			{value && <span className="text-primary"><ion-icon name="checkmark-circle" style={size}></ion-icon></span>}
			{!value && !(col.itemlabel == "reportstatus") && <span className="text-danger"><ion-icon name="ellipse" style={size}></ion-icon></span>}
		</>
	}

	const getProg = (item, key, col) => {
		const max = col.max;
		const val = item[key];
		const per = Math.round(val * 100 / max);
		return <div className="progress" style={{ height: 18 }}>
			<div className="progress-bar bg-primary"
				style={{ width: per + "%", height: 18 }}
				aria-valuenow={per}
				aria-valuemin="0" aria-valuemax="100">{item[key]}</div>
		</div>
	}

	const depVal = (item, key) => {
		if (key.indexOf('.') > -1) {

			var arr = key.split('.');
			return item[arr[0]] ? item[arr[0]][arr[1]] : '';
		} else {
			return item[key];
		}
	}

	const goReport = (installationId, modifylink) => {
		window.location = modifylink + installationId;
	}

	const getVal = (item, key, coloption) => {
		const col = columns.filter(x => x.name == key)[0];

		let el;

		if (col.name == 'summary') {	// AdminProductServiceList 중 Icon(chip)은 Link에서 제외하기 위해 추가함. 다른 곳에 영향이 있을지 체크 필요 (2022.04.27 LJH)
			el = getItemText(item, key, coloption);
		} else if (options.linkto && Array.isArray(options.linkkey) && options.keyCol == col.name) {
			var linkparam = options.linkkey.map(x => { return depVal(item, x) });
			el = <Link to={options.linkto + "/" + linkparam.join("/")}>{getItemText(item, key, coloption)}</Link>;
		} else if (options.linkto && key == options.linkkey) {
			el = <Link to={options.linkto + "/" + depVal(item, options.keyCol)}>{getItemText(item, key, coloption)}</Link>;
		} else if (col.name == "sn") {
			el = <Link to={"/Admin/AdminProductInfo/" + depVal(item, col.name)}>{getItemText(item, key, coloption)}</Link>;
		} else if (options.linkto && key == options.linkkey) {
			el = <Link to={options.linkto + "/" + depVal(item, options.keyCol)}>{getItemText(item, key, coloption)}</Link>;
		} else if (col.link && depVal(item, col.linkdata)) {
			el = <Link to={col.link + "/" + depVal(item, col.linkdata)}>{getItemText(item, key, coloption)}</Link>;
		} else if (options.linkkey && key == options.linkkey) {
			return <a href onClick={() => rowSelectCallback && rowSelectCallback(item)}>{getItemText(item, key, coloption)}</a>;
		} else if (col.itemlabel == "report") {
			if (item[col.itemview])
				return <Link to={col.itemlink + depVal(item, col.itemlinkkey)}>{getItemText(item, key, coloption)}</Link>;
			else
				return <a href style={{ cursor: 'pointer' }} onClick={() => goReport(depVal(item, col.itemlinkkey), col.modifylink)}>{getItemText(item, key, coloption)}</a>;
		} else {
			el = getItemText(item, key, coloption);
		}

		return el;
	};

	const btnClick = (item, btnOptions) => {
		if (btnOptions.paramType == 'param') {
			history.push({
				pathname: btnOptions.linkPath,
				state: {
					refer: btnOptions.paramRefer,
					item: item,
					extraItem: parentData,
				},
			});
		} else if (btnOptions.paramType == 'callback') {
			calllback && calllback(item);
		}
	};

	const chkgetTFValue = (option) => {
		if (option === "True")
			return true;
		if (option === "False")
			return false;
		return option;
	}

	const chkgetTFOption = (option, type) => {
		if (type === "isSubmit") {
			if (option === true)
				return "Complete";
			if (option === false)
				return "Incomplete";
		} else {
			if (option === true)
				return "True";
			if (option === false)
				return "False";
			if (type == "lang") {
				var nl = LanguageCode.filter(x => x.code == option);
				return nl.length > 0 ? nl[0].title : option;
			}
		}
		return option;
	}

	const countryName = (option) => {
		if (option) {
			const cname = CountryCode.find(x => x.code == option);
			return cname ? cname.title : option;
		}
		return "UNDEFINED";
	}
	const s_sort = (a, b) => {
		if (a > b) return -1;
		else if (a == b) return 0;
		else return 1;
	}

	const getFilterTitle = (val) => {
		if (val == "isSubmit")
			return "SUBMITTED"
		if (val == "lang")
			return "LANGUAGE"
		return val ? val.toUpperCase() : '';
	}

	const goExport = () => {
		setShowExportList(true);
	}

	const getModelInfo = (val) => {
		const ret = clist.find(x => x.group === val || x.alias.includes(val));
		return ret;
	}

	return (
		<>
			{!showExportList && <>
				{title && <h2>{title}</h2>}
				{!onlytable &&
					<div className="row">
						<div className={(bulkActions.length > 0 && checkList.length > 0) ? "col-4" : "col-4 pt-1"}>
							{(allItemCount > 0 && !(bulkActions.length > 0 && checkList.length > 0) && !options.hideListCount) && <span className="h3">{allItemCount.toLocaleString()}</span>}
							{(bulkActions.length > 0 && checkList.length > 0) && <div className="btn btn-group mt-0 p-0">
								{bulkActions.map(x => <button
									className={x.action == "OPTRANSFER" ? "btn btn-success mt-0" : (x.action == "CLEARDOA" ? "btn btn-danger mt-0" : "btn btn-warning mt-0")}
									onClick={() => bulkActionDo && bulkActionDo(x.action)}>{x.title}{x.action != 'EXPORTCSR' && (checkList.length > 0 && "(" + checkList.length + ")")}</button>
								)}
							</div>}
						</div>
						<div className="col-8 mb-1">
							<div className="float-end">
								<div className="input-group">
									{(conSearch) && <div className="me-1">
										<button
											className={dsfilterCount > 0 ? "btn btn-success m-0" : "btn btn-warning m-0"}
											onClick={(e) => conSearch(e)}>{t('deepsearch')}{dsfilterCount > 0 ? "(" + dsfilterCount + ")" : ""}</button>
									</div>}
									{datefilters.map((item, idx) => {
										return <InputDateFilter
											key={item.code + idx}
											options={{
												type: "datetime",
												name: item.code,
												onlyDate: true,
												label: item.label,
												val: item.val || {
													opt: "",
													lastVal: 1,
													lastValOpt: "d",
													s: new Date().getTime(),
													e: new Date().getTime(),
												},
												required: true
											}}
											disabled={loading}
											inline={true}
											editable={true}
											onchange={(e) => handleDateFilter(e, item.code)} />
									})}
									{toggle && <div className='me-2'><button
										onClick={() => setToggle(!toggleval)}
										className="btn btn-warning mt-0">
										{toggle.filter(x => x.code == toggleval)[0].title}
									</button></div>
									}
									{su && userfilter.length > 0 && <select
										onChange={(e) => {
											e.target.value === "" ?
												callbackchangeuserfilter({}) :
												callbackchangeuserfilter(userfilter.filter(x => x.id == e.target.value)[0]);
										}}
										value={nowfilter.id}
										className="form-control">
										<option value="">{t('selectafilter')}</option>
										{userfilter.map((option, id) => (
											<option key={id} value={option.id}>{option.title}</option>
										))}
									</select>}
									{filters.map((item, idx) => {
										let options = getColGroup(item, idx);

										let tmp = [];

										if (item.col === 'model') {
											if (user.isMegagen)
												options = ['R2 Studio Q'];

											if (Array.isArray(options[0])) {
												for (let i = 0; i < options.length; i++) {
													for (let j = 0; j < options[i].length; j++) {
														if (!tmp.includes(options[i][j]) && options[i][j] != '')
															tmp.push(options[i][j]);
													}
												}

												options = tmp;
											}
										}

										return (
											<select
												key={idx}
												onChange={(e) => handleFilter(e, idx)}
												value={item.val}
												className="form-control"
											>
												<option value="ALL">ALL({getFilterTitle(item.col)})</option>
												{item.col != "isSubmit" ?
													options.filter(x => x).sort(s_sort).map((option) => (
														<option key={option} value={option}>
															{item.col == "country" ? countryName(option) : chkgetTFOption(option, item.col)}
														</option>
													)) : options.sort(s_sort).map((option) => (
														<option key={option} value={option}>
															{item.col == "country" ? countryName(option) : chkgetTFOption(option, item.col)}
														</option>
													))}
											</select>
										);
									})}
									{options.search && <>
										<input
											onChange={handleSearch}
											onKeyPress={handleSearchKeyPress}
											disabled={loading}
											type="text"
											className="form-control"
											placeholder={t('search')}
										/>
										{options.useapi && <button className="btn btn-secondary mt-0" onClick={() => handleSearchButton()}>{t('search')}</button>}
									</>}
								</div>
							</div>
						</div>
					</div>}
				<div className={columns.length > 10 ? "overflow-auto" : ""}>
					<table className={css ? css : "table table-bordered mb-1"}>
						<thead>
							<tr>
								{options.isShowCheckBox && (
									<td style={{ width: 24 }}>
										<input
											type="checkbox"
											checked={checkList.length === nowData.length}
											onChange={(e) => handleCheckBoxAll(e)}
										/>
									</td>
								)}
								{columns.map((col) => {
									return (
										<th key={col.name} onClick={() => col.insortable || handleSort(col.name)}
											className={col.align && col.align}
											style={col.style && col.style}
										>
											{col.label}
											{sort == col.name && sortType == 1 && <span>&#8593;</span>}
											{sort == col.name && sortType == -1 && <span>&#8595;</span>}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{loading && <tr><td colSpan={columns.length + (options.isShowCheckBox ? 1 : 0)}><Loading /></td></tr>}
							{!loading && nowData?.map((item, index) => {
								return (
									<tr
										key={index}
										className={rowSelectCallback && 'row_hand'}
										onClick={() => rowSelectCallback && rowSelectCallback(item)}
									>
										{options.isShowCheckBox && (
											<td>
												<input
													type="checkbox"
													checked={checkList.includes(depVal(item, options.keyCol))}
													onChange={(e) => {
														handleCheckBox(e, depVal(item, options.keyCol));
													}}
												/>
											</td>
										)}
										{columns.map((col, idx) => {
											if (col.type == 'BUTTON') {
												return (
													<td key={col.name + idx} className="text-center p-0">
														<button
															className="btn btn-sm btn-warning mt-05"
															style={{ fontSize: "12px" }}
															onClick={() => btnClick(item, col.options)} >
															{col.options.title}
														</button>
													</td>
												);
											} else if (col.type == 'MODEL_SET_BUTTON') {
												var cname = item[col.name];
												const prdmodel = getModelInfo(cname);
												return <>
													{prdmodel && <td key={col.name + idx} className="text-center p-0">{prdmodel.name}</td>}
													{!prdmodel && <td key={col.name + idx} className="text-center text-danger fw-bolder p-0">{cname}</td>}
												</>
											} else if (col.type == 'CALLBACK_BUTTON') {
												return (
													<td key={col.name + idx} className="text-center p-0">
														<button
															className="btn btn-sm btn-warning mt-05"
															style={{ fontSize: "12px" }}
															onClick={() => btnClick(item, col.options)} >
															{getVal(item, col.name, col)}
														</button>
													</td>
												);
											} else if (col.type == 'COMPANY') {
												var cname = item[col.name];
												if (companies.length > 0) {
													var cid = companies.filter(x => x.companyId == cname);
													if (cid.length > 0)
														cname = cid[0].name
												}
												return (
													<td className="" key={col.name + idx}>
														{cname}
													</td>
												)
											} else if (col.type == 'EMSLOGBUTTON') {
												return (
													<td className="text-center p-0" key={col.name + idx}>
														<EMSLogButton item={item} col={col} css={"btn btn-sm btn-success mt-05"} product={parentData} showtextfilename={true} />
													</td>
												)
											} else if (col.type == 'PASSFAIL') {
												return (
													<td className="text-center p-0" key={col.name + idx}>
														{minmaxPassFail(item, col)}
													</td>
												)
											} else if (col.type == 'LIST') {
												return (
													<td className="text-left p-0" key={col.name + idx}>
														<ul className="list-group list-group-flush">
															{getArrayToString(item[col.name])}
														</ul>
													</td>
												)
											} else if (col.type == 'TAG') {
												return (
													<td key={col.name + idx} className={col.align && col.align}>
														{item[col.name]?.map(x =>
															<div className="chip chip-outline me-05">
																<span className='chip-label'>{x}</span>
															</div>
														)}
													</td>
												)
											} else if (col.type == "LISTTABLE") {
												return (
													<td key={col.name + idx} className="text-center p-0">
														{
															item[col.name]
														}
														{
															item[col.name].map(x => {
																if (col.itemlink && col.itemlinkkey) {
																	return <Link to={col.itemlink + x[col.itemlinkkey]}>{x[col.itemlinkkey]}</Link>
																} else {
																	return x[col.itemlabel]
																}
															})
														}
													</td>
												);
											} else if (col.type == "ARRAYLIST") {
												return (
													<td key={col.name + idx} className="text-center p-0" style={{ whiteSpace: 'pre-wrap' }}>
														{
															item[col.name].map(x => x)
														}

													</td>
												);
											} else if (col.type == "ARRAYLINK") {
												return (
													<td className="text-center pt-1" key={col.name + idx}>
														{item[col.name]?.map((x, idx) => <>
															<Link to={col.arraylink + '/' + x} >{x}</Link>
															{idx < item[col.name].length - 1 && <span className='me-1'>,</span>}
														</>)}
													</td>
												)
											} else if (col.type == "ARRAY") {
												return (
													<td className="text-center pt-1" key={col.name + idx}>
														{item[col.name]?.map(x => x).join(', ')}
													</td>
												)
											}
											else if (Array.isArray(item[col.name])) {
												return (
													<td key={col.name + idx} className="text-center p-0">
														{item[col.name].slice(0, 1).map(x => {
															if (col.itemlink && col.itemlinkkey) {
																return <Link to={col.itemlink + x[col.itemlinkkey]}>{x[col.itemlinkkey]}</Link>
															} else {
																return x[col.itemlabel]
															}
														})}
														{item[col.name].length > 1 && <span className="badge badge-primary">{item[col.name].length}</span>}
													</td>
												);
											} else if (col.type == 'DOWNLOADBUTTON') {
												return (<td key={col.name + idx} className="text-center p-0">
													{(item.attachmentPath && item.attachmentOrigin) && <S3DownloadButton
														text="Download"
														showtextfilename={true}
														style={{ fontSize: "20px" }}
														path={item.attachmentPath}
														filename={item.attachmentOrigin}
														css={"btn btn-sm btn-success mt-05"}
													/>}
												</td>)
											} else if (col.type == 'INFO') {
												return <td className="text-break" key={col.name + idx}>{getVal(item, col.name, col)}</td>;
											} else if (col.type == 'DOADIM') {
												return (
													<td className="text-break" key={col.name + idx}>{
														item[col.name] &&
														<span className={`badge badge-${col.name == 'doa' && 'danger' || col.name == 'dim' && 'warning'}`}>{col.label}</span>
													}</td>
												)
											}
											else {
												return <td key={col.name + idx} className={`text-break ${col.align && col.align}`}>{getVal(item, col.name, col)}</td>;
											}
										})}
									</tr>
								);
							})}
							{!loading && nowData?.length == 0 && <tr><td colSpan={columns.length + (options.isShowCheckBox ? 1 : 0)} className="text-center"><i>{t('nodataavailable')}</i></td></tr>}
						</tbody>
					</table>
				</div>
				<div className="row">
					<div className="col ps-1">
						{!loading && allItemCount > itemPerPage && (
							<RayPagination
								pageSize={itemPerPage}
								totalItems={allItemCount}
								curPage={rdpage || (options.useapi ? filterpage.page : currentPage)}
								paginate={changeCurPage}
							></RayPagination>
						)}
					</div>
					<div className="col pe-1 text-end">
						{exportlocalaction &&
							(
								checkList.length == 0 ? <button type='button' className={"btn btn-sm btn-secondary rayexport m-0"} disabled={true}>{t('export')}</button> :
									<ExportExcelClass
										data={exportData}
										disabled={loading}
										css={"btn btn-lg btn-primary rayexport m-0"}
										title={t('export')} />
							)
						}
					</div>
				</div>
			</>}
			{showExportList && <RayTableExport
				filters={filterpage}
				sort={sort}
				columns={columns}
				goback={() => setShowExportList()}
				exportlocalaction={exportlocalaction} />}
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var su = state.AuthReducer.su;
	var companies = state.CompanyReducer.companies;
	var showmodel = state.ProgramReducer.showmodel;
	var clist = state.RayCodeReducer.items;
	return { user, companies, su, showmodel, clist };
}

export default connect(mapState)(RayTable);
