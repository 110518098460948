import axios from 'axios';
import { AppConfig, JWT, RT_TOKEN } from '../config';
import { SiteConstant } from './constants/SiteConstant';
import { ProgramConstant } from './constants/ProgramConstant';
import { AlertAction } from './AlertAction';

// const GetSites = (filter, append = false) => async (dispatch) => {
// 	dispatch({ type: SiteConstant.LOADING });

// 	const ret = await axios.post(`${AppConfig.GROUP_API_PATH}/site`, { type: "get", data: filter }, RT_TOKEN());

// 	if (ret.data.status === 'success') {
// 		const total_count = parseInt(ret.data.data.totalcount) || 0;
// 		const list = ret.data.data;

// 		dispatch({ type: SiteConstant.GET_SITELIST, sites: list, append });		
// 		dispatch({ type: ProgramConstant.SET_SEARCHSCREEN_TITLE, title: "Sites", count: total_count });
// 	} else {
// 		dispatch(AlertAction.Error(ret.data));
// 	}

// 	dispatch({ type: SiteConstant.LOADED });
// };

const GetSites = (filter, append = false) => async (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site', { type: "get", data: filter }, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: SiteConstant.GET_SITELIST, sites: ret.data.data, append });
		dispatch({ type: ProgramConstant.SET_SEARCHSCREEN_TITLE, title: "Sites", count: ret.data.data.totalcount });

	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: SiteConstant.LOADED });
}

const GetSiteSimples = (filter, append = false) => async (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	
	const data = { type: "getsimple", data: filter };
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT());
	
	if (ret.data.status == 'success') {
		dispatch({ type: SiteConstant.GET_SITELIST, sites: ret.data.data, append });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: SiteConstant.LOADED });
};

const GetSearchSiteAll = async (searchCols, keyword) => {
	const data = {
		type: "get",
		data: {
			searchCols: searchCols,
			searchKey: keyword
		}
	}

	return await axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT());
};

const GetFavSiteAll = (filter) => async (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	const uData = JSON.parse(localStorage.getItem('user'));
	const data = {
		type: "get",
		data: { ...filter, match: { siteId: { '$in': uData.favoriteSites } } }
		//data : {...filter}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: SiteConstant.GET_FAV_SITELIST, sites: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: SiteConstant.LOADED });
};

const GetSiteCombo = async () => {
	const data = { type: "combo" };
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const AddSite = (site) => async (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'create',
		data: site,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT());
	
	if (ret.data.status == 'success') {
	
		// const sub = JSON.parse(localStorage.getItem('rtUser')).sub;

		// const ddbData = {
		// 	type: 'create',
		// 	data: {
		// 		companyId: '',
		// 		customerDateOfBirth: 0,
		// 		customerForgotPasswordSessionExired: 0,
		// 		who: sub,
		// 		address: site.address,
		// 		sitename: site.sitename,
		// 		customerFirstName: customerFirstName,
		// 		emsuid: ' ',
		// 		country: site.country,
		// 		email: site.email,
		// 		scp: ''
		// 	}
		// }

		// const resposne = await axios.post(`${AppConfig.GROUP_API_PATH}/site`, ddbData, RT_TOKEN());

		// console.log(response);

		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: SiteConstant.ADD_SITEINFO, site: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Create Failed"));
	}
	dispatch({ type: SiteConstant.LOADED });
};

const DeleteSite = (siteid) => async (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	const data = {
		"who": JSON.parse(localStorage.getItem("user")).adminid,
		"type": "delete"
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site/' + siteid, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: SiteConstant.DELETE_SITE, delsiteid: siteid });
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
	dispatch({ type: SiteConstant.LOADED });
};

const UpdateSiteInfo = (siteid, siteInfo) => async (dispatch) => {

	dispatch({ type: SiteConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: siteInfo,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site/' + siteid, data, JWT());
	if (ret.data.status == 'success') {

		// const ddbData = {
		// 	type: 'update',
		// 	data: siteInfo
		// }

		// const response = await axios.post(`${AppConfig.GROUP_API_PATH}/site/${siteid}`, ddbData, RT_TOKEN());

		// if (response.data.status === 'success') {
		dispatch(AlertAction.Success('Updated'));
		dispatch({ type: SiteConstant.UPDATE_SITEINFO, site: ret.data.data });
		// } else {
		// 	dispatch(AlertAction.ErrorMsg("Update Failed"));
		// }
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}

	dispatch({ type: SiteConstant.LOADED });
};

const GetSite = (_id) => async (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	let aggregate = [];
	//aggregate.push({ '$match' : { 'siteId' :  _id } });
	aggregate.push({ '$match': { 'siteId': _id } });
    aggregate = [...aggregate, ...[
	    {
			$lookup: {
				from: 'companies',
				localField: 'companyId',
				foreignField: 'companyId',
				as: 'company'
			}
		},
		{
			$addFields: {
				'company': { "$arrayElemAt": ["$company", 0] }
			}
		},
		{
			$lookup: {
				from: 'products',
				localField: 'siteId',
				foreignField: 'siteId',
				as: 'products'
			}
		},
		{
			$addFields: { 'sns' : '$products.sn' }
		},
		{
			$project: {
				'products.emsalignments' : 0,
				'products.acquisitions' : 0,
				'products.histories' : 0,
				'products.qcs' : 0,
				'products.errs' : 0
			}
		}
		
	]];
	const data = {
		data : {
			type: "site",
			aggregate
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());

	////const ret = await axios.get(AppConfig.API_SERVER_PATH + '/site/' + _id, JWT());
	// const ret = await axios.get(`${AppConfig.GROUP_API_PATH}/site/${_id}`, RT_TOKEN());

	if (ret.data.status == 'success') {
		console.log("GET_SITE : ===", ret.data.data && ret.data.data[0]);
		dispatch({ type: SiteConstant.GET_SITE, site: ret.data.data && ret.data.data[0] });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: SiteConstant.LOADED });
};

const GetSiteOld = (_id) => async (dispatch) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/site/' + _id, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: SiteConstant.GET_SITE, site: ret.data.data[0] });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: SiteConstant.LOADED });
}

const GetSiteLocal = async (_id) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/site/' + _id, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	}
	return null;
};

const GetSiteAllLocal = async (data) => {
	return await axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT());
}

const ClearUpdateResult = () => (dispatch) => {
	dispatch({ type: SiteConstant.LOADING });
	dispatch({ type: SiteConstant.CLEAR_UPDATERES });
	dispatch({ type: SiteConstant.LOADED });
};

const GetInstalledProduct = async (siteId) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/site/getdelcheckinfo/' + siteId, JWT());
	if (ret.data.status == "success") {
		return ret.data.data;
	} else {
		AlertAction.Error("Check Fail!");
		return [];
	}
};

const GetSitesByCompanyId = async (companyId) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/site', { type: 'sitesByCompanyId', data: { companyId } }, JWT());

	if (ret.data?.status === 'success')
		return ret.data.data;
	else
		return [];
}

export const SiteAction = {
	GetSites,
	GetFavSiteAll,
	GetSiteSimples,
	GetSearchSiteAll,
	GetSiteCombo,
	GetInstalledProduct,
	AddSite,
	UpdateSiteInfo,
	DeleteSite,
	GetSite,
	GetSiteOld,
	ClearUpdateResult,
	GetSiteLocal,
	GetSiteAllLocal,
	GetSitesByCompanyId
};