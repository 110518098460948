import React, { useEffect, useState } from 'react';
import ProductInfoAutoDiagnosisItem from './ProductInfoAutoDiagnosisItem';
import { NoRecord } from '../../pages/HomePage';
import { Translate as t } from '../../config/Translate';

const ProductInfoAutoDiagnosis = ({ product }) => {

	const [list, setList] = useState(product.autoDiagnoses || []);

	const getReorderedList = () => {
		list.sort(function (a, b) {
			return b.timestamp - a.timestamp;
		});
		setList([...list]);
	}

	useEffect(() => {
		getReorderedList();
	}, [product])

	return (
		<>
			{list.length > 0 &&
				<div className="section">
					<div className="accordion mt-1">
						{list.map((x, index) => <div className="accordion-item" key={x.timestamp}><ProductInfoAutoDiagnosisItem item={x} showindex={index} /></div>)}
					</div>
				</div>
			}
			{!list.length &&
				<NoRecord />
			}
		</>
	);
};

export default ProductInfoAutoDiagnosis;