export const SiteSurveyConstant = {
    GET_SITESURVEYLIST: 'GET_SITESURVEYLIST',
    GET_SITESURVEY: 'GET_SITESURVEY',
    NEW: 'NEW_SITESURVEY',
    ERROR: 'ERROR',
    DELETE: 'SS_DELETE',
    UPDATE: 'UPDATE_SITESURVEY',
    CREATE: 'CREATE_SITESURVEY',
    LOADING: 'SS_LOADING',
    LOADED: 'SS_LOADED',
    CLEAR: 'SS_CLEAR',
    SITESURVEY_UPDATE_ADD_ATTACHMENT: 'SITESURVEY_UPDATE_ADD_ATTACHMENT',
    SITESURVEY_UPDATE_DEL_ATTACHMENT: 'SITESURVEY_UPDATE_DEL_ATTACHMENT',
};