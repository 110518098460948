import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import WdigetItemCountCard from '../../widgets/WdigetItemCountCard';
import WidgetPieChart from '../../widgets/WidgetPieChart';
import AdminSiteInfo_Site from './AdminSiteInfo_Site';
import { SiteAction } from '../../actions';
import CustomFieldValues from '../../components/customfield/CustomFieldValues';
import { Translate as t } from '../../config/Translate';

const AdminSiteInfoSummary = ({ site, editable, UpdateSiteInfo }) => {

	const AggrServiceOpen = () => {
		return {
			type: 'service',
			aggregate: [
				{ $match: { 'siteId': site.siteId, 'status': { $ne: "Close" } } },
				{ $count: 'count' },
			]
		}
	}

	const AggrAcq = (days) => {
		return {
			type: 'product',
			aggregate: [
				{ $match: { 'siteId': site.siteId } },
				{ $project: { acq: '$acquisitions', _id: 0 }, },
				{ $unwind: '$acq' },
				{ $match: { 'acq.activationTime': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } },
				{ $count: 'count' },
			],
		}
	}

	const AggrErrorPercent = () => {
		return {
			type: 'product',
			aggregate: [
				{ $match: { 'siteId': site.siteId } },
				{ $unwind: '$errs' },
				{ $project: { 'errs.code': 1 } },
				{ $addFields: { 'code': '$errs.code' } },
				{ $group: { _id: { label: '$code' }, value: { $sum: 1 } } },
			],
		};
	};

	const AggrErrorLastNDays = (days) => {
		return {
			type: 'product',
			aggregate: [
				{ $match: { 'siteId': site.siteId } },
				{ $project: { err: '$errs', _id: 0 } },
				{ $unwind: '$err' },
				{ $match: { 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } },
				{ $count: 'count' },
			],
		};
	};

	const UpdateExtra = (code, value) => {
		UpdateSiteInfo(site._id, {
			customfieldvals: { ...site.customfieldvals, [code]: value }
		});
	}

	return <div className="row">
		<div className="col-8 pe-0">
			<AdminSiteInfo_Site site={site} editable={editable} />
		</div>
		<div className="col-4 ps-0 pt-1 pe-2">
			<CustomFieldValues model={"SITE"} update={UpdateExtra} data={site.customfieldvals || {}} />
			<div className="row">
				<div className="col ps-1">
					<WdigetItemCountCard
						title={t('service')}
						subtitle="Not Closed"
						aggregate={AggrServiceOpen()} />
				</div>
				<div className="col ps-0">
					<WdigetItemCountCard
						title={t('error')}
						subtitle="Last 7 Days"
						aggregate={AggrErrorLastNDays(7)} />
				</div>
				<div className="col ps-0">
					<WdigetItemCountCard
						title={t('acquisition')}
						subtitle="Last 7 Days"
						aggregate={AggrAcq(7)} />
				</div>
			</div>
			<div className="pe-05">
				<WidgetPieChart
					title={t('error')}
					subtitle={t('alltime')}
					aggregate={AggrErrorPercent()} />
			</div>
		</div>
	</div>
};

const mapDispatch = (dispatch) => ({
	UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data))
});

export default connect(null, mapDispatch)(AdminSiteInfoSummary);