import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { InfinteScroll } from '../../components/common/InfinteScroll';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Loading } from '../common/Loading';
import { history } from '../../history';
import { SiteAction, AuthAction } from '../../actions';
import { getDistance } from 'geolib';
import ScheduleCreate from '../../components/schedule/ScheduleCreate';
import { CalendarConstant } from '../../actions/constants/CalendarConstant';
import SiteCard from './SiteCard';
import moment from 'moment';
import {
	LeadingActions,
	SwipeableList,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
	Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import MapView from '../common/MapView';

const SiteList = ({ GetSites, items, totalCount, isLoading, favSites, UpdateUserFav, GetFavSiteAll, showFav = false }) => {

	const dispatch = useDispatch();
	const [mapSite, setMapSite] = useState({});
	const [showAS, setShowAS] = useState(false);
	const [showASSched, setShowASSched] = useState(false);
	const [target, setTarget] = useState(null);
	const [page, setPage] = useState(1);
	const [itemperpage, setItemPage] = useState(30);
	const [viewFav, setViewFav] = useState(showFav);
	const [list, setList] = useState([]);
	const [pos, setPos] = useState({});

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(function (position) {
			setPos({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			});
		});
	}, []);

	useEffect(() => {
		if (viewFav != showFav) {
			setViewFav(showFav);
			setPage(1);
			if (page == 1)
				loadItems(page);
		}
	}, [showFav]);

	useEffect(() => {
		loadItems(page);
	}, [page]);

	useEffect(() => {
		var sList = [];
		items.map(x => sList.push({ ...x, far: getFar(x) }));
		setList(sList.sort((a, b) => parseFloat(a.far) - parseFloat(b.far)));
	}, [items]);

	useEffect(() => {
		if (viewFav) {
			dispatch({
				type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
				title: "Favorite Sites",
				count: list.length.toLocaleString(),
			});
		}
	}, [list]);

	const loadItems = (pg) => {
		if (showFav) {
			GetFavSiteAll({ itemperpage: 100, page: 1 })
		} else {
			GetSites({ itemperpage: itemperpage, page: pg }, true);
		}
	}

	const getFar = (item) => {
		if (pos.latitude) {
			return getDistance({
				latitude: pos.latitude,
				longitude: pos.longitude,
			}, {
				latitude: parseFloat(item.lat),
				longitude: parseFloat(item.lng),
			})
		}
		return "";
	}

	const isEnd = () => { return totalCount <= page * itemperpage; }

	const removeArr = (id) => {
		const idx = favSites.indexOf(id);
		if (idx > -1) {
			favSites.splice(idx, 1);
			if (showFav)
				setList([...list.filter(x => x.siteId != id)])
		}
		return favSites;
	}

	const fav = (isFav, sn) => {
		UpdateUserFav("site", isFav ?
			{ favoriteSites: removeArr(sn) } :
			{ favoriteSites: [...favSites, sn] });
	}

	const updateFav = (siteId) => {
		fav(favSites.filter(x => x == siteId).length > 0 ? true : false, siteId)
		//GetFavSiteAll();
	}

	InfinteScroll({
		target,
		onIntersect: ([{ isIntersecting }]) => {
			if (isIntersecting && !isLoading && !isEnd()) {
				setPage(page + 1);
			}
		}
	});

	const showMap = (item) => {
		setMapSite(item);
		setShowAS(true);
	}

	const createSche = (item) => {
		dispatch({
			type: CalendarConstant.SET_NEW, item: {
				type: "On-site",
				color: "bg-success",
				start: moment().clone().add(4, "hours").valueOf(),
				finish: moment().clone().add(5, "hours").valueOf(),
				sn: item.product.sn,
				sitename: item.sitename,
				siteId: item.siteId,
				model: item.product.model,
				option: item.product.option
			}
		});
		setShowASSched(true);
	}

	const makeAs = (item) => {
		history.push({
			pathname: "/ServiceAddNew",
			state: {
				refer: "PRODUCTNORMAL",
				item: item.siteId,
				extraItem: item.product ? item.product.sn : "",
			},
		});
	}

	const leadingActions = (isFav, sn) => (
		<LeadingActions>
			<SwipeAction onClick={() => updateFav(sn)}>
				<div className={isFav ? "bg-danger text-center" : "bg-dark text-center"} style={{ width: "80px" }}>
					<ion-icon size="large" name={isFav ? "heart" : "heart-outline"}></ion-icon>
				</div>
			</SwipeAction>
		</LeadingActions>
	);

	const trailingActionsWithProduct = ({ item }) => (
		<TrailingActions>
			<SwipeAction onClick={() => createSche(item)}>
				<div className="bg-ygreen text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="calendar-outline"></ion-icon>
				</div>
			</SwipeAction>
			<SwipeAction onClick={() => showMap(item)}>
				<div className="bg-cyan text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="navigate-outline"></ion-icon>
				</div>
			</SwipeAction>
			<SwipeAction onClick={() => makeAs(item)}>
				<div className="bg-info text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="build-outline"></ion-icon>
				</div>
			</SwipeAction>
		</TrailingActions>
	);

	const trailingActions = ({ item }) => (
		<TrailingActions>
			<SwipeAction onClick={() => showMap(item)}>
				<div className="bg-success text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="map-outline"></ion-icon>
				</div>
			</SwipeAction>
			<SwipeAction onClick={() => makeAs(item)}>
				<div className="bg-info text-center" style={{ width: "80px" }}>
					<ion-icon size="large" name="build-outline"></ion-icon>
				</div>
			</SwipeAction>
		</TrailingActions>
	);

	const [wStyle, setWStyle] = useState();

	return (
		<>
			<SwipeableList type={ListType.IOS} fullSwipe={false}
				className="listview image-listview">
				{list.map((item, index) =>
					<SwipeableListItem key={index}
						fullSwipe={false}
						leadingActions={leadingActions(favSites.filter(x => x == item.siteId).length > 0, item.siteId)}
						trailingActions={item.product ? trailingActionsWithProduct({ item }) : trailingActions({ item })}>
						<SiteCard item={item} />
					</SwipeableListItem>
				)}
			</SwipeableList>
			{isLoading && <Loading />}
			<div ref={setTarget} className='last-item' />
			<ScheduleCreate showAS={showASSched} callback={() => setShowASSched(false)} />
			<MapView site={mapSite} callback={() => setShowAS(false)} showAS={showAS} />
		</>
	);
};

const mapState = (state) => {
	var items = state.SiteReducer.sites;
	var totalCount = state.SiteReducer.totalSiteCount;
	var isLoading = state.SiteReducer.isLoading;

	var favSites = state.FavoriteReducer.site;
	return { items, totalCount, isLoading, favSites };
};

const mapDispatch = (dispatch) => ({
	GetSites: (filter, append) => dispatch(SiteAction.GetSites(filter, append)),
	GetFavSiteAll: (filter) => dispatch(SiteAction.GetFavSiteAll(filter)),
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
});

export default connect(mapState, mapDispatch)(SiteList);