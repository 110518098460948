import { CompanyConstant } from '../actions/constants/CompanyConstant';
const initialState = 
{ 
    companies : [],
    company : {},
    filter : {},
	isUpdateRes: false,
};
export const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case CompanyConstant.UPDATE_LISTFILTER:
            return {...state, filter : action.filter, isUpdateRes : false };
        case CompanyConstant.ADD_COMPANYINFO:
            return {...state, 
                companies : [...state.companies, action.company], 
                company : action.company,
                isUpdateRes : true};
        case CompanyConstant.UPDATE_COMPANYINFO:
            return {...state, 
                companies : state.companies.map(u => u._id == action.company._id ? action.company : u),
                company : action.company,
                isUpdateRes : true
            };
        case CompanyConstant.DELETE_COMPANY:
            return {...state, 
                companies : state.companies.filter(u => u._id !== action.delcompanyid), 
                isUpdateRes : true
            };
        case CompanyConstant.CLEAR_UPDATERES:
            return { ...state, isUpdateRes : false };
        case CompanyConstant.GET_COMPANYLIST:
            return {...state, companies : action.companies, isUpdateRes : false };
        case CompanyConstant.GET_COMPANY:
            return {...state, 
                companies : state.companies.map(u => u._id == action.company._id ? action.company : u),
                company : action.company,
                isUpdateRes : false};
        default:
            return state
    }
}