import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { AdminUserConstant } from './constants/AdminUserConstant';
import { AlertAction } from './AlertAction';
import { store } from '../store';

const GetUserAll = () => async (dispatch) => {
	dispatch({ type: AdminUserConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user', JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: AdminUserConstant.GET_USERLIST, users: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: AdminUserConstant.LOADED });
};

const UpdateUserInfo = (userid, userInfo) => async (dispatch) => {
	dispatch({ type: AdminUserConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: userInfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + userid, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success('Updated'));
		dispatch({ type: AdminUserConstant.UPDATE_USERINFO, user: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: AdminUserConstant.LOADED });
};

const RegistUserInfo = (userInfo) => async (dispatch) => {
	dispatch({ type: AdminUserConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'regist',
		data: userInfo,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: AdminUserConstant.REGIST_USERINFO, user: ret.data.data });
		dispatch(AlertAction.Success('Saved'));
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: AdminUserConstant.LOADED });
};

const DeleteUser = (userid) => async (dispatch) => {
	dispatch({ type: AdminUserConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + userid, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: AdminUserConstant.DELETE_USER, deluserid: userid });
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
	dispatch({ type: AdminUserConstant.LOADED });
};

const ClearUpdateResult = () => (dispatch) => {
	dispatch({ type: AdminUserConstant.CLEAR_UPDATERES });
};

const GetUser = (_id) => async (dispatch) => {
	dispatch({ type: AdminUserConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: AdminUserConstant.GETUSERINFO_SUCCESS, user: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Get Users Failed"));
	}
	dispatch({ type: AdminUserConstant.LOADED });
	return ret;
};

const UpdateListFilter = (filter) => async (dispatch) => {
	function success(user) {
		return { type: AdminUserConstant.UPDATE_LISTFILTER, filter };
	}
};

const GetUserInfoByAdminId = (adminid) => async (dispatch) => {
	dispatch({ type: AdminUserConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user/' + adminid, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: AdminUserConstant.GETUSERINFO_SUCCESS, user: ret.data.data });
	}
	dispatch({ type: AdminUserConstant.LOADED });
}

const GetComboListByCompanyId = async () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user/getcomboByCompanyId/' + user.companyInfo.companyId, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}

const GetUserListByCompanyId = async (companyId) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user/getUserListByCompanyId/' + companyId, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}

const GetReducer = (state) => {
	return state.AdminUserReducer;
};

export const AdminUserAction = {
	GetUserAll,
	GetUser,
	UpdateUserInfo,
	RegistUserInfo,
	UpdateListFilter,
	ClearUpdateResult,
	DeleteUser,
	GetReducer,
	GetComboListByCompanyId,
	GetUserInfoByAdminId,
	GetUserListByCompanyId
};
