import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Translate as t } from '../../config/Translate';

import {
	AggrProductCountAll,
	AggrProductCountInstalled,
	AggrProductNewInstalled,
	AggrProductInstallationsByCountry,
	AggrProductInstallationByCountryLastNdays,
	AggrNewInstalledByCountry,
	AggrModelPercentByNDays,
	AggrInventoryProductCount,
	AggrInventoryProduct,
	AggrInventoryProductByCountry,
	AggrTotalProductByCountry,
	AggrProductsInstalledLastNDays,
	AggrProductCountry,
	AggrModelPercent2,

} from '../../config/Aggregates';

import WdigetItemCountCard from '../../widgets/WdigetItemCountCard';
import WidgetCustomTable from '../../widgets/WidgetCustomTable';
import { InputBasicNumber } from '../../components/common/Inputs';
import { AuthAction } from '../../actions';

const AdminProductDashboard = ({ user, deviceCategory, UpdateUserAdminSetting, showmodel }) => {

	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setData({
			dashboardSettingProductNewInstalledTile: user.dashboardSettingProductNewInstalledTile || 7,
			dashboardSettingProductNewInstalledByModel: user.dashboardSettingProductNewInstalledByModel || 7,
			dashboardSettingProductLastInstalled: user.dashboardSettingProductLastInstalled || 7
		})
	}, [user]);

	const openSettingPopup = (key, title) => {
		setSelected({ key, title, value: data[key] });
		setShowModal(true);
	}

	const updateDashboardSetting = () => {
		UpdateUserAdminSetting({ [selected.key]: selected.value });
		setShowModal(false);
	}

	const handleChange = (e) => {
		setSelected({ ...selected, value: e.target.value });
	}

	const initializeUpdateData = () => {
		setShowModal(false);
	}

	return (
		<>
			<div className="extraHeader pb-0">
				<h2 className="mb-0">{t('productdashboard')}</h2>
				<div className="ray-right">
					{
						(user.adminType == 'S' && user.companyId != 'RAY02091') && <Link to="/Admin/AdminProductImport" className="btn btn-sm mt-0">
							<ion-icon name="share-outline"></ion-icon> {t('importdevices')}
						</Link>
					}
					<Link to="/Admin/AdminProductList" className="btn btn-sm mt-0">
						<ion-icon name="list-outline"></ion-icon> List
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{/* 상단카드 */}
				<div className="section dashboardTop pt-2">
					<div className="row">
						<div className="col">
							<WdigetItemCountCard
								title={t('product')}
								subtitle={t('all')}
								aggregate={AggrProductCountAll(deviceCategory)} />
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('installed')}
								subtitle={t('all')}
								aggregate={AggrProductCountInstalled(deviceCategory)} />
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('inventory')}
								subtitle={t('all')}
								aggregate={AggrInventoryProductCount(deviceCategory)} />
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('newinstalled')}
								subtitle={`${data.dashboardSettingProductNewInstalledTile} ${t('days')}`}
								aggregate={AggrProductNewInstalled(deviceCategory, data.dashboardSettingProductNewInstalledTile)}
								setting={() => openSettingPopup('dashboardSettingProductNewInstalledTile', `${t('newinstalled')} (${t('days')})`)}
							/>
						</div>
					</div>
				</div>
				{/* 첫쨰줄 */}
				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<WidgetCustomTable
							title={t('model')}
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrModelPercent2(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('model')} (${(t('installed'))})`}
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrModelPercentByNDays(deviceCategory, null)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('model')} (${(t('inventory'))})`}
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrInventoryProduct(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('model')} (${(t('newinstalled'))})`}
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrModelPercentByNDays(deviceCategory, data.dashboardSettingProductNewInstalledByModel)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingProductNewInstalledByModel', `${t('model')} (${t('newinstalled')}) (${t('days')})`)}
						/>
					</div>
				</div>
				{/* 둘째줄 */}
				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<WidgetCustomTable
							title={t('totalproductbycountry')}
							headers={[
								{ name: "_id.label", label: t('country'), type: "COUNTRY", showType: '' },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrTotalProductByCountry(deviceCategory)}
							height={250}
						/>
					</div>
					<div className="col">
						<WidgetCustomTable
							title={t('installationsbycountry')}
							headers={[
								{ name: "_id.label", label: t('country'), type: "COUNTRY", showType: '' },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrProductInstallationsByCountry(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={t('inventorybycoutnry')}
							headers={[
								{ name: "_id.label", label: t('country'), type: "COUNTRY", showType: '' },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrInventoryProductByCountry(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={t('lastinstalledproducts')}
							headers={[
								{ name: "sites.country", label: "", type: "COUNTRY", showtype: "ONLYFLAG", style: { width: 30 } },
								{ name: "sn", label: t('sn'), type: "SN" },
								{ name: "model", label: t('model') },
								{ name: "site.sitename", label: t('site'), linkdata: 'siteId' },
								{ name: 'installedDate', label: t('installed'), type: 'DTONLY' }
							]}
							aggregate={AggrProductsInstalledLastNDays(deviceCategory, data.dashboardSettingProductLastInstalled)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingProductLastInstalled', `${t('lastinstalledproducts')} (${t('days')})`)}
							linkKey="sites._id"
							basePath="/Admin/AdminSiteInfo/"
							basePathKey=""
						/>
					</div>
				</div>

				<Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)}>
					<Modal.Header>
						<Modal.Title>{t('settings')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='p-1'>
							<InputBasicNumber
								options={{
									label: selected.title,
									showpin: true,
									val: selected.value,
								}}
								editable={true}
								onchange={handleChange}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => updateDashboardSetting()}>{t('save')}</button>
						<button className="btn btn-block btn-dark" onClick={() => initializeUpdateData()}>{t('close')}</button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var deviceCategory = state.ProgramReducer.deviceCategory;
	const showmodel = state.ProgramReducer.showmodel;
	return { user, deviceCategory, showmodel };
}

const mapDispatch = (dispatch) => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
})

export default connect(mapState, mapDispatch)(AdminProductDashboard);
