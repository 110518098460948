import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CompanyAction, AdminUserAction } from '../../actions';
import { LanguageCode } from '../../config';
import { RayForm } from '../../components/common';
import CustomFieldValues from '../../components/customfield/CustomFieldValues';
import { AdminFunctions, AdminTypes, AdminPosition } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';

const AdminUserEditInfo = ({ authuser, user, companyList, canedit, UpdateUserInfo, editable }) => {

    const [companies, setCompanies] = useState([]);
    const [guardeditable, setGuardEditable] = useState(false);
    const [adminTypeList, setAdminTypeList] = useState(AdminTypes);

    useEffect(() => {
        //GetCompany();
    }, []);

    useEffect(() => {
        if (editable) {
            if (authuser.adminType != "U" || (authuser.companyInfo && authuser.companyInfo.org == "HQ")) {
                setGuardEditable(true);
            }
        } else {
            setGuardEditable(false);
        }
    }, [editable]);

    useEffect(() => {
        if (guardeditable) {
            if (authuser.companyInfo && authuser.companyInfo.org == "HQ") {
                setAdminTypeList(AdminTypes);
            } else if (authuser.adminType == "M") {
                setAdminTypeList(AdminTypes.filter(x => x.code != "S"));
            } else if (authuser.adminType == "U") {
                setAdminTypeList(AdminTypes.filter(x => x.code == "U"));
            } else if (authuser.adminType == "S") {
                setAdminTypeList(AdminTypes);
            }
        } else {
            setAdminTypeList(AdminTypes);
        }
    }, [guardeditable]);

    useEffect(() => {
        if (companyList.length > 0)
            setCompanySelect();
    }, [companyList]);

    const setCompanySelect = () => {
        const cList = [];
        companyList.map((c) => cList.push({ code: c.companyId, title: c.name }));
        setCompanies(cList);
    }

    var form_controls = [
        {
            title: `${t('user')} ${t('information')}`, items: [
                { type: "input", name: "name", label: t('name'), val: user.name, isEditable: editable },
                { type: "select2", name: "companyId", label: t('company'), val: user.companyId, list: companies, required: true, isEditable: editable, onlyOne: true },
                { type: "input", name: "firstName", label: t('firstname'), val: user.firstName, isEditable: editable },
                { type: "input", name: "lastName", label: t('lastname'), val: user.lastName, isEditable: editable },
                { type: "email", name: "email", label: t('email'), val: user.email, required: true, isEditable: editable },
                { type: "tel", name: "hp", label: t('mobile'), val: user.hp, isEditable: editable },
                { type: "tel", name: "tel", label: t('tel'), val: user.tel, isEditable: editable },
                { type: "select", name: "language", label: t('language'), val: user.language, list: LanguageCode, isEditable: editable }
            ]
        },
        {
            title: `RAYGuard ${t('settings')}`, items: [
                { type: "select", name: "adminType", label: t('admintype'), val: user.adminType, list: adminTypeList, required: true, isEditable: guardeditable },
                { type: "select", name: "function", label: t('function'), val: user.function, list: AdminFunctions, required: true, isEditable: guardeditable },
                { type: "select", name: "position", label: t('position'), val: user.position, list: AdminPosition, required: true, isEditable: guardeditable },
                { type: "input", name: "csPortalLink", label: `CS Portal ${t('link')}`, size: 12, val: user.csPortalLink, isEditable: guardeditable },
                { type: "checkbox", name: "isUse", label: "Use", val: user.isUse, isEditable: guardeditable },
                { type: "checkbox", name: "permAdminAccess", label: "Admin Access", size: 12, val: user.permAdminAccess, isEditable: guardeditable },
            ]
        },
    ]

    const UpdateExtra = (code, value) => {
        UpdateUserInfo(user.adminid, {
            customfieldvals: { ...user.customfieldvals, [code]: value }
        });
    }

    return (
        <>
            <div className="section mt-2 mb-2 text-center">
                <div className="avatar-section">
                    {user.avatar && <img src={user.avatar} alt="avatar" className="imaged w100 rounded" />}
                    {!user.avatar && <img src="/assets/img/avatar_default_none.png" alt="avatar" className="imaged w100 rounded" />}
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <RayForm
                        controls={form_controls}
                        action={UpdateUserInfo}
                        showAllFields={true}
                        actionkey={user.adminid} />
                </div>
                <div className="col-md-4 p-2">
                    <CustomFieldValues model={"USER"} update={UpdateExtra} data={user.customfieldvals || {}} />
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    const companyList = state.CompanyReducer.companies;
    const authuser = state.AuthReducer.user;
    return { authuser, companyList };
}

const mapDispatch = dispatch => ({
    UpdateUserInfo: (adminid, data) => dispatch(AdminUserAction.UpdateUserInfo(adminid, data)),
    //GetCompany: () => dispatch(CompanyAction.GetCompany()),
})
export default connect(mapState, mapDispatch)(AdminUserEditInfo);