import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ServiceAction, AdminUserAction } from '../../actions';
import { GetServiceTypeChip } from '../common/CommonViews';
import { Link } from 'react-router-dom';
import { ExportExcelClass } from '../common/Exports';
import { Translate as t } from '../../config/Translate';

const ServiceInfoLogs = ({ service, allUsers, Users }) => {
	// const [t] = useTranslation();
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const excepts = ["updater", "updateDate", "scheId"];
	const [filterList, setFilterList] = useState([]);
	const [filter, setFilter] = useState("");
	const [sort, setSort] = useState("asc");
	const [list, setList] = useState([]);
	const [exportData, setExportData] = useState();
	const [userList, setUserList] = useState([]);

	useEffect(() => {
		Users();
	}, [])

	useEffect(() => {
		setUserList([...allUsers]);
	}, [allUsers]);


	useEffect(() => {
		var fList = [""];
		if (service.histories.length > 0) {
			service.histories.map(x => {
				if (!fList.includes(x.actionCode))
					fList.push(x.actionCode);
			})
		}
		setFilterList(fList);
	}, [service])

	useEffect(() => {
		setList(service.histories.filter(x => filter ? x.actionCode == filter : x));
	}, [filter])

	useEffect(() => {
		exportItems();
	}, [list])

	const getUserName = (adminId) => {
		let userName = "";

		userList.forEach(x => {
			if (x.adminid == adminId)
				userName = x.firstName + ' ' + x.lastName;
		});

		return userName;
	}


	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(
					<React.Fragment key={idx}>
						{line}
						<br />
					</React.Fragment>
				);
			});
		} else {
			return 'N/A';
		}
		return html;
	};

	const getDot = type => {
		if (type) {
			if (type.indexOf("ADD") > -1)
				return "dot bg-primary";
			if (type.indexOf("DEL") > -1)
				return "dot bg-danger";
			if (type.indexOf("UPDATE") > -1)
				return "dot bg-info";
		}
		return "dot bg-primary";
	}

	const fromCamel = (v) => {
		const d = v.replace(/([a-z])([A-Z])/g, '$1 $2');
		return d.charAt(0).toUpperCase() + d.slice(1);
	}

	const kVal = (key, val) => {
		if (key.toUpperCase().indexOf("DATE") > -1)
			return moment(val).format("YYYY-MM-DD HH:mm");
		return val;
	}

	const getMessage = (msg) => {
		var ret = [];
		try {
			const json = JSON.parse(msg);
			if (json.hasOwnProperty("replacements")) {
				json.replacements.map((x,idx) => {
					ret.push(<div key={idx}>{x.title}({x.subtitle}) : {x.oldsn} -&gt; {x.newsn}</div>);
				})
			} else {
				Object.keys(json).forEach(function (key) {
					if (!excepts.includes(key))
						ret.push(<div>{fromCamel(key) + " : " + kVal(key, json[key])}</div>);
				})
			}
			return ret;
		} catch (e) {
			ret.push(msg);
			return <div>{ret}</div>;
		}
	}

	const getSortList = () => {
		return sort == "asc" ?
			list.sort((a, b) => a.createDate - b.createDate) :
			list.sort((a, b) => b.createDate - a.createDate);
	}

	const exportItems = () => {
		var items = [];
		getSortList().map((c, idx) => {
			if (filter && filter != c.actionCode)
				return;
			items.push({ ...c, createDate: moment.utc(c.createDate).local().format('YYYY-MM-DD HH:mm:ss') });
		});
		setExportData({
			...exportData,
			items: items,
			filename: service.ticketKey + " - Logs" + (filter && "(" + filter + ")"),
			sheettitle: "Logs",
			headers: [
				{ label: t('type'), name: "actionCode" },
				{ label: t('comments'), name: "comment" },
				{ label: t('creator'), name: "creator" },
				{ label: t('date'), name: "createDate" },
			]
		});
	}

	return (
		<>
			{service.histories.length == 0 && <div className="text-muted text-center mt-1 mb-1">No Logs</div>}
			{service.histories.length > 0 && (
				<div>
					<div className="p-1 row">
						<div className="col">
							<select name="filter"
								className="form-control form-control-sm float-start"
								style={{ width: 200 }}
								value={filter}
								onChange={(e) => setFilter(e.target.value)}>
								{filterList.map((x, idx) => <option key={idx} value={x}>{x ? x : "ALL"}</option>)}
							</select>
						</div>
						<div className="col text-right">
							<button className="btn btn-sm btn-secondary m-0 me-1"
								onClick={(e) => setSort(sort == "asc" ? "desc" : "asc")}>
								{t('sort')} : {sort == "asc" && <ion-icon name="arrow-down-outline"></ion-icon>}
								{sort == "desc" && <ion-icon name="arrow-up-outline"></ion-icon>}
							</button>
							{isAdmin && <ExportExcelClass data={exportData} />}
						</div>
					</div>
					<div className="border-top">
						<div className="timeline ms-3 me-1">
							{getSortList().map((c, idx) => {
								if (filter && filter != c.actionCode)
									return;
								return <div className="item" key={idx}>
									<div className={getDot(c.actionCode)}></div>
									<div className="content">
										<div className="title mb-05">
											<GetServiceTypeChip type={c.actionCode} />
											<div className="float-end me-1 text-right">
												<span className="text-muted">{moment.utc(c.createDate).local().fromNow()}{' '}</span><br />
												<Link to={(isAdmin ? "/Admin/AdminUserInfo/" : "/UserInfo/") + c.creator} className="ms-1 text-right">{getUserName(c.creator)}</Link>
											</div>
										</div>
										<div className="text">
											{c.comment && (
												<i>{getMessage(c.comment)}</i>
											)}
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const allUsers = state.AdminUserReducer.users;
	return { service, allUsers };
};

const mapDispatch = (dispatch) => ({
	Comment: (_id, type, data) => dispatch(ServiceAction.Comment(_id, type, data)),
	Users: () => dispatch(AdminUserAction.GetUserAll())
});

export default connect(mapState, mapDispatch)(ServiceInfoLogs);
