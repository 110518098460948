import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DeviceModels, GetRayDeviceGuide, SpecialityTypes } from '../../config/RayCodes';
import { InputBasicTextArea } from '../../components/common/Inputs';
import { FileAddList } from '../../components/common/FileAddList';
import { SiteSurveyAction, RayCodeAction, ProductAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { useTranslation } from 'react-i18next';
import { FileItem, FileUpload } from '../common/FileUpload';
import { Translate as t } from '../../config/Translate';

const SurveyStep3 = ({ Update, info, isSummary = false, Attachment, gostep }) => {
	// const [ t, i18n ] = useTranslation();
	const [siteSurvey, setSiteSurvey] = useState({ ...info, siteSurveyStep: 5 });
	const [roomdimensions, setRoomDimension] = useState({
		space: "",
		img: ""
	});

	useEffect(() => {
		if (gostep) {
			if (gostep.curStep == 4) {
				Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: gostep.goStepNo });
			}
		}
	}, [gostep])

	useEffect(() => {
		setSiteSurvey(info);
	}, [info]);

	const Prev = (e) => {
		Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 3 });
	};

	const validNext = () => {
		Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 5 });
	};

	useEffect(() => {
		if (siteSurvey.model) {
			console.log('SurveyStep3 ---> ', clist?.filter(x => x.type === 'PRODUCTOPTION' && x.group === siteSurvey.model && x.code === siteSurvey.option));
			const x = clist?.filter(x => x.type === 'PRODUCTOPTION' && x.group === siteSurvey.model && x.code === siteSurvey.option);
			//RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION", group: siteSurvey.model, code: siteSurvey.option }).then(x => {
			if (x.length > 0) {
				setRoomDimension(GetRayDeviceGuide(x[0].model, x[0].option));
			} else {
				setRoomDimension(GetRayDeviceGuide("", ""));
			}
			//});
		} else {
			setRoomDimension(GetRayDeviceGuide("", ""));
		}
	}, [siteSurvey, clist]);

	const filelist = (list) => {
		setSiteSurvey({ ...siteSurvey, ["attachments"]: list });
	}
	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setSiteSurvey({ ...siteSurvey, [name]: value });
	}

	const setNewFile = (file, fileType) => {
		Attachment(siteSurvey._id, "ADD", {
			...siteSurvey,
			name: file.original,
			path: file.s3path,
			attachmentType: fileType
		});
	};

	const delFile = (c) => {
		Attachment(siteSurvey._id, "DEL", {
			...siteSurvey,
			delAttachid: c._id
		});
	};

	return (
		<>
			{!isSummary && <div className={isSummary ? "" : "section mt-1"}>
				<div className={isSummary ? "section-title bdB2" : "section-title"}>{t('roomdimensions')}</div>
				<blockquote className="blockquote mt-1">
					<p className="mb-0">{t('roomwarnig1')}</p>
				</blockquote>
				<blockquote className="blockquote">
					<p className="mb-0">{t('roomwarnig2')}</p>
				</blockquote>
			</div>}
			{!isSummary &&
				<div className={isSummary ? "" : "section mt-1"}>
					<div className={isSummary ? "section-title bdB2" : "section-title"}>{t('minimumspace')}</div>
					<div className="card">
						<div className="card-body">
							<div>
								<div><p style={{ whiteSpace: 'pre-line' }}> {roomdimensions.space.replace("Width door", t('widthdoor')).replace("Width", t('width')).replace("Depth", t('depth')).replace("Height", t('height'))} </p></div>
							</div>
							<div>
								<img src={roomdimensions.img} className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			}
			<div className={isSummary ? "" : "section mt-1"}>
				<div className={isSummary ? "section-title bdB2" : "section-title"}>{t('attachments')}</div>
				{!isSummary &&
					<>
						<div className="card">
							<div className="card-body">
								<FileUpload
									option={{ doneandnew: true, label: t('selectafile') }}
									extraData={{ path: "siteSurvey/" + siteSurvey._id + "/" }}
									callback={(e) => setNewFile(e, "room")}
								/>
							</div>
						</div>
						<ul className="list-group">
							{siteSurvey.attachments.map((p, idx) =>
								p.type == "room" &&
								<li className='list-group-item ps-1' key={p._id}>
									<FileItem fileInfo={{ ...p, original: p.name, s3path: p.path }} callback={(p) => delFile(p)} key={idx} />
								</li>
							)}
						</ul>
					</>
				}
				{isSummary && <ul className="list-group">
					{siteSurvey.attachments.map((p, idx) =>
						p.type == "room" &&
						<li className='list-group-item ps-1' key={p._id}>
							<FileItem fileInfo={{ ...p, original: p.name, s3path: p.path }} callback={(p) => delFile(p)} key={idx} />
						</li>
					)}
				</ul>}
			</div>
			<div className={isSummary ? "" : "section mt-1"}>
				<div className={isSummary ? "section-title bdB2" : "section-title"}>{t('finalcomment')}</div>
				{!isSummary && <div className="card">
					<div className="card-body">
						<InputBasicTextArea
							options={{ val: siteSurvey.finalComment, name: "finalComment", label: t('comment') }}
							editable={!isSummary}
							isForm={true}
							onchange={handleChangeData} />
					</div>
				</div>}
				{isSummary && <div className="pb-1 pt-1">
					<InputBasicTextArea
						options={{ val: siteSurvey.finalComment, name: "finalComment", label: t('comment') }}
						editable={!isSummary}
						isForm={true}
						onchange={handleChangeData} />
				</div>}
			</div>
			{!isSummary &&
				<div className="section mt-1 mb-2">
					<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
					<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('prev')}</button>
				</div>
			}
		</>
	);
};

const mapState = (state) => {
	const clist = state.RayCodeReducer.items;
	return { clist };
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(SiteSurveyAction.UpdateSiteSurvey(_id, data)),
	Attachment: (_id, type, data) => dispatch(SiteSurveyAction.Attachment(_id, type, data)),
});

export default connect(mapState, mapDispatch)(SurveyStep3);