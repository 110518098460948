import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../common/Loading';
import { ProductAction } from '../../actions';
import { InfinteScroll } from '../../components/common/InfinteScroll';
import ProductErrorCard from './ProductErrorCard';

const ProductErrorList = ({ GetList, items, totalCount, isLoading, onlyshow = false }) => {

	const [target, setTarget] = useState(null);
	const [page, setPage] = useState(1);
	const [itemperpage, setItemPage] = useState(30);

	useEffect(() => {
		GetList({ itemperpage: itemperpage, page: page }, true);
	}, [page]);

	const isEnd = () => { return totalCount <= page * itemperpage; }

	InfinteScroll({
		target,
		onIntersect: ([{ isIntersecting }]) => {
			if (isIntersecting && !isLoading && !isEnd() && !onlyshow) {
				setPage(page + 1);
			}
		}
	});

	return (
		<div className="section">
			{items.length > 0 && items.map((item, idx) => {
				return (
					<ProductErrorCard item={item} key={"err" + item.errs._id + (idx + page)} />
				)
			})}
			{isLoading && <Loading />}
			<div ref={setTarget} className='last-item' />
		</div>
	);
};

const mapState = (state, ownProps) => {
	if (ownProps.onlyshow) {
		return {}
	} else {
		var items = state.ProductReducer.producterrs;
		var totalCount = state.ProductReducer.totalproducterrcount;
		var isLoading = state.ProductReducer.isErrLoading;

		return { items, totalCount, isLoading };
	}
};

const mapDispatch = (dispatch) => ({
	GetList: (filter, append) => dispatch(ProductAction.GetErrors(filter, append)),
});

export default connect(mapState, mapDispatch)(ProductErrorList);