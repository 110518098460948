import React, { useState, useEffect } from 'react';
import { AuthAction, ProductAction } from '../../actions';
import { connect } from 'react-redux';
import './register-product-style.css';
import { Modal } from 'react-bootstrap';
import { Translate as t } from '../../config/Translate';
import { InputBasicText } from '../../components/common';
import { CountryCode } from '../../config';
import { InputBasicDateTime, InputBasicEmail, InputBasicTel, InputCombo, InputSelect2 } from '../../components/common/Inputs';

const ProductRegistByUser = ({ user, loggingIn, isAdmin, login, logout }) => {
	const [data, setData] = useState({ });
	const [loading, setLoading] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);
	const [result, setResult] = useState({
		done : false,
		issuccess : true,
	});

	const handleChangeData = (e) => {
		setData({...data, [e.target.name] : e.target.value});
	}

	const submit = async () => {
		setLoading(true);
		const ret = await ProductAction.RegistProductSiteByUser(data);
		if(ret.data.status === 'success'){
			setResult({ done : true, issuccess : true });
		}else{
			setResult({ done : true, issuccess : false, msg : ret?.data?.err?.message || 'Please try again later or contact customer support.' });
		}
		setData({ });
		setLoading(false);
	}

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	const isValid = () => {
		return data.sn && data.email && data.country && data.sitename && data.address && data.tel
	}

	const isMobile = width <= 768;

	return (
		<>
			<div className="main regproduct">
				<div className="section mt-2 text-center">
					<img src="/assets/img/logo.svg" className="logo my-4" />
					<h2>Register Your Product</h2>
					<p className="mt-1">
						Registering your product ensures better service.
					</p>
				</div>
				<div className="section p-2">
					{!result.done && <>
						<div className="card" style={{ boxShadow : 'initial' }}>
							<div className="card-body">
								<InputBasicText
									options={{ val: data.sn, name: "sn", label: t('Serial Number') }}
									inline={false}
									editable={true}
									onchange={handleChangeData} />
								<InputBasicDateTime 
									options={{ val: data.installed, name: "installed", onlyDate : true, label: t('Installation Date') }}
									inline={false}
									editable={true}
									onchange={handleChangeData} />
								<InputBasicEmail
									options={{ val: data.email, name: "email", label: t('E-mail') }}
									inline={false}
									editable={true}
									onchange={handleChangeData} />
								<InputBasicText
									options={{ val: data.sitename, name: "sitename", label: t('Site Name') }}
									inline={false}
									editable={true}
									onchange={handleChangeData} />
								<InputSelect2 options={{
									type: "select",
									name: "country",
									label: "Country",
									val: data.country,
									list: CountryCode
								}} onchange={handleChangeData} editable={true} />
								<InputBasicText
									options={{ val: data.address, name: "address", label: t('Address') }}
									inline={false}
									editable={true}
									onchange={handleChangeData} />
								<InputBasicTel
									options={{ val: data.tel, name: "tel", label: t('Tel') }}
									inline={false}
									editable={true}
									onchange={handleChangeData} />
							</div>
							{!isMobile && <div className='ps-2 pe-2'>
								<button className={"item btn btn-primary btn-block btn-lg " + (isValid() ? '' : ' disabled ') + ( !loading ? '' : ' disabled ')}
									onClick={() => submit()}>{loading ? t('Please wait...') : t('Register')}</button>
							</div>}
						</div>
						{isMobile && <div className="form-button-group">
							<button className={"item btn btn-primary btn-block btn-lg " + (isValid() ? '' : ' disabled ') + ( !loading ? '' : ' disabled ')}
								onClick={() => submit()}>{loading ? t('Please wait...') : t('Register')}</button>
						</div>}
					</>}

					{result.done && <>
						<Modal show={result.done}>
							<Modal.Header>
								{result.issuccess && <h5 className="modal-title text-primary"><strong>Success</strong></h5>}
								{!result.issuccess && <h5 className="modal-title text-danger"><strong>Failed</strong></h5>}
							</Modal.Header>
							<Modal.Body>
								{result.issuccess && <>Your product registration is complete.<br />Thank you.</>}
								{!result.issuccess && <>{result.msg}</>}
							</Modal.Body>
							<Modal.Footer>
								<a href="#" className="btn" onClick={() => setResult({...result, done : false})}>CLOSE</a>
							</Modal.Footer>
						</Modal>

					</>}

				</div>

			</div>
		</>
	);
};

const mapState = (state) => {
	return {
		loggingIn: state.AuthReducer.loggingIn,
		user: state.AuthReducer.user,
	};
};

const mapDispatch = (dispatch) => ({
	login: (username, password) => dispatch(AuthAction.Login(username, password)),
	logout: () => dispatch(AuthAction.Logout()),
});

export default connect(mapState, mapDispatch)(ProductRegistByUser);
