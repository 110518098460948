import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { RayTable, RayTreeview, AdminProductListHeader } from '../../components/common';
import { ProductAction } from '../../actions/ProductAction';
import { CompanyAction } from '../../actions';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Translate as t } from '../../config/Translate';

const ModalTransfer = ({ showModal, type, callback, callbackClose }) => {

	const [companyList, setCompanyList] = useState([]);

	const [company, setCompany] = useState({
		companyId: "",
		comment: ""
	});

	useEffect(() => {
		getCompanys()
	}, [])

	const getCompanys = async () => {
		const list = await CompanyAction.GetCompanyLocal()
		setCompanyList(list);
	}

	return (
		<Modal show={showModal} onHide={() => callbackClose(false)} className="action-sheet">
			<Modal.Header>
				<h2 className="modal-title">{t('productstocklist')}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<ul className="listview simple-listview no-space border-0 m-2">

					<div className="form-group basic">
						<div className="input-wrapper">
							<label className="label">Transfer to</label>
							<Select
								value={company.companyId}
								getOptionLabel={option => option.name}
								getOptionValue={option => option.companyId}
								onChange={(e) => setCompany({ e, comment: company.comment })}
								options={companyList} />
						</div>
					</div>

					<div className="form-group basic">
						<div className="input-wrapper">
							<label className="label">Description</label>
							<textarea className="form-control"
								rows={5}
								onChange={(e) => setCompany({ ...company, comment: e.target.value })}
								defaultValue={company.comment}></textarea>
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn btn-block btn-primary mb-0"
					onClick={() => { callback(company); callbackClose(false); }}>Transfer</button>
				<button className="btn btn-block btn-dark" onClick={() => callbackClose(false)}>Cancel</button>
			</Modal.Footer>
		</Modal>
	);
}

const AdminProductStockList = ({ su, user, GetProductAllStock, BulkUpdateProductInfo, products, totalproductcount,
	filterDateOptions, filterOptions, filters, sort, companies, viewTree }) => {

	const [showModal, setShowModal] = useState(false);
	const [bulkList, setBulkList] = useState([]);
	const [bulkType, setBulkType] = useState("");

	const [wHg, setwHg] = useState(300);
	const [wAll, setwAll] = useState(window.innerWidth);
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });
	const dispatch = useDispatch();

	const filterpage = {
		filters: filters || {},
		filterOptions: ['model'],
		searchCols: ['model', 'option', 'sn', 'company'],
		sort: sort || { updateDate: -1 },
		searchKey: "",
		page: 1,
		itemperpage: user.settings.dashboardtablelistcount || 30
	};

	const options = {
		isShowCheckBox: true,
		search: true,
		keyCol: 'sn',
		defaultSort: filterpage.sort && Object.keys(filterpage.sort)[0],
		defaultSortType: filterpage.sort && Object.values(filterpage.sort)[0],
		linkto: '/Admin/AdminProductInfo',
		getList: GetProductAllStock,
		linkkey: 'sn',
		filterpageoption: filterpage,
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalproductcount,
		filterOptions: filterOptions
	};

	const checkedList = (list) => {
		setBulkList(list);
	}

	const doBulkCallback = (company) => {
		var data = {
			companyId: company.e.companyId,
			"$push": {
				histories: {
					type: 'TRANSFER',
					user: user.adminid,
					contents: 'Transfer to ' + company.e.name,
					description: 'Bulk process',
					timestamp: new Date().getTime(),
				}
			}
		}
		//console.log(data);
		//console.log(bulkList);
		setShowModal(false);
		BulkUpdateProductInfo(bulkList, data);
	}

	const bulkActionDo = (action) => {
		setBulkType(action);
		setShowModal(true);
	}

	const bulkActions = [
		{ title: "Transfer", action: "TRANSFER" },
	]

	useEffect(() => {
		if (viewTree) {
			setwHg(300);
		} else {
			setwHg(0);
		}
	}, [viewTree]);

	const getWidth = () => setwAll(window.innerWidth);

	useEffect(() => {
		// window.addEventListener('resize', getWidth);
		// setwAll(window.innerWidth);
		dispatch({ type: ProgramConstant.SET_PRODUCTLISTISSTOCK, isstock: true })
	}, []);

	const onChangeTreeviewItem = (item) => {
		setFilterCompanyId({ companyId: item.id });
		setFilterCompany(item);
	}

	const updateViewTree = (bl) => {
		dispatch({ type: ProgramConstant.VIEWTREE, val: bl });
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('productstocklist')}
					{companies.length > 1 && <button className="btn btn-secondary btn-sm mt-0 ms-1" onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>}
				</h2>
				<div className="ray-right">
					<Link to="/Admin/AdminProductDashboard" className="btn btn-sm mt-0">
						<ion-icon name="desktop-outline"></ion-icon> {t('dashboard')}
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{(viewTree && user.adminType != "U") &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>}
				<div className="section m-1 p-0">
					<div className="card p-2">
						<div className={user.adminType != "U" ? "fl" : ""}>
							<RayTable
								data={products}
								addFilter={filterCompanyId}
								bulkActions={bulkActions}
								bulkActionDo={bulkActionDo}
								checkBoxCallback={(list) => checkedList(list)}
								columns={AdminProductListHeader}
								options={options}
							/>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			</div>
			<ModalTransfer showModal={showModal}
				callbackClose={() => setShowModal(false)}
				type={bulkType}
				callback={(comment) => doBulkCallback(comment)} />
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var su = state.AuthReducer.su;
	var viewTree = state.ProgramReducer.viewtree;
	var companies = state.CompanyReducer.companies;
	var products = state.ProductReducer.products;
	var totalproductcount = state.ProductReducer.totalproductcount;
	var filterOptions = state.ProductReducer.filterOptions;
	var filterDateOptions = state.ProductReducer.filterDateOptions;
	var filters = state.ProductReducer.filters;
	var sort = state.ProductReducer.sort;
	return { user, su, products, totalproductcount, filterOptions, filters, sort, filterDateOptions, viewTree, companies };
};

const mapDispatch = (dispatch) => ({
	GetProductAllStock: (filter) => dispatch(ProductAction.GetProductAllStock(filter)),
	BulkUpdateProductInfo: (sns, data) => dispatch(ProductAction.BulkUpdateProductInfo(sns, data)),
});

export default connect(mapState, mapDispatch)(AdminProductStockList);