import { ProductConstant } from '../actions/constants/ProductConstant';
import { SiteConstant } from '../actions/constants/SiteConstant';

const uData =  JSON.parse(localStorage.getItem('user'));
const initialState = {
	sn : uData ? (uData.favorites || []) : [],
	site : uData ? (uData.favoriteSites || []) : [],
	service : uData ? (uData.favoriteServices || []) : [],
	productoptions : uData ? (uData.favoriteProductOptions || []) : [],
	products : [],
	sites : [],
};

export const FavoriteReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProductConstant.UPDATE_FAVS:
			if(action.favs.type == "productoptions")
				return { ...state, productoptions : [...action.favs.data.favoriteProductOptions] }
			if(action.favs.type == "sn")
				return { ...state, sn : [...action.favs.data.favorites] }
			if(action.favs.type == "site")
				return { ...state, site : [...action.favs.data.favoriteSites] }
			if(action.favs.type == "service")
				return { ...state, service : [...action.favs.data.favoriteServices] }
		default:
			return state;
	}
};
