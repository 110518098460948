import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { RayCodeConstant } from './constants/RayCodeConstant';
import { AlertAction } from './AlertAction';

const GetAll = () => async (dispatch) => {
	dispatch({ type: RayCodeConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/code', JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: RayCodeConstant.GET_RAYCODELIST, items: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: RayCodeConstant.LOADED });
};

const GetAllByFilter = async (filter) => {
	const data = {
		type: 'getfilter',
		data: filter,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/code', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const GetOne = (_id) => async (dispatch) => {
	dispatch({ type: RayCodeConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/code/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: RayCodeConstant.GET_RAYCODE, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: RayCodeConstant.LOADED });
};

const Delete = (_id) => async (dispatch) => {
	dispatch({ type: RayCodeConstant.LOADING });
	const data = {
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/code/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: RayCodeConstant.DELETE_RAYCODE, _id: _id });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: RayCodeConstant.LOADED });
};

const Create = (info) => async (dispatch) => {
	dispatch({ type: RayCodeConstant.LOADING });
	const data = {
		type: 'create',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/code', data, JWT());

	console.log(ret);

	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: RayCodeConstant.CREATE_RAYCODE, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: RayCodeConstant.LOADED });
};

const AddUpdate = (info) => async (dispatch) => {
	dispatch({ type: RayCodeConstant.ADDUPDATE, item: info });
};

const Update = (_id, info) => async (dispatch) => {
	dispatch({ type: RayCodeConstant.LOADING });
	const data = {
		type: 'update',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/code/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: RayCodeConstant.UPDATE_RAYCODE, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: RayCodeConstant.LOADED });
};

export const RayCodeAction = {
	GetAll,
	GetOne,
	GetAllByFilter,
	Create,
	Update,
	Delete,
	AddUpdate
};
