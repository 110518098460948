import { SparePartsConstant } from '../actions/constants/SparePartsConstant';
const initialState = {
    spareParts: [],
    sparePart: {},
    groupList: [],
    group: '',
    companyStockList: [],
    companyStockInfo: {},
    companyStockInList: [],
    orderContents: {},
    orderList: [],
    order: {},
    outList: [],
    companyOutList: [],
    outContents: {},
    outHistory: []
};

export const SparePartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SparePartsConstant.REGIST_SPARE_PARTS:
        case SparePartsConstant.GET_SPARE_PARTS_LIST:
            return { ...state, spareParts: action.spareParts }

        case SparePartsConstant.UPDATE_SPARE_PARTS:
            return { ...state, sparePart: action.sparePart.isDel ? {} : action.sparePart }

        case SparePartsConstant.GET_ONE_SPARE_PARTS:
        case SparePartsConstant.ADD_SPARE_PARTS_HISTORY:
        case SparePartsConstant.UPDATE_SPARE_PARTS_HISTORY:
            return { ...state, sparePart: action.sparePart }

        case SparePartsConstant.GET_GROUP_LIST:
        case SparePartsConstant.REGIST_GROUP:
        case SparePartsConstant.UPDATE_GROUP:
            return { ...state, groupList: action.groupList }

        case SparePartsConstant.REGIST_COMPANY_STOCK:
        case SparePartsConstant.UPDATE_COMPANY_STOCK:
        case SparePartsConstant.COMPANY_STOCK_LIST:
            return { ...state, companyStockList: action.companyStockList }

        case SparePartsConstant.COMPANY_STOCK_IN_LIST:
            return { ...state, companyStockInList: action.companyStockInList }
        
        case SparePartsConstant.COMPANY_STOCK_INFO:
            return { ...state, companyStockInfo: action.companyStockInfo }

        case SparePartsConstant.SPARE_PARTS_ORDER_SUBMIT:
            return { ...state, orderContents: action.orderContents }

        case SparePartsConstant.SPARE_PARTS_ORDER_LIST:
        case SparePartsConstant.ORDER_CHANGE_STATUS:
            return { ...state, orderList: action.orderList }

        case SparePartsConstant.SPARE_PARTS_ORDER_GET_ONE:
            return { ...state, order: action.order }

        case SparePartsConstant.SPARE_PARTS_OUT_LIST:
            return { ...state, outList: action.outList }

        case SparePartsConstant.SPARE_PARTS_OUT_CONTENTS:
            return { ...state, outContents: action.outContents }

        case SparePartsConstant.SPARE_PARTS_OUT_HISTORY:
            return { ...state, outHistory: action.outHistory }

        case SparePartsConstant.COMPANY_OUT_LIST:
            return { ...state, companyOutList: action.companyOutList }

        default:
            return state;
    }
}