import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../history';
import { useEffect, useState } from 'react';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { RayTable } from '../../components/common';
import { Modal } from 'react-bootstrap';
import { InputBasicDateTime, InputBasicNumber, InputBasicText } from '../../components/common/Inputs';

const AdminSparePartsCompanyDetail = ({ partsInfo, companyStockInfo, companyStockInList, GetOne, CompanyStockInfoByServiceCode, CompanyStockReceiveList, CompanyOutRegist }) => {
    const { _id } = useParams();
    const user = JSON.parse(localStorage.getItem("user"));

    const [showModal, setShowModal] = useState(false);
    const [outList, setOutList] = useState([]);

    useEffect(() => {
        GetOne(_id);
        CompanyStockReceiveList(user.companyId, _id);
    }, []);

    useEffect(() => {
        const serviceCode = partsInfo?.serviceCode;
        CompanyStockInfoByServiceCode(user.companyId, serviceCode);
    }, [partsInfo]);

    useEffect(() => {
        const list = companyStockInfo.outHistory?.map(x => {
            return {
                outDate: x.outDate,
                quantity: x.quantity,
                sn: x.sn,
                memo: x.memo
            }
        });

        setOutList(!list ? [] : list)

    }, [companyStockInfo]);


    const inHeader = [
        { name: 'orderDate', label: 'Order Date', type: 'DTONLY', align: "text-center" },
        { name: 'receiveDate', label: 'Receive Date', type: 'DTONLY', align: "text-center" },
        { name: 'orderType', label: 'Order Type', align: 'text-center' },
        { name: 'quantity', label: 'Quantity', align: "text-center" },
    ];

    const outHeader = [
        { name: 'outDate', label: 'Out Date', type: 'DTONLY', align: "text-center" },
        { name: 'quantity', label: 'Quantity', align: "text-center" },
        { name: 'sn', label: 'Serial Number', type: 'ARRAY', align: 'text-center' },
        { name: 'memo', label: 'Memo', align: 'text-center' },
    ]

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">{partsInfo?.servicePartName}</h2>
                <div className="ray-right">
                    <button className="btn btn-primary btn-sm" onClick={() => history.push('/Admin/AdminSparePartsCompany')}>
                        Back
                    </button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className='row m-1 p-0'>
                    <div className="col m-0 p-1">
                        <div className='card p-3'>
                            <h1>In</h1>
                            <RayTable
                                data={companyStockInList}
                                columns={inHeader}
                                options={{
                                    search: false,
                                    defaultSort: "orderDate",
                                    defaultSortType: -1,
                                    hideListCount: true
                                }}
                            />
                        </div>
                    </div>

                    <div className="col m-0 p-1">
                        <div className='card p-3'>
                            <div>
                                <div style={{ float: 'left' }}>
                                    <h1>Out</h1>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button className='btn btn-primary btn-sm mt-0' onClick={() => setShowModal(true)}>regist</button>
                                </div>
                            </div>
                            <RayTable
                                data={outList}
                                columns={outHeader}
                                options={{
                                    search: false,
                                    defaultSort: "outDate",
                                    defaultSortType: -1,
                                    hideListCount: true
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <RegistOutHistory
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                companyId={user.companyId}
                partsId={_id}
                serviceCode={partsInfo.serviceCode}
                CompanyOutRegist={CompanyOutRegist}
            />
        </>
    )
}

const RegistOutHistory = ({ showModal, closeModal, companyId, partsId, serviceCode, CompanyOutRegist }) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const [data, setData] = useState({ outDate: new Date().valueOf(), quantity: 1 });

    const handleChangeData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const companyOutRegist = () => {
        CompanyOutRegist({ ...data, companyId, serviceCode, sn: data.sn?.split(','), creator: user.adminid });
        setData({ outDate: new Date().valueOf(), quantity: 1 });
        closeModal();
    }

    return (
        <Modal show={showModal}>
            <Modal.Header>
                <h2 className='modal-title'>Regist Out History</h2>
                <a onClick={() => {
                    closeModal();
                }}>
                    <ion-icon name="close-outline" style={{ fontSize: '30px' }}></ion-icon>
                </a>
            </Modal.Header>
            <Modal.Body className='p-2'>
                <div className="rayform section">
                    <div className="listview simple-listview no-space border-0">
                        <div className="form-group mt-1">
                            <div className="input-wrapper">
                                <InputBasicDateTime
                                    options={{ name: 'outDate', label: 'Out Date', onlyDate: true, val: data?.outDate }}
                                    editable={true}
                                    onchange={handleChangeData} />
                            </div>
                        </div>

                        <div className="form-group mt-1">
                            <div className="input-wrapper">
                                <InputBasicNumber
                                    options={{ name: 'quantity', label: 'Quantity', val: data.quantity }}
                                    editable={true}
                                    onchange={handleChangeData} />
                            </div>
                        </div>

                        <div className="form-group mt-1">
                            <div className="input-wrapper">
                                <InputBasicText
                                    options={{ name: 'sn', label: 'Serial Number (separate items with commas(\',\'))' }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>

                        <div className="form-group mt-1">
                            <div className="input-wrapper">
                                <InputBasicText
                                    options={{ name: 'memo', label: 'Memo' }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-block btn-success mb-0' onClick={() => companyOutRegist()}>Regist</button>
                <button className='btn btn-block btn-dark' onClick={() => closeModal()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

const mapState = (state) => {
    const partsInfo = state.SparePartsReducer.sparePart;
    const companyStockInfo = state.SparePartsReducer.companyStockInfo;
    const companyStockInList = state.SparePartsReducer.companyStockInList;
    return { partsInfo, companyStockInfo, companyStockInList };
};

const mapDispatch = (dispatch) => ({
    GetOne: (_id) => dispatch(SparePartsAction.GetOne(_id)),
    CompanyStockInfoByServiceCode: (companyId, serviceCode) => dispatch(SparePartsAction.CompanyStockInfoByServiceCode(companyId, serviceCode)),
    CompanyStockReceiveList: (companyId, partsId) => dispatch(SparePartsAction.CompanyStockReceiveList(companyId, partsId)),
    CompanyOutRegist: (data) => dispatch(SparePartsAction.CompanyOutRegist(data))
});

export default connect(mapState, mapDispatch)(AdminSparePartsCompanyDetail);
