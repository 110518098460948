import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import RootReducer from './reducers';

const loggerMiddleware = createLogger();

//const resetEnhancer = RootReducer => (state, action) => {
//    if (action.type !== 'RESET') return RootReducer(state, action);
//  
//    const newState = RootReducer(undefined, {});
//    newState.router = state.router;
//    return newState;
//};

export const store = createStore(
    RootReducer,
    applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware
    )
);