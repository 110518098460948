import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isDIM, isDOA, isCon } from './CommonViews';
import { CountryCode } from '../../config/CountryCode';
import { Loading } from './Loading';
import { ServiceStatusChip } from '../service/ServiceCard';
import { ExportExcelClass } from '../common/Exports';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const RayTableExport = ({ title = "", columns, sort, filters, exportlocalaction, goback, companies }) => {
	const [loading, setLoading] = useState(true);
	const [nowData, setNowData] = useState([]);
	const [exportData, setExportData] = useState({});

	useEffect(() => {
		if (exportlocalaction) {
			setLoading(true);
			getData();
		}
	}, []);

	const getData = async () => {
		var projects = {};

		columns.map(x => projects[x.name] = 1);


		console.log(filters);

		var data = await exportlocalaction({
			...filters,
			itemperpage: 30000,
			projects,
			page: 1
		});
	}

	useEffect(() => {
		var newCols = []
		var nowCols = JSON.parse(JSON.stringify(columns));
		var newDatas = JSON.parse(JSON.stringify(nowData));
		nowCols.map(x => {
			if (x.name.indexOf(".") > 0) {
				const newKey = x.name.replace(".", "");
				const keyarr = x.name.split(".");
				newDatas.map((y) => {
					try {
						y[newKey] = y[keyarr[0]][keyarr[1]];
					} catch { }
					return y
				})
				newCols.push({ ...x, name: newKey })
			} else {
				newCols.push(x)
			}
		})
		newCols.map(x => {
			if (x.type == "DT" || x.type == "DATETIME") {
				newDatas.map(y => {
					y[x.name] = moment(y[x.name]).local().format('YYYY-MM-DD HH:mm:ss');
					return y
				})
			}
			if (x.type == "COMPANY" && companies.length > 0) {
				newDatas.map(y => {
					var cid = companies.filter(n => n.companyId == y[x.name]);
					if (cid.length > 0) {
						y[x.name] = cid[0].name
					}
					return y;
				})
			}
		})
		var url = window.location.href;
		var head = url.split("/")[url.split("/").length - 1];
		setExportData({
			items: newDatas,
			css: "btn btn-secondary m-0",
			filename: head.replace("Admin", "").replace("List", " List") + " - Download - " + moment().format("YYYY-MM-DD hh-mm-ss"),
			sheettitle: "Sheet",
			headers: newCols
		});
		setLoading(false);
	}, [nowData]);

	const handleSort = (sortCol) => {
		setSort(sortCol);
		if (exportlocalaction) {
			var obj = {};
			obj[sortCol] = -1;
			updateSortInfo(obj);
		}
	};

	const isEqaul = (str) => { return str.charAt(str.length - 1) == "e" }

	const getItemText = (item, key, col) => {
		const countKeys = ["country", "ticketNationCode"];
		try {
			var r = '';
			if (key.indexOf('.') > -1) {
				var arr = key.split('.');
				r = item[arr[0]][arr[1]];
			} else {
				r = item[key];
			}
			if (col.type == "DT" || col.type == "DATETIME") {
				r = r ? moment(r).local().format('YYYY-MM-DD HH:mm:ss') : "";
			} else if (col.name == "errs.code") {
			} else if (col.type == "SPAN" && Number.isInteger(parseInt(r))) {
				r = parseInt(r).toLocaleString();
			} else if (col.type == "COMPANY") {
				if (companies.length > 0) {
					var cid = companies.filter(x => x.companyId == r);
					if (cid.length > 0)
						r = cid[0].name
				}
			} else if (col.type == "PROGRESS") {
				r = getProg(item, key, col);
			} else if (col.type == "TF" || col.type == "BOOLEAN") {
				r = getTF(r, col);
			} else if (col.name == "sn") {
				r = <>{isCon(item, 16)}{r} {isDOA(item)} {isDIM(item)}</>;
			} else if (col.type == "STATUS") {
				r = <ServiceStatusChip item={{ status: r }} />;
			} else if (col.type == "TITLE") {
				r = r || "[UNDEFINED]";
			} else if (col.type == "COUNTRY" || (countKeys.includes(key) && r.length == 2)) {
				const cname = CountryCode.find(item => r == item.code).title;
				r = <>
					<OverlayTrigger
						placement={'top'}
						overlay={<Tooltip> {cname} </Tooltip>}
					>
						<img src={"/assets/img/flags/" + r + ".png"} style={{ height: 24 }} alt={cname} />
					</OverlayTrigger>
					{col.showtype != "ONLYFLAG" && <>&nbsp;{cname}</>}
				</>
			}
			return r;
		} catch { }
		return "";
	};

	const getTF = (value, col) => {
		const size = {
			width: 24,
			height: 24,
		}
		return <>
			{value && <span className="text-primary"><ion-icon name="checkmark-circle" style={size}></ion-icon></span>}
			{!value && <span className="text-danger"><ion-icon name="ellipse" style={size}></ion-icon></span>}
		</>
	}

	const getProg = (item, key, col) => {
		const max = col.max;
		const val = item[key];
		const per = Math.round(val * 100 / max);
		return <div className="progress" style={{ height: 18 }}>
			<div className="progress-bar bg-primary"
				style={{ width: per + "%", height: 18 }}
				aria-valuenow={per}
				aria-valuemin="0" aria-valuemax="100">{item[key]}</div>
		</div>
	}

	const depVal = (item, key) => {
		if (key.indexOf('.') > -1) {
			var arr = key.split('.');
			return item[arr[0]][arr[1]];
		} else {
			return item[key];
		}
	}

	const getVal = (item, key, coloption) => {
		return getItemText(item, key, coloption);
	};

	const chkgetTFValue = (option) => {
		if (option === "True")
			return true;
		if (option === "False")
			return false;
		return option;
	}

	return (
		<>
			{title && <h2>{title}</h2>}
			<>
				<ExportExcelClass
					data={exportData}
					disabled={loading}
					css={"btn btn-lg btn-block btn-primary rayexport m-0"}
					title={"Click to export " + nowData.length.toLocaleString() + " item(s)."} />
				<button className="btn btn-block mt-1 btn-secondary" onClick={goback}>Cancel</button>
			</>
			<h3 className="mt-2">Example</h3>
			<table className={"table table-bordered mb-1"}>
				<thead>
					<tr>
						{columns.map((col) => {
							return (
								<th key={col.name} onClick={() => col.insortable || handleSort(col.name)}
									className={col.align && col.align}
									style={col.style && col.style}
								>
									{col.label}
									{sort == col.name && <span>&#8595;</span>}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{loading && <tr><td colSpan={columns.length}><Loading /></td></tr>}
					{!loading &&
						<>
							{nowData.length > 10 && <tr><td colSpan={columns.length} style={{ textAlign: "center" }}>......</td></tr>}
							{nowData.slice(0, 10).map((item, index) => {
								return (
									<tr
										key={index}>
										{columns.map((col, idx) => {
											return <td key={col.name + idx} className={col.align && col.align}>{getVal(item, col.name, col)}</td>;
										})}
									</tr>
								);
							})}
							{nowData.length > 10 && <tr><td colSpan={columns.length} style={{ textAlign: "center" }}>......</td></tr>}
						</>
					}
					{!loading && nowData.length == 0 && <tr><td colSpan={columns.length} className="text-center"><i>No data</i></td></tr>}
				</tbody>
			</table>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var su = state.AuthReducer.su;
	var companies = state.CompanyReducer.companies;
	return { user, companies, su };
}

export default connect(mapState)(RayTableExport);
