import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppConfig } from '../../config';
import axios from 'axios';
import { CompanyAction, AlertAction, AuthAction } from '../../actions';
import { LanguageCode, Sitemap, CountryCode, CompanyTypes } from '../../config';
import { RayForm, RayFormValidation } from '../../components/common';
import AdminCompanyDelete from './AdminCompanyDelete';
import { store } from '../../store';
import { history } from '../../history';

const CompanyAdd = ({ company, companies, AddCompanyInfo }) => {

    const user = JSON.parse(localStorage.getItem('user'));

    const [isAddProcess, setIsAddProcess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [newcompany, setCompany] = useState({
        country: CountryCode[0].code,
        org: CompanyTypes[0].code,
        contact: {},
        owner: {}
    });

    useEffect(() => {
        if (isAddProcess) {
            history.push("/Admin/AdminCompanyList");
        }
    }, [company])

    const setCompanySelect = (list) => {
        const cList = [{ code: "", title: "None" }];
        list.map((c) => cList.push({ code: c.companyId, title: c.name }));
        setCompanies(cList);
    }

    const handleAdd = (e) => {
        const errValidate = RayFormValidation(form_controls, newcompany);
        if (errValidate.length > 0) {

            console.log(errValidate);

            setErrors(errValidate);
        } else {
            setIsAddProcess(true);



            AddCompanyInfo(newcompany);
        }
    }

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setCompany(newcompany => ({ ...newcompany, [name]: value }));
    }

    const form_controls = [
        {
            title: "Company Information", items: [
                { type: "input", name: "name", label: "Name", val: newcompany.name, required: true, isEditable: true },
                { type: "select", name: "country", label: "Country", val: newcompany.country, list: CountryCode, required: true, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "select", name: "org", label: "Organization", val: newcompany.org, list: CompanyTypes, required: true, isEditable: true },
                { type: "input", name: "state", label: "State", val: newcompany.state, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "zip", label: "Zip", val: newcompany.zip, isEditable: true },
                { type: "input", name: "region", label: "Region", val: newcompany.region, isEditable: true },
            ]
        },
        {
            title: "", items: [
                {
                    type: "combo", name: "parentCompanyId", label: "Parent Company",
                    val: newcompany.parentCompanyId,
                    startval: "None",
                    getCombo: CompanyAction.GetCombo,
                    isEditable: true
                },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "address", label: "Address", val: newcompany.address, size: 12, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "addressExtra", label: "Address Extra.", val: newcompany.addressExtra, size: 12, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "textarea", name: "note", label: "Note", val: newcompany.note, size: 12, isEditable: true },
            ]
        },
        {
            title: "Contact Information", items: [
                { type: "email", name: "contactEmail", label: "Email", val: newcompany.contactEmail, required: true, isEditable: true },
                { type: "tel", name: "contactTel", label: "Tel", val: newcompany.contactTel, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "contactHp", label: "Mobile", val: newcompany.contactHp, isEditable: true },
                { type: "tel", name: "contactFax", label: "Fax", val: newcompany.contactFax, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "contactWebsite", label: "Website", val: newcompany.contactWebsite, size: 12, isEditable: true },
            ]
        },
        {
            title: "Owner Information", items: [
                { type: "input", name: "ownerName", label: "Owner Name", val: newcompany.ownerName, isEditable: true },
                { type: "tel", name: "ownerHp", label: "Owner Mobile", val: newcompany.ownerHp, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "ownerEmail", label: "Owner Email", val: newcompany.ownerEmail },
            ]
        },

    ]

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Add New Company</h2>
                <div className="ray-right">
                    <Link to="/Admin/AdminCompanyList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <RayForm
                    controls={form_controls}
                    isForm={true}
                    handleChangeData={handleChangeData}
                    errors={errors} />
                <div className="section">
                    <button className="btn btn-primary btn-block" onClick={() => handleAdd()}>Add Company</button>
                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    const company = state.CompanyReducer.company;
    const companies = state.CompanyReducer.companies;
    return { company, companies };
}

const mapDispatch = dispatch => ({
    AddCompanyInfo: (data) => dispatch(CompanyAction.AddCompanyInfo(data)),
})

export default connect(mapState, mapDispatch)(CompanyAdd);
