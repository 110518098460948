import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../history';
import { AdminUserAction } from '../../actions';
import AdminUserEditInfo from './AdminUserEditInfo';
import AdminUserEditUpdatePassword from './AdminUserEditUpdatePassword';
import { Translate as t } from '../../config/Translate';

const AdminUserEdit = ({ authuser, user, DeleteUser, isUpdateRes, ClearUpdateResult, GetUser }) => {

    const { adminid } = useParams();
    const [view, setView] = useState("info");
    const [tab, setTab] = useState(view || "info");
    const [canedit, setCanEdit] = useState(false);
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        GetUser(adminid);
    }, []);

    useEffect(() => {
        setCanEdit(authuser.adminType == "M" || adminid == authuser.adminid || (authuser.companyInfo && authuser.companyInfo.org == "HQ") || authuser.companyId == 'RAY02091');
    }, [user]);

    useEffect(() => {
        if (view == "deluserconfirm" && isUpdateRes) {
            ClearUpdateResult();
            history.push("/Admin/AdminUserList");
        }
    }, [isUpdateRes]);

    const handleDeleteUser = (e) => {
        DeleteUser(user.adminid);
    }

    const tabList = [
        { code: "info", title: "Information" }
    ]

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">
                    {user.adminid}
                    {(canedit && view == "info") &&
                        <a href onClick={() => setEditable(!editable)} className="btn btn-secondary btn-sm mt-0 ms-1">
                            <ion-icon name={editable ? "lock-open-outline" : "lock-closed-outline"} />
                            {editable ? t('unlocked') : t('locked')}
                        </a>}
                </h2>
                <div className="ray-right">
                    {canedit && <>
                        {view != "deluserconfirm" && <button className="btn btn-danger btn-sm mt-0 me-1" onClick={() => setView('deluserconfirm')}>Delete User</button>}
                        {view != "updatepassword" && <button className="btn btn-warning btn-sm mt-0 me-1" onClick={() => setView('updatepassword')}>{t('updatepassword')}</button>}
                        {view != "info" && <button className="btn btn-primary btn-sm mt-0 me-1" onClick={() => setView('info')}>Edit User</button>}
                    </>}
                    <Link to="/Admin/AdminUserList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1 ">
                </div>
                {view == "info" && <AdminUserEditInfo user={user} adminid={adminid} editable={editable} canedit={canedit} />}
                {view == "deluserconfirm" &&
                    <div className="section">
                        <div className="card bg-danger text-white">
                            <div className="card-header">
                                <div className="card-title">경고!</div>
                            </div>
                            <div className="card-body">
                                <h3>즉시 반영됩니다!!!</h3>
                                <p>이 작업은 되돌릴 수 없는 작업입니다.</p>
                                <p>정말 삭제하시겠습니까?</p>
                            </div>
                        </div>
                        <button className="btn btn-outline-danger" onClick={handleDeleteUser}>Delete User</button>
                    </div>
                }
                {view == "updatepassword" && <AdminUserEditUpdatePassword user={user} adminid={adminid} />}
            </div>
        </>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.AdminUserReducer.isUpdateRes;
    const user = state.AdminUserReducer.user;
    const authuser = state.AuthReducer.user;
    return { authuser, user, isUpdateRes };
};

const mapDispatch = (dispatch) => ({
    GetUser: (adminid) => dispatch(AdminUserAction.GetUser(adminid)),
    DeleteUser: (adminid) => dispatch(AdminUserAction.DeleteUser(adminid)),
    updateUserInfo: (adminid, data) => dispatch(AdminUserAction.UpdateUserInfo(adminid, data)),
    registUserInfo: (data) => dispatch(AdminUserAction.RegistUserInfo(data)),
    ClearUpdateResult: () => dispatch(AdminUserAction.ClearUpdateResult()),
});

export default connect(mapState, mapDispatch)(AdminUserEdit);
