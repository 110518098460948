import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputBasicTextArea } from '../../components/common/Inputs';
import { FileAddList } from '../../components/common/FileAddList';
import { InstallationAction, ErrorCodeAction, ProductAction } from '../../actions';
import InstallationStep0 from './InstallationStep0';
import InstallationStep1 from './InstallationStep1';
import InstallationStep2 from './InstallationStep2';
import InstallationStep3 from './InstallationStep3';
import InstallationStep4 from './InstallationStep4';
import InstallationStep5 from './InstallationStep5';
import InstallationStep6 from './InstallationStep6';
import { Link } from 'react-router-dom';

import { RaySignaturePad } from '../common';
import { ExportPdfReport, ExportPdfReportToEmail } from '../../components/common/ExportPdfReport';

import { Translate as t } from '../../config/Translate';
import axios from 'axios';

const InstallationStep7 = ({ info, Update, product, isSummary = false, view, SendInstallationMail }) => {

	// const [t] = useTranslation();
	const [isChecked, setIsChecked] = useState(false);
	const [showTab, setShowTab] = useState({
		tab1: true,
		tab2: false,
		tab3: false,
		tab4: false,
		tab5: false,
		tab6: false,
		tab7: false,
		tab8: false
	});

	const Prev = (e) => {
		Update(info._id, { installationStep: 7 });
	};


	const iubendaUpdate = () => {

		var datas = {
			subject: {
				first_name: info?.customerSignatureName,
				verified: true
			},
			preferences: { "privacy_policy": true },
			legal_notices: [
				{ "identifier": "privacy_policy" },
				{ "identifier": "cookie_policy" },
				{ "identifire": "terms", "version": "v1" }
			],
			proofs: [{ "content": "proof_content", "form": "proof_form" }]
		};

		try {
			axios.post('https://consent.iubenda.com/consent/', datas, {
				headers: {
					'Content-Type': 'application/json',
					'ApiKey': 'eGYsdYTiYWsCsxduymtBvvR91dM1MORg',
					'Accept': 'application/json',
				}
			})
				.then(function (response) {
					Update(info._id, { isSubmit: true, isIubendaId: response.data.subject_id });
				})
				.catch(function (error) {
				});
		} catch (err) {
			console.log(err);
		}
	}


	function validNext(e) {
		//////SendInstallationMail(info.id, info, 'installComplete');
		e.preventDefault();
		if (isChecked) {
			iubendaUpdate();
		} else {
			alert(t('privacyalert'));
		}
	};

	function handleChangeData(e) {
		console.log(e.target);
		const { name, checked } = e.target;
		if (checked) {
			setIsChecked(true);
		} else {
			setIsChecked(false);
		}
	}

	const sigInstaller = () => {
	};

	const sigCustomer = () => {
	};

	return (
		<>
			{(info.isSubmit && view != "view") && <>
				<div className="section text-center mt-2 mb-2">
					<div className="card">
						<div className="card-body">
							<div className="listed-detail p-4">
								<div className="icon-wrapper">
									<div className="iconbox bg-success">
										<ion-icon name="checkmark-sharp" role="img" className="md hydrated" aria-label="checkmark sharp"></ion-icon>
									</div>
								</div>
								<h2 className="mt-2">{t('installationreporthasbeencompleted')}</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div>
						<Link to="/Calendar" className="btn btn-block btn-primary btn-lg">{t('calendar')}</Link>
						<Link to="/" className="btn btn-block btn-outline-primary btn-lg">{t('home')}</Link>
					</div>
				</div>
			</>}
			{(!info.isSubmit || (info.isSubmit && view == "view")) &&
				<>
					<div className="section mt-1">
						<div className="section-title">{t('summarysig')}</div>
					</div>
					<div className="section">
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab1: !showTab.tab1 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('siteinfo')}
									</strong>
								</button>
							</h2>
							{showTab.tab1 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep1 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab2: !showTab.tab2 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('productinfo')}
									</strong>
								</button>
							</h2>
							{showTab.tab2 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep0 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab3: !showTab.tab3 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('packagingcheck')}
									</strong>
								</button>
							</h2>
							{showTab.tab3 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep2 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab4: !showTab.tab4 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('systemconfig')}
									</strong>
								</button>
							</h2>
							{showTab.tab4 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep3 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab5: !showTab.tab5 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('imagequality')}
									</strong>
								</button>
							</h2>
							{showTab.tab5 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep4 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab6: !showTab.tab6 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('regulatorydocument')}
									</strong>
								</button>
							</h2>
							{showTab.tab6 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep5 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						<div className="accordion mt-1">
							<h2 className="accordion-header">
								<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab7: !showTab.tab7 })}>
									<strong>
										<ion-icon name="document-text-outline" class="fl md hydrated" />
										{t('usertraining')}
									</strong>
								</button>
							</h2>
							{showTab.tab7 &&
								<div className="accordion-collapse collapse show">
									<div className="accordion-body pb-0 pt-0">
										<InstallationStep6 info={info} isSummary={true} product={product} />
									</div>
								</div>}
						</div>
						{view == "view" &&
							<div className="accordion mt-1">
								<h2 className="accordion-header">
									<button className="accordion-button" onClick={() => setShowTab({ ...showTab, tab8: !showTab.tab8 })}>
										<strong>
											<ion-icon name="document-text-outline" class="fl md hydrated" />
											{t('signature')}
										</strong>
									</button>
								</h2>
								{showTab.tab8 &&
									<div className="accordion-collapse collapse show">
										<div className="accordion-body pb-0 pt-0">
											<RaySignaturePad sigType="Installer" reportId={info._id} sigPath={info} view="view" />
											<RaySignaturePad sigType="Customer" reportId={info._id} sigPath={info} view="view" />
										</div>
									</div>}
							</div>
						}

					</div>
					{view == "view" &&
						<div className="section">
							<div className="mb-3 mt-2 ms-2 me-2">
								<ExportPdfReport reportType={'installation'} infos={info} />
								<button className="btn btn-outline-primary btn-block btn-lg" onClick={() => history.back()}>{t("close")}</button>
							</div>
						</div>
					}
					{view != "view" &&
						<>
							<div className="section mb-2">
								<div style={{ backgroundColor: "white" }}>
									<RaySignaturePad sigType="Installer" reportId={info._id} sigPath={info} />
									<RaySignaturePad sigType="Customer" reportId={info._id} sigPath={info} />
								</div>
								<div className="section mb-2">
									<input type="checkbox"
										checked={isChecked}
										onChange={handleChangeData} />
									{t('privacyconfirm1')}
									<label className="form-check-label">
										<a href="https://www.iubenda.com/privacy-policy/74962298/legal" className="iubenda-nostyle iubenda-embed " title="Privacy Policy ">&nbsp; Privacy Policy &nbsp;</a><script type="text/javascript" src="https://cdn.iubenda.com/iubenda.js"></script>
									</label>
									{t('privacyconfirm2')}
								</div>
								<button className="btn btn-block btn-primary btn-lg" onClick={validNext}>{t('complete')}</button>
								<button className="btn btn-block btn-outline-primary btn-lg" onClick={() => Prev()}>{t('back')}</button>
							</div>
						</>
					}
				</>}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	SendInstallationMail: (_id, data, subType) => dispatch(InstallationAction.SendInstallationMail(_id, data, subType))
});

export default connect(null, mapDispatch)(InstallationStep7);