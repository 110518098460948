import React, { useState, useEffect } from 'react';
import { RayForm } from '../common';
import { Translate as t } from '../../config/Translate';

const ProductSimpleInfo = ({ product }) => {
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [contols, setControls] = useState([]);

	const showKey = [
		{
			label: t('sn'), value: "sn",
			link: isAdmin ? "/Admin/AdminProductInfo/" : "/Product/"
		},
		{ label: t('model'), value: "model" },
		{ label: t('option'), value: "option" },
		{ label: t('status'), value: "status" },
		{ label: t('installeddate'), value: "installedDate" },
		{ label: t('lastconnect'), value: "lastConnectedDate" }
	]

	useEffect(() => {
		var objs = [];
		showKey.map(x => {
			if (product[x.value]) {
				var obj = {
					title: '', items: [{
						type: 'input',
						name: x.value,
						label: x.label,
						viewLinkPath: x.link,
						val: product[x.value],
						valtype: x.value.indexOf("Date") > -1 ? "date" : "",
						size: 12
					}]
				};
				objs.push(obj);
			}
		});
		objs[0].title = "";
		setControls(objs);
	}, []);

	return <RayForm controls={contols} />;
};

export default ProductSimpleInfo;
