import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppConfig } from '../../config';
import axios from 'axios';
import { CompanyAction, AlertAction, AuthAction } from '../../actions';
import { history } from '../../history';

const CompanyDelete = ({ company, isUpdateRes, DeleteCompany, ClearUpdateResult }) => {

    const { companyId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);

    const [delCheckResult, setDelCheckResult] = useState({ userCount: 0, siteCount: 0, childCompanyCount: 0 });

    useEffect(() => {
        setIsLoading(true)
        if (company.companyId) {
            axios.get(AppConfig.API_SERVER_PATH + '/company/getdelcheckinfo/' + company.companyId).then((ret) => {
                setIsLoading(false)
                if (ret.data.status == "success") {
                    setDelCheckResult(ret.data.data);
                } else {
                    AlertAction.Error("Check Fail!");
                    setView("editcompany");
                }
            });
        }
    }, []);

    useEffect(() => {
        if (isUpdateRes) {
            ClearUpdateResult();
            history.goBack();
        }
    }, [isUpdateRes]);

    const handleDeleteCompany = (e) => {
        DeleteCompany(company.companyId);
    }

    return (
        <>
            {delCheckResult.userCount + delCheckResult.siteCount + delCheckResult.childCompanyCount > 0 && (
                <>
                    <div className="card bg-danger text-white">
                        <div className="card-header">경고!</div>
                        <div className="card-body">
                            <div className="card-title">
                                아래의 사유로 {company.name}을/를 삭제할 수 없습니다
                            </div>
                        </div>
                        <ul className="listview simple-listview">
                            {delCheckResult.userCount > 0 &&
                                <li>
                                    {company.name}에 소속된 사용자가 {delCheckResult.userCount}명이 존재합니다.
                                </li>}
                            {delCheckResult.siteCount > 0 &&
                                <li>
                                    {company.name}에 소속된 Site가 {delCheckResult.siteCount}개 존재합니다.
                                </li>}
                            {delCheckResult.childCompanyCount > 0 &&
                                <li>
                                    {company.name}의 하위에 존재하는 Company가 <b>{delCheckResult.childCompanyCount}</b>개 존재합니다.
                                </li>}
                        </ul>
                    </div>
                </>
            )}
            {delCheckResult.userCount + delCheckResult.siteCount + delCheckResult.childCompanyCount <= 0 && (
                <>
                    <div className="card bg-danger text-white">
                        <div className="card-header">
                            <div className="card-title">경고!</div>
                        </div>
                        <div className="card-body">
                            <h4>즉시 반영됩니다!!!</h4>
                            <p>이 작업은 되돌릴 수 없는 작업입니다.</p>
                            <p>정말 삭제하시겠습니까?</p>
                        </div>
                    </div>
                    <button className="btn btn-outline-danger" onClick={handleDeleteCompany}>Delete Company</button>
                </>
            )}
        </>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.CompanyReducer.isUpdateRes;
    return { isUpdateRes };
}

const mapDispatch = dispatch => ({
    ClearUpdateResult: () => dispatch(CompanyAction.ClearUpdateResult()),
    DeleteCompany: (companyid) => dispatch(CompanyAction.DeleteCompany(companyid))
})

export default connect(mapState, mapDispatch)(CompanyDelete);