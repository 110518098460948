export const NotificationConstant = {
    GET_ALL: 'NOTI_GET_ALL',
    READ: 'NOTI_READ',
    UNREAD: 'NOTI_UNREAD',
    READALL: 'NOTI_READALL',
    DELETE: 'NOTI_DELETE',
    DELETEALL: 'NOTI_DELETEALL',
    DELETEREAD: 'NOTI_DELETEREAD',
    LOADING: 'NOTI_LOADING',
    LOADED: 'NOTI_LOADED',
};