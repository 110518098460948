import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SiteAction } from '../../actions/SiteAction';
import { history } from '../../history';
import AdminSiteDelete from './AdminSiteDelete';
import AdminSiteInfo_Customer from './AdminSiteInfo_Customer';
import AdminSiteInfo_Product from './AdminSiteInfo_Product';
import { Flag } from '../../components/common/CommonViews';
import AdminSiteInfoSummary from './AdminSiteInfoSummary';
import { Translate as t } from '../../config/Translate';

const AdminSiteEdit = ({ site, GetSite }) => {

    const { id, view } = useParams();
    const [editable, setEditable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState(view || "siteinfo");

    useEffect(() => {
        setTab(view || "siteinfo")
    }, [view])

    useEffect(() => {
        console.log('site================================');
        console.log(site);
        setIsLoading(id != site?._id)
    }, [site])

    useEffect(() => {
        id != site?._id && GetSite(id);
    }, [])

    const tabList = [
        { code: "siteinfo", title: `${t('site')} ${t('information')}` },
        { code: "customerinfo", title: `${t('customer')} ${t('information')}` },
        { code: "product", title: t('installed') },
    ]

    const createService = () => {
        history.push({
            pathname: '/Admin/AdminServiceAdd',
            state: {
                refer: 'SITENORMAL',
                item: {},
                extraItem: site,
            },
        });
    }

    return (
        <>
            {isLoading && <div className="">Loading...</div>}
            {!isLoading &&
                <>
                    <div className="extraHeader">
                        <h2 className="mb-0">{Flag(site.country, 42)}{id ? site.sitename : "Add New Site"}</h2>
                        <div className="ray-right">
                            {(tab == "siteinfo" || tab == "customerinfo") &&
                                <a href onClick={() => setEditable(!editable)} className="btn btn-secondary btn-sm mt-0 me-1">
                                    <ion-icon name={editable ? "lock-open-outline" : "lock-closed-outline"} />
                                    {editable ? t('unlocked') : t('locked')}
                                </a>
                            }
                            <Link to={"/Admin/AdminSiteInfo/" + site._id + "/del"} className="btn btn-danger btn-sm mt-0 me-1" onClick={() => setTab('del')}>{t('deletesite')}</Link>
                            <Link to="/Admin/AdminSiteDashboard" className="btn btn-sm mt-0">
                                <ion-icon name="desktop-outline"></ion-icon> {t('dashboard')}
                            </Link>
                            <Link to="/Admin/AdminSiteList" className="btn btn-sm mt-0">
                                <ion-icon name="list-outline"></ion-icon> List
                            </Link>
                        </div>
                    </div>
                    <div id="appCapsule" className="extra-header-active full-height">
                        <div className="section mt-1 mb-1">
                            <ul className="nav nav-tabs style2 mt-1 mb-1">
                                {tabList.map(x => {
                                    return (
                                        <li className="nav-item" key={x.code}>
                                            <Link to={"/Admin/AdminSiteInfo/" + site._id + "/" + x.code}
                                                className={tab == x.code ? "nav-link active" : "nav-link"}
                                                onClick={() => setTab(x.code)}>{x.title}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {tab == "siteinfo" && <AdminSiteInfoSummary site={site} editable={editable} />}
                        {tab == "customerinfo" && <AdminSiteInfo_Customer site={site} editable={editable} />}
                        {tab == "product" && <AdminSiteInfo_Product site={site} />}
                        {tab == "del" && <AdminSiteDelete site={site} />}
                    </div>
                </>
            }
        </>
    );
};

const mapState = (state) => {
    const site = state.SiteReducer.site;
    const isClose = state.AlertReducer.isClose;
    return { site, isClose };
}

const mapDispatch = dispatch => ({
    GetSite: (_id) => dispatch(SiteAction.GetSite(_id)),
    UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data)),
    AddSite: (site) => dispatch(SiteAction.AddSite(site)),
})
export default connect(mapState, mapDispatch)(AdminSiteEdit);