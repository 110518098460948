import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import CustomHeader from '../../components/common/CustomHeader';
import { ServiceConstant } from '../../actions/constants/ServiceConstant';
import { RayTable, RayTreeview, AdminServiceListHeader } from '../../components/common';
import { ServiceAction } from '../../actions/ServiceAction';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Loading } from '../../components/common/Loading';
import { ModalConfirm } from '../../components/common/ModalConfirm';
import { AdminUserAction, CustomFieldAction, } from '../../actions';
import DeepSearch from '../../components/common/DeepSearch';
import { Translate as t } from '../../config/Translate';

const AdminServiceList = ({ customs, user, users, GetServiceAll, services, totalservicecount, filterOptions, filterDateOptions,
	dsfilters, UpdateStatusBulk, companies, viewTree, GetUserAll, servicematch }) => {

	const [bulkList, setBulkList] = useState([]);
	const [bulkIng, setBulkIng] = useState(false);
	const [bulkCnt, setbulkCnt] = useState(0);
	const [action, setAction] = useState("");
	const [exheader, setExHeader] = useState([]);

	const [wHg, setwHg] = useState(300);
	const [wAll, setwAll] = useState(window.innerWidth);
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });

	const [header, setHeader] = useState([]);
	const [showCHModal, setShowCHModal] = useState(false);

	const [nowuserfilter, setnowuserfilter] = useState({});
	const [filters, setFilters] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [showDSModal, setShowDSModal] = useState(false);
	const [match, setMatch] = useState({});
	const [actioninfo, setActionInfo] = useState({});
	const [ready, setReady] = useState(false);
	const [dfilters, setdFilters] = useState(false);

	const [service, setService] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		user.adminType != "U" && updateViewTree(true);
	}, []);

	const listAction = [
		{ title: "Close Selected", action: "CLOSEALL" },
	];

	var filterpage = {
		filters: {},
		filterOptions: [],
		filterDateOptions: filterDateOptions || [{ code: 'createDate', label: 'Created Date' },],
		searchCols: ['summary', 'ticketKey', 'sn', 'site.sitename'],
		sort: { createDate: -1 },		
		searchKey: "",
		page: 1,
		itemperpage: user.settings?.dashboardtablelistcount || 30
	}

	var options = {
		isShowCheckBox: true,
		search: true,
		keyCol: 'ticketKey',
		defaultSort: Object.keys(filterpage.sort)[0],
		defaultSortType: Object.values(filterpage.sort)[0],
		linkto: '/Admin/AdminService',
		getList: GetServiceAll,
		linkkey: 'summary',
		filterpageoption: filterpage,
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalservicecount,
		filterOptions: filterOptions,
		defaultSort: "createDate",
		defaultSortType: -1,
	};

	useEffect(() => {
		setFilters(user.filters || []);
	}, [user.filters]);

	useEffect(() => {
		setReady(header.length > 0);
	}, [header]);

	useEffect(() => {
		if (dfilters.length > 0) {
			setExHeader((user.adminheaders?.services) ? user.adminheaders?.services.filter(x => dfilters.map(m => m.code).includes(x.name)) : []);
		} else {
			setExHeader([]);
		}
	}, [user.adminheaders?.services, dfilters]);

	useEffect(() => {
		const originHeader = AdminServiceListHeader.filter(x => {
			return ((user.adminType == 'U' && (x.name != 'doa' && x.name != 'dim' && x.name != 'exportcsr')) || (user.adminType != 'U' && x.name != 'installedDate'))
		}).filter(x => user.companyId == 'RAY02091' ? x.name != 'exportcsr' : x.name = x.name);

		setHeader(exheader.length > 0 ? originHeader.concat(exheader) : originHeader);
	}, [exheader]);

	useEffect(() => {
		if (viewTree) {
			setwHg(300);
		} else {
			setwHg(0);
		}
	}, [viewTree]);

	useEffect(() => {
		callbackDs(dsfilters);
	}, [dsfilters]);

	useEffect(() => {
		setService(services.map(x => {
			return {
				...x,
				companyId: companies.filter(item => item.companyId == x.companyId).length == 1 ? companies.filter(item => item.companyId == x.companyId)[0].name : '',
				orgReplacement: x.replacements ? x.replacements : [],
				replacements: x.replacements ? x.replacements.map(z => `${z.title} (${z.oldsn} -> ${z.newsn})\n`) : [],
				creator: getUserName(x.creator),
				assignee: getUserName(x.assignee),
				installedDate: x.installedDate
			}
		}));
	}, [services]);

	const onChangeTreeviewItem = (item) => {
		setFilterCompanyId({ companyId: item.id });
		setFilterCompany(item);
	}

	const getWidth = () => setwAll(window.innerWidth);

	useEffect(() => {
		GetUserAll();
	}, []);

	const checkedList = (list) => {
		setBulkList(list);
	}

	const builk_UpdateStatus = () => {
		setActionInfo({ ...actioninfo, showModal: false });
		UpdateStatusBulk(bulkList, { status: "Close" });
	}

	const builk_ExportCCR = () => {
		setBulkIng(true);
		setActionInfo({ ...actioninfo, showModal: false });
	}

	const bulkActionDo = (action) => {
		switch (action) {
			case "CLOSEALL":
				setActionInfo({
					...actioninfo,
					title: "Close Selected Service", content: "Are you sure?", showModal: true,
					actions: [
						{ title: "Update All", callback: builk_UpdateStatus }
					]
				})
				break;
			case "EXPORTCCR":
				setActionInfo({
					...actioninfo,
					title: "Export CCR", content: "Are you sure?", showModal: true,
					actions: [
						{ title: "Export All", callback: builk_ExportCCR }
					]
				})
				break;
		}
		setAction(action);
	}

	const callbackDs = (filter) => {
		var mt = {}
		filter.map(x => {
			if (x.type == "STRING" || x.type == "FIXLIST" || x.type == "NUMBER")
				mt[x.key] = x.val;
			if (x.type == "LIKESTRING")
				mt[x.key] = { $regex: x.val, $options: 'si' };
			if (x.type == "DATETIME") {
				mt[x.key] = {};
				if (x.val.opt == "last") {
					mt[x.key]['$gte'] = moment().add(-1 * x.val.lastVal, x.val.lastValOpt).valueOf();
				} else {
					mt[x.key]['$gte'] = x.val.s;
					mt[x.key]['$lte'] = x.val.e;
				}
			}
			if (x.type == "BOOLEAN")
				mt[x.key] = x.val;
		})
		setMatch(mt);
	}

	const toggle = [
		{ code: true, title: "All", filter: {} },
		{ code: false, title: "Not Closed", filter: { 'status': { '$ne': 'Close' } } }
	];

	const getMatchToggle = () => {
		var m = toggle.filter(x => JSON.stringify(x.filter) == JSON.stringify(servicematch));
		if (m.length > 0) {
			return m[0].code;
		}
		return false;
	}

	const defaultFilter = [
		//STRING, DATETIME, BOOLEAN
		{ code: "summary", name: "Summary(LIKE)", type: "LIKESTRING" },
		{ code: "description", name: "Description(LIKE)", type: "LIKESTRING" },
		{ code: "sn", name: "Serial Number", type: "STRING" },
		{ code: "status", name: "Status", type: "STATUS" },
		{ code: "assignee", name: "Assignee", type: "USER" },
		{ code: "companyId", name: "Operator", type: "OPERATOR" },
		{ code: "category", name: "Claim Category", type: "CLAIM" },
		{ code: "creator", name: "Reporter", type: "USER" },
		{ code: "effectiveDate", name: "Effective Date", type: "DATETIME" },
		{ code: "errorCode", name: "Error Code", type: "STRING" },

		{ code: "comments.comment", name: "Comment(LIKE)", type: "LIKESTRING" },

		{ code: "attachments.createDate", name: "Attachment Create Date", type: "DATETIME" },

		{ code: "replacements.title", name: "Replacement Part", type: "PARTLIST" },
		{ code: "replacements.sendDate", name: "Replacement Date(Send)", type: "DATETIME" },
		{ code: "replacements.oldsn", name: "Replacement Part SN(OLD)", type: "STRING" },
		{ code: "replacements.newsn", name: "Replacement Part SN(NEW)", type: "STRING" },
		{ code: "replacements.trackingno", name: "Replacement Part Tracking No.", type: "STRING" },

		{ code: "escalated", name: "Escalated", type: "BOOLEAN" },
		{ code: "visitDate", name: "Visit Date", type: "DATETIME" },
		{ code: "remoteDate", name: "Remote Date", type: "DATETIME" },
		{ code: "createDate", name: "Create Date", type: "DATETIME" },
		{ code: "updateDate", name: "Last Update Date", type: "DATETIME" },
	]

	useEffect(() => {
		const newfilters = [];
		customs.filter(m => m.group.includes("SERVICE")).map(x => {
			if (x.valuetype == "FIXLIST") {
				var mList = [];
				x.listvals.map(l => mList.push({ code: l, title: l }));
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title, fixlist: mList });
			} else {
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title });
			}
		});
		defaultFilter.map(x => newfilters.push(x));
		setdFilters(newfilters);
	}, [customs]);

	const conSearch = () => {
		setShowDSModal(true);
	}

	const callbackchangeuserfilter = (data) => {
		setnowuserfilter(data);
		dispatch({ type: ServiceConstant.UPDATE_DSFILTER, dsfilters: data.filter ? [...data.filter] : [] });
	}

	const updateViewTree = (bl) => {
		dispatch({ type: ProgramConstant.VIEWTREE, val: bl });
	}

	const getUserName = (adminId) => {
		return users.filter(item => item.adminid == adminId).length == 1 ?
			users.filter(item => item.adminid == adminId)[0].firstName + ' ' + users.filter(item => item.adminid == adminId)[0].lastName : adminId;
	}

	console.log('111');
	console.log(ready);
	console.log('111');

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('service')}
					{companies.length > 1 && <button className="btn btn-secondary btn-sm mt-0 ms-1" onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>}
				</h2>
				<div className="ray-right">
					<Link to="/Admin/AdminServiceAdd" className="btn btn-primary btn-sm mt-0">
						<ion-icon name="add-outline"></ion-icon>
						{t('addnewservice')}
					</Link>
					<button onClick={() => setShowCHModal(true)} className="btn btn-sm mt-0">
						<ion-icon name="options-outline"></ion-icon> {t('customheaders')}
					</button>
					<Link to="/Admin/AdminServiceDashboard" className="btn btn-sm mt-0">
						<ion-icon name="desktop-outline"></ion-icon> {t('dashboard')}
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{(viewTree && user.adminType != "U") &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>}
				<div className="section m-1 p-0">
					<div className="card p-2">
						<div className={user.adminType != "U" ? "fl" : ""} >
							{showDSModal && <div className="mb-1">
								<DeepSearch
									dispatchType={ServiceConstant.UPDATE_DSFILTER}
									extratrakey={"SERVICEEXTRAKEY"}
									extratrakeyname={"extradata"}
									savefiltertype={"service"}
									dsfilters={dsfilters || []}
									filterData={filterData}
									nowuserfilter={nowuserfilter}
									callbackDs={callbackDs}
									callbackchangeuserfilter={callbackchangeuserfilter}
									callbackDSClose={() => setShowDSModal(false)}
									defaultFilter={dfilters} />
							</div>}
							{(ready && !bulkIng) && <RayTable
								data={service}
								addFilter={filterCompanyId}
								ufilters={filters.length > 0 ? filters.filter(x => x.type == "service") : []}
								match={match}
								dsfilterCount={dsfilters.length}
								conSearch={conSearch}
								callbackchangeuserfilter={callbackchangeuserfilter}
								defaultFilter={defaultFilter}
								bulkActions={listAction}
								bulkActionDo={bulkActionDo}
								exportlocalaction={ServiceAction.GetServiceAllLocal}
								checkBoxCallback={(list) => checkedList(list)}
								columns={header}
								options={options}
								listtype='service'
								toggle={toggle}
								toggleval={getMatchToggle()}

							/>}
							{bulkIng &&
								<div className="card">
									<div className="card-body pdB0">
										<div className="mt-3 mb-5 no-data">
											<Loading text={""} type="Circles" />
											<h2 className="mb-1">{actioninfo.title}</h2>
										</div>
									</div>
								</div>
							}
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
				<CustomHeader
					extratrakey={"SERVICEEXTRAKEY"}
					title={"Service List Header"}
					extratrakeyname={"extradata"}
					exHeader={exheader}
					adminheaderprefix={"services"}
					showModal={showCHModal}
					callbackClose={() => setShowCHModal(false)}
					defaultFilter={dfilters || []} />
			</div>
			<ModalConfirm info={actioninfo} callbackClose={() => setActionInfo({ ...actioninfo, showModal: false })} />
		</>
	);
};

const mapState = (state) => {
	var services = state.ServiceReducer.services;
	var user = state.AuthReducer.user;
	const users = state.AdminUserReducer.users;
	var viewTree = state.ProgramReducer.viewtree;
	var companies = state.CompanyReducer.companies;
	var dsfilters = state.ServiceReducer.dsfilters;
	var totalservicecount = state.ServiceReducer.totalservicecount;
	var filterDateOptions = state.ServiceReducer.filterDateOptions;
	var filterOptions = state.ServiceReducer.filterOptions;
	var servicematch = state.ServiceReducer.servicematch;
	const customs = state.CustomFieldReducer.items;
	return { customs, user, users, services, totalservicecount, filterOptions, filterDateOptions, dsfilters, viewTree, companies, servicematch };
};

const mapDispatch = (dispatch) => ({
	//GetCustomAll: () => dispatch(CustomFieldAction.GetAll()),
	GetServiceAll: (filter) => dispatch(ServiceAction.GetServiceAll(filter)),
	UpdateStatusBulk: (ids, data) => dispatch(ServiceAction.UpdateStatusBulk(ids, data)),
	GetUserAll: () => dispatch(AdminUserAction.GetUserAll()),
});

export default connect(mapState, mapDispatch)(AdminServiceList);
