import { AdminUserConstant } from '../actions/constants/AdminUserConstant';
const initialState = {
	users: [],
	usersrecursive: [],
	user: {},
	filter: {},
	isUpdateRes: false,
	isLoading: false,
};
export const AdminUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case AdminUserConstant.UPDATE_LISTFILTER:
			return { ...state, filter: action.filter, isUpdateRes: false };
		case AdminUserConstant.GETUSERINFO_SUCCESS:
			return {
				...state,
				user: action.user,
				users: state.users.map((u) => (u._id == action.user._id ? action.user : u)),
				isUpdateRes: false
			};
		case AdminUserConstant.UPDATE_USERINFO:
			const u = state.users.filter((n) => (n._id == action.user._id));
			if (u.length > 0) {
				return {
					...state, user: action.user, isUpdateRes: true,
					users: state.users.map((u) => (u._id == action.user._id ? action.user : u)),
				};
			} else {
				return {
					...state, user: action.user, isUpdateRes: true,
					users: [action.user],
				};
			}
		case AdminUserConstant.DELETE_USER:
			return { ...state, users: state.users.filter((u) => u._id !== action.deluserid), isUpdateRes: true };
		case AdminUserConstant.CLEAR_UPDATERES:
			return { ...state, isUpdateRes: false };
		case AdminUserConstant.REGIST_USERINFO:
			return {
				...state,
				users: [...state.users, action.user],
				isUpdateRes: true
			};
		case AdminUserConstant.GET_USERLIST:
			return { ...state, users: action.users, isUpdateRes: false };
		case AdminUserConstant.LOADING:
			return { ...state, isLoading: true }
		case AdminUserConstant.LOADED:
			return { ...state, isLoading: false }
		default:
			return state;
	}
};
