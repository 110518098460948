import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history } from '../../history';
import { ErrorCodeAction, ServiceAction } from '../../actions';

const ServiceStatusIcon = ({ item, css = "item mt-05" }) => {

	const [chipCss, setChipCss] = useState("chip-icon bg-dblue");
	const [chipIcon, setChipIcon] = useState("");

	const getChipCss = () => {
		switch (item.status) {
			case "New": setChipCss("icon-box bg-dblue me-05"); setChipIcon("caret-forward-outline"); break;
			case "Visit": setChipCss("icon-box bg-dblue me-05"); setChipIcon("location"); break;
			case "Remote": setChipCss("icon-box bg-primary me-05"); setChipIcon("radio"); break;
			case "Escalate": setChipCss("icon-box bg-info me-05"); setChipIcon("arrow-up"); break;
			case "Replacement": setChipCss("icon-box bg-primary me-05"); setChipIcon("build"); break;
			case "TBD": setChipCss("icon-box bg-warning me-05"); setChipIcon("chatbubble-ellipses"); break;
			case "Monitoring": setChipCss("icon-box bg-cyan me-05"); setChipIcon("search"); break;
			case "Pending": setChipCss("icon-box bg-yorange me-05"); setChipIcon("pause"); break;
			case "Cancel": setChipCss("icon-box bg-orange me-05"); setChipIcon("close-sharp"); break;
			case "Close": setChipCss("icon-box bg-success me-05"); setChipIcon("checkmark-sharp"); break;
		}
	}

	useEffect(() => {
		getChipCss();
	}, [item]);

	return (
		<div className={css}>
			<div className={chipCss}>
				<ion-icon name={chipIcon} role="img"
					style={{ fontSize: "20px" }}
					className="md hydrated"
					aria-label="chatbubble outline"></ion-icon>
			</div>
		</div>
	)
}

const ServiceStatusChip = ({ item, showText = true }) => {

	const [chipCss, setChipCss] = useState("chip-icon bg-dblue");
	const [chipIcon, setChipIcon] = useState("");

	const getChipCss = () => {
		switch (item.status) {
			case "New": setChipCss("chip-icon bg-dblue"); setChipIcon("caret-forward-outline"); break;
			case "Visit": setChipCss("chip-icon bg-dblue"); setChipIcon("location"); break;
			case "Remote": setChipCss("chip-icon bg-primary"); setChipIcon("radio"); break;
			case "Escalate": setChipCss("chip-icon bg-info"); setChipIcon("arrow-up"); break;
			case "Replacement": setChipCss("chip-icon bg-primary"); setChipIcon("build"); break;
			case "TBD": setChipCss("chip-icon bg-warning"); setChipIcon("chatbubble-ellipses"); break;
			case "Monitoring": setChipCss("chip-icon bg-cyan"); setChipIcon("search"); break;
			case "Pending": setChipCss("chip-icon bg-yorange"); setChipIcon("pause"); break;
			case "Cancel": setChipCss("chip-icon bg-orange"); setChipIcon("close-sharp"); break;
			case "Close": setChipCss("chip-icon bg-success"); setChipIcon("checkmark-sharp"); break;
		}
	}

	useEffect(() => {
		getChipCss();
	}, [item]);

	return (
		<div className="chip chip-media chip-default">
			<i className={chipCss}>
				<ion-icon name={chipIcon} className="md hydrated"></ion-icon>
			</i>
			{showText && <span className="chip-label">{item.status}</span>}
		</div>
	)
}

const ServiceCard = ({ item, UpdateStatus, favList, byHome = false, byKey = "", hideDate = false, css = "badge-info" }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const isFav = item && (favList ? favList.includes(item.ticketKey) : false);
	//const [t, i18n] = useTranslation();
	const [info, setInfo] = useState(item || {});

	useEffect(() => {
		if (byKey)
			getInfo();
	}, [byKey]);

	const getInfo = async () => {
		const ret = await axios.get(AppConfig.API_SERVER_PATH + '/service/getbyticket/' + byKey, JWT());
		if (ret.data.status == 'success') {
			setInfo(ret.data.data);
		}
	}

	return (
		<>
			{byHome &&
				<div className="card mb-1">
					<div className="card-body pb-1">
						<div className="swipeable-list-item">
							<Link to={"/Service/" + info.ticketKey} className={info.status == "Close" ? "item p-2 text-white" : "item p-2"}>
								{!hideDate && <span className={"float-end badge " + css}><small className="text-white ps-2 pe-2">{moment.utc(info.updateDate).local().fromNow()}</small></span>}
								<div className="in fl">
									<div>
										<strong className="text-primary">{info.summary}</strong>
									</div>
									<div className="mt-1">
										<div className="chip chip-media chip-default">
											<i className="chip-icon bg-info">
												<ion-icon name="ticket-outline" role="img" className="md hydrated" aria-label="ticket outline"></ion-icon>
											</i>
											<span className="chip-label">{info.ticketKey}</span>
										</div>
										<ServiceStatusChip item={info} />
										<div className="chip chip-media ms-1">
											<i className="chip-icon bg-secondary">
												<ion-icon name="person" className="md hydrated"></ion-icon>
											</i>
											<span className="chip-label">{info.assignee || "Not assigned"}</span>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			}
			{!byHome &&
				<Link to={isAdmin ? "/Admin/AdminService/" + info.ticketKey : "/Service/" + info.ticketKey}
					className={info.status == "Close" ? "item p-2 text-white" : "item p-2"}>
					<div className="in fl">
						<div>
							<div>
								<strong className="text-primary">{info.summary}</strong>
							</div>
							<div className="mt-1">
								<div className="chip chip-media chip-default">
									<i className="chip-icon bg-info">
										<ion-icon name="ticket-outline" role="img" className="md hydrated" aria-label="ticket outline"></ion-icon>
									</i>
									<span className="chip-label">{info.ticketKey}</span>
								</div>
								<ServiceStatusChip item={info} />
								<div className="chip chip-media ms-1">
									<i className="chip-icon bg-secondary">
										<ion-icon name="person" className="md hydrated"></ion-icon>
									</i>
									<span className="chip-label">{info.assignee || "Not assigned"}</span>
								</div>
							</div>
						</div>
						<span className={info.status == "Close" ? "text-white" : "text-muted"}>{info.customerFirstName && info.customerFirstName} </span>
					</div>
					{
						!hideDate &&
						<span className={"float-end badge " + css}>
							<small className="text-white ps-2 pe-2">
								{moment.utc(info.updateDate).local().fromNow()}
								{/* {moment.utc().local()} */}
							</small>
						</span>
					}
				</Link>
			}
		</>
	)
}

const mapState = (state) => {
	var service = state.ServiceReducer.service;
	const favList = state.FavoriteReducer.service;
	return { service, favList };
};

const mapDispatch = dispatch => ({
	UpdateStatus: (_id, data) => dispatch(ServiceAction.UpdateStatus(_id, data)),
	Delete: (_id) => dispatch(ServiceAction.Delete(_id)),
})

export default connect(mapState, mapDispatch)(ServiceCard);
export {
	ServiceStatusChip,
	ServiceStatusIcon
}