import { SiteConstant } from '../actions/constants/SiteConstant';
const initialState = {
    sites: [],
    site: {},
    oldSite: {},
    isUpdateRes: false,
    filterDateOptions: null,
    totalSiteCount: 0,
    isLoading: false,
};
export const SiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case SiteConstant.GET_FAV_SITELIST:
        case SiteConstant.GET_SITELIST:
            return {
                ...state,
                sites: (action.append && action.sites.page != 1) ? [...state.sites, ...action.sites.list] : action.sites.list,
                filterOptions: action.sites.filterOptions,
                filterDateOptions: action.sites.filterDateOptions,
                totalSiteCount: action.sites.totalcount,
                isUpdateRes: false
            };
        case SiteConstant.GET_SITE:
            return { ...state, site: action.site };
        case SiteConstant.UPDATE_SITEINFO:
            return {
                ...state,
                sites: state.sites.map(u => u._id == action.site._id ? action.site : u),
                site: action.site
            };
        case SiteConstant.DELETE_SITE:
            return {
                ...state,
                sites: state.sites.filter(u => u._id !== action.delsiteid),
                isUpdateRes: true
            };
        case SiteConstant.CLEAR_UPDATERES:
            return { ...state, isUpdateRes: false };
        case SiteConstant.ADD_SITEINFO:
            return {
                ...state,
                sites: state.sites.map(u => u._id == action.site._id ? action.site : u),
                site: action.site
            };
        case SiteConstant.LOADING:
            return { ...state, isLoading: true }
        case SiteConstant.LOADED:
            return { ...state, isLoading: false }
        default:
            return state
    }
}