import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { Modal } from 'react-bootstrap';
import { InstallationAction, ErrorCodeAction, ProductAction } from '../../actions';
import { AppHeader } from '../../components/common/LeftMenu';
import { Translate as t } from '../../config/Translate';
import { InstallationConstant } from '../../actions/constants/InstallationConstant';
import InstallationStep0 from '../../components/installation/InstallationStep0';
import InstallationStep1 from '../../components/installation/InstallationStep1';
import InstallationStep2 from '../../components/installation/InstallationStep2';
import InstallationStep3 from '../../components/installation/InstallationStep3';
import InstallationStep4 from '../../components/installation/InstallationStep4';
import InstallationStep5 from '../../components/installation/InstallationStep5';
import InstallationStep6 from '../../components/installation/InstallationStep6';
import InstallationStep7 from '../../components/installation/InstallationStep7';

const Installation = ({ Get, GetProduct, installation, product, Update, user }) => {

	const dispatch = useDispatch();
	const { _id, view } = useParams();
	const [step, setStep] = useState(1);

	//const [t, i18n] = useTranslation();

	useEffect(() => {
		if (_id) {
			Get(_id);
		} else {
			dispatch({ type: InstallationConstant.NEW });
		}
	}, [])

	useEffect(() => {
		setStep(installation.installationStep);
		if (installation.sn) {
			GetProduct(installation.sn);
		}
	}, [installation])

	const goStep = (num, addminus) => {
		Update(installation._id, { installationStep: num + addminus });
	}

	const getHeaderStep = () => {
		var rows = [];
		for (var i = 1; i <= 8; i++) {
			if (step == i) {
				rows.push(<a className="badge badge-primary me-05" href onClick={(e) => goStep(e.target.title, '')} title={i}>{' '}</a>);
			} else {
				rows.push(<a className="badge badge-secondary me-05" href onClick={(e) => goStep(e.target.title, '')} title={i}>{' '}</a>);
			}
		}
		return rows;
	}

	return (
		<>
			<div className="appHeader no-border bg-white">
				<div className="left">
					<a href onClick={() => history.goBack()} className="headerButton goBack">
						<ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
					</a>
					<div className="pageTitle">
						{t('installreport')}
						{(installation._id && installation.siteName) && " : " + installation.siteName}
					</div>
				</div>
				<div className="right">
				</div>
			</div>
			<div className="extraHeader full-width step-title">
				{installation._id && <div className="section-title fl">
					{(view != "view" && step > 1) && <a onClick={() => goStep(step, -1)} className="text-primary btn-sm"><ion-icon name="caret-back-outline" role="img" className="md hydrated" aria-label="caret back outline"></ion-icon></a>}
				</div>}
				<div className="text-center step-badge">
					<h3 style={{ marginBottom: "5px" }}>Step {step}</h3>
					{getHeaderStep()}
				</div>
				{installation._id && <div className="section-title fr">
					{(view != "view" && step < 8) && <a onClick={() => goStep(step, 1)} className="text-secondary btn-sm"><ion-icon name="caret-forward-outline" role="img" className="md hydrated" aria-label="caret forward outline"></ion-icon></a>}
				</div>}
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{view == "view" && <InstallationStep7 info={installation} product={product} view="view" />}
				{view != "view" &&
					<>
						{(step === 1 && !installation._id) && <InstallationStep0 info={installation} />}
						{(step === 1 && installation._id) && <InstallationStep0 info={installation} product={product} />}
						{step === 2 && <InstallationStep1 info={installation} product={product} />}
						{step === 3 && <InstallationStep2 info={installation} product={product} />}
						{step === 4 && <InstallationStep3 info={installation} product={product} />}
						{step === 5 && <InstallationStep4 info={installation} product={product} />}
						{step === 6 && <InstallationStep5 info={installation} product={product} />}
						{step === 7 && <InstallationStep6 info={installation} product={product} />}
						{step === 8 && <InstallationStep7 info={installation} product={product} />}
					</>
				}
			</div>
		</>
	);
};

const mapState = (state) => {
	const installation = state.InstallationReducer.installation;
	const product = state.ProductReducer.product;
	const user = state.AuthReducer.user;
	return { installation, product, user };
}

const mapDispatch = (dispatch) => ({
	GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
	Create: (data) => dispatch(InstallationAction.CreateInstallation(data)),
	Get: (_id) => dispatch(InstallationAction.GetInstallation(_id)),
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
});

export default connect(mapState, mapDispatch)(Installation);