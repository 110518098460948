import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BaiduMap,  asyncWrapper, Marker, MarkerClusterer, Label } from 'react-baidu-maps';
import { Translate as t } from '../../config/Translate';
import { DeviceModels } from '../../config';
import { CommonAction } from '../../actions/CommonAction';
import moment from 'moment';
import ProductView from '../../pages/product/ProductView';

const MapB = ({ showSub, user, automapplay }) => {
	const [showType, setShowType] = useState('installed');
	const [height, setvHg] = useState(300);
	const [search, setSearch] = useState("");
	const [scope, setScope] = useState("");
	const [device, setDevice] = useState("");
	const [sn, setSN] = useState("");
	const [marks, setMarks] = useState([]);
	const [aggre, setAggre] = useState({});
	const [zoom, setZoom] = useState(1);
	const [isMapOk, setIsMapOk] = useState(true);
	const [pin, setPin] = useState(null);

    useEffect(() => {
		window.addEventListener('resize', getHeight);
		setvHg(window.innerHeight);
	}, []);

	useEffect(() => {
		let interval;

		if (!interval) {
			if (showType.indexOf('service') > -1)
				makeAggreService(showType);
			else
				makeAggre(showType);
		}

		interval = setInterval(() => {
			if (showType.indexOf('service') > -1)
				makeAggreService(showType);
			else
				makeAggre(showType);
		}, 1000 * 60 * 10); // 10min

		return () => {
			clearInterval(interval);
		}
	}, [showType, device, scope]);

	useEffect(() => { 
		console.log('search============================');
		console.log(search);
	}, [search]);

	useEffect(() => {
		getItemData(aggre);
	}, [aggre]);

	useEffect(() => {
		// if (isMapOk) {
		// 	if (!automapplay)
		// 		return;

		// 	setTimeout(function () {
		// 		randomMove();
		// 	}, 5000);
		// }
	}, [isMapOk, automapplay])

	const randomMove = () => {
		if (automapplay && marks.length > 1 && !showSub) {
			const num = Math.floor(Math.random() * (marks.length - 1 - 0) + 0);
			setPin(num);
		}
	}

    const getHeight = () => setvHg(window.innerHeight);

    const goSearch = () => {
		if (search) {
			var ret = {
				type: 'product'
			}
			var aggrList = [];
			aggrList.push({ '$match': { 'siteId': { '$exists': true } } });
			aggrList.push({ '$match': { 'siteId': { '$ne': "" } } });
			aggrList.push(
				{
					GEN: "LIKESEARCH",
					COLS: ["sn", "sitename"],
					VAL: search
				});
			aggrList.push({
				$lookup: {
					from: 'sites',
					localField: 'siteId',
					foreignField: 'siteId',
					as: 'site'
				}
			})
			aggrList.push({
				$addFields: {
					'site': { "$arrayElemAt": ["$site", 0] }
				}
			});
			aggrList.push({
				$project: {
					'sn': 1,
					'site._id': 1,
					'site.lat': 1,
					'site.lng': 1,
					'site.sitename': 1,
				},
			});
			ret["aggregate"] = aggrList;
			setAggre({ ...ret });
		}

		setZoom(1);
	}

    const cancelSearch = () => {
		setSN("");
		setSearch("");
		makeAggre(showType);
	}

    const show = (type) => {
		setShowType(type);
		setSN("");
	}

	const makeAggreService = (showType) => {
		var ret = {
			type: 'product'
		}
		var aggrList = [];
		aggrList.push({ '$match': { 'siteId': { '$exists': true } } });
		aggrList.push({ '$match': { 'siteId': { '$ne': "" } } });
		aggrList.push({
			$lookup: {
				from: 'services',
				localField: 'sn',
				foreignField: 'sn',
				as: 'service'
			}
		})
		aggrList.push({
			$addFields: {
				'service': { "$arrayElemAt": ["$service", 0] }
			}
		});
		if (device)
			aggrList.push({ '$match': { model: device } });

		if (showType == "servicenew") {
			aggrList.push({ '$match': { 'service.status': { '$ne': 'Close' } } });
			if (scope) {
				aggrList.push({
					'$match': {
						'service.createDate': {
							'$gte': moment
								.utc(moment().utc())
								.add(-1 * scope, 'days').unix() * 1000
						}
					}
				});
			}
		}
		if (showType == "serviceoverdue") {
			aggrList.push({ '$match': { 'service.status': { '$ne': 'Close' } } });
			if (scope) {
				aggrList.push({
					'$match': {
						'service.createDate': {
							'$gte': moment
								.utc(moment().utc())
								.add(-1 * (settings.dsservicecardduration || 30), 'days').unix() * 1000
						}
					}
				});
			}
		}
		aggrList.push({
			$lookup: {
				from: 'sites',
				localField: 'siteId',
				foreignField: 'siteId',
				as: 'site'
			}
		})
		aggrList.push({
			$addFields: {
				'site': { "$arrayElemAt": ["$site", 0] }
			}
		});
		aggrList.push({
			$project: {
				'sn': 1,
				'site._id': 1,
				'site.lat': 1,
				'site.lng': 1,
				'site.sitename': 1,
			},
		});
		ret["aggregate"] = aggrList;

		setAggre(ret);
	}

	const makeAggre = () => {
		var ret = {
			type: 'product'
		}
		var aggrList = [];
		aggrList.push({ '$match': { 'siteId': { '$exists': true } } });
		aggrList.push({ '$match': { 'siteId': { '$ne': "" } } });
		if (device)
			aggrList.push({ '$match': { model: device } });
		if (showType == "error") {
			aggrList.push({ '$match': { 'installedDate': { $gt: 1065398400000 }, 'errs.closer': null, 'errs.end': null, } });
			if (scope) {
				aggrList.push({ '$match': { 'errs.begin': { '$gte': moment.utc(moment().utc()).add(-1 * scope, 'days').unix() * 1000 } } });
			}
			
		}
		if (showType == "installed") {
			if (scope)
				aggrList.push({
					'$match': {
						'installedDate': { '$gte': moment.utc(moment().utc()).add(-1 * scope, 'days').unix() * 1000 },
						'siteId': { $ne: '' }
					}
				});
		}
		aggrList.push({
			$lookup: {
				from: 'sites',
				localField: 'siteId',
				foreignField: 'siteId',
				as: 'site'
			}
		})
		aggrList.push({
			$addFields: {
				'site': { "$arrayElemAt": ["$site", 0] }
			}
		});
		aggrList.push({
			$project: {
				'sn': 1,
				'site._id': 1,
				'site.lat': 1,
				'site.lng': 1,
				'site.sitename': 1,
			},
		});
		ret["aggregate"] = aggrList;

		setAggre(ret);
	}

	const getItemData = async (query) => {
		if (query.aggregate) {
			var list = [];
			const ret = await CommonAction.GetList(query);
			var i = 0;
			ret.data.map(x => {
				if (x.site) {
					if (x.site.lng && x.site.lat) {
						list.push({
							lat: parseFloat(x.site.lat),
							lng: parseFloat(x.site.lng),
							title: x.site.sitename,
							_id: x.site._id,
							sn: x.sn,
						});
					}
				}
			});
			setMarks(list);
		}
	}

    const dayScopes = [
		{ code: "", title: t('alltime') },
		{ code: "1", title: "Last 1 Day" },
		{ code: "7", title: "Last 1 Week" },
		{ code: "14", title: "Last 2 Weeks" },
		{ code: "30", title: "Last 1 Month" },
		{ code: "60", title: "Last 2 Months" },
		{ code: "90", title: "Last 3 Months" },
	]

    const devices = [{ code: "", title: t('alldevices') }, ...DeviceModels.filter(x => user.isMegagen ? x.code == "R2 Studio Q" : x.code)];

    return (
        <div className="section p-0">
            {showSub && <div className="section ps-1 pe-1 bg-default">
                <div className="float-start mb-1 mt-1">
                    <div className="input-group input-group-sm">
                        <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} />
                        <button className="btn btn-primary mt-0" onClick={() => goSearch()}>{t('search')}</button>
                        <button className="btn btn-secondary mt-0" onClick={() => cancelSearch()}>{t('clear')}</button>
                    </div>
                </div>
                <div className="float-end mb-1 mt-1">
                    <div className="input-group input-group-sm">
                        <select
                            onChange={(e) => setDevice(e.target.value)}
                            value={device}
                            className="form-control">
                            {devices.map(x => <option value={x.code} key={x.code}>{x.title}</option>)}
                        </select>
                        <select
                            onChange={(e) => setScope(e.target.value)}
                            value={scope}
                            className="form-control" >
                            {dayScopes.map(x => <option value={x.code} key={x.code}>{x.title}</option>)}
                        </select>
                        <button className={`btn btn-${showType === 'installed' ? 'primary' : 'dark'} mt-0`} onClick={() => show("installed")}>{t('installed')}</button>
                        <button className={`btn btn-${showType === 'servicenew' ? 'primary' : 'dark'} mt-0`} onClick={() => show("servicenew")}>{t('servicenew')}</button>
                        <button className={`btn btn-${showType === 'serviceoverdue' ? 'primary' : 'dark'} mt-0`} onClick={() => show("serviceoverdue")}>{t('serviceoverdue')}</button>
                        <button className={`btn btn-${showType === 'error' ? 'primary' : 'dark'} mt-0`} onClick={() => show("error")}>{t('error')}</button>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>}
            <div className="section ps-0 pe-0">				
				<MapData data={marks} height={height - (showSub ? 130 : 65)} setSN={(sn) => setSN(sn)} zoom={zoom} setZoom={(x) => setZoom(x)} isRandom={automapplay} />
				{sn && <ProductView _sn={sn} />}
            </div>
        </div>
    )
}

const MapData = ({ data, height, setSN, zoom, setZoom, isRandom }) => {
	const AsyncMap = asyncWrapper(BaiduMap);
	
	const [center, setCenter] = useState({ lng: 118.127294, lat: 24.438853 });

	const selectSite = (position, sn) => {
		setZoom(17);
		setCenter(position);
		setSN(sn);
	}

	useEffect(() => {
		if (isRandom) {
			setTimeout(function () {
				const randomIndex = Math.floor(Math.random() * data.length);
				selectSite({ lat: data[randomIndex].lat, lng: data[randomIndex].lng }, data[randomIndex].sn);
			}, 5000);
		}

	}, [isRandom]);

	useEffect(() => {
		console.log(data);
	}, [data]);
	return (
		<div style={{ height }}>
			<AsyncMap
				mapUrl={`https://api.map.baidu.com/api?v=2.0&ak=FjPal1gPoU1FyiXOHMyBRUGGGPjnMpqf`}
				loadingElement={<div>Loading.....</div>}
				mapContainer={<div style={{ height }} />}
				enableScrollWheelZoom={true}
				defaultZoom={zoom}
				zoom={zoom}
				center={center}
			>
				<MarkerClusterer>
					{
						data?.map((x, idx) => {
							return (
								<Marker key={idx}
									position={{ lng: x.lng, lat: x.lat }}
									title={x.title}
									onClick={() => selectSite({ lng: x.lng, lat: x.lat }, x.sn)}>									
								</Marker>
							)
						})
					}
				</MarkerClusterer>
			</AsyncMap>
		</div>
	)
}

const mapState = (state) => {
	var showSub = state.ProgramReducer.showSub;
	var showChart = state.ProgramReducer.showChart;
	var chartType = state.ProgramReducer.chartType;
	var automapplay = state.ProgramReducer.automapplay;
	var user = state.AuthReducer.user;
	return { showSub, showChart, chartType, automapplay, user };
};

export default connect(mapState)(MapB);
