import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { InstallationConstant } from './constants/InstallationConstant';
import { AlertAction } from './AlertAction';
import { ProgramConstant } from './constants/ProgramConstant';
import { store } from '../store';

const GetInstallationList = (filter, append = false) => async (dispatch) => {
	dispatch({ type : InstallationConstant.LOADING });
    const data = { type : "get", data : filter };
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation', data, JWT());
    
    if(ret.data.status == 'success') {
        dispatch({ type: InstallationConstant.GET_INSTALLATIONLIST, result : ret.data.data, append });
    } else {
        dispatch(AlertAction.Error(ret.data.err));
    }
	dispatch({ type : InstallationConstant.LOADED });
};

const GetInstallation = (_id) => async (dispatch) => {
	dispatch({ type : InstallationConstant.LOADING });
    const ret = await axios.get(AppConfig.API_SERVER_PATH + '/installation/' + _id, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: InstallationConstant.GET_INSTALLATION, installation : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : InstallationConstant.LOADED });
};

const GetCountNotFinished = async () => {
    const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate.push({ '$match' : { 'model' : { '$in' : showmodel} , isSubmit : false, customerId : { '$ne' : ''}, sn : { '$ne' : ''}, site : { '$ne' : ''} } });
	}else{
		aggregate.push({ '$match' : { isSubmit : false, customerId : { '$ne' : ''}, sn : { '$ne' : ''}, site : { '$ne' : ''} } });
    }
    aggregate = [...aggregate, ...[{
        '$lookup' : {
            from: 'sites',
            localField: 'siteId',
            foreignField: 'siteId',
            as: 'site'
        }
    },
    {
        '$lookup' : {
            from: 'products',
            localField: 'sn',
            foreignField: 'sn',
            as: 'product'
        }
    },
    {
        '$lookup' : {
            from: 'companies',
            localField: 'companyId',
            foreignField: 'companyId',
            as: 'companyInfo'
        }
    },{
        '$addFields' : { 
            'site' :  { "$arrayElemAt": [ "$site", 0 ] },
            'product' :  { "$arrayElemAt": [ "$product", 0 ] },
            'operator' : {'$arrayElemAt': ['$companyInfo.name', 0] }
        }
    },{
        '$project' : { 
            '_id' : 1,
            'isSubmit' : 1,
            'installationStep' : 1,
            'site.sitename': 1,
            'product.sn': 1,
            'product.model': 1,
            'product.option': 1,
            'product.sn': 1,
            // 'product.autoDiagnoses': 0,
            // 'product.alignments': 0,
            // 'product.errs': 0,
            // 'product.acquisitions': 0,
            // 'product.cautions': 0,
            // 'product.qcs': 0,
            // 'product.histories': 0,
            // 'product.sitehistories': 0,
        }
    }]];
	const data = {
		data : {
			type: "installation",
			aggregate,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
    if(ret.data.status == 'success') {
        console.log('installation', ret.data.data);
        return ret.data.data;
    } else {
        AlertAction.Error(ret.data.error);
    }
};

const CreateInstallation = (info) => async (dispatch) => {
	dispatch({ type : InstallationConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'create',
		data: info,
	};
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation', data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: InstallationConstant.GET_INSTALLATION, installation : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : InstallationConstant.LOADED });
};

const UpdateInstallation = (_id, info) => async (dispatch) => {
	dispatch({ type : InstallationConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: info,
	};
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation/' + _id, data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: InstallationConstant.GET_INSTALLATION, installation : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : InstallationConstant.LOADED });
};

const DeleteInstallation = (_id) => async (dispatch) => {
	dispatch({ type : InstallationConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete'
	};
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation/' + _id, data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type: InstallationConstant.DELETE, delinstallationid : _id });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
	dispatch({ type : InstallationConstant.LOADED });
};

const SendInstallationMail = (_id, info, subType) => async (dispatch) => {
    dispatch({ type : InstallationConstant.LOADING});
    const data = {
        who: JSON.parse(localStorage.getItem('user')).adminid,
        type: 'sendMail',
        subType: subType,
        data: info,
    };
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation/' + _id, data, JWT());
    if(ret.data.status == 'success') {
        dispatch({ type : InstallationConstant.LOADED });
    } else {
        dispatch(AlertAction.Error(ret.data.error));
    }
    dispatch({ type : InstallationConstant.LOADED });
}

const Signature = (_id, type, attachmentData) => async (dispatch) => {
    dispatch({ type : InstallationConstant.LOADING });
    const data = {
        who: JSON.parse(localStorage.getItem('user')).adminid,
        type: type == "ADD" ? "addSignature" : "delSignature",
        data: attachmentData
    };
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation/' + _id, data, JWT());
    if (ret.data.status == 'success') {
        dispatch(AlertAction.Success("Update Signature."));
        dispatch({ type: InstallationConstant.INSTALLATION_UPDATE_SIG, installation : ret.data.data });
    } else {
        dispatch(AlertAction.Error(ret.data));
    }
    dispatch({ type : InstallationConstant.LOADED });
}

const Attachment = (_id, type, attachmentData) => async (dispatch) => {
	dispatch({ type : InstallationConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type == "ADD" ? "addAttachment" : "delAttachment",
		data : attachmentData
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/installation/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update " + type + "!"));
		dispatch({ type: type == "ADD" ? 
			InstallationConstant.INSTALLATION_UPDATE_ADD_ATTACHMENT : 
			InstallationConstant.INSTALLATION_UPDATE_DEL_ATTACHMENT, installation : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type : InstallationConstant.LOADED });
};

export const InstallationAction = {
    GetInstallationList,
    GetInstallation,
    CreateInstallation,
    UpdateInstallation,
    DeleteInstallation,
    GetCountNotFinished,
    SendInstallationMail,
    Attachment,
    Signature
};