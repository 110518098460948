import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SiteAction } from '../../actions/SiteAction';
import { RayForm } from '../../components/common';
import { SpecialityTypes } from '../../config/RayCodes';
import { CountryCode } from '../../config';
import { NoRecord } from '../../pages/HomePage';
import { Translate as t } from '../../config/Translate';

const SiteInfo = ({ siteId, siteReducer, GetSite, UpdateSiteInfo, editable, siteInfo, showTitle = false, onlyShowHasVal = false, nocard = false }) => {
    const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
    const [errors, setErrors] = useState([]);
    const [site, setSite] = useState(siteInfo);

    useEffect(() => {
        setSite(siteReducer);
    }, [siteReducer]);

    useEffect(() => {
        if (siteId)
            GetSite(siteId);
    }, []);

    const form_controls = [
        {
            title: "", items: [
                {
                    type: "input", name: "sitename", label: t('sitename'),
                    viewLinkPath: isAdmin ? "/Admin/AdminSiteInfo/" : "/Site/",
                    viewLinkUseId: site && site.siteId,
                    extra: site,
                    val: site && site.sitename, required: true, isEditable: editable
                },
                { type: "select", name: "speciality", label: t('specialty'), val: site && site.speciality, list: SpecialityTypes, isEditable: editable },
                { type: "tel", name: "tel", label: t('tel'), val: site && site.tel, isEditable: editable },
                { type: "tel", name: "fax", label: t('fax'), val: site && site.fax, isEditable: editable },
                { type: "email", name: "email", label: t('email'), val: site && site.email, isEditable: editable },
                { type: "tel", name: "hp", label: t('mobile'), val: site && site.hp, isEditable: editable },
                { type: "input", name: "address", label: t('address'), size: 12, val: site && site.address, isEditable: editable },
                { type: "tel", name: "zipcode", label: t('zipcode'), val: site && site.zipcode, isEditable: editable },
                { type: "select", name: "country", label: t('country'), val: site && site.country, list: CountryCode, isEditable: editable },
                { type: "input", name: "website", label: t('homepage'), val: site && site.website, size: 12, isEditable: editable }
            ]
        }
    ]

    const getControl = () => {
        var list = []
        if (!editable && !showTitle) {
            list = [{ title: "", items: form_controls[0].items.filter(x => x.name != "sitename") }];
        } else {
            list = form_controls;
        }

        if (onlyShowHasVal && !editable) {
            list = [{ title: "", items: form_controls[0].items.filter(x => x.val) }];
        }
        return list;
    }

    return <>
        {site && <>
            {site._id && <RayForm
                nocard={nocard}
                controls={getControl()}
                action={UpdateSiteInfo}
                actionkey={site && site._id}
                errors={errors} />}
            {!site._id && <div className='section'><NoRecord title="Not exists" contents="Site information does not exist now." /></div>}
        </>}
    </>
};

const mapState = (state) => {
    const siteReducer = state.SiteReducer.site;
    return { siteReducer };
}

const mapDispatch = dispatch => ({
    UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data)),
    GetSite: (_id) => dispatch(SiteAction.GetSite(_id))
})

export default connect(mapState, mapDispatch)(SiteInfo);