import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RayForm, RayFormValidation, InputBasicText } from '../common';
import { InstallationAction, ProductAction } from '../../actions';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const SubItemList = ({ name, type, info, handleChangeDataList, isSummary = false }) => {
	// const [ t, i18n ] = useTranslation();
	const [list, setList] = useState(info || []);
	const [item, setItem] = useState({ type: type, title: "", ip: "", port: "" });

	useEffect(() => {
		handleChangeDataList(name, list);
	}, [list]);

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	}
	const Add = () => {
		setList([...list, item]);
		setItem({ type: type, title: "", ip: "", port: "" });
	}
	const Del = (idx) => {
		list.splice(idx, 1);
		setList([...list]);
	}
	return (
		<>
			<div className="">
				<div className="card">
					<ul className="listview simple-listview no-space">
						{list.map((x, idx) =>
							<li key={idx}>
								{x.title} / {x.ip} / {x.port}
								{!isSummary && <button className="btn btn-sm btn-danger" onClick={() => Del(idx)}>Del</button>}
							</li>
						)}
					</ul>
					{!isSummary &&
						<div className="">
							<InputBasicText
								options={{ val: item.title, name: "title", label: t('aetitle') }}
								isForm={!isSummary}
								inline={false}
								editable={!isSummary}
								onchange={handleChangeData} />

							<InputBasicText
								options={{ val: item.ip, name: "ip", label: t('ipaddress') }}
								isForm={!isSummary}
								editable={!isSummary}
								onchange={handleChangeData} />

							<InputBasicText
								options={{ val: item.port, name: "port", label: t('port') }}
								isForm={!isSummary}
								editable={!isSummary}
								onchange={handleChangeData} />
							{!isSummary && <button className="btn btn-block btn-primary" onClick={() => Add()}>{t('add')}</button>}
						</div>
					}
				</div>
			</div>
		</>
	);
}

const InstallationStep3 = ({ info, Update, UpdateProductInfo, isSummary = false, product }) => {
	// const [ t, i18n ] = useTranslation();
	const siteId = info.siteId;
	const [installation, setInstallation] = useState(info);
	const [pInfo, setPInfo] = useState({
		networkstaticip: "",
		verRayio: "",
		verRaycan: "",
		verSmartDent: "",
		verRayServer: "",
		verOndemand3D: ""
	});

	useEffect(() => {
		setPInfo({
			networkstaticip: product.extrainfo && product.extrainfo.networkstaticip || "",
			verRayio: product.extrainfo && product.extrainfo.verRayio || "",
			verRaycan: product.extrainfo && product.extrainfo.verRaycan || "",
			verSmartDent: product.extrainfo && product.extrainfo.verSmartDent || "",
			verRayServer: product.extrainfo && product.extrainfo.verRayServer || "",
			verOndemand3D: product.extrainfo && product.extrainfo.verOndemand3D || ""
		});
	}, [product.extrainfo])

	const Prev = () => {
		Update(installation._id, {
			...installation,
			installationStep: 3
		});
	};

	const validNext = () => {
		UpdateProductInfo(product._id, { extrainfo: pInfo });
		Update(installation._id, {
			pacsList: installation.pacsList,
			installationStep: 5
		});
	};

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setPInfo({ ...pInfo, [name]: value });
	};

	const handleChangeDataList = (type, list) => {
		const pList = installation.pacsList.filter(x => x.type != type);
		setInstallation({ ...installation, pacsList: pList.concat(list) });
	}

	const form_controls = [
		{
			title: t('diagnosisresult'), items: [
				{ type: "input", name: "networkstaticip", label: t('networkstaticip'), val: pInfo.networkstaticip, isEditable: !isSummary },
				{ type: "input", name: "verRayio", label: t('rayioversion'), val: pInfo.verRayio, isEditable: !isSummary },
				{ type: "input", name: "verRaycan", label: t('rayscanversion'), val: pInfo.verRaycan, isEditable: !isSummary },
				{ type: "input", name: "verSmartDent", label: t('smartdentversion'), val: pInfo.verSmartDent, isEditable: !isSummary },
				{ type: "input", name: "verRayServer", label: t('rayserverversion'), val: pInfo.verRayServer, isEditable: !isSummary },
				{ type: "input", name: "verOndemand3D", label: t('ondemand3dversion'), val: pInfo.verOndemand3D, isEditable: !isSummary }
			]
		},
	];

	return (
		<>
			<RayForm controls={form_controls} isSummary={isSummary} isForm={!isSummary} handleChangeData={handleChangeData} />
			<div className={isSummary ? "pb-1" : "section"}>
				<div className={isSummary ? "section-title bdB2" : "section-title"}>{t('pacs')}</div>
				<div className={isSummary ? "" : "card mb-2 pb-1"}>
					<div className={isSummary ? "" : "card-body"}>
						<div className={isSummary ? "mt-1" : ""}>
							<h4>1) {t('mwl')}</h4>
						</div>
						<SubItemList
							isSummary={isSummary}
							info={info.pacsList.filter(x => x.type == "mwl")}
							type="mwl" name="mwl"
							handleChangeDataList={(type, list) => handleChangeDataList(type, list)} />
						<div className={isSummary ? "mt-1" : "mt-2"}>
							<h4>2) {t('dicomprinter')}</h4>
						</div>
						<SubItemList
							isSummary={isSummary}
							info={info.pacsList.filter(x => x.type == "dicom")}
							type="dicom" name="dicom"
							handleChangeDataList={(type, list) => handleChangeDataList(type, list)} />
						<div className={isSummary ? "mt-1" : "mt-2"}>
							<h4>3) SCP</h4>
						</div>
						<SubItemList
							isSummary={isSummary}
							info={info.pacsList.filter(x => x.type == "scp")}
							type="scp" name="scp"
							handleChangeDataList={(type, list) => handleChangeDataList(type, list)} />
					</div>
				</div>
			</div>
			{!isSummary && <div className="section mt-1 mb-2">
				<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
				<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
			</div>}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	UpdateProductInfo: (siteId, data) => dispatch(ProductAction.UpdateProductInfo(siteId, data)),
});

export default connect(null, mapDispatch)(InstallationStep3);