import axios from "axios";
import { AppConfig, JWT } from '../config';
import { SparePartsConstant } from './constants/SparePartsConstant';
import { AlertAction } from './AlertAction';

const Regist = (createData) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'regist', data: createData }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: SparePartsConstant.REGIST_SPARE_PARTS, spareParts: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
};

const GetList = (filter) => async (dispatch) => {
	const data = { type: "get", data: filter };
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', data, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: SparePartsConstant.GET_SPARE_PARTS_LIST, spareParts: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
}

const Update = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: "update", data }, JWT());

	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: SparePartsConstant.UPDATE_SPARE_PARTS, sparePart: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
}

const AddHistory = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: "addHistory", data }, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Added!"));
		dispatch({ type: SparePartsConstant.ADD_SPARE_PARTS_HISTORY, sparePart: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
}

const UpdateHistory = (_id, data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts/' + _id, { data }, JWT());

	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success('Updated!'));
		dispatch({ type: SparePartsConstant.UPDATE_SPARE_PARTS_HISTORY, sparePart: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data))
	}
}

const GetOne = (_id) => async (dispatch) => {
	try {
		const ret = await axios.get(AppConfig.API_SERVER_PATH + '/spareparts/' + _id, JWT());

		if (ret.data.status == 'success') {
			dispatch({ type: SparePartsConstant.GET_ONE_SPARE_PARTS, sparePart: ret.data.data });
		} else {
			dispatch(AlertAction.Error(ret.data));
		}
	} catch (error) {		
		dispatch(AlertAction.Error(error));
	}
}

const GetOneLocal = async (_id) => {
	try {
		const ret = await axios.get(AppConfig.API_SERVER_PATH + '/spareparts/' + _id, JWT());

		if (ret.data.status === 'success')
			return ret.data.data;
	} catch (error) {
		console.log(error);
	}
}

const GetGroupList = () => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'grouplist' }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.GET_GROUP_LIST, groupList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const RegistGroup = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'groupRegist', data }, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: SparePartsConstant.REGIST_GROUP, groupList: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
}

const UpdateGroup = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'groupUpdate', data }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.UPDATE_GROUP, groupList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data))
}

const RegistCompanyStock = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'registCompanyStock', data }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.REGIST_COMPANY_STOCK, companyStockList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const UpdateCompanyStock = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'updateCompanyStock', data }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.UPDATE_COMPANY_STOCK, companyStockList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const CompanyStockList = (companyId) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'companyStockList', data: { companyId } }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.COMPANY_STOCK_LIST, companyStockList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const CompanyStockInfoByServiceCode = (companyId, serviceCode) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'companyStockInfoByServiceCode', data: { companyId, serviceCode } }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.COMPANY_STOCK_INFO, companyStockInfo: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const CompanyStockReceiveList = (companyId, partsId) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'companyStockReceiveList', data: { companyId, partsId } }, JWT());
	
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.COMPANY_STOCK_IN_LIST, companyStockInList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}


const SubmitOrder = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'submitOrder', data }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_ORDER_SUBMIT, orderList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const OrderList = (companyId) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'orderList', data: { companyId } }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_ORDER_LIST, orderList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const GetOneOrderById = (_id) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'getOneOrderById', data: { _id } }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_ORDER_GET_ONE, order: ret.data.data[0] });
	else {
		dispatch(AlertAction.Error(ret.data));
	}
}

const GetOneOrderByIdLocal = async (_id) => {
	return await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'getOneOrderById', data: { _id } }, JWT());
}

const SparePartsOutRegist = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'outRegist', data }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_CONTENTS, outContents: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data))
}

const ChangeStatus = (companyId, _id, status, pi) => async (dispatch) => {
	const data = { companyId, _id, status, pi };

	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'changeStatus', data }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.ORDER_CHANGE_STATUS, orderList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const GetOutList = () => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'outList' }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_LIST, outList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const GetOutContentsById = (_id) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'outContents', data: { _id } }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_CONTENTS, outContents: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const GetOutContentsByOrderId = (orderId) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'outContentsByOrderId', data: { orderId } }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_CONTENTS, outContents: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const OutContentsUpdate = (data) => async (dispatch) =>{
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'outContentsUpdate', data }, JWT());
	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_CONTENTS, outContents: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const OutHistory = (_id) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'outHistory', data: { _id } }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_HISTORY, outHistory: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const ReceiveOrder = (orderId) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + "/spareparts", { type: 'receiveOrder', data: { orderId } }, JWT());

	if (ret.data.status == 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_IN_HISTORY, inHistory: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const CompanyOutList = (companyId, partsId) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'companyOutList', data: { companyId, partsId } }, JWT());

	if (ret.data.status === 'success')
		dispatch({ type: SparePartsConstant.COMPANY_OUT_LIST, companyOutList: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const CompanyOutRegist = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'companyOutRegist', data }, JWT());

	if (ret.data.status === 'success') 		
		dispatch({ type: SparePartsConstant.COMPANY_STOCK_INFO, companyStockInfo: ret.data.data })
	else
		dispatch(AlertAction.Error(ret.data));
}

const UpdateCIInfo = (data) => async (dispatch) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'updateCIInfo', data }, JWT());

	if (ret.data.status === 'success')
		dispatch({ type: SparePartsConstant.SPARE_PARTS_OUT_CONTENTS, outContents: ret.data.data });
	else
		dispatch(AlertAction.Error(ret.data));
}

const SendCIConfirmMail = async (data) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/spareparts', { type: 'sendCIConfirmMail', data }, JWT());
}

const CalculateSparePartsTimes = (orderDate, piPublishTime, shipmentDate) => {
	/*
		hTime	: 자재확보시간 : piPublishTime - orderDate (시간으로 표현)
		cTime	: 자재출하기간 분류 :  piPublishTime - orderDate (1주내, 48시간 내 등)
		jTime	: 자재조달시간 : shipmentDate - piPublishTime (일 단위로 표현)
	*/

	let result = {};

	if (piPublishTime > 0 && orderDate > 0) {
		const hTime = ((piPublishTime - orderDate) / 1000 / 60 / 60).toFixed(0);

		let cTime = '';

		if (hTime <= 48)
			cTime = '48시간 내';

		if (hTime > 48 && hTime <= 24 * 7)
			cTime = '1주 내';

		if (hTime > 24 * 7 && hTime <= 24 * 7 * 2)
			cTime = '1주 이상';

		if (hTime > 24 * 7 * 2 && hTime <= 24 * 7 * 3)
			cTime = '2주 이상';

		if (hTime > 24 * 7 * 3 && hTime <= 24 * 7 * 4)
			cTime = '3주 이상';

		if (hTime > 24 * 7 * 4)
			cTime = '한 달 이상';

		result = { hTime, cTime };
	}

	if (piPublishTime > 0 && shipmentDate > 0) {
		const jTime = ((shipmentDate - piPublishTime) / 1000 / 60 / 60 / 24).toFixed(0);
		result = { ...result, jTime: jTime < 1 ? 1 : jTime }
	}

	return result;
}

export const SparePartsAction = {
	Regist,
	GetList,
	Update,
	GetOne,
	GetOneLocal,
	AddHistory,
	UpdateHistory,
	GetGroupList,
	RegistGroup,
	UpdateGroup,
	RegistCompanyStock,
	UpdateCompanyStock,
	CompanyStockList,
	CompanyStockInfoByServiceCode,
	CompanyStockReceiveList,
	SubmitOrder,
	OrderList,
	GetOneOrderById,
	GetOneOrderByIdLocal,
	SparePartsOutRegist,
	ChangeStatus,
	GetOutList,
	GetOutContentsById,
	GetOutContentsByOrderId,
	OutHistory,
	ReceiveOrder,
	CompanyOutList,
	CompanyOutRegist,
	UpdateCIInfo,
	SendCIConfirmMail,
	OutContentsUpdate,
	CalculateSparePartsTimes
};