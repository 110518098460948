import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { history } from '../../history';
import { AppHeader } from '../../components/common/LeftMenu';
import QrReader from 'react-qr-reader'
import { Translate as t } from '../../config/Translate';

const QRScan = ({ }) => {

	const user = useSelector((state) => state.AuthReducer.user)
	// const [ t, i18n ] = useTranslation();
	const [qrStart, setQRStart] = useState(false);
	const [err, setErr] = useState("");

	const handleScan = data => {
		if (data) {
			console.log(data);
			const arr = data.split('=');
			history.push("/Product/" + arr[1]);
		}
	}

	const handleError = err => {
		//console.log(err)
		//setErr(err)
	}

	return (
		<>
			<div className="appHeader no-border">
				<AppHeader title={t('scanqrcode')} />
				<div className="right">
				</div>
			</div>
			<div id="appCapsule" className={!qrStart ? "extra-header-active full-height" : "full-height"}>
				{!qrStart &&
					<>
						<div className="section">
							<div className="splash-page mt-5 mb-5">
								<div className="mb-3">
									<img src="assets/img/qrcode-quide-ct.png" alt="QR Code" className="imaged square w310" />
								</div>
								{t('pleasescantheqr')}
							</div>
						</div>
						<div className="section">
							<div className="fixed-bar b60">
								<button className="btn btn-lg btn-primary mb-2 btn-block" onClick={() => setQRStart(true)}>{t('scanqrcode')}</button>
							</div>
						</div>
					</>
				}
				{qrStart &&
					<div className="section ps-2 pe-2">
						<QrReader
							delay={300}
							onError={(err) => handleError(err)}
							onScan={(data) => handleScan(data)}
							style={{ width: '100%' }} />
						{err && <p className="alert">{err}</p>}
						<button className="btn btn-lg btn-dark mt-2 mb-2 btn-block" onClick={() => setQRStart(false)}>{t('cancel')}</button>
					</div>
				}
			</div>
		</>
	)
}

export default QRScan;
