import { ErrorCodeConstant } from '../actions/constants/ErrorCodeConstant';
const initialState = {
    errorCodes: [],
    errorCode: {
        technote: [],
        solutions: [],
        attachments: [],
        model: [],
    },
    isLoading: false,
    isUpdateRes: false
};

export const ErrorCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ErrorCodeConstant.ERRORCODE_GET_LIST:
            return { ...state, errorCodes: action.errorCodes, isUpdateRes: false };
        case ErrorCodeConstant.ERRORCODE_GET_ONE:
            return {
              ...state,
              errorCodes: state.errorCodes.map((u) =>
                u?._id == action.errorCode?._id ? action.errorCode : u
              ),
              errorCode: action.errorCode,
              isUpdateRes: false,
            };
        case ErrorCodeConstant.ERRORCODE_ADD:
            return {
                ...state,
                errorCodes: [...state.errorCodes, action.errorCode],
                errorCode: action.errorCode,
                isUpdateRes: true
            };
        case ErrorCodeConstant.ERRORCODE_UPDATE:
            return {
                ...state,
                errorCodes: state.errorCodes.map(u => u._id == action.errorCode._id ? action.errorCode : u),
                errorCode: action.errorCode,
                isUpdateRes: true
            };
        case ErrorCodeConstant.ERRORCODE_DELETE:
            return {
                ...state,
                errorCodes: state.errorCodes.filter(u => u.errorCode != action.delitem.errorCode && u.lang != action.delitem.lang),
                isUpdateRes: true
            };
        case ErrorCodeConstant.ERRORCODE_CLEAR_UPDATERES:
            return { ...state, isUpdateRes: false };
        case ErrorCodeConstant.LOADING:
            return { ...state, isLoading: true }
        case ErrorCodeConstant.LOADED:
            return { ...state, isLoading: false }
        default:
            return state
    }
}