import axios from 'axios';
import moment from 'moment';
import { AppConfig, JWT } from '../config';
import { ServiceConstant } from './constants/ServiceConstant';
import { ProgramConstant } from './constants/ProgramConstant';
import { AlertAction } from './AlertAction';
import { store } from '../store';

const CreateService = (newservice) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const user = JSON.parse(localStorage.getItem('user'));
	const data = {
		who: user.adminid,
		type: 'create',
		data: { ...newservice, ticketNationCode: user.companyInfo.country },
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: ServiceConstant.SERVICE_CREATE, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Create Failed"));
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const CreateServiceLocal = async (newservice) => {
	const user = JSON.parse(localStorage.getItem('user'));
	const data = {
		who: user.adminid,
		type: 'create',
		data: { ...newservice, ticketNationCode: user.companyInfo.country },
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success')
		return ret.data.data;

	return null;
};

const GetServiceBySN = (sn) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/service/getbysn/' + sn, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ServiceConstant.SERVICE_PRODUCT_GET_ALL, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const GetSearchServiceAll = async (searchCols, keyword) => {
	const data = {
		type: "get",
		data: {
			searchCols: searchCols, //[ 'sitename', 'customerFirstName', 'customerLastName' ],
			searchKey: keyword
		}
	}
	return await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
};

const GetSearchServiceByAtlasSearch = async (keyword) => {
	const aggregate = [];
	aggregate.push({ 
		'$search': { 
			'index': 'rayguard-service',
			'text': {
				'query': keyword,
				'path': {
					'wildcard': '*' // "path": ["title", "content"],  // 검색할 필드들
					//"score": { "boost": { "value": 2, "path": "title" } }  // 제목 필드에 가중치 부여
				}
			}
		}
	},{
		'$addFields': {
			'score': { '$meta' : 'searchScore' }
		}
	},{
		'$sort': {
			'score': -1
		}
	},{
		'$limit': 20
	});
	aggregate.push({
		'$lookup' : {
			'from': 'products',
			'localField': 'sn',
			'foreignField': 'sn',
			'as': 'prds'
			}
	});
	aggregate.push({
		'$project': {
			'createDate' : 1,
			'ticketKey' : 1,
			'status' : 1,
			'summary' : 1,
			'updateDate' : 1,
			'assignee' : 1
		}
	});
	const data = {
		data : {
			type: "service",
			aggregate,
			passfilter: true,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());

	//const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		return ret;
	}
	return null
}

const GetServiceAll = (filter, append = false) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	
	const showmodel = store.getState().ProgramReducer.showmodel;
	const data = showmodel[0] !== '' ? 
		{ type: "get", data: {...filter, match : {...filter.match, model : { '$in' : showmodel }} } } : 
		{ type: "get", data: filter}

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ServiceConstant.SERVICE_GET_ALL, result: ret.data.data, append });
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Services",
			count: ret.data.data.totalcount
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const GetServiceOpenCount = async () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [];
	aggregate.push({ '$match': { 'status': { "$ne": "Close" } } });
	if(showmodel[0] !== ''){
		aggregate.push({
			'$lookup' : {
				'from': 'products',
				'localField': 'sn',
				'foreignField': 'sn',
				'as': 'prds'
			  }
		})
		aggregate.push({
			'$match' : {
				'prds' : {
					'$elemMatch' : {
						'model' : { '$in' : showmodel} 
					}
			  	}
			}
		});
	}
	aggregate.push({
		'$project': {
			'createDate' : 1,
			'ticketKey' : 1,
			'status' : 1,
			'summary' : 1,
			'updateDate' : 1,
			'assignee' : 1
		}
	});
	const data = {
		data : {
			type: "service",
			aggregate,
		}
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());

	//const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	}
	return null
	// const data = { type: "getopenCount" }
	// const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	// if (ret.data.status == 'success') {
	// 	return ret.data.data;
	// }
	// return null
};

const GetFavServiceAll = (filter) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const uData = JSON.parse(localStorage.getItem('user'));
	const data = {
		type: "get",
		data: { ...filter, match: { ticketKey: { '$in': uData.favoriteServices } } }
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ServiceConstant.SERVICE_GET_ALL, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const SearchService = async (filter) => {
	const data = { type: "get", data: filter }
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	}
	return [];
};

const GetServiceAllLocal = async (filter) => {
	const data = { type: "get", data: filter }
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());

	if (ret.data.status == 'success') {		
		return ret.data.data;
	}
};

const GetService = (_id) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/service/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ServiceConstant.SERVICE_GET_ONE, service: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const GetServiceByTicketKey = (ticketKey) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/service/getbyticket/' + ticketKey, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ServiceConstant.SERVICE_GET_ONE, service: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const UpdateService = (serviceId, serviceInfo) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: serviceInfo,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const SendMail = (serviceId, mailData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'sendMail',
		data: mailData,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Email sent!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Send email Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const UpdateAssignee = (serviceId, assignData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateAssignee',
		data: assignData,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update assignee!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const UpdateStatusBulk = (serviceIds, statusData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'statusUpdateBulk',
		list: serviceIds,
		data: statusData,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update status!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO_BULK, services: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const UpdateStatus = (serviceId, statusData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'statusUpdate',
		data: statusData,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update status!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const Comment = (serviceId, type, commentData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type,
		data: commentData
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update Comments - " + type + "!"));
		dispatch({ type: type == "ADD" ? ServiceConstant.SERVICE_UPDATE_INFO : ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const Replacement = (serviceId, type, replacementData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type == "ADD" ? "addReplacement" : "delReplacement",
		data: replacementData
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update Replacements!"));
		dispatch({
			type: type == "ADD" ?
				ServiceConstant.SERVICE_UPDATE_INFO :
				ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data
		});
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const Attachment = (serviceId, type, attachmentData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: type == "ADD" ? "addAttachment" : "delAttachment",
		data: attachmentData
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update " + type + "!"));
		dispatch({
			type: type == "ADD" ?
				ServiceConstant.SERVICE_UPDATE_INFO :
				ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data
		});
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const UpdateSig = (serviceId, sigData) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'updateSignature',
		data: sigData,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Update signature!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const MoveTrash = (serviceId) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'trash'
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + serviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Moved trash!"));
		dispatch({ type: ServiceConstant.SERVICE_UPDATE_INFO, service: ret.data.data });
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const Delete = (delserviceId) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete'
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + delserviceId, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted"));
		dispatch({ type: ServiceConstant.SERVICE_DELETE, delserviceId: delserviceId });
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const DeleteSimple = async (delserviceId) => {
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'delete'
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service/' + delserviceId, data, JWT());
	if (ret.data.status == 'success') {
		AlertAction.Success("Deleted");
		return true;
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
};

const AddUpdate = (info) => async (dispatch) => {
	dispatch({ type: ServiceConstant.ADDUPDATE, item: info });
};

const ClearUpdateResult = () => (dispatch) => {
	dispatch({ type: ServiceConstant.SERVICE_CLEAR_UPDATERES });
};

const GetReducer = (state) => {
	return state.ServiceReducer;
};


const GetAllHomeOverdueByFilter = (days) => async (dispatch) => {
	const fin = days > 0 ? moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 : 4102444800000;
	dispatch({ type: ServiceConstant.LOADING });
	const data = {
		type: "get",
		data: {
			match: {
				createDate: { '$lte': fin },
				status: { '$ne': "Close" },
				isDel: false
			}
		},
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/service', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ProgramConstant.SET_OVERDUE_SERVICE, list: ret.data.data.list });
	}
	dispatch({ type: ServiceConstant.LOADED });
};

const GetForExport = async (serviceId) => {
	const data = {
		type: "getforexport"
	}
	return await axios.get(AppConfig.API_SERVER_PATH + '/service/getbyticket/' + serviceId, JWT());
};

const GetServiceReplacementsBySN = (sn) => async (dispatch) => {
	dispatch({ type: ServiceConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/service/getreplacements/' + sn, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: ServiceConstant.SERVICE_GET_REPLACEMENTS, result: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: ServiceConstant.LOADED });
};

export const ServiceAction = {
	CreateService,
	CreateServiceLocal,
	GetServiceAll,
	GetFavServiceAll,
	GetService,
	GetServiceByTicketKey,
	UpdateService,
	SendMail,
	UpdateStatus,
	UpdateAssignee,
	Replacement,
	Attachment,
	UpdateSig,
	AddUpdate,
	Comment,
	MoveTrash,
	Delete,
	DeleteSimple,
	ClearUpdateResult,
	SearchService,
	GetReducer,
	GetServiceOpenCount,
	GetAllHomeOverdueByFilter,
	GetServiceBySN,
	GetSearchServiceAll,
	UpdateStatusBulk,
	GetForExport,
	GetServiceAllLocal,
	GetServiceReplacementsBySN,
	GetSearchServiceByAtlasSearch
};
