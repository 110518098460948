import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { AuthAction } from '../../actions/AuthAction';
import { AppConfig } from '../../config';

import AdminTopMenu from '../../components/common/AdminTopMenu';

const StaffManagementPage = () => {
	const [companyList, setCompanyList] = useState([]);
	const [accountList, setAccountList] = useState([]);
	const [filteredAccountList, setFilteredAccountList] = useState([]);
	const [selectedOrgIndex, setSelectedOrgIndex] = useState(-1);
	const [selectedAccountIndex, setSelectedAccountIndex] = useState(-1);

	const [selectedAccountItem, setSelectedAccountItem] = useState({});

	useEffect(() => {
		axios.get(AppConfig.API_SERVER_PATH + '/company').then((response) => {
			if (response.data.status === 'success') {
				setCompanyList(response.data.data);
			}
		});

		axios.get(AppConfig.API_SERVER_PATH + '/user').then((response) => {
			if (response.data.status === 'success') {
				setAccountList(response.data.data);
			}
		});
	}, []);

	const organization = [
		{ sortNum: 1, name: 'HQ', value: 'HQ' },
		{ sortNum: 2, name: 'Branch', value: 'Branch' },
		{ sortNum: 3, name: 'Distributor (HQ)', value: 'DstHQ' },
		{ sortNum: 4, name: 'Distributor (HQ + Branch)', value: 'DstHQBranch' },
		{ sortNum: 5, name: 'Distributor (Branch)', value: 'DstBranch' },
		{ sortNum: 6, name: 'Sub Distributor (Distributor)', value: 'SubDstDst' },
		{ sortNum: 7, name: 'Sub Distributor [Distributor (Branch)]', value: 'SubDstDstBranch' },
	];

	const handleSelectOrg = (value, index) => {
		setFilteredAccountList(accountList.filter((obj) => obj.companyInfo && obj.companyInfo.org == value));
		setSelectedOrgIndex(index);
		setSelectedAccountIndex(-1);
		setSelectedAccountItem({});
	};

	const handleSelectAccount = (selectedItem, index) => {
		setSelectedAccountIndex(index);
		setSelectedAccountItem(selectedItem);
	};

	return (
		<>
			<AdminTopMenu />
			<div className="contents">
				<div className="staff-management-left">
					<div className="staff-management-left-top">
						<div className="admin-contents-title">Organization</div>
						<div className="contents-part">
							<ul className="organization-list">
								{organization.map((item, index) => {
									return (
										<li
											key={item.sortNum}
											onClick={() => handleSelectOrg(item.value, index)}
											className={selectedOrgIndex == index ? 'org-selected' : ''}
										>
											{item.name}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
					<div className="staff-management-left-bottom">
						<div className="admin-contents-title">
							<div>Avail Company List</div>
							<div>
								<button type="button" className="admin-contents-title-button">
									Add
								</button>
								&nbsp;
								<button type="button" className="admin-contents-title-button">
									Delete
								</button>
							</div>
						</div>
						<div className="contents-part avail-company-contents">
							<table className="avail-company-list-table">
								<colgroup>
									<col />
									<col />
									<col />
								</colgroup>
								<tbody>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>HQ</td>
										<td>[HQ] RAY_HQ</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>HQ</td>
										<td>[HQ] RAY_CS</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>Branch</td>
										<td>[US] Ray America</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>Branch</td>
										<td>[AU] Ray Australia</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>Branch</td>
										<td>[DE] Ray Europe GmbH </td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>Branch</td>
										<td>[US] RAY DEMO</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>Branch</td>
										<td>[JP] Ray Japan</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>Branch</td>
										<td>[TW] Ray Taiwan</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[CH] EasySheng</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[PH] Oris</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[TH] SDT</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[VN] Tran &amp; Trung</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[MY] Raytech</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[SG] GOH</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[HK] Denco Medical</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[ID] PT M.M.I</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[IN] Chesa Dental Care</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQ</td>
										<td>[KH] QDS</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DshHQBrranch</td>
										<td>[TH] Dental Siam</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[PL] ES Dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[CZ] Everydent</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[IT] Apex Dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[GR] Dentoplan</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[RS] Orthodent</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[RO] Labvision</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[HU] DentalMode</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[BE] Bescan</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[FR] Couleur et Connection</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[TR] Kent Dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[AE] Al Zahrawi</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[BH] Backbone</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[KW] Al-Sayafe Medical</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[DZ] SO.DI.M.M.E.D</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[SA] Ahmed Abdulwahed</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[UA] DDT</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[RU] Pharmgeocom</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[YE] Al-Arifi</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[SK] Dentonet</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[QA] Al Tawasul</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[JO] Plus Dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[TR] Onur Dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[AE] Prime Med. Supply</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[LY] Alreyada</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[EG] New Dental Company</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[EU] MEGAGEN</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[FR] Idem Dentaire</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[SE] BiCef Solution</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[AL] RodioPharma</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[JP] Medi Panoramic</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[DE] Argon Dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[DE] CoSi</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[PT] P e Ortho</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[ES] P e Ortho</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Ray Distributor #01</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[CA] Ray Distributor #02</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[JP] Kyocera Medical</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] LED</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstHQBranch</td>
										<td>[EE] ProImplant</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Young's </td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Argon </td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] RF america</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] 3D Dex Ic.</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Darby</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Kapricon</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Integdental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Genicore</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Kern</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Dental Planet</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Denterprise</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Tri State</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Alliance Dental Solution</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] IQ</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] DTZ dental consulting</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Ashtel</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Dentall, Dentner</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Empire </td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Pro dental</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Plaza Dental &amp; Implant Center</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Tekitronics</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Dart</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Papilon</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Voxel</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Comfort </td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Neo</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Xpress</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[US] Dentner</td>
									</tr>
									<tr>
										<td>
											<input type="checkbox" />
										</td>
										<td>DstBranch</td>
										<td>[Peru] Mainimport S.A.C</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="staff-management-right">
					<div className="staff-management-right-top">
						<div className="admin-contents-title">Related Account List</div>
						<div className="contents-part related-account-contents">
							<table className="account-list-table">
								<colgroup>
									<col />
									<col />
									<col />
									<col />
									<col />
									<col />
									<col />
									<col />
								</colgroup>
								<thead>
									<tr>
										<td>LOGIN ID</td>
										<td>NAME</td>
										<td>ROLE</td>
										<td>EMAIL</td>
										<td>MOBILE</td>
										<td>FUNCTION</td>
										<td>COMPANY</td>
										<td>COUNTRY</td>
									</tr>
								</thead>
								<tbody>
									{filteredAccountList.map((item, index) => {
										return (
											<tr
												className={
													selectedAccountIndex == index
														? 'account-list-row account-list-selected'
														: 'account-list-row'
												}
												onClick={() => handleSelectAccount(item, index)}
											>
												<td>{item.adminid}</td>
												<td>{item.name}</td>
												<td>{item.adminType}</td>
												<td>{item.email}</td>
												<td>{item.hp}</td>
												<td>{item.function}</td>
												<td>{item.companyInfo && item.companyInfo.name}</td>
												<td>{item.rgaurdSettings && item.rgaurdSettings.language}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
					<div className="staff-management-right-bottom">
						<div className="admin-contents-title">
							<div>Account Details</div>
							<div>
								<button type="button" className="admin-contents-title-button">
									Email
								</button>
								&nbsp;
								<button type="button" className="admin-contents-title-button">
									New
								</button>
								&nbsp;
								<button type="button" className="admin-contents-title-button">
									Save
								</button>
							</div>
						</div>
						<div className="contents-part">
							<table className="account-details">
								<colgroup>
									<col width="8%" />
									<col width="10%" />
									<col width="23%" />
									<col width="10%" />
									<col width="23%" />
									<col width="10%" />
									<col width="23%" />
									<col width="10%" />
									<col width="23%" />
									<col width="10%" />
									<col width="23%" />
								</colgroup>
								<tbody>
									<tr>
										<th rowspan="4">
											<label>info</label>
										</th>
										<th>Login ID</th>
										<td>{selectedAccountItem.adminid}</td>
										<th>First Name</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={selectedAccountItem.firstName || ''}
											/>
										</td>
										<th>Last Name</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={selectedAccountItem.lastName || ''}
											/>
										</td>
										<th>Role</th>
										<td>
											<select>
												<option value="M" selected={selectedAccountItem.adminType === 'M'}>
													Manager
												</option>
												<option value="S" selected={selectedAccountItem.adminType === 'S'}>
													Supervisor
												</option>
												<option value="U" selected={selectedAccountItem.adminType === 'U'}>
													User
												</option>
											</select>
										</td>
										<th>Password</th>
										<td>
											<button type="button">Reset</button>
										</td>
									</tr>
									<tr>
										<th>email</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={selectedAccountItem.email || ''}
											/>
										</td>
										<th>Telephone</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={selectedAccountItem.tel || ''}
											/>
										</td>
										<th>Mobile</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={selectedAccountItem.hp || ''}
											/>
										</td>
										<th>Function</th>
										<td>
											<select id="slfunction">
												<option value="CS" selected={selectedAccountItem.function === 'CS'}>
													CS
												</option>
												<option
													value="Sales"
													selected={selectedAccountItem.function === 'Sales'}
												>
													Sales
												</option>
											</select>
										</td>

										<th>Language</th>
										<td>
											<select>
												<option value="abk">Abkhazian</option>
												<option value="ace">Achinese</option>
												<option value="ach">Acoli</option>
												<option value="ada">Adangme</option>
												<option value="ady">Adyghe; Adygei</option>
											</select>
										</td>
									</tr>

									<tr>
										<th>Creator</th>
										<td>{selectedAccountItem.creator}</td>
										<th>Created Time</th>
										<td>
											{(selectedAccountItem.createDate &&
												selectedAccountItem.createDate
													.replace('T', ' ')
													.replace('Z', ' ')
													.substring(0, 19)) ||
												''}
										</td>
										<th>Last Login</th>
										<td>
											{(selectedAccountItem.lastlogged &&
												selectedAccountItem.lastlogged
													.replace('T', ' ')
													.replace('Z', ' ')
													.substring(0, 19)) ||
												''}
										</td>
										<th>Login Status</th>
										<td>{selectedAccountItem.adminStatus}</td>
										<th>Access</th>
										<td>
											<input
												id="chkStaffDetailUseYN"
												type="checkbox"
												checked={selectedAccountItem.isUse ? true : false}
											/>
											<label htmlFor="chkStaffDetailUseYN">Allowed</label>
										</td>
									</tr>

									<tr>
										<th>Last Update by</th>
										<td>{selectedAccountItem.updater || ''}</td>
										<th>Last Update time</th>
										<td>
											{(selectedAccountItem.updateDate &&
												selectedAccountItem.updateDate
													.replace('T', ' ')
													.replace('Z', ' ')
													.substring(0, 19)) ||
												''}
										</td>
										<th>CS Portal Link</th>
										<td colspan="5">
											<input
												type="text"
												className="account-details-input"
												value={selectedAccountItem.csPortalLink || ''}
											/>
										</td>
									</tr>

									<tr>
										<th rowspan="3">
											Company
											<br />
											info
										</th>
										<th>Country</th>
										<td>
											<select>
												<option value="AF">Afghanistan</option>
												<option value="AL">Albania</option>
												<option value="DZ">Algeria</option>
												<option value="AW">American Samoa</option>
											</select>
										</td>
										<th>Organization</th>
										<td>
											{(selectedAccountItem.companyInfo && selectedAccountItem.companyInfo.org) ||
												''}
										</td>
										<th>Company</th>
										<td>
											<select style={{ maxWidth: '100%' }}>
												{companyList.map((item) => {
													return (
														<option
															value={item.companyId}
															selected={selectedAccountItem.company === item.companyId}
														>
															{item.name}
														</option>
													);
												})}
											</select>
										</td>

										<th>Note</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.note) ||
													''
												}
											/>
										</td>
										<th>
											<label>Timezone</label>
										</th>
										<td>
											<select style={{ maxWidth: '100%' }}>
												<option value="TZ201">(GMT-12:00) International Date Line West</option>
												<option value="TZ61">(GMT-11:00) Midway Island, Samoa</option>
												<option value="TZ38">(GMT-10:00) Hawaii</option>
												<option value="TZ02">(GMT-09:00) Alaska</option>
												<option value="TZ55">
													(GMT-08:00) Pacific Time (US and Canada); Tijuana
												</option>
											</select>
										</td>
									</tr>
									<tr>
										<th>Street Address</th>
										<td colspan="3">
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.address) ||
													''
												}
											/>
										</td>
										<th>Suburb Address</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.addressExtra) ||
													''
												}
											/>
										</td>
										<th>State</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.state) ||
													''
												}
											/>
										</td>
										<th>Zip Code</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.zip) ||
													''
												}
											/>
										</td>
									</tr>

									<tr>
										<th>Telephone</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.contact &&
														selectedAccountItem.companyInfo.contact.tel) ||
													''
												}
											/>
										</td>
										<th>Fax</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.contact &&
														selectedAccountItem.companyInfo.contact.fax) ||
													''
												}
											/>
										</td>
										<th>Email</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.contact &&
														selectedAccountItem.companyInfo.contact.email) ||
													''
												}
											/>
										</td>
										<th>Website</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.contact &&
														selectedAccountItem.companyInfo.contact.website) ||
													''
												}
											/>
										</td>
										<th>Region</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.region) ||
													''
												}
											/>
										</td>
									</tr>

									<tr>
										<th>Owner</th>
										<th>Name</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.owner &&
														selectedAccountItem.companyInfo.owner.name) ||
													''
												}
											/>
										</td>
										<th>Mobile</th>
										<td>
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.owner &&
														selectedAccountItem.companyInfo.owner.hp) ||
													''
												}
											/>
										</td>
										<th>Email</th>
										<td colspan="5">
											<input
												type="text"
												className="account-details-input"
												value={
													(selectedAccountItem.companyInfo &&
														selectedAccountItem.companyInfo.owner &&
														selectedAccountItem.companyInfo.owner.email) ||
													''
												}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	return { user: AuthAction.getUserInfo(state) };
};

export default connect(mapState)(StaffManagementPage);
