import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate as t } from '../../config/Translate';
import { Modal } from 'react-bootstrap';

import {
	AggrServiceChartLastMonth,
	AggrServiceReplacementPortion,
	AggrServiceTotalTicketCount,
	AggrServiceOpenCount,
	AggrServiceTotalClosedCount,
	AggrServiceOverdueOnlyCount,
	ServiceTotalEscalatedCount,
	AggrServiceLastestOpenTickets,
	AggrServiceLastestClosedTickets,
	AggrServiceResolve3Step,
	AggrServiceReplacementTracking
} from '../../config/Aggregates';

import WdigetItemCountCard from '../../widgets/WdigetItemCountCard';
import WidgetBarChart from '../../widgets/WidgetBarChart';
import WidgetCustomTable from '../../widgets/WidgetCustomTable';

import { InputBasicNumber } from '../../components/common/Inputs';
import { AuthAction } from '../../actions';


const AdminServiceDashboard = ({ user, UpdateUserAdminSetting, showmodel }) => {
	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setData({
			dashboardSettingServiceTotalTicketTile: user.dashboardSettingServiceTotalTicketTile || 0,
			dashboardSettingServiceClosedTicketTile: user.dashboardSettingServiceClosedTicketTile || 0,
			dashboardSettingServiceStatusMonthly: user.dashboardSettingServiceStatusMonthly || 12,
			dashboardSettingServiceClosedTicket: user.dashboardSettingServiceClosedTicket || 7,
			dashboardSettingServiceOverdueTicketTile: user.dashboardSettingServiceOverdueTicketTile || 1,
			dashboardSettingServiceReplacementParts: user.dashboardSettingServiceReplacementParts || 1,
			dashboardSettingServiceReplacements: user.dashboardSettingServiceReplacements || 1

		})
	}, [user]);

	const openSettingPopup = (key, title) => {
		setSelected({ key, title, value: data[key] });
		setShowModal(true);
	}

	const updateDashboardSetting = () => {
		UpdateUserAdminSetting({ [selected.key]: selected.value });
		setShowModal(false);
	}

	const handleChange = (e) => {
		setSelected({ ...selected, value: e.target.value });
	}

	const initializeUpdateData = () => {
		setShowModal(false);
	}

	return (
		<>
			<div className="extraHeader pb-0">
				<h2 className="mb-0">{t('servicedashboard')}</h2>
				<div className="ray-right">
					<Link to="/Admin/AdminServiceList" className="btn btn-sm mt-0">
						<ion-icon name="list-outline"></ion-icon> List
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section dashboardTop pt-2">
					<div className="row">
						<div className="col">
							<WdigetItemCountCard
								title={t('totalticket')}
								subtitle={data.dashboardSettingServiceTotalTicketTile == 0 ? t('all') : `${data.dashboardSettingServiceTotalTicketTile} ${t('days')}`}
								aggregate={AggrServiceTotalTicketCount(data.dashboardSettingServiceTotalTicketTile)}
								setting={() => openSettingPopup('dashboardSettingServiceTotalTicketTile', `${t('totalticket')} (${t('days')})`)}
							/>
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('openticket')}
								subtitle={t('all')}
								aggregate={AggrServiceOpenCount('ALL')} />
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('closedticket')}
								subtitle={data.dashboardSettingServiceClosedTicketTile == 0 ? t('all') : `${data.dashboardSettingServiceClosedTicketTile} ${t('days')}`}
								aggregate={AggrServiceTotalClosedCount(data.dashboardSettingServiceClosedTicketTile)}
								setting={() => openSettingPopup('dashboardSettingServiceClosedTicketTile', `${t('closedticket')} (${t('days')})`)}
							/>
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('overdueticket')}
								subtitle={`${data.dashboardSettingServiceOverdueTicketTile} ${t('days')}`}
								aggregate={AggrServiceOverdueOnlyCount(data.dashboardSettingServiceOverdueTicketTile)}
								setting={() => openSettingPopup('dashboardSettingServiceOverdueTicketTile', `${t('overdueticket')} (${t('days')})`)}

							/>
						</div>
						{
							(user.adminType == 'S' && user.companyId != 'RAY02091') &&
							<div className="col">
								<WdigetItemCountCard
									title={t('escalatedticket')}
									subtitle={t('all')}
									aggregate={ServiceTotalEscalatedCount()} />
							</div>
						}
					</div>
				</div>
				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<WidgetCustomTable
							title={t('openticket')}
							basePath='/Admin/AdminService/'
							linkKey='ticketKey'
							headers={[
								{ name: "status", label: t('status'), type: "STATUS" },
								{ name: "ticketKey", label: t('ticketnumber') },
								{ name: "site.sitename", label: t('sitename'), linkdata: 'siteId' },
								{ name: "sn", label: t('sn') },
								{ name: "createDate", label: t('registered'), type: 'DTONLY' },
							]}
							aggregate={AggrServiceLastestOpenTickets(20)}
							height={250}
						/>
					</div>
					<div className="col">
						<WidgetCustomTable
							title={t('averagetimetosolveissues')}
							headers={[
								{ name: "abc", label: t('duration'), type: "ABC" },
								{ name: "value", label: "Count", type: "NUMBER", align: "text-right" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							xtype={'abc'}
							aggregate={AggrServiceResolve3Step()}
							height={250}
						/>
					</div>
					<div className="col-md-5">
						<WidgetBarChart
							title={t('statusmonthly')}
							height={264}
							xtype={"month"}
							days={data.dashboardSettingServiceStatusMonthly || 12}
							aggregate={AggrServiceChartLastMonth('status', parseInt(data.dashboardSettingServiceStatusMonthly) || 12)}
							setting={() => openSettingPopup('dashboardSettingServiceStatusMonthly', `${t('statusmonthly')} (${t('month')})`)}

						/>
					</div>
				</div>
				<div className="row p-1 pb-0">
					<div className="col">
						<WidgetCustomTable
							title={`${t('closedticket')}`}
							basePath='/Admin/AdminService/'
							linkKey='ticketKey'
							headers={[
								{ name: "status", label: t('status'), type: "STATUS" },
								{ name: "ticketKey", label: t('ticketnumber') },
								{ name: 'site.sitename', label: t('sitename'), linkdata: 'siteId' },
								{ name: "sn", label: t('sn') },
								{ name: "closedDate", label: t('finish'), type: 'DTONLY' },
							]}
							aggregate={AggrServiceLastestClosedTickets(data.dashboardSettingServiceClosedTicketTile)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingServiceClosedTicketTile', `${t('closedticket')} (${t('days')})`)}							
						/>
					</div>
					<div className="col">
						<WidgetCustomTable
							title={t('replacementparts')}
							headers={[
								{ name: "title", label: t('part'), type: "PARTS" },
								{ name: "value", label: "Count", type: "NUMBER", align: "text-right" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } }
							]}
							aggregate={AggrServiceReplacementPortion(data.dashboardSettingServiceReplacementParts)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingServiceReplacementParts', `${t('replacementparts')} (${t('month')})`)}
						/>
					</div>
					<div className="col-md-5">
						<WidgetCustomTable
							title={t('replacements')}
							basePath='/Admin/AdminService/'
							linkKey='ticketKey'
							headers={
								[
									{ name: "status", label: t('status'), type: "STATUS" },
									{ name: "ticketKey", label: t('ticketnumber') },
									{ name: 'site.sitename', label: t('sitename'), linkdata: 'siteId' },
									{ name: "sn", label: t('sn') },
									{ name: "replacements.title", label: t('parttype') },
									{ name: "replacements.subtitle", label: t('part'), type: "PARTS" },
									{ name: "replacements.trackservice", label: t('courier') },
									{ name: "replacements.trackingno", label: t('trackingnumber'), type: "TRACKNO", trackservicekey: "replacements.trackservice" },
									{ name: "replacements.sendDate", label: t('sentday'), type: "DTONLY" },
									{ name: "replacements.recvDate", label: t('receivedday'), type: "DTONLY" },
									{ name: "createDate", label: t('createddate'), type: "DTONLY" },
								]
							}
							aggregate={AggrServiceReplacementTracking(data.dashboardSettingServiceReplacements)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingServiceReplacements', `${t('replacementparts')} (${t('month')})`)}
						/>
					</div>

					<Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)}>
						<Modal.Header>
							<Modal.Title>{t('settings')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='p-1'>
								<InputBasicNumber
									options={{
										label: selected.title,
										showpin: true,
										val: selected.value,
										positive: true,
									}}
									editable={true}
									onchange={handleChange}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => updateDashboardSetting()}>{t('save')}</button>
							<button className="btn btn-block btn-dark" onClick={() => initializeUpdateData()}>{t('close')}</button>
						</Modal.Footer>
					</Modal>

				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	const showmodel = state.ProgramReducer.showmodel;
	return { user, showmodel };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
})

export default connect(mapState, mapDispatch)(AdminServiceDashboard);
