import { AuthConstant } from '../actions/constants/AuthConstant';

var user, clist, userToken;
try{
	user = JSON.parse(localStorage.getItem('user'));
	clist = localStorage.getItem('clist');
	userToken = localStorage.getItem('userToken');
}catch{
	localStorage.removeItem('user');
	localStorage.removeItem('clist');
	localStorage.removeItem('userToken');
	localStorage.removeItem('rtUser');
	localStorage.removeItem('rtToken');
}

if (user && clist) {
	if (!user.adminid) {
		// const user = JSON.parse(localStorage.getItem('user'));
		localStorage.removeItem('user');
		localStorage.removeItem('userToken');
		user = {};
		userToken = "";
	}

	if (user?.rguardSettingDarkMode) {
		document.body.classList.add('dark-mode');
	}

	if (!user?.settings) {
		user.settings = {};		
	}

	if (!user?.adminheaders) {
		user.adminheaders = {};
	}
	
	if (!user?.filters) {
		user.filters = {};
	}
}
const initialState = (user && clist)
	? {
		loggingIn: true,
		user,
		isAdmin: false,
		isUpdateRes: false,
		userToken : userToken,
		isLoading : false,
		su : user?.companyInfo?.org == "HQ",
	  }
	: { 
		isLoading : false,
		loggingIn: false,
		su : false
	};

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case AuthConstant.LOGIN_REQUEST:
			return { user: action.user };
		case AuthConstant.LOGIN_SUCCESS:
			if(action.user.rguardSettingDarkMode == true)
				document.body.classList.add('dark-mode');
			return { loggingIn: true, user: action.user, isAdmin: false, su : action.user?.companyInfo?.org == "HQ", userToken : action.userToken};
		case AuthConstant.ADMINLOGIN_SUCCESS:			
			return { loggingIn: true, user: action.user, isAdmin: true, su : action.user?.companyInfo?.org == "HQ", userToken : action.userToken };
		case AuthConstant.TEST:
			return { ...state };
		case AuthConstant.CLEAR_UPDATERES:
			return { ...state, isUpdateRes: false };
		case AuthConstant.UPDATE_SUCCESS:
			if(action.user.rguardSettingDarkMode == true)
				document.body.classList.add('dark-mode');
			return { ...state, user: action.user, isUpdateRes: true };
		case AuthConstant.UPDATE_ADMINSETTING:
			return { ...state, user: action.user };
		case AuthConstant.UPDATE_ADMINFILTERS:
			return { ...state, user: action.user };
		case AuthConstant.LOGIN_FAILURE:
			return { loggingIn: false };
		case AuthConstant.LOGOUT:
			return { user : null,  loggingIn: false };
        case AuthConstant.LOADING:
            return {...state, isLoading : true}
        case AuthConstant.LOADED:
            return {...state, isLoading : false}
		default:
			return state;
	}
};
