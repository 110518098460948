import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import AdminSparepartsDetailIn from './AdminSparePartsDetailIn';
import AdminSparePartsDetailInfo from './AdminSparePartsDetailInfo';
import AdminSparepartsDetailOut from './AdminSparePartsDetailOut';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { history } from '../../history';

const AdminSparePartsDetail = ({ partsInfo, GetOne, Update }) => {
    const { _id } = useParams();
    const [selectedTab, setSelectedTab] = useState('IN');

    useEffect(() => {
        if (!partsInfo.hasOwnProperty('_id'))
            GetOne(_id);
    }, []);

    useEffect(() => {
        // if (!partsInfo.hasOwnProperty('_id'))
        //     history.push('/Admin/AdminSpareParts');
    }, [partsInfo]);

    const deleteParts = () => {
        if (confirm("삭제하시겠습니까?")) {
            Update({ _id, isDel: true });
        }
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0"></h2>
                <div className="ray-right">
                    <button className="btn btn-danger btn-sm" onClick={() => deleteParts()}>파트삭제</button>
                    <Link to={"/Admin/AdminSpareParts"} className="btn btn-outline-default btn-sm">목록으로</Link>
                </div>
            </div>

            <div id="appCapsule" className="extra-header-active full-height">
                <div className='row m-1 p-0'>
                    <div className="col-3 m-0 p-1">
                        <AdminSparePartsDetailInfo _id={_id} />
                    </div>

                    <div className="col m-0 p-1">
                        <div className="section mt-1 mb-1">
                            <ul className="nav nav-tabs style2 mt-1 mb-1">
                                <li className="nav-item">
                                    <a className={`nav-link${selectedTab === 'IN' ? ' active' : ''}`} onClick={()=> setSelectedTab('IN')}>입고</a>
                                    <a className={`nav-link${selectedTab === 'OUT' ? ' active' : ''}`} onClick={()=> setSelectedTab('OUT')}>출고</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            {selectedTab === 'IN' ? <AdminSparepartsDetailIn /> : <AdminSparepartsDetailOut />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapState = (state) => {
    const partsInfo = state.SparePartsReducer.sparePart;
    return { partsInfo };
};

const mapDispatch = (dispatch) => ({
    Update: (data) => dispatch(SparePartsAction.Update(data)),
    AddHistory: (data) => dispatch(SparePartsAction.AddHistory(data)),
    UpdateHistory: (_id, data) => dispatch(SparePartsAction.UpdateHistory(_id, data)),
    GetOne: (_id) => dispatch(SparePartsAction.GetOne(_id)),
});

export default connect(mapState, mapDispatch)(AdminSparePartsDetail);
