import axios from 'axios';
import { AppConfig, JWT, RT_TOKEN } from '../config';
import { CompanyConstant } from './constants/CompanyConstant';
import { AlertAction } from './AlertAction';
import { CompanyReducer } from '../reducers/CompanyReducer';

const GetCompany = () => async (dispatch) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/company', JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: CompanyConstant.GET_COMPANYLIST, companies: ret.data.data });
		// dispatch({ type: CompanyConstant.GET_COMPANYLIST, companies: data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	
	// const partners = await GetAllPartners();

	// if (partners.length > 0) {
	// 	const data = partners.map(x => {
	// 		return {
	// 			operator: x?.ops,
	// 			alias: x?.alias,
	// 			contactEmail: x?.cemail,
	// 			contactHp: x?.chp,
	// 			contactTel: x?.ctel,
	// 			contactFax: x?.cfax,
	// 			contactWebsite: x?.cweb,
	// 			createDate: x?.cd,
	// 			updateDate: x?.ud,
	// 			isDel: x?.isDel,
	// 			_id: x?._id,
	// 			companyId: x?.cId,
	// 			name: x?.nm,
	// 			country: x?.cnty,
	// 			org: x?.otype,
	// 			address: x?.address,
	// 			addressExtra: x?.addext,
	// 			state: x?.state,
	// 			zip: x?.zip,
	// 			region: x?.region,
	// 			note: x?.note,
	// 			ownername: x?.oname,
	// 			ownerhp: x?.ohp,
	// 			owneremail: x?.omail,
	// 			timezone: x?.tz,
	// 			lat: x?.coords?.length > 0 ? x.coords[0] : '',
	// 			lng: x?.coords?.length > 0 ? x.coords[1] : '',
	// 			parentCompanyId: x?.prntId,
	// 			companyTWId: x?.twId,
	// 			scp_1: parseInt(x?.sk?.replace('scp:', '').split(':')[0]),
	// 			scp_2: parseInt(x?.sk?.replace('scp:', '').split(':')[1]),
	// 			scp_3: parseInt(x?.sk?.replace('scp:', '').split(':')[2]),
	// 			updater: x?.ud
	// 		}
	// 	}).sort((a, b) => {
	// 		if (a.scp_1 === b.scp_1) {
	// 			if (a.scp_2 === b.scp_2)
	// 				return (a.scp_3 < b.scp_3) ? -1 : (a.scp_3 > b.scp_3) ? 1 : 0;
	// 			else
	// 				return (a.scp_2 < b.scp_2) ? -1 : 1;
	// 		} else {
	// 			return (a.scp_1 < b.scp_1) ? -1 : 1;
	// 		}
	// 	});

	// 	dispatch({ type: CompanyConstant.GET_COMPANYLIST, companies: data });
	// } else {
	// 	dispatch(AlertAction.Error(ret.data.err));
	// }

	
};

const GetCompanyByToken = (token) => async (dispatch) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/company', { headers: { token } });
	if (ret.data.status == 'success') {
		dispatch({ type: CompanyConstant.GET_COMPANYLIST, companies: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
};

const GetCompanyInfo = (companyId) => async (dispatch) => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/company/' + companyId, JWT());

	if (ret.data.status == 'success') {
		dispatch({ type: CompanyConstant.GET_COMPANY, company: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}

	// const partners = await GetAllPartners();
	// const partnerInfo = partners.find(x => x.cId === companyId);
	// const scp = partnerInfo?.sk?.replace('scp:', '');

	// const response = await axios.get(`${AppConfig.GROUP_API_PATH}/partner/${scp}`, RT_TOKEN());

	// if (response.data.status === 'success' && response.data.data.length > 0) {
	// 	const result = response.data.data[0];
	// 	const data = {
	// 		operator: result.ops,
	// 		alias: result.alias,
	// 		contactEmail: result.cemail,
	// 		contactHp: result.chp,
	// 		contactTel: result.ctel,
	// 		contactFax: result.cfax,
	// 		contactWebsite: result.cweb,
	// 		createDate: result.cd,
	// 		updateDate: result.ud,
	// 		isDel: result.isDel,
	// 		_id: result._id,
	// 		companyTWId: result.twId,
	// 		companyId: result.cId,
	// 		name: result.nm,
	// 		country: result.cnty,
	// 		org: result.otype,
	// 		address: result.address,
	// 		addressExtra: result.addext,
	// 		state: result.state,
	// 		zip: result.zip,
	// 		region: result.region,
	// 		note: result.note,
	// 		ownername: result?.oname,
	// 		ownerhp: result?.ohp,
	// 		owneremail: result?.omail,
	// 		timezone: result?.tz,
	// 		lat: result?.coords?.length > 0 ? result.coords[0] : 0,
	// 		lng: result?.coords?.length > 0 ? result.coords[1] : 0,
	// 		parentCompanyId: result.prntId,
	// 		scp: result.sk?.replace('scp:', '')
	// 	}

	// 	dispatch({ type: CompanyConstant.GET_COMPANY, company: data });
	// }
	// else {
	// 	dispatch(AlertAction.Error(ret.data));
	// }
};

const AddCompanyInfo = (companyInfo) => async (dispatch) => {
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: "create",
		data: companyInfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/company', data, JWT());

	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: CompanyConstant.ADD_COMPANYINFO, company: ret.data.data });

		// const status = await AddCompanyInfoDDB(ret.data.data);

		// if (status) {
		// 	dispatch(AlertAction.Success("Created!"));
		// 	dispatch({ type: CompanyConstant.ADD_COMPANYINFO, company: ret.data.data });
		// } else {
		// 	dispatch(AlertAction.ErrorMsg("Create Failed"));
		// }
	} else {
		dispatch(AlertAction.ErrorMsg("Create Failed"));
	}
};

const AddCompanyInfoDDB = async (prevData) => {
	const parentCompanyId = prevData.parentCompanyId;
	const allPartners = await GetAllPartners();
	const parentPartnerSCP = allPartners.find(x => x.cId === parentCompanyId)?.sk?.replace('scp:', '');

	const data = {
		type: 'create',
		data: {
			operator: prevData.operator,
			alias: prevData.alias,
			contactEmail: prevData.contactEmail,
			contactHp: prevData.contactHp,
			contactTel: prevData.contactTel,
			contactFax: prevData.contactFax,
			contactWebsite: prevData.contactWebsite,
			createDate: prevData.createDate,
			updateDate: 0,
			country: prevData.country,
			org: prevData.org,
			name: prevData.name,
			address: prevData.address,
			addressExtra: prevData.addressExtra,
			state: prevData.state,
			zip: prevData.zip,
			region: prevData.region,
			note: prevData.note,
			ownername: prevData.ownerName,
			ownerhp: prevData.ownerHp,
			owneremail: prevData.ownerEmail,			
			lat: prevData.lat,
			lng: prevData.lng,
			parentCompanyId,
			companyTWId: prevData.companyTWId,									
			parentPartnerSCP
		}
	}

	const ret = await axios.post(AppConfig.GROUP_API_PATH + '/partner', data, RT_TOKEN());

	if (ret.data.status === 'success')
		return true;
	else
		return false;
}

const UpdateCompanyInfo = (companyId, companyInfo) => async (dispatch) => {
	const data = {
		who: JSON.parse(localStorage.getItem('user')).adminid,
		type: 'update',
		data: companyInfo,
	};

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/company/' + companyId, data, JWT());
	if (ret.data.status == 'success') {

		// const result = await UpdateCompanyInfoDDB(companyId, companyInfo);

		// if (result)
		dispatch({ type: CompanyConstant.UPDATE_COMPANYINFO, company: ret.data.data });
		// else
		// 	dispatch(AlertAction.ErrorMsg("Update Failed"));

	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
};

const UpdateCompanyInfoDDB = async (companyId, companyInfo) => {
	const partners = await GetAllPartners();
	const partnerInfo = partners.find(x => x.cId === companyId);

	const _id = partnerInfo?._id;
	const scp = partnerInfo?.sk?.replace('scp:', '');

	const data = {
		type: 'update',
		data: {...companyInfo, scp}
	}

	const ret = await axios.post(`${AppConfig.GROUP_API_PATH}/partner/${_id}`, data, RT_TOKEN());

	if (ret.data.status === 'success')
		return true;
	else
		return false;
}

const DeleteCompany = (companyId) => async (dispatch) => {
	const data = {
		"who": JSON.parse(localStorage.getItem("user")).adminid,
		"type": "delete"
	}
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/company/' + companyId, data, JWT());
	if (ret.data.status == 'success') {
		// const result = await DeleteCompanyDDB(companyId);

		// if (result) {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: CompanyConstant.DELETE_COMPANY, delcompanyid: companyId });
		// } else {
		// 	dispatch(AlertAction.ErrorMsg("Delete Failed"));
		// }
	} else {
		dispatch(AlertAction.ErrorMsg("Delete Failed"));
	}
};

const DeleteCompanyDDB = async (companyId) => {
	const partners = await GetAllPartners();
	const partnerInfo = partners.find(x => x.cId === companyId);

	const _id = partnerInfo?._id;
	const scp = partnerInfo?.sk?.replace('scp:', '');

	const data = { type: 'delete', data: { scp } }

	const ret = await axios.post(`${AppConfig.GROUP_API_PATH}/partner/${_id}`, data, RT_TOKEN());

	if (ret.data.status === 'success')
		return true;
	else
		return false;
}

const GetParents = async () => {
	const companyId = JSON.parse(localStorage.getItem("user")).companyId;
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/company/getparents/' + companyId, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}

const GetParentsForRT = async () => {
	const cid = JSON.parse(localStorage.getItem('rtUser'))?.cId;
	const partners = await GetAllPartners();
	const scp = partners.find(x => x.cId === cid)?.sk.replace('scp:', '');

	if (scp !== '' && scp !== undefined) {
		const ret = await axios.get(AppConfig.GROUP_API_PATH + '/partner/getparents/' + scp, RT_TOKEN());
		if (ret.data.status === 'success')
			return ret.data.data;
		else
			return [];
	}
	else {
		return [];
	}
}

const GetAllPartners = async () => {
	const data = {
		type: "getall",
		user: {
			companyInfo: {
				org: "hq"
			},
			scp: "0:"
		}
	};

	const ret = await axios.post(AppConfig.GROUP_API_PATH + '/partner', data, RT_TOKEN());

	if (ret.data.status === 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}

const GetCombo = async () => {
	const isMegagen = JSON.parse(localStorage.getItem("user")).isMegagen;
	// // const scp = JSON.parse(localStorage.getItem("rtUser"))?.scp;

	// const data = isMegagen ? { type: 'getall', user: { companyInfo: { org: 'branch' }, scp: '0:29:' } } : { type: 'getcombo', user: { scp: '0:' } };

	// const response = await axios.post(`${AppConfig.GROUP_API_PATH}/partner`, data, RT_TOKEN());

	// if (response.data.status === 'success') {
	// 	return response.data.data?.sort((a, b) => {
	// 		if (parseInt(a.scp.split(':')[0]) === parseInt(b.scp.split(':')[0])) {
	// 			if (parseInt(a.scp.split(':')[1]) === parseInt(b.scp.split(':')[1]))
	// 				return (parseInt(a.scp.split(':')[2]) < parseInt(b.scp.split(':')[2])) ? -1 : (parseInt(a.scp.split(':')[2]) > parseInt(b.scp.split(':')[2])) ? 1 : 0;
	// 			else
	// 				return (parseInt(a.scp.split(':')[1]) < parseInt(b.scp.split(':')[1])) ? -1 : 1;
	// 		} else {
	// 			return (parseInt(a.scp.split(':')[0]) < parseInt(b.scp.split(':')[0])) ? -1 : 1;
	// 		}
	// 	}).map(x => { return { code: x.code, title: x.title } });
	// } else {
	// 	return [];
	// }

	if (isMegagen) {
		const companies = await axios.get(AppConfig.API_SERVER_PATH + '/company', JWT());
		return (companies.data.status == 'success') ? companies.data.data.map(x => { return { code: x.companyId, title: x.name } }) : [];
	} else {
		const ret = await axios.post(AppConfig.API_SERVER_PATH + '/company', { type: "getcombo" }, JWT());
		return (ret.data.status == 'success') ? ret.data.data : [];
	}
}

const ClearUpdateResult = () => (dispatch) => {
	dispatch({ type: CompanyConstant.CLEAR_UPDATERES });
};

const UpdateListFilter = (filter) => async (dispatch) => {
	function success(companies) { return { type: CompanyConstant.UPDATE_LISTFILTER, filter }; }
};

const GetReducer = (state) => {
	return state.CompanyReducer;
};

const GetCompanyLocal = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/company', JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}

	// const partners = await GetAllPartners();

	// let data = [];

	// if (partners.length > 0) {
	// 	data = partners.map(x => {
	// 		return {
	// 			operator: x?.ops,
	// 			alias: x?.alias,
	// 			contactEmail: x?.cemail,
	// 			contactHp: x?.chp,
	// 			contactTel: x?.ctel,
	// 			contactFax: x?.cfax,
	// 			contactWebsite: x?.cweb,
	// 			createDate: x?.cd,
	// 			updateDate: x?.ud,
	// 			isDel: x?.isDel,
	// 			_id: x?._id,
	// 			companyId: x?.cId,
	// 			name: x?.nm,
	// 			country: x?.cnty,
	// 			org: x?.otype,
	// 			address: x?.address,
	// 			addressExtra: x?.addext,
	// 			state: x?.state,
	// 			zip: x?.zip,
	// 			region: x?.region,
	// 			note: x?.note,
	// 			ownername: x?.oname,
	// 			ownerhp: x?.ohp,
	// 			owneremail: x?.omail,
	// 			timezone: x?.tz,
	// 			lat: x?.coords?.length > 0 ? x.coords[0] : '',
	// 			lng: x?.coords?.length > 0 ? x.coords[1] : '',
	// 			parentCompanyId: x?.prntId,
	// 			companyTWId: x?.twId,
	// 			scp_1: parseInt(x?.sk?.replace('scp:', '').split(':')[0]),
	// 			scp_2: parseInt(x?.sk?.replace('scp:', '').split(':')[1]),
	// 			scp_3: parseInt(x?.sk?.replace('scp:', '').split(':')[2]),
	// 			// updater: x?.ud
	// 		}
	// 	}).sort((a, b) => {
	// 		if (a.scp_1 === b.scp_1) {
	// 			if (a.scp_2 === b.scp_2)
	// 				return (a.scp_3 < b.scp_3) ? -1 : (a.scp_3 > b.scp_3) ? 1 : 0;
	// 			else
	// 				return (a.scp_2 < b.scp_2) ? -1 : 1;
	// 		} else {
	// 			return (a.scp_1 < b.scp_1) ? -1 : 1;
	// 		}
	// 	});
	// }

	// return data;
};

const IsMegagen = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + "/company/ismg", JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
}



export const CompanyAction = {
	GetCompany,
	GetCompanyByToken,
	GetCompanyInfo,
	GetCombo,
	UpdateListFilter,
	AddCompanyInfo,
	UpdateCompanyInfo,
	DeleteCompany,
	ClearUpdateResult,
	GetCompanyLocal,
	GetReducer,
	GetParents,
	GetParentsForRT,
	GetAllPartners
};
