export const ProgramConstant = {
    SIDEMENU_OPEN: 'SIDEMENU_OPEN',
    SHOW_TAB_MAIN: 'SHOW_TAB_MAIN',
    SHOW_FAV: 'LIST_SHOW_FAV',
    SHOW_ALL: 'LIST_SHOW_ALL',
    SIDEMENU_CLOSE: 'SIDEMENU_CLOSE',
    VIEWTREE: 'VIEWTREE',
    ERRORCODELOOKUP_SHOW: 'ERRORCODELOOKUP_SHOW',
    ERRORCODELOOKUP_HIDE: 'ERRORCODELOOKUP_HIDE',
    PRODUCTERROR_SHOWACTIONS: 'PRODUCTERROR_SHOWACTIONS',
    SETSEARCHFILTERS: 'SETSEARCHFILTERS',

    DASHBOARDSHOW_TYPE: 'DASHBOARDSHOW_TYPE',
    SET_SPLIDER: 'HOME_SET_SPLIDER',
    SET_SHOWSUB: 'SET_SHOWSUB',
    SET_MAPAUTOPLAY: 'SET_MAPAUTOPLAY',

    SET_CHARTOPT: 'SET_CHARTOPT',
    SET_PRODUCTLISTISSTOCK: 'SET_PRODUCTLISTISSTOCK',

    SET_RECENTINSTALL: 'HOME_SET_RECENTINSTALL',
    SET_TOTALPRDCOUNT: 'HOME_SET_TOTALPRDCOUNT',
    SET_OVERDUE_SERVICE: 'HOME_SET_OVERDUE_SERVICE',

    SET_SS_DOCCOUNT: 'SET_SS_DOCCOUNT',
    SET_INST_DOCCOUNT: 'SET_INST_DOCCOUNT',

    SET_SEARCHSCREEN_TITLE: 'HOME_SET_SEARCHSCREEN_TITLE',

    SET_SHEDS: 'HOME_SET_SHEDS',
    DELETE_SCH: 'HOME_DELETE_SCH',
    UPDATE_SHEDS: 'HOME_UPDATE_SHEDS',

    SET_ERRORS: 'HOME_SET_ERRORS',
    UPDATE_PRODUCTERRORINFO: 'HOME_UPDATE_PRODUCTERRORINFO',

    DEVICE_CATEGORY: 'DEVICE_CATEGORY',
    SHOW_MODEL: 'SHOW_MODEL'
};