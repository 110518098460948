import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { InputSelect2, InputBasicText } from '../common/Inputs';
import { RayCodeAction } from '../../actions/RayCodeAction';
import DatePicker from "react-datepicker";
import { ProductConstant } from '../../actions/constants/ProductConstant';
import { AuthAction } from '../../actions';
import { Translate as t } from '../../config/Translate';

const CustomHeader = ({ showModal, title, defaultFilter, UpdateUserAdminSetting,
	callbackClose, clist,
	extratrakey, exHeader, extratrakeyname, adminheaderprefix }) => {

	const [options, setOptions] = useState([]);
	const [seloptions, setSelOptions] = useState(exHeader ? exHeader.map(x => x.name) : []);
	const [selval, setSelVal] = useState("");
	const [prdOptionList, setPrdOptionList] = useState([]);
	const [selprdoption, setSelPrdOption] = useState("");
	const [selvaltf, setSelValTF] = useState(true);
	const [valtype, setValType] = useState("STRING");
	const [filter, setFilter] = useState(exHeader ? exHeader : []);
	const [codeList, setCodeList] = useState([]);
	const [dtOptionVal, setDtOptionVal] = useState({ opt: "last", lastVal: 7, lastValOpt: "d" });
	const dispatch = useDispatch();

	useEffect(() => {
		setCodes();
	}, [defaultFilter, clist]);

	useEffect(() => {
		setSelOptions(exHeader.map(x => x.name));
	}, [exHeader]);

	useEffect(() => {
		var oList = []
		codeList.map(x => {
			oList.push({ code: x.code, title: x.name });
		});
		setOptions(oList);
	}, [codeList]);

	const setCodes = async () => {
		var filterList = [...defaultFilter];
		var extradataList = clist?.filter(x => x.type === extratrakey);
		extradataList.filter(x => filterList.push({ code: extratrakeyname + "." + x.code, name: x.name, type: x.datatype }));
		setCodeList(filterList);

		var optList = [];
		var oList = clist?.filter(x => x.type === 'PRODUCTOPTION');
		oList.filter(x => optList.push({ code: x.name, title: x.name, type: 'OPTION' }));
		setPrdOptionList(optList);
	}

	const onchange = (e) => {
		setSelOptions(e.target.value);
	}

	const btnSave = () => {
		var filter = [];
		seloptions.map(x => {
			var t = codeList.filter(y => y.code == x);
			t.length > 0 && filter.push({ name: x, label: t.length > 0 ? t[0].name : x, type: t[0].type || "" });
		});
		var obj = {};
		obj["adminheaders." + adminheaderprefix] = filter;
		UpdateUserAdminSetting({ '$set': obj });
		callbackClose(false);
	}

	const getFilterOptionList = () => {
		return options.filter(x => !filter.map(m => m.key).includes(x.code));
	}

	return (
		<Modal show={showModal} size="lg">
			<Modal.Header>
				<h2 className="modal-title">{title}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<ul className="listview simple-listview no-space border-0 m-2">
					<div className="form-group basic">
						<div className="input-wrapper">
							<InputSelect2 options={{
								label: t('selectheader'),
								name: "seloptions",
								isMulti: true,
								list: getFilterOptionList(),
								val: seloptions,
								onlyitems: true,
							}} onchange={onchange} editable={true} />
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => btnSave()}>{t('save')}</button>
				<button className="btn btn-block btn-dark" onClick={() => callbackClose(false)}>{t('close')}</button>
			</Modal.Footer>
		</Modal>
	);
}

const mapState = (state) => {
	var user = state.AuthReducer.user;
	const clist = state.RayCodeReducer.items;
	return { user, clist };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminSetting: (uinfo) => dispatch(AuthAction.UpdateUserAdminSetting(uinfo)),
});

export default connect(mapState, mapDispatch)(CustomHeader);