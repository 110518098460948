import React, { useState, useEffect } from 'react';
import { ProductAction } from '../../actions';
import { AppConfig, JWT } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Translate as t } from '../../config/Translate';

export const S3DownloadButton = ({ text, css, path, filename, showtextfilename = false, tsg = false }) => {

	const [url, setUrl] = useState("");

	useEffect(() => {
		if (filename && path)
			getUrl();
	}, [])

	useEffect(() => {
		if (filename && path) {
			setUrl("");
			getUrl();
		}
	}, [filename, path])

	const getUrl = async () => {
		const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
			path: path,
			name: filename,
		}, JWT());

		if (ret.data.status == "success")
			setUrl(ret.data.data);
	}

	return (
		<>
			{!tsg &&
				<>
					{url && <a href={url} target="_blank"
						style={{ fontSize: "12px" }}
						className={css}>{showtextfilename ? filename : text}</a>}
					{!url && filename}
				</>
			}
			{tsg &&
				<>
					{url && <a href={url} target="_blank"
						className={css}>{t('troubleshootingguide')}
						{text > 0 &&
							<div className="chip chip-media ms-1">
								<i className="chip-icon bg-primary">
									<ion-icon name="thumbs-up-outline"></ion-icon>
								</i>
								<span className="chip-label">{text}</span>
							</div>
						}
					</a>}
					{!url && filename}
				</>
			}
		</>
	)
}

export const RayDelButton = ({ item, callback, startMsg = "delete", confirmMsg = "Are you sure?", css = "btn btn-danger" }) => {

	const [btnText, setBtnText] = useState(startMsg || "Delete");
	const [deleting, setDeleting] = useState(false);
	const [confirmed, setConfirmed] = useState(false);

	useEffect(() => {
		if (confirmed) {
			var timer = setInterval(() => {
				setConfirmed(false);
				clearInterval(timer)
			}, 3000);
		}
	}, [confirmed])

	const del = () => {
		if (confirmed) {
			setBtnText("Proceed...");
			setDeleting(true);
			callback(item);
		}
		if (!confirmed) {
			setBtnText(confirmMsg || "Are you sure?");
			setConfirmed(true);
		}
	}

	return <button
		disabled={deleting}
		className={css}
		onClick={() => del()}>{confirmed ? confirmMsg : t(startMsg)}</button>
}

export const EMSLogButton = ({ item, product, css, showtextfilename = false }) => {

	const dispatch = useDispatch();

	const btnClick = () => {
		dispatch(ProductAction.RequestErrorLogFile(product.sn, item._id));
	}
	return (
		<>
			{item.emsreferKey && !item.emsrequestlog &&
				<button className={css}
					style={{ fontSize: "12px" }}
					onClick={() => btnClick()} > Request Log </button>
			}
			{(item.emsrequestlog && !item.emsresponsetime) && <div>Requested</div>}
			{item.emslogpath &&
				<S3DownloadButton
					text="Download"
					showtextfilename={showtextfilename}
					style={{ fontSize: "12px" }}
					path={item.emslogpath}
					filename={item.emslogpath.replace(/^.*[\\\/]/, '')}
					css={css} />
			}
			{item.emsresponsefailreason && <div>{item.emsresponsefailreason}</div>}
		</>
	)
}