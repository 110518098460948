import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CountryCode } from '../../config';
import { SpecialityTypes } from '../../config/RayCodes';
import { InstallationAction, AlertAction, ProductAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { CheckValidateValue } from '../common/RayFormValidation';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const InstallationStep6 = ({ info, Update, isSummary = false }) => {
	// const [ t, i18n ] = useTranslation();
	const dispatch = useDispatch();
	const [errors, setErrors] = useState([]);

	const getData = (key) => {
		if (info.userTraining) {
			return info.userTraining[key] || false;
		}
		return false;
	}
	const [data, setDataSys] = useState({
		reg1Title: t('remote'),
		reg1IsChecked: getData("reg1IsChecked") || false,
		reg2Title: t('action'),
		reg2IsChecked: getData("reg2IsChecked") || false,
		reg3Title: t('cleaning'),
		reg3IsChecked: getData("reg3IsChecked") || false,
		reg4Title: t('positioning'),
		reg4IsChecked: getData("reg4IsChecked") || false,
		reg5Title: t('lasers'),
		reg5IsChecked: getData("reg5IsChecked") || false,
		reg6Title: t('correct'),
		reg6IsChecked: getData("reg6IsChecked") || false,
		reg7Title: t('patient'),
		reg7IsChecked: getData("reg7IsChecked") || false,
		reg8Title: t('import'),
		reg8IsChecked: getData("reg8IsChecked") || false,
		reg9Title: t('tools'),
		reg9IsChecked: getData("reg9IsChecked") || false,
	});

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		console.log({ name, value })
		setDataSys({ ...data, [name]: value });
	};

	const form_controls = [
		{
			title: t('system'), items: [
				{
					type: "checkbox", name: "reg1IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg1IsChecked || false,
					label: data.reg1Title,
				},
				{
					type: "checkbox", name: "reg2IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg2IsChecked || false,
					label: data.reg2Title,
				},
				{
					type: "checkbox", name: "reg3IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg3IsChecked || false,
					label: data.reg3Title,
				}
			]
		},
		{
			title: t('patientposition'), items: [
				{
					type: "checkbox", name: "reg4IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg4IsChecked || false,
					label: data.reg4Title,
				},
				{
					type: "checkbox", name: "reg5IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg5IsChecked || false,
					label: data.reg5Title,
				},
				{
					type: "checkbox", name: "reg6IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg6IsChecked || false,
					label: data.reg6Title,
				}
			]
		},
		{
			title: t('application'), items: [
				{
					type: "checkbox", name: "reg7IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg7IsChecked || false,
					label: data.reg7Title,
				},
				{
					type: "checkbox", name: "reg8IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg8IsChecked || false,
					label: data.reg8Title,
				},
				{
					type: "checkbox", name: "reg9IsChecked",
					isEditable: !isSummary, onlyCheck: true, val: data.reg9IsChecked || false,
					label: data.reg9Title,
				}
			]
		},
	];

	const Prev = (e) => {
		Update(info._id, { installationStep: 6 });
	};

	const validNext = () => {
		if (data.reg1IsChecked && data.reg2IsChecked && data.reg3IsChecked &&
			data.reg4IsChecked && data.reg5IsChecked && data.reg6IsChecked &&
			data.reg7IsChecked && data.reg8IsChecked && data.reg9IsChecked) {
			Update(info._id, { userTraining: data, installationStep: 8 });
		} else {
			dispatch(AlertAction.ErrorMsg(t('checklistcomment')));
		}
	};

	return (
		<>
			{!isSummary &&
				<div className="section">
					<div className="section-title">{t('usertrainingcheck')}</div>
					<div className="">{t('checklistcomment')}</div>
				</div>
			}
			<RayForm controls={form_controls}
				showAllFields={true}
				isSummary={isSummary}
				isForm={!isSummary}
				handleChangeData={handleChangeData} errors={errors} />
			{!isSummary && <div className="section mt-1 mb-2">
				<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
				<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('prev')}</button>
			</div>}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
});

export default connect(null, mapDispatch)(InstallationStep6);