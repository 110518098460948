import React from 'react';
import { Link } from 'react-router-dom';
import { LoginFooter } from '../../components/common/CommonViews';

const ForgotPasswordSent = () => {

    return (
        <>
            <div className="appHeader no-border transparent position-absolute">
                <div className="left">
                    <Link to="/Login" className="headerButton goBack">
                        <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
                    </Link>
                </div>
            </div>
            <div id="appCapsule" className="no-bottom">
                <div className="section mt-5 text-center">
                    <h1>Check your email</h1>
                    <p>Check your email and follow the link to reset your password.</p>
                </div>
                <div className="section mb-5 p-2">
                    <form>
                        <div className="mt-2 transparent mb-5 text-center">
                            <a className="btn btn-primary btn-block waves-effect waves-classic" href="/Login">Done</a>
                            <p>Didn't receive the email? <a href="/ForgotPassword">Resend</a></p>
                        </div>
                    </form>
                </div>
                <LoginFooter />
            </div>
        </>
    )
}

export default ForgotPasswordSent;