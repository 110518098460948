import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { history } from '../../history';
import { AuthAction } from '../../actions/AuthAction';
import { LanguageCode, DeviceModels, Sitemap } from '../../config';
import { RayForm, RayFormValidation } from '../../components/common';
import { ErrorCodeReducer } from '../../reducers/ErrorCodeReducer';
import { ErrorCodeAction } from '../../actions';
import ServiceList from '../../components/service/ServiceList';
import ProductErrorList from '../../components/product/ProductErrorList';
import ProductListNewInstalled from '../../components/product/ProductListNewInstalled';
import { AppHeader } from '../../components/common';
import { Translate as t } from '../../config/Translate';

const MyTask = ({ user }) => {
	const { view } = useParams();
	const [tab, setTab] = useState(view || "service");
	//const [ t, i18n ] = useTranslation();
	const [exist, setExist] = useState(true);

	useEffect(() => {
	}, []);

	const tabList = [
		{ code: "service", title: t("service"), icon: "support_agent" },
		{ code: "errorlist", title: t("errorlist"), icon: "error_outline" },
		{ code: "newinstalled", title: t("newinstalled"), icon: "fiber_new" },
	]

	return (
		<>
			<div className="appHeader bg-primary text-light">
				<AppHeader title="My Task" />
				<div className="right">
				</div>
			</div>
			<div id="appCapsule">
				<div className="section mb-2">
					<div className="card">
						<div className="card-body">
							<ul className="nav nav-tabs style1">
								{tabList.map(x =>
									<li className="nav-item">
										<button key={x.code}
											className={tab == x.code ? "nav-link active" : "nav-link"}
											onClick={() => { setTab(x.code); history.push("/MyTask/" + x.code); }}>
											{x.title}
										</button>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
				<div className="section mt-2 mb-2">
					{tab == "service" && <ServiceList />}
					{tab == "errorlist" && <ProductErrorList />}
					{tab == "newinstalled" && <ProductListNewInstalled />}
				</div>
			</div>
		</>
	)
}

const mapState = state => {
	const user = state.AuthReducer.user;
	const errorCode = ErrorCodeReducer.errorCode;
	return { user, errorCode };
}

const mapDispatch = dispatch => ({
	GetOne: (errorCode, lang) => dispatch(ErrorCodeAction.GetOne(errorCode, lang))
})

export default connect(mapState, mapDispatch)(MyTask);