import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthAction } from './actions/AuthAction';
import { history } from './history';

export const AuthRoute = ({ component: Component, roles, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!AuthAction.isLogged()) {
					if(props.match.path == "/Service/:ticketKey") {
						localStorage.setItem('mailLink', props.match.url);
					}

					return (
						<Redirect
							to={{
								pathname: props.location.pathname.toLowerCase().startsWith('/admin/') ? '/Admin/Login' : '/Login',
								state: { from: props.location },
							}}
						/>
					);
				}else{
					return <Component {...props} />;
				}
			}}
		/>
	);
};

export const AdminAuthRoute = ({ component: Component, roles, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!AuthAction.isLogged()) {
					return (
						<Redirect
							to={{
								pathname: props.location.pathname.toLowerCase().startsWith('/admin/') ? '/Admin/Login' : '/Login',
								state: { from: props.location },
							}}
						/>
					);
				}else{
					return <Component {...props} />;
				}
			}}
		/>
	);
};
