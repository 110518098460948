import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ServiceAction, AdminUserAction, RayCodeAction, CalendarAction, CompanyAction } from '../../actions';
import { RayStatuses } from '../../config/RayCodes';
import {
	InputBasicText,
	InputBasicSelect,
	InputBasicTextArea,
	InputBasicDateTime,
	InputCombo,
	InputSelect2,
} from "../../components/common/Inputs";
import { Translate as t } from '../../config/Translate';

const ServiceInfoView = ({ su, service, UpdateService, UpdateStatus, UpdateAssignee, editable, callBackUpdate, clist }) => {
	const [isAdmin, setIsAdmin] = useState(location.pathname.toLowerCase().startsWith('/admin/'));
	const userCompanyId = JSON.parse(localStorage.getItem("user")).companyId;
	const [comboPending, setComboPending] = useState([]);
	const [status, setStatus] = useState(service.status);
	const [assignee, setAssignee] = useState(service.assignee);
	const [data, setData] = useState({});
	const [prestatus, setPrestatus] = useState("");
	const [ownerShipList, setOwnerShipList] = useState([]);

	useEffect(() => {
		setData({
			ticketKey: service.ticketKey,
			summary: service.summary,
			effectiveDate: service.effectiveDate,
			pendingReason: service.pendingReason,
			visitDate: service.visitDate || new Date().getTime(),
			remoteDate: service.remoteDate || new Date().getTime(),
			description: service.description,
			remoteOwner: data.remoteOwner || userCompanyId,
			remoteStatus: service.remoteStatus
		});
		setPrestatus(service.status);
		setStatus(service.status);
		setAssignee(service.assignee);
	}, [service])

	useEffect(() => {
		// RayCodeAction.GetAllByFilter({ type: "SERVICEPENDING" }).then(x => {
		// 	var list = [];
		// 	x.map(m => list.push({ code: m.code, title: m.name }));
		// 	setComboPending(list);
		// });
		console.log('ServiceInfoView-SERVICEPENDING', clist?.filter(x => x.type === 'SERVICEPENDING'));
		const x = clist?.filter(x => x.type === 'SERVICEPENDING');
		var list = [];
		x.map(m => list.push({ code: m.code, title: m.name }));
		setComboPending(list);
		if (ownerShipList.length == 0)
			setOwner();
	}, [clist])

	const setOwner = async () => {
		// var list = await CompanyAction.GetParents();
		const list = await CompanyAction.GetParentsForRT();

		if (list.filter(x => x.code == "RAY00001").length == 0)
			list.push({ code: "RAY00001", title: "[HQ] RAY_HQ" });

		setOwnerShipList(list);
	}

	const handleChangeData = (e) => {
		var { name, value } = e.target;
		if (name == "status") {
			setStatus(value);
		} else if (name == "assignee") {
			setAssignee(value);
		} else {
			setData({ ...data, [name]: value });
		}
	}

	const getStatusExtra = (upData) => {
		if (status == "Remote") {
			upData["remoteOwner"] = upData.remoteOwner || data.remoteOwner;
			upData["remoteDate"] = upData.remoteDate || data.remoteDate;
			upData["remoteStatus"] = upData.remoteStatus || data.remoteStatus;
		} else if (status == "Visit") {
			upData["visitDate"] = upData.visitDate || data.visitDate;
		}
		return upData;
	}

	const updateData = async () => {

		var upData = {};
		Object.keys(data).forEach(function (key) {
			if (data[key] != service[key])
				upData[key] = data[key];
		})

		if (status != service.status) {
			if (status == "Remote" || status == "Visit") {
				upData = getStatusExtra(upData, status);
				const schData = await regSchedule(upData);
				upData["scheId"] = schData.data._id;
			}
			if (status == "Remote")
				upData["remoteStatus"] = "REMOTE_REQUEST";

			UpdateStatus(service._id, { status: status, extra: upData });
			delete upData.scheId;
		} else if (status == "Remote") {
			const extra = getStatusExtra(upData);
			if (Object.keys(upData).length > 0) {
				UpdateStatus(service._id, { status: status, extra: extra });
				delete upData.remoteDate;
				delete upData.remoteOwner;
			}
		} else if (status == "Visit") {
			const extra = getStatusExtra(upData);
			if (Object.keys(upData).length > 0) {
				UpdateStatus(service._id, { status: status, extra: extra });
				delete upData.visitDate;
			}
		}

		if (assignee != service.assignee)
			UpdateAssignee(service._id, { assignee: assignee });

		if (Object.keys(upData).length > 0) {
			var updateData = {};
			Object.keys(upData).forEach(function (key) {
				if (key != "remoteOwner" && key != "scheId" && key != "remoteDate" && key != "visitDate" && key != "remoteStatus")
					updateData[key] = upData[key];
			})
			if (Object.keys(updateData).length > 0)
				UpdateService(service._id, updateData);
		}

		callBackUpdate && callBackUpdate();
	}

	const regSchedule = async (upData, type) => {
		return await CalendarAction.CreateUpdateByService({
			scheId: service.scheId || "",
			serviceId: service._id,
			color: type == "Remote" ? "bg-purple" : "bg-cyan",
			finish: type == "Remote" ?
				((upData.remoteDate || service.remoteDate) + 1000 * 60 * 60) :
				((upData.visitDate || service.visitDate) + 1000 * 60 * 60),
			model: service.model,
			option: service.option,
			siteId: service.sid,
			sitename: service.sitename,
			sn: service.sn,
			start: type == "Remote" ?
				(upData.remoteDate || service.remoteDate) :
				(upData.visitDate || service.visitDate),
			type: type
		});
	}

	const RemoteStatus = [
		{ code: "REMOTE_REQUEST", title: "Requested" },
		{ code: "REMOTE_ACCEPT", title: "Accepted" },
		{ code: "REMOTE_FINISH", title: "Finished" }
	]

	return (
		<div className="rayform section">
			<div className="card">
				<div className="card-body">

					{!editable && <InputBasicText
						options={{
							type: 'input',
							name: 'ticketKey',
							label: t('ticketnumber'),
							size: 12,
							val: data.ticketKey,
							required: true
						}}
						editable={false} />}

					{editable && <InputBasicText
						options={{
							type: 'input',
							name: 'summary',
							label: t('subject'),
							size: 12,
							val: data.summary,
							required: true
						}}
						onchange={handleChangeData}
						editable={editable} />}

					<InputBasicSelect
						options={{
							type: "select",
							name: "status",
							label: t('status'),
							val: status,
							list: editable ? (su ? RayStatuses : RayStatuses.filter(x => x.code != "Escalate")) : RayStatuses,
							action: UpdateStatus
						}}
						onchange={handleChangeData}
						editable={editable} />

					{status == "Monitoring" && <InputBasicSelect
						options={{
							type: "select",
							name: "pendingReason",
							label: 'Monitoring Reason',
							val: data.pendingReason,
							list: comboPending
						}}
						onchange={handleChangeData}
						editable={editable} />}

					{status == "Visit" && <InputBasicDateTime
						options={{
							type: "datetime",
							name: "visitDate",
							label: "Visit Date",
							val: data.visitDate,
						}}
						editable={editable}
						onchange={handleChangeData} />}

					{status == "Remote" && <InputBasicDateTime
						options={{
							type: "datetime",
							name: "remoteDate",
							label: "Remote Date",
							val: data.remoteDate,
						}}
						editable={editable}
						onchange={handleChangeData} />}

					{status == "Remote" &&
						<>
							{(isAdmin && editable) &&
								<>
									<InputSelect2
										options={{
											type: "select",
											name: "remoteStatus",
											label: "Remote Status",
											val: data.remoteStatus,
											list: RemoteStatus
										}}
										onchange={handleChangeData}
										editable={editable} />
								</>}
							{!editable && <InputBasicSelect
								options={{
									type: "select",
									name: 'remoteStatus',
									label: 'Remote Status',
									val: data.remoteStatus,
									list: RemoteStatus,
								}}
								editable={false} />}
						</>}

					{status == "Remote" && <InputSelect2
						options={{
							type: "select2",
							name: "remoteOwner",
							label: t('owner'),
							placeholder: t('owner'),
							list: ownerShipList,
							val: data.remoteOwner || userCompanyId
						}}
						editable={editable}
						onchange={handleChangeData} />}

					{(data.effectiveDate > 0 || editable) && <InputBasicDateTime
						options={{
							type: "datetime",
							name: "effectiveDate",
							label: t('effectivedate'),
							val: data.effectiveDate
						}}
						editable={editable}
						onchange={handleChangeData} />}

					<InputCombo
						options={{
							type: "combo",
							name: "assignee",
							label: t('assigned'),
							val: assignee,
							startval: t('noassigned'),
							action: UpdateAssignee,
							viewLinkPath: "/UserInfo/",
							getCombo: AdminUserAction.GetComboListByCompanyId
						}}
						onchange={handleChangeData}
						editable={editable} />

					<InputBasicTextArea
						options={{
							type: 'textarea',
							name: 'description',
							label: t('description'),
							val: data.description,
						}}
						editable={editable}
						onchange={handleChangeData} />
					{editable && <button className="btn btn-block btn-primary" onClick={() => updateData()}>{t('update')}</button>}
				</div>
			</div>
		</div>
	)
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const su = state.AuthReducer.su;
	const clist = state.RayCodeReducer.items;
	return { service, su, clist };
};

const mapDispatch = (dispatch) => ({
	UpdateStatus: (_id, data) => dispatch(ServiceAction.UpdateStatus(_id, data)),
	UpdateAssignee: (_id, data) => dispatch(ServiceAction.UpdateAssignee(_id, data)),
	UpdateServiceInfo: (_id, data) => dispatch(ServiceAction.UpdateService(_id, data)),
	UpdateService: (_id, data) => dispatch(ServiceAction.UpdateService(_id, data))
});

export default connect(mapState, mapDispatch)(ServiceInfoView);
