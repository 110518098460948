import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InputBasicSelectInput, InputBasicText } from '../../components/common/Inputs';
import { CountryCode } from '../../config';
import { SiteSurveyAction, RayCodeAction, ProductAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const SurveyPreviousUnits = ({ name, info, handleChangeDataList, isSummary = false, clist }) => {
	// const [ t, i18n ] = useTranslation();
	const [list, setList] = useState(info || []);
	const [item, setItem] = useState({ brand: "None", product: "" });

	useEffect(() => {
		handleChangeDataList(name, list);
	}, [list]);

	const comboList = [
		{ code: "None", title: "None" },
		{ code: "Carestream", title: "Carestream" },
		{ code: "Gendex", title: "Gendex" },
		{ code: "Planmeca", title: "Planmeca" },
		{ code: "Sirona", title: "Sirona" },
		{ code: "Vatech", title: "Vatech" },
		{ code: "", title: "Other" },
	]
	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	}
	const Add = () => {
		setList([...list, item]);
		setItem({ brand: "None", product: "" });
	}
	const Del = (idx) => {
		list.splice(idx, 1);
		setList([...list]);
	}
	return (
		<>
			<div className={isSummary ? "" : "section"}>
				{!isSummary && <div className="section-title">{name == 'previousUnitsExtraOral' ? t('extraoral') : t('intraoral')}</div>}
				{isSummary && <div className="section-title bdB2">{name == 'previousUnitsExtraOral' ? t('extraoral') : t('intraoral')}</div>}
				<div className={isSummary ? "" : "card"}>
					<div className={isSummary ? "" : "card-body"}>
						<ul className="listview simple-listview no-space pb-1">
							{list.map((x, idx) =>
								<li key={idx} className="pt-0">
									{x.brand} / {x.product}
									{!isSummary && <button className="btn btn-sm btn-danger" onClick={() => Del(idx)}>Del</button>}
								</li>
							)}
						</ul>
						<hr className="m-0" />
						{!isSummary && <>
							<InputBasicSelectInput
								options={{ list: comboList, val: item.brand, name: "brand", label: t('brand') }}
								isForm={!isSummary}
								inline={false}
								editable={!isSummary}
								onchange={handleChangeData} />

							<InputBasicText
								options={{ val: item.product, name: "product", label: t('product') }}
								isForm={!isSummary}
								editable={!isSummary}
								onchange={handleChangeData} />
						</>}
						{!isSummary && <button className="btn btn-block btn-primary" onClick={() => Add()}>{t('add')}</button>}
					</div>
				</div>
			</div>
		</>
	);
}

const SurveyStep2 = ({ Update, info, isSummary = false, gostep }) => {
	// const [ t, i18n ] = useTranslation();
	const [errors, setErrors] = useState([]);
	const [isAddProcess, setIsAddProcess] = useState(false);
	const [siteSurvey, setSiteSurvey] = useState({ ...info, siteSurveyStep: 4 });
	const [category, setCategory] = useState("Delivery");
	const [allModelOptionList, setAllModelOptionList] = useState([]);
	const [models, setModels] = useState([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (gostep) {
			if (gostep.curStep == 3) {
				var errValidate = [];
				var errValidate1 = RayFormValidation(form_controls1, siteSurvey);
				var errValidate2 = RayFormValidation(form_controls2, siteSurvey);
				errValidate = errValidate.concat(errValidate1);
				errValidate = errValidate.concat(errValidate2);
				if (errValidate.length > 0) {
					setErrors(errValidate);
				} else {
					setIsAddProcess(true);
					Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: gostep.goStepNo });
				}
			}
		}
	}, [gostep])

	useEffect(() => {
		var list = [];
		allModelOptionList.map(x => {
			if (list.filter(m => m.code == x.group).length == 0)
				list.push({ code: x.group, title: x.group });
		});
		setModels(list)
		setOptionList(siteSurvey.model || "RAYSCAN α+ (NEW)");
	}, [allModelOptionList])

	const setOptionList = (model) => {
		var list = [];
		allModelOptionList.filter(x => x.group == model).map(x => {
			if (list.filter(m => m.code == x.code).length == 0)
				list.push({ code: x.code, title: x.code });
		});
		setOptions(list);
	}

	useEffect(() => {
		setOptionList(siteSurvey.model);
	}, [siteSurvey.model])

	useEffect(() => {
		console.log('SurveyStep2 ---> ', clist?.filter(x => x.type === 'PRODUCTOPTION'));
		setAllModelOptionList(clist?.filter(x => x.type === 'PRODUCTOPTION'));
		// RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" }).then((e) => {
		// 	setAllModelOptionList(e);
		// });
	}, [clist])

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setSiteSurvey({ ...siteSurvey, [name]: value });
	};

	const Prev = (e) => {
		Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 2 });
	};

	const form_controls1 = [
		{
			title: t('delivery'), items: [
				{ type: "datetime", name: "deliveryDate", label: t('deliverydate'), val: siteSurvey.deliveryDate },
				{
					type: "selectinput", name: "accessibility", label: t('accessibility'), size: 4, val: siteSurvey.accessibility,
					list: [
						{ code: "Main entrance", title: t('mainentrance') },
						{ code: "Secondary enterance", title: t('secondaryentrance') },
						{ code: "Ground floor", title: t('groundfloor') },
						{ code: "Floor", title: t('floor') },
						{ code: "", title: t('other') },
					]
				},
				{
					type: "select", name: "liftYN", label: t('lift'), size: 4, val: siteSurvey.liftYN || t('no'),
					list: [
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					]
				}
			]
		},

		{
			title: t('datamigration'), items: [
				{
					type: "select", name: "datamigrationRequired", label: t('required'), size: 4, val: siteSurvey.datamigrationRequired,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					]
				},
				{ type: "input", name: "datamigrationModality", label: t('modality'), size: 4, val: siteSurvey.datamigrationModality },
				{
					type: "select", name: "datamigrationExpense", label: t('expense'), size: 4, val: siteSurvey.datamigrationExpense,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					]
				}
			]
		}
	]
	const form_controls2 = [
		{
			title: t('pms'), items: [
				{ type: "input", name: "integrationPmsBrand", label: t('brand'), val: siteSurvey.integrationPmsBrand },
				{ type: "input", name: "integrationPmsProduct", label: t('product'), val: siteSurvey.integrationPmsProduct },
			]
		},
		{
			title: t('psp'), items: [
				{ type: "input", name: "integrationPspBrand", label: t('brand'), val: siteSurvey.integrationPspBrand },
				{ type: "input", name: "integrationPspProduct", label: t('product'), val: siteSurvey.integrationPspProduct },
				{
					type: "select", name: "integrationPspTwain", label: t('twain'), val: siteSurvey.integrationPspTwain,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					]
				},
			]
		},
		{
			title: t('intrasensor'), items: [
				{ type: "input", name: "integrationSensorBrand", label: t('brand'), val: siteSurvey.integrationSensorBrand },
				{ type: "input", name: "integrationSensorProduct", label: t('product'), val: siteSurvey.integrationSensorProduct },
				{
					type: "select", name: "integrationSensorTwain", label: t('twain'), val: siteSurvey.integrationSensorTwain,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					]
				},
			]
		},
		{
			title: t('intracamera'), items: [
				{ type: "input", name: "integrationCameraBrand", label: t('brand'), val: siteSurvey.integrationCameraBrand },
				{ type: "input", name: "integrationCameraProduct", label: t('product'), val: siteSurvey.integrationCameraProduct },
				{
					type: "select", name: "integrationCameraTwain", label: t('twain'), val: siteSurvey.integrationCameraTwain,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					]
				},
			]
		},
	]

	const handleChangeDataList = (name, value) => {
		console.log(name);
		console.log(value);
		setSiteSurvey({ ...siteSurvey, [name]: value });
	}

	const validNext = () => {
		var errValidate = [];
		var errValidate1 = RayFormValidation(form_controls1, siteSurvey);
		var errValidate2 = RayFormValidation(form_controls2, siteSurvey);
		errValidate = errValidate.concat(errValidate1);
		errValidate = errValidate.concat(errValidate2);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsAddProcess(true);
			Update(siteSurvey._id, siteSurvey);
		}
	};

	return (
		<>
			<div className={isSummary ? "" : "section mt-2"}>
				<ul className={isSummary ? "nav nav-tabs style3 mb-1" : "nav nav-tabs style2"}>
					<li className="nav-item">
						<a href
							className={category == "Delivery" ? "nav-link active" : "nav-link"}
							onClick={() => setCategory("Delivery")}>{t('deliveryinfo')}</a>
					</li>
					<li className="nav-item">
						<a href
							className={category == "PreviousUnits" ? "nav-link active" : "nav-link"}
							onClick={() => setCategory("PreviousUnits")}>{t('previousunit')}</a>
					</li>
					<li className="nav-item">
						<a href
							className={category == "Integration" ? "nav-link active" : "nav-link"}
							onClick={() => setCategory("Integration")}>{t('intergration')}</a>
					</li>
				</ul>
			</div>
			{category == "Delivery" &&
				<RayForm controls={form_controls1} isForm={true} isSummary={isSummary} showAllFields={true} handleChangeData={handleChangeData} errors={errors} />
			}
			{category == "PreviousUnits" &&
				<>
					<SurveyPreviousUnits info={siteSurvey.previousUnitsExtraOral}
						name="previousUnitsExtraOral"
						isSummary={isSummary}
						handleChangeDataList={handleChangeDataList} errors={errors} />
					<SurveyPreviousUnits info={siteSurvey.previousUnitsIntraOral}
						name="previousUnitsIntraOral"
						isSummary={isSummary}
						handleChangeDataList={handleChangeDataList} errors={errors} />
				</>
			}
			{category == "Integration" &&
				<RayForm controls={form_controls2} isForm={true} isSummary={isSummary} showAllFields={true} handleChangeData={handleChangeData} errors={errors} />
			}

			{!isSummary &&
				<div className="section mt-1 mb-2">
					<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
					<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
				</div>
			}
		</>
	);
};

const mapState = (state) => {
	const clist = state.RayCodeReducer.items;
	return { clist };
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(SiteSurveyAction.UpdateSiteSurvey(_id, data)),
});

export default connect(mapState, mapDispatch)(SurveyStep2);