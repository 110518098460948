import axios from 'axios';
import { AppConfig, JWT } from '../config';

const GetList = async (dataQuery) => {
	const data = {
		data: dataQuery
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/common/', data, JWT());
	return ret.data;
};

const GetRecord = async (data) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
	return ret.data;
};

const GetCountAction = async (dataQuery) => {
	const data = {
		data: dataQuery
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/common/', data, JWT());
	return ret.data;
};

const AcqCountLastHours = async (dataQuery) => {
	const data = {
		data: dataQuery
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/common/acqCountLastHours', data, JWT());
	return ret.data;
};

const DailyModality = async (dataQuery) => {
	const data = {
		data: dataQuery
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/common/dailyModality', data, JWT());
	return ret.data;
};


const ServerTime = async () => {
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/common/serverTime', JWT());
	return ret.data;
}

export const CommonAction = {
	GetList,
	GetCountAction,
	AcqCountLastHours,
	DailyModality,
	GetRecord,
	ServerTime
};
