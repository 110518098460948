import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { SiteAction, ProductAction } from '../../actions';

const AdminSiteInfo_Product = ({ site, UpdateProductSite, isUpdateRes }) => {

    const { id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [showAlert, setShowAlert] = useState(false);
	const [delProduct, setDelProduct] = useState({});
	const [installedProducts, setInstalledProducts] = useState([]);

	// useEffect(() => {
	// 	if (isUpdateRes) {
	// 		ClearUpdateResult();
	// 		getProductsInfo();
	// 	}
	// }, [isUpdateRes]);

	useEffect(() => {
		getProductsInfo();	
	}, []);

	const getProductsInfo = () => {
		setIsLoading(true)
		if (site._id) {
			SiteAction.GetInstalledProduct(site._id).then((products) => {
				console.log(products);
				setInstalledProducts(products);
				setIsLoading(false)
			});
		}
	}

	const removeProduct = (pid) => {
		setDelProduct(installedProducts.filter(x => x._id == pid)[0]);
		setShowAlert(true);
	}

	const remove = (pid) => {
		UpdateProductSite(pid, site.siteId, "");
	}

	return (
		<div className="section">
			{isLoading && <span>Loading...</span>}
			{!isLoading &&
				<>
					{installedProducts.length > 0 &&
						<>
							<h4>설치된 장비 목록</h4>
							<ul className="list-group">
								{installedProducts.map(x => {
									return (
										<li className="list-group-item">
											<Link to={"/Admin/AdminProductInfo/" + x.sn}>
												{x.sn}({x.model})
											</Link>
											<button className="float-end btn btn-sm btn-danger m-0" onClick={() => removeProduct(x._id)}>
												<ion-icon name="close-outline"></ion-icon> Uninstall
											</button>
										</li>
									)
								})}
							</ul>
							{showAlert &&
								<div className="card bg-danger mt-1">
									<div className="card-body text-white">
										<h4 className="text-white">{delProduct.sn}({delProduct.model}) 장비 설치 제거는 즉시 반영됩니다!!!</h4>
										<p className="text-white">이 작업은 되돌릴 수 없는 작업입니다.</p>
										<p className="text-white">정말 제거하시겠습니까?</p>
										<button className="btn btn-sm btn-info m-r-5" onClick={() => remove(delProduct._id)}>설치 제거</button>
										<button className="btn btn-sm text-white" onClick={() => setShowAlert("")}>취소</button>
									</div>
								</div>
							}
						</>
					}
					{installedProducts.length == 0 &&
						<h4>설치된 장비가 없습니다.</h4>
					}
				</>
			}
		</div>
	);
};

const mapState = (state) => {
	const isUpdateRes = state.ProductReducer.isUpdateRes;
	return { isUpdateRes };
}
const mapDispatch = dispatch => ({
	//ClearUpdateResult: () => dispatch(ProductAction.ClearUpdateResult()),
	UpdateProductSite: (_id, nowSiteId, newSiteId) => dispatch(ProductAction.UpdateProductSite(_id, nowSiteId, newSiteId))
})

export default connect(mapState, mapDispatch)(AdminSiteInfo_Product);