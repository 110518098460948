import { InputSelect2Search } from "../../components/common/Inputs";

const AdminSparePartsInRegist = () => {
    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">입고등록</h2>
                 <div className="ray-right">
                    <button className="btn btn-sm btn-primary">목록</button>
                 </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section row mt-1 mb-1">
                    <div className="col-4">
                        <div className="section-title">Spare Parts 정보</div>
                        <div className="card">
                            <div className="p-2">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Section</label>
                                        <div className="form-content">DETECTOR</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Type</label>
                                        <div className="form-content">DETECTOR</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Service Code</label>
                                        <div className="form-content">RA-D-DE-0003</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Service Name</label>
                                        <div className="form-content">PANO DETECTOR (A-LVDS Type)</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">BOM Code</label>
                                        <div className="form-content">RA-07S-5035</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">BOM Name</label>
                                        <div className="form-content">PANO DETECTOR-C10500D-02</div>
                                    </div>
                                </div>                               
                            </div>                            
                        </div>                       
                    </div>                    
                    <div className="col-4">
                        <div className="section-title">입고 목록</div>
                        <div className="card">
                            <div className="p-2">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Section</label>
                                        <div className="form-content">DETECTOR</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Type</label>
                                        <div className="form-content">DETECTOR</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Service Code</label>
                                        <div className="form-content">RA-D-DE-0003</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Service Name</label>
                                        <div className="form-content">PANO DETECTOR (A-LVDS Type)</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">BOM Code</label>
                                        <div className="form-content">RA-07S-5035</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">BOM Name</label>
                                        <div className="form-content">PANO DETECTOR-C10500D-02</div>
                                    </div>
                                </div>                               
                            </div>                            
                        </div>                       
                    </div>   
                </div>
            </div>
        </>
    )
}


export default AdminSparePartsInRegist;