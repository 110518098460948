import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppHeader } from '../../components/common/LeftMenu';
import { CalendarConstant } from '../../actions/constants/CalendarConstant';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import ScheduleCreate from '../../components/schedule/ScheduleCreate';
import { CalendarAction } from '../../actions';
// import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const CalAlert = ({ dismiss }) => {
	return (
		<div className="alert alert-imaged alert-light alert-dismissible fade show mb-2" role="alert">
			<div>
				Today you have <strong className="text-primary">5</strong> tasks
			</div>
			<button onClick={() => dismiss()} className="btn-close text-secondary"></button>
		</div>
	)
}

const CalDayNav = ({ showDate, showDays, setDate, list }) => {
	const getCSS = (x) => {
		if (showDate.format("YYYYMMDD") == x.format("YYYYMMDD"))
			return "nav-link active";
		if (moment().format("YYYYMMDD") == x.format("YYYYMMDD"))
			return "nav-link today";
		return "nav-link";
	}

	const hasSched = (day) => {
		return list.filter(x => moment(x.start).format("YYYYMMDD") == day.format("YYYYMMDD")).length > 0 ?
			<span className="badge badge-danger badge-empty" /> : "";
	}
	return (
		<ul className="nav nav-tabs style1 date-list mb-2" role="tablist">
			{showDays.map(x => <li className="nav-item">
				<a className={getCSS(x)}
					data-bs-toggle="tab" onClick={() => setDate(x)} role="tab">
					{x.format("ddd DD")}
				</a>
				{hasSched(x)}
			</li>
			)}
		</ul>
	)
}

const CalHeaderNav = ({ showDays, prevWeek, nextWeek, showDate, toDay }) => {
	return (
		<>
			{showDays.length > 0 &&
				<div className="section-title fl">
					<a onClick={() => prevWeek()} className="text-secondary btn-sm"><ion-icon name="caret-back-outline" role="img" className="md hydrated" aria-label="caret back outline"></ion-icon></a>
					{showDays[0].format("YYYYMM") != showDays[showDays.length - 1].format("YYYYMM") ?
						showDays[0].format("YYYY.MM") + " ~ " + showDays[showDays.length - 1].format("MM") :
						showDays[0].format("YYYY.MM")
					}
					<a onClick={() => nextWeek()} className="text-secondary btn-sm"><ion-icon name="caret-forward-outline" role="img" className="md hydrated" aria-label="caret forward outline"></ion-icon></a>
					{showDate.format("YYYYMMDD") != moment().format("YYYYMMDD") && <a onClick={() => toDay()} className="btn-text-secondary btn-sm">Today</a>}
				</div>
			}
		</>
	)
}

const CalSchHome = ({ items, schCallback }) => {

	const [ymdList, setYmndList] = useState([]);
	const [list, setList] = useState([]);

	useEffect(() => {
		setList([...items]);
	}, [items])

	useEffect(() => {
		var items = [];
		list.map(item => {
			const ymd = moment(item.start).format("YYYY.MM.DD");
			if (!items.includes(ymd)) {
				items.push(ymd);
			}
		})
		setYmndList(items);
	}, [list])

	const getColor = (item) => {
		return "dot " + item.color;
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
			})
		} else {
			return "N/A";
		}
		return html;
	}

	const getSchedList = (ymd) => {
		const arr = list.filter(y => moment(y.start).format("YYYY.MM.DD") == ymd);
		return arr.sort((a, b) => a.start - b.start);
	}

	return (
		<>
			{ymdList.map(day =>
				<div className="card mb-1">
					<div className="card-header">{day}{day == moment().format("YYYY.MM.DD") && <span className="float-end badge badge-warning">Today</span>}</div>
					<div className="timeline timed ms-1 me-2">
						{getSchedList(day).map(x =>
							<div className="item" onClick={() => schCallback(x)}>
								<span className="time">{moment(x.start).format("HH:mm A")}</span>
								<div className={getColor(x)}></div>
								<div className="content">
									<h4 className="title">
										<div className="chip chip-dark">
											<span className="chip-label">{x.type}</span>
										</div> {x.title}</h4>
									{x.sitename && <div className="text"><strong>{x.sitename}{x.customer && " " + x.customer}</strong></div>}
									{x.sn && <div className="text">{x.sn}{x.model && " / " + x.model}{x.option && " / " + x.option}</div>}
									{x.note && <div className="text">{nl2br(x.note)}</div>}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}

const CalSch = ({ list, schCallback, standAlone = false, items = [] }) => {

	const [ymdList, setYmndList] = useState([]);

	useEffect(() => {
		var items = [];
		if (standAlone) {
			list.map(item => {
				const ymd = moment(item.start).format("YYYY.MM.DD");
				if (!items.includes(ymd)) {
					items.push(ymd);
				}
			})
			setYmndList(items);
		}
	}, [standAlone])

	useEffect(() => {
		var items = [];
		if (standAlone) {
			list.map(item => {
				const ymd = moment(item.start).format("YYYY.MM.DD");
				if (!items.includes(ymd)) {
					items.push(ymd);
				}
			})
			setYmndList(items);
		}
	}, [standAlone])

	const getColor = (item) => {
		return "dot " + item.color;
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
			})
		} else {
			return "N/A";
		}
		return html;
	}

	const sortList = () => list.sort((a, b) => a.start - b.start);

	const getSchedList = (ymd) => {
		const arr = list.filter(y => moment(y.start).format("YYYY.MM.DD") == ymd);
		console.log(arr);
		return arr.sort((a, b) => a.start - b.start);
	}

	return (
		<>
			{standAlone &&
				<>
					{ymdList.map(day =>
						<div className="card mb-1">
							<div className="card-header">{day}{day == moment().format("YYYY.MM.DD") && <span className="float-end badge badge-warning">Today</span>}</div>
							<div className="timeline timed ms-1 me-2">
								{getSchedList(day).map(x =>
									<div className="item" onClick={() => schCallback(x)}>
										<span className="time">{moment(x.start).format("HH:mm A")}</span>
										<div className={getColor(x)}></div>
										<div className="content">
											<h4 className="title">
												<div className="chip chip-dark">
													<span className="chip-label">{x.type}</span>
												</div> {x.title}</h4>
											{x.sitename && <div className="text"><strong>{x.sitename}{x.customer && " " + x.customer}</strong></div>}
											{x.sn && <div className="text">{x.sn}{x.model && " / " + x.model}{x.option && " / " + x.option}</div>}
											{x.note && <div className="text">{nl2br(x.note)}</div>}
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</>
			}
			{!standAlone &&
				<div className="timeline timed ms-1 me-2">
					{sortList().map(x =>
						<div className="item" onClick={() => schCallback(x)}>
							<span className="time">{moment(x.start).format("HH:mm A")}</span>
							<div className={getColor(x)}></div>
							<div className="content">
								<h4 className="title">
									<div className="chip chip-dark">
										<span className="chip-label">{x.type}</span>
									</div> {x.title}</h4>
								{x.sitename && <div className="text"><strong>{x.sitename}{x.customer && " " + x.customer}</strong></div>}
								{x.sn && <div className="text">{x.sn}{x.model && " / " + x.model}{x.option && " / " + x.option}</div>}
								{x.note && <div className="text">{nl2br(x.note)}</div>}
							</div>
						</div>
					)}
				</div>
			}
		</>
	)
}

const MyCalendar = ({ items, isLoading, GetList }) => {
	// const [ t ] = useTranslation();
	const [showAS, setShowAS] = useState(false);
	const [showAlert, setShowAlert] = useState(true);
	const [showDays, setShowDays] = useState([]);
	const [showDate, setShowDate] = useState(null);
	const [schDay, setSchDay] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setShowDate(moment());
		setWeek(moment());
	}, [])

	useEffect(() => {
		if (showDate) {
			setSchDay(items.filter(x => moment(x.start).format("YYYYMMDD") == showDate.format("YYYYMMDD")));
		}
	}, [showDate, items])

	useEffect(() => {
		if (showDays.length == 0)
			return;
		getSche();
	}, [showDays])

	const setWeek = (date) => {
		var start = date.clone().startOf('week');
		var days = []
		for (var i = 0; i < 7; i++) {
			days.push(start.clone().add(i, 'days'));
		}
		setShowDays(days);
	}

	const getSche = () => {
		GetList({
			start: { '$gte': showDays[0].clone().valueOf() },
			start: { '$lte': showDays[showDays.length - 1].clone().valueOf() },
		});
	}

	const ASNew = () => {
		dispatch({
			type: CalendarConstant.SET_NEW, item: {
				type: "Pre-install",
				color: "bg-warning",
				start: showDate.clone().valueOf(),
				finish: showDate.clone().add(3, "hours").valueOf()
			}
		});
		setShowAS(true);
	}

	const schCallback = (item) => {
		dispatch({ type: CalendarConstant.SET_NEW, item: item });
		setShowAS(true);
	}

	const nextWeek = () => {
		setWeek(showDays[showDays.length - 1].clone().add(1, 'days'));
		setShowDate(showDays[showDays.length - 1].clone().add(1, 'days'));
	}

	const prevWeek = () => {
		setWeek(showDays[0].clone().add(-1, 'days'));
		setShowDate(showDays[0].clone().add(-1, 'days'));
	}

	const toDay = () => {
		setWeek(moment());
		setShowDate(moment());
	}

	return (
		<>
			<div className="appHeader full-color">
				<AppHeader title={t('calschedule')} />
				<div className="right">
					<a onClick={() => ASNew()} className="headerButton">
						<ion-icon name="add-circle-outline"></ion-icon>
					</a>
				</div>
			</div>
			<div id="appCapsule" className="full-height">
				<div className="section mb-2 sch-result">
					{/*
					{showAlert && <CalAlert dismiss={() => setShowAlert(false)} />}
					*/}
					<div className="cal-header week-header">
						<CalHeaderNav showDays={showDays}
							toDay={() => toDay()}
							prevWeek={() => prevWeek()} nextWeek={() => nextWeek()} showDate={showDate}
						/>
						<CalDayNav showDate={showDate} showDays={showDays} setDate={(x) => setShowDate(x)} list={items} />
					</div>
					<div className="card mb-2">
						<div className="card-body pt-0 pb-0">
							<div className="tab-content">
								{schDay.length > 0 && <CalSch list={schDay} schCallback={(item) => schCallback(item)} />}
								{schDay.length == 0 && <p className="text-center mt-2 mb-2">{t('calnoschedule')}</p>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<ScheduleCreate showAS={showAS} callback={() => setShowAS(false)} />
		</>
	)
}

const mapState = (state) => {
	const items = state.CalendarReducer.items;
	const isLoading = state.CalendarReducer.isLoading;
	return { items, isLoading };
};

const mapDispatch = (dispatch) => ({
	GetList: (filter) => dispatch(CalendarAction.GetAllByFilter(filter)),
});

export default connect(mapState, mapDispatch)(MyCalendar);
export {
	CalSchHome
}