import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ServiceAction } from '../../actions';
import ServiceInfoView from '../../components/service/ServiceInfoView';
import ServiceComments from '../../components/service/ServiceInfoComments';
import ServiceInfoAttachments from '../../components/service/ServiceInfoAttachments';
import ServiceClaimCategory from '../../components/service/ServiceClaimCategory';
import ServiceInfoLogs from '../../components/service/ServiceInfoLogs';
import ServiceReplacement from '../../components/service/ServiceReplacement';
import SiteInfo from '../../components/site/SiteInfo';
import ProductErrorInfo from '../../components/product/ProductErrorInfo';
import ServiceProductErrorRegister from '../../components/service/ServiceProductErrorRegister';
import { AppHeader } from '../../components/common';
import { ServiceStatusIcon } from '../../components/service/ServiceCard';
import { NoRecord } from '../HomePage';
import { Translate as t } from '../../config/Translate';

const ServiceDetail = ({ GetServiceByTicketKey, service }) => {
	const { ticketKey, view } = useParams();
	const [tab, setTab] = useState(view || "comment");
	const [maintab, setMainTab] = useState("info");
	const [isLoading, setIsLoading] = useState(true);
	const [editable, setEditable] = useState(false);

	useEffect(() => {
		setIsLoading(false);

	}, [service])

	useEffect(() => {
		GetServiceByTicketKey(ticketKey);
	}, [])

	const getLengthStr = (objArr) => {
		if (objArr) {
			return objArr.length > 0 ? "(" + objArr.length + ")" : "";
		}
		return "";
	}

	const mainTabList = [
		{ code: "info", title: t('overview'), icon: "info" },
		{ code: "status", title: t('detail'), icon: "visibility" },
		{ code: "error", title: t('error'), icon: "error_outline" },
		{ code: "site", title: t('site'), icon: "location_city" }
	]

	const tabList = [
		{ code: "comment", title: t('comments') + getLengthStr(service.comments) },
		{ code: "attachments", title: t('attachments') + getLengthStr(service.attachments) },
		{ code: "logs", title: "Logs" + getLengthStr(service.histories) }
	]

	return (
		<>
			<div className="appHeader no-border">
				<AppHeader
					leadIcon={ServiceStatusIcon}
					item={service}
					title={service.summary}
					subtitle={service.sn + ((service.errorCode != undefined) ? " / " + service.errorCode : "")} />
				<div className="right">
					{maintab == "info" && <a href onClick={() => setEditable(!editable)} className="headerButton"><ion-icon name="create-outline"></ion-icon></a>}
				</div>
			</div>
			<div id="appCapsule">
				<div className="section mb-2">
					<ul className="nav nav-tabs style2 mt-1 mb-1">
						{mainTabList.map((x,idx) =>
							<li key={`srv_${idx}`} className="nav-item">
								<button key={x.code}
									className={maintab == x.code ? "nav-link active" : "nav-link"} onClick={() => { setMainTab(x.code); }}>
									{x.title}
								</button>
							</li>
						)}
					</ul>
				</div>
				{maintab == "info" && <ServiceInfoView service={service} editable={editable} callBackUpdate={() => setEditable(false)} />}

				{maintab == "status" &&
					<>
						<div className="section mt-2 mb-2">
							<div className="card">
								<ServiceClaimCategory editable={editable} key={service} />
								<ServiceReplacement service={service} editable={editable} />
							</div>
						</div>
					</>
				}
				{maintab == "error" && <>
					{service.productErrorCodeId && <ProductErrorInfo productErrorCodeId={service.productErrorCodeId} sn={service.sn} />}
					{!service.productErrorCodeId && <ServiceProductErrorRegister serviceId={service._id} sn={service.sn} />}
				</>
				}
				{(maintab == "site" && service.siteId) && <SiteInfo siteId={service.siteId} showTitle={true} />}
				{(maintab == "site" && !service.siteId) && <NoRecord
					title="Not Installed"
					contents={"This Product( " + service.sn + " ) is not installed"} />}
				<div className="section mt-2 mb-2">
					<div className="card">
						<ul className="nav nav-tabs lined">
							{tabList.map((x, idx) =>
								<li key={idx} className="nav-item">
									<a href
										key={x.code}
										className={tab == x.code ? "nav-link active" : "nav-link"}
										onClick={() => { setTab(x.code); }} >{x.title}</a>
								</li>
							)}
						</ul>
						{tab == "comment" && <ServiceComments service={service} />}
						{tab == "attachments" && <ServiceInfoAttachments service={service} />}
						{tab == "logs" && <ServiceInfoLogs service={service} />}
					</div>
				</div>
			</div>
		</>
	)
}

const mapState = (state) => {
	var service = state.ServiceReducer.service;
	return { service };
};

const mapDispatch = dispatch => ({
	GetServiceByTicketKey: (ticketKey) => dispatch(ServiceAction.GetServiceByTicketKey(ticketKey)),
})

export default connect(mapState, mapDispatch)(ServiceDetail);