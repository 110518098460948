import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { RayFormValidation, RayForm } from '../../components/common';
import { FileItem, FileUpload } from '../../components/common/FileUpload';
import { ProductAction } from '../../actions';
import { Translate as t } from '../../config/Translate';
import { UserType } from '../../config';

const ProductExtendedWarrantyAdd = ({ product, showModal, codeList, callbackClose, AddExtendedWarranty }) => {
    const [info, setInfo] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!info.hasOwnProperty('type')) {
            const u = JSON.parse(localStorage.getItem('user'));

            const creator = u?.adminid;
            const companyId = u?.companyId;
            const userType = UserType(companyId);
            setInfo({ ...info, type: userType === 'HQ' ? 'HQ' : 'BRANCH', creator });
        }
    }, []);

    const updateVal = (e) => {
        const { name, value } = e.target;
        setInfo({ ...info, [name]: value })
    }

    const getName = (val) => {
        var n = codeList.filter(x => x.code == val);
        return n.length > 0 ? n[0].name : val;
    }

    const controls = [{
        title: '', items: [
            {
                type: 'select2',
                name: 'dest',
                label: t('productandpart'),
                val: info && info.dest,
                isEditable: true,
                list: product.waranties.filter(x => x.category == 'wartype_ms').map(x => { return { code: x.dest, title: getName(x.dest) } }),
                isMulti: true,
                required: true
            },
            { type: 'number', name: 'amount', label: t('period'), val: info && info.amount, isEditable: true, required: true },
            {
                type: 'select2', name: 'amountType', label: t('periodunit'), val: info && info.amountType, isEditable: true, list: [
                    { code: "d", title: "day" },
                    { code: "M", title: "month" },
                    { code: "y", title: "year" }
                ], required: true
            },
            { type: 'textarea', name: 'reason', label: t('reason'), val: info && info.why, isEditable: true, required: true },
        ]
    }];

    const handleSubmit = () => {
        const errValidate = RayFormValidation(controls, info);

        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            AddExtendedWarranty(product.sn, info);
            callbackClose();
        }
    }

    return (
        <Modal show={showModal} size="lg">
            <Modal.Header>
                <h2 className="modal-title">{t('extendedwarranty')}</h2>
            </Modal.Header>
            <Modal.Body className="p-0">
                <RayForm
                    handleChangeData={updateVal}
                    controls={controls}
                    errors={errors}
                    nocard={true} />

                <div className="input-wrapper p-2 pt-0 position-relative">
                    <label className="label pb-1">{t('attachments')}</label>
                    <FileUpload
                        option={{ doneandnew: false }}
                        extraData={{ path: "warranty/" + product.sn + "/" }}
                        callback={(e) => e ? setInfo({ ...info, attachment: { path: e.s3path, origin: e.original } }) : setInfo({ ...info, attachment: {} })} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-block btn-primary" onClick={() => handleSubmit()}>{t('add')}</button>
                <button className="btn btn-block btn-dark" onClick={() => callbackClose()}>{t('close')}</button>
            </Modal.Footer>
        </Modal>
    );
}

const mapState = (state) => {
    var product = state.ProductReducer.product;
    return { product };
};

const mapDispatch = (dispatch) => ({
    AddExtendedWarranty: (sn, data) => dispatch(ProductAction.AddExtendedWarranty(sn, data))
});

export default connect(mapState, mapDispatch)(ProductExtendedWarrantyAdd);