import React, { useEffect, useState } from 'react';
import { FileUpload, FileItem } from './FileUpload';

export const FileAddList = ({ callback, itemlist, basepath, readonly = false, isForm = false, cssBtn = "mt-2 mb-2" }) => {

	const [list, setList] = useState(itemlist)

	useEffect(() => {
		setList(itemlist);
	}, [itemlist]);

	useEffect(() => {
		if (list.length != itemlist.length) {
			callback && callback(list);
		}
	}, [list]);

	const delFile = (c) => {
		console.log("delFile : ====", c);
		console.log("isForm : ===", isForm);
		if (!isForm) {
			setList(list.filter(x => x._id != c._id));
		} else {
			setList(list.filter(x => x.path != c.path));
		}
	}

	const addCallback = (addfileInfo) => {
		setList([...list, { name: addfileInfo.original, path: addfileInfo.s3path }]);
	}

	return (
		<>
			<div className="list-group">
				{list.map((c, idx) =>
					<div className="list-group-item">
						<FileItem
							showDel={!readonly}
							fileInfo={{ ...c, original: c.name, s3path: c.path }}
							callback={(c) => delFile(c)} key={idx} />
					</div>
				)}
			</div>
			{!readonly &&
				<div className={cssBtn}>
					<FileUpload callback={(item) => addCallback(item)} basepath={basepath} option={{ doneandnew: true }} extraData={{path: basepath}} />
				</div>
			}
		</>
	)
}
