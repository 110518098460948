import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { InputBasicText } from '../../components/common/Inputs';
import html2pdf from 'html2pdf.js'
import '../../asset/css/cipublish.css';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { history } from '../../history';
import { AdminUserAction } from '../../actions';
import axios from 'axios';
import Loader from "react-loader-spinner";
import { AppConfig, JWT } from '../../config';
import moment from 'moment';
// import { S3Upload } from '../../components/common/FileUpload';

const AdminSparePartsCIPublish = ({ location, order, users, GetOneOrderById, GetUserAll, UpdateCIInfo }) => {
    const pdfOption = {
        margin: 0.3,
        filename: "",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scrollY: 0, scale: 3, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', useCORS: true },
        pageBreak: { avoid: 'tr', mode: 'css', before: ['.sign'] }        
    }

    const useEORICompany = ['RAY00013', 'RAY02078'];

    const [showLoader, setShowLoader] = useState(false);
    const [vHg, setvHg] = useState(0);
    const [ciConfirmed, setCIConfirmed] = useState(false);
    const [useEORI, setUseEORI] = useState(false);
    const [data, setData] = useState({
        shipper: 'RAY Co., Ltd',
        shipperAddress: '12F, 221, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do,13494, Korea',
        shipperTel: '+82 31 605 1000',
        invoiceNumber: '',
        invoiceDate: '',
        piNumber: '',
        messerRep: '',
        messerRepAttn: '',
        messerAddress: '',
        messerTel: '',
        messerEmail: '',
        notifyParty: 'Notify Accountee',
        remarksOriginOfGoods: 'MADE IN SOUTH KOREA',
        remarksLicenseNumber: '135-81-73282',
        remarksCompanyEori: '',
        remarksHqEori: 'DE506538147972043',
        remarksIncoterms: 'EXW',
        portOfLoading: 'INCHEON, KOREA',
        finalDestination: '',
        carrier: '',
        sailingOnOrAbout: '',
        currencyUnit: '',
        partsList: [],
        boxInfo: [
            { seq: 1, box: '', weight: '', dimension: '' }
        ]
    });

    useEffect(() => {
        window.addEventListener('resize', getHeight);
        setvHg(window.innerHeight);
        GetUserAll();
    }, []);

    useEffect(() => {
        setUseEORI(useEORICompany.includes(location.state?.companyId || ''));

        const orderId = location.state?.orderId;

        GetOneOrderById(orderId);

        if (!location.state?.ci) {
            setData({
                ...data,
                _id: location.state?._id,
                orderId: location.state?.orderId,
                messerRep: location.state?.companyName,
                messerRepAttn: order.orderer,
                messerAddress: location.state?.address,
                messerTel: order.tel,
                messerEmail: order.email,
                piNumber: order.pi?.refNo,
                currencyUnit: order?.currencyUnit,
                partsList: location.state?.partsList,
                boxInfo: [
                    { seq: 1, box: '', weight: '', dimension: '' }
                ]
            });
        } else {            
            setData({ ...location.state.ci, orderId: location.state?.orderId });
        }
    }, [location]);

    useEffect(() => {
        setCIConfirmed(order.status === 'CI_CONFIRM')
    }, [order]);

    const handleChangeData = (e) => {
        if (e.target.name === "invoiceDate") {
            if (e.target.value.length === 4) {
                const convertedDate = isValidMMDD(e.target.value);
                setData({ ...data, invoiceDate: !convertedDate ? e.target.value : convertedDate });
            }
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    }

    const isValidMMDD = (mmdd) => {
        const date = moment(mmdd, 'MMDD', true);

        if (date.isValid()) {
            if (date.month() === 3 || date.month() === 5 || date.month() === 8 || date.month() === 10) {
                if (date.date() > 30) {
                    return false;
                }
            }

            if (date.month() === 1) {
                const isLeapYear = date.isLeapYear();
                const maxDays = isLeapYear ? 29 : 28;
                if (date.date() > maxDays) {
                    return false;
                }
            }
            
            return date.format(`YYYY-MM-DD`);           
        }

        return false;
    }

    const getHeight = () => setvHg(window.innerHeight);

    const ciPublish = () => {
        if (confirm('CI가 발행되면 수정이 불가능합니다.\n진행하시겠습니까?')) {
            setShowLoader(true);
            const el = document.getElementsByClassName('contents-wrapper')[0];
            const filename = data.invoiceNumber;
            pdfOption.filename = filename;

            html2pdf().set(pdfOption).from(el).outputPdf('arraybuffer').then((buffer) => {
                const formData = new FormData();

                formData.append("file", new Blob([buffer]), filename + '.pdf');
                formData.append("type", "normal");
                formData.append("path", "spareparts/ci/");

                axios.post(AppConfig.API_SERVER_PATH + "/file/upload", formData, {
                    headers: { "Content-Type": "multipart/form-data", "token": localStorage.getItem('userToken') },
                }).then(res => {
                    if (res.data.status === "success") {
                        UpdateCIInfo({
                            ...data,
                            type: 'PUBLISH',
                            s3path: res.data.data.path.s3path,
                            savedFileName: res.data.data.path.filename,
                            originFileName: res.data.data.path.original,
                        });
                    }
                    else if (res.message) {
                        alert(res.message);
                    }
                    else if (res.data.data.path.err) {
                        alert(res.data.data.path.err);
                    }
                });

                setShowLoader(false);
            });
        }
    }

    const ciExport = () => {
        setShowLoader(true);
        const el = document.getElementsByClassName('contents-wrapper')[0];
        pdfOption.filename = data.invoiceNumber ? data.invoiceNumber : 'download';
        html2pdf().set(pdfOption).from(el).save().then(() => setShowLoader(false));
    }

    const downloadCI = async () => {
        const url = await getUrl(data.s3path, data.savedFileName);
        if (url)
            window.location.href = url;
    }

    const getUrl = async (path, filename) => {
        const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
            path,
            name: filename,
        }, JWT());

        if (ret.data.status == "success")
            return ret.data.data;
        else
            return ''
    }

    const addBoxInfoRow = () => {
        const maxSeq = Math.max(...data.boxInfo.map(x => x.seq));
        setData({ ...data, boxInfo: [...data.boxInfo, { seq: maxSeq + 1, box: '', weight: '', dimension: '' }] });
    }

    const removeBoxInfoRow = (e, seq) => {
        e.preventDefault();
        setData({ ...data, boxInfo: [...data.boxInfo.filter(x => x.seq !== seq)] });
    }

    const boxInfoChange = (e, seq) => {
        const selectedRowData = data.boxInfo.find(x => x.seq === seq);
        setData({ ...data, boxInfo: [...data.boxInfo.filter(x => x.seq !== seq), { ...selectedRowData, [e.target.name]: e.target.value }] });
    }

    const sendMail = async () => {
        if (!data.orderId) {
            alert("딜러출고는 메일을 발송할 수 없습니다.");
        } else {
            if (confirm('주문자에게 CI 확인 메일을 발송합니다.\n진행하시겠습니까?'))
                await SparePartsAction.SendCIConfirmMail(data);
        }
    }

    const save = () => {
        setShowLoader(true);
        const el = document.getElementsByClassName('contents-wrapper')[0];
        const filename = data.invoiceNumber;
        pdfOption.filename = filename;

        html2pdf().set(pdfOption).from(el).outputPdf('arraybuffer').then((buffer) => {
            // S3Upload("spareparts/ci/", new Blob([buffer]), { isBuffer: true, filename: filename || 'download.pdf' }).then(res => {
            //     UpdateCIInfo({
            //         ...data,
            //         type: 'PUBLISH',
            //         s3path: res.s3path,
            //         savedFileName: res.filename,
            //         originFileName: res.original,
            //     });
            //     alert("저장되었습니다.");
            // });

            const formData = new FormData();

            formData.append("file", new Blob([buffer]), filename + '.pdf');
            formData.append("type", "normal");
            formData.append("path", "spareparts/ci/");

            axios.post(AppConfig.API_SERVER_PATH + "/file/upload", formData, {
                headers: { "Content-Type": "multipart/form-data", "token": localStorage.getItem('userToken') },
            }).then(res => {
                if (res.data.status === "success") {
                    UpdateCIInfo({
                        ...data,
                        type: 'PUBLISH',
                        s3path: res.data.data.path.s3path,
                        savedFileName: res.data.data.path.filename,
                        originFileName: res.data.data.path.original,
                    });
                    alert("저장되었습니다.");
                }
                else if (res.message) {
                    alert(res.message);
                }
                else if (res.data.data.path.err) {
                    alert(res.data.data.path.err);
                }
            });

            setShowLoader(false);
        });
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">CI발행</h2>
                <div className="ray-right">
                    <button className="btn btn-sm btn-primary" onClick={() => history.goBack()}>뒤로가기</button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active">
                <div className='row m-1 p-0'>
                    <div className="mr-0 p-1 card" style={{ flex: '0 0 auto', width: '43%', maxHeight: vHg - 140, marginRight: '10px', overflowY: 'scroll', }}>
                        <CIForm data={data} order={order} ciConfirmed={ciConfirmed} users={users} setData={setData} useEORI={useEORI} title={location.state.title} />
                    </div>
                    <div className="col-4 m-0 p-4 card" style={{ maxHeight: vHg - 140, marginRight: '10px', overflowY: 'scroll' }}>
                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'shipper', label: 'Shipper', val: data.shipper }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'shipperAddress', label: 'Shipper Address', val: data.shipperAddress }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'shipperTel', label: 'Shipper Tel', val: data.shipperTel }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'invoiceNumber', label: 'Invoice Number', val: data.invoiceNumber }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'invoiceDate', label: 'Invoice Date', val: data.invoiceDate }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        {
                            order.type === 'CHARGE' &&
                            <div className="form-group basic">
                                <InputBasicText options={{ name: 'piNumber', label: 'PI Number', val: data.piNumber }} editable={!ciConfirmed} onchange={handleChangeData} />
                            </div>
                        }

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'messerRep', label: 'Receive Company', val: data.messerRep }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'messerRepAttn', label: 'Receiver', val: data.messerRepAttn }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'messerAddress', label: 'Receiver Address', val: data.messerAddress }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'messerTel', label: 'Receiver Tel', val: data.messerTel }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'messerEmail', label: 'Receiver Email', val: data.messerEmail }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'notifyParty', label: 'Notify Party', val: data.notifyParty }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'remarksOriginOfGoods', label: 'Origin of Goods', val: data.remarksOriginOfGoods }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'remarksLicenseNumber', label: 'Business License Registration Number', val: data.remarksLicenseNumber }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        {
                            useEORI &&
                            <div className="form-group basic">
                                <InputBasicText options={{ name: 'remarksCompanyEori', label: `${data.messerRep} EORI`, val: data.remarksCompanyEori }} editable={!ciConfirmed} onchange={handleChangeData} />
                            </div>
                        }

                        {/* <div className="form-group basic">
                            <InputBasicText options={{ name: 'remarksHqEori', label: 'RAY EORI', val: data.remarksHqEori }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div> */}

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'remarksIncoterms', label: 'Incoterms', val: data.remarksIncoterms }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'portOfLoading', label: 'Port Of Loading', val: data.portOfLoading }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'finalDestination', label: 'Final Destination', val: data.finalDestination }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'carrier', label: 'Carrier', val: data.carrier }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <InputBasicText options={{ name: 'sailingOnOrAbout', label: 'Sailing On Or About', val: data.sailingOnOrAbout }} editable={!ciConfirmed} onchange={handleChangeData} />
                        </div>

                        <div className="form-group basic">
                            <div>
                                <div style={{ width: '50%', display: 'inline-block' }}>
                                    <label class="label" style={{ marginBottom: '10px' }}>Box Info</label>
                                </div><div style={{ width: '50%', display: 'inline-block', textAlign: 'right' }}>
                                    {
                                        !ciConfirmed &&
                                        <button type='button' className='box-info-form-add-button' onClick={() => addBoxInfoRow()} >+</button>
                                    }
                                </div>
                            </div>
                            <table className='box-info-form'>
                                <thead>
                                    <tr>
                                        <td>Box</td>
                                        <td>Weight(kg)</td>
                                        <td>Dimension(mm)</td>
                                        {
                                            !ciConfirmed &&
                                            <td></td>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.boxInfo?.sort((a, b) => {
                                            if (a.seq > b.seq) return 1;
                                            if (a.seq < b.seq) return -1;
                                            return 0;
                                        }).map(x => {
                                            return (
                                                <tr key={`row_${x.seq}`}>
                                                    <td><input name='box' type='text' onChange={(e) => boxInfoChange(e, x.seq)} value={x.box} readOnly={ciConfirmed} /></td>
                                                    <td><input name='weight' type='text' onChange={(e) => boxInfoChange(e, x.seq)} value={x.weight} readOnly={ciConfirmed} /></td>
                                                    <td><input name='dimension' type='text' onChange={(e) => boxInfoChange(e, x.seq)} value={x.dimension} readOnly={ciConfirmed} /></td>
                                                    {
                                                        !ciConfirmed &&
                                                        <td>
                                                            <a href='#' onClick={(e) => removeBoxInfoRow(e, x.seq)}>
                                                                <ion-icon
                                                                    name="remove-circle"
                                                                    style={{ fontSize: '25px', color: 'red', cursor: 'pointer', verticalAlign: 'middle' }}></ion-icon>
                                                            </a>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div style={{ textAlign: 'right', marginTop: '30px' }}>
                            {
                                showLoader &&
                                <>
                                    <Loader
                                        type='TailSpin'
                                        color='#000'
                                        width={25}
                                        height={25}
                                        visible={true}
                                        radius={10}
                                        style={{ display: 'inline-block', marginRight: '10px' }} />
                                    잠시만 기다려주세요...
                                </>
                            }

                            {
                                !showLoader &&
                                <>
                                    {
                                        !ciConfirmed &&
                                        <>
                                            <button className='btn btn-primary' onClick={() => save()}>저장하기</button>&nbsp;
                                            <button className='btn btn-primary' onClick={() => sendMail()}>메일발송</button>&nbsp;
                                        </>
                                    }
                                    <button className="btn btn-primary" onClick={() => ciExport()}>PDF 다운로드</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CIForm = ({ data, order, ciConfirmed, users, setData, useEORI, title }) => {
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        let tmp = 0;
        for (let i = 0; i < data.partsList?.length; i++) {
            tmp += data.partsList[i]["price"] * data.partsList[i]["quantity"];
        }
        setAmount(tmp);
    }, [data.partsList]);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getUserName = (adminId) => {
        return users.filter(item => item.adminid == adminId).length == 1 ?
            users.filter(item => item.adminid == adminId)[0].firstName + ' ' + users.filter(item => item.adminid == adminId)[0].lastName : adminId;
    }

    return (
        <div className="contents-wrapper">
            <div style={{ textAlign: 'right', paddingRight: '60px', paddingTop: '10px', fontSize: '12px' }}>RAY Co., Ltd</div>
            <div className="s_title">
                {title}                
                <img src='/assets/img/img_wm.png' style={{ width: '90px', height: 'auto', position: 'absolute', 'right': 50, 'top': 60 }} />

            </div>
            <div style={{ padding: '20px' }}>
                <div style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', width: '100%' }}>
                    <div style={{ display: 'inline-block', width: 'calc(60% - 2px)', borderRight: '1px solid #000' }}>
                        <div style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: '5px', paddingLeft: '3px' }}>SHIPPER/EXPORTER</div>
                        <div style={{ paddingLeft: '3px' }}>
                            <strong style={{ fontSize: '12px' }}>{data.shipper}</strong>
                            <div style={{ fontSize: '12px' }}>{data.shipperAddress}</div>
                            <div style={{ paddingTop: '30px', paddingBottom: '5px', fontSize: '12px' }}>T. {data.shipperTel}</div>
                        </div>
                    </div><div style={{ display: 'inline-block', width: '40%', verticalAlign: 'top' }}>
                        <div style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: '5px', paddingLeft: '3px' }}>NO & DATE OF INVOICE</div>
                        <div style={{ paddingLeft: '20px', fontWeight: 'bold', fontSize: '12px', lineHeight: '1.5' }}>
                            <div><label style={{ width: '40px' }}>No.</label> {data.invoiceNumber}</div>
                            <div><label style={{ width: '40px' }}>Date.</label> {data.invoiceDate}</div>
                            {
                                order.type === "CHARGE" &&
                                <div><label style={{ width: '40px' }}>PI No.</label> {data.piNumber}</div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid #000', width: '100%' }}>
                    <div style={{ display: 'inline-block', width: 'calc(60% - 2px)', borderRight: '1px solid #000' }}>
                        <div style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: '5px', paddingLeft: '3px' }}>FOR ACCOUNT & RISK OF MESSERS</div>
                        <div style={{ fontSize: '12px', paddingLeft: '3px' }}>
                            <strong>
                                Rep.: {data.messerRep}&nbsp;
                                {/* &#40;attn : {getUserName(data.messerRepAttn) || data.messerRepAttn}&#41; */}
                                &#40;attn : {getUserName(data.messerRepAttn)}&#41;
                            </strong>
                            <div style={{ paddingBottom: '5px' }}>
                                {data.messerAddress}<br />
                                Tel : {data.messerTel}<br />
                                E-mail : {data.messerEmail}
                            </div>
                        </div>
                        <div style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: '5px', paddingLeft: '3px', borderTop: '1px solid #000' }}>
                            NOTIFY PARTY
                        </div>
                        <div style={{ paddingBottom: '50px', fontSize: '12px', paddingLeft: '3px' }}>
                            {data.notifyParty}
                        </div>
                    </div><div style={{ display: 'inline-block', width: '40%', verticalAlign: 'top' }}>
                        <div style={{ paddingBottom: '10px', fontSize: '12px', fontWeight: 'bold', paddingTop: '5px', paddingLeft: '3px' }}>REMARKS</div>
                        <div style={{ paddingLeft: '13px', fontSize: '12px' }}>
                            -. ORIGIN OF GOODS : {data.remarksOriginOfGoods}<br />
                            -. Business License Registration Number<br />
                            &nbsp;&nbsp;: {data.remarksLicenseNumber}<br />
                            
                            {
                                useEORI &&
                                <>
                                    -. {data.messerRep} EORI : {data.remarksCompanyEori}<br />
                                </>
                            }

                            {/* -. RAY EORI : {data.remarksHqEori}<br /> */}
                            -. INCOTERMS : {data.remarksIncoterms}
                        </div>
                    </div>
                </div>

                <table className="parts-list" border="0" style={{ width: '100%', borderSpacing: 0, fontSize: '11px' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '16%', verticalAlign: 'top', height: '50px' }}>
                                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>PORT OF LOADING</div>
                                <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '12px' }}>{data.portOfLoading}</div>
                            </td>
                            <td colspan="2" style={{ width: '43.7%', verticalAlign: 'top' }}>
                                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>FINAL DESTINATION</div>
                                <div style={{ fontStyle: 'italic', fontWeight: 'bold', width: '100%', textAlign: 'center', fontSize: '12px' }}>{data.finalDestination}</div>
                            </td>
                            <td style={{ verticalAlign: 'top' }}>
                                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>CARRIER</div>
                                <div style={{ fontStyle: 'italic', fontWeight: 'bold', width: '100%', textAlign: 'center', fontSize: '12px' }}>{data.carrier}</div>
                            </td>
                            <td colspan="3" style={{ verticalAlign: 'top' }}>
                                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>SAILING ON OR ABOUT</div>
                                <div style={{ fontStyle: 'italic', fontWeight: 'bold', width: '100%', textAlign: 'center', fontSize: '12px' }}>{data.sailingOnOrAbout}</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ height: '40px', fontWeight: 'bold' }}>
                                NUMBER OF PKGS
                            </td>
                            <td colspan="2" style={{ height: '30px', fontWeight: 'bold', textAlign: 'center' }}>
                                DESCRIPTION OF GOODS
                            </td>
                            <td style={{ height: '30px', fontWeight: 'bold', textAlign: 'center' }}>
                                QUANTITY
                            </td>
                            <td style={{ height: '30px', fontWeight: 'bold', textAlign: 'center' }}>
                                UNIT PRICE
                            </td>
                            <td style={{ height: '30px', fontWeight: 'bold', textAlign: 'center' }}>
                                AMOUNT
                            </td>
                            <td style={{ height: '30px', fontWeight: 'bold', textAlign: 'center' }}>
                                HSCODE
                            </td>
                        </tr>
                        {
                            data.partsList?.length > 0 && data.partsList.map((x, idx) => {
                                return (
                                    <tr>
                                        {
                                            idx === 0 &&
                                            <td rowSpan={data.partsList.length}></td>
                                        }

                                        <td style={{ height: '40px', paddingLeft: '3px' }}>{x.serviceCode}</td>
                                        <td style={{ paddingLeft: '3px' }} dangerouslySetInnerHTML={{ __html: `${x.servicePartName}${x.sn ? `<br />(SN : ${x.sn})` : ''}` }}></td>
                                        <td style={{ textAlign: 'center', height: '35px' }}>
                                        <input
                                                type="text"
                                                value={x?.quantity}
                                                style={{ width: '50px', height: '35px', lineHeight: '35px', padding: 0, margin: 0, textAlign: 'center', border: 0, outline: 0 }}
                                                onChange={(e) => {
                                                    setData({
                                                        ...data,
                                                        partsList: data.partsList.map(y => y._id === x._id ? { ...y, quantity: e.target.value } : { ...y })
                                                    });
                                                }}
                                                readOnly={ciConfirmed}
                                            />
                                            </td>
                                        <td style={{ textAlign: 'center', height: '35px', verticalAlign: 'middle' }}>
                                            <input
                                                type="text"
                                                value={x?.price}
                                                style={{ width: '50px', height: '35px', lineHeight: '35px', padding: 0, margin: 0, textAlign: 'center', border: 0, outline: 0 }}
                                                onChange={(e) => {
                                                    setData({
                                                        ...data,
                                                        partsList: data.partsList.map(y => y._id === x._id ? { ...y, price: e.target.value } : { ...y })
                                                    });
                                                }}
                                                readOnly={ciConfirmed}
                                            />
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{x.price * x.quantity}</td>
                                        <td style={{ height: '35px', textAlign: 'center', verticalAlign: 'middle' }}>
                                            <input
                                                type="text"
                                                value={x?.hsCode}
                                                style={{ width: '72px', height: '35px', lineHeight: '35px', padding: 0, margin: 0, textAlign: 'center', border: 0, outline: 0 }}
                                                onChange={(e) => {
                                                    setData({
                                                        ...data,
                                                        partsList: data.partsList.map(y => y._id === x._id ? { ...y, hsCode: e.target.value } : { ...y })
                                                    });
                                                }}
                                                readOnly={ciConfirmed}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div style={{ fontWeight: 'bold', paddingTop: '5px', paddingLeft: '40px', fontSize: '13px' }}>
                    HANDLE WITH EXTREME CARE
                </div>

                <div style={{ textAlign: 'right', marginTop: '20px', fontSize: '13px' }}>
                    <div style={{ width: '75%', borderTop: '1px solid #000', display: 'inline-block', paddingTop: '10px', fontWeight: 'bold' }}>
                        <div style={{ display: 'inline-block', width: '75%', textAlign: 'left', paddingLeft: '5%', verticalAlign: 'top' }}>Grand Total 	&#40;{data.currencyUnit}&#41;
                        </div><div style={{ display: 'inline-block', width: '20%', textAlign: 'left', lineHeight: '1.5' }}>
                            {numberWithCommas(amount)}<br />
                            (Not include VAT)
                        </div>
                    </div>
                </div>

                <table className="box-info">
                    <tbody>
                        <tr>
                            <td>BOX</td>
                            <td>Weight (KG)</td>
                            <td>Dimension (mm):</td>
                        </tr>
                        {
                            data.boxInfo?.sort((a, b) => {
                                if (a.seq > b.seq) return 1;
                                if (a.seq < b.seq) return -1;
                                return 0;
                            }).map(x => {
                                return (
                                    <tr>
                                        <td>{x.box}</td>
                                        <td>{x.weight}</td>
                                        <td>{x.dimension}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div id='sign'>
                    <div style={{ fontSize: '10px', display: 'inline-block', width: '55%', verticalAlign: 'bottom' }}>
                        ** This invoice is not valid without Ray's official seal.
                    </div><div style={{ fontWeight: 'bold', borderBottom: '3px solid #000', display: 'inline-block', width: '45%', fontSize: '13px' }}>
                        SIGNED BY :

                        <img src='/assets/img/sign.png' style={{ width: '200px', height: 'auto', marginLeft: '40px', marginBottom: '10px' }} />

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapState = (state) => {
    const order = state.SparePartsReducer.order;
    const users = state.AdminUserReducer.users;
    return { order, users };
};

const mapDispatch = (dispatch) => ({
    GetOneOrderById: (orderId) => dispatch(SparePartsAction.GetOneOrderById(orderId)),
    GetUserAll: () => dispatch(AdminUserAction.GetUserAll()),
    UpdateCIInfo: (data) => dispatch(SparePartsAction.UpdateCIInfo(data)),
});

export default connect(mapState, mapDispatch)(AdminSparePartsCIPublish);