import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProductAction, AlertAction, AuthAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';
import { history } from '../../history';

const AdminProductAdd = ({ product, apierrors, RegistProductInfo }) => {

    const [isAddProcess, setIsAddProcess] = useState(false);
    const [newProduct, setNewProduct] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (isAddProcess) {
            history.push("/Admin/AdminProductInfo/" + product._id);
        }
    }, [product])

    useEffect(() => {
        setErrors(apierrors);
    }, [apierrors]);

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    }

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "sn", label: "Serial Number", required: true },
                { type: "input", name: "model", label: "Model", required: true, isEditable: true },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "option", label: "Options", required: true, isEditable: true },
            ]
        },
    ]

    const validAdd = () => {
        const errValidate = RayFormValidation(form_controls, newProduct);
        if (errValidate.length > 0) {
            setErrors(errValidate);
        } else {
            setIsAddProcess(true);
            RegistProductInfo(newProduct);
        }
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Add New Product</h2>
                <div className="ray-right">
                    <Link to="/Admin/AdminProductList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <RayForm
                    controls={form_controls}
                    isForm={true}
                    handleChangeData={handleChangeData}
                    errors={errors} />
                <div className="section mt-1 mb-1 ">
                    <button className="btn btn-primary btn-block" onClick={() => validAdd()}>Add</button>
                </div>
            </div>
        </>
    );
};

const mapState = (state) => {
    const product = state.ProductReducer.product;
    const apierrors = state.AlertReducer.errors;
    return { apierrors, product };
}

const mapDispatch = dispatch => ({
    RegistProductInfo: (data) => dispatch(ProductAction.RegistProductInfo(data)),
})

export default connect(mapState, mapDispatch)(AdminProductAdd);