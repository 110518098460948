import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { LanguageCode } from '../../config';
import { connect } from 'react-redux';
import { ErrorCodeAction } from '../../actions';
import { ErrorCodeDelete, ErrorCodeInfo } from '../../components/errorCode'
import { history } from '../../history';
import { Loading } from '../../components/common/Loading';

const AdminErrorCodeInfo = ({
  su,
  GetErrorCode,
  GetErrors,
  GetErrorCodeById,
  errorCodeInfo,
}) => {
  const { errorCode, lang, view, _id } = useParams();
  const [lng, setLng] = useState(lang || "eng");
  const [tab, setTab] = useState(view || "info");
  const [isLoading, setIsLoading] = useState(true);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [errorCodeInfo]);

  useEffect(() => {
    console.log("AdminErrorCodeInfo");
    GetErrors();
    if(_id) {
      GetErrorCodeById(_id);
    } else { 
      if(errorCode) {
        GetErrorCode(errorCode, lang);
      }
    } 
    
  }, []);

  const tabList = [
    { code: "info", title: "Information" },
    { code: "history", title: "History" },
  ];

  const langData =
    errorCodeInfo && LanguageCode.filter((x) => x.code == errorCodeInfo.lang);

  return (
    <>
      {!isLoading && (
        <>
          {errorCodeInfo && (
            <>
              <div className="extraHeader">
                <h2 className="mb-0">
                  {errorCodeInfo.errorCode}
                  <span className="fs-13 text-danger">
                    {" "}
                    {langData.length > 0 && langData[0].title}{" "}
                  </span>
                </h2>
                <div className="ray-right">
                  {su && tab != "del" && (
                    <Link
                      to={
                        "/Admin/AdminErrorCodeInfo/" +
                        errorCodeInfo.errorCode +
                        "/" +
                        errorCodeInfo.lang +
                        "/del"
                      }
                      className="btn btn-danger btn-sm mt-0 me-1"
                      onClick={() => setTab("del")}
                    >
                      Delete ErrorCode
                    </Link>
                  )}
                  <Link
                    to="/Admin/AdminErrorCodeList"
                    className="btn btn-outline-default btn-sm mt-0 me-1"
                  >
                    List
                  </Link>
                </div>
              </div>
              <div id="appCapsule" className="extra-header-active full-height">
                {tab == "info" && errorCodeInfo.errorCode && (
                  <ErrorCodeInfo errorCodeInfo={errorCodeInfo} />
                )}
                {tab == "del" && errorCodeInfo.errorCode && (
                  <ErrorCodeDelete errorCodeInfo={errorCodeInfo} />
                )}
              </div>
            </>
          )}
          {!errorCodeInfo && (
            <div id="appCapsule" className="full-height mt-2">
              <div className="section">
                <div className="alert alert-danger">
                  {errorCode} 코드는 존재하지 않는 ErrorCode 입니다.
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => history.goBack()}
                >
                  이전으로 돌아가기
                </button>
              </div>
            </div>
          )}
        </>
      )}
			{isLoading && <div className="" style={{ marginTop : 120 }}>        
				<Loading />
			</div>}
    </>
  );
};

const mapState = (state) => {
  const su = state.AuthReducer.su;
  const errorCodeInfo = state.ErrorCodeReducer.errorCode;
  return { su, errorCodeInfo };
};

const mapDispatch = (dispatch) => ({
  GetErrors: () => dispatch(ErrorCodeAction.GetList()),
  GetErrorCode: (errorCode, lang) => dispatch(ErrorCodeAction.GetOne(errorCode, lang)),
  GetErrorCodeById: (_id) => dispatch(ErrorCodeAction.GetOneById(_id)),
});

export default connect(mapState, mapDispatch)(AdminErrorCodeInfo);
