export const siteSurveyColumn = [
    { title: 'preinstallationchecklist', column: '' }, 
    { title: 'customer', column: ''},
    { title: 'office', column: 'siteSitename' },
    { title: 'type', column: 'siteSpeciality' },
    { title: 'customername', column: 'customerFirstName' },
    { title: 'phone', column: 'siteTel' },
    { title: 'email', column: 'customerEmail' },
    { title: 'mobile', column: 'customerHp' },
    { title: 'address', column: 'siteAddress' },
    { title: 'zipcode', column: 'siteZipcode' },
    { title: 'country', column: 'siteCountry' },
    { title: 'patientchairs', column: 'accessibility' },
    { title: 'clientpc', column: 'accessibility' },
    { title: 'product', column: '' },
    { title: 'installation', column: 'accessibility' },
    { title: 'model', column: 'model' },
    { title: 'option', column: 'option' },
    { title: 'scheduled', column: 'scheduleDate' },
    { title: 'usertraining', column: 'trainingDate' },
    { title: 'additionalorders', column: '' },
    { title: 'mainunit', column: 'accessibility' },
    { title: 'portable', column: 'additionalPortableXray' },
    { title: 'threedprinter', column: 'additionalThreedPrinter' },
    { title: 'curingunit', column: 'additionalCurningUnit' },
    { title: 'resin', column: 'accessibility' },
    { title: 'riosensorqty', column: 'additionalRioSensorSize1' },
    { title: 'riosensortotal', column: 'additionalRioScanStandard' },
    { title: 'remarks', column: 'accessibility' },
    { title: 'environment', column: '' },
    { title: 'quantity', column: '' },
    { title: 'manufacturer', column: '' },
    { title: 'wallmount', column: 'environmentWalmountXray' },
    { title: 'portable', column: 'environmentPotableXray' },
    { title: 'remarks', column: 'environmentRemark' },
    // Delivery information
    { title: 'delivery', column: '' },
    { title: 'date', column: 'deliveryDate' },
    { title: 'accessibility', column: 'accessibility' },
    { title: 'lift', column: 'liftYN' },
    { title: 'remarks', column: 'additionalRemark' },
    { title: 'previousunit', column: '' },
    { title: 'extraoral', column: '' },
    { title: 'brand', column: '' },
    { title: 'product', column: '' },
    { title: 'intraoral', column: '' },
    { title: 'brand', column: '' },
    { title: 'product', column: '' },
    { title: 'datamigration', column: 'accessibility' },
    { title: 'intergration', column: 'accessibility' },
    { title: 'pms', column: 'accessibility' },
    { title: 'psp', column: 'accessibility' },
    { title: 'twain', column: 'accessibility' },
    { title: 'intrasensor', column: 'accessibility' },
    { title: 'twain', column: 'accessibility' },
    { title: 'intracamera', column: 'accessibility' },
    { title: 'twain', column: 'accessibility' },
    { title: 'shieldroom', column: '' },
    { title: 'construction', column: 'accessibility' },
    { title: 'interior', column: 'shieldroomConstruction' },
    { title: 'power', column: 'shieldroomOutputHz' },               //
    { title: 'outputvoltage', column: 'shieldroomOutputVtg' },
    { title: 'grounded', column: '' },
    { title: 'l1ground', column: 'shieldroomlGroundVoltage1' },
    { title: 'l2ground', column: 'shieldroomlGroundVoltage2' },
    { title: 'avr', column: 'shieldroomAvrCharged' },
    // Installation Details
    { title: 'installdetails', column: '' },
    { title: 'workstation', column: '' },
    { title: 'monitor', column: 'workstationMonitor' },
    { title: 'location', column: 'workstationLocation' },
    { title: 'shelf', column: 'workstationShelf' },
    { title: 'remarks', column: 'workstationRemark' },
    { title: 'exposureswitch', column: '' },
    { title: 'exposureswitchlocation', column: 'exposureswitchLocation' },
    { title: 'interlock', column: 'exposureswitchInterlock' },
    { title: 'interlockProduct', column: 'exposureswitchInterlock' },
    { title: 'interlockModel', column: 'exposureswitchInterlockModel' },
    { title: 'model', column: 'exposureswitchModel' },
    { title: 'remarks', column: 'exposureswitchRemark' },
    { title: 'clientpc', column: 'accessibility' },
    { title: 'pcpurchase', column: 'accessibility' },
    { title: 'quantity', column: 'accessibility' },
    { title: 'monitor', column: 'accessibility' },
    { title: 'other', column: 'accessibility' },
    { title: 'monitorarm', column: 'accessibility' },
    { title: 'quantity', column: 'accessibility' },
    { title: 'software', column: '' },
    { title: 'mainviewer', column: 'softwareMainProduct' },
    { title: 'threedviewer', column: 'softwareThreedProduct' },
    { title: 'cephtracing', column: 'softwareCephProduct' },
    { title: 'other', column: 'softwareOtherProduct' },
    { title: 'mainModel', column: 'softwareMainModel'},
    { title: 'threedModel', column: 'softwareThreedModel' },
    { title: 'cephtracingModel', column: 'softwareCephModel' },
    { title: 'otherModel', column: 'softwareOtherModel' },
    { title: 'softwareRemark', column: 'softwareRemark'},
    { title: 'remarks', column: 'accessibility' },
    { title: 'network', column: '' },
    { title: 'construction', column: 'networkConstruction' },
    { title: 'router', column: 'networkRouter' },
    //{ title: 'router + type', column: 'accessibility' },
    { title: 'type', column: 'networkRouterType' },
    { title: 'interlock', column: 'networkInternet' },
    
    { title: 'remarks', column: 'networkRemark' },
    // Room dimensions
    { title: 'drawing', column: 'attachments' },
    //---- image ------
    { title: 'finalcomment', column: 'finalComment' },
    // Signature
    { title: 'signature' },
    
    // previous Units
    { title: 'previousUnitsExtraOralList', column: 'previousUnitsExtraOralList' },
    { title: 'previousUnitsIntraOralList', column: 'previousUnitsIntraOralList' },
    // datamigration
    { title: 'datamigrationExpense', column: 'datamigrationExpense' },
    { title: 'datamigrationModality', column: 'datamigrationModality' },
    { title: 'datamigrationRequired', column: 'datamigrationRequired' },
    { title: 'integrationPmsBrand', column: 'integrationPmsBrand' },
    { title: 'integrationPmsProduct', column: 'integrationPmsProduct' },
    { title: 'integrationPspBrand', column: 'integrationPspBrand' },
    { title: 'integrationPspProduct', column: 'integrationPspProduct' },
    { title: 'integrationPspTwain', column: 'integrationPspTwain' },
    { title: 'integrationSensorBrand', column: 'integrationSensorBrand'},
    { title: 'integrationSensorProduct', column: 'integrationSensorProduct'},
    { title: 'integrationSensorTwain', column: 'integrationSensorTwain'},
    { title: 'integrationCameraBrand', column: 'integrationCameraBrand'},
    { title: 'integrationCameraProduct', column: 'integrationCameraProduct'},
    { title: 'integrationCameraTwain', column: 'integrationCameraTwain'},

    { title: 'shieldroomAvrCharged', column: 'shieldroomAvrCharged' },
    { title: 'exposureswitchInterlockRequired', column: 'exposureswitchInterlockRequired'},
    { title: 'monitorList', column: 'monitorList'},
    { title: 'environmentWalmountXray', column: 'environmentWalmountXray' },
    { title: 'environmentPotableXray', column: 'environmentPotableXray' },
    { title: 'environmentRemark', column: 'environmentRemark' },
    { title: 'additionalObjectScankit', column: 'additionalObjectScankit' },
    { title: 'additionalRayCeph', column: 'additionalRayCeph' },
    { title: 'additionalRayScanweb', column: 'additionalRayScanweb' },
    { title: 'environmentWalmountXrayManufacture', column: 'environmentWalmountXrayManufacture' },
    { title: 'environmentPotableXrayManufacture', column: 'environmentPotableXrayManufacture' },
    { title: 'environmentUnitChairs', column: 'environmentUnitChairs'},
    { title: 'environmentClientPc', column: 'environmentClientPc'}
];



export const installationColumn = [
    { title: 'installreport', column: '' },
    // installer
    { title: 'installer', column: '' },
    { title: 'country', column: 'country' },
    { title: 'installerCountry', column: 'installerCountry'},
    { title: 'installername', column: 'creator' },
    { title: 'Company', column: 'installerName' },
    { title: 'techphone', column: 'installerPhone' },
    { title: 'installdate', column: 'installationDate' },
    // customer
    { title: 'customer', column: '' },
    { title: 'office', column: 'siteName' },
    { title: 'type', column: 'siteSpecialty'},
    { title: 'customefirstname', column: 'customerFirstName'},
    { title: 'customelastname', column: 'customerlastName'},
    { title: 'phone', column: 'siteTel'},
    { title: 'email', column: 'customerEmail'},
    { title: 'mobile', column: 'customerHp'},
    { title: 'address', column: 'siteAddress'},
    { title: 'zipcode', column: 'siteZipCode'},
    { title: 'countrysite', column: 'siteCountry'},
    // product
    { title: 'product', column: '' },
    { title: 'model', column: 'model' },
    { title: 'option', column: 'option'},
    { title: 'sn', column: 'sn'},
    // system configuration
    { title: 'systemconfig', column: '' },
    { title: 'networkstaticip', column: 'networkStaticIp' },
    { title: 'rayioversion', column: 'rayIoVersion'},
    { title: 'rayscanversion', column: 'rayScanVersion'},
    { title: 'smartdentversion', column: 'smartdentVersion'},
    { title: 'rayServerVersion', column: 'rayServerVersion'},
    { title: 'ondemandVersion', column: 'ondemandVersion'},
    { title: 'pacs', column: ''},
    { title: 'mwl', column: ''},
    { title: 'aetitle', column: ''},
    { title: 'port', column: ''},
    {title: 'pacsList', column: 'pacsList'},
    { title: 'pacs-mwl-aetitle1', column: 'pacs-mwl-aetitle1'},
    { title: 'pacs-mwl-aetitle2', column: 'pacs-mwl-aetitle2'},
    { title: 'pacs-mwl-aetitle3', column: 'pacs-mwl-aetitle3'},
    { title: 'pacs-mwl-ip1', column: 'pacs-mwl-ip1'},
    { title: 'pacs-mwl-ip2', column: 'pacs-mwl-ip2'},
    { title: 'pacs-mwl-ip3', column: 'pacs-mwl-ip3'},
    { title: 'pacs-mwl-port1', column: 'pacs-mwl-port1'},
    { title: 'pacs-mwl-port2', column: 'pacs-mwl-port2'},
    { title: 'pacs-mwl-port3', column: 'pacs-mwl-port3'},
    { title: 'pacs-printer-aetitle1', column: 'pacs-printer-aetitle1'},
    { title: 'pacs-printer-aetitle2', column: 'pacs-printer-aetitle2'},
    { title: 'pacs-printer-aetitle3', column: 'pacs-printer-aetitle3'},
    { title: 'pacs-printer-ip1', column: 'pacs-printer-ip1'},
    { title: 'pacs-printer-ip2', column: 'pacs-printer-ip2'},
    { title: 'pacs-printer-ip3', column: 'pacs-printer-ip3'},
    { title: 'pacs-printer-port1', column: 'pacs-printer-port1'},
    { title: 'pacs-printer-port2', column: 'pacs-printer-port2'},
    { title: 'pacs-printer-port3', column: 'pacs-printer-port3'},
    { title: 'pacs-scp-aetitle1', column: 'pacs-scp-aetitle1'},
    { title: 'pacs-scp-aetitle2', column: 'pacs-scp-aetitle2'},
    { title: 'pacs-scp-aetitle3', column: 'pacs-scp-aetitle3'},
    { title: 'pacs-scp-ip1', column: 'pacs-scp-ip1'},
    { title: 'pacs-scp-ip2', column: 'pacs-scp-ip2'},
    { title: 'pacs-scp-ip3', column: 'pacs-scp-ip3'},
    { title: 'pacs-scp-port1', column: 'pacs-scp-port1'},
    { title: 'pacs-scp-port2', column: 'pacs-scp-port2'},
    { title: 'pacs-scp-port3', column: 'pacs-scp-port3'},

    // user training check-list
    { title: 'usertrainingcheck', column: ''},
    { title: 'system', column: ''},
    { title: 'remote', column: ''},
    { title: 'complete', column: ''},
    { title: 'action', column: ''},
    { title: 'cleaning', column: ''},
    { title: 'positioning', column: ''},
    { title: 'lasers', column: ''},
    { title: 'correct', column: ''},
    { title: 'patient', column: ''},
    { title: 'import', column: ''},
    { title: 'tools', column: ''},
    // packing
    { title: 'packing', column: 'packing'},
    { title: 'shockindicator', column: ''},
    { title: 'tiltwatch', column: ''},
    // image quality
    { title: 'imagequality', column: 'imagequality'},
    { title: 'panoramic', column: 'pano'},
    { title: 'cbct', column: 'cbct'},
    { title: 'cephalometric', column: 'ceph'},
    // signature
    { title: 'signatures', column: 'signatures'},
    { title: 'installersig', column: 'signatureName'},
    { title: 'installersigcommnet', column: ''},
    { title: 'customersig', column: 'customerSignatureName'},
    // customer signature
    { title: 'customersig', column: ''},
    { title: 'reposigcomment', column: ''},
    // Appendix. Regulatory Document
    { title: 'regulatorydocument', column: 'regulatorydocument'},

];