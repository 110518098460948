import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import { SpecialityTypes } from '../../config/RayCodes';
import { CountryCode } from '../../config';
import { SiteSurveyAction, ErrorCodeAction, ProductAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const SurveyStep0 = ({ info, Create, Update, isSummary = false, gostep, user }) => {
	//const [ t, i18n ] = useTranslation();
	const [errors, setErrors] = useState([]);
	const [isAddProcess, setIsAddProcess] = useState(false);
	const [siteSurvey, setSiteSurvey] = useState(info ? info : { siteSurveyStep: 2 });

	const SpecialityTypes = [
		{ code: "GP", title: t('generalpractitioner') },
		{ code: "Pros", title: t('prosthodontist') },
		{ code: "Endo", title: t('Endodontist') },
		{ code: "Perio", title: t('periodontist') },
		{ code: "Ortho", title: t('orthodontist') },
		{ code: "Ped", title: t('pediatricdentist') },
		{ code: "Oral", title: t('oralandmaxillofacialsurgeon') },
		{ code: "Public", title: t('publichealthdentist') },
		{ code: "Rad", title: t('radiologist') },
		{ code: "Other", title: t('other') },
	]

	useEffect(() => {
		setSiteSurvey(info);
		setSiteSurvey({ ...siteSurvey, ["siteCountry"]: info.siteCountry ? info.siteCountry : user.companyInfo.country });
	}, [info]);

	useEffect(() => {
		if (gostep) {
			if (gostep.curStep == 1) {
				const errValidate = RayFormValidation(form_controls, siteSurvey);
				if (errValidate.length > 0) {
					setErrors(errValidate);
				} else {
					setIsAddProcess(true);
					Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: gostep.goStepNo });
				}
			}
		}
	}, [gostep])

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setSiteSurvey({ ...siteSurvey, [name]: value });
	};

	const form_controls = [
		{
			title: t('practice'), items: [
				{ type: "input", name: "siteSitename", label: t('sitename'), val: siteSurvey.siteSitename, required: true, isEditable: !isSummary },
				{ type: "select2", name: "siteSpeciality", label: t('specialty'), val: siteSurvey.siteSpeciality, list: SpecialityTypes, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "select2", name: "siteCountry", label: t('country'), val: !siteSurvey.siteCountry ? user.companyInfo.country : siteSurvey.siteCountry, list: CountryCode, isEditable: !isSummary, isCounrty: true },
				{ type: "input", name: "siteAddress", label: t('fulladdress'), val: siteSurvey.siteAddress, isEditable: !isSummary },
			]
		},
		{
			title: "", items: [
				{ type: "tel", name: "siteZipcode", label: t('zipcode'), val: siteSurvey.siteZipcode, isEditable: !isSummary },
				{ type: "input", name: "siteWebsite", label: t('website'), val: siteSurvey.siteWebsite, isEditable: !isSummary }
			]
		},
		{
			title: t('customer'), items: [
				{ type: "input", name: "customerFirstName", label: t('firstnamerequired'), val: siteSurvey.customerFirstName, required: true, isEditable: !isSummary },
				{ type: "input", name: "customerLastName", label: t('lastnamerequired'), val: siteSurvey.customerLastName, required: true, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "email", name: "customerEmail", label: t('email'), val: siteSurvey.customerEmail, isEditable: !isSummary },
				{ type: "input", name: "customerHp", label: t('mobile'), val: siteSurvey.customerHp, isEditable: !isSummary }
			]
		},
	];

	const validAdd = () => {
		const errValidate = RayFormValidation(form_controls, siteSurvey);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsAddProcess(true);
			if (siteSurvey._id) {
				Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 2 });
			} else {
				Create({ ...siteSurvey, siteSurveyStep: 2 });
			}
		}
	};

	return (
		<>
			<RayForm
				controls={form_controls}
				showAllFields={true}
				isForm={!isSummary}
				isSummary={isSummary}
				handleChangeData={handleChangeData}
				errors={errors}
			/>
			{!isSummary &&
				<div className="section">
					<div className="mb-2 clearfix">
						<button className="btn btn-primary btn-block btn-lg" onClick={() => validAdd()}>{t('next')}</button>
					</div>
				</div>
			}
		</>
	);
};

const mapState = (state) => {
	const user = state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	Create: (data) => dispatch(SiteSurveyAction.CreateSiteSurvey(data)),
	Update: (_id, data) => dispatch(SiteSurveyAction.UpdateSiteSurvey(_id, data)),
});

export default connect(mapState, mapDispatch)(SurveyStep0);