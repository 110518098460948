import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { ErrorCodeReducer } from '../../reducers/ErrorCodeReducer';
import { NoRecord } from '../../pages/HomePage';
import { ErrorCodeAction, ProductAction, ServiceAction } from '../../actions';
import ProductErrorCard from '../product/ProductErrorCard';
import moment from 'moment';
import { Translate as t } from '../../config/Translate';

const ServiceProductErrorRegister = ({ serviceId, sn, UpdateService, service }) => {

	//const [t, i18n] = useTranslation();
	const [show, setShow] = useState("normal");
	const [errList, setErrList] = useState([]);
	const [err, setErr] = useState({});
	const errlastdays = 2400;

	useEffect(() => {
		getErrList();
	}, [])

	const getErrList = async () => {
		const item = await ProductAction.GetProductLocal(sn);
		var list = [];
		if (item.errs) {
			item.errs.map(x => {
				if (x.begin > moment().add(-1 * errlastdays, "days").valueOf())
					list.push({ ...item, errs: x, acquisition: [] });
			});
		}
		setErrList(list.sort((a, b) => b.begin - a.begin));
	}

	const goLink = () => {
		setShow("linkerror");
	}

	const hideLink = () => {
		setShow("normal");
	}

	const checkError = (err) => {
		setErr(err);
	}

	const goLinkto = () => {
		UpdateService(serviceId, { productErrorCodeId: err._id });
	}

	return (
		<>
			{show == "normal" && <div className='m-1'>
				<NoRecord
					title={t('nodataavailable')}
					contents={t('thisticketwasregisteredwithoutanerrorcode')} />
				<div className="section p-0">
					{errList.length > 0 && <button className="btn btn-block btn-secondary" onClick={() => goLink()}>{t('syncerror')}</button>}
				</div>
			</div>}
			{show == "linkerror" &&
				<div className="section">
					<div className="section-title">{t('selectanerror')}(last {errlastdays} days)</div>
					{errList.length > 0 && <>
						{errList.map(item => <ProductErrorCard
							item={item}
							key={"err" + item._id}
							checked={err._id == item.errs._id}
							check={(e) => checkError(e)} />)}
					</>}
					{errList.length === 0 && <NoRecord title={t('noerrors')} contents={t('thereisnoerrorhistory')} />}
					{err._id && <button className="btn btn-block btn-primary" onClick={() => goLinkto()}>{t('save')}</button>}
					<button className="btn btn-block btn-secondary" onClick={() => hideLink()}>{t('cancel')}</button>
				</div>
			}
		</>
	)
}

const mapState = state => {
	const user = state.AuthReducer.user;
	const errorCode = ErrorCodeReducer.errorCode;
	const isShow = state.ProgramReducer.errorLookupShow;
	const eCode = state.ProgramReducer.errorLookupCode;
	const service = state.ServiceReducer.service;
	return { user, errorCode, eCode, isShow, service };
}

const mapDispatch = dispatch => ({
	GetOne: (errorCode, lang) => dispatch(ErrorCodeAction.GetOne(errorCode, lang)),
	UpdateService: (serviceId, serviceInfo) => dispatch(ServiceAction.UpdateService(serviceId, serviceInfo))
})

export default connect(mapState, mapDispatch)(ServiceProductErrorRegister);