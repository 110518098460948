import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RayChartBar } from '../common';

const ProductInfoErrorChart = ({ product, showtable = true, days = 14, height = 400, onlyGraph = false }) => {

	const [has, setHas] = useState(false);
	const [chartData, setChartData] = useState([]);
	const [chartCategory, setChartCategory] = useState([]);
	const [chartFilter, setChartFilter] = useState({
		date: "", modality: ""
	});

	useEffect(() => {
		makeData();
	}, [product]);

	const makeData = () => {
		var category = [];
		product.errs.map((x) => {
			if (!category.includes(x.code))
				category.push(x.code)
		})
		setChartCategory(category);

		var result = [];
		for (var i = days - 1; i >= 0; i--) {
			var localDate = moment().add(-1 * i, 'days');
			var obj = { date: localDate.format("MM/DD"), odate: localDate.format("YYYY-MM-DD") };
			category.map(m => {
				obj[m] = 0;
			});
			result.push(obj)
		}
		var cnt = 0;
		product.errs.map((value) => {
			var localDate = moment.utc(value.begin).local().format("MM/DD");
			var foundIndex = result.findIndex(x => x.date == localDate);
			if (result[foundIndex]) {
				result[foundIndex][value.code]++;
				cnt++;
			}
		});
		setHas(cnt > 0);
		setChartData(result);
	}

	const handleClick = (item) => {
		setChartFilter({
			date: item.data.odate,
			code: item.id
		});
		console.log(item);
	}

	const chartOptions = {
		height: height,
		handleClick: handleClick
	}

	return (
		<>
			{has && <RayChartBar data={chartData} category={chartCategory} indexBy="date" options={chartOptions} />}
		</>
	);
};

export default ProductInfoErrorChart;