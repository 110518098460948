import { ProductPartConstant } from '../actions/constants/ProductPartConstant';

const initialState = {
	items : [],
	item : { 
		models : [] 
	},
    isLoading : false,
	totalproductcount: 0,
};

export const ProductPartReducer = (state = initialState, action) => {
	switch (action.type) {
		case ProductPartConstant.GET_ALL:
			return { ...state, items : [...action.items] }
		case ProductPartConstant.UPDATE:
		case ProductPartConstant.GET_ONE:
			return {
				...state,
				items: state.items.map((u) => (u._id == action.item._id ? action.item : u)),
				item: action.item,
			};
		case ProductPartConstant.CREATE:
			return {
				...state,
				items : [...state.items, action.item],
				item: action.item,
			};
		case ProductPartConstant.DELETE:
			return { ...state, items: state.items.filter((u) => u._id !== action._id), item : {}};
		case ProductPartConstant.ADDUPDATE:
			return { ...state, item: action.item };
        case ProductPartConstant.LOADING:
            return {...state, isLoading : true}
        case ProductPartConstant.LOADED:
            return {...state, isLoading : false}
		default:
			return state;
	}
};
