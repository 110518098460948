export const SparePartsConstant = {
    GET_SPARE_PARTS_LIST: 'GET_SPARE_PARTS_LIST',
    REGIST_SPARE_PARTS: 'REGIST_SPARE_PARTS',
    UPDATE_SPARE_PARTS: 'UPDATE_SPARE_PARTS',
    GET_ONE_SPARE_PARTS: 'GET_ONE_SPARE_PARTS',
    ADD_SPARE_PARTS_HISTORY: 'ADD_SPARE_PARTS_HISTORY',
    UPDATE_SPARE_PARTS_HISTORY: 'UPDATE_SPARE_PARTS_HISTORY',
    GET_GROUP_LIST: 'GET_GROUP_LIST',
    REGIST_GROUP: 'REGIST_GROUP',
    UPDATE_GROUP: 'UPDATE_GROUP',

    REGIST_COMPANY_STOCK: 'REGIST_COMPANY_STOCK',
    UPDATE_COMPANY_STOCK: 'UPDATE_COMPANY_STOCK',
    COMPANY_STOCK_LIST: 'COMPANY_STOCK_LIST',
    COMPANY_STOCK_INFO: 'COMPANY_STOCK_INFO',
    COMPANY_STOCK_IN_LIST: 'COMPANY_STOCK_IN_LIST',

    SPARE_PARTS_ORDER_SUBMIT: 'SPARE_PARTS_ORDER_SUBMIT',
    SPARE_PARTS_ORDER_LIST: 'SPARE_PARTS_ORDER_LIST',
    SPARE_PARTS_ORDER_GET_ONE: 'SPARE_PARTS_ORDER_GET_ONE',
    SPARE_PARTS_OUT_LIST: 'SPARE_PARTS_OUT_LIST',
    ORDER_CHANGE_STATUS: 'ORDER_CHANGE_STATUS',
    SPARE_PARTS_OUT_CONTENTS: 'SPARE_PARTS_OUT_CONTENTS',
    SPARE_PARTS_OUT_HISTORY: 'SPARE_PARTS_OUT_HISTORY',
    SPARE_PARTS_IN_HISTORY: 'SPARE_PARTS_IN_HISTORY',
    COMPANY_OUT_LIST: 'COMPANY_OUT_LIST',
};
