export const InstallationConstant = {
    GET_INSTALLATIONLIST: 'GET_INSTALLATIONLIST',
    GET_INSTALLATION: 'GET_INSTALLATION',
    NEW: 'NEW_INSTALLATION',
    ERROR: 'INSTALLATION_ERROR',
    DELETE: 'INSTALLATION_DELETE',
    UPDATE: 'UPDATE_INSTALLATION',
    CREATE: 'CREATE_INSTALLATION',
    LOADING: 'INSTALLATION_LOADING',
    LOADED: 'INSTALLATION_LOADED',
    CLEAR: 'INSTALLATION_CLEAR',
    INSTALLATION_UPDATE_SIG: 'INSTALLATION_UPDATE_SIG',
    INSTALLATION_UPDATE_ADD_ATTACHMENT: 'INSTALLATION_UPDATE_ADD_ATTACHMENT',
    INSTALLATION_UPDATE_DEL_ATTACHMENT: 'INSTALLATION_UPDATE_DEL_ATTACHMENT',
};