import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RayTable } from '../../components/common';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { InputBasicSelect, InputBasicDateTime, InputBasicNumber, InputBasicText, InputBasicCheckbox, InputSelect2Add } from '../../components/common/Inputs';

const AdminSparepartsDetailIn = ({ partsInfo, AddHistory, UpdateHistory, GetOne }) => {
    const [showModal, setShowModal] = useState(false);
    const [modifyData, setModifyData] = useState({});

    const [inCount, setInCount] = useState({ confirm: 0, notConfirm: 0 });

    const header = [
        { name: 'date', label: '일자', type: 'DTONLY', align: "text-center" },
        { name: 'type', label: '구분', align: "text-center" },
        { name: 'sn', label: '시리얼넘버', align: "text-center", type: 'ARRAY' },
        { name: 'noSnQty', label: '시리얼넘버 미확인', align: "text-center" },
        { name: 'totalQty', label: '시리얼 확인+ 시리얼 미확인', align: "text-center" },
        { name: 'isCanceled', label: '취소여부', type: 'PASSFAIL', align: "text-center" },
        { name: 'memo', label: '메모' },
    ];
    
    const { _id } = useParams();

    useEffect(() => {
        if (!partsInfo)
            GetOne(_id);
    }, []);

    useEffect(() => {
        setShowModal(false);

        let confirmed =0;
        let notConfirmed = 0;

        partsInfo?.in?.forEach(x => {
            if (!x.isCanceled) {
                confirmed += x.sn.length;
                notConfirmed += x.noSnQty;
            }
        });

        setInCount({ confirm: confirmed, notConfirm: notConfirmed });
    }, [partsInfo]);

    const rowSelect = (item) => {        
        setModifyData({ ...item, isCanceled: item.isCanceled === '취소됨' ? true : false, _temp: new Date().getTime() });
        setShowModal(true);
    }

    return (
        <>
            <div className='text-right pb-1'>
                <button className='btn btn-sm btn-primary' onClick={() => setShowModal(true) }>입고등록</button>                
            </div>
            <div className='card p-2'>
                <div className='pb-1 h5'>{`잔여수량:${inCount.confirm + inCount.notConfirm}ea (S/N 확인:${inCount.confirm}, S/N 미확인:${inCount.notConfirm})`}</div>
                <RayTable
                    data={partsInfo?.in?.map(x => x.isCanceled ? { ...x, isCanceled: '취소됨' } : { ...x, isCanceled: '' }).map(x => { return { ...x, totalQty: x.sn.length + x.noSnQty } }) || []}
                    columns={header}
                    options={{
                        search: true,
                        keyCol: "code",
                        filterColumns: [
                            { col: "type", val: "ALL" },
                        ],
                        defaultSort: "date",
                        defaultSortType: -1,
                        hideListCount: true
                    }}
                    rowSelectCallback={rowSelect}
                />
            </div>

            <AddSparePartsHistoryModal
                _id={_id}
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                data={modifyData}
                AddHistory={(data) => AddHistory(data)}
                UpdateHistory={(_id, data) => UpdateHistory(_id, data)} />
        </>
    )
}

const AddSparePartsHistoryModal = ({ _id, showModal, closeModal, data, AddHistory, UpdateHistory }) => {
    const [mode, setMode] = useState('INSERT');
    const [inData, setInData] = useState({ _id, inOrOut: 'IN', date: new Date().getTime(), type: '새제품', sn: [], noSnQty: 0, memo: '', isCanceled: false });
    
    const handleChangeData = (e) => {
        setInData({ ...inData, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (data.hasOwnProperty('_id')) {
            setMode('UPDATE');
            setInData({
                _id: data._id,
                inOrOut: 'IN',
                date: data.date,
                type: data.type,                
                sn: data.sn,
                noSnQty: data.noSnQty,
                memo: data.memo,
                isCanceled: data.isCanceled
            });
        } else {
            setMode('INSERT');
        }
    }, [data]);
    
    return (
        <Modal show={showModal}>
            <Modal.Header>
                <h2 className='modal-title'>입고 내역 {mode == 'INSERT' ? '등록' : '수정'}</h2>
            </Modal.Header>
            <Modal.Body className='p-1'>
                <div className="rayform section">
                    <div className="listview simple-listview no-space border-0">
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label">일자</label>
                                <InputBasicDateTime
                                    options={{ name: 'date', onlyDate: true, dateFormat: 'yyyy-MM-dd', val: inData.date }}
                                    editable={true}
                                    onchange={handleChangeData} />                                
                            </div>
                        </div>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label">구분</label>
                                <InputBasicSelect
                                    options={{
                                        name: 'type',
                                        list: [{ code: '새제품', title: '새제품' }, { code: '수리품', title: '수리품' }],
                                        val: inData.type
                                    }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label">시리얼넘버</label>
                                <InputSelect2Add options={{ name: 'sn', val: inData.sn || []}}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label">시리얼넘버 미확인 수량</label>
                                <InputBasicNumber
                                    options={{ name: 'noSnQty', positive: true, val:inData.noSnQty }}
                                    editable={true}
                                    onchange={handleChangeData} />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label">메모</label>
                                <InputBasicText options={{ name: 'memo', val: inData.memo }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                        <div className="item nopadd">
                        <div className="input-wrapper">
                                <div className="label">취소여부</div>
                                <InputBasicCheckbox
                                    options={{ name: 'isCanceled', val: inData.isCanceled }}
                                    editable={true}
                                    onchange={handleChangeData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-block btn-success mb-0' onClick={() => {
                    if (mode == 'INSERT')
                        AddHistory(inData);
                    else
                        UpdateHistory(_id, inData);
                }}> {mode == 'INSERT' ? '등록' : '수정'}</button>
                <button className='btn btn-block btn-dark' onClick={() => { setInData({ _id, date: new Date().getTime(), inOrOut: 'IN' }); closeModal(); }}>닫기</button>
            </Modal.Footer>
        </Modal>
    )
}

const mapState = (state) => {
    const partsInfo = state.SparePartsReducer.sparePart;
    return { partsInfo };
};

const mapDispatch = (dispatch) => ({
    Update: (data) => dispatch(SparePartsAction.Update(data)),
    AddHistory: (data) => dispatch(SparePartsAction.AddHistory(data)),
    UpdateHistory: (_id, data) => dispatch(SparePartsAction.UpdateHistory(_id, data)),
    GetOne: (_id) => dispatch(SparePartsAction.GetOne(_id)),
});

export default connect(mapState, mapDispatch)(AdminSparepartsDetailIn);
