import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductPartAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { RayCodeAction } from '../../actions/RayCodeAction';
import { DeviceModels } from '../../config/RayCodes';
import { FileAddList } from '../../components/common/FileAddList';
import { RayDelButton } from '../../components/common/Buttons';
import { Translate as t } from '../../config/Translate';

const ProductPartAdd = ({ productpart, Update, Create, Delete, callback, clist }) => {
	const [errors, setErrors] = useState([]);
	const [isUp, setIsUp] = useState(false);
	const [isDel, setIsDel] = useState(false);
	const [item, setItem] = useState(productpart);
	const [options, setOptions] = useState([]);
	const [attachments, setAttachments] = useState([]);

	useEffect(() => {
		setCodes();
	}, [clist])

	const setCodes = async () => {
		var filterList = [];
		console.log('PRODUCTEXTRAKEY', clist?.filter(x => x.type === 'PRODUCTEXTRAKEY'));
		//var extradataList = await RayCodeAction.GetAllByFilter({ type: "PRODUCTEXTRAKEY" });
		var extradataList = clist?.filter(x => x.type === 'PRODUCTEXTRAKEY');
		extradataList.filter(x => filterList.push({ code: "extradata." + x.code, title: x.name }));
		setOptions(filterList);
	}

	useEffect(() => {
		if (isDel && !productpart._id) {
			setIsDel(false);
			callback();
		} else {
			setItem(productpart)
			productpart?.guides && setAttachments(productpart.guides);
		}
	}, [productpart])

	const form_controls = [
		{
			title: '',
			items: [
				{ type: 'input', name: 'type', label: t('type'), val: item.type, required: true, isEditable: true },
				{
					type: "select2", name: "models", label: t('model'), size: 6, isMulti: "Y",
					val: item.models,
					list: DeviceModels.map(x => { return { code: x.code, title: x.title } }),
					isMulti: true,
					required: true,
					isEditable: true
				},
				{ type: 'input', name: 'code', label: t('code'), val: item.code, required: true, isEditable: true },
				{ type: 'input', name: 'name', label: t('name'), val: item.name, required: true, isEditable: true },
				{
					type: "select2", name: "productextrakey", label: 'Related product key', size: 6,
					val: item.productextrakey,
					list: options,
					isEditable: true
				},
				{ type: 'input', name: 'platformInfo', label: t('platform'), val: item.platformInfo, isEditable: true },
				{ type: "checkbox", name: "eos", label: "EOS", val: item.eos || false, isEditable: true },
			],
		},
	];

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	};

	const validAdd = () => {
		console.log("validAdd : ===", item);
		const errValidate = RayFormValidation(form_controls, item);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsUp(true);
			//Add({ ...newErrorCode, attachments: attachments });
			Create({...item, guides: attachments});
		}
	};

	const del = () => {
		setIsDel(true);
		Delete(item._id);
	}

	const filelist = (list) => {
		if (productpart._id) {
			Update(productpart._id, { guides: list });
		} else {
			setItem({ ...item, ["guides"]: list });
		}
	};

	const setNewFile = (files) => {
		console.log("setNewFile", files);
		const addNewFile = files.find(f => !f._id) || false;
		if (productpart?._id) {
			if (addNewFile) {
				Update(productpart._id, {name: addNewFile?.name, path: addNewFile?.path}, "attachment");
			}
			Update(productpart._id, { guides: files });
		} else {
			setAttachments([...files]);
		}
    };

	return (
		<>
			{productpart._id && <RayForm controls={form_controls} action={Update} actionkey={productpart._id} />}
			{!productpart._id && <RayForm controls={form_controls} isForm={true} handleChangeData={handleChangeData} errors={errors} />}
			<div className="section">
				<div className="section-title">
					{t('attachments')}
				</div>
				<FileAddList callback={setNewFile} isForm={true} basepath="docs/productpart/" itemlist={attachments} />
				{!productpart._id && <button className="btn btn-primary" onClick={() => validAdd()}>{t('add')}</button>}
				{productpart._id && <RayDelButton callback={() => del()} />}
				<button className="btn btn-default" onClick={() => callback()}>{t('cancel')}</button>
			</div>
		</>
	)
};

const mapState = (state) => {
	const productpart = state.ProductPartReducer.item;
	const clist = state.RayCodeReducer.items;
	return { productpart, clist };
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data, type) => dispatch(ProductPartAction.Update(_id, data, type)),
	Delete: (_id) => dispatch(ProductPartAction.Delete(_id)),
	Create: (data) => dispatch(ProductPartAction.Create(data)),
});

export default connect(mapState, mapDispatch)(ProductPartAdd);
