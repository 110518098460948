import { history } from '../../history';
import { connect } from 'react-redux';
import { RayTable } from '../../components/common';
import { AdminSparePartsStockHeader } from '../../components/common/TableHeader';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { InputBasicNumber, InputSelect2 } from '../../components/common/Inputs';

const AdminSparePartsCompany = ({ allParts, companyStockList, orderList, GetAllPartsList, CompanyStockList, RegistCompanyStock, UpdateCompanyStock, OrderList }) => {
    const [showModal, setShowModal] = useState(false);
    const [sparePartsList, setSparePartsList] = useState([]);
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});

    const companyId = JSON.parse(localStorage.getItem('user')).companyId;

    useEffect(() => {
        GetAllPartsList();
        OrderList(companyId);
        CompanyStockList(companyId);
    }, []);

    useEffect(() => {
        if (showModal) {
            setSelected({});
            setShowModal(false);
        }

        setList(allParts.map(x => {
            const stock = companyStockList.find(y => y.serviceCode === x.serviceCode);

            let orderCount = 0;
            const order = orderList.filter(x => x.status !== 'RECEIVE' && x.status !== 'CANCEL');
            order.forEach(orders => {
                orders.orderList.forEach(ol => {
                    if (ol.partsId === x._id)
                        orderCount += ol.quantity;
                })
            });

            let receiveCount = 0;
            const receive = orderList.filter(x => x.status === 'RECEIVE');
            receive.forEach(orders => {
                orders.orderList.forEach(ol => {
                    if (ol.partsId === x._id)
                        receiveCount += ol.quantity;
                })
            });

            const used = stock?.outHistory?.reduce((a, b) => { return a + parseInt(b.quantity) }, 0) || 0;
            const amount = receiveCount - used;

            return {
                id: x._id,
                model: x.model,
                type: x.type,
                serviceCode: x.serviceCode,
                servicePartName: x.servicePartName,
                section: x.section,
                orderCount,
                receiveCount,
                used,
                amount
            }
        }));
    }, [allParts, companyStockList]);

    useEffect(() => {
        const exists = [];
        setSparePartsList(allParts.filter(x => !exists.includes(x.serviceCode)).map(x => { return { code: x.serviceCode, title: x.servicePartName } }));
    }, [allParts, companyStockList]);

    const options = {
        search: true,
        keyCol: "code",        
        filterColumns: [
            { col: 'model', val: 'ALL' },
            { col: 'section', val: 'ALL' },
            { col: 'type', val: 'ALL' },
        ],
        defaultSort: { section: 1 },
        defaultSortType: 1,
        hideListCount: true
    }

    const rowSelect = (item) => {
        history.push(`/Admin/AdminSparePartsCompanyDetail/${item.id}`);
    }

    const closeModal = () => {
        setSelected({});
        setShowModal(false);
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Spare Parts</h2>
                <div className="ray-right">
                    <button className="btn btn-primary btn-sm mx-1" onClick={() => history.push('/Admin/AdminSparePartsOrderFree')}>
                        <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon> Order (Free)
                    </button>

                    <button className="btn btn-primary btn-sm mx-1" onClick={() => history.push('/Admin/AdminSparePartsOrder')}>
                        <ion-icon name="add-outline" role="img" className="md hydrated" aria-label="add outline"></ion-icon> Order (Charge)
                    </button>

                    <button className="btn btn-primary btn-sm" onClick={() => history.push('/Admin/AdminSparePartsOrderList')}>
                        <ion-icon name="cart-outline" role="img" className="md hydrated" aria-label="cart outline"></ion-icon> Order List
                    </button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1">
                    <div className="wide-block pt-2 pb-2">
                        <div>
                            <RayTable
                                data={list}
                                columns={AdminSparePartsStockHeader}
                                options={options}
                                rowSelectCallback={(item) => rowSelect(item)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ManualManageModal
                companyId={companyId}
                showModal={showModal}
                closeModal={closeModal}
                sparePartsList={sparePartsList}
                selectedItem={selected}
                regist={RegistCompanyStock}
                update={UpdateCompanyStock}
            />
        </>
    )
}

const ManualManageModal = ({ companyId, showModal, closeModal, sparePartsList, selectedItem, regist, update }) => {
    const [data, setData] = useState({ companyId });
    const [mode, setMode] = useState('');

    useEffect(() => {
        if (selectedItem.hasOwnProperty('serviceCode')) {
            setMode('Modify');
            setData({ ...data, serviceCode: selectedItem.serviceCode, havingCount: selectedItem.havingCount });
        } else {
            setMode('Regist');
            setData({ companyId });
        }
    }, [selectedItem]);

    const handleChangeData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    return (
        <Modal show={showModal}>
            <Modal.Header>
                <h2 className='modal-title'>{mode}</h2>
            </Modal.Header>
            <Modal.Body className='p-1'>
                <div className="rayform section">
                    <div className="listview simple-listview no-space border-0">
                        <div className="form-group mt-1">
                            <InputSelect2
                                options={{
                                    name: 'serviceCode',
                                    label: 'Spare Parts',
                                    val: data.serviceCode,
                                    list: sparePartsList,
                                }}
                                editable={mode == "Regist" ? true : false}
                                onchange={handleChangeData} />
                        </div>
                    </div>

                    <div className="listview simple-listview no-space border-0">
                        <div className="form-group mt-1">
                            <InputBasicNumber 
                            options={{ name: 'havingCount', label: 'Count', val: data.havingCount }} 
                            editable={true} 
                            onchange={handleChangeData}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-block btn-success mb-0' onClick={() => mode == 'Regist' ? regist(data) : update(data)}>{mode}</button>
                <button className='btn btn-block btn-dark' onClick={() => closeModal()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

const mapState = (state) => {
    const allParts = state.SparePartsReducer.spareParts;
    const companyStockList = state.SparePartsReducer.companyStockList;
    const orderList = state.SparePartsReducer.orderList
    return { allParts, companyStockList, orderList };
};

const mapDispatch = (dispatch) => ({
    GetAllPartsList: () => dispatch(SparePartsAction.GetList({})),
    CompanyStockList: (companyId) => dispatch(SparePartsAction.CompanyStockList(companyId)),
    RegistCompanyStock: (data) => dispatch(SparePartsAction.RegistCompanyStock(data)),
    UpdateCompanyStock: (data) => dispatch(SparePartsAction.UpdateCompanyStock(data)),
    OrderList: (companyId) => dispatch(SparePartsAction.OrderList(companyId))
});

export default connect(mapState, mapDispatch)(AdminSparePartsCompany);
