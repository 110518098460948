import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './lang/lang.en.json';
import translationDE from './lang/lang.de.json';
import translationES from './lang/lang.es.json';
import translationJA from './lang/lang.ja.json';
import translationKO from './lang/lang.ko.json';
import translationHANS from './lang/lang.zh-hans.json';
import translationHANT from './lang/lang.zh-hant.json';
import translationFR from './lang/lang.fr.json';

const resources = {
    eng: { translation: translationEN },
    deu: { translation: translationDE },
    spa: { translation: translationES },
    jpn: { translation: translationJA },
    kor: { translation: translationKO },
    cmn: { translation: translationHANS },
    zhh: { translation: translationHANT },
    fre: { translation: translationFR}
};
i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "eng",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
});

export default i18n;