import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBasicText, InputBasicTextArea, InputSelect2 } from '../../components/common/Inputs';
import { CompanyAction, ProductAction } from '../../actions';
import { history } from '../../history';
import {
    ProductInfoSite
} from '../../components/product'

const ProductChangeSN = ({ user, GetProduct, site, product, companyList }) => {

    const [data, setData] = useState({
        newsn: "",
        nowsn: product.sn,
        companyId: product.companyId,
        reason: "",
    });
    const [companies, setCompanies] = useState([]);
    const [ready, setReady] = useState(false);
    const [err, setErr] = useState("");

    useEffect(() => {
        if (companyList.length > 0)
            setCompanySelect();
    }, [companyList]);

    const setCompanySelect = () => {
        const cList = [];
        companyList.map((c) => cList.push({ code: c.companyId, title: c.name }));
        setCompanies(cList);
    }

    useEffect(() => {
        setReady(false);
        //GetCompany();
    }, []);

    useEffect(() => {
        setReady(true);
    }, [site]);

    const changeSn = async () => {
        var ret = await ProductAction.ChangeSN(product._id, { ...data, newsn: data.newsn.toUpperCase().trim() });
        if (ret.data.status == 'success') {
            GetProduct(ret.data.data.sn)
            history.push("/Admin/AdminProductInfo/" + ret.data.data.sn);
        } else {
            if (ret.data.errmsg == "ER0091") {
                setErr("Already Exist - New SN : " + data.newsn);
            }
        }
    }

    return (
        <>
            {product.siteId && (
                <>
                    <div className="section mt-2 mb-2">
                        <div className="card bg-danger text-white">
                            <div className="card-header">
                                <div className="card-title">경고!</div>
                            </div>
                            <div className="card-body">
                                <h4 className="text-white">아래의 Site에 설치된 장비입니다. SN 변경전에 Site에서 Uninstall 해주시기 바랍니다.</h4>
                            </div>
                        </div>
                    </div>
                    <ProductInfoSite product={product} showtitle={false} />
                </>
            )}
            {!product.siteId && (
                <>
                    <div className="section mt-2 mb-2">
                        {err && <div className="mb-1"><span className="alert alert-danger">변경하려는 SN은 이미 존재하는 SN 입니다.</span></div>}
                        <div className="card bg-danger text-white">
                            <div className="card-header">
                                <div className="card-title">경고!</div>
                            </div>
                            <div className="card-body">
                                <h4 className="text-white">즉시 반영됩니다!!!</h4>
                                <div className="text-white">
                                    <p className="text-white">이 작업은 되돌릴 수 없는 작업입니다. 변경되면 HQ 멤버만 변경전의 SN으로 열람할 수 있습니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="card">
                            <div className="card-body">

                                <InputBasicText
                                    options={{
                                        name: 'newsn',
                                        label: 'NEW SN',
                                        size: 12,
                                        val: data.newsn,
                                        required: true
                                    }}
                                    onchange={(e) => setData({ ...data, newsn: e.target.value })}
                                    editable={true} />

                                <InputSelect2
                                    options={{
                                        name: "companyId",
                                        label: "Transfer Company",
                                        val: data.companyId,
                                        list: companies,
                                    }}
                                    onchange={(e) => setData({ ...data, companyId: e.target.value })}
                                    editable={true} />

                                <InputBasicTextArea
                                    options={{
                                        name: 'reason',
                                        label: 'Reason',
                                        val: data.reason,
                                        required: true
                                    }}
                                    onchange={(e) => setData({ ...data, reason: e.target.value })}
                                    editable={true} />
                            </div>
                        </div>
                        {(data.newsn && data.reason) && <button className="btn btn-outline-danger" onClick={() => changeSn()}>Change SN</button>}
                        {!(data.newsn && data.reason) && <button className="btn btn-outline-danger disabled" onClick={() => changeSn()}>Change SN</button>}
                    </div>
                </>
            )}
        </>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.ProductReducer.isUpdateRes;
    const companyList = state.CompanyReducer.companies;
    const site = state.SiteReducer.site;
    const user = state.AuthReducer.user;
    return { site, user, isUpdateRes, companyList };
}

const mapDispatch = dispatch => ({
    //GetCompany: () => dispatch(CompanyAction.GetCompany()),
    GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
    //ClearUpdateResult : () => dispatch(ProductAction.ClearUpdateResult()),
    //DeleteProduct : (_id) => dispatch(ProductAction.DeleteProduct(_id))
})

export default connect(mapState, mapDispatch)(ProductChangeSN);