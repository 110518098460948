import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PickImage, UploadImage } from '../../components/common/Camera';
import { InstallationAction, ErrorCodeAction, ProductAction } from '../../actions';
import { FileAddList } from '../../components/common/FileAddList';
import { FileUpload, FileItem } from '../../components/common/FileUpload';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const InstallationStep4 = ({ info, Update, isSummary = false, Attachment }) => {
	// const [ t, i18n ] = useTranslation();
	const [installation, setInstallation] = useState(info);
	const [pano, setPano] = useState(installation.imgQuality.filter(x => x.type == "pano") || []);
	const [ct, setCt] = useState(installation.imgQuality.filter(x => x.type == "ct") || []);
	const [ceph, setCepth] = useState(installation.imgQuality.filter(x => x.type == "ceph") || []);

	useEffect(() => {
		setInstallation(info);
	}, [info]);

	const Prev = () => {
		Update(installation._id, { installationStep: 4 });
	};

	const validNext = () => {
		var img = pano.concat(ct);
		img = img.concat(ceph);
		Update(installation._id, {
			installationStep: 6,
			imgQuality: img
		});
	};

	const setNewFile = (file, fileType) => {
		Attachment(installation._id, "ADD", {
			...installation,
			name: file.original,
			path: file.s3path,
			attachmentType: fileType
		});
	};

	const delFile = (c) => {
		Attachment(installation._id, "DEL", {
			...installation,
			delAttachid: c._id
		});
	};

	return (
		<>
			<div className={isSummary ? "pb-1" : "section"}>
				{!isSummary &&
					<>
						<div className="section-title">{t('imagequality')}</div>
						<blockquote className="blockquote">
							<p class="mb-0">{t('imagequalitycomment')}</p>
						</blockquote>
					</>
				}
				<div className={isSummary ? "" : "card mb-2"}>
					<div className={isSummary ? "section-title bdB2" : "card-header"}>{t('panoramic')}</div>
					{installation.attachments.filter(item => 'pano' == item.type).length == 0 && (isSummary == false ? <img src='/assets/img/panoramic.jfif' className="w-100 ml-0" /> : <i className="p-2">Not exist.</i>)}
					<ul className="mb-0">
						{installation.attachments.map((p, idx) =>
							p.type == "pano" &&
							<div>
								<FileItem fileInfo={{ ...p, original: p.name, s3path: p.path }} callback={(p) => delFile(p)} key={idx} />
							</div>
						)}
					</ul>
					{!isSummary &&
						<div className="card-footer">
							<FileUpload
								option={{ doneandnew: true, label: t('upload') }}
								extraData={{ path: "installation/" + installation._id + "/" }}
								callback={(e) => setNewFile(e, 'pano')} />
						</div>
					}
				</div>
				<div className={isSummary ? "" : "card mb-2"}>
					<div className={isSummary ? "section-title bdB2" : "card-header"}>{t('cbct')}</div>
					{installation.attachments.filter(item => 'ct' == item.type).length == 0 && (isSummary == false ? <img src='/assets/img/cbct.jfif' className="w-100 ml-0" /> : <i className="p-2">Not exist.</i>)}
					<ul className="mb-0">
						{installation.attachments.map((x, idx) =>
							x.type == "ct" &&
							<div>
								<FileItem fileInfo={{ ...x, original: x.name, s3path: x.path }} callback={(x) => delFile(x)} key={idx} />
							</div>
						)}
					</ul>
					{!isSummary &&
						<div className="card-footer">
							<FileUpload
								option={{ doneandnew: true, label: t('upload') }}
								extraData={{ path: "installation/" + installation._id + "/" }}
								callback={(e) => setNewFile(e, 'ct')} />
						</div>
					}
				</div>
				<div className={isSummary ? "" : "card mb-2"}>
					<div className={isSummary ? "section-title bdB2" : "card-header"}>{t('cephalometric')}</div>
					{installation.attachments.filter(item => 'ceph' == item.type).length == 0 && (isSummary == false ? <img src='/assets/img/cephalometric.jfif' className="w-100 ml-0" /> : <i className="p-2">Not exist.</i>)}
					<ul className="mb-0">
						{installation.attachments.map((c, idx) =>
							c.type == "ceph" &&
							<div>
								<FileItem fileInfo={{ ...c, original: c.name, s3path: c.path }} callback={(c) => delFile(c)} key={idx} />
							</div>
						)}
					</ul>
					{!isSummary &&
						<div className="card-footer">
							<FileUpload
								option={{ doneandnew: true, label: t('upload') }}
								extraData={{ path: "installation/" + installation._id + "/" }}
								callback={(e) => setNewFile(e, 'ceph')} />
						</div>
					}
				</div>
			</div>
			{!isSummary && <div className="section mt-1 mb-2">
				<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
				<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
			</div>}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	Attachment: (_id, type, data) => dispatch(InstallationAction.Attachment(_id, type, data)),
});

export default connect(null, mapDispatch)(InstallationStep4);