export const CountryCode = [
    {code : "AF", title : "Afghanistan" },
    {code : "AL", title : "Albania" },
    {code : "DZ", title : "Algeria" },
    {code : "AS", title : "American Samoa" },
    {code : "AD", title : "Andorra" },
    {code : "AO", title : "Angola" },
    {code : "AI", title : "Anguilla" },
    {code : "AQ", title : "Antarctica" },
    {code : "AG", title : "Antigua And Barbuda" },
    {code : "AR", title : "Argentina" },
    {code : "AM", title : "Armenia" },
    {code : "AW", title : "Aruba" },
    {code : "AU", title : "Australia" },
    {code : "AT", title : "Austria" },
    {code : "AZ", title : "Azerbaijan" },
    {code : "BS", title : "Bahamas" },
    {code : "BH", title : "Bahrain" },
    {code : "BD", title : "Bangladesh" },
    {code : "BB", title : "Barbados" },
    {code : "BY", title : "Belarus" },
    {code : "BE", title : "Belgium" },
    {code : "BZ", title : "Belize" },
    {code : "BJ", title : "Benin" },
    {code : "BM", title : "Bermuda" },
    {code : "BT", title : "Bhutan" },
    {code : "BO", title : "Bolivia" },
    {code : "BA", title : "Bosnia And Herzegowina" },
    {code : "BW", title : "Botswana" },
    {code : "BV", title : "Bouvet Island" },
    {code : "BR", title : "Brazil" },
    {code : "IO", title : "British Indian Ocean Territory" },
    {code : "BN", title : "Brunei Darussalam" },
    {code : "BG", title : "Bulgaria" },
    {code : "BF", title : "Burkina Faso" },
    {code : "BI", title : "Burundi" },
    {code : "KH", title : "Cambodia" },
    {code : "CM", title : "Cameroon" },
    {code : "CA", title : "Canada" },
    {code : "CV", title : "Cape Verde" },
    {code : "KY", title : "Cayman Islands" },
    {code : "CF", title : "Central African Republic" },
    {code : "TD", title : "Chad" },
    {code : "CL", title : "Chile" },
    {code : "CN", title : "China" },
    {code : "CX", title : "Christmas Island" },
    {code : "CC", title : "Cocos (Keeling) Islands" },
    {code : "CO", title : "Colombia" },
    {code : "KM", title : "Comoros" },
    {code : "CD", title : "Congo, Democratic Republic Of (Was Zaire)" },
    {code : "CG", title : "Congo, People'S Republic Of" },
    {code : "CK", title : "Cook Islands" },
    {code : "CR", title : "Costa Rica" },
    {code : "CI", title : "Cote D'Ivoire" },
    {code : "HR", title : "Croatia" },
    {code : "CU", title : "Cuba" },
    {code : "CY", title : "Cyprus" },
    {code : "CZ", title : "Czech Republic" },
    {code : "DK", title : "Denmark" },
    {code : "DJ", title : "Djibouti" },
    {code : "DM", title : "Dominica" },
    {code : "DO", title : "Dominican Republic" },
    {code : "TP", title : "East Timor" },
    {code : "EC", title : "Ecuador" },
    {code : "EG", title : "Egypt" },
    {code : "SV", title : "El Salvador" },
    {code : "GQ", title : "Equatorial Guinea" },
    {code : "ER", title : "Eritrea" },
    {code : "EE", title : "Estonia" },
    {code : "ET", title : "Ethiopia" },
    {code : "FK", title : "Falkland Islands (Malvinas)" },
    {code : "FO", title : "Faroe Islands" },
    {code : "FJ", title : "Fiji" },
    {code : "FI", title : "Finland" },
    {code : "FR", title : "France" },
    {code : "FX", title : "France, Metropolitan" },
    {code : "FG", title : "French Guiana" },
    {code : "PF", title : "French Polynesia" },
    {code : "TF", title : "French Southern Territories" },
    {code : "GA", title : "Gabon" },
    {code : "GM", title : "Gambia" },
    {code : "GE", title : "Georgia" },
    {code : "DE", title : "Germany" },
    {code : "GH", title : "Ghana" },
    {code : "GI", title : "Gibraltar" },
    {code : "GR", title : "Greece" },
    {code : "GL", title : "Greenland" },
    {code : "GD", title : "Grenada" },
    {code : "GP", title : "Guadeloupe" },
    {code : "GU", title : "Guam" },
    {code : "GT", title : "Guatemala" },
    {code : "GN", title : "Guinea" },
    {code : "GW", title : "Guinea-Bissau" },
    {code : "GY", title : "Guyana" },
    {code : "HT", title : "Haiti" },
    {code : "HM", title : "Heard And Mc Donald Islands" },
    {code : "HN", title : "Honduras" },
    {code : "HK", title : "Hong Kong" },
    {code : "HU", title : "Hungary" },
    {code : "IS", title : "Iceland" },
    {code : "IN", title : "India" },
    {code : "ID", title : "Indonesia" },
    {code : "IR", title : "Iran (Islamic Republic Of)" },
    {code : "IQ", title : "Iraq" },
    {code : "IE", title : "Ireland" },
    {code : "IL", title : "Israel" },
    {code : "IT", title : "Italy" },
    {code : "JM", title : "Jamaica" },
    {code : "JP", title : "Japan" },
    {code : "JO", title : "Jordan" },
    {code : "KZ", title : "Kazakhstan" },
    {code : "KE", title : "Kenya" },
    {code : "KI", title : "Kiribati" },
    {code : "KP", title : "Korea, Democratic People'S Republic Of" },
    {code : "KR", title : "Korea" },
    {code : "KW", title : "Kuwait" },
    {code : "KG", title : "Kyrgyzstan" },
    {code : "LA", title : "Lao People'S Democratic Republic" },
    {code : "LV", title : "Latvia" },
    {code : "LB", title : "Lebanon" },
    {code : "LS", title : "Lesotho" },
    {code : "LR", title : "Liberia" },
    {code : "LY", title : "Libyan Arab Jamahiriya" },
    {code : "LI", title : "Liechtenstein" },
    {code : "LT", title : "Lithuania" },
    {code : "LU", title : "Luxembourg" },
    {code : "MO", title : "Macau" },
    {code : "MK", title : "Macedonia, The Former Yugoslav Republic Of" },
    {code : "MG", title : "Madagascar" },
    {code : "MW", title : "Malawi" },
    {code : "MY", title : "Malaysia" },
    {code : "MV", title : "Maldives" },
    {code : "ML", title : "Mali" },
    {code : "MT", title : "Malta" },
    {code : "MH", title : "Marshall Islands" },
    {code : "MQ", title : "Martinique" },
    {code : "MR", title : "Mauritania" },
    {code : "MU", title : "Mauritius" },
    {code : "YT", title : "Mayotte" },
    {code : "MX", title : "Mexico" },
    {code : "FM", title : "Micronesia, Federated States Of" },
    {code : "MD", title : "Moldova, Republic Of" },
    {code : "MC", title : "Monaco" },
    {code : "MN", title : "Mongolia" },
    {code : "MS", title : "Montserrat" },
    {code : "MA", title : "Morocco" },
    {code : "MZ", title : "Mozambique" },
    {code : "MM", title : "Myanmar" },
    {code : "NA", title : "Namibia" },
    {code : "NR", title : "Nauru" },
    {code : "NP", title : "Nepal" },
    {code : "NL", title : "Netherlands" },
    {code : "AN", title : "Netherlands Antilles" },
    {code : "NC", title : "New Caledonia" },
    {code : "NZ", title : "New Zealand" },
    {code : "NI", title : "Nicaragua" },
    {code : "NE", title : "Niger" },
    {code : "NG", title : "Nigeria" },
    {code : "NU", title : "Niue" },
    {code : "NF", title : "Norfolk Island" },
    {code : "MP", title : "Northern Mariana Islands" },
    {code : "NO", title : "Norway" },
    {code : "OM", title : "Oman" },
    {code : "PK", title : "Pakistan" },
    {code : "PW", title : "Palau" },
    {code : "PS", title : "Palestinian Territory, Occupied" },
    {code : "PA", title : "Panama" },
    {code : "PG", title : "Papua New Guinea" },
    {code : "PY", title : "Paraguay" },
    {code : "PE", title : "Peru" },
    {code : "PH", title : "Philippines" },
    {code : "PN", title : "Pitcairn" },
    {code : "PL", title : "Poland" },
    {code : "PT", title : "Portugal" },
    {code : "PR", title : "Puerto Rico" },
    {code : "QA", title : "Qatar" },
    {code : "RE", title : "Reunion" },
    {code : "RO", title : "Romania" },
    {code : "RU", title : "Russian Federation" },
    {code : "RW", title : "Rwanda" },
    {code : "KN", title : "Saint Kitts And Nevis" },
    {code : "LC", title : "Saint Lucia" },
    {code : "VC", title : "Saint Vincent And The Grenadines" },
    {code : "WS", title : "Samoa" },
    {code : "SM", title : "San Marino" },
    {code : "ST", title : "Sao Tome And Principe" },
    {code : "SA", title : "Saudi Arabia" },
    {code : "SN", title : "Senegal" },
    {code : "RS", title : "Serbia" },
    {code : "SC", title : "Seychelles" },
    {code : "SL", title : "Sierra Leone" },
    {code : "SG", title : "Singapore" },
    {code : "SK", title : "Slovakia (Slovak Republic)" },
    {code : "SI", title : "Slovenia" },
    {code : "SB", title : "Solomon Islands" },
    {code : "SO", title : "Somalia" },
    {code : "ZA", title : "South Africa" },
    {code : "GS", title : "South Georgia And The South Sandwich Islands" },
    {code : "ES", title : "Spain" },
    {code : "LK", title : "Sri Lanka" },
    {code : "SH", title : "St. Helena" },
    {code : "PM", title : "St. Pierre And Miquelon" },
    {code : "SD", title : "Sudan" },
    {code : "SR", title : "Suriname" },
    {code : "SJ", title : "Svalbard And Jan Mayen Islands" },
    {code : "SZ", title : "Swaziland" },
    {code : "SE", title : "Sweden" },
    {code : "CH", title : "Switzerland" },
    {code : "SY", title : "Syrian Arab Republic" },
    {code : "TW", title : "Taiwan" },
    {code : "TJ", title : "Tajikistan" },
    {code : "TZ", title : "Tanzania, United Republic Of" },
    {code : "TH", title : "Thailand" },
    {code : "TG", title : "Togo" },
    {code : "TK", title : "Tokelau" },
    {code : "TO", title : "Tonga" },
    {code : "TT", title : "Trinidad And Tobago" },
    {code : "TN", title : "Tunisia" },
    {code : "TR", title : "Turkey" },
    {code : "TM", title : "Turkmenistan" },
    {code : "TC", title : "Turks And Caicos Islands" },
    {code : "TV", title : "Tuvalu" },
    {code : "UG", title : "Uganda" },
    {code : "UA", title : "Ukraine" },
    {code : "AE", title : "United Arab Emirates" },
    {code : "UK", title : "United Kingdom" },
    {code : "UM", title : "United States Minor Outlying Islands" },
    {code : "UY", title : "Uruguay" },
    {code : "US", title : "USA" },
    {code : "UZ", title : "Uzbekistan" },
    {code : "VU", title : "Vanuatu" },
    {code : "VA", title : "Vatican City State (Holy See)" },
    {code : "VE", title : "Venezuela" },
    {code : "VN", title : "Viet Nam" },
    {code : "VG", title : "Virgin Islands (British)" },
    {code : "WF", title : "Wallis And Futuna Islands" },
    {code : "YE", title : "Yemen" },
    {code : "YS", title : "Yugoslavia" },
    {code : "ZM", title : "Zambia" },
    {code : "ZW", title : "Zimbabwe" }
]