import React, { useState, useEffect } from 'react';
import { history } from '../../history';
import { Link } from 'react-router-dom';
import { LoginFooter } from '../../components/common/CommonViews';
import { AuthAction, TranslateAction } from './../../actions';
import { connect } from 'react-redux';
import '../../asset/css/login.css';

const LoginPage = ({ user, loggingIn, isAdmin, login, logout }) => {
	const [inputs, setInputs] = useState({ username: '', password: '' });	
	const { username, password } = inputs;
	const siteType = process.env.NODE_ENV;

	useEffect(() => {
		if (loggingIn) {
			if (user.rguardSettingLanguage) {
				TranslateAction.SearchByLanguageDirect(user.rguardSettingLanguage).then(translate => {
					localStorage.setItem('translate', JSON.stringify(translate));
				});
			}
			if (user.isIubenda) {
				if (localStorage.getItem('mailLink')) {
					const mailLink = localStorage.getItem('mailLink');
					localStorage.removeItem('mailLink');
					history.push(mailLink);
				} else {
					history.push('/Home');
				}
			} else {
				// iubenda 동의 페이지 이동
				logout();
				history.push('/IubendaLogin');
			}
		}		
	}, [loggingIn]);

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	}

	function handleSubmit(e) {		
		e.preventDefault();
		if (username && password) {
			login(username, password);
		}
	}

	return (
		<>
			<div className="login">
				<div className="appHeader no-border transparent position-absolute">
					<div className="left"> </div>
					<div className="pageTitle"></div>
				</div>
				<div id="appCapsule" className="no-bottom">
					<div className="section mt-5 text-center">
						<h1>RAYGuard <span>Tech</span></h1>
						{/* {siteType != "production" && <h2 className="text-primary mt--1">DEVELOPMENT</h2>} */}
						<p>Sign in with your RAYGuard Tech account</p>
					</div>
					<div className="section mb-5 p-2">
						<form>
							<div className="card">
								<div className="card-body pb-1">
									<div className="form-group basic">
										<div className="input-wrapper">
											<label className="label">ID</label>
											<input
												type="text"
												className="form-control input-md"
												name="username"
												value={username}
												onChange={handleChange} />
											<i className="clear-input">
												<ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
											</i>
										</div>
									</div>
									<div className="form-group basic">
										<div className="input-wrapper">
											<label className="label">Password</label>
											<input
												type="password"
												className="form-control input-md"
												name="password"
												value={password}
												onChange={handleChange} />
											<i className="clear-input">
												<ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
											</i>
										</div>
									</div>

								</div>

							</div>
							<div className="mt-2 transparent">
								<button
									type="submit"
									className="btn btn-primary btn-block waves-effect waves-classic"
									onClick={handleSubmit} >Sign in</button>
							</div>
						</form>
						<div className='d-flex justify-content-between mt-2'>
							<div>
								<input type='checkbox'></input>&nbsp; Keep me signed in
							</div>
							<div >
								<Link to="/ForgotPassword" className="text-secondary">Forgot Your Password?</Link>
							</div>
						</div>
					</div>
					<LoginFooter />
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	return {
		loggingIn: state.AuthReducer.loggingIn,
		user: state.AuthReducer.user,
	};
};

const mapDispatch = (dispatch) => ({
	login: (username, password) => dispatch(AuthAction.Login(username, password)),
	logout: () => dispatch(AuthAction.Logout()),
});

export default connect(mapState, mapDispatch)(LoginPage);
