exports.ValidateEmail = ( email ) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
exports.ValidateTel = ( tel ) => {
    const re = /^(?=.*[0-9])[- +()0-9]+$/;
    return re.test(String(tel).toLowerCase());
}
exports.ValidatePasswordStrength = (password) => {
    let strength = new RegExp('^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$')
    return strength.test(password);
}