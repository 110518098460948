import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { CustomFieldAction } from '../../actions/CustomFieldAction';
import {
	InputBasicText,
	InputBasicTextArea,
	InputSelect2
} from "../../components/common/Inputs";
import { RayDelButton } from './Buttons';
import { Translate as t } from '../../config/Translate';

const CustomFieldModal = ({ item, codeList, data, callbackClose, showModal, Update, Create, Delete }) => {

	const [request, setRequest] = useState(false);
	const [editable, setEditable] = useState(true);
	const [errors, setErrors] = useState([]);
	const [info, setInfo] = useState({ listvals: [] });
	const [addListItem, setAddListItem] = useState("");
	const [isNew, setIsNew] = useState(true);

	useEffect(() => {
		setErrors([]);
		setIsNew(!data.hasOwnProperty("_id"));
		if (!data.hasOwnProperty("_id")) {
			setInfo({
				title: "",
				listvals: [],
				group: [],
				valuetype: "STRING",
				description: "",
				title: "",
			});
		} else {
			setInfo(data);
		}
	}, [data])

	useEffect(() => {
		if (request) {
			setRequest(false);
			callbackClose();
		}
	}, [item])

	const fieldTypes = [
		{ code: "STRING", title: "STRING" },
		{ code: "FIXLIST", title: "LIST" },
		{ code: "DATETIME", title: "DATE" },
		{ code: "BOOLEAN", title: "BOLLEAN" },
		{ code: "NUMBER", title: "Number" }
	]
	const groupTypes = [
		{ code: "PRODUCT", title: t('product') },
		{ code: "SERVICE", title: t('service') },
		{ code: "SITE", title: t('site') },
		{ code: "USER", title: t('user') }
	]

	const handleSubmit = (isAdd) => {
		setRequest(true);
		if (info._id) {
			Update(info._id, info);
		} else {
			Create(info);
		}
	}

	const updateVal = (e) => {
		const { name, value } = e.target;
		setInfo({ ...info, [name]: value })
	}

	const submitEnable = () => {
		return (!request && info.title && info.group && info.valuetype);
	}

	const updateAddNewListItem = (e) => {
		if (e.key == "Enter") {
			if (info.listvals) {
				setInfo({ ...info, listvals: [...info.listvals, addListItem] })
			} else {
				setInfo({ ...info, listvals: [addListItem] })
			}
			setAddListItem("");
		}
	}

	const delListItem = (idx) => {
		info.listvals.splice(idx, 1);
		setInfo({ ...info, listvals: info.listvals })
	}

	const removeCF = () => {
		Delete(info._id);
		callbackClose();
	}

	return (
		<Modal show={showModal} onHide={() => callbackClose()} size="lg">
			<Modal.Header>
				<h2 className="modal-title">{isNew ? "Add Custom Field" : "Update Custom Field - " + info.title}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<div className='p-2'>
					<InputSelect2 options={{
						type: "select",
						name: "valuetype",
						label: "Field Type",
						val: info && info.valuetype,
						list: fieldTypes,
						required: true
					}} onchange={updateVal} editable={isNew} />
					{info.valuetype == "FIXLIST" && <div className='form-group basic'>
						<div className='input-wrapper'>
							<label className='label'>Add Item</label>
							<input type="text" className='form-control from-control-sm mb-1'
								value={addListItem}
								onChange={(e) => setAddListItem(e.target.value)}
								onKeyPress={updateAddNewListItem} />
							<ul className='list-group'>
								{info.listvals && info.listvals.map((x, idx) => {
									return <li className='list-group-item' key={x + idx}>
										{x}
										<button className='btn btn-sm btn-danger float-end mt-0' onClick={() => delListItem(idx)}>Del</button>
									</li>
								})}
							</ul>
						</div>
					</div>}
					<InputSelect2 options={{
						type: "select",
						name: "group",
						label: "Use Model(s)",
						val: info && info.group,
						list: groupTypes,
						isMulti: true,
						required: true
					}}
						onchange={updateVal} editable={editable} />
					<InputBasicText options={{
						type: 'input',
						name: 'title',
						label: t('title'),
						val: info && info.title,
						required: true
					}}
						onchange={updateVal} editable={editable} />
					<InputBasicTextArea
						options={{
							type: 'textarea',
							name: 'description',
							label: t('description'),
							val: info && info.description,
						}}
						onchange={updateVal} editable={editable} />
				</div>
			</Modal.Body>
			<div className='border-top p-2'>
				<button className={submitEnable() ? "btn btn-block btn-primary" : "btn btn-block btn-primary disabled"} onClick={() => handleSubmit(true)}>
					{isNew ? t('add') : t('update')}
				</button>
				{!isNew && <RayDelButton css="btn btn-danger btn-block" startMsg="Remove" callback={removeCF} />}
				<button className={request ? "btn btn-block btn-dark disabled" : "btn btn-block btn-dark"} onClick={() => callbackClose()}>{t('close')}</button>
			</div>
		</Modal>
	);
}

const mapState = (state) => {
	var item = state.CustomFieldReducer.item;
	return { item };
};

const mapDispatch = (dispatch) => ({
	Update: (_id, info) => dispatch(CustomFieldAction.Update(_id, info)),
	Create: (info) => dispatch(CustomFieldAction.Create(info)),
	Delete: (_id) => dispatch(CustomFieldAction.Delete(_id)),
});

export default connect(mapState, mapDispatch)(CustomFieldModal);