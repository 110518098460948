import { ProgramConstant } from './constants/ProgramConstant'

function MenuClose() {
    return { type: ProgramConstant.SIDEMENU_CLOSE };
}

function DashboardType(type) {
    return { type: ProgramConstant.DASHBOARDSHOW_TYPE, showtype : type };
}

function DashboardShowSub(bl) {
    return { type: ProgramConstant.SET_SHOWSUB, showSub : bl };
}

function MenuOpen() {
    return { type: ProgramConstant.SIDEMENU_OPEN };
}

function DeviceCategory(deviceCategory) {
    return { type: ProgramConstant.DEVICE_CATEGORY, deviceCategory }
}

export const ProgramAction = {
    MenuClose,
    MenuOpen,
    DashboardType,
    DashboardShowSub,
    DeviceCategory
}