import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { RayDelButton } from '../../components/common/Buttons';
import { Modal } from 'react-bootstrap';
import { InstallationAction, ProductAction } from '../../actions';
import { ProductPartAction } from '../../actions';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const DIAModal = ({ product, UpdateProductInfo, dimView, setDimView, user }) => {
	//const [ t ] = useTranslation();
	const [dimData, setDimData] = useState({});
	//const [partBL, setPartBL] = useState(false);
	//const [ comboParent, setComboParent ] = useState([]);
	//const [ combo, setCombo ] = useState([]);
	//const [ codes, setCodes ] = useState([]);
	//const [ list, setList ] = useState([]);
	//const [ item, setItem ] = useState({
	//	title: "",
	//	subtitle: ""
	//});

	useEffect(() => {
		//ProductPartAction.GetAllDirect().then(x => setCodes(x));
	}, [])

	useEffect(() => {
		setDimView(false);
	}, [product])

	/*
	useEffect(() => {
		if(codes.length > 0){
			SetCodeData();
		}
	}, [codes])

	useEffect(() => {
		if(!item.title || codes.length == 0)
			return;

		var list = [];
		codes.map(x => {
			if(x.type == item.title)
				list.push(x);
		});
		setCombo(list);
		setItem({...item, ["subtitle"] : list[0].code});
	}, [item.title])

	const del = (c) => {
		setList(list.filter(x => x.title+x.subtitle != c.title+c.subtitle));
	}

	const additem = () => {
		if(list.filter(x => x.title+x.subtitle == item.title+item.subtitle).length === 0)
			setList([...list, item]);
	}

	const getCodeName = (code) => {
		const m = codes.filter(x => x.code == code);
		if(m.length > 0){
			return m[0].name;
		}
		return code;
	}

	const SetCodeData = () => {
		var types = [];
		codes.map(x => types.push(x.type));
		const newList = [...new Set(types)];
		setComboParent(newList);
		setItem({...item, ["title"] : newList[0]});
	}
	*/

	const updateDim = (bl) => {
		UpdateProductInfo(product._id, {
			dim: bl,
			"$push": {
				dimlogs: {
					...dimData,
					user: user.adminid,
					dim: bl,
					timestamp: new Date().getTime()
				}
			}
		});
	}

	const changeCombo = (e) => {
		if (!e.target.value) return;
		var rep = {};
		codes.map(x => {
			if (x.type == item.title && x.code == e.target.value)
				rep = x;
		});
		setItem({ ...item, ["subtitle"]: e.target.value });
	}

	const changeComboParent = (e) => {
		setItem({ ...item, ["title"]: e.target.value });
	}

	console.log(dimView);

	return (
		<Modal show={dimView} onHide={() => setDimView(false)} className="action-sheet">
			<Modal.Header>
				<h2 className="modal-title">{t('diminfomation')}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<ul className="listview simple-listview no-space border-0 m-2">
					<div className="form-group basic">
						<div className="input-wrapper">
							<label className="label">{t('description')}</label>
							<textarea className="form-control"
								rows={5}
								onChange={(e) => setDimData({ ...dimData, comment: e.target.value })}
								defaultValue={dimData.comment}></textarea>
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				{!product.dim && <button
					className="btn btn-block btn-primary mb-0"
					onClick={() => updateDim(true)}>{t('checkdim')}</button>}
				{product.dim && <button
					className="btn btn-block btn-success mb-0"
					onClick={() => updateDim(false)}>Clear DIM</button>}
				<button className="btn btn-block btn-dark" onClick={() => setDimView(false)}>{t('close')}</button>
			</Modal.Footer>
		</Modal>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	UpdateProductInfo: (sn, data) => dispatch(ProductAction.UpdateProductInfo(sn, data)),
});

export default connect(mapState, mapDispatch)(DIAModal);