import {
    Document,
    Table,
    TableRow,
    Paragraph,
    TableCell,
    Packer,
    VerticalAlign,
    WidthType,
    HeightRule,
    TextRun,
    UnderlineType,
    AlignmentType,
    Footer,
    BorderStyle,
    ImageRun
} from 'docx';

import axios from 'axios';
import moment from 'moment';
import { AppConfig, JWT } from '../config';
import sizeOf from 'buffer-image-size';

export const csrBlob = async (data) => {
    const options = [
        { category: 'RAYSCAN m+', option: ['160(ENT CT)', '160-SDR(ENT CT/SDR)', '160(ENT CT)'], value: '없음' },
        { category: 'RAYSCAN m+', option: ['160-DRSC(ENT CT/DX)', '160-DRSCS(ENT CT/DX)', '160-SC(ENT CT/DX)'], value: 'ENT SC' },
        { category: 'RAYSCAN m+', option: ['M+ 160V-DRV','160-SDR(ENT CT/SDR)','160-DRSG(ENT CT)','M+ 160V-DRV_DEMO','160-DR(ENT CT/DR)'], value: 'ENT CH' },

        { category: 'RAYSCAN α 시리즈', option: ['P', 'PP', 'a-2DNI', 'LPP'], value: 'P' },
        { category: 'RAYSCAN α 시리즈', option: ['a-2DNI-OCSV', 'a-2DNI-OCLV', 'a-2DN-OCSV', 'a-2DN-OCLV', 'LOCSO', 'LOCG', 'LOCS', 'OCG', 'OCL', 'OCS', 'OC'], value: 'OC' },
        { category: 'RAYSCAN α 시리즈', option: ['SC', 'a-2DNI-SCS', 'LSC', 'LSCS', 'SCS'], value: 'SC' },
        { category: 'RAYSCAN α 시리즈', option: ['100V-OCT(CT/PX/OCT)', '100V(CT/PX)', '100V(CT/PX/OCT)', '3D'], value: '3D' },
        { category: 'RAYSCAN α 시리즈', option: ['SM3D', '100V-SC-OCT(CT/PX/DX/OCT)', '100V-SC(CT/PX/DX)', '100V-SCS(CT/PX/DX)', '100V-SCS-OCT(CT/PX/DX/OCT)', 'SM3DS'], value: 'SM3D' },
        {
            category: 'RAYSCAN α 시리즈', option:
                [
                    'A-100V-OCSV',
                    'A-100V-OCLV',
                    '100V-OCG-OCT(CT/PX/DX/OCT)',
                    '100V-OCG(CT/PX/DX)',
                    '100V-OCS-OCT(CT/PX/DX/OCT)',
                    '100V-OCS(CT/PX/DX)',                    
                    'A-100V-OCSV-DEMO',
                    'A-100V-OCSV-DEMO',
                    'A-100V-OCLV-DEMO',
                    'A-100V-OCLV-DEMO',
                    'M3D',
                    'M3DG',
                    'M3DL',
                    'M3DS',
                ],
            value: 'M3D'
        },        

        { category: 'RCT700 시리즈', option: ['180V', '160V-OCT(CT/PX/OT)', '160V(CT/PX)', '130V-OCT(CT/PX/OT)', '130V(CT/PX)', '80(CT/PX)', '160(CT/PX)', '130(CT/PX)'], value: '없음' },
        {
            category: 'RCT700 시리즈', option: [
                '160V-SC-OCT(CT/PX/DX/OCT)',
                '160V-SC(CT/PX/DX)',
                '160V-SCS-OCT(CT/PX/DX/OCT)',
                '160V-SCS(CT/PX/DX)',
                '130V-SC-OCT(CT/PX/DX/OCT)',
                '130V-SC(CT/PX/DX)',
                '130V-SCS-OCT(CT/PX/DX/OCT)',
                '130V-SCS(CT/PX/DX)',
                '80-SCS(CT/PX/DX)',
                '80-SC(CT/PX/DX)',
                '160-SCS(CT/PX/DX)',
                '160-SC(CT/PX/DX)',
                '130-SCS(CT/PX/DX)',
                '130-SC(CT/PX/DX)',
                '80V-SCS'
            ], value: 'SC'
        },
        {
            category: 'RCT700 시리즈', option: [
                '160V-OCSV_LIC',
                '160V-OCS-OCT(CT/PX/DX/OCT)',
                '160V-OCS(CT/PX/DX)',
                '130V-OCSV',
                '130V-OCS-OCT(CT/PX/DX/OCT)',
                '130V-OCS(CT/PX/DX)',
                '160V-OCSV_DEMO',
                '130V-OCSV_DEMO',
                '80-OCS(CT/PX/DX)',
                '160-OCS(CT/PX/DX)',
                '130-OCS(CT/PX/DX)'
            ], value: 'OCS'
        },
        {
            category: 'RCT700 시리즈', option: [
                '160V-OCLV_LIC',
                '160V-OCG-OCT(CT/PX/DX/OCT)',
                '160V-OCG(CT/PX/DX)',
                '130V-OCLV',
                '130V-OCG-OCT(CT/PX/DX/OCT)',
                '130V-OCG(CT/PX/DX)',
                '160V-OCLV_DEMO',
                '130V-OCLV_DEMO',
                '80-OCG(CT/PX/DX)',
                '80-OCL(CT/PX/DX)',
                '160-OCG(CT/PX/DX)',
                '160-OCL(CT/PX/DX)',
                '130-OCG(CT/PX/DX)',
                '130-OCL(CT/PX/DX)'
            ], value: 'OCL'
        },
        
        { category: 'RCT800 시리즈', option: ['200V-OCT(CT/PX/OT)', '200V(CT/PX)', 's- 180V', '180V(CT/PX/OCT)'], value: '없음' },
        { category: 'RCT800 시리즈', option: ['200V-SCS-OCT(CT/PX/DX/OT)', '200V-SCS(CT/PX/DX)', 's- 180V-SCS', '180V_SCS(CT/PX/SCS/OCT)'], value: 'SC' },
        { category: 'RCT800 시리즈', option: ['s- 200V-OCSV', '200V-OCS-OCT(CT/PX/DX/OT)', '200V-OCS(CT/PX/DX)', 's- 180V-OCSV', 's- 200V-OCSV_DEMO'], value: 'OCS' },
        { category: 'RCT800 시리즈', option: ['s- 200V-OCLV', '200V-OCG-OCT(CT/PX/DX/OT)', '200V-OCG(CT/PX/DX)', 's- 180V-OCLV', 's- 200V-OCLV_DEMO'], value: 'OCL' },
    ];

    const ticketKey = data.ticketKey;
    const effectiveDate = data.effectiveDate;
    const grade = data.grade;
    const selectedType = data.selectedType;
    const claim = data.claim;
    const claimReason = data.claimReason;
    const service = data.service;
    const serviceReason = data.serviceReason;
    
    const companyId = data.companyId;
    const companyName = data.companyName;
    const parentCompanyId = data.parentCompanyId;

    let isDomestic = false;

    if (companyId === 'RAY00012' || parentCompanyId === 'RAY00012')
        isDomestic = true;

    const customerName = data.customerName;
    const customerTel = data.customerTel;
    const customerAddress = data.customerAddress;

    const sn = data.sn;
    const option = data.option;
    const optionInfo = options.find(x => x.option.includes(option));

    const installedDate = data.installedDate;

    const creator = data.creator; // 접수자
    const summary = data.summary; // 접수증상

    const closedDate = data.closedDate;     // 확인일시
    const assignee = data.assignee;         // 확인자
    const description = data.description;   // 처리내역

    const rev = data.rev;

    const createDate = data.createDate;

    const closer = data.closer;

    const retParts = await axios.get(AppConfig.API_SERVER_PATH + '/part', JWT());

    let parts = [];

    if (retParts.data.status === 'success') {
        parts = retParts.data.data.filter(x => x.models.indexOf(data.model) > -1);        
    }

    const getPartsName = (code) => {        
        return parts.find(x => x.models.indexOf(data.model) > -1 && x.code === code)?.name;
    }

    const results = description?.split('\n').map(x => {
        return new Paragraph({
            children: [new TextRun({ text: x, font: '맑은 고딕' })]
        })
    }) || [];

    const replacementsList = (replacements) => {
        const result = replacements?.map(x => {
            return new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: getPartsName(x.subtitle) , font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: x.oldsn, font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: x.newsn, font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 500,
                    rule: HeightRule.EXACT
                }
            })
        });

        let loop = result?.length;

        while (loop < 3) {
            result.push(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            borders: {
                                right: {
                                    style: BorderStyle.DOUBLE,
                                    size: 2,
                                    color: '000000'
                                }
                            }
                        }),
                    ],
                    height: {
                        value: 500,
                        rule: HeightRule.EXACT
                    }
                })
            )

            loop++;
        }

        return result;
    }
    
    const replacements = replacementsList(data.replacements);

    let images = [];

    if (data.attachments) {
        for (const x of data?.attachments) {
            const ext = x.name.slice((x.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();

            if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(ext)) {
                const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
                    path: x.path,
                    ctype: "image/" + x.path.slice((x.path.lastIndexOf(".") - 1 >>> 0) + 2),
                }, JWT());

                const imageURL = ret.data.data;
                const response = await axios.get(imageURL, { responseType: 'arraybuffer' });
                const buffer = Buffer.from(response.data, 'binary');
                const dimensions = sizeOf(buffer);

                let w = dimensions.width;
                let h = dimensions.height;

                if (w > 500) {
                    w = 500;
                    h = Math.ceil(500 * dimensions.height / dimensions.width);
                }

                images.push(new Paragraph({
                    children: [new ImageRun({
                        data: Uint8Array.from(atob(buffer.toString('base64')), c => c.charCodeAt(0)),
                        transformation: { width: w, height: h }
                    })]
                }));
            }
        }
    }

    // const images = await Promise.all(
    //     data.attachments?.map(async x => {

    //         console.log(x.name);

    //         const ext = x.name.slice((x.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
    //         if (x.name !== 'inverter NG.png') {
    //             // if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(ext)) {
    //             const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
    //                 path: x.path,
    //                 ctype: "image/" + x.path.slice((x.path.lastIndexOf(".") - 1 >>> 0) + 2),
    //                 isBuffer: true
    //             }, JWT());

    //             const dimensions = sizeOf(Buffer.from(ret.data.data.Body.data));

    //             let w = dimensions.width;
    //             let h = dimensions.height;

    //             if (w > 500) {
    //                 w = 500;
    //                 h = Math.ceil(500 * dimensions.height / dimensions.width);
    //             }

    //             return new Paragraph({ children: [new ImageRun({ data: ret.data.data.Body.data, transformation: { width: w, height: h } })] });
    //         }
    //     }));

    const solved = data.solved;
    const hardware = data.hardware;
    const noise = data.noise;
    const software = data.software;
    const acq = data.acq;

    const logfile = data.logfile;

    const getSquare = (category, value, options) => {
        if (options?.category === category && options?.value === value)
            return '■'
        else
            return '□'
    }

    const table = new Table({
        rows: [
            //  서비스처리서
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [new TextRun({
                                    text: "서비스 처리서",
                                    underline: {
                                        type: UnderlineType.SINGLE,
                                        color: '000000'
                                    },
                                    bold: true,
                                    size: 35
                                })],
                                alignment: AlignmentType.CENTER,
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 10,
                        borders: {
                            top: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            },
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            },
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            },
                            bottom: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            },
                        }

                    }),
                ],
                height: {
                    value: 850,
                    rule: HeightRule.EXACT
                }
            }),

            // 접수번호,  등급
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '접수번호', bold: true })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        width: { size: 1000, type: WidthType.DXA },
                        borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: moment(createDate).format('YYYYMMDD'), font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 7,
                        width: { size: 2500, type: WidthType.DXA }
                    }),                   
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: '접수일자', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, width: { size: 1000, type: WidthType.DXA } }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: moment(createDate).format('YYYY-MM-DD'), font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        width: { size: 2200, type: WidthType.DXA },
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 구분 > 고객불만
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '구분', bold: true })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        // rowSpan: 2,
                        borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${service == 'option1' ? '■' : '□'} 서비스 / ${service == 'option2' ? '■' : '□'} 사용자 부주의 / ${service == 'option3' ? '■' : '□'} 사용자 재교육 / ${service == 'option4' ? '■' : '□'} 기타 (               )`,
                                font: '맑은 고딕'
                            })]
                        })],
                        columnSpan: 9,
                        verticalAlign: VerticalAlign.CENTER,
                        margins: {
                            left: 200
                        },
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),                 
                ],
                height: {
                    value: 800,
                    rule: HeightRule.EXACT
                },
            }),

            // 고객정보 -> 고객명, 연락처
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({ text: '고객정보', bold: true })],
                            alignment: AlignmentType.CENTER
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        rowSpan: 2,
                        borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ text: '고객명', alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: isDomestic ? customerName : companyName, font: '맑은 고딕' })], alignment: AlignmentType.LEFT })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 6,
                        margins: {
                            left: 100
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ text: '연락처', alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({ text: isDomestic ? customerTel?.replace('+82 ', '0') : '', font: '맑은 고딕' })],
                            alignment: AlignmentType.LEFT
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2,
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }, margins: {
                            left: 100
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                },
            }),

            // 고객정보 -> 고객주소
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ text: '고객주소', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: isDomestic ? customerAddress : '', font: '맑은 고딕' })], alignment: AlignmentType.LEFT })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 8,
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        },
                        margins: {
                            left: 100
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> Table Header
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '제품정보', bold: true })], alignment: AlignmentType.CENTER })],
                        verticalAlign: VerticalAlign.CENTER,
                        rowSpan: 11,
                        borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: '제품명', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: '옵션 및 버전사항', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 5 }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: 'S/N', font: '맑은 고딕', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER,
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RAYSCAN Symphony
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RAYSCAN Symphony', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: '□M      □C      □P      □BM      □BP      □V'
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RAYSCAN Symphony' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RAYSCAN α 시리즈
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RAYSCAN α 시리즈', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${getSquare('RAYSCAN α 시리즈', 'P', optionInfo)}P   ${getSquare('RAYSCAN α 시리즈', 'OC', optionInfo)}OC   ${getSquare('RAYSCAN α 시리즈', 'SC', optionInfo)}SC   ${getSquare('RAYSCAN α 시리즈', '3D', optionInfo)}3D   ${getSquare('RAYSCAN α 시리즈', 'SM3D', optionInfo)}SM3D   ${getSquare('RAYSCAN α 시리즈', 'M3D', optionInfo)}M3D`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RAYSCAN α 시리즈' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RCT700 시리즈
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RCT700 시리즈', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `Ceph option  :  ${getSquare('RCT700 시리즈', '없음', optionInfo)}없음    ${getSquare('RCT700 시리즈', 'SC', optionInfo)}SC    ${getSquare('RCT700 시리즈', 'OCS', optionInfo)}OCS    ${getSquare('RCT700 시리즈', 'OCL', optionInfo)}OCL`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),

                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RCT700 시리즈' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RCT800 시리즈
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RCT800 시리즈', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `Ceph option  :  ${getSquare('RCT800 시리즈', '없음', optionInfo)}없음    ${getSquare('RCT800 시리즈', 'SC', optionInfo)}SC    ${getSquare('RCT800 시리즈', 'OCS', optionInfo)}OCS    ${getSquare('RCT800 시리즈', 'OCL', optionInfo)}OCL`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RCT800 시리즈' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RAYSCAN m+
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RAYSCAN m+', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `Ceph option  : ${getSquare('RAYSCAN m+', '없음', optionInfo)}없음     ${getSquare('RAYSCAN m+', 'ENT SC', optionInfo)}ENT SC     ${getSquare('RAYSCAN m+', 'ENT CH', optionInfo)}ENT CH`,
                                font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RAYSCAN m+' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RIOSensor
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RIOSensor', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${getSquare('RIOSensor', 'Size 1', optionInfo)}Size 1     ${getSquare('RIOSensor', 'Size 2', optionInfo)}Size 2`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RIOSensor' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RIOScan
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RIOScan', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${getSquare('RIOScan', 'Standard', optionInfo)}Standard     ${getSquare('RIOScan', 'Occlusal', optionInfo)}Occlusal`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RIOScan' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RAYDENT Studio
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RAYDENT Studio', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${getSquare('RAYDENT Studio', '500', optionInfo)}500     ${getSquare('RAYDENT Studio', '500', optionInfo)}600     ${getSquare('RAYDENT Studio', '600M', optionInfo)}600M     ${getSquare('RAYDENT Studio', '후 경화기', optionInfo)}후 경화기`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RAYDENT Studio' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> RAYFace
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: 'RAYFace', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${getSquare('RAYFace', '100', optionInfo)}100     ${getSquare('RAYFace', '200', optionInfo)}200`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: optionInfo?.category === 'RAYFace' ? sn : '', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 제품정보 -> 기타
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: '기타' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${getSquare('기타', 'Nanoray, Oral Camera, Portable X-ray, etc', optionInfo)}Nanoray, Oral Camera, Portable X-ray, etc`
                                , font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                        margins: {
                            left: 200
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ text: optionInfo?.category === '기타' ? sn : '', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 설치일자
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '설치일자', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({ text: (installedDate <= 1065366000000 ? '' : moment(installedDate).format('YYYY-MM-DD')), font: '맑은 고딕' })], alignment: AlignmentType.CENTER,
                    })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 9,
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        },
                        margins: { left: 100 }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            // 접수증상
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '접수증상', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({ children: [new TextRun({ text: `[접수자 : ${creator} ]`, font: '맑은 고딕' })], }),
                            new Paragraph({ children: [new TextRun({ text: summary, font: '맑은 고딕' })] }),

                        ],
                        verticalAlign: VerticalAlign.TOP,
                        columnSpan: 9,
                        margins: {
                            top: 100,
                            left: 100
                        }, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 2500,
                    rule: HeightRule.EXACT
                }
            }),

            // 확인증상
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '확인증상', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({ text: '[확인일자 :                         / 확인자 :                         ]', })],
                        verticalAlign: VerticalAlign.TOP,
                        columnSpan: 9,
                        margins: {
                            top: 100,
                            left: 100
                        }, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 2500,
                    rule: HeightRule.EXACT
                }
            }),

            // 증상사진
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '증상사진', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: images,
                        columnSpan: 9,
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        },
                        margins: {
                            top: 200,
                            left: 200,
                            right: 200,
                            bottom: 200
                        }
                    }),
                ],           
            }),

            // 처리내역
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({ text: '조치내역', bold: true })],
                            alignment: AlignmentType.CENTER
                        })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({ children: [new TextRun({ text: `[확인일자 : ${closedDate ? moment(closedDate).format("YYYY-MM-DD") : ''}  / 확인자 : ${assignee} ]`, font: '맑은 고딕' })] }),
                            ...results
                        ],
                        verticalAlign: VerticalAlign.TOP,
                        columnSpan: 9,
                        margins: {
                            top: 100,
                            left: 100
                        }, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 2500,
                    rule: HeightRule.EXACT
                }
            }),

            // 처리결과
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: '조치결과', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({ children: [new TextRun({ text: `[확인일자 : ${closedDate ? moment(closedDate).format("YYYY-MM-DD") : ''}  / 확인자 : ${assignee} ]`, font: '맑은 고딕' })] }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [

                                            
                                            new TableCell({
                                                children: [new Paragraph({ children: [new TextRun({ text: '#', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })],
                                                verticalAlign: VerticalAlign.CENTER,
                                                width: { size: 1000, type: WidthType.DXA },                                                
                                            }),                                            
                                            new TableCell({ children: [new Paragraph({ text: '항목', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                            new TableCell({ children: [new Paragraph({ text: '테스트 결과', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                        ],
                                        height: {
                                            value: 450,
                                            rule: HeightRule.EXACT
                                        }                                        
                                    }),

                                     new TableRow({
                                         children: [
                                             new TableCell({ children: [new Paragraph({  children: [new TextRun({ text: '1', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                             new TableCell({ children: [new Paragraph({ text: '접수 이슈 사항 해결 여부', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                             new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: `${solved == 'pass' ? '■' : '□'} 합격              ${solved == 'fail' ? '■' : '□'} 불합격`, font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                         ],
                                         height: {
                                             value: 450,
                                             rule: HeightRule.EXACT
                                         }                                         
                                    }),

                                     new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph({  children: [new TextRun({ text: '2', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                            new TableCell({ children: [new Paragraph({ text: '해당 제품 별 하드웨어 기본 동작 점검', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: `${hardware == 'pass' ? '■' : '□'} 합격              ${hardware == 'fail' ? '■' : '□'} 불합격`, font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                        ],
                                        height: {
                                            value: 450,
                                            rule: HeightRule.EXACT
                                        }
                                    }),

                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph({  children: [new TextRun({ text: '3', font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                            new TableCell({ children: [new Paragraph({ text: '해당 제품 별 소프트웨어 기본 동작 점검', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                            new TableCell({ children: [new Paragraph({ children: [new TextRun({ text: `${software == 'pass' ? '■' : '□'} 합격              ${software == 'fail' ? '■' : '□'} 불합격`, font: '맑은 고딕' })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER }),
                                        ],
                                        height: {
                                            value: 450,
                                            rule: HeightRule.EXACT
                                        }
                                    }),
                                ],
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE
                                },
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP,
                        columnSpan: 9,
                        margins: {
                            top: 100,
                            left: 100,
                            right: 100
                        }
                        , borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 3700,
                    rule: HeightRule.EXACT
                }
            }),

            // Log File
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: 'Log File', font: '맑은 고딕', bold: true })], alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${logfile == 'O' ? '■' : '□'}확보           ${logfile == 'X' ? '■' : '□'}미확보            ${logfile == '-' ? '■' : '□'}불필요`,
                                font: '맑은 고딕'
                            })]
                        })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 9,
                        margins: {
                            left: 100
                        }, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 500,
                    rule: HeightRule.EXACT
                }
            }),

            // 변경 / 교체사항 (Header)
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({ children: [new TextRun({ text: '변경', bold: true })], alignment: AlignmentType.CENTER }),
                            new Paragraph({ children: [new TextRun({ text: '/', bold: true })], alignment: AlignmentType.CENTER }),
                            new Paragraph({ children: [new TextRun({ text: '교체사항', bold: true })], alignment: AlignmentType.CENTER }),

                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        rowSpan: (replacements?.length || 0) + 1,
                        borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({ children: [new Paragraph({ text: '항목', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({ children: [new Paragraph({ text: '변경 전', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3 }),
                    new TableCell({
                        children: [new Paragraph({ text: '변경 후', alignment: AlignmentType.CENTER })], verticalAlign: VerticalAlign.CENTER, columnSpan: 3, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ],
                height: {
                    value: 600,
                    rule: HeightRule.EXACT
                }
            }),

            ...replacements,

            // // 변경 / 교체사항 (ROW 1)
            // new TableRow({
            //     children: [
            //         new TableCell({ children: [new Paragraph({ text: '' })], columnSpan: 3 }),
            //         new TableCell({ children: [new Paragraph({ text: '' })], columnSpan: 3 }),
            //         new TableCell({
            //             children: [new Paragraph({ text: '' })], columnSpan: 3, borders: {
            //                 right: {
            //                     style: BorderStyle.DOUBLE,
            //                     size: 2,
            //                     color: '000000'
            //                 }
            //             }
            //         }),
            //     ],
            //     height: {
            //         value: 500,
            //         rule: HeightRule.EXACT
            //     }
            // }),

            // // 변경 / 교체사항 (ROW 2)
            // new TableRow({
            //     children: [
            //         new TableCell({ children: [new Paragraph({ text: '' })], columnSpan: 3 }),
            //         new TableCell({ children: [new Paragraph({ text: '' })], columnSpan: 3 }),
            //         new TableCell({
            //             children: [new Paragraph({ text: '' })], columnSpan: 3, borders: {
            //                 right: {
            //                     style: BorderStyle.DOUBLE,
            //                     size: 2,
            //                     color: '000000'
            //                 }
            //             }
            //         }),
            //     ],
            //     height: {
            //         value: 500,
            //         rule: HeightRule.EXACT
            //     }
            // }),

            // // 변경 / 교체사항 (ROW 3)
            // new TableRow({
            //     children: [
            //         new TableCell({ children: [new Paragraph({ text: '' })], columnSpan: 3 }),
            //         new TableCell({ children: [new Paragraph({ text: '' })], columnSpan: 3 }),
            //         new TableCell({
            //             children: [new Paragraph({ text: '' })], columnSpan: 3, borders: {
            //                 right: {
            //                     style: BorderStyle.DOUBLE,
            //                     size: 2,
            //                     color: '000000'
            //                 }
            //             }
            //         }),
            //     ],
            //     height: {
            //         value: 500,
            //         rule: HeightRule.EXACT
            //     }
            // }),

            // 완료일자, 처리자
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({ children: [new TextRun({ text: `완료일자 : ${closedDate > 0 ? moment(closedDate).format("YYYY") : ''}년 ${closedDate > 0 ? moment(closedDate).format("MM") : ''}월 ${closedDate > 0 ? moment(closedDate).format("DD") : ''}일`, font: '맑은 고딕' })] }),
                        ],
                        columnSpan: 7,
                        rowSpan: 2,
                        verticalAlign: VerticalAlign.CENTER,
                        margins: {
                            left: 100
                        }, borders: {
                            left: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }, bottom: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                    new TableCell({
                        children: [
                            // new Paragraph({ children: [new TextRun({ text: `처리자  ${closer ? closer : ''} (확인)`, font: '맑은 고딕' })] })
                            new Paragraph({ text: '처리자 :                                            (확인)', font: '맑은 고딕' })
                        ],
                        columnSpan: 3,
                        margins: {
                            left: 100
                        }, borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    }),
                ]
            }),

            // 확인자
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({ text: '확인자 :                                            (확인)', font: '맑은 고딕' })
                        ],
                        columnSpan: 3,
                        margins: { left: 100 },
                        borders: {
                            right: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }, bottom: {
                                style: BorderStyle.DOUBLE,
                                size: 2,
                                color: '000000'
                            }
                        }
                    })
                ]
            }),
        ],

        width: {
            size: 100,
            type: WidthType.PERCENTAGE
        },

    });

    const document = new Document({
        sections: [{
            properties: {
                page: {
                    margin: {
                        top: 1200,
                        bottom: 2,
                        left: 800,
                        right: 800
                    }
                }
            },
            children: [table],
            footers: {
                default: new Footer({
                    children: [
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph({
                                                children: [new TextRun({
                                                    text: 'Ray Co., Ltd.',
                                                    font: '맑은 고딕'
                                                })]
                                            })],
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                left: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                right: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                            }
                                        }),
                                        new TableCell({
                                            children: [new Paragraph({
                                                children: [new TextRun({
                                                    text: 'Rev.(' + rev + ')',
                                                    font: '맑은 고딕'
                                                })], alignment: AlignmentType.CENTER
                                            })],
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                left: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                right: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                            }
                                        }),
                                        new TableCell({
                                            children: [new Paragraph({
                                                children: [new TextRun({
                                                    text: 'ROP801-6',
                                                    font: '맑은 고딕'
                                                })], alignment: AlignmentType.RIGHT
                                            })],
                                            borders: {
                                                top: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                left: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                right: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                                bottom: { style: BorderStyle.NONE, size: 0, color: 'ffffff' },
                                            }
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                })
            }
        }],
    });


    return await Packer.toBlob(document);
}
