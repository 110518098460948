import { RayForm } from "../../components/common";
import { InputSelect2Search } from "../../components/common/Inputs";
import { ProductAction } from '../../actions/ProductAction';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { history } from '../../history';
import moment from 'moment';

const AdminSparePartsOutRegist = ({ sparePartsList, selectedOrder, productList, GetSparePartsList, GetOneOrderById, GetProductList, SparePartsOutRegist }) => {
    const [data, setData] = useState({
        partsList: [],
        partsSecuringTime: '',
        partsProcureTime: '',
        partsProcureDuration: '',
        shipmentWorker: '박태영',
        forwardingAgent: 'TNT'
    });

    const { _id } = useParams();

    useEffect(() => {
        GetSparePartsList();
        GetOneOrderById(_id);
        setData({ ...data, orderId: _id });
    }, []);

    useEffect(() => {
        GetProductList({ 'match': { 'companyId': selectedOrder.companyId } });

        const orderList = selectedOrder?.orderList?.map(x => {
            const filtered = sparePartsList?.filter(y => y._id == x.partsId)[0]
            return {
                _id: filtered?._id,
                serviceCode: filtered?.serviceCode,
                servicePartName: filtered?.servicePartName,
                quantity: x.quantity,
                price: x.price,
                hsCode: filtered?.hsCode || ''
            }
        });

        setData({
            ...data,
            orderId: _id,
            orderDate: selectedOrder.orderDate,
            piPublishTime: selectedOrder.piPublishTime,
            shipmentDate: selectedOrder.shipmentDate,
            country: selectedOrder.companyInfo?.country,
            companyId: selectedOrder.companyId,
            companyName: selectedOrder.companyInfo?.name,
            address: selectedOrder.address,
            partsList: orderList?.map((data, idx) => {
                return { ...data, seq: idx + 1 }
            }),
            type: selectedOrder.type
        });

    }, [selectedOrder]);


    useEffect(() => {
        const result = SparePartsAction.CalculateSparePartsTimes(data.orderDate, data.piPublishTime, data.shipmentDate);
        setData({
            ...data,
            partsSecuringTime: result.hTime ? result.hTime + '시간' : 0,
            partsProcureTime: result.jTime ? result.jTime + '일' : 0,
            partsProcureDuration: result.cTime || 0
        });
    }, [data.orderDate, data.shipmentDate, data.piPublishTime])


    useEffect(() => { 
        console.log(data);
    }, [data]);


    const basic_controls = [
        { title: "기본정보", items: [{ type: "input", name: "country", label: "국가", isEditable: false, val: selectedOrder.companyInfo?.country }] },
        { title: "", items: [{ type: "input", name: "companyName", label: "업체", isEditable: false, val: selectedOrder.companyInfo?.name }] },
        { title: "", items: [{ type: "input", name: "address", label: "배송지 주소", isEditable: false, val: selectedOrder.address }] },
        { title: "", items: [{ type: "input", name: "outType", label: "출고구분", isEditable: false, val: '일반출고' }] },
        { title: "", items: [{ type: "input", name: "chargeOrFree", label: "유상/무상", isEditable: false, val: selectedOrder.type === 'FREE' ? '무상' : '유상' }] },
    ];

    const product_controls = [
        {
            title: "장비정보", items: [{
                type: "select2search",
                name: "productSN",
                label: "SN",
                isEditable: true,
                list: productList?.map(x => { return { code: x.sn, title: x.sn } }),
                val: data.productSN
            }]
        },
        { title: "", items: [{ type: "input", name: "model", label: "모델", isEditable: false, val: data.model }] },
        { title: "", items: [{ type: "input", name: "option", label: "옵션", isEditable: false, val: data.option }] },
        { title: "", items: [{ type: "input", name: "installedDate", label: "설치일", isEditable: false, val: data.installedDate && moment(data.installedDate).format("YYYY-MM-DD") }] },
    ];

    const swList = [
        { code: '', title: '' },
        { code: '박태영', title: '박태영' },
        { code: '김주영', title: '김주영' },
        { code: '유태선', title: '유태선' },
        { code: '원선우', title: '원선우' },
        { code: '정보상', title: '정보상' },
        { code: '정현석', title: '정현석' },
        { code: '백미영', title: '백미영' },
    ];

    const faList = [
        { code: '', title: '' },
        { code: 'TNT', title: 'TNT' },
        { code: 'DHL', title: 'DHL' },
        { code: 'Fedex', title: 'Fedex' },
        { code: 'UPS', title: 'UPS' },
        { code: 'ETC', title: 'ETC' },
    ];

    const release_controls = [
        { title: "불출정보", items: [{ type: "datetime", name: "orderDate", onlyDate: true, label: "주문일", val: data.orderDate, isEditable: false }] },
        { title: "", items: [{ type: "datetime", name: "receiveDate1", onlyDate: true, label: "접수일(무상,고불처)", val: data.receiveDate1, isEditable: true }] },
        { title: "", items: [{ type: "datetime", name: "receiveDate2", onlyDate: true, label: "접수일(자재출고)", val: data.receiveDate2, isEditable: true }] },
        { title: "", items: [{ type: "datetime", name: "shipmentDate", onlyDate: true, label: "선적일", val: data.shipmentDate, isEditable: true }] },
        { title: "", items: [{ type: "select", name: "shipmentWorker", list: swList, label: "선적작업자", isEditable: false, val: data.shipmentWorker, isEditable: true }] },
        { title: "", items: [{ type: "datetime", name: "piPublishTime", onlyDate: true, label: "PI발행시간", val: data.piPublishTime, isEditable: (data.type === 'FREE' ? true : false) }] },        

        { title: "", items: [{ type: "input", name: "partsSecuringTime", label: "자재확보시간", isEditable: false, val: data.partsSecuringTime }] },
        { title: "", items: [{ type: "input", name: "partsProcureTime", label: "자재조달시간", isEditable: false, val: data.partsProcureTime }] },
        { title: "", items: [{ type: "input", name: "partsProcureDuration", label: "자재출하기간분류", isEditable: false, val: data.partsProcureDuration }] },

        { title: "", items: [{ type: "input", name: "partsProcureDelayReason", label: "자재조달지연사유", isEditable: false, val: data.partsProcureDelayReason, isEditable: true }] },
        { title: "", items: [{ type: "select", name: "forwardingAgent", list: faList, label: "운송사", isEditable: false, val: data.forwardingAgent, isEditable: true }] },
        { title: "", items: [{ type: "input", name: "invoiceNumber", label: "송장번호", isEditable: false, val: data.invoiceNumber, isEditable: true }] },
        { title: "", items: [{ type: "input", name: "workingDepartment", label: "불출구분", isEditable: false, val: data.workingDepartment, isEditable: true }] },
        { title: "", items: [{ type: "input", name: "ccrNumber", label: "고불처번호", isEditable: false, val: data.ccrNumber, isEditable: true }] },
        { title: "", items: [{ type: "input", name: "draftNumber", label: "기안번호", isEditable: false, val: data.draftNumber, isEditable: true }] },
    ];

    const handleChangeData = (e) => {
        if (e.target.name == 'productSN') {
            const sn = e.target.value;
            const filtered = productList.filter(x => x.sn == sn)[0];
            setData({ ...data, productSN: sn, model: filtered.model, option: filtered.option, installedDate: filtered.installedDate });
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    }

    const removeParts = (seq) => {
        setData({ ...data, partsList: data.partsList.filter(x => x.seq != seq) });
    }

    const changePartsQuantity = (e, seq) => {
        setData({
            ...data,
            partsList: data.partsList.map(x => x.seq === seq ? { ...x, quantity: parseInt(e.target.value || 0) } : x)
        });
    }

    const changePartsSN = (e, seq) => {
        setData({
            ...data,
            partsList: data.partsList.map(x => x.seq === seq ? { ...x, sn: e.target.value } : x)
        });
    }

    const releaseChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const outRegist = () => {
        if (confirm("출고등록하시겠습니까?")) {
            SparePartsOutRegist(data);
            history.push('/Admin/AdminSparePartsOutList');
        }
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">출고등록</h2>
                <div className="ray-right">
                    <button className="btn btn-sm btn-primary mx-1" onClick={() => outRegist()}>등록하기</button>
                    <button className="btn btn-sm btn-primary" onClick={() => history.push('/Admin/AdminSpareParts')}>목록으로</button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active">
                <div className="row m-1 p-0">
                    <div className="col-4 m-0 p-1">
                        <RayForm controls={basic_controls} isForm={false} />
                        <RayForm controls={product_controls} isForm={false} handleChangeData={handleChangeData} />
                    </div>

                    <div className="col-5">
                        <div className="rayform section mt-1">
                            <div className="section-title">파트정보</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <InputSelect2Search
                                        name='parts'
                                        options={{
                                            list: sparePartsList.map(x => { return { code: x.serviceCode, title: x.servicePartName } }),
                                            isMulti: false,                                            
                                        }}
                                        editable={true}
                                        onchange={(e) => {
                                            const filtered = sparePartsList.filter(x => x.serviceCode == e.target.value)[0];

                                            const seq = (Math.max.apply(Math, data.partsList.map(x => {
                                                return x.seq
                                            })) || 0) + 1;

                                            setData({
                                                ...data,
                                                partsList: [...data.partsList, { seq, _id: filtered._id, serviceCode: e.target.value, servicePartName: filtered.servicePartName, quantity: 1, }]
                                            })
                                        }}
                                    />

                                    <table className="table table-bordered mb-1">
                                        <thead>
                                            <tr>
                                                <th>서비스코드</th>
                                                <th>서비스파트명</th>
                                                <th>수량</th>
                                                <th>SN</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.partsList?.length > 0 && data.partsList?.map((x, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="text-break">{x.serviceCode}</td>
                                                            <td className="text-break">{x.servicePartName}</td>
                                                            <td className="text-break text-center">
                                                                <input type='text'
                                                                    value={x.quantity}
                                                                    className='order-textbox'
                                                                    onChange={(e) => changePartsQuantity(e, x.seq)}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type='text' className='order-textbox-sn' onChange={(e) => changePartsSN(e, x.seq)} />
                                                            </td>
                                                            <td className="text-center">
                                                                <a onClick={() => removeParts(x.seq)} >
                                                                    <ion-icon name="remove-circle" className="order-remove" style={{ fontSize: '25px', color: 'red', cursor: 'pointer' }}></ion-icon>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }

                                            {
                                                data.partsList?.length == 0 &&
                                                (
                                                    <tr>
                                                        <td colSpan={4} className="text-center">
                                                            <i>선택된 파츠가 없습니다.</i>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-3">
                        <RayForm controls={release_controls} isForm={false} handleChangeData={releaseChange} />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    const sparePartsList = state.SparePartsReducer.spareParts;
    const selectedOrder = state.SparePartsReducer.order;
    const productList = state.ProductReducer.products;
    return { sparePartsList, selectedOrder, productList };
};

const mapDispatch = (dispatch) => ({
    GetSparePartsList: () => dispatch(SparePartsAction.GetList({})),
    GetOneOrderById: (_id) => dispatch(SparePartsAction.GetOneOrderById(_id)),
    GetProductList: (filter) => dispatch(ProductAction.GetProductAll(filter)),
    SparePartsOutRegist: (data) => dispatch(SparePartsAction.SparePartsOutRegist(data))
});

export default connect(mapState, mapDispatch)(AdminSparePartsOutRegist);
