import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InfinteScroll } from '../../components/common/InfinteScroll';
import { ProductAction } from '../../actions/ProductAction';
import { Loading } from '../common/Loading';
import ProductCard from './ProductCard';
import moment from 'moment';

const ProductListNewInstalled = ({ GetProductAll, items, totalCount, isLoading, days = 7 }) => {

	const [target, setTarget] = useState(null);
	const [page, setPage] = useState(1);
	const [itemperpage, setItemPage] = useState(30);
	const [lastDays, setLastDays] = useState(days);

	useEffect(() => {
		GetProductAll({
			match: { 'installedDate': { $gte: getTime() } },
			itemperpage: itemperpage,
			page: page
		}, true);
	}, [page, lastDays])

	const isEnd = () => { return totalCount <= page * itemperpage; }
	const getTime = () => { return moment.utc(moment().utc()).add(-1 * lastDays, 'days').unix() * 1000 }

	InfinteScroll({
		target,
		onIntersect: ([{ isIntersecting }]) => {
			if (isIntersecting && !isLoading && !isEnd()) {
				setPage(page + 1);
			}
		}
	});

	return (
		<ul className="listview link-listview">
			{items.map((item, index) =>
				<li className="item" key={index}>
					<ProductCard item={item} />
				</li>
			)}
			{isLoading && <Loading />}
			<div ref={setTarget} className='last-item' />
		</ul>
	);
};

const mapState = (state) => {
	var items = state.ProductReducer.products;
	var totalCount = state.ProductReducer.totalproductcount;
	var isLoading = state.ProductReducer.isLoading;

	return { items, totalCount, isLoading };
};

const mapDispatch = (dispatch) => ({
	GetProductAll: (filter, append) => dispatch(ProductAction.GetProductAll(filter, append)),
});

export default connect(mapState, mapDispatch)(ProductListNewInstalled);