import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CalendarAction, ProductAction, CompanyAction } from '../../actions';
import { Modal } from 'react-bootstrap';
import {
    InputBasicText,
    InputSelect2,
    InputBasicDateTime,
    InputSelect2Search,
    InputBasicTextArea
} from '../../components/common/Inputs';
import { RayDelButton } from '../common/Buttons';
import { Translate as t } from '../../config/Translate';

const ScheduleCreate = ({ item, isLoading, Create, Update, Delete, callback, showAS, isFull = true }) => {
    const userCompanyId = JSON.parse(localStorage.getItem("user")).companyId;
    // const [data, setData] = useState({ color: "bg-warning" });
    const [data, setData] = useState({});
    const [searchList, setSearchList] = useState([]);
    const [searchSN, setSearchSN] = useState({});
    const [ownerShipList, setOwnerShipList] = useState([]);
    const [err, setErr] = useState([]);

    const setOwner = async () => {
        var list = await CompanyAction.GetParents();

        if (list.filter(x => x.code == "RAY00001").length == 0)
            list.push({ code: "RAY00001", title: "[HQ] RAY_HQ" });

        setOwnerShipList(list);
    }

    useEffect(() => {
        if (ownerShipList.length == 0)
            setOwner();
    }, [])

    useEffect(() => {
        if (item._id) {
            setData(item);
        } else {
            setData({ ...item, color: item.color || "bg-warning", owner: item.owner || userCompanyId });
        }
        if (item.sn) {
            setSearchList([{ code: item.sn, title: item.sn }]);
            setSearchSN({
                sn: item.sn,
                sitename: item.sitename,
                siteId: item.siteId,
                model: item.model,
                option: item.option
            });
        } else {
            setSearchSN({});
            setSearchList([]);
        }
    }, [item])

    useEffect(() => {
        callback && callback();
    }, [isLoading])

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        var nData = { ...data, [name]: value };

        if (name == "sn") {
            nData["sn"] = value;
            const ret = searchList.filter(x => x.sn == value);
            if (ret.length > 0) {
                nData["sitename"] = ret[0].sitename;
                nData["siteId"] = ret[0].siteId;
                nData["model"] = ret[0].model;
                nData["option"] = ret[0].option;
                setSearchSN(ret[0]);
            }
        }

        if (name == "start") {
            nData["finish"] = new Date(new Date(nData["start"]).setHours(new Date(nData["start"]).getHours() + 3)).getTime().valueOf();
        }

        console.log(nData);

        setData(nData);
    }

    const handleInputChangeData = (e) => {
        const { name, value } = e.target;
        if (name == "sn" && value.length > 2) {
            search(name, value);
        }
    }

    const search = async (type, value) => {
        var list = [];
        if (type == "sn") {
            const ret = await ProductAction.GetProductDirect({ searchCols: ["sn"], searchKey: value });
            ret.list.map(x => list.push({ ...x, code: x.sn, title: x.sn }))
        }
        setSearchList(list);
    }

    const validAdd = () => {
        if (!data.customer || data.customer == "") {
            var errlist = [];
            setErr([]);
            errlist.push({ name: "customer", msg: "Customer is required" });
            setErr(errlist);
        }

        if (!data.title || data.title == "") {
            var errlist = [];
            setErr([]);
            errlist.push({ name: "title", msg: "title is required" });
            setErr(errlist);
        }

        if ((data.customer && data.customer != "") && (data.title && data.title != "")) {
            if (item._id)
                Update(item._id, data);
            else
                Create(data);
        }
    }

    const del = () => {
        if (item._id) {
            Delete(item._id);
        }
    }

    const colors = [
        { name: "Yellow", color: "bg-warning" },
        { name: "Red", color: "bg-danger" },
        { name: "Green", color: "bg-success" },
        { name: "Purple", color: "bg-purple" },
        { name: "Cyan", color: "bg-cyan" },
        { name: "Gray", color: "bg-dark" }
    ]

    const schTypes = [
        { code: "Pre-install", title: t('preinstall') },
        { code: "Install", title: t('installation') },
        { code: "Remote", title: t('calremote') },
        { code: "On-site", title: t('calonsite') },
        { code: "Other", title: t('other') }
    ]

    const getCssColor = (x) => colors.filter(y => y.name == x.name)[0].color + " chip-icon";
    const getColorName = (x) => colors.filter(y => y.color == x)[0].name;

    return (
        <Modal show={showAS} fullscreen={isFull} dialogClassName={isFull ? "modal-fullscreen" : ""} scrollable={true}>
            <Modal.Header className={data.color + " pt-1 pb-1"}>
                <h3 className="modal-title text-white">{data.title || "New Event"}</h3>
                <div className="float-end">
                    <a onClick={() => callback()}>
                        <ion-icon name="close-outline" size="large" />
                    </a>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-2">
                    <div className="form-group basic">
                        {colors.map(y => <div key={y.color} className="chip chip-media me-2" onClick={() => setData({ ...data, color: y.color })}>
                            <i className={getCssColor(y)}>
                                <ion-icon name={y.color == data.color ? "checkmark" : "dd"} className="md hydrated"></ion-icon>
                            </i>
                        </div>)}
                    </div>

                    <InputSelect2
                        options={{
                            type: "select2",
                            name: "type",
                            label: t('type'),
                            placeholder: t('type'),
                            list: schTypes,
                            val: data.type || schTypes[0].code,
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData} />

                    <InputBasicText
                        options={{
                            name: "title",
                            label: t('title'),
                            placeholder: t('title'),
                            val: data.title || "",
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData}
                        errs={err}
                    />

                    <InputSelect2
                        options={{
                            type: "select2",
                            name: "owner",
                            label: t('owner'),
                            placeholder: t('owner'),
                            list: ownerShipList,
                            val: data.owner || userCompanyId,
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData} />

                    <InputBasicDateTime
                        options={{
                            type: "datetime",
                            name: "start",
                            label: t('start'),
                            val: data.start || new Date(),
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData} />

                    <InputBasicDateTime
                        options={{
                            type: "datetime",
                            name: "finish",
                            label: t('finish'),
                            val: data.finish || new Date(new Date().setHours(new Date().getHours() + 3)),
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData} />

                    <InputSelect2Search
                        options={{
                            type: "select2",
                            name: "sn",
                            label: t('sn'),
                            list: searchList,
                            val: data.sn,
                            required: true
                        }}
                        editable={true}
                        oninputchange={handleInputChangeData}
                        onchange={handleChangeData} />

                    {searchSN.model && <div className="form-group basic">
                        <div className="input-wrapper">
                            <label className="label">{t('model')}</label>
                            <div className="form-content multi">{searchSN.model}</div>
                        </div>
                    </div>}

                    {searchSN.option && <div className="form-group basic">
                        <div className="input-wrapper">
                            <label className="label">{t('option')}</label>
                            <div className="form-content multi">{searchSN.option}</div>
                        </div>
                    </div>}

                    {searchSN.sitename && <div className="form-group basic">
                        <div className="input-wrapper">
                            <label className="label">{t('sitename')}</label>
                            <div className="form-content multi">{searchSN.sitename}</div>
                        </div>
                    </div>}

                    <InputBasicText
                        options={{
                            name: "customer",
                            label: t('customername'),
                            placeholder: t('customername'),
                            val: data.customer || "",
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData}
                        errs={err}
                    />

                    <InputBasicTextArea
                        options={{
                            type: "textarea",
                            name: "note",
                            label: t('note'),
                            val: data.note || "",
                            required: true
                        }}
                        editable={true}
                        onchange={handleChangeData} />

                </div>
            </Modal.Body>
            <Modal.Footer className="p-1">
                <button onClick={() => validAdd()} className="btn btn-primary btn-block">{t('apply')}</button>
                {item._id && <RayDelButton callback={() => del()} css={"btn btn-danger btn-block"} />}
            </Modal.Footer>
        </Modal>
    );
};

const mapState = (state) => {
    const item = state.CalendarReducer.item;
    const isLoading = state.CalendarReducer.isLoading;
    return { item, isLoading };
}

const mapDispatch = dispatch => ({
    Create: (data) => dispatch(CalendarAction.Create(data)),
    Update: (_id, data) => dispatch(CalendarAction.Update(_id, data)),
    Delete: (_id) => dispatch(CalendarAction.Delete(_id)),
})

export default connect(mapState, mapDispatch)(ScheduleCreate);