import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CommonAction } from '../actions/CommonAction';

const WidgetItemCountCard = ({ name, title, valuetitles = [], aggregate, action = null,
	dataType = "",
	subtitle, txtcolor = "default", bgcolor = "default", options, fontsize = "48", setting = false }) => {

	const [count, setCount] = useState([]);
	const [callCount, setCallCount] = useState(0);
	const [isFetch, setIsFetch] = useState(true);

	useEffect(() => {
		if (name) {
			var savedData = JSON.parse(localStorage.getItem(name));
			if (savedData) {
				if (new Date().getTime() - savedData.timestamp > 60 * 60 * 1000)
					savedData = null;
			}
			getData(savedData ? savedData.data : null);
		} else {
			getData(null);
		}
	}, [aggregate])

	useEffect(() => {
		const timer = setTimeout(() => getData(null), 60 * 60 * 1000);
		return () => clearTimeout(timer);
	}, [callCount])

	const setCountData = (data) => {
		if (data) {
			if (valuetitles.length > 0) {
				var nCount = []
				valuetitles.map(x => {
					if (data[0].hasOwnProperty(x)) {
						nCount.push(data[0][x].count.toLocaleString());
					} else {
						nCount.push("0");
					}
				})
				setCount(nCount.join("/", nCount));
			} else {
				if (dataType == "mstoday") {
					var t = data[0].count;
					if (t === 0) {
						setCount(0);
					} else {
						t = t / 1000 / 60 / 60 / 24;
						setCount(t.toFixed(0).toLocaleString());
					}
				} else {
					setCount(data[0]?.count.toLocaleString());
				}
			}
		} else {
			setCount(0);
		}
	}

	const getData = async (savedData) => {

		setIsFetch(true);

		if (savedData) {
			setCountData(savedData);
		} else {
			const ret = await CommonAction.GetCountAction(aggregate);
			const sData = {
				timestamp: new Date().getTime(),
				data: ret.data
			}

			if (name) {
				localStorage.setItem(name, JSON.stringify(sData));
			}

			setCountData(ret.data);
		}
		setIsFetch(false);
		setCallCount(callCount > 100 ? 0 : callCount + 1);
	}

	return (
		<div className={isFetch ? "fadeLoading mb-2" : "mb-2"}>
			<div className={"card bg-" + bgcolor + " text-" + txtcolor}>
				<div className="card-header text-center separator">
					<div className='text-right'>
						{setting ? <a className='btn btn-sm mt-0' onClick={setting}><ion-icon name="settings-outline"></ion-icon></a> :
							<a className='btn btn-sm mt-0'></a>
						}
					</div>
					<div className="card-title">
						<h2 className="mb-0">{title}</h2>
						{subtitle && <span className="small mb-0">{subtitle}</span>}
						{!subtitle && <span className="small mb-0">&nbsp;</span>}
					</div>
				</div>
				<div className={"card-body text-" + txtcolor}>
					<h1 className="text-center" style={{ fontSize: fontsize + "px" }}>{count ? count : 0}</h1>
				</div>
			</div>
		</div>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var deviceCategory = state.ProgramReducer.deviceCategory;
	const showmodel = state.ProgramReducer.showmodel;
	return { user, deviceCategory, showmodel };
}

const mapDisPatch = dispatch => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
});

export default connect(mapState, mapDisPatch)(WidgetItemCountCard);
