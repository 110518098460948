import React, {useState} from 'react';
import { ResponsiveBar } from '@nivo/bar'

const RayChartBar = ({ data, category, indexBy, options }) => {

    return <div style={{height: options.height}}>
        <ResponsiveBar
            data={data}
            keys={category}
            indexBy = {indexBy}
            onClick = {options.handleClick}
            margin={options.hasOwnProperty("margin") ? options.margin : { top: 20, right: 130, bottom: 40, left: 40 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={[
                '#38c1ff',
                '#82ca9c',
                '#ffeb3b',
                '#cb94d9',
                '#cddc39',
                '#6ddbdb',
                '#fad55c',
                '#7da7d9',
                '#f5b2d8',
                '#68c182',
                '#c7b299',
                '#97b4f4',
                '#f2aba1',
                '#8ce7ce',
                '#a186be',
                '#fabd5c',
                '#998675',
                '#8bc34a',
                '#6dcff6',
                '#8781bd',
                '#f69679',
                '#00bcd4',
                '#f49ac1',
                '#fff799',
                '#c69c6d',
                '#c4df9b',
                '#8393ca',
                '#76ccc7',
                '#f5989d',
                '#fdc689',
                '#bd8cbf'
            ]}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[]}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            axisBottom={options.hasOwnProperty("axisBottom") ? options.axisBottom : true}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            legends={options.hasOwnProperty("legends") ? options.legends : [
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />
    </div>
};

export default RayChartBar;