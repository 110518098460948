import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { history } from '../history';
import { AuthConstant } from './constants/AuthConstant';
import { ProductConstant } from './constants/ProductConstant';
import { ProgramConstant } from './constants/ProgramConstant';
import { AlertAction } from './AlertAction';
import { CompanyAction } from './CompanyAction';

const Login = (username, password, isAdmin) => async (dispatch) => {
	dispatch({ type: AuthConstant.LOADING });
	dispatch({ type: AuthConstant.LOGIN_REQUEST, user: username });
	var data = {
		adminid: username,
		password: password,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/auth', { data: data });

	if (ret.data.status == 'success') {
		if (ret.data.data.user.adminid) {
			localStorage.setItem('user', JSON.stringify(ret.data.data.user));
			localStorage.setItem('clist', ret.data.data.user?.clist);
			localStorage.setItem('userToken', ret.data.data.jwt);
			dispatch(!isAdmin ?
				{ type: AuthConstant.LOGIN_SUCCESS, user: ret.data.data.user, userToken: ret.data.data.jwt } :
				{ type: AuthConstant.ADMINLOGIN_SUCCESS, user: ret.data.data.user, userToken: ret.data.data.jwt });
		} else {
			dispatch({ type: AuthConstant.LOGIN_FAILURE, error: ret.data.err });
			dispatch(AlertAction.Error(ret.data));
		}
	} else {
		dispatch({ type: AuthConstant.LOGIN_FAILURE, error: ret.data.err });
		dispatch(AlertAction.ErrorMsg("Login Failed"));
	}
	dispatch({ type: AuthConstant.LOADED });
};

const SendEmail = (type, email) => {
	return {};
};

const RequestForgotPassword = async (data) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/auth/requestForgotPassword', { data: data });
	return ret.data;
};

const UpdatePasswordByForgot = async (data) => {
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/auth/resetPasswordByForgot', { data: data });
	return ret.data;
};

const UpdatePasswordByAdmin = async (adminid, data) => {
	var user = getUserInfoByStorage();
	var data = {
		who: user.adminid,
		type: 'updatePasswordForAdmin',
		data: data,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + adminid, data, JWT());
	return ret.data;
};

const UpdateUserFav = (type, favData) => async (dispatch) => {
	dispatch({ type: AuthConstant.LOADING });
	var user = getUserInfoByStorage();
	console.log(user);
	var data = {
		who: user.adminid,
		type: 'update',
		data: favData,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	if (ret.data.status == 'success') {
		localStorage.setItem('user', JSON.stringify(ret.data.data));
		dispatch({ type: ProductConstant.UPDATE_FAVS, favs: { type: type, data: favData } });
		dispatch(AlertAction.Success('Saved'));
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: AuthConstant.LOADED });
};

const UpdateUserAdminFilters = (filters) => async (dispatch) => {
	var user = getUserInfoByStorage();
	var data = {
		who: user.adminid,
		type: 'update',
		data: filters,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	if (ret.data.status == 'success') {
		localStorage.setItem('user', JSON.stringify(ret.data.data));
		dispatch({ type: AuthConstant.UPDATE_ADMINFILTERS, user: ret.data.data });
	}
};

const UpdateUserAdminSetting = (userinfo) => async (dispatch) => {
	var user = getUserInfoByStorage();

	if (userinfo.hasOwnProperty('dashboardSettingMainNewInstalledByCountry') ||
		userinfo.hasOwnProperty('dashboardSettingMainNewInstalledByModel') ||
		userinfo.hasOwnProperty('dashboardSettingMainNewInstalledTile')) {

		const settingValue = (userinfo.hasOwnProperty('dashboardSettingMainNewInstalledByCountry') && userinfo['dashboardSettingMainNewInstalledByCountry']) ||
			(userinfo.hasOwnProperty('dashboardSettingMainNewInstalledByModel') && userinfo['dashboardSettingMainNewInstalledByModel']) ||
			(userinfo.hasOwnProperty('dashboardSettingMainNewInstalledTile') && userinfo['dashboardSettingMainNewInstalledTile'])

		userinfo = {
			...userinfo,
			dashboardSettingMainNewInstalledByCountry: settingValue,
			dashboardSettingMainNewInstalledByModel: settingValue,
			dashboardSettingMainNewInstalledTile: settingValue,
		}
	}

	if (userinfo.hasOwnProperty('dashboardSettingProductNewInstalledTile') ||
		userinfo.hasOwnProperty('dashboardSettingProductNewInstalledByModel') ||
		userinfo.hasOwnProperty('dashboardSettingProductLastInstalled')) {

		const settingValue = (userinfo.hasOwnProperty('dashboardSettingProductNewInstalledTile') && userinfo['dashboardSettingProductNewInstalledTile']) ||
			(userinfo.hasOwnProperty('dashboardSettingProductNewInstalledByModel') && userinfo['dashboardSettingProductNewInstalledByModel']) ||
			(userinfo.hasOwnProperty('dashboardSettingProductLastInstalled') && userinfo['dashboardSettingProductLastInstalled'])

		userinfo = {
			...userinfo,
			dashboardSettingProductNewInstalledTile: settingValue,
			dashboardSettingProductNewInstalledByModel: settingValue,
			dashboardSettingProductLastInstalled: settingValue,
		}
	}

	if (userinfo.hasOwnProperty('dashboardSettingServiceClosedTicketTile') ||
		userinfo.hasOwnProperty('dashboardSettingServiceClosedTicket')) {

		const settingValue = (userinfo.hasOwnProperty('dashboardSettingServiceClosedTicketTile') && userinfo['dashboardSettingServiceClosedTicketTile']) ||
			(userinfo.hasOwnProperty('dashboardSettingServiceClosedTicket') && userinfo['dashboardSettingServiceClosedTicket'])

		userinfo = {
			...userinfo,
			dashboardSettingServiceClosedTicketTile: settingValue,
			dashboardSettingServiceClosedTicket: settingValue,
		}
	}

	if (userinfo.hasOwnProperty('dashboardSettingErrorClosedErrorTile') ||
		userinfo.hasOwnProperty('dashboardSettingErrorClosedError')) {

		const settingValue = (userinfo.hasOwnProperty('dashboardSettingErrorClosedErrorTile') && userinfo['dashboardSettingErrorClosedErrorTile']) ||
			(userinfo.hasOwnProperty('dashboardSettingErrorClosedError') && userinfo['dashboardSettingErrorClosedError'])

		userinfo = {
			...userinfo,
			dashboardSettingErrorClosedErrorTile: settingValue,
			dashboardSettingErrorClosedError: settingValue,
		}
	}

	var data = { who: user.adminid, type: 'update', data: userinfo, };

	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	if (ret.data.status == 'success') {
		localStorage.setItem('user', JSON.stringify(ret.data.data));
		dispatch({ type: AuthConstant.UPDATE_ADMINSETTING, user: ret.data.data });
	}
};

const UpdateUserRoleCompany = (userinfo) => async (dispatch) => {
	dispatch({ type: AuthConstant.LOADING });
	var user = getUserInfoByStorage();
	var data = {
		who: user.adminid,
		type: 'update',
		data: userinfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	if (ret.data.status == 'success') {
		localStorage.setItem('user', JSON.stringify(ret.data.data));
		dispatch({ type: AuthConstant.UPDATE_SUCCESS, user: ret.data.data });
		var loc = history.location.pathname;
		history.push('/Admin/AdminEmpty');
		setTimeout(() => {
			history.replace(loc);
		});
		//window.location.reload();
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: AuthConstant.LOADED });
};

const UpdateUser = (userinfo) => async (dispatch) => {
	dispatch({ type: AuthConstant.LOADING });
	var user = getUserInfoByStorage();
	var data = {
		who: user.adminid,
		type: 'update',
		data: userinfo,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	if (ret.data.status == 'success') {
		localStorage.setItem('user', JSON.stringify(ret.data.data));
		dispatch({ type: AuthConstant.UPDATE_SUCCESS, user: ret.data.data });
		dispatch(AlertAction.Success('Saved'));
	} else {
		dispatch(AlertAction.ErrorMsg("Update Failed"));
	}
	dispatch({ type: AuthConstant.LOADED });
};

const checkPassword = async (password) => {
	var user = getUserInfoByStorage();
	var data = {
		who: user.adminid,
		type: 'checkPassword',
		data: {
			password: password,
		},
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	return ret.data.status == 'success';
};

const isLogin = (state) => {
	return isLogged();
};

const isAdmin = (state) => {
	return state.AuthReducer.isAdmin;
};

const ClearUpdateResult = () => (dispatch) => {
	dispatch({ type: AuthConstant.CLEAR_UPDATERES });
};

const getUserInfo = (state) => {
	return state.AuthReducer.user;
};

const Logout = () => (dispatch) => {
	localStorage.removeItem('user');
	localStorage.removeItem('clist');
	localStorage.removeItem('userToken');

	localStorage.removeItem('rtUser');
	localStorage.removeItem('rtToken');

	dispatch({ type: ProgramConstant.SIDEMENU_CLOSE })
	dispatch({ type: AuthConstant.LOGOUT });
};

/* not export */
const getUserInfoByStorage = () => {
	return JSON.parse(localStorage.getItem('user'));
};

const isAdminAccess = () => {
	const user = localStorage.getItem('user');
	if (!user)
		return false;
	if (!JSON.parse(user).permAdminAccess) {
		return false;
	}
	return true;
};
const isLogged = () => {
	const user = localStorage.getItem('user');
	if (!user)
		return false;
	if (!JSON.parse(user).adminid)
		return false;
	return true;
};

const UpdatePasswordModify = async (data) => {
	var user = getUserInfoByStorage();
	var data = {
		who: user.adminid,
		type: 'updatePassword',
		data: data,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/user/' + user.adminid, data, JWT());
	return ret.data.status == 'success';
};

export const AuthAction = {
	Login,
	Logout,
	SendEmail,
	isLogged,
	isLogin,
	isAdmin,
	isAdminAccess,
	checkPassword,
	UpdateUser,
	UpdateUserRoleCompany,
	UpdateUserFav,
	ClearUpdateResult,
	getUserInfo,
	UpdatePasswordModify,
	RequestForgotPassword,
	UpdatePasswordByForgot,
	UpdateUserAdminSetting,
	UpdateUserAdminFilters,
	UpdatePasswordByAdmin
};
