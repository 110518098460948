import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppConfig } from '../../config';
import axios from 'axios';
import { ErrorCodeAction, AlertAction, AuthAction } from '../../actions';
import { history } from '../../history';

const AdminErrorCodeDelete = ({ errorCodeInfo, isUpdateRes, Delete, ClearUpdateResult }) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
    }, []);

    useEffect(() => {
        console.log("isUpdateRes" + " ---> " + isUpdateRes);
        if (isUpdateRes) {
            ClearUpdateResult();
            history.push("/Admin/AdminErrorCodeList");
        }
    }, [isUpdateRes]);

    const handleDeleteErrorCode = (e) => {
        Delete(errorCodeInfo.errorCode, errorCodeInfo.lang);
    }

    return (
        <div className="section">
            <div className="card bg-danger text-white m-b-0">
                <div className="card-header">
                    <div className="card-title">경고!</div>
                </div>
                <div className="card-body">
                    <h4>즉시 반영됩니다!!!</h4>
                    <p>이 작업은 되돌릴 수 없는 작업입니다.</p>
                    <p>정말 삭제하시겠습니까?</p>
                </div>
            </div>
            <button className="btn btn-outline-danger fs-14" onClick={handleDeleteErrorCode}>Delete ErrorCode</button>
        </div>
    );
};

const mapState = (state) => {
    const isUpdateRes = state.ErrorCodeReducer.isUpdateRes;
    return { isUpdateRes };
}

const mapDispatch = dispatch => ({
    ClearUpdateResult: () => dispatch(ErrorCodeAction.ClearUpdateResult()),
    Delete: (errorCode, lang) => dispatch(ErrorCodeAction.Delete(errorCode, lang))
})

export default connect(mapState, mapDispatch)(AdminErrorCodeDelete);