import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate as t } from '../../config/Translate';
import { Modal } from 'react-bootstrap';

import {
	AggrErrorChartLastNDays,
	AggrLatestErrorProduct2,
	AggrLatestOpenErrorProduct2,
	AggrErrorTotalCount,
	AggrErrorOpenCount,
	AggrErrorClosedCount,
	AggrErrorOpenLastest,
	AggrErrorClosedLastest,
	AggrErrorNotCloseLastNHours
} from '../../config/Aggregates';

import WdigetItemCountCard from '../../widgets/WdigetItemCountCard';
import WidgetBarChart from '../../widgets/WidgetBarChart';
import WidgetCustomTable from '../../widgets/WidgetCustomTable';

import { InputBasicNumber } from '../../components/common/Inputs';
import { AuthAction } from '../../actions';

const AdminErrorDashboard = ({ user, UpdateUserAdminSetting, showmodel }) => {

	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setData({
			dashboardSettingErrorTotalErrorTile: user.dashboardSettingErrorTotalErrorTile || 1,
			dashboardSettingErrorClosedErrorTile: user.dashboardSettingErrorClosedErrorTile || 1,
			dashboardSettingErrorErrors: user.dashboardSettingErrorErrors || 2,
			dashboardSettingErrorClosedError: user.dashboardSettingErrorClosedError || 1,
			dashboardSettingErrorErrorTop5: user.dashboardSettingErrorErrorTop5 || 1,
			dashboardSettingErrorErrorProductTop5: user.dashboardSettingErrorErrorProductTop5 || 1

		})
	}, [user]);

	const openSettingPopup = (key, title) => {
		setSelected({ key, title, value: data[key] });
		setShowModal(true);
	}

	const updateDashboardSetting = () => {
		UpdateUserAdminSetting({ [selected.key]: selected.value });
		setShowModal(false);
	}

	const handleChange = (e) => {
		setSelected({ ...selected, value: e.target.value });
	}

	const initializeUpdateData = () => {
		setShowModal(false);
	}


	return (
		<>
			<div className="extraHeader pb-0">
				<h2 className="mb-0">{t('errordashboard')}</h2>
				<div className="ray-right">
					<Link to="/Admin/AdminErrorList" className="btn btn-sm mt-0">
						<ion-icon name="list-outline"></ion-icon> List
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">

				<div className="section dashboardTop pt-2">
					<div className="row">
						<div className="col">
							<WdigetItemCountCard
								title={t('totalerror')}
								subtitle={`${data.dashboardSettingErrorTotalErrorTile} ${t('week')}`}
								aggregate={AggrErrorTotalCount(data.dashboardSettingErrorTotalErrorTile)}
								setting={() => openSettingPopup('dashboardSettingErrorTotalErrorTile', `${t('totalerror')} (${t('week')})`)}

							/>
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('openerror')}
								subtitle={t('all')}
								aggregate={AggrErrorOpenCount()} />
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={t('closederror')}
								subtitle={`${data.dashboardSettingErrorClosedErrorTile} ${t('week')}`}
								aggregate={AggrErrorClosedCount(data.dashboardSettingErrorClosedErrorTile * 7)}
								setting={() => openSettingPopup('dashboardSettingErrorClosedErrorTile', `${t('closederror')} (${t('week')})`)} />


						</div>
						<div className="col">
							<WdigetItemCountCard
								title={`${t('exceederror')} (48${t('hrs')})`}
								subtitle={t('all')}
								aggregate={AggrErrorNotCloseLastNHours(48)} />
						</div>
						<div className="col">
							<WdigetItemCountCard
								title={`${t('exceederror')} (1${t('week')})`}
								subtitle={t('all')}
								aggregate={AggrErrorNotCloseLastNHours(24 * 7)} />
						</div>
					</div>
				</div>

				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<WidgetCustomTable
							title={t('openerror')}
							basePath='/Admin/AdminErrorCodeInfo/'
							appendix='/eng'
							linkKey='err.code'
							headers={[
								{ name: "err.code", label: t('errorcode') },
								{ name: 'site.sitename', label: t('sitename'), linkdata: 'siteId' },
								{ name: "sn", label: t('sn'), type: 'SN' },
							]}
							aggregate={AggrErrorOpenLastest(7)}
							height={300}
						/>
					</div>
					<div className="col-md-8">
						<WidgetBarChart
							title={t('errors')}
							height={313}
							days={data.dashboardSettingErrorErrors * 7}
							aggregate={AggrErrorChartLastNDays('code', data.dashboardSettingErrorErrors * 7)}
							setting={() => openSettingPopup('dashboardSettingErrorErrors', `${t('errors')} (${t('week')})`)} />
					</div>
				</div>

				<div className="row p-1 pb-0 pt-2">
					<div className="col">
						<WidgetCustomTable
							title={t('closederror')}
							basePath='/Admin/AdminErrorCodeInfo/'
							appendix='/eng'
							linkKey='err.code'
							headers={[
								{ name: "err.code", label: t('errorcode') },
								{ name: 'site.sitename', label: t('sitename'), linkdata: 'siteId' },
								{ name: "sn", label: t('sn'), type: 'SN' },
								{ name: "err.end", label: t('finish'), type: 'DTONLY' },
							]}
							aggregate={AggrErrorClosedLastest(data.dashboardSettingErrorClosedError)}
							height={300}
							setting={() => openSettingPopup('dashboardSettingErrorClosedError', `${t('closederror')} (${t('week')})`)}
						/>
					</div>
					<div className="col-md-4">
						{/*  */}
						<WidgetCustomTable
							title={`${t('error')} Top 5`}
							basePath='/Admin/AdminErrorCodeInfo/'
							appendix='/eng'
							linkKey='_id.label'
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER", align: "text-right" },
								{ name: "notCloseCount", label: t('openerror'), type: "NUMBER", align: "text-right" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrLatestOpenErrorProduct2(data.dashboardSettingErrorErrorTop5 * 7, 5)}
							height={300}
							setting={() => openSettingPopup('dashboardSettingErrorErrorTop5', `${t('error')} Top 5 (${t('week')})`)}
						/>
					</div>
					<div className="col-md-4">
						<WidgetCustomTable
							title={`${t('error')} ${t('product')} Top 5`}
							headers={[
								{ name: "sn", label: t('sn') },
								{ name: 'site.sitename', label: t('sitename'), linkdata: 'siteId' },
								{ name: "count", label: "Count", type: "NUMBER", align: "text-right" },
								{ name: "", label: "", type: "PROGRESS", value: "count", style: { minWidth: 200 } }
							]}
							aggregate={AggrLatestErrorProduct2(data.dashboardSettingErrorErrorProductTop5 * 7, 100)}
							height={300}
							setting={() => openSettingPopup('dashboardSettingErrorErrorProductTop5', `${t('error')} ${t('product')} Top 5 (${t('week')})`)}
						/>
					</div>

					<Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)}>
						<Modal.Header>
							<Modal.Title>{t('settings')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='p-1'>
								<InputBasicNumber
									options={{
										label: selected.title,
										showpin: true,
										val: selected.value,
										positive: true,
									}}
									editable={true}
									onchange={handleChange}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => updateDashboardSetting()}>{t('save')}</button>
							<button className="btn btn-block btn-dark" onClick={() => initializeUpdateData()}>{t('close')}</button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	const showmodel = state.ProgramReducer.showmodel;
	return { user, showmodel };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
})

export default connect(mapState, mapDispatch)(AdminErrorDashboard);
