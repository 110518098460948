import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SiteAction } from '../../actions/SiteAction';
import { RayTable, AdminProductSiteHistory } from '../common';
import { RayForm } from '../common';
import { SpecialityTypes } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';

const ProductInfoSite = ({ product, site, GetSite, _sn, showtitle = true }) => {
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [sitehistory, setSiteHistory] = useState([]);

	const options = {
		keyCol: "_id",
	}

	useEffect(() => {
		product.siteId && GetSite(product.siteId);
		//getHistoryInfo();
	}, [product])

	const getSiteInfo = (siteinfoList, siteId) => {
		const ret = siteinfoList.filter(x => x.siteId == siteId);
		return ret.length > 0 ? ret[0] : null;
	}

	/*
	Get Site History
	const getHistoryInfo = () => {
		if(product.sitehistories.length > 0){
			setIsLoading(true);
			const data = {
				who: JSON.parse(localStorage.getItem('user')).adminid,
				type : "getsitesinfo",
				data : product.sitehistories.map(x => x.siteId)
			};
			axios.post(AppConfig.API_SERVER_PATH + '/site', data, JWT()).then((ret) => {
				setIsLoading(false)
				if(ret.data.status == "success"){
					var nData = [...product.sitehistories].map(x => {
						if(x.siteId){
							const siteInfo = getSiteInfo(ret.data.data, x.siteId);
							return siteInfo ? 
								{...siteInfo, productUpdateDate : x.timestamp} : 
								{...x, productUpdateDate : x.timestamp, sitename : "NOT EXIST"};
						}else{
							return {...x, productUpdateDate : x.timestamp, sitename : "NOT INSTALLED"}
						}
					})
					setSiteHistory(nData);
				}else{
					console.log(ret.data);
				}
			});
		}
	}
	*/

	const form_controls = [
		{
			title: (_sn ? "" : (showtitle ? t('siteinfo') : "")), items: [
				{
					type: "input", name: "sitename", label: t('sitename'), val: site.sitename, extra: site,
					viewLinkPath: isAdmin ? "/Admin/AdminSiteInfo/" : "/Site/", viewLinkUseId: site.siteId
				},
				{ type: "select", name: "speciality", label: t('specialty'), val: site.speciality, list: SpecialityTypes },
				{ type: "tel", name: "tel", label: t('tel'), val: site.tel },
			]
		},
		{
			title: "", items: [
				{ type: "tel", name: "fax", label: t('fax'), val: site.fax },
				{ type: "tel", name: "hp", label: t('mobile'), val: site.hp }
			]
		},
		{
			title: "", items: [
				{ type: "email", name: "email", label: t('email'), val: site.email },
				{ type: "input", name: "website", label: t('homepage'), val: site.website }
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "address", label: t('address'), size: 12, val: site.address },
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "addressExtra", label: t('address') + ' ' + " Extra", size: 12, val: site.addressExtra }
			]
		},
	]

	return (
		<>
			{site.siteId && <RayForm controls={form_controls} _sn={_sn} />}
			{!site.siteId &&
				<h4 className="m-2">설치되지 않은 장비입니다.</h4>
			}
			{(isAdmin && product.sitehistories.length > 0) &&
				<>
					{showtitle && <h5>Site history</h5>}
					<RayTable
						_sn={_sn}
						data={sitehistory}
						columns={AdminProductSiteHistory}
						options={options} />
				</>
			}
		</>
	);
};

const mapState = (state) => {
	const site = state.SiteReducer.site;
	return { site };
}

const mapDispatch = dispatch => ({
	UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data)),
	GetSite: (_id) => dispatch(SiteAction.GetSite(_id))
})

export default connect(mapState, mapDispatch)(ProductInfoSite);