import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { JiraConstant } from './constants/JiraConstant';
import { AlertAction } from './AlertAction';

const Get = (jirakey, _id) => async (dispatch) => {
	dispatch({ type : JiraConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/jira/get', { jirakey : jirakey, _id : _id }, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: JiraConstant.GET, issueInfo : JSON.parse(ret.data.data.data) });
	} else {
		dispatch(AlertAction.ErrorMsg(ret.data.err));
	}
	dispatch({ type : JiraConstant.LOADED });
};

const Add = (data) => async (dispatch) => {
	dispatch({ type : JiraConstant.LOADING });
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/jira/add', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: JiraConstant.ADD, issue : JSON.parse(ret.data.data.data) });
	} else {
		dispatch(AlertAction.ErrorMsg(ret.data.err));
	}
	dispatch({ type : JiraConstant.LOADED });
};

export const JiraAction = {
	Add,
	Get
};
