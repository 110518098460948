import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SiteSurveyAction } from '../../actions/SiteSurveyAction';
import { RayForm } from '../../components/common';
import { SpecialityTypes } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';
import moment from 'moment';

const AdminSiteSurveyInfo = ({ siteSurvey, GetSiteSurvey }) => {

    const { id, view } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        console.log(siteSurvey.siteSitename);
        setIsLoading(id != siteSurvey._id)
    }, [siteSurvey])

    useEffect(() => {
        console.log(siteSurvey);
        id != siteSurvey._id && GetSiteSurvey(id);
    }, [])

    const form_controls = [
        {
            title: t('practice'), items: [
                { type: "input", name: "sitename", label: t('sitename'), val: siteSurvey.siteSitename, required: false },
                { type: "select", name: "speciality", label: t('specialty'), val: siteSurvey.siteSpeciality, list: SpecialityTypes }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "country", label: t('country'), val: siteSurvey.siteCountry },
                { type: "input", name: "address", label: t('address'), val: siteSurvey.siteAddress + ' ' + siteSurvey.siteAddressExtra }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "zip", label: t('zipcode'), val: siteSurvey.siteZipcode },
                { type: "input", name: "website", label: t('website'), val: siteSurvey.siteWebsite }
            ]
        },

        {
            title: t('customer'), items: [
                { type: "input", name: "firstname", label: t('firstname'), val: siteSurvey.customerFirstName },
                { type: "input", name: "lastname", label: t('lastname'), val: siteSurvey.customerLastName }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "email", label: t('email'), val: siteSurvey.customerEmail },
                { type: "input", name: "customerHp", label: t('mobile'), val: siteSurvey.customerHp }
            ]
        },
        {
            title: t('installation'), items: [
                { type: "input", name: "model", label: t('model'), val: siteSurvey.model },
                { type: "input", name: "option", label: t('option'), val: siteSurvey.option }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "scheduled", label: t('installeddate'), val: moment(siteSurvey.scheduleDate).format("YYYY-MM-DD") },
                { type: "input", name: "usertraining", label: t('usertraining'), val: moment(siteSurvey.trainingDate).format("YYYY-MM-DD") }
            ]
        },

        {
            title: t('additionalorders'), items: [
                { type: "tel", name: "mainunit", label: t('mainunit'), size: 3, val: siteSurvey.additionalMainUnit },
                { type: "tel", name: "portablexray", label: t('portable'), size: 3, val: siteSurvey.additionalPortableXray },
                { type: "tel", name: "threedprint", label: t('threedprinter'), size: 3, val: siteSurvey.additionalThreedPrinter },
                { type: "tel", name: "curingunit", label: t('curingunit'), size: 3, val: siteSurvey.additionalCurningUnit }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "sensorsize1", label: t('riosensorsize1'), size: 3, val: siteSurvey.additionalRioSensorSize1 },
                { type: "tel", name: "portablexray", label: t('riosensorsize1'), size: 3, val: siteSurvey.additionalRioSensorSize2 },
                { type: "tel", name: "scanstandard", label: t('rioscanstandard'), size: 3, val: siteSurvey.additionalRioScanStandard },
                { type: "tel", name: "scanocclusal", label: t('rioscanocclusal'), size: 3, val: siteSurvey.additionalRioScanOcctusal }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "objectscankit", label: t('objectscankit'), val: siteSurvey.additionalObjectScankit },
                { type: "input", name: "rayceph", label: "RayCeph", val: siteSurvey.additionalRayCeph }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "rayscanweb", label: "RAYSCAN Web", val: siteSurvey.additionalRayScanweb },
                { type: "input", name: "addtionalremark", label: t('remarks'), val: siteSurvey.additionalRemark }
            ]
        },

        {
            title: t('resin') + ' (' + (siteSurvey.additionalCb500
                + siteSurvey.additionalCb1l
                + siteSurvey.additionalSg500
                + siteSurvey.additionalSg1l
                + siteSurvey.additionalDb500
                + siteSurvey.additionalDb1l) + ')', items: [
                    { type: "tel", name: "cb500", label: "C&B / 500ml", size: 3, val: siteSurvey.additionalCb500 },
                    { type: "tel", name: "cb1l", label: "C&B / 1L", size: 3, val: siteSurvey.additionalCb1l },
                    { type: "tel", name: "sg500ml", label: "SG / 500ml", size: 3, val: siteSurvey.additionalSg500 },
                    { type: "tel", name: "portablexray", label: "SG / 1L", size: 3, val: siteSurvey.additionalSg1l }
                ]
        },
        {
            title: "", items: [
                { type: "tel", name: "Cb500", label: "DM / 500ml", size: 3, val: siteSurvey.additionalDb500 },
                { type: "tel", name: "portablexray", label: "DM / 1L", size: 3, val: siteSurvey.additionalDb1l }
            ]
        },

        {
            title: t('environment'), items: [
                { type: "tel", name: "environmentUnitChairs", label: t('unitchairs'), size: 3, val: siteSurvey.environmentUnitChairs },
                { type: "tel", name: "environmentClientPc", label: t('clientpc'), size: 3, val: siteSurvey.environmentClientPc },
                { type: "tel", name: "environmentWalmountXray", label: t('wallmount'), size: 3, val: siteSurvey.environmentWalmountXray },
                { type: "tel", name: "environmentPotableXray", label: t('portable'), size: 3, val: siteSurvey.environmentPotableXray }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "CenvironmentWalmountXrayManufactureb500", label: "Wall-Mounted X-ray Manufacture", val: siteSurvey.environmentWalmountXrayManufacture },
                { type: "tel", name: "environmentPotableXrayManufacture", label: "Portable X-ray Manufacture", val: siteSurvey.environmentPotableXrayManufacture }
            ]
        },

        {
            title: t('shieldroom'), items: [
                { type: "input", name: "shieldroomConstruction", label: t('construction'), val: siteSurvey.shieldroomConstruction },
                { type: "input", name: "outputvoltage", label: t('outputvoltage'), val: siteSurvey.shieldroomOutputVtg + 'V' + ' ' + siteSurvey.shieldroomOutputHz + 'Hz' }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "shieldroomGrounded", label: t('grounded'), size: 3, val: siteSurvey.shieldroomGrounded },
                { type: "input", name: "shieldroomAvrCharged", label: t('avr'), size: 3, val: siteSurvey.shieldroomAvrCharged },
                { type: "input", name: "shieldroomGrounded", label: t('l1ground'), size: 3, val: siteSurvey.shieldroomlGroundVoltage1 },
                { type: "input", name: "shieldroomAvrCharged", label: t('l2ground'), size: 3, val: siteSurvey.shieldroomlGroundVoltage2 }
            ]
        },

        {
            title: t('workstation'), items: [
                { type: "input", name: "workstationMonitor", label: t('monitor'), size: 4, val: siteSurvey.workstationMonitor },
                { type: "input", name: "workstationLocation", label: t('location'), size: 4, val: siteSurvey.workstationLocation },
                { type: "input", name: "workstationShelf", label: t('shelf'), size: 4, val: siteSurvey.workstationShelf }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "workstationRemark", label: t('remarks'), val: siteSurvey.workstationRemark }
            ]
        },

        {
            title: t('exposureswitch'), items: [
                { type: "input", name: "exposureswitchLocation", label: t('location'), size: 4, val: siteSurvey.exposureswitchLocation },
                { type: "input", name: "exposureswitchInterlockModel", label: t('interlock'), size: 4, val: siteSurvey.exposureswitchInterlockModel }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "exposureswitchRemark", label: t('remarks'), val: siteSurvey.exposureswitchRemark }
            ]
        },


        {
            title: t('software'), items: [
                { type: "input", name: "softwareMainProduct", label: t('mainviewerproduct'), size: 3, val: siteSurvey.softwareMainProduct },
                { type: "input", name: "softwareThreedProduct", label: t('threedviewerproduct'), size: 3, val: siteSurvey.softwareThreedProduct },
                { type: "input", name: "softwareCephProduct", label: t("cephtracingproduct"), size: 3, val: siteSurvey.softwareCephProduct },
                { type: "input", name: "softwareOtherProduct", label: t('otherproduct'), size: 3, val: siteSurvey.softwareOtherProduct },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "softwareRemark", label: t('remarks'), val: siteSurvey.softwareRemark }
            ]
        },

        {
            title: t('network'), items: [
                { type: "input", name: "networkConstruction", label: t('construction'), size: 4, val: siteSurvey.networkConstruction },
                { type: "input", name: "networkRouter", label: t('router'), size: 4, val: siteSurvey.networkRouter },
                { type: "input", name: "networkInternet", label: t('internet'), size: 4, val: siteSurvey.networkInternet }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "networkRemark", label: t('remarks'), val: siteSurvey.networkRemark }
            ]
        },

        {
            title: t('delivery'), items: [
                { type: "input", name: "deliveryDate", label: t('deliverydate'), size: 4, val: moment(siteSurvey.deliveryDate).format("YYYY-MM-DD") },
                { type: "input", name: "accessibility", label: t('accessibility'), size: 4, val: siteSurvey.accessibility },
                { type: "input", name: "liftYN", label: t('lift'), size: 4, val: siteSurvey.liftYN }
            ]
        },
        {
            title: "Data Migration", items: [
                { type: "input", name: "datamigrationRequired", label: t('required'), size: 4, val: siteSurvey.datamigrationRequired },
                { type: "input", name: "accessibility", label: t('modality'), size: 4, val: siteSurvey.datamigrationModality },
                { type: "input", name: "liftYN", label: t('expense'), size: 4, val: siteSurvey.datamigrationExpense }
            ]
        }

    ]

    return (
        <>
            {isLoading && <div className="">Loading...</div>}
            {!isLoading &&
                <>
                    <div className="extraHeader">
                        <h2 className="mb-0">{siteSurvey.siteSitename}</h2>
                        <div className="ray-right">
                            <Link to="/Admin/AdminSiteSurveyList" className="btn btn-default btn-sm mt-0 me-1">List</Link>
                        </div>
                    </div>
                    <div id="appCapsule" className="extra-header-active full-height">
                        <RayForm controls={form_controls} errors={errors} />
                    </div>
                </>
            }
        </>
    );
};

const mapState = (state) => {
    const siteSurvey = state.SiteSurveyReducer.siteSurvey;
    const isClose = state.AlertReducer.isClose;
    return { siteSurvey, isClose };
}

const mapDispatch = dispatch => ({
    GetSiteSurvey: (_id) => dispatch(SiteSurveyAction.GetSiteSurvey(_id)),
})
export default connect(mapState, mapDispatch)(AdminSiteSurveyInfo);