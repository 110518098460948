import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { history } from '../../history';
import { AuthAction } from '../../actions/AuthAction';
import { AppHeader } from '../../components/common';
import UserAccount from './UserAccount';
import UserSettings from './UserSettings';
import { Translate as t } from '../../config/Translate';

export const MyInfo = ({ user }) => {

	// const [t, i18n] = useTranslation();
	const { view } = useParams();
	const [maintab, setMainTab] = useState(view || "info");
	const [editable, setEditable] = useState(false);

	useEffect(() => {
		setMainTab(view || "info")
	}, [view])

	const mainTabList = [
		{ code: "info", title: t('myaccount'), icon: "info" },
		{ code: "setting", title: t('settings'), icon: "info" },
	]

	const goUrl = (code) => {
		history.push("/MyInfo/" + code);
	}

	return (
		<>
			<div className="appHeader full-color">
				<AppHeader title={user && user.adminid} />
				<div className="right">
					{(!editable && maintab == "info") &&
						<a href onClick={() => setEditable(!editable)} className="headerButton">
							<ion-icon name="create-outline"></ion-icon>
						</a>
					}
				</div>
			</div>
			<div id="appCapsule">
				<div className="section">
					<ul className="nav nav-tabs style2 mt-1 mb-1">
						{mainTabList.map(x =>
							<li className="nav-item">
								<button key={x.code}
									className={maintab == x.code ? "nav-link active" : "nav-link"} onClick={() => { goUrl(x.code); }}>
									{x.title}
								</button>
							</li>
						)}
					</ul>
				</div>
				{maintab == "info" && <UserAccount editable={editable} callback={() => setEditable(false)} />}
				{maintab == "setting" && <UserSettings editable={editable} callback={() => setEditable(false)} />}
			</div>
		</>
	)
}

const mapState = state => {
	const user = AuthAction.getUserInfo(state);
	return { user }
}

export default connect(mapState)(MyInfo);