import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { DeviceModels, LanguageCode } from '../../config';
import ProductErrorInfo from '../../components/product/ProductErrorInfo';
import ProductInfo from '../../components/product/ProductInfo';
import { connect } from 'react-redux';
import moment from 'moment';
import { ErrorCodeAction, ProductAction } from '../../actions';
import { CommonAction } from '../../actions/CommonAction';
import { ErrorCodeInfo } from '../../components/errorCode'
import { Translate as t } from '../../config/Translate';

import { AggrErrorCodeLastNDays, AggrErrorByCodeLastNDays, AggrAllErrCount, AggrBeginEndDiff } from '../../config/Aggregates';
import { history } from '../../history';
import ServiceByErrorCode from '../../components/service/ServiceByErrorCode';
import WidgetCustomTable from '../../widgets/WidgetCustomTable';
import WidgetBarChart from '../../widgets/WidgetBarChart';

const AdminErrorInfo = ({ su, product, GetErrorCode, errorCodeInfo, GetProduct }) => {

	const { sn, eCode, errId, view } = useParams();
	const [tab, setTab] = useState(view || "info");
	const [isLoading, setIsLoading] = useState(true);
	const [editable, setEditable] = useState(false);
	const [data, setData] = useState({ code: "" });
	const [allClose, setAllClose] = useState({});
	const [beginEnd, setBeginEnd] = useState(0);

	useEffect(() => {
		if (data.code) {
			GetErrorCode(data.code, "eng");
			getErrCodeExtraInfo();
		}
	}, [data])

	useEffect(() => {
		setIsLoading(false);
	}, [errorCodeInfo])

	useEffect(() => {
		setIsLoading(true);
		if (sn && errId) {
			GetProduct(sn);
			getErroInfo();
			getErrCodeBeginEndDiff();
		}
	}, [])

	const getErrCodeBeginEndDiff = async () => {
		const diffInfo = await getData(AggrBeginEndDiff(data.code));
		if (diffInfo.length > 0) {
			setBeginEnd(Math.round(diffInfo[0].periodsum / (diffInfo[0].allcount * 1000)));
		}
	}

	const getErrCodeExtraInfo = async () => {
		const countInfo = await getData(AggrAllErrCount(data.code));
		if (countInfo.length > 0) {
			setAllClose({
				all: countInfo[0].value,
				open: countInfo[0].notCloseCount,
			})
		}
	}

	const getData = async (aggregate) => {
		var extra = await CommonAction.GetCountAction(aggregate);
		return extra.data;
	}

	const getErroInfo = async () => {
		var errorInfo = await ProductAction.GetProductErrorId(sn, errId)
		if (errorInfo) {
			console.log(errorInfo);
			setData({ ...errorInfo });
		}
	}

	const tabList = [
		{ code: "info", title: t('overview') },
		{ code: "service", title: t('service') },
		{ code: "err", title: t('information') },
	]

	const errorCodePrdheaders = [
		{ name: "_id", label: t('model') },
		{ name: "value", label: "Count", type: "NUMBER" },
		{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
	];

	const errorCodeSNheaders = [
		{ name: "_id", label: t('sn'), type: "SN" },
		{ name: "value", label: "Count", type: "NUMBER" },
		{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
	];

	return (
		<>
			{!isLoading && (
				<>
					{errorCodeInfo &&
						<>
							<div className="extraHeader">
								<h2 className="mb-0">
									{errorCodeInfo.errorCode}
									{<span className="badge badge-primary ms-1">Open : {allClose.open} / All : {allClose.all}</span>}
									{<span className="badge badge-warning ms-1">Begin ~ End Time(Avg.) : {moment.duration(beginEnd, 'second').humanize()}</span>}
								</h2>
								<div className="ray-right">
									<Link to="/Admin/AdminErrorList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
								</div>
							</div>
							<div id="appCapsule" className="extra-header-active full-height">
								<div className="section mt-1 mb-1">
									<ul className="nav nav-tabs style2 mt-1 mb-1">
										{tabList.map(x => {
											return (
												<li className="nav-item" key={x.code}>
													<Link to={"/Admin/AdminErrorInfo/" + eCode + "/" + sn + "/" + errId + "/" + x.code}
														className={tab == x.code ? "nav-link active" : "nav-link"}
														onClick={() => setTab(x.code)}>{x.title}</Link>
												</li>
											)
										})}
									</ul>
								</div>
								{tab == "info" &&
									<div className="section">
										<WidgetBarChart
											title={errorCodeInfo.errorCode + ` ${t('occurred')} - 1 Months`}
											height={400}
											days={30}
											aggregate={AggrErrorByCodeLastNDays(errorCodeInfo.errorCode, 'isClose', 30)} />
										<div className="row">
											<div className="col ps-1">
												<WidgetCustomTable
													name="wLatestAcqProduct"
													title={errorCodeInfo.errorCode + ` ${t('bymodel')} (1 Months)`}
													headers={errorCodePrdheaders}
													aggregate={AggrErrorCodeLastNDays(errorCodeInfo.errorCode, 'model', 30)} />
											</div>
											<div className="col">
												<WidgetCustomTable
													name="wLatestAcqProduct"
													title={errorCodeInfo.errorCode + ` ${t('byproduct')} (1 Months)`}
													headers={errorCodeSNheaders}
													aggregate={AggrErrorCodeLastNDays(errorCodeInfo.errorCode, 'sn', 30, 10)} />
											</div>
											<div className="col pe-1" style={{ margin: -8 }}>
												<ProductErrorInfo errorCodeInfo={data} />
												<ProductInfo productInfo={product} editable={false} showsn={true} />
											</div>
										</div>
									</div>}
								{tab == "service" && errorCodeInfo.errorCode && <ServiceByErrorCode errorCode={errorCodeInfo.errorCode} />}
								{tab == "err" && errorCodeInfo.errorCode && <ErrorCodeInfo errorCodeInfo={errorCodeInfo} readonly={true} />}
							</div>
						</>
					}
					{!errorCodeInfo && <div id="appCapsule" className="full-height mt-2">
						<div className="section">
							<div className="alert alert-danger">에러 코드는 존재하지 않는 ErrorCode 입니다.</div>
							<button className="btn btn-dark" onClick={() => history.goBack()}>{t('prevpage')}</button>
						</div>
					</div>}
				</>
			)}
			{isLoading && <div className="">Loading...</div>}
		</>
	);
};

const mapState = (state) => {
	const su = state.AuthReducer.su;
	const product = state.ProductReducer.product;
	const errorCodeInfo = state.ErrorCodeReducer.errorCode;
	return { su, product, errorCodeInfo };
};

const mapDispatch = (dispatch) => ({
	GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
	GetErrorCode: (errorCode, lang) => dispatch(ErrorCodeAction.GetOne(errorCode, lang)),
});

export default connect(mapState, mapDispatch)(AdminErrorInfo);
