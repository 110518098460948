import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { InstallationAction, ProductAction } from '../../actions';
import InstallationStep2 from '../../components/installation/InstallationStep2';
import InstallationStep3 from '../../components/installation/InstallationStep3';
import InstallationStep4 from '../../components/installation/InstallationStep4';
import InstallationStep5 from '../../components/installation/InstallationStep5';
import InstallationStep6 from '../../components/installation/InstallationStep6';
import { SpecialityTypes } from '../../config/RayCodes';
import { CountryCode } from '../../config';
import { useParams, Link } from 'react-router-dom';
import { RayForm, RaySignaturePad } from '../../components/common';
import { Translate as t } from '../../config/Translate';

const AdminInstallationInfo = ({ installation, product, GetInstallation, GetProduct }) => {

    const { id, view } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const isSummary = true;

    useEffect(() => {
        setIsLoading(id != installation._id)
        if (installation.sn)
            GetProduct(installation.sn)
    }, [installation])

    useEffect(() => {
        id != installation._id && GetInstallation(id);
    }, [])

    const form_controls = [
        {
            title: t('practice'), items: [
                { type: "input", name: "sitename", label: t('sitename'), val: installation.sitename, isEditable: !isSummary },
                { type: "select", name: "speciality", label: t('specialty'), val: installation.speciality, list: SpecialityTypes, isEditable: !isSummary }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "tel", label: t('tel'), val: installation.tel, isEditable: !isSummary },
                { type: "tel", name: "fax", label: t('fax'), val: installation.fax, isEditable: !isSummary }
            ]
        },
        {
            title: "", items: [
                { type: "email", name: "email", label: t('email'), val: installation.email, isEditable: !isSummary },
                { type: "tel", name: "hp", label: t('mobile'), val: installation.hp, isEditable: !isSummary }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "address", label: t('address'), val: installation.address, isEditable: !isSummary },
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "zipcode", label: t('zipcode'), val: installation.zipcode, isEditable: !isSummary },
                { type: "select", name: "country", label: t('country'), val: installation.country, list: CountryCode, isEditable: !isSummary }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "website", label: t('homepage'), val: installation.website, isEditable: !isSummary }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "customerFirstName", label: t('fistname'), val: installation.customerFirstName, isEditable: !isSummary },
                { type: "input", name: "customerLastName", label: t('lastname'), val: installation.customerLastName, isEditable: !isSummary },
                { type: "email", name: "customerEmail", label: t('email'), val: installation.customerEmail, isEditable: !isSummary },
                { type: "tel", name: "customerHp", label: t('mobile'), val: installation.customerHp, isEditable: !isSummary }
            ]
        },
        {
            title: t('productinfo'), items: [
                {
                    type: "input", name: "sn", label: t('sn'),
                    val: installation.sn, isEditable: false
                },
                { type: "input", name: "model", label: t('model'), val: installation.model, isEditable: !isSummary, required: true },
                { type: "input", name: "option", label: t('option'), val: installation.option, isEditable: !isSummary, required: true },
            ]
        },
        {
            title: "Install Information", items: [
                { type: "datetime", name: "installationDate", label: t('installdate'), onlyDate: true, val: installation.installationDate, isEditable: !isSummary },
                { type: "input", name: "installerName", label: t('installer'), val: installation.installerName, isEditable: !isSummary },
            ]
        },
    ]

    return (
        <>
            {isLoading && <div className="">Loading...</div>}
            {(!isLoading && product.sn) &&
                <>
                    <div className="extraHeader">
                        <h2 className="mb-0">{installation.sn}</h2>
                        <div className="ray-right">
                            <Link to="/Admin/AdminInstallationList" className="btn btn-default btn-sm mt-0 me-1">List</Link>
                        </div>
                    </div>
                    <div id="appCapsule" className="extra-header-active full-height">
                        <RayForm controls={form_controls} />
                        <div className="section mt-1">
                            <div className="section-title">{t('packagingcheck')}</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <InstallationStep2 info={installation} product={product} isSummary={isSummary} />
                                </div>
                            </div>
                        </div>
                        <div className="section mt-1">
                            <div className="section-title">{t('systemconfig')}</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <InstallationStep3 info={installation} product={product} isSummary={isSummary} />
                                </div>
                            </div>
                        </div>
                        <div className="section mt-1">
                            <div className="section-title">{t('imagequality')}</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <InstallationStep4 info={installation} product={product} isSummary={isSummary} />
                                </div>
                            </div>
                        </div>
                        <div className="section mt-1">
                            <div className="section-title">{t('regulatorydocument')}</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <InstallationStep5 info={installation} product={product} isSummary={isSummary} />
                                </div>
                            </div>
                        </div>
                        <div className="section mt-1">
                            <div className="section-title">{t('usertrainingcheck')}</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <InstallationStep6 info={installation} product={product} isSummary={isSummary} />
                                </div>
                            </div>
                        </div>
                        <div className='section mt-1'>
                            <div className='section-title'>{t('signature')}</div>
                            <div className='card'>
                                <div className='card-body pb-1 pt-1'>
                                    <RaySignaturePad sigType="Installer" reportId={installation._id} sigPath={installation} view="view"></RaySignaturePad>
                                    <RaySignaturePad sigType="Customer" reportId={installation._id} sigPath={installation} view="view"></RaySignaturePad>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

const mapState = (state) => {
    const installation = state.InstallationReducer.installation;
    const product = state.ProductReducer.product;
    const isClose = state.AlertReducer.isClose;
    return { installation, isClose, product };
}

const mapDispatch = dispatch => ({
    GetInstallation: (_id) => dispatch(InstallationAction.GetInstallation(_id)),
    GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
})

export default connect(mapState, mapDispatch)(AdminInstallationInfo);