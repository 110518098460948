import moment from 'moment-timezone';

export const TimeZoneCode = () => {
    const timeZones = moment.tz.names();
    const dict = [];
    for(var i in timeZones)
    {
        dict.push({ code : i , title : "(GMT"+moment.tz(timeZones[i]).format('Z')+") " + timeZones[i]});
    }
    return dict;
}