import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PickImage, UploadImage } from '../../components/common/Camera';
import { InstallationAction, ErrorCodeAction, ProductAction } from '../../actions';
import { FileUpload, FileItem } from '../../components/common/FileUpload';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const InstallationStep5 = ({ info, Update, isSummary = false, Attachment }) => {
	// const [ t, i18n ] = useTranslation();
	const [installation, setInstallation] = useState(info);
	const [docs, setDocs] = useState(installation.docs || []);

	useEffect(() => {
		setInstallation(info);
	}, [info]);

	const Prev = () => {
		Update(installation._id, { installationStep: 5 });
	};

	const validNext = () => {
		Update(installation._id, {
			installationStep: 7,
			docs: docs
		});
	};

	const setNewFile = (file, fileType) => {
		Attachment(installation._id, "ADD", {
			...installation,
			name: file.original,
			path: file.s3path,
			attachmentType: fileType
		});
	};

	const delFile = (c) => {
		Attachment(installation._id, "DEL", {
			...installation,
			delAttachid: c._id
		});
	};

	return (
		<>
			<div className="section">
				{!isSummary &&
					<>
						<div className="section-title">{t('regulatorydocument')}</div>
						<blockquote className="blockquote">
							<p class="mb-0">{t('regulatorydocumentcomment')}</p>
						</blockquote>
						<div className="mb-2">
							<FileUpload
								option={{ doneandnew: true, label: t('upload') }}
								extraData={{ path: "installation/" + installation._id + "/" }}
								callback={(e) => setNewFile(e, 'doc')} />
						</div>
					</>
				}
				<ul className="list-group">
					{installation.attachments.filter(item => 'doc' == item.type).length > 0 &&
						<>
							{installation.attachments.map((c, idx) =>
								c.type == "doc" &&
								<li className="list-group-item ps-1" key={c._id}>
									<FileItem fileInfo={{ ...c, original: c.name, s3path: c.path }} callback={(c) => delFile(c)} key={idx} />
								</li>
							)}
						</>
					}
				</ul>
				{(isSummary && installation.attachments.filter(item => 'doc' == item.type).length === 0) &&
					<div className="mb-2">
						<i>No Document</i>
					</div>}
			</div>
			{!isSummary && <div className="section mt-1 mb-2">
				<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
				<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
			</div>}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	Attachment: (_id, type, data) => dispatch(InstallationAction.Attachment(_id, type, data)),
});

export default connect(null, mapDispatch)(InstallationStep5);