import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { SiteAction, AlertAction, AuthAction, SiteSurveyAction, InstallationAction } from '../../actions';
import { RayDelButton } from '../common/Buttons';
import { Translate as t } from '../../config/Translate';

const IntallationCard = ({ item, DeleteInstallation }) => {

	const [view, setView] = useState(false);
	// const [ t, i18n ] = useTranslation();
	const del = () => {
		DeleteInstallation(item._id);
	}

	return (
		<div className="accordion round-box progress-card mb-1">
			<div className="accordion-item">
				<h2 className="accordion-header">
					<button className="accordion-button collapsed" onClick={() => setView(!view)} style={{ width: "100%" }}>
						<div className="item">
							{!item.isSubmit && <div className="in">
								<div>
									{item.site?.sitename && <h4>{item.site?.sitename}</h4>}
									{item.product && <p>{item.product.model} {item.product.option && "/ " + item.product.option}</p>}
								</div>
								<div className="price">{item.installationStep} / 8</div>
							</div>}
							{!item.isSubmit && <div className="progress mt-1">
								<div className="progress-bar bg-success" style={{ width: ((item.installationStep / 8) * 100) + "%" }}>{Math.round((item.installationStep / 8) * 100) + "%"}</div>
							</div>}
							{item.isSubmit && <div>
								<ion-icon name="checkmark-circle" size="large" class="text-success fl md hydrated"></ion-icon>
								{item.site?.sitename && <h4>{item.site?.sitename}</h4>}
								{item.product && <p>{item.product.model} {item.product.option && "/ " + item.product.option}</p>}
							</div>}
						</div>
					</button>
				</h2>
				{view && <div className="accordion-collapse">
					<div className="accordion-body mt-0">
						{!item.isSubmit && <Link to={"/Installation/" + item._id} className="btn btn-block btn-primary">{t('edit')}</Link>}
						{item.isSubmit && <Link to={"/Installation/" + item._id + "/view"} className="btn btn-block btn-primary">{t('view')}</Link>}
						{!item.isSubmit && <RayDelButton css="btn btn-block btn-dark" callback={() => del()} startMsg={t('delete')} />}
					</div>
				</div>}
			</div>
		</div>
	)
};

const mapDispatch = dispatch => ({
	DeleteInstallation: (_id) => dispatch(InstallationAction.DeleteInstallation(_id)),
})

export default connect(null, mapDispatch)(IntallationCard);