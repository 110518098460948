import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { LanguageCode, DeviceModels, Sitemap } from '../../config';
import { ErrorCodeReducer } from '../../reducers/ErrorCodeReducer';
import { Modal } from 'react-bootstrap';
import {
    InputBasicText,
    InputBasicSelect
} from "../../components/common/Inputs";
import { ErrorCodeAction } from '../../actions';
import ErrorCodeView from '../../components/errorCode/ErrorCodeView';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Translate as t } from '../../config/Translate';

const ErrorCodeLookup = ({ user, eCode, isShow }) => {

    const dispatch = useDispatch();
    //const [t, i18n] = useTranslation();
    const [exist, setExist] = useState(true);
    const [show, setShow] = useState(false);
    const [searchType, setSearchType] = useState("modelcode");
    const [resultList, setResultList] = useState([]);
    const [result, setResult] = useState({
        solutions: [],
        attachments: [],
    });
    const [search, setSearch] = useState({
        model: DeviceModels[0].code,
        errorCode: eCode || "",
        lang: user.rguardSettingLanguage || "eng"
    });

    useEffect(() => {
        goSearch();
        setShow(isShow);
    }, [isShow]);

    useEffect(() => {
        goSearch();
    }, []);

    const goSearch = () => {
        if (eCode) {
            setSearchType("model");
            setSearch({
                model: "",
                errorCode: eCode
            });
            searchErrorCode("model");
        }
    }

    const handleSearchClick = (e) => {
        e.preventDefault();
        searchErrorCode();
    }

    const searchErrorCode = async (searchType) => {
        const list = await ErrorCodeAction.GetOneOnlyErrorCode(search.model, eCode);
        //if(searchType == "model")
        //setResultList(list);
        if (list.length > 0) {
            var viewInfo = list.filter(x => x.lang == user.rguardSettingLanguage);
            if (viewInfo.length > 0) {
                setResult(viewInfo[0]);
            } else {
                viewInfo = list.filter(x => x.lang == "eng");
                if (viewInfo.length > 0) {
                    setResult(viewInfo && viewInfo[0]);
                } else {
                    setResult(list[0]);
                }
            }
            setExist(true);
        } else {
            setExist(false);
            setResult({
                solutions: [],
                attachments: [],
            });
        }
    }

    const clearSearch = () => {
        history.push("/ErrorCodeLookup/");
        setSearchType("modelcode");
        setSearch({
            model: "",
            errorCode: ""
        });
        setResult({
            solutions: [],
            attachments: [],
        });
    }

    const handleChangeData = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value });
    }

    const closeModal = () => {
        dispatch({ type: ProgramConstant.ERRORCODELOOKUP_HIDE });
    }

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={() => closeModal()} dialogClassName="modal-fullscreen">
                <Modal.Header>
                    <Modal.Title>{eCode || t('errorcodelookup')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {searchType == "modelcode" &&
                        <>
                            <div className="card">
                                <div className="card-body">
                                    <InputBasicSelect
                                        options={{ name: "model", val: search.model, list: DeviceModels, label: t('model'), size: "full" }}
                                        isForm={true}
                                        editable={true}
                                        onchange={handleChangeData} />
                                    <InputBasicText
                                        options={{ name: "errorCode", val: search.errorCode, label: t('errorcode'), placeholder: t('errorcodelookupcomment'), size: "full" }}
                                        isForm={true}
                                        editable={true}
                                        onchange={handleChangeData} />
                                </div>
                            </div>
                            <button
                                disabled={search.errorCode.length < 5}
                                onClick={handleSearchClick}
                                className="btn btn-primary btn-block">{t('search')}</button>
                        </>
                    }
                    {(searchType == "model" && resultList.length > 0) &&
                        <div className="section mt-2 mb-2">
                            <div className="card">
                                <div className="card-body">
                                    {resultList.map(x => <div className="" onClick={() => setResult(x)}>{x.model} ({x.lang}</div>)}
                                </div>
                            </div>
                        </div>
                    }
                    {result.errorCode &&
                        <>
                            <ErrorCodeView errorCodeInfo={result} />
                        </>
                    }
                    <div className="section mt-2 mb-2">
                        {!exist && <em>No Data</em>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary btn-block" onClick={() => closeModal()}>{t('close')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapState = state => {
    const user = state.AuthReducer.user;
    const errorCode = ErrorCodeReducer.errorCode;
    const isShow = state.ProgramReducer.errorLookupShow;
    const eCode = state.ProgramReducer.errorLookupCode;
    return { user, errorCode, eCode, isShow };
}

const mapDispatch = dispatch => ({
    GetOne: (errorCode, lang) => dispatch(ErrorCodeAction.GetOne(errorCode, lang))
})

export default connect(mapState, mapDispatch)(ErrorCodeLookup);