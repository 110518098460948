import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Flag } from '../common/CommonViews';
import { AuthAction } from '../../actions';
import { Translate as t } from '../../config/Translate';

const SiteCard = ({ item, favList, UpdateUserFav, showOnly = false }) => {

	const isFav = favList ? favList.includes(item._id) : false;

	const showDateTime = (r) => {
		var rList = [];
		rList.push(moment.utc(r).local().fromNow());
		rList.push(<small>({moment.utc(r).local().format('YYYY-MM-DD HH:mm:ss')})</small>);
		return rList;
	}

	const getIcon = () => {
		return "";
		return isFav ? <ion-icon name="star"></ion-icon> : "";
	}

	const showProduct = (sn) => {
		console.log(sn);
	}

	const removeArr = (sn) => {
		const idx = favList.indexOf(sn);
		if (idx > -1) {
			favList.splice(idx, 1);
		}
		return favList;
	}

	const fav = () => {
		UpdateUserFav("site", isFav ?
			{ favoriteSites: removeArr(item.siteId) } :
			{ favoriteSites: [...favList, item.siteId] });
	}

	return (
		<>
			{showOnly && <div className="item p-2">
				<div>
					<strong>{Flag(item.country)}{item.sitename} {getIcon()}</strong>
					<div className="text-muted">
						{item.product && <>{item.product.model} {item.product.option && "/" + item.product.option}</>}
						{!item.product && <i>Not Installed</i>}
					</div>
				</div>
				<div className="swipeable-list-right">
					{item.far && <span className="badge badge-cyan">{(parseInt(item.far) / 1000).toFixed(1).toLocaleString() + "km"}</span>}
				</div>
			</div>}
			{!showOnly && <Link to={"/Site/" + item.siteId} className="item p-2">
				<div>
					<strong>{Flag(item.country)}{item.sitename} {getIcon()}</strong>
					<div className="text-muted">
						{item.product && <>{item.product.model} {item.product.option && "/" + item.product.option}</>}
						{!item.product && <i>Not Installed</i>}
					</div>
				</div>
				<div className="swipeable-list-right">
					{item.far && <span className="badge badge-cyan">{(parseInt(item.far) / 1000).toFixed(1).toLocaleString() + "km"}</span>}
				</div>
			</Link>}
		</>
	)
};

const mapState = (state) => {
	const favList = state.FavoriteReducer.site;
	return { favList };
}

const mapDispatch = dispatch => ({
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
})

export default connect(mapState, mapDispatch)(SiteCard);