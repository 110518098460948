import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { SiteAction, AlertAction, AuthAction, SiteSurveyAction } from '../../actions';
import { RayDelButton } from '../common/Buttons';
import { Translate as t } from '../../config/Translate';

const SurveyCard = ({ item, DeleteSiteSurvey }) => {

	const [view, setView] = useState(false);
	//const [ t, i18n ] = useTranslation();
	const del = () => {
		DeleteSiteSurvey(item._id);
	}


	return (
		<div className="accordion round-box progress-card mb-1">
			<div className="accordion-item">
				<h2 className="accordion-header">
					<button className="accordion-button collapsed" onClick={() => setView(!view)} style={{ width: "100%" }}>
						<div className="item">
							{!item.isSubmit && <div className="in">
								<div>
									{item.siteSitename && <h4>{item.siteSitename}</h4>}
									{item.model && <p>{item.model}</p>}
								</div>
								<div className="price">{item.siteSurveyStep} / 6</div>
							</div>}
							{!item.isSubmit && <div className="progress mt-1">
								<div className="progress-bar bg-success" style={{ width: ((item.siteSurveyStep / 6) * 100) + "%" }}>{Math.round((item.siteSurveyStep / 6) * 100) + "%"}</div>
							</div>}
							{item.isSubmit && <div>
								<ion-icon name="checkmark-circle" size="large" className="text-success fl md hydrated"></ion-icon>
								{item.siteSitename && <h4>{item.siteSitename}</h4>}
								{item.model && <p>{item.model}</p>}
							</div>}
						</div>
					</button>
				</h2>
				{view && <div className="accordion-collapse">
					<div className="accordion-body mt-0">
						{!item.isSubmit && <Link to={"/SiteSurvey/" + item._id} className="btn btn-block btn-primary mt-0">{t('edit')}</Link>}
						{item.isSubmit && <Link to={"/SiteSurvey/" + item._id + "/view"} className="btn btn-block btn-primary mt-0">{t('view')}</Link>}
						<RayDelButton css="btn btn-block btn-dark" callback={() => del()} startMsg={t('delete')} />
					</div>
				</div>}
			</div>
		</div>
	)
};

const mapDispatch = dispatch => ({
	DeleteSiteSurvey: (_id) => dispatch(SiteSurveyAction.DeleteSiteSurvey(_id)),
})

export default connect(null, mapDispatch)(SurveyCard);