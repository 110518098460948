import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ServiceAction, AdminUserAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';

const ServiceInfoStatus = ({ service, UpdateStatus, editable, nocard = false, allUsers, Users }) => {

    const [userList, setUserList] = useState([]);

    useEffect(() => {
        Users();
    }, [service]);

    useEffect(() => {
        setUserList([...allUsers]);
    }, [allUsers]);


    const getUserName = (adminId) => {
        let userName = "";

        userList.forEach(x => {
            if (x.adminid == adminId)
                userName = x.firstName + ' ' + x.lastName;
        });

        return userName;
    }

    const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');

    const form_controls = [

        {
            title: "", items: [{
                type: "input", name: "creator", label: "Reporter",
                viewLinkPath: isAdmin ? "/Admin/AdminUserInfo/" : "/UserInfo/",
                val: getUserName(service.creator), size: 12
            }]
        },
        { title: "", items: [{ type: "input", name: "createDate", label: "Created", val: service.createDate, valtype: "date" }] },

        {
            title: "", items: [{
                type: "input", name: "updater", label: "Updater",
                viewLinkPath: isAdmin ? "/Admin/AdminUserInfo/" : "/UserInfo/",
                val: getUserName(service.updater), size: 12
            }]
        },
        { title: "", items: [{ type: "input", name: "updateDate", label: "Updated", val: service.updateDate, valtype: "date" }] },
        { title: "", items: [{ type: "input", name: "closeDate", label: "Closed", val: service.closedDate, valtype: "date" }] },
    ]



    return (
        <RayForm
            action={UpdateStatus}
            nocard={nocard}
            actionkey={service._id}
            controls={form_controls} />
    );
};

const mapState = (state) => {
    const service = state.ServiceReducer.service;
    const allUsers = state.AdminUserReducer.users;
    return { service, allUsers };
}

const mapDispatch = dispatch => ({
    UpdateStatus: (_id, data) => dispatch(ServiceAction.UpdateStatus(_id, data)),
    UpdateService: (_id, data) => dispatch(ServiceAction.UpdateService(_id, data)),
    Users: () => dispatch(AdminUserAction.GetUserAll())
})

export default connect(mapState, mapDispatch)(ServiceInfoStatus);