import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { history } from '../../history';
import { CountryCode } from '../../config/CountryCode';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { Modal } from 'react-bootstrap';
import { store } from '../../store';
import { useTranslation } from 'react-i18next';
import { ServiceConstant } from '../../actions/constants/ServiceConstant';
import { Translate as t } from '../../config/Translate';

export const LoginFooter = ({ css = "section mt-5 text-muted text-center" }) => {
    return (
        <div className={css}>
            (C) {new Date().getFullYear()}. Ray, All rights reserved.
        </div>
    )
}

export const Flag = (cCode, height = 24) => {
    if (cCode) {
        const cname = CountryCode.find(item => cCode == item.code);
        if (cname) {
            return <img src={"/assets/img/flags/" + cCode + ".png"} style={{ height: height, marginRight: 4 }} alt={cname.title} />
        }
    }
}

export const isCon = (product, size) => {
    if (!product)
        return "";

    if (product.hasOwnProperty("isConnected")) {
        return product.isConnected ?
            <ion-icon name="wifi" className="text-primary md hydrated" style={{ fontSize: size, marginRight: 4, pointerEvents: 'none' }}></ion-icon> :
            <ion-icon name="wifi-outline" className="text-muted md hydrated" style={{ fontSize: size, marginRight: 4, pointerEvents: 'none' }}></ion-icon>;
    }

    return <ion-icon name="wifi-outline" className="text-muted md hydrated" style={{ fontSize: size, marginRight: 4, pointerEvents: 'none' }}></ion-icon>;
}

export const isDOA = (product, adminType = '') => {
    if (!product)
        return "";

    if (product.doa) {
        return product.doa && (adminType == 'S' || adminType == 'M') ? <span className="badge badge-danger"> DOA </span> : "";
    }
    return "";
}

export const isDIM = (product, adminType) => {
    if (!product)
        return "";

    if (product.dim) {
        return product.dim && (adminType == 'S' || adminType == 'M') ? <span className="badge badge-warning"> DIM </span> : "";
    }
    return "";
}

export const isNEW = (product, days) => {
    if (!product)
        return "";

    if (product.installedDate) {
        return product.installedDate >= days ? <span className="badge badge-success">NEW</span> : "";
    }
    return "";
}

export const GetServiceTypeChip = ({ type }) => {
    // const [t] = useTranslation();

    var title = "";
    var icon = "";
    switch (type) {
        case 'UPDATE':
            icon = <ion-icon name="brush-outline"></ion-icon>;
            title = t('update');
            break;
        case 'SENDMAIL':
            icon = <ion-icon name="mail-outline"></ion-icon>;
            title = t('sendemail');
            break;
        case 'STATUS':
            icon = <ion-icon name="albums-outline"></ion-icon>;
            title = t('status');
            break;
        case 'ADDCOMMENT':
            icon = <ion-icon name="chatbubble-ellipses-outline"></ion-icon>;
            title = t('addcomments');
            break;
        case 'UPDATECOMMENT':
            icon = <ion-icon name="chatbubble-ellipses-outline"></ion-icon>;
            title = t('updatecomments');
            break;
        case 'UPDATEASSIGNEE':
            icon = <ion-icon name="person-outline"></ion-icon>;
            title = "Update assignee";
            break;
        case 'DELCOMMENT':
            icon = <ion-icon name="chatbubble-ellipses-outline"></ion-icon>;
            title = "Delete comment";
            break;
        case 'ADDREPLACEMENT':
            icon = <ion-icon name="build-outline"></ion-icon>;
            title = "Add replacement";
            break;
        case 'DELREPLACEMENT':
            icon = <ion-icon name="build-outline"></ion-icon>;
            title = "Delete replacement";
            break;
        case 'ADDATTACHMENT':
            icon = <ion-icon name="attach-outline"></ion-icon>;
            title = t('addattachment');
            break;
        case 'DELATTACHMENT':
            icon = <ion-icon name="attach-outline"></ion-icon>;
            title = "Delete attachment";
            break;
        case 'ADDSIGNATURE':
            icon = <ion-icon name="finger-print-outline"></ion-icon>;
            title = "Add signature";
            break;
    }
    return <div className="chip chip-media">
        <i className="chip-icon bg-primary">{icon}</i>
        <span className="chip-label">{title}</span>
    </div>
}

const LoggedBottomMenu = ({ site, product, showmodel, clist }) => {
    // const [t] = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [nowpath, setNowPath] = useState("/");
    const [showAS, setShowAS] = useState(false);
    const [infoSN, setInfoSN] = useState("");
    const [modelcategory, setModelCategory] = useState("");
    const [infoSiteId, setInfoSiteId] = useState("");
    const [showAddServiceSN, setshowAddServiceSN] = useState(false);

    // useEffect(() => {
    //     if(showmodel === ""){
    //         setModelCategory("");
    //     }else if(clist.length > 0){
    //         setModelCategory(clist.find(x => x.group === showmodel)?.category);
    //     }
    // }, [showmodel, clist])

    useEffect(() => {
        setInfoSN(site.product ? site.product.sn : "");
        setInfoSiteId(site.siteId);
    }, [site])

    useEffect(() => {
        setInfoSN(product.sn || "");
        setInfoSiteId(product.siteId || "");
    }, [product])

    useEffect(() => {
        setNowPath(location.pathname);
        chkShowSN(location.pathname);
    }, [location.pathname])

    const chkShowSN = (path) => {
        var showsnpath = ["/Site/", "/Product/"];
        var bl = false;
        showsnpath.map(x => {
            if (path.indexOf(x) > -1) { bl = true }
        });
        setshowAddServiceSN(bl);
    }

    const getClass = (path) => {
        const url = nowpath.toLowerCase();
        if (path == "/") {
            if (url == path)
                return "item active"
        } else {
            if (url == path) {
                return "item active"
            } else if (url.startsWith(path)) {
                return "item active"
            }
        }
        return "item";
    }

    const goUrl = (url) => {
        if (url == "/ServiceAddNew") {
            dispatch({ type: ServiceConstant.CLEAER });
            if (infoSN && showAddServiceSN) {
                history.push({
                    pathname: "/ServiceAddNew",
                    state: {
                        refer: "PRODUCTNORMAL",
                        item: infoSiteId,
                        extraItem: infoSN,
                    },
                });
            } else {
                history.push(url);
            }
        } else if (url == "/SiteSurvey") {
            history.push(url);
            //window.location.href = '/SiteSurvey';
        } else if (url == "/Installation") {
            history.push(url);
            //window.location.href = '/Installation';
        } else {
            history.push(url);
        }
        setShowAS(false);
    }

    return (
        <>
            <div className="appBottomMenu">
                <Link to="/" className={getClass("/")}>
                    <div className="col">
                        <ion-icon name="home-outline"></ion-icon>
                        <strong>{t('home')}</strong>
                    </div>
                </Link>
                <Link to="/MySearch" className={getClass("/mytask")}>
                    <div className="col">
                        <ion-icon name="search-outline"></ion-icon>
                        <strong>{t('search')}</strong>
                    </div>
                </Link>
                <a onClick={() => setShowAS(true)} className="item">
                    <div className="col">
                        <ion-icon name="add-outline"></ion-icon>
                        <strong>{t('add')}</strong>
                    </div>
                </a>
                <Link to="/QRScan" className="item">
                    <div className="col">
                        <ion-icon name="qr-code-outline"></ion-icon>
                        <strong>{t('qr')}</strong>
                    </div>
                </Link>
                <a className="item" onClick={() => dispatch({ type: ProgramConstant.SIDEMENU_OPEN })}>
                    <div className="col">
                        <ion-icon name="menu-outline"></ion-icon>
                        <strong>{t('menu')}</strong>
                    </div>
                </a>
            </div>

            <Modal show={showAS} className="action-sheet" dialogClassName="" onHide={() => setShowAS(false)}>
                <Modal.Header>
                    <h5 className="modal-title">{t('add')}...</h5>
                </Modal.Header>
                <Modal.Body>
                    <ul className="action-button-list">
                        <li>
                            <a className="btn btn-list" onClick={() => goUrl("/ServiceAddNew")}>
                                <span><ion-icon name="construct-outline" style={{ pointerEvents: 'none' }}></ion-icon>{t('service')}</span>
                                {(infoSN && showAddServiceSN) && <div className="bg-primary float-end ps-1 pe-1">{infoSN}</div>}
                            </a>
                        </li>
                        {(modelcategory === "" || modelcategory === "Extra-oral Imaging") && <>
                            <li>
                                <a className="btn btn-list" onClick={() => goUrl("/SiteSurvey")}>
                                    <span><ion-icon name="checkbox-outline" style={{ pointerEvents: 'none' }}></ion-icon>{t('preinstallreport')}</span>
                                </a>
                            </li>
                            <li>
                                <a className="btn btn-list" onClick={() => goUrl("/Installation")}>
                                    <span><ion-icon name="reader-outline" style={{ pointerEvents: 'none' }}></ion-icon>{t('installreport')}</span>
                                </a>
                            </li>
                        </>}
                        <li className="action-divider" />
                        <li>
                            <a className="btn btn-list text-secondary" onClick={() => setShowAS(false)}>
                                <span><ion-icon name="close-outline" style={{ pointerEvents: 'none' }}></ion-icon>{t('cancel')}</span>
                            </a>
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapState = (state) => {
    const product = state.ProductReducer.product;
	const showmodel = state.ProgramReducer.showmodel;
	const clist = state.RayCodeReducer.items;
    const site = state.SiteReducer.site;
    return { product, site, showmodel, clist };
};

export default connect(mapState)(LoggedBottomMenu);

export const LoggedFooter = () => {
    return (
        <div className="copyright sm-text-center m-t-30">
            <p className="small no-margin pull-left sm-pull-reset">
                <span className="hint-text">Copyright © {new Date().getFullYear()}</span>
                <span className="font-montserrat">Ray</span>.
                <span className="hint-text">All rights reserved.</span>
            </p>
            <p className="small no-margin pull-right sm-pull-reset">
            </p>
            <div className="clearfix"></div>
        </div>
    )
}
export const LoggedLeftFooter = () => {
    return (
        <div className="site-menubar-footer">
            <a to="https://www.iubenda.com/privacy-policy/85310967/legal" className="iubenda-nostyle iubenda-embed " title="Privacy Policy ">Privacy</a><script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>
            <Link to="/Home/Terms">Terms</Link>
            <a to="https://partner.raymedical.com/?autologin_code=eg5pnG7EAYs9kFFtsIzkq7IunvgZEB"><i className="md-square-right" aria-hidden="true" title="Portal"></i> Portal</a>
        </div>
    )
}

export const LoggedAdminFooter = () => {

    const location = useLocation()
    const [path, setPath] = useState("");
    useEffect(() => {
        setPath(location.pathname);
    }, [location])

    return (
        <>
            {path != "/Admin/Dashboard" &&
                <div className="container-fluid footer">
                    <div className="copyright sm-text-center">
                        <p className="small no-margin pull-left sm-pull-reset">
                            <span className="hint-text">Copyright © {new Date().getFullYear()}</span>
                            <span className="font-montserrat">Ray</span>.
                            <span className="hint-text">All rights reserved.</span>
                        </p>
                        <p className="small no-margin pull-right sm-pull-reset">
                        </p>
                        <div className="clearfix"></div>
                    </div>
                </div>
            }
        </>
    )
}

export const Toast = ({ option }) => {
    const [isAdmin, setIsAdmin] = useState(location.pathname.toLowerCase().startsWith('/admin/'));

    const getIcon = () => {
        switch (option.type) {
            case "error": return "warning-outline";
            case "success": return "checkmark-outline";
        }
    }

    const getCss = () => {
        const color = option.type == "success" ? "bg-secondary" : "bg-danger";
        return option.show ? "toast-box toast-bottom " + color + " show" : "toast-box toast-bottom " + color;
    }

    return (
        <div className={getCss()}
            style={option.show ? (isAdmin ? { bottom: 0 } : { bottom: 56 }) : {}}>
            <div className="in">
                <ion-icon name={getIcon()}></ion-icon>
                <div className="text"> {option.message} </div>
            </div>
        </div>
    )
}
export const SimpleAlert = ({ type, message }) => {

    return (
        <div>
            {type == "sa-error" &&
                <div className="sa-icon sa-error">
                    <span className="sa-x-mark">
                        <span className="sa-line sa-left"></span>
                        <span className="sa-line sa-right"></span>
                    </span>
                </div>
            }
            {type == "sa-warning" &&
                <div className="sa-icon sa-warning">
                    <span className="sa-body"></span>
                    <span className="sa-dot"></span>
                </div>
            }
            {type == "sa-info" &&
                <div className="sa-icon sa-info"></div>
            }
            {type == "sa-success" &&
                <div className="sa-icon sa-success">
                    <span className="sa-line sa-tip"></span>
                    <span className="sa-line sa-long"></span>
                    <div className="sa-placeholder"></div>
                    <div className="sa-fix"></div>
                </div>
            }
            <p className="lead text-muted">{message}</p>
        </div>
    )
}
