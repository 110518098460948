import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LanguageCode } from '../../config';
import { AuthAction } from '../../actions';
import { RayForm } from '../../components/common';

const AdminSetting = ({ UpdateUser, user }) => {

	const [settings, setSettings] = useState({});

	useEffect(() => {
		setSettings(user.settings);
	}, [user.settings]);

	var fc_dashboard = [
		{
			title: "Dashboard", items: [
				{ type: "number", name: "aggrserviceoverdueonlycount", label: "Service Overdue 'N' Days", val: settings.aggrserviceoverdueonlycount || 7 },
				{ type: "number", name: "aggrerrorlastndays", label: "Errors Last 'N' Days", val: settings.aggrerrorlastndays || 1 },
				{ type: "number", name: "aggrerrornotcloselastndays", label: "Errors Not Closed/Overdue 'N' Days", val: settings.aggrerrornotcloselastndays || 1 },
				{
					type: "select2", name: "dashboardmodelchart", label: "Model Chart", val: settings.dashboardmodelchart || "pie", list: [
						{ code: "pie", title: "Pie Chart" },
						{ code: "bar", title: "Horizontal Bar Chart" },
						{ code: "hide", title: "Hide" },
					]
				},
				{ type: "number", name: "dashboardmodelchartlimit", label: "Model Chart - Limit(Bar Chart Only)", val: settings.dashboardmodelchartlimit || 5 },

				{ type: "number", name: "dashboardaggrerrorpercentlastndays", label: "Error Chart - Last 'N' Days", val: settings.dashboardaggrerrorpercentlastndays || 7 },
				{ type: "number", name: "dashboardaggrerrorpercentlimit", label: "Error Chart - Last 'N' Days - Limit(Bar Chart Only)", val: settings.dashboardaggrerrorpercentlimit || 5 },
				{
					type: "select2", name: "dashboardaggrerrorpercent", label: "Error Chart - Last 'N' Days - Option", val: settings.dashboardaggrerrorpercent || "pie", list: [
						{ code: "pie", title: "Pie Chart" },
						{ code: "bar", title: "Horizontal Bar Chart" },
						{ code: "hide", title: "Hide" },
					]
				},

				{
					type: "select2", name: "dashboardaggrerrorchartlastndaysshow", label: "Acquisitions Daily Chart Show", val: settings.dashboardaggrerrorchartlastndaysshow || "Y", list: [
						{ code: "Y", title: "Show" },
						{ code: "N", title: "Hide" },
					]
				},
				{
					type: "select2", name: "dashboardaggrerrorchartlastndays", label: "Error Daily Chart 'N' Days", val: settings.dashboardaggrerrorchartlastndays || 14, list: [
						{ code: 7, title: "1 Week" },
						{ code: 14, title: "2 Weeks" },
						{ code: 21, title: "3 Weeks" },
						{ code: 28, title: "4 Weeks" },
					]
				},
				{
					type: "select2", name: "dashboardaggracqchartlastndaysshow", label: "Acquisitions Daily Chart Show", val: settings.dashboardaggracqchartlastndaysshow || "Y", list: [
						{ code: "Y", title: "Show" },
						{ code: "N", title: "Hide" },
					]
				},
				{
					type: "select2", name: "dashboardaggracqchartlastndays", label: "Acquisitions Daily Chart 'N' Days", val: settings.dashboardaggracqchartlastndays || 14, list: [
						{ code: 7, title: "1 Week" },
						{ code: 14, title: "2 Weeks" },
						{ code: 21, title: "3 Weeks" },
						{ code: 28, title: "4 Weeks" },
					]
				},

				{ type: "number", name: "dashboardaggrservicelongclose", label: "Open Service - Last 'N' Days", val: settings.dashboardaggrservicelongclose || 7 },
				//{ type : "number", name : "dashboardaggrservicelongcloselimit", label : "Open Service - Last 'N' Days - Limit", val : settings.dashboardaggrservicelongcloselimit || 7 },
				{ type: "number", name: "dashboarddashboardaggrserviceclosed", label: "Closed Service - Last 'N' Days", val: settings.dashboarddashboardaggrserviceclosed || 7 },
			]
		},
	]
	var fc_product = [
		{
			title: "Product", items: [
				{ type: "number", name: "dslatestinstalleddays", label: "Dashboard - Latest Installed Products - Days", val: settings.dslatestinstalleddays || 5 },
				{ type: "number", name: "dslatestinstalledcount", label: "Dashboard - Latest Installed Products - Count", val: settings.dslatestinstalledcount || 5 },
				{ type: "number", name: "dbprdlonguseyear", label: "Dashboard - Old Product - Years", val: settings.dbprdlonguseyear || 7 },
				{ type: "number", name: "dbprdlonguseyearshowcount", label: "Dashboard - Old Product - Count", val: settings.dbprdlonguseyearshowcount || 10 },
				{ type: "number", name: "dsprdcountlistcount", label: "Dashboard - Country List - Count", val: settings.dsprdcountlistcount || 10 },
				{ type: "number", name: "dsprdoccerrorount", label: "Dashboard - Occurred Errors - Count", val: settings.dsprdoccerrorount || 5 },
				{ type: "number", name: "dsprdsrvcount", label: "Dashboard - Service - Count", val: settings.dsprdsrvcount || 7 },
				{ type: "number", name: "dsprdacqcount", label: "Dashboard - Acquisitions - Count", val: settings.dsprdacqcount || 7 },
			]
		},
	]
	var fc_service = [
		{
			title: "Service", items: [
				{ type: "number", name: "dsservicecardduration", label: "Dashboard - Card Duration 'N' Days", val: settings.dsservicecardduration || 30 },
				{ type: "number", name: "dsservicecountrytop", label: "Dashboard - Country Top 'N'", val: settings.dsservicecountrytop || 5 },
				{ type: "number", name: "dsserviceerrortop", label: "Dashboard - Error Top 'N'", val: settings.dsserviceerrortop || 5 },
				{ type: "number", name: "dsservicestatustop", label: "Dashboard - Status Top 'N'", val: settings.dsservicestatustop || 5 },
				{ type: "number", name: "dsservicemodeltop", label: "Dashboard - Model Top 'N'", val: settings.dsservicemodeltop || 5 },
				{ type: "number", name: "dsservicenowclosedtop", label: "Dashboard - Open Service Top 'N'", val: settings.dsservicenowclosedtop || 10 },
				{
					type: "select2", name: "dsserviceservicestatusmonthchart", label: "Dashboard - Monthly Status 'N' Month", val: settings.dsserviceserviceallchart || 12, list: [
						{ code: 12, title: "12 Month" },
						{ code: 24, title: "24 Month" },
						{ code: 36, title: "36 Month" },
					]
				},
				{
					type: "select2", name: "dsserviceserviceallchart", label: "Dashboard - Incoming Chart 'N' Days", val: settings.dsserviceserviceallchart || 180, list: [
						{ code: 30, title: "1 Month" },
						{ code: 60, title: "2 Month" },
						{ code: 90, title: "3 Month" },
						{ code: 180, title: "6 Month" },
					]
				},
				{
					type: "select2", name: "dsserviceservicestatuschart", label: "Dashboard - Status Chart 'N' Days", val: settings.dsserviceservicestatuschart || 14, list: [
						{ code: 7, title: "1 Week" },
						{ code: 14, title: "2 Weeks" },
						{ code: 21, title: "3 Weeks" },
					]
				},
				{
					type: "select2", name: "dsserviceerrorchart", label: "Dashboard - Error Chart 'N' Days", val: settings.dsserviceerrorchart || 14, list: [
						{ code: 7, title: "1 Week" },
						{ code: 14, title: "2 Weeks" },
						{ code: 21, title: "3 Weeks" },
					]
				},
				{
					type: "select2", name: "dsservicereplacementsdays", label: "Dashboard - Replacements 'N' Days", val: settings.dsservicereplacementsdays || 14, list: [
						{ code: 7, title: "1 Week" },
						{ code: 14, title: "2 Weeks" },
						{ code: 21, title: "3 Weeks" },
						{ code: 28, title: "4 Weeks" },
					]
				},
			]
		},
	]
	var fc_common = [
		{
			title: "Common", items: [
				{
					type: "select2", name: "dashboardtablelistcount", label: "Table List Count", val: settings.dashboardtablelistcount || 30, list: [
						{ code: 10, title: "10" },
						{ code: 20, title: "20" },
						{ code: 30, title: "30" },
						{ code: 40, title: "40" },
						{ code: 50, title: "50" },
						{ code: 100, title: "100" },
						{ code: 1000, title: "1000" },
					]
				},
				{
					type: "select2", name: "dashboardshowprogval", label: "Show value in progress bar", val: settings.dashboardshowprogval || "N", list: [
						{ code: "Y", title: "Show" },
						{ code: "N", title: "Hide" },
					]
				},

			]
		},
	]

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		var obj = {};
		var sobj = {};
		sobj['settings.' + name] = value;
		obj['$set'] = sobj;
		UpdateUser(obj);
	};

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">Settings</h2>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="row">
					<div className="col">
						<RayForm
							controls={fc_dashboard}
							isForm={true}
							handleUpdateData={handleChangeData} />
					</div>
					<div className="col">
						<RayForm
							controls={fc_common}
							isForm={true}
							handleUpdateData={handleChangeData} />
						<RayForm
							controls={fc_product}
							isForm={true}
							handleUpdateData={handleChangeData} />
						<RayForm
							controls={fc_service}
							isForm={true}
							handleUpdateData={handleChangeData} />
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	return { user };
}

const mapDispatch = dispatch => ({
	UpdateUser: (data) => dispatch(AuthAction.UpdateUser(data)),
})

export default connect(mapState, mapDispatch)(AdminSetting);