export const SiteConstant = {
    GET_SITELIST: 'GET_SITELIST',
    GET_FAV_SITELIST : 'GET_FAV_SITELIST',
    GET_SITE: 'GET_SITE',
    UPDATE_SITEINFO: 'UPDATE_SITEINFO',
    ADD_SITEINFO: 'ADD_SITEINFO',
    UPDATE_LISTFILTER: 'UPDATE_LISTFILTER',
    DELETE_SITE: 'DELETE_SITE',
    CLEAR_UPDATERES: 'CLEAR_UPDATERES',
    ADDUPDATE: 'SITE_ADDUPDATE',
    ERROR: 'SITE_ERROR',
    LOADING: 'SITE_LOADING',
    LOADED: 'SITE_LOADED',
};