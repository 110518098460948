import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { ProductAction } from '../../actions';
import { UserSimpleCard } from '../../components/common/SimpleLabel';
import { RayDelButton } from '../../components/common/Buttons';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const ProductHistory = ({ product }) => {

	// const [ t ] = useTranslation();
	const [filterList, setFilterList] = useState([]);
	const [filter, setFilter] = useState("");
	const [sort, setSort] = useState("asc");
	const [list, setList] = useState([]);

	const exliudesHistoryKey = ["user", "timestamp", "updater", "updateDate"];

	useEffect(() => {
		var fList = [""];
		if (product.histories !== undefined) {
			if (product.histories.length > 0) {
				product.histories.map(x => {
					if (!fList.includes(x.type))
						fList.push(x.type);
				})
			}
		}
		setFilterList(fList);
	}, [product])

	useEffect(() => {
		setList(product.histories.filter(x => filter ? x.type == filter : x));
	}, [filter])

	const getSortList = () => {
		return sort == "asc" ?
			list.sort((a, b) => a.timestamp - b.timestamp) :
			list.sort((a, b) => b.timestamp - a.timestamp);
	}

	const showDateTime = (r) => {
		var rList = [];
		rList.push(moment.utc(r).local().fromNow());
		rList.push(<small> ({moment.utc(r).local().format('YYYY-MM-DD HH:mm:ss')})</small>);
		return rList;
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
			})
		} else {
			return "N/A";
		}
		return html;
	}

	const getDot = type => {
		if (type) {
			if (type.indexOf("UPDATE") > -1)
				return "dot bg-primary";
			if (type.indexOf("EMSVERUPDATE") > -1)
				return "dot bg-info";
			if (type.indexOf("UPDATE") > -1)
				return "dot bg-info";
		}
		return "dot bg-primary";
	}

	const getVal = (v) => {
		if (v === undefined) {
			return "";
		} else {
			if (v === false) {
				return "false";
			}
			if (v === true) {
				return "true";
			}
			if (v.toString().length === 13) {
				var n = parseInt(v);
				if (isNaN(n)) {
					return v;
				} else {
					return moment(v).local().format("YYYY-MM-DD");
				}
			}
			return v;
		}
	}

	const showDiff = (c) => {
		var res = [];
		var r = [];
		Object.keys(c).map(x => {
			r.push(...diffCon(x, c[x]));
		})
		//console.log(r);
		//var arr = Object.values(r.reduce((acc, obj) => {
		//	const [[key, val]] = Object.entries(obj);
		//	return (acc[val] = {[key]: val}, acc);
		//}, {}));
		//console.log(arr);
		r.map(x => {
			if (!exliudesHistoryKey.includes(Object.keys(x)[0])) {
				res.push(<li> {getKey(Object.keys(x)[0])} : {getVal(x[Object.keys(x)[0]])} </li>)
			}
		});
		return res;
	}

	const getKey = (key) => {
		return key.replace(".", " ").replace(/(^|\s)\S/g, letter => letter.toUpperCase());
	}

	const diffCon = (key, val) => {
		var r = [];
		if (typeof val === "object") {
			if (val.hasOwnProperty("__old") || val.hasOwnProperty("__new")) {
				var obj = {};
				if (val.__new === "") {
					obj[key] = <>{getVal(val.__old)} -&gt; N/A</>;
				} else {
					obj[key] = <>{getVal(val.__old) + " -> " + getVal(val.__new)}</>;
				}
				r.push(obj);
			} else {
				Object.keys(val).map(x => {
					r.push(...diffCon(x, val[x]));
				});
			}
			return r;
		} else {
			var obj = {};
			if (key.indexOf("__added") > -1) {
				obj[key.replace("__added", "")] = "ADD : New - " + getVal(val);
			} else if (key.indexOf("__deleted") > -1) {
				obj[key.replace("__deleted", "")] = "REMOVE : Old - " + getVal(val);
			} else {
				obj[key] = val;
			}
			r.push(obj);
		}
		return r;
	}

	return (
		<>
			{product.histories &&
				<>
					<div className="p-1 row">
						<div className="col">
							<select name="filter"
								className="form-control form-control-sm float-start"
								style={{ width: 200 }}
								value={filter}
								onChange={(e) => setFilter(e.target.value)}>
								{filterList.map(x => <option value={x}>{x ? x : "ALL"}</option>)}
							</select>
						</div>
						<div className="col text-right">
							<button className="btn btn-sm btn-secondary m-0"
								onClick={(e) => setSort(sort == "asc" ? "desc" : "asc")}>
								Sort : {sort == "asc" && <ion-icon name="arrow-down-outline"></ion-icon>}
								{sort == "desc" && <ion-icon name="arrow-up-outline"></ion-icon>}
							</button>
						</div>
					</div>
					<div className="border-top">
						<div className="timeline ms-3">
							{getSortList().map((c, idx) => {
								if (filter && filter != c.type)
									return;
								return <div key={idx} className="item">
									<div className={getDot(c.type)}></div>
									<div className="content rayhistory">
										<div className="title">
											{c.user != "EMS" && <UserSimpleCard user={c.user} />} / {showDateTime(c.timestamp)}
										</div>
										<h4 className="text">{nl2br(c.contents)}</h4>
										{c.diff && <ul className="listview simple-listview">
											{showDiff(c.diff)}
										</ul>}
									</div>
								</div>
							})}
						</div>
					</div>
				</>}
		</>
	);
};

export default ProductHistory;