import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ServiceAction, AdminUserAction } from '../../actions';
import { FileItem, FileUpload } from '../../components/common/FileUpload';
import { Translate as t } from '../../config/Translate';

const ServiceInfoAttachments = ({ service, propService, Attachment, onlylist = false, allUsers, Users, uploadLater, callback }) => {
	// const [t] = useTranslation();
	const [userList, setUserList] = useState([]);
	const [goStartUpload, setGoStartUpload] = useState(false);
	const [serviceItem, setServiceItem] = useState({ attachments: [] });
	const [callbackUpList, setCallbackUpList] = useState([]);
	useEffect(() => {
		Users();
	}, []);

	useEffect(() => {
		setUserList([...allUsers]);
	}, [allUsers]);

	useEffect(() => {
		if (serviceItem._id)
			setGoStartUpload(true);
	}, [serviceItem]);

	useEffect(() => {
		setServiceItem(propService?._id ? propService : service);
	}, [service, propService]);

	const delFile = (file) => {
		Attachment(serviceItem._id, "DEL", { delAttachid: file._id })
	}

	const setNewFile = (file) => {
		Attachment(serviceItem._id, "ADD", {
			name: file.original,
			path: file.s3path,
		})
	}

	const getUserName = (adminId) => {
		let userName = "";

		userList.forEach(x => {
			if (x.adminid == adminId)
				userName = x.firstName + ' ' + x.lastName;
		});

		return userName;
	}

	return (
		<>
			<div className="p-1 mb-1">
				{!onlylist && <div className="h5 clearfix m-b-10">
					<FileUpload
						option={{ doneandnew: true }}
						extraData={{ path: "services/" + serviceItem.ticketKey + "/" }}
						uploadLater={uploadLater}
						callbackUpListChanged={(list) => {
							setCallbackUpList(list);
							callback && callback(list);
						}}
						doUpStart={goStartUpload}
						callback={(e) => setNewFile(e)} />
				</div>}
				<ul className="list-group">
					{serviceItem && serviceItem.attachments.map((c, idx) =>
						<li className="list-group-item ps-1" key={c._id}>
							<FileItem
								fileInfo={{ ...c, creatorName: getUserName(c.creator), original: c.name, s3path: c.path }}
								callback={(c) => delFile(c)}
								key={idx}
							/>
						</li>
					)}

					{callbackUpList && callbackUpList.map((c, idx) =>
						<li className="list-group-item ps-1" key={c._id}>
							{c.name}
						</li>)}
				</ul>
				{((serviceItem.attachments.length == 0) && (callbackUpList.length == 0)) && <span className="text-muted block text-center">{t('noattach')}</span>}
			</div>
		</>
	);
};

const mapState = (state) => {
	let service = state.ServiceReducer.service;
	const allUsers = state.AdminUserReducer.users;

	if (!service.hasOwnProperty('attachments'))
		service = { ...service, attachments: [] }

	return { service, allUsers };
}

const mapDispatch = dispatch => ({
	Attachment: (_id, type, data) => dispatch(ServiceAction.Attachment(_id, type, data)),
	Users: () => dispatch(AdminUserAction.GetUserAll())
})

export default connect(mapState, mapDispatch)(ServiceInfoAttachments);