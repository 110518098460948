import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { CompanyAction } from '../../actions/CompanyAction';
import Select from 'react-select';

const CompanyModalAlias = ({ company, companies, info, callbackClose, showModal, UpdateService }) => {

	const [reuqest, setRequest] = useState(false);
	const [val, setVal] = useState({});

	useEffect(() => {
		if (showModal) {
			setVal({});
		}
	}, [showModal])

	useEffect(() => {
		if (reuqest) {
			setRequest(false);
			callbackClose({ ...info.dealer, companyId: val.companyId });
		}
	}, [company])

	const handleSubmit = () => {
		if (!val)
			return;
		if (!val.companyId)
			return;
		setRequest(true);
		if (val.alias) {
			UpdateService(val.companyId, { alias: [...val.alias, info.dealer.trim().toUpperCase()] })
		} else {
			UpdateService(val.companyId, { alias: [info.dealer.trim().toUpperCase()] })
		}
	}

	return (
		<Modal show={showModal} onHide={() => callbackClose()}>
			<Modal.Header>
				<h2 className="modal-title">Search Company/Dealer</h2>
			</Modal.Header>
			<Modal.Body className="p-2">
				<h3>"{info.dealer}" is ...</h3>
				<Select
					value={val}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.companyId}
					onChange={(e) => setVal(e)}
					options={companies} />
			</Modal.Body>
			<Modal.Footer>
				<button className={reuqest ? "btn btn-block btn-primary disabled" : "btn btn-block btn-primary"} onClick={() => handleSubmit()}>Add Alias</button>
				<button className={reuqest ? "btn btn-block btn-dark disabled" : "btn btn-block btn-dark"} onClick={() => callbackClose()}>Close</button>
			</Modal.Footer>
		</Modal>
	);
}

const mapState = (state) => {
	const company = state.CompanyReducer.company;
	return { company };
};

const mapDispatch = (dispatch) => ({
	UpdateService: (companyId, companyInfo) => dispatch(CompanyAction.UpdateCompanyInfo(companyId, companyInfo))
});

export default connect(mapState, mapDispatch)(CompanyModalAlias);