import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { history } from '../../history';
import { Link } from 'react-router-dom';
import { Translate as t } from '../../config/Translate';


const HomeCard = ({ user, overDue, serviceCount, schedCount, callback, errCount, docCount,
    homeListTotalPrdCount, homeListTotalActivePrdCount, recentInstallCount, recentInstalldays }) => {
    // const [t] = useTranslation();
    const dispatch = useDispatch();
    const beforDays = user.rguardSettingHomeErrorScope == undefined ? 1 : user.rguardSettingHomeErrorScope;
    const overdueDays = user.rguardSettingHomeOverdueScope == undefined ? 7 : user.rguardSettingHomeOverdueScope;
    const schedScope = user.rguardSettingHomeUpcomingScope || 7;

    const [search, setSearch] = useState("");

    const dayDays = (daycount) => {
        return daycount == 1 ? t('day') : t('days');
    }

    const chkform = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (search) {
                dispatch({ type: ProgramConstant.SETSEARCHFILTERS, filters: { search: search, model: "" } })
                history.push("/MySearch");
            }
        }
    }

    return (
        <div className="user-card" style={{ height: '440px' }}>
            <div className="tit my-0">
                <div className="left">
                    <span className="title">{t('welcomeback')}</span>
                    <h1 className="username">{user.firstName}
                    </h1>
                </div>
                <div className="right">
                    <Link to="/MyInfo"><img src={user.avatar || "/assets/img/avatar_default_none.png"} alt="image" className="imaged w64" /></Link>
                </div>
            </div>
            <div className='sch' style={{ height: '60px' }}>
                <div className=''>
                    <form className='search-form'>
                        <div className="form-group searchbox">
                            <input type="text" className="form-control" placeholder={t('search')} value={search} onChange={(e) => setSearch(e.target.value)} onKeyPress={(e) => chkform(e)} />
                            <i className="input-icon">
                                <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            </i>
                        </div>
                    </form>
                </div>
            </div>
            <div className="uc-footer">
                <div className="item">
                    <a onClick={() => callback("service")} data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                        <div className="icon-wrapper bg-info mb-1" style={{ cursor: "pointer" }}>
                            <ion-icon name="receipt-outline" role="img" className="md hydrated" aria-label="receipt outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            <span className="badge badge-primary">{serviceCount}</span>
                        </div>
                        <strong>{t('service')}</strong><p>{t('total')}</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={() => callback("overdue")} data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                        <div className="icon-wrapper bg-danger mb-1" style={{ cursor: "pointer" }}>
                            <ion-icon name="alert-outline" role="img" className="md hydrated" aria-label="close outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            <span className="badge badge-primary">{overDue}</span>
                        </div>
                        <strong>{t('overdue')}</strong>
                        <p>{overdueDays == 0 ? t('all') : overdueDays + dayDays(overdueDays)}</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={() => callback("sched")} data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                        <div className="icon-wrapper bg-warning mb-1" style={{ cursor: "pointer" }}>
                            <ion-icon name="calendar-outline" role="img" className="md hydrated" aria-label="alert outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            <span className="badge badge-primary">{schedCount}</span>
                        </div>
                        <strong>{t('schedule')}</strong><p>{schedScope / 7} {schedScope / 7 > 1 ? t('weeks') : t('week')}</p>
                    </a>
                </div>
            </div>
            <div className='uc-footer'></div>
            <div className='uc-footer'>
                <div className="item">
                    <a onClick={() => callback("err")} data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                        <div className="icon-wrapper bg-secondary mb-1" style={{ cursor: "pointer" }}>
                            <ion-icon name="close-outline" role="img" className="md hydrated" aria-label="close outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            <span className="badge badge-primary">{errCount}</span>
                        </div>
                        <strong>{t('error')}</strong>
                        <p>{beforDays == 0 ? t('total') : beforDays + dayDays(beforDays)}</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={() => callback("recentInstall")} data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                        <div className="icon-wrapper bg-secondary mb-1" style={{ cursor: "pointer" }}>
                            <ion-icon name="settings-outline" role="img" className="md hydrated" aria-label="calendar outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            <span className="badge badge-primary">{recentInstallCount}</span>
                        </div>
                        <strong>{t('newinstalled')}</strong><p>{recentInstalldays} {dayDays(recentInstalldays)}</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={() => callback("doc")} data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                        <div className="icon-wrapper bg-secondary mb-1" style={{ cursor: "pointer" }}>
                            <ion-icon name="documents-outline" style={{ pointerEvents: 'none' }}></ion-icon>
                            <span className="badge badge-primary">{docCount.toLocaleString()}</span>
                        </div>
                        <strong>{t('reports')}</strong>
                    </a>
                </div>
            </div>
        </div>
    )
}

const mapState = (state) => {
    var user = state.AuthReducer.user;
    const homeListTotalPrdCount = state.ProgramReducer.homeListTotalPrdCount;
    const homeListTotalActivePrdCount = state.ProgramReducer.homeListTotalActivePrdCount;
    return { user };
};

const mapDispatch = dispatch => ({
    GetList: (filter, append) => dispatch(ProductAction.GetErrors(filter, append)),
})

export default connect(mapState, mapDispatch)(HomeCard);
