import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { CalendarConstant } from './constants/CalendarConstant';
import { AlertAction } from './AlertAction';
import { ProgramConstant } from './constants/ProgramConstant';
import { store } from '../store';

const GetAll = () => async (dispatch) => {
	dispatch({ type: CalendarConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/cal', JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: CalendarConstant.GET_SCHLIST, items : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: CalendarConstant.LOADED });
};

const GetAllByFilter = (filter) => async (dispatch) => {
	dispatch({ type: CalendarConstant.LOADING });
	const data = {
		type: 'getfilter',
		data: filter,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cal', data, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: CalendarConstant.GET_SCHLIST, items : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: CalendarConstant.LOADED });
};

const GetOne = (_id) => async (dispatch) => {
	dispatch({ type: CalendarConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/cal/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: CalendarConstant.GET_SCH, item : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: CalendarConstant.LOADED });
};

const Delete = (_id) => async (dispatch) => {
	dispatch({ type: CalendarConstant.LOADING });
	const data = {
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cal/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: ProgramConstant.DELETE_SCH, _id : _id });
		dispatch({ type: CalendarConstant.DELETE_SCH, _id : _id });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: CalendarConstant.LOADED });
};

const CreateUpdateByService = async (info) => {
	const data = {
		type: 'createupdatebyservice',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cal', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data;
	}
};


const Create = (info) => async (dispatch) => {
	dispatch({ type: CalendarConstant.LOADING });
	const data = {
		type: 'create',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cal', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: CalendarConstant.CREATE_SCH, item : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: CalendarConstant.LOADED });
};

const Update = (_id, info) => async (dispatch) => {
	dispatch({ type: CalendarConstant.LOADING });
	const data = {
		type: 'update',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cal/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: CalendarConstant.UPDATE_SCH, item : ret.data.data });
		dispatch({ type: ProgramConstant.UPDATE_SHEDS, item : ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: CalendarConstant.LOADED });
};

const GetAllHomeByFilter = async (filter) => {
	const aggregate = [];
	aggregate.push({ '$match': { 'isDel': false } });
	aggregate.push({ '$match': filter });
	if(store.getState().AuthReducer.user?.companyInfo?.org !== "HQ"){
		aggregate.push({
			'$match' : {
				'owner' : {
					'$in' : localStorage.getItem('clist')?.split(',') || []
			  	}
			}
		});
	}
	const data = {
		data : {
			type: 'calendars',
			aggregate,
			passfilter : true
		}
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/q', data, JWT());
	//const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cal', data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	}
};

export const CalendarAction = {
	GetAll,
	GetOne,
	GetAllByFilter,
	Create,
	CreateUpdateByService,
	Update,
	Delete,
	GetAllHomeByFilter
};
