import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import 'react-swipeable-list/dist/styles.css';
import ProductDIMModal from '../../components/product/ProductDIMModal';
import { NoRecord } from '../../pages/HomePage';
import ServiceCard from '../service/ServiceCard';
import ProductErrorCard from './ProductErrorCard';
import { ServiceAction } from '../../actions';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const ProductDIM = ({ product, _sn }) => {
	// const [ t ] = useTranslation();
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [dimView, setDimView] = useState(false);
	const [list, setList] = useState([]);

	var dimdate = new Date();
	dimdate.setDate(dimdate.getDate() - 30);

	useEffect(() => {
		setList(product.dimlogs ? [...product.dimlogs].sort((a, b) => b.timestamp - a.timestamp) : []);
	}, [product]);

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(
					<React.Fragment key={idx}>
						{line} <br />
					</React.Fragment>
				);
			});
		} else {
			return 'N/A';
		}
		return html;
	};

	return (
		<div className="section">
			{!product.orisn && <>
				{(product.dim || product.installedDate > dimdate.getTime()) &&
					<>
						<button className="btn btn-block btn-primary" onClick={() => setDimView(true)}>
							{product.dim ? t('cleardim') : t('checkdim')}
						</button>
						<ProductDIMModal product={product} setDimView={setDimView} dimView={dimView} />
					</>}
			</>}

			{list.length > 0 &&
				<div className="card"
					style={_sn && {
						boxShadow: "4px 4px 4px darkgrey"
					}}>
					<div className="timeline ms-1">
						{list.map((item, idx) =>
							<div className="item" key={idx}>
								<div className={item.dim ? "dot bg-danger" : "dot bg-primary"}></div>
								<div className="content">
									<div className="title">
										{item.dim && <span className="badge badge-danger">DIM</span>}
										{!item.dim && <span className="badge badge-primary">{t('clear')}</span>}
										{' '}
										{item.user} <span className="text-muted">{moment.utc(item.timestamp).local().fromNow()}{' '}</span>
									</div>
									{item.serviceId && <div className="card p-0 m-0 me-1 border">
										<ServiceCard byKey={item.serviceId} hideDate={true} />
									</div>}
									{item.error && <div className="card p-0 m-0 me-1 border">
										<ProductErrorCard item={{
											errs: item.error,
											option: product.option,
											sn: product.sn
										}} byService={true} />
									</div>}
									<div className="title mt-1">
										{item.comment && <>{nl2br(item.comment)}</>}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>}
			{list.length === 0 && <NoRecord />}
		</div>
	);
};

const mapState = (state) => {
	return {};
};

const mapDispatch = (dispatch) => ({
	//GetServiceBySN: (sn) => dispatch(ServiceAction.GetServiceBySN(sn)),
});

export default connect(mapState, mapDispatch)(ProductDIM);
