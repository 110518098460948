import React from 'react';
import Loader from "react-loader-spinner";

export const Loading = ({ type = "ThreeDots", text = "" }) => {
	return (
		<div className="text-center">
			<Loader type={type} color="#00beff" height={50} width={50} timeout={0} />
			{text && text}
		</div>
	)
}