export const LanguageCode = [
    { code : "abk", title : "Abkhazian" },
    { code : "ace", title : "Achinese" },
    { code : "ach", title : "Acoli" },
    { code : "ada", title : "Adangme" },
    { code : "ady", title : "Adyghe" },
    { code : "aar", title : "Afar" },
    { code : "afh", title : "Afrihili" },
    { code : "afr", title : "Afrikaans" },
    { code : "afa", title : "Afro - Asiatic languages" },
    { code : "ain", title : "Ainu" },
    { code : "aka", title : "Akan" },
    { code : "akk", title : "Akkadian" },
    { code : "alb", title : "Albanian" },
    { code : "ale", title : "Aleut" },
    { code : "alg", title : "Algonquian languages" },
    { code : "tut", title : "Altaic languages" },
    { code : "amh", title : "Amharic" },
    { code : "egy", title : "Ancient Egyptian" },
    { code : "grc", title : "Ancient Greek" },
    { code : "apa", title : "Apache languages" },
    { code : "ara", title : "Arabic" },
    { code : "arg", title : "Aragonese" },
    { code : "arc", title : "Aramaic" },
    { code : "arp", title : "Arapaho" },
    { code : "aah", title : "Arapesh, Abu" },
    { code : "arn", title : "Araucanian" },
    { code : "arw", title : "Arawak" },
    { code : "arm", title : "Armenian" },
    { code : "art", title : "Artificial languages" },
    { code : "asm", title : "Assamese" },
    { code : "ast", title : "Asturian; Bable" },
    { code : "ath", title : "Athapascan languages" },
    { code : "aus", title : "Australian languages" },
    { code : "map", title : "Austronesian languages" },
    { code : "ava", title : "Avar" },
    { code : "ave", title : "Avestan" },
    { code : "awa", title : "Awadhi" },
    { code : "aym", title : "Aymara" },
    { code : "aze", title : "Azeri; Azerbaijani" },
    { code : "ban", title : "Balinese" },
    { code : "bat", title : "Baltic languages" },
    { code : "bal", title : "Baluchi" },
    { code : "bam", title : "Bambara" },
    { code : "bai", title : "Bamileke languages" },
    { code : "bad", title : "Banda" },
    { code : "bnt", title : "Bantu languages" },
    { code : "bas", title : "Basa" },
    { code : "bak", title : "Bashkir" },
    { code : "baq", title : "Basque" },
    { code : "bar", title : "Bavarian" },
    { code : "bej", title : "Beja" },
    { code : "bel", title : "Belarusian" },
    { code : "bem", title : "Bemba" },
    { code : "ben", title : "Bengali" },
    { code : "ber", title : "Berber languages" },
    { code : "bho", title : "Bhojpuri" },
    { code : "bih", title : "Bihari" },
    { code : "bik", title : "Bikol" },
    { code : "bin", title : "Bini" },
    { code : "bis", title : "Bislama" },
    { code : "byn", title : "Blin; Bilin" },
    { code : "bos", title : "Bosnian" },
    { code : "bra", title : "Braj" },
    { code : "bre", title : "Breton" },
    { code : "bug", title : "Buginese" },
    { code : "bul", title : "Bulgarian" },
    { code : "bua", title : "Buriat" },
    { code : "bur", title : "Burmese" },
    { code : "cad", title : "Caddo" },
    { code : "zhh", title : "Cantonese Chinese" },
    { code : "car", title : "Carib" },
    { code : "cat", title : "Catalan" },
    { code : "cau", title : "Caucasian languages" },
    { code : "ceb", title : "Cebuano" },
    { code : "cel", title : "Celtic languages" },
    { code : "cai", title : "Central American Indian languages" },
    { code : "chg", title : "Chagatai" },
    { code : "cmc", title : "Chamic languages" },
    { code : "cha", title : "Chamorro" },
    { code : "che", title : "Chechen" },
    { code : "chr", title : "Cherokee" },
    { code : "chy", title : "Cheyenne" },
    { code : "chb", title : "Chibcha" },
    { code : "nya", title : "Chichewa; Chewa; Nyanja" },
    { code : "chn", title : "Chinook jargon" },
    { code : "chp", title : "Chipewyan" },
    { code : "cho", title : "Choctaw" },
    { code : "chu", title : "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic" },
    { code : "chk", title : "Chuukese" },
    { code : "chv", title : "Chuvash" },
    { code : "nwc", title : "Classical Newari; Old Newari" },
    { code : "cop", title : "Coptic" },
    { code : "cor", title : "Cornish" },
    { code : "cos", title : "Corsican" },
    { code : "cre", title : "Cree" },
    { code : "mus", title : "Creek" },
    { code : "crh", title : "Crimean Turkish; Crimean Tatar" },
    { code : "scr", title : "Croatian" },
    { code : "cus", title : "Cushitic languages" },
    { code : "cze", title : "Czech" },
    { code : "dak", title : "Dakota" },
    { code : "dan", title : "Danish" },
    { code : "dar", title : "Dargwa" },
    { code : "day", title : "Dayak" },
    { code : "del", title : "Delaware" },
    { code : "din", title : "Dinka language" },
    { code : "div", title : "Divehi language" },
    { code : "doi", title : "Dogri" },
    { code : "dgr", title : "Dogrib" },
    { code : "dra", title : "Dravidian languages" },
    { code : "dua", title : "Duala" },
    { code : "dut", title : "Dutch; Flemish" },
    { code : "dyu", title : "Dyula" },
    { code : "dzo", title : "Dzongkha" },
    { code : "efi", title : "Efik" },
    { code : "eka", title : "Ekajuk language" },
    { code : "elx", title : "Elamite" },
    { code : "eng", title : "English" },
    { code : "cpe", title : "English-based creoles and pidgins" },
    { code : "myv", title : "Erzya" },
    { code : "epo", title : "Esperanto" },
    { code : "est", title : "Estonian" },
    { code : "ewe", title : "Ewe" },
    { code : "ewo", title : "Ewondo" },
    { code : "fan", title : "Fang" },
    { code : "fat", title : "Fanti" },
    { code : "fao", title : "Faroese" },
    { code : "hif", title : "Fiji Hindi" },
    { code : "fij", title : "Fijian" },
    { code : "fil", title : "Filipino; Pilipino" },
    { code : "fin", title : "Finnish" },
    { code : "fiu", title : "Finno-Ugrian languages" },
    { code : "fon", title : "Fon" },
    { code : "fre", title : "French" },
    { code : "cpf", title : "French-based creoles and pidgins" },
    { code : "fry", title : "Frisian" },
    { code : "ful", title : "Fulah; Fulani" },
    { code : "gaa", title : "Ga" },
    { code : "gla", title : "Gaelic; Scottish Gaeli" },
    { code : "glg", title : "galicien" },
    { code : "lug", title : "Ganda" },
    { code : "gay", title : "Gayo" },
    { code : "gba", title : "Gbaya" },
    { code : "gez", title : "Geez" },
    { code : "geo", title : "Georgian" },
    { code : "deu", title : "German" },
    { code : "gem", title : "Germanic languages" },
    { code : "gil", title : "Gilbertese" },
    { code : "gon", title : "Gondi" },
    { code : "got", title : "Gothic" },
    { code : "grb", title : "Grebo" },
    { code : "kal", title : "Greenlandic; Kalaallisut" },
    { code : "grn", title : "Guarani" },
    { code : "guj", title : "Gujarati" },
    { code : "gwi", title : "Gwich' in" },
    { code : "hai", title : "Haida" },
    { code : "hat", title : "Haitian" },
    { code : "hau", title : "Hausa" },
    { code : "haw", title : "Hawaiian" },
    { code : "heb", title : "Hebrew" },
    { code : "her", title : "Herero" },
    { code : "hil", title : "Hiligaynon" },
    { code : "him", title : "Himachali" },
    { code : "hin", title : "Hindi" },
    { code : "hmo", title : "Hiri Motu" },
    { code : "hit", title : "Hittite" },
    { code : "hmn", title : "Hmong" },
    { code : "hun", title : "Hungarian" },
    { code : "hup", title : "Hupa" },
    { code : "iba", title : "Iban" },
    { code : "ice", title : "Icelandic" },
    { code : "ido", title : "Ido" },
    { code : "ibo", title : "Igbo" },
    { code : "ijo", title : "Ijo" },
    { code : "ilo", title : "Iloko" },
    { code : "smn", title : "Inari Sami" },
    { code : "inc", title : "Indic languages" },
    { code : "ind", title : "Indonesian" },
    { code : "inh", title : "Ingush" },
    { code : "ina", title : "Interlingua (International Auxiliary Language Association)" },
    { code : "ine", title : "Interlingue" },
    { code : "iku", title : "Inuktitut" },
    { code : "ipk", title : "Inupiaq" },
    { code : "ira", title : "Iranian languages" },
    { code : "gle", title : "Irish" },
    { code : "iro", title : "Iroquoian languages" },
    { code : "ita", title : "Italian" },
    { code : "jpn", title : "Japanese" },
    { code : "jav", title : "Javanese" },
    { code : "jrb", title : "Judeo-Arabic" },
    { code : "jpr", title : "Judeo - Persian" },
    { code : "kbd", title : "Kabardian" },
    { code : "kab", title : "Kabyle" },
    { code : "kac", title : "Kachin" },
    { code : "xal", title : "Kalmyk" },
    { code : "kam", title : "Kamba" },
    { code : "kan", title : "Kannada" },
    { code : "kau", title : "Kanuri" },
    { code : "krc", title : "Karachay - Balkar" },
    { code : "kaa", title : "Kara-Kalpak" },
    { code : "kar", title : "Karen" },
    { code : "kas", title : "Kashmiri" },
    { code : "csb", title : "Kashubian" },
    { code : "kaw", title : "Kawi" },
    { code : "kaz", title : "Kazakh" },
    { code : "kha", title : "Khasi" },
    { code : "khm", title : "Khmer" },
    { code : "khi", title : "Khoisan languages" },
    { code : "kho", title : "Khotanese" },
    { code : "kik", title : "Kikuyu; Gikuyu" },
    { code : "kin", title : "Kinyarwanda" },
    { code : "kir", title : "Kirghiz" },
    { code : "tlh", title : "Klingon" },
    { code : "kom", title : "Komi" },
    { code : "kon", title : "Kongo" },
    { code : "kok", title : "Konkani" },
    { code : "kor", title : "Korean" },
    { code : "kus", title : "Kosraean" },
    { code : "kpe", title : "Kpelle" },
    { code : "kro", title : "Kru" },
    { code : "kua", title : "Kuanyama" },
    { code : "kum", title : "Kumyk" },
    { code : "kur", title : "Kurdish" },
    { code : "kru", title : "Kurukh" },
    { code : "kut", title : "Kutenai" },
    { code : "lad", title : "Ladino" },
    { code : "lah", title : "Lahnda" },
    { code : "lam", title : "Lamba" },
    { code : "smi", title : "langues sami" },
    { code : "lao", title : "Lao" },
    { code : "lat", title : "Latin" },
    { code : "lav", title : "Latvian" },
    { code : "lez", title : "Lezghian" },
    { code : "lim", title : "Limburgan; Limburger; Limburgish" },
    { code : "lin", title : "Lingala" },
    { code : "lit", title : "Lithuanian" },
    { code : "jbo", title : "Lojban" },
    { code : "nds", title : "Low German; Low Saxon" },
    { code : "dsb", title : "Lower Sorbian" },
    { code : "loz", title : "Lozi" },
    { code : "lub", title : "Luba - Katanga" },
    { code : "lua", title : "Luba-Lulua" },
    { code : "lui", title : "Luiseno" },
    { code : "smj", title : "Lule Sami" },
    { code : "lun", title : "Lunda" },
    { code : "luo", title : "Luo (Kenya and Tanzania)" },
    { code : "lus", title : "Lushai" },
    { code : "ltz", title : "Luxembourgish; Letzeburgesch" },
    { code : "mac", title : "Macedonian" },
    { code : "mad", title : "Madurese" },
    { code : "mag", title : "Magahi" },
    { code : "mai", title : "Maithili" },
    { code : "mak", title : "Makasar" },
    { code : "mlg", title : "Malagasy" },
    { code : "may", title : "Malay" },
    { code : "mal", title : "Malayalam" },
    { code : "mlt", title : "Maltese" },
    { code : "mnc", title : "Manchu" },
    { code : "mdr", title : "Mandar" },
    { code : "cmn", title : "Mandarin Chinese" },
    { code : "man", title : "Mandingo" },
    { code : "mni", title : "Manipuri" },
    { code : "mno", title : "Manobo languages" },
    { code : "glv", title : "Manx" },
    { code : "mao", title : "Maori" },
    { code : "mar", title : "Marathi" },
    { code : "chm", title : "Mari" },
    { code : "mah", title : "Marshallese" },
    { code : "mwr", title : "Marwari" },
    { code : "mas", title : "Masai" },
    { code : "myn", title : "Mayan languages" },
    { code : "men", title : "Mende" },
    { code : "dum", title : "Middle Dutch" },
    { code : "enm", title : "Middle English" },
    { code : "frm", title : "Middle French" },
    { code : "gmh", title : "Middle High German" },
    { code : "mga", title : "Middle Irish" },
    { code : "mic", title : "Mi'kmaq; Micmac" },
    { code : "min", title : "Minangkabau" },
    { code : "mwl", title : "Mirandese" },
    { code : "mis", title : "Miscellaneous languages" },
    { code : "gre", title : "Modern Greek" },
    { code : "mdf", title : "Moksha" },
    { code : "moh", title : "Mohawk" },
    { code : "mol", title : "Moldavian; Moldovan" },
    { code : "lol", title : "Mongo" },
    { code : "mon", title : "Mongolian" },
    { code : "mkh", title : "Mon-Khmer languages" },
    { code : "mos", title : "Mossi" },
    { code : "mun", title : "Munda languages" },
    { code : "nah", title : "Nahuatl" },
    { code : "nau", title : "Nauru" },
    { code : "nav", title : "Navajo; Navaho" },
    { code : "ndo", title : "Ndonga" },
    { code : "nap", title : "Neopolitan" },
    { code : "nep", title : "Nepali" },
    { code : "new", title : "Newari; Nepal Bhasa" },
    { code : "nic", title : "Niger-Kordofanian languages" },
    { code : "ssa", title : "Nilo-Saharan languages" },
    { code : "niu", title : "Niuean" },
    { code : "nog", title : "Nogai" },
    { code : "nai", title : "North American Indian languages" },
    { code : "nde", title : "North Ndebele" },
    { code : "sme", title : "Northern Sami" },
    { code : "nor", title : "Norwegian" },
    { code : "nob", title : "Norwegian Bokmål" },
    { code : "nno", title : "Norwegian Nynorsk" },
    { code : "nub", title : "Nubian languages" },
    { code : "nym", title : "Nyamwezi" },
    { code : "nyn", title : "Nyankole" },
    { code : "nyo", title : "Nyoro" },
    { code : "nzi", title : "Nzima" },
    { code : "oci", title : "Occitan; Provençal" },
    { code : "oji", title : "Ojibwa" },
    { code : "ang", title : "Old English" },
    { code : "fro", title : "Old French" },
    { code : "goh", title : "Old High German" },
    { code : "sga", title : "Old Irish" },
    { code : "non", title : "Old Norse" },
    { code : "peo", title : "Old Persian" },
    { code : "pro", title : "Old Provençal" },
    { code : "ori", title : "Oriya" },
    { code : "orm", title : "Oromo" },
    { code : "osa", title : "Osage" },
    { code : "oss", title : "Ossetian; Ossetic" },
    { code : "crp", title : "other creoles and pidgins" },
    { code : "oto", title : "Otomian languages" },
    { code : "ota", title : "Ottoman Turkish" },
    { code : "pal", title : "Pahlavi" },
    { code : "pau", title : "Palauan" },
    { code : "pli", title : "Pali" },
    { code : "pam", title : "Pampanga" },
    { code : "pag", title : "Pangasinan" },
    { code : "pan", title : "Panjabi; Punjabi" },
    { code : "pap", title : "Papiamento" },
    { code : "paa", title : "Papuan languages" },
    { code : "nso", title : "Pedi; Sepedi; Northern Sotho" },
    { code : "per", title : "Persian" },
    { code : "phi", title : "Philippine languages" },
    { code : "phn", title : "Phoenician" },
    { code : "pon", title : "Pohnpeian" },
    { code : "pol", title : "Polish" },
    { code : "por", title : "Portuguese" },
    { code : "cpp", title : "Portuguese-based creoles and pidgins" },
    { code : "pra", title : "Prakrit languages" },
    { code : "pus", title : "Pushto" },
    { code : "que", title : "Quechua" },
    { code : "raj", title : "Rajasthani" },
    { code : "rap", title : "Rapanui" },
    { code : "rar", title : "Rarotongan" },
    { code : "roh", title : "Rhaeto-Romance" },
    { code : "roa", title : "Romance languages" },
    { code : "rum", title : "Romanian" },
    { code : "rom", title : "Romany" },
    { code : "run", title : "Rundi" },
    { code : "rus", title : "Russian" },
    { code : "rue", title : "Rusyn" },
    { code : "sal", title : "Salishan languages" },
    { code : "sam", title : "Samaritan Aramaic" },
    { code : "smo", title : "Samoan" },
    { code : "sad", title : "Sandawe" },
    { code : "sag", title : "Sango" },
    { code : "san", title : "Sanskrit" },
    { code : "sat", title : "Santali" },
    { code : "srd", title : "Sardinian" },
    { code : "sas", title : "Sasak" },
    { code : "sco", title : "Scots" },
    { code : "sel", title : "Selkup" },
    { code : "sem", title : "Semitic languages" },
    { code : "scc", title : "Serbian" },
    { code : "srr", title : "Serer" },
    { code : "shn", title : "Shan" },
    { code : "sna", title : "Shona" },
    { code : "iii", title : "Sichuan Yi" },
    { code : "scn", title : "Sicilian" },
    { code : "sid", title : "Sidamo" },
    { code : "sgn", title : "Sign languages" },
    { code : "bla", title : "Siksika" },
    { code : "snd", title : "Sindhi" },
    { code : "sin", title : "Sinhala; Sinhalese" },
    { code : "sit", title : "Sino-Tibetan languages" },
    { code : "sio", title : "Siouan languages" },
    { code : "sms", title : "Skolt Sami" },
    { code : "den", title : "Slave (Athapascan)" },
    { code : "sla", title : "Slavic languages" },
    { code : "slo", title : "Slovak" },
    { code : "slv", title : "Slovenian" },
    { code : "sog", title : "Sogdian" },
    { code : "som", title : "Somali" },
    { code : "son", title : "Songhai" },
    { code : "wen", title : "Sorbian languages" },
    { code : "sai", title : "South American Indian languages" },
    { code : "nbl", title : "South Ndebele" },
    { code : "alt", title : "Southern Altai" },
    { code : "sma", title : "Southern Sami" },
    { code : "sot", title : "Southern Sotho" },
    { code : "spa", title : "Spanish; Castilian" },
    { code : "suk", title : "Sukuma" },
    { code : "sux", title : "Sumerian" },
    { code : "sun", title : "Sundanese" },
    { code : "sus", title : "Susu" },
    { code : "swa", title : "Swahili" },
    { code : "ssw", title : "Swati" },
    { code : "swe", title : "Swedish" },
    { code : "syr", title : "Syriac" },
    { code : "tgl", title : "Tagalog" },
    { code : "tah", title : "Tahitian" },
    { code : "tai", title : "Tai languages" },
    { code : "tgk", title : "Tajik" },
    { code : "tmh", title : "Tamashek" },
    { code : "tam", title : "Tamil" },
    { code : "tat", title : "Tatar" },
    { code : "tel", title : "Telugu" },
    { code : "ter", title : "Tereno" },
    { code : "tet", title : "Tetum" },
    { code : "tha", title : "Thai" },
    { code : "tib", title : "Tibetan" },
    { code : "tig", title : "Tigre" },
    { code : "tir", title : "Tigrinya" },
    { code : "tem", title : "Timne" },
    { code : "tiv", title : "Tiv" },
    { code : "tli", title : "Tlingit" },
    { code : "tpi", title : "Tok Pisin" },
    { code : "tog", title : "Tonga (Nyasa)" },
    { code : "ton", title : "Tonga (Tonga Islands)" },
    { code : "tsi", title : "Tsimshian" },
    { code : "tso", title : "Tsonga" },
    { code : "tsn", title : "Tswana" },
    { code : "tum", title : "Tumbuka" },
    { code : "tup", title : "Tupi languages" },
    { code : "tur", title : "Turkish" },
    { code : "tuk", title : "Turkmen" },
    { code : "tvl", title : "Tuvalu" },
    { code : "tyv", title : "Tuvinian" },
    { code : "twi", title : "Twi" },
    { code : "udm", title : "Udmurt" },
    { code : "uga", title : "Ugaritic" },
    { code : "uig", title : "Uighur" },
    { code : "ukr", title : "Ukrainian" },
    { code : "umb", title : "Umbundu" },
    { code : "hsb", title : "Upper Sorbian" },
    { code : "urd", title : "Urdu" },
    { code : "uzb", title : "Uzbek" },
    { code : "vai", title : "Vai" },
    { code : "ven", title : "Venda" },
    { code : "vie", title : "Vietnamese" },
    { code : "vol", title : "Volapük" },
    { code : "vot", title : "Votic" },
    { code : "wak", title : "Wakashan languages" },
    { code : "wal", title : "Walamo" },
    { code : "wln", title : "Walloon" },
    { code : "war", title : "Waray" },
    { code : "was", title : "Washo" },
    { code : "wel", title : "Welsh" },
    { code : "wol", title : "Wolof" },
    { code : "xho", title : "Xhosa" },
    { code : "sah", title : "Yakut" },
    { code : "yao", title : "Yao" },
    { code : "yap", title : "Yapese" },
    { code : "yid", title : "Yiddish" },
    { code : "yor", title : "Yoruba" },
    { code : "ypk", title : "Yupik languages" },
    { code : "znd", title : "Zande" },
    { code : "zap", title : "Zapotec" },
    { code : "zen", title : "Zenaga" },
    { code : "zha", title : "Zhuang; Chuang" },
    { code : "zul", title : "Zulu" },
    { code : "zun", title : "Zuni" }
]