export const Translate = (title) => {
    const translate = localStorage.getItem('translate');

    let result = "";

    if (translate && translate != 'undefined') {
        result = JSON.parse(translate).find(item => { return item.title.trim() == title });
    }

    return !result ? title : result.contents;
};