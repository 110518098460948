import { AlertConstant } from './constants/AlertConstant'

const Success = (message) => async (dispatch) => {
    return dispatch({ type: AlertConstant.SUCCESS, message });
}

const Error = (data) => async (dispatch) => {
    if (data) {
        return dispatch({ type: AlertConstant.ERROR, data: data.errors || [], message: data.errmsg });
    }
}

const ErrorMsg = (message) => async (dispatch) => {
    return dispatch({ type: AlertConstant.ERRORMSG, message });
}

function Closed() {
    return { type: AlertConstant.CLOSED };
}

function Clear() {
    return { type: AlertConstant.CLEAR };
}

export const AlertAction = {
    Success,
    Error,
    ErrorMsg,
    Clear,
    Closed,
}
