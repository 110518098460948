import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { RayDelButton } from '../../components/common/Buttons';
import { Modal } from 'react-bootstrap';
import { InstallationAction, ProductAction } from '../../actions';
import { ProductPartAction } from '../../actions';
import { useTranslation } from 'react-i18next';

const DOAModal = ({ installation, product, UpdateProductInfo, byprd = false, doaView, setDoaView, Update }) => {
	// 	const [t] = useTranslation();
	const [doaData, setDoaData] = useState({});
	const [partBL, setPartBL] = useState(false);
	const [comboParent, setComboParent] = useState([]);
	const [combo, setCombo] = useState([]);
	const [codes, setCodes] = useState([]);
	const [list, setList] = useState([]);
	const [item, setItem] = useState({
		title: "",
		subtitle: ""
	});



	useEffect(() => {
		ProductPartAction.GetAllDirect().then(x => setCodes(x));
	}, [])

	// useEffect(() => {
	// 	setDoaView(false);
	// 	if (!byprd) {
	// 		if (installation.doacomment) {
	// 			setList([...installation.doacomment.parts]);
	// 			setPartBL(installation.doacomment.parts.length > 0);
	// 		}
	// 		setDoaData({ ...installation.doacomment });
	// 	}

	// }, [installation, product])

	useEffect(() => {
		if (codes.length > 0) {
			SetCodeData();
		}
	}, [codes])

	useEffect(() => {
		if (!item.title || codes.length == 0)
			return;

		var list = [];

		codes.map(x => {
			if (x.type == item.title && x.models.includes(product.model))
				list.push(x);
		});

		setCombo(list);
		setItem({ ...item, ["subtitle"]: list[0]?.code });
	}, [item.title])

	const del = (c) => {
		setList(list.filter(x => x.title + x.subtitle != c.title + c.subtitle));
	}

	const additem = () => {
		if (list.filter(x => x.title + x.subtitle == item.title + item.subtitle).length === 0)
			setList([...list, item]);
	}

	const getCodeName = (code) => {
		const m = codes.filter(x => x.code == code);
		if (m.length > 0) {
			return m[0].name;
		}
		return code;
	}

	const SetCodeData = () => {
		var types = [];
		codes.map(x => types.push(x.type));
		const newList = [...new Set(types)];
		setComboParent(newList);
		setItem({ ...item, ["title"]: newList[0] });
	}

	const updateDoa = (bl) => {
		if (byprd) {
			UpdateProductInfo(product._id, {
				doa: bl,
				"$push": {
					doalogs: {
						...doaData,
						doa: bl,
						parts: (partBL ? list : []),
						timestamp: new Date().getTime()
					}
				}
			});

			setDoaView(false);
		} else {
			Update(installation._id, {
				doaupdate: "Y",
				doa: bl,
				sn: installation.sn,
				doacomment: (bl ? { ...doaData, parts: (partBL ? list : []) } : {})
			});
		}
	}

	const changeCombo = (e) => {
		if (!e.target.value) return;
		var rep = {};
		codes.map(x => {
			if (x.type == item.title && x.code == e.target.value)
				rep = x;
		});
		setItem({ ...item, ["subtitle"]: e.target.value });
	}

	const changeComboParent = (e) => {
		setItem({ ...item, ["title"]: e.target.value });
	}

	return (
		<Modal show={doaView} onHide={() => setDoaView(false)} className="action-sheet" dialogClassName="modal-fullscreen" scrollable={true}>
			<Modal.Header>
				<h2 className="modal-title">Detective On Arrival (If applicable)</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<ul className="listview simple-listview no-space border-0 m-2">
					{(!byprd || (byprd && !product.doa)) && <>
						<div className="form-group basic mb-2">
							<div className="item nopadd">
								<div className="in">
									<div className="label">
										<strong className="f16">Detective parts</strong>
									</div>
									<div className="form-check form-switch">
										<input className="form-check-input" type="checkbox"
											checked={partBL}
											value={partBL} />
										<label className="form-check-label" onClick={() => setPartBL(!partBL)} />
									</div>
								</div>
							</div>
						</div>
					</>}
					{partBL && <>
						<strong>Choose Parts</strong>
						<div className="form-group basic">
							<select className="form-control mb-1"
								value={item.title}
								onChange={(e) => changeComboParent(e)}>
								{comboParent.map(x => <option value={x}>{x}</option>)}

							</select>
							<select className="form-control mb-1"
								value={item.subtitle}
								onChange={(e) => changeCombo(e)}>
								{combo.map(x => <option value={x.code}>{x.name}</option>)}
							</select>
						</div>
						<ul className="listview simple-listview transparent flush">

						</ul>
						{list.map((x, idx) =>
							<li className={idx > 0 ? "border-top" : ""}>
								<div>{x.title} - {getCodeName(x.subtitle)}</div>
								<div className="form-check form-switch">
									<button
										className="btn btn-sm btn-danger float-end"
										onClick={() => del(x)}><ion-icon name="trash-outline"></ion-icon></button>
								</div>
							</li>
						)}
						<button className="btn btn-primary btn-block" onClick={() => additem()}>{t('add')}</button>
					</>}
					<div className={partBL ? "form-group basic mt-2" : "form-group basic"}>
						<div className="input-wrapper">
							<label className="label">Details</label>
							<textarea className="form-control"
								rows={5}
								onChange={(e) => setDoaData({ ...doaData, comment: e.target.value })}
								defaultValue={doaData.comment}></textarea>
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				{/* {byprd &&
					<>
						{!product.doa && <button
							className="btn btn-block btn-primary mb-0"
							onClick={() => updateDoa(true)}>Check DOA</button>}
						{product.doa && <button
							className="btn btn-block btn-success mb-0"
							onClick={() => updateDoa(false)}>{t('clear')}</button>}
					</>
				} */}
				{
					// !byprd &&
					<>
						{!product.doa && <button
							className="btn btn-block btn-primary mb-0"
							onClick={() => updateDoa(true)}>{t('save')}</button>}
						{product.doa &&
							<>
								<button
									className="btn btn-block btn-primary mb-0"
									onClick={() => updateDoa(true)}>{t('save')}</button>
								<button
									className="btn btn-block btn-success mb-0"
									onClick={() => updateDoa(false)}>{t('clear')}</button>
							</>}
					</>
				}

				<button className="btn btn-block btn-dark" onClick={() => setDoaView(false)}>{t('close')}</button>
			</Modal.Footer>
		</Modal>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	UpdateProductInfo: (sn, data) => dispatch(ProductAction.UpdateProductInfo(sn, data)),
});

export default connect(null, mapDispatch)(DOAModal);