import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SiteAction } from '../../actions/SiteAction';
import { RayForm, RayMaps } from '../../components/common';
import { SpecialityTypes } from '../../config/RayCodes';
import { CountryCode } from '../../config';
import { ModalConfirm } from '../../components/common/ModalConfirm';
import { Translate as t } from '../../config/Translate';

const AdminSiteInfo_Site = ({ site, GetSite, UpdateSiteInfo, editable }) => {

    const [errors, setErrors] = useState([]);
    const [isMapOk, setIsMapOk] = useState(true);
    const [updateModalInfo, setUpdateModalInfo] = useState({});
    const [pos, setPos] = useState({});

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "sitename", label: t('sitename'), val: site.name, required: true, isEditable: editable },
                { type: "select", name: "speciality", label: t('specialty'), val: site.speciality, list: SpecialityTypes, isEditable: editable }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "tel", label: t('tel'), val: site.tel, isEditable: editable },
                { type: "tel", name: "fax", label: t('fax'), val: site.fax, isEditable: editable }
            ]
        },
        {
            title: "", items: [
                { type: "email", name: "email", label: t('email'), val: site.email, isEditable: editable },
                { type: "tel", name: "hp", label: t('mobile'), val: site.hp, isEditable: editable }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "address", label: t('address'), size: 12, val: site.address, isEditable: editable },
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "addressExtra", label: "Address Extra", size: 12, val: site.addressExtra, isEditable: editable }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "zipcode", label: t('zipcode'), val: site.zipcode, isEditable: editable },
                { type: "select", name: "country", label: t('country'), val: site.countryCode, list: CountryCode, isEditable: editable }
            ]
        },
        {
            title: "", items: [
                { type: "input", name: "website", label: t('website'), val: site.website, size: 12, isEditable: editable }
            ]
        }
    ]

    const mapOk = (bl) => {
        setIsMapOk(bl);
    }

    const updateSiteLocation = (e) => {
        UpdateSiteInfo(site._id, { lat: e.lat, lng: e.lng });
        setUpdateModalInfo({ ...updateModalInfo, showModal: false });
    }

    const callbackAddress = (e) => {
        setUpdateModalInfo({
            showModal: true,
            title: "Update site info?",
            content: "Do you want to update site location?",
            actions: [{ title: "Yes", callback: () => updateSiteLocation(e) }]
        });
    }

    useEffect(() => {
        setPos({
            lat: site.lat || 37.400671488676345,
            lng: site.lng || 127.10900008486625,
            isset: (site.lat && site.lng)
            // lat: parseFloat(site.coords[1]) || 37.400671488676345,
            // lng: parseFloat(site.coords[0]) || 127.10900008486625,
            // isset: (site.coords[1] && site.coords[0])
        });
    }, [site])

    return (
        <>
            <div className="ms-2 me-2">
                {!pos.isset && <div className='alert alert-danger rounded-0'>Not set site location. please update location.</div>}
                {(pos.lat && pos.lng && isMapOk) &&
                    <RayMaps
                        id="myMap"
                        options={{
                            zoom: 18,
                            draggable: true,
                            mapTypeControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            scaleControl: false,
                            zoomControl: false
                        }}
                        onMapLoad={map => {

                        }}
                        callback={(bl) => mapOk(bl)}
                        callbackAddress={callbackAddress}
                        country=""
                        Lat={pos.lat}
                        Lng={pos.lng}
                        markerList={[]}
                    />
                }
            </div>
            <RayForm
                controls={form_controls}
                action={UpdateSiteInfo}
                actionkey={site._id}
                errors={errors} />
            <div>
            </div>
            <ModalConfirm
                isActionSheet={true}
                info={updateModalInfo}
                callbackClose={() => setUpdateModalInfo({ ...updateModalInfo, showModal: false })} />
        </>
    );
};

const mapDispatch = dispatch => ({
    UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data))
})
export default connect(null, mapDispatch)(AdminSiteInfo_Site);