import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ServiceAction } from '../../actions';
import ServiceCard from '../service/ServiceCard';
import { Translate as t } from '../../config/Translate';
import {
	LeadingActions,
	SwipeableList,
	SwipeableListItem,
	SwipeAction,
	Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { NoRecord } from '../../pages/HomePage';

const ProductInfoServices = ({ product, items, favServices, GetServiceBySN, _sn }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [list, setList] = useState([]);
	const [sort, setSort] = useState(1);

	useEffect(() => {
		GetServiceBySN(product.sn);
	}, [product]);

	useEffect(() => {
		setList([...items]);
	}, [items]);

	const removeArr = (id) => {
		const idx = favServices.indexOf(id);
		if (idx > -1) {
			favServices.splice(idx, 1);
			if (showFav)
				setList([...list.filter(x => x.ticketKey != id)])
		}
		return favServices;
	}

	const fav = (isFav, ticketKey) => {
		UpdateUserFav("service", isFav ?
			{ favoriteServices: removeArr(ticketKey) } :
			{ favoriteServices: [...favServices, ticketKey] });
	}

	const updateFav = (ticketKey) => {
		fav(favServices.filter(x => x == ticketKey).length > 0 ? true : false, ticketKey)
	}

	const leadingActions = (isFav, ticketKey, item) => (
		<LeadingActions>
			<SwipeAction onClick={() => updateFav(ticketKey)}>
				<div className={isFav ? "bg-danger text-center" : "bg-dark text-center"} style={{ width: "80px" }}>
					<ion-icon size="large" name={isFav ? "heart" : "heart-outline"}></ion-icon>
				</div>
			</SwipeAction>
		</LeadingActions>
	);

	return (
		<>
			{list.length > 0 &&
				<>
					{isAdmin && <div className="section">
						<div className="card image-listview"
							style={_sn && {
								boxShadow: "4px 4px 4px darkgrey"
							}}>
							{list.map((item, index) =>
								<div className={item.status == "Close" ? "status-close swipeable-list-item__content swipeable-list-item" : "swipeable-list-item__content swipeable-list-item"}>
									<ServiceCard item={item} key={item._id} />
								</div>
							)}
						</div>
					</div>}
					{!isAdmin && <>
						<div className='p-1 text-right'>
							<button className="btn btn-sm btn-secondary m-0 me-1" onClick={() => {
								setList(list.sort((a, b) => sort == 1 ? a.createDate - b.createDate : b.createDate - a.createDate));
								setSort(!sort);
							}}>Sort :
								<ion-icon name={sort == 1 ? "arrow-down-outline" : "arrow-up-outline"} role="img" className="md hydrated" aria-label={sort == 1 ? "arrow down outline" : "arrow up outline"}></ion-icon>
							</button></div>
						<SwipeableList type={ListType.IOS} fullSwipe={false} className="listview image-listview">
							{list.map((item, index) =>
								<SwipeableListItem key={index}
									className={item.status == "Close" ? "status-close" : ""}
									fullSwipe={false}
									leadingActions={leadingActions(favServices.filter(x => x == item.ticketKey).length > 0, item.ticketKey, item)}>
									<ServiceCard item={item} key={item._id} />
								</SwipeableListItem>
							)}
						</SwipeableList>
					</>}
				</>
			}
			{!list.length &&
				<NoRecord title={t('noissues')} contents={t('thereisnoissuehistory')} _sn={_sn} widthsection={true} />
			}
		</>
	);
};

const mapState = (state) => {
	var items = state.ServiceReducer.prdservices;
	var isLoading = state.ServiceReducer.isLoading;

	var favServices = state.FavoriteReducer.service;
	return { items, isLoading, favServices };
};

const mapDispatch = (dispatch) => ({
	GetServiceBySN: (sn) => dispatch(ServiceAction.GetServiceBySN(sn)),
});

export default connect(mapState, mapDispatch)(ProductInfoServices);
