import { JiraConstant } from '../actions/constants/JiraConstant';
const initialState = 
{ 
    issueInfo : {},
    issue : {},
	isLoading: false,
};
export const JiraReducer = (state = initialState, action) => {
    switch (action.type) {
        case JiraConstant.ADD:
            return {...state, 
				issue : action.issue, 
				isLoading : false };
        case JiraConstant.GET:
            return {...state, 
                issueInfo : action.issueInfo,
                isLoading : false };
        default:
            return state
    }
}