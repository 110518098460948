import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SiteAction } from '../../actions/SiteAction';
import { RayForm } from '../../components/common';
import { Translate as t } from '../../config/Translate';

const AdminSiteInfo_Customer = ({ site, UpdateSiteInfo, editable }) => {

    const [errors, setErrors] = useState([]);

    const form_controls = [
        {
            title: "", items: [
                { type: "input", name: "customerFirstName", label: t('firstname'), val: site.customer?.firstname, isEditable: editable },
                { type: "input", name: "customerLastName", label: t('lastname'), val: site.customerLastName, isEditable: editable }
            ]
        },
        {
            title: "", items: [
                { type: "tel", name: "customerHp", label: t('mobile'), val: site.customerHp, isEditable: editable },
                { type: "email", name: "customerEmail", label: t('email'), val: site.customerEmail, isEditable: editable },
            ]
        }
    ]

    return (
        <RayForm
            controls={form_controls}
            action={UpdateSiteInfo}
            actionkey={site._id}
            errors={errors} />
    );
};

const mapDispatch = dispatch => ({
    UpdateSiteInfo: (_id, data) => dispatch(SiteAction.UpdateSiteInfo(_id, data)),
})
export default connect(null, mapDispatch)(AdminSiteInfo_Customer);