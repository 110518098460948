import React, { useState, useEffect } from 'react';
import { ProductPartAction } from '../../actions';
import { connect } from 'react-redux';
import { RayCodeAction } from '../../actions/RayCodeAction';
import { RayDelButton } from '../../components/common/Buttons';
import { FileItem } from '../../components/common/FileUpload';
import moment from 'moment';
import { ModalConfirm } from '../common/ModalConfirm';
import { Translate as t } from '../../config/Translate';

const ServiceReplacementAddList = ({ items, callback, model, clist }) => {
	const [codes, setCodes] = useState([]);
	const [comboParent, setComboParent] = useState([]);
	const [combo, setCombo] = useState([]);
	const [attachList, setSetAttachList] = useState([]);
	const [isAdd, setIsAdd] = useState(false);
	const [trackservices, settrackservices] = useState([]);
	const [item, setItem] = useState({
		title: '',
		subtitle: '',
		other: '',
		oldsn: '',
		newsn: '',
		trackservice: '',
		productextrakey: ''
	});
	const [showModal, setShowModal] = useState(false);
	const [showOther, setShowOther] = useState(false);

	useEffect(() => {
		console.log('ServiceReplacementAddList -> ', clist?.filter(x => x.type === 'TRACKING_PATH'));
		settrackservices([{ code: "", name: "Not assigned" }].concat(clist?.filter(x => x.type === 'TRACKING_PATH')));
		// RayCodeAction.GetAllByFilter({ type: "TRACKING_PATH" }).then(x => {
		// 	settrackservices([{ code: "", name: "Not assigned" }].concat(x));
		// });
	}, [clist])

	useEffect(() => {
		ProductPartAction.GetAllDirect().then(cdList => {
			setCodes(cdList.filter(x => x.models.includes(model)))
		});
	}, [items])

	useEffect(() => {
		console.log(item);
	}, [item]);

	useEffect(() => {
		if (codes.length > 0) {
			SetCodeData();
		}
	}, [codes])

	const SetCodeData = () => {
		var types = [];
		codes.map(x => types.push(x.type));
		const newList = [...new Set(types), 'Cable', 'Other'];
		setComboParent(newList);
		setItem({ ...item, ["title"]: newList[0] });
	}

	useEffect(() => {
		if (!item.title || codes.length == 0)
			return;

		var list = [];
		codes.map(x => {
			if (x.type == item.title)
				list.push(x);

		});

		list.push({ code: 'other', name: 'Other' });

		setCombo(list);

		if (item.title != 'Other' && item.title != 'Cable')
			setItem({ ...item, subtitle: list[0].code, productextrakey: list[0].productextrakey });
	}, [item.title])

	useEffect(() => {
		if (codes.length == 0)
			return;
		setItem({ ...item, subtitle: item.subtitle });
	}, [item.subtitle])

	const changeCombo = (e) => {
		if (!e.target.value) return;

		if (e.target.value != 'other') {
			var rep = {};
			codes.map(x => {
				if (x.type == item.title && x.code == e.target.value)
					rep = x;
			});
			setSetAttachList(rep.guides);
			setItem({ ...item, ["subtitle"]: e.target.value, productextrakey: rep.productextrakey, other: '' });

			setShowOther(false);
		}
		else {
			setSetAttachList([]);
			setItem({ ...item, ["subtitle"]: e.target.value, other: '' });

			setShowOther(true);
		}
	}

	const changeComboParent = (e) => {
		if (e.target.value == 'Other' || e.target.value == 'Cable') {
			setItem({ ...item, ["title"]: e.target.value, subtitle: '', other: '' });
			setShowOther(true);
		} else {
			setItem({ ...item, ["title"]: e.target.value, other: '' });
			setShowOther(false);
		}
	}

	const handleChange = (e) => {
		setItem({ ...item, [e.target.name]: e.target.value });
	}

	const del = (c) => {
		callback && callback(items.filter(x => x._id != c._id));
	}

	const additem = () => {
		callback && callback([...items, { ...item, sendDate: new Date().valueOf() }]);
		setIsAdd(false);
	}

	const getCodeName = (code) => {
		const m = codes.filter(x => x.code == code);
		if (m.length > 0) {
			return m[0].name;
		}
		return code;
	}

	const recv = (x) => {
		x.recvDate = new Date().valueOf();
		items.filter(m => m._id == x._id ? x : m);
		callback && callback(items.filter(m => m._id == x._id ? x : m));
	}

	const winOpen = (x) => {
		var url = "https://t.17track.net/ko#nums=";
		if (x.trackservice) {
			var ts = trackservices.filter(y => y.name == x.trackservice);
			if (ts.length > 0) {
				url = ts[0].code;
			}
		}
		window.open(url + x.trackingno, "_blank");
	}

	const getPart = (x) => {
		return <div>
			<div>
				<div className="chip chip-media me-1 mb-1">
					<i className="chip-icon bg-warning">
						<ion-icon name="build" role="img" className="md hydrated" aria-label="chatbubble"></ion-icon>
					</i>
					<span className="chip-label">{x.title} / {(x.title == 'Other' || x.subtitle == 'other') ? x.other : getCodeName(x.subtitle)}</span>
				</div>
				<div className="float-end">{x.oldsn} <ion-icon name="arrow-forward-outline"></ion-icon> {x.newsn}</div>
			</div>
			<div className="card rounded-0 border-1 border border-dark">
				{x.trackingno && <div className="card-header raycur" onClick={() => winOpen(x)}>
					<ion-icon name="boat-outline"></ion-icon>&nbsp;
					Tracking No. : {x.trackservice && "[ " + x.trackservice + " ] "}{x.trackingno}&nbsp;
					<ion-icon name="open-outline"></ion-icon>
				</div>}
				<div className="card-body">
					<div><ion-icon name="arrow-up-outline"></ion-icon> : {moment(x.sendDate).format("YYYY-MM-DD HH:mm:ss")}</div>
					{x.recvDate > 0 && <div><ion-icon name="arrow-down-outline"></ion-icon> : {moment(x.recvDate).format("YYYY-MM-DD HH:mm:ss")}</div>}
					{x.recvDate === 0 && <button className="btn btn-sm btn-primary" onClick={() => setShowModal(true)}>Received</button>}

					<ModalConfirm info={{
						showModal,
						content: 'Did you receive it?',
						actions: [{
							css: '',
							callback: () => {
								recv(x);
								setShowModal(false)
							}
							, title: 'Recevied'
						}],
						title: 'Recevied',
					}} callbackClose={() => setShowModal(false)} />
				</div>
			</div>
		</div>
	}

	return (
		<>
			{codes.length == 0 && <>{t('notexistpartsforreplacement')}</>}
			{codes.length > 0 &&
				<>
					{!isAdd && <button className="btn btn-block btn-primary" onClick={() => setIsAdd(true)}>{t('addreplacement')}</button>}
					{isAdd && <div className="border-bottom pb-1">
						<div className="form-group basic">
							<div className="input-wrapper">
								<label>Part Category</label>
								<select className="form-control mb-1" value={item.title} onChange={(e) => changeComboParent(e)}>
									{comboParent.map(x => <option value={x}>{x}</option>)}
								</select>
							</div>
						</div>
						{
							(!showOther || item.subtitle == 'other') &&
							<div className="form-group basic">
								<div className="input-wrapper">
									<label>{t('part')}</label>
									<select className="form-control mb-1"
										value={item.subtitle}
										onChange={(e) => changeCombo(e)}>
										{combo.map(x => <option value={x.code}>{x.name}</option>)}
									</select>
								</div>
							</div>
						}
						{
							showOther &&
							<div className="form-group basic">
								<div className="input-wrapper">
									<label>{t('Other')}</label>
									<input name='other' type='text' className="form-control mb-1" value={item.other} onChange={(e) => handleChange(e)} />
								</div>
							</div>
						}

						<div className="form-group basic">
							<div className="input-wrapper">
								<label>{t('originalsn')}</label>
								<input type="text" className="form-control mb-1" placeholder={t('originalsn')}
									name="oldsn" value={item.oldsn} onChange={(e) => handleChange(e)} />
							</div>
						</div>
						<div className="form-group basic">
							<div className="input-wrapper">
								<label>{t('replacesn')}</label>
								<input type="text" className="form-control" placeholder={t('replacesn')}
									name="newsn" value={item.newsn} onChange={(e) => handleChange(e)} />
							</div>
						</div>
						<div className="form-group basic">
							<div className="input-wrapper">
								<label>Track Service</label>
								<select className="form-control mb-1"
									value={item.trackservice}
									onChange={(e) => setItem({ ...item, trackservice: e.target.value })}>
									{trackservices.map(x => <option value={x.code == "" ? x.code : x.name}>{x.name}</option>)}
								</select>
							</div>
						</div>
						{
							item.trackservice != "" &&
							<div className="form-group basic">
								<div className="input-wrapper">
									<label>Tracking No.{item.trackservice}</label>
									<input type="text" className="form-control" placeholder="Tracking No."
										name="trackingno" value={item.trackingno} onChange={(e) => handleChange(e)} />
								</div>
							</div>
						}
						{
							attachList.length > 0 &&
							<div className="section p-0 mt-1">
								<div className="section-title">Manual</div>
								<div className="list-group">
									<div className="list-group-item">
										{attachList.map(x => <FileItem fileInfo={{ s3path: x.path, original: x.name }} showDel={false} />)}
									</div>
								</div>
							</div>
						}
						<button className="btn btn-primary" onClick={() => additem()}>{t('add')}</button>
						<button className="btn btn-cancel" onClick={() => setIsAdd(false)}>{t('cancel')}</button>
					</div>}
					<div>
						{items.map((x, idx) =>
							<ul className="listview simple-listview transparent flush" key={idx}>
								<li className="border-bottom">
									{getPart(x)}
									<RayDelButton callback={(c) => del(c)} item={x} key={idx} css="float-end btn-sm btn btn-danger" />
								</li>
							</ul>
						)}
					</div>
				</>}
		</>
	);
}

const mapState = (state) => {
	const clist = state.RayCodeReducer.items;
	return {  clist };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ServiceReplacementAddList);