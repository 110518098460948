import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RayTable } from '../../components/common';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { useParams } from 'react-router-dom';

const AdminSparepartsDetailOut = ({ outHistory, GetOutHistory }) => {
    const { _id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        GetOutHistory(_id)
    }, []);

    useEffect(() => {
        setData(outHistory.map(x => {
            return {
                type : x?.isDealerOut ? '딜러출고' : '일반출고',
                outDate: x.outDate,
                country: x.country,
                companyName: x.companyName,
                cof: x.cof,
                quantity: x.quantity,                                
            }
        }));
    }, [outHistory]);

    const header = [
        { name: 'type', label: '구분', align: 'text-center' },
        { name: 'outDate', label: '선적일', type: 'DTONLY', align: "text-center" },
        { name: 'country', label: '국가', align: "text-center", type: 'COUNTRY' },
        { name: 'companyName', label: '배송지', align: "text-center" },
        { name: 'cof', label: '유상/무상', type: 'PASSFAIL', align: "text-center" },
        { name: 'quantity', label: '출고수량', align: "text-center" },                
    ];

    return (
        <>            
            <div className='card p-2'>
                <RayTable
                    data={data}
                    columns={header}
                    options={{
                        search: false,
                        keyCol: "code",
                        filterColumns: [
                            { col: "type", val: "ALL" },
                            { col: "country", val: "ALL" },
                            { col: "companyName", val: "ALL" },
                        ],
                        defaultSort: "date",
                        defaultSortType: -1,
                        hideListCount: true
                    }}
                />
            </div>
        </>
    )
}

const mapState = (state) => {
    const outHistory = state.SparePartsReducer.outHistory;
    return { outHistory };
};

const mapDispatch = (dispatch) => ({    
    GetOutHistory: (_id) => dispatch(SparePartsAction.OutHistory(_id)),
});

export default connect(mapState, mapDispatch)(AdminSparepartsDetailOut);
