import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DeviceModels } from '../../config';
import { history } from '../../history';
import { InstallationAction, RayCodeAction, ProductAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { Translate as t } from '../../config/Translate';

const InstallationStep0 = ({ newInst, info, Create, Update, GetProduct, codelist, product, isSummary = false }) => {
	// const [ t, i18n ] = useTranslation();
	const [errors, setErrors] = useState([]);
	const [searchErr, setSearchErr] = useState("");
	const [snStart, setSNSatart] = useState(false);
	const [searchSN, setSearchSN] = useState("");
	const [qrStart, setQRStart] = useState(false);
	const [err, setErr] = useState("");
	const [isAddProcess, setIsAddProcess] = useState(false);
	const [installation, setInstallation] = useState(info);
	const [options, setOptions] = useState([]);
	const [allCodeList, setAllCodeList] = useState([]);

	useEffect(() => {
		console.log(codelist);
		if(codelist?.length > 0){
			setAllCodeList(codelist.filter(x => x.type === 'PRODUCTOPTION'));
		}
		// RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" }).then(x => {
		// 	setAllCodeList([...x]);
		// });
	}, [codelist]);

	useEffect(() => {
		if (isAddProcess) {
			history.push("/Installation/" + newInst._id);
		}
	}, [newInst]);

	useEffect(() => {
		if (info._id) {
			setInstallation({ ...info });
			setSNSatart(true);
		}
	}, [info]);

	useEffect(() => {
		if (product) {
			setProduct(product);
			getOptions(product.model);
		}
	}, [product]);

	const setProduct = (product) => {
		if (product.sn) {
			setInstallation({
				...installation,
				sn: product.sn,
				siteId: product.siteId,
				model: product.model,
				option: product.codes
			});
			setSNSatart(true);
		}
	}

	const handleScan = data => {
		if (data) {
			const arr = data.split('=');
			if (arr[1]) {
				SearchStart(arr[1]);
			}
		}
	}

	const handleError = err => {
		setErr(err);
	}

	const SearchStart = (sn) => {
		ProductAction.GetProductLocal(sn.toUpperCase()).then(x => {
			if (x) {
				setProduct(x);
				getOptions(x.model);
			} else {
				setSearchErr("No records")
			}
		});
	}

	const onKeyPress = (e) => {
		if (e.key == 'Enter') {
			SearchStart(searchSN);
		}
	}

	const getOptions = (model) => {
		var lst = [];
		allCodeList.map(x => {
			if (x.group == model)
				lst.push({ code: x.code, title: x.name });
		});
		if (lst.length > 0) {
			setOptions([...lst]);
		}
	}

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		if (name == "model") {
			getOptions(value);
		}

		// datetime update popup 구현		
		if (name == "option") {
			codelist.filter(x => x.type === 'PRODUCTOPTION' && x.code === value).then(x => {
				setInstallation({ ...installation, optionName: x[0].name, [name]: value });
			});
			// RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION", code: value }).then(x => {
			// 	setInstallation({ ...installation, optionName: x[0].name, [name]: value });
			// });
		}

		setInstallation({ ...installation, [name]: value });
	};

	const form_controls = [
		{
			title: t('productinfo'), items: [
				{
					type: "input", name: "sn", label: t('serialn'),
					extra: product,
					val: installation.sn, isEditable: false
				},
				{ type: "select", name: "model", label: t('model'), val: installation.model, list: DeviceModels, isEditable: false, required: true },
				{ type: "select2", name: "option", label: t('option'), val: installation.option, list: options, isEditable: true, required: true },
			]
		},
		{
			title: t('installdetails'), items: [
				{ type: "datetime", name: "installationDate", label: t('installdate'), onlyDate: true, val: installation.installationDate, isEditable: !isSummary },
				{ type: "input", name: "installerName", label: t('installer'), val: installation.installerName, isEditable: !isSummary },
			]
		},
	];

	const validNext = () => {
		const errValidate = RayFormValidation(form_controls, installation);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsAddProcess(true);
			if (installation._id) {
				Update(installation._id, { ...installation, installationStep: 2 });
			} else {
				Create({ ...installation, installationStep: 2 });
			}
		}
	};

	return (
		<>
			{!snStart &&
				<div className="card mb-2">
					<div className="card-body">
						{qrStart &&
							<>
								<QrReader
									delay={300}
									onError={(err) => handleError(err)}
									onScan={(data) => handleScan(data)}
									style={{ width: '100%' }} />
								{err && <p className="alert">{err}</p>}
								<button className="btn btn-lg btn-outline-primary mb-2 btn-block" onClick={() => setQRStart(false)}>{t('cancel')}</button>
							</>
						}
						<div className="mt-2">
							<input type="input" name="searchSN" className="form-control" onChange={(e) => { setSearchSN(e.target.value); setSearchErr("") }} value={searchSN}
								placeholder={t('enteravalidproductserialnumber')}
								onKeyPress={(e) => onKeyPress(e)}
							/>
							{searchErr && <div className="alert alert-danger mt-1">{searchErr}</div>}
							<button className="btn btn-lg btn-primary btn-block" onClick={() => SearchStart(searchSN)}>{t('search')}</button>
						</div>
						{!qrStart && <button className="btn btn-lg btn-primary btn-block" onClick={() => setQRStart(true)}>{t('qrscan')}</button>}
					</div>
				</div>
			}
			{snStart &&
				<>
					<RayForm controls={form_controls} isSummary={isSummary} handleChangeData={handleChangeData} errors={errors} />
					{!isSummary &&
						<div className="section mt-1 mb-2">
							<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
							<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => setSNSatart(false)}>{t('cancel')}</button>
						</div>
					}
				</>
			}
		</>
	);
};

const mapState = (state) => {
	const newInst = state.InstallationReducer.installation;
	const codelist = state.RayCodeReducer.items;
	return { newInst, codelist };
}

const mapDispatch = (dispatch) => ({
	Create: (data) => dispatch(InstallationAction.CreateInstallation(data)),
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
});

export default connect(mapState, mapDispatch)(InstallationStep0);