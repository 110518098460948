import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RayTable, ErrorCodeListHeader } from '../../components/common';
import { ErrorCodeAction } from '../../actions/ErrorCodeAction';
import { LanguageCode } from '../../config';
import { Translate as t } from '../../config/Translate';

const AdminErrorCodeList = ({ su, GetList, errorCodes }) => {

	const [list, setList] = useState([]);
	const options = {
		search: true,
		keyCol: 'errorCode',
		linkto: '/Admin/AdminErrorCodeInfoById',
		//linkkey: ['errorCode', 'lang'],
		linkkey: ['_id'],
		filterColumns: [
			{ col: "lang", val: "ALL" },
		]
	}

	useEffect(() => {
		GetList();
	}, []);

	useEffect(() => {
		setList(errorCodes.map(x => {
			const lng = LanguageCode.filter(n => n.code == x.lang);
			return {
				...x,
				language: lng.length > 0 ? lng[0].title : x.lang,
				// technotecount: x.technote?.length,
				// modelcnt: x.model.length
			}
		}));
	}, [errorCodes]);

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('errorcodelist')}</h2>
				<div className="ray-right">
					{su && <Link to="/Admin/AdminErrorCodeAdd" className="btn btn-primary btn-sm mt-0">
						<ion-icon name="add-outline"></ion-icon>
						{t('adderrorcode')}
					</Link>}
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section mt-1 mb-1">
					<div className="wide-block pt-2 pb-2">
						<RayTable data={list} columns={ErrorCodeListHeader} options={options} />
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	const su = state.AuthReducer.su;
	var errorCodes = state.ErrorCodeReducer.errorCodes;
	return { su, errorCodes };
};

const mapDispatch = (dispatch) => ({
	GetList: () => dispatch(ErrorCodeAction.GetList()),
});

export default connect(mapState, mapDispatch)(AdminErrorCodeList);
