import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PickImage, UploadImage } from '../../components/common/Camera';
import { InstallationAction, ErrorCodeAction, ProductAction } from '../../actions';
import { FileAddList } from '../../components/common/FileAddList';
import { FileUpload, FileItem } from '../../components/common/FileUpload';
import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const InstallationStep2 = ({ info, Update, isSummary = false, Attachment }) => {
	// const [ t, i18n ] = useTranslation();
	const [installation, setInstallation] = useState(info);
	const [shocks, setShocks] = useState(installation.shocks || []);
	const [tilts, setTilts] = useState(installation.attachments.filter(item => 'tilt' == item.type) || []);

	const [url, setUrl] = useState("");

	useEffect(() => {
		setInstallation(info);		
	}, [info]);

	const Prev = () => {
		Update(installation._id, {
			...installation,
			installationStep: 2
		});
	};

	const validNext = () => {
		Update(installation._id, {
			...installation,
			installationStep: 4,
			shocks: shocks,
			tilts: tilts
		});
	};

	const setNewFile = (file, fileType) => {
		Attachment(installation._id, "ADD", {
			...installation,
			name: file.original,
			path: file.s3path,
			attachmentType: fileType
		});
	};

	const delFile = (c) => {
		Attachment(installation._id, "DEL", {
			...installation,
			delAttachid: c._id
		});
	};

	const getImageFileAttachs = async (item) => {
		var ext = [".JPG", ".PNG", ".JPEG"];
		const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
			path: item,
			ctype: "image/" + item.slice((item.lastIndexOf(".") - 1 >>> 0) + 2)
		}, JWT());
		if (ret.data.status == "success") {
			setUrl(ret.data.data);
		}
	}

	return (
		<>
			<div className={isSummary ? "pb-2" : "section"}>
				{!isSummary && <div className="section-title">{t('packagingcheck')}</div>}
				<div className={isSummary ? "" : "card mb-2"}>
					<div className={isSummary ? "section-title bdB2" : "card-header"}>{t('shockindicator')}</div>					
					{installation.attachments.filter(item => 'shock' == item.type).length > 0 &&
						<ul className="list-group">
							{installation.attachments.map((c, idx) =>
								c.type == "shock" &&
								<li className="list-group-item">
									<FileItem fileInfo={{ ...c, original: c.name, s3path: c.path }} callback={(c) => delFile(c)} key={idx} />
								</li>
							)}
						</ul>
					}
					{installation.attachments.filter(item => 'shock' == item.type).length === 0 && <i className="p-2">Not exist.</i>}
					{!isSummary &&
						<div className="card-footer">
							<FileUpload
								option={{ doneandnew: true, label: t('upload') }}
								extraData={{ path: "installation/" + installation._id + "/" }}
								callback={(e) => setNewFile(e, 'shock')} />
						</div>}
				</div>
				<div className={isSummary ? "" : "card mb-2"}>
					<div className={isSummary ? "section-title bdB2" : "card-header"}>{t('tiltwatch')}</div>
					{installation.attachments.filter(item => 'tilt' == item.type).length > 0 &&
						<ul className="list-group">
							{installation.attachments.map((c, idx) =>
								c.type == "tilt" &&
								<li className="list-group-item">
									<FileItem fileInfo={{ ...c, original: c.name, s3path: c.path }} callback={(c) => delFile(c)} key={idx} />
								</li>
							)}
						</ul>
					}
					{installation.attachments.filter(item => 'tilt' == item.type).length === 0 && <i className="p-2">Not exist.</i>}
					{!isSummary &&
						<div className="card-footer">
							<FileUpload
								option={{ doneandnew: true, label: t('upload') }}
								extraData={{ path: "installation/" + installation._id + "/" }}
								callback={(e) => setNewFile(e, 'tilt')} />
						</div>}
				</div>
			</div>
			{!isSummary && <div className="section mt-1 mb-2">
				<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
				<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
			</div>}
		</>
	);
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(InstallationAction.UpdateInstallation(_id, data)),
	Attachment: (_id, type, data) => dispatch(InstallationAction.Attachment(_id, type, data)),
});

export default connect(null, mapDispatch)(InstallationStep2);