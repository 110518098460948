import React from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../history';
import ServiceCreate from '../../components/service/ServiceCreate';
import ServiceCreateByErrorCode from '../../components/service/ServiceCreateByErrorCode';
import { Translate as t } from '../../config/Translate';

const AdminServiceAdd = (props) => {
	const { refer, item, extraItem } = (props.location && props.location.state) || {};

	const callbackCreated = (service) => {
		history.push('/Admin/AdminService/' + service.ticketKey);
	};

	const getReferExtra = (itemname) => {
		switch (refer) {
			case "PRODUCTERROR":
				if (itemname == "product")
					return extraItem;
			case "PRODUCTNORMAL":
				if (itemname == "product")
					return extraItem;
			case "SITENORMAL":
				if (itemname == "site")
					return extraItem;
		}
		return {};
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('addnewservice')}</h2>
				<div className="ray-right">
					{!refer &&
						<Link to="/Admin/AdminServiceList" className="btn btn-outline-default btn-sm mt-0 me-1">
							List
						</Link>
					}
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{refer && (
					<ServiceCreateByErrorCode
						withBack={true}
						callbackCreated={callbackCreated}
						referErrorCode={item}
						referProduct={getReferExtra("product")}
						referSite={getReferExtra("site")}
					/>
				)}
				{!refer &&
					<ServiceCreate callbackCreated={callbackCreated} withBack={true} />
				}
			</div>
		</>
	);
};

export default AdminServiceAdd;
