import e from 'cors';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CompanyAction } from '../../actions';

const RayTreeviewParent = ({ item, callback }) => {
    const [view, setView] = useState(true);
    const [icon, setIcon] = useState("/assets/img/minus3.gif");
    const toggleShowHide = (e) => {
        setIcon(!view ? "/assets/img/minus3.gif" : "/assets/img/plus3.gif");
        setView(!view);
        callback && callback(item);
    }
    return <img src={icon} onClick={(e) => toggleShowHide(e)} style={{ height: 18, width: 18 }} />;
}

const RayTreeviewItem = ({ item, isParent = false, click, selected }) => {
    return <>
        {!isParent && <img src="/assets/img/line3.gif" style={{ height: 18, width: 18 }} />}
        <span className="cur-pointer" onClick={click}>
            <img src="/assets/img/RayIOTTreeMap.png" />
            <span className={selected == item.id ? "cur-pointer rtvselected" : "cur-pointer"}>
                {item.country && "[" + item.country + "] "}{item.name}
            </span>
        </span>
    </>
}

const RayTreeview = ({ companies, onClickItem, checkbox = false, maxHeight = 600, user }) => {
    const [vHg, setvHg] = useState(0);
    const [selected, setSelected] = useState(0);
    const [list, setList] = useState([]);

    const getHeight = () => setvHg(window.innerHeight);

    useEffect(() => {
        genTreeviewData();
    }, [companies]);

    useEffect(() => {
        //GetCompany();
        window.addEventListener('resize', getHeight);
        setvHg(window.innerHeight);
    }, []);

    const genTreeviewData = () => {
        const treeNode = []
        if (user.companyInfo.org == "HQ") {
            treeNode.push({ id: "0", name: "ALL", parent: "", companyId: "", scp: '' });
        } else {
            const rtUser = JSON.parse(localStorage.getItem("rtUser"));
            treeNode.push({ id: user.companyInfo.companyId, name: user.companyInfo.name, parent: "", companyId: user.companyInfo.companyId, scp: rtUser?.scp });
        }

        companies.map((u) => {
            let scp ='';

            if (u.scp_1 >= 0) scp += u.scp_1 + ':';
            if (u.scp_2 >= 0) scp += u.scp_2 + ':';
            if (u.scp_3 >= 0) scp += u.scp_3 + ':';

            treeNode.push({
                id: u.companyId,
                name: u.name,
                country: u.country,
                parent: u.parentCompanyId || "0",
                scp
            });
        });
        
        setList(treeNode);
    }

    const CreateTree = (data, isSub = false, lev) => {
        let level = lev || 1;
        let children = [];
        (!isSub ? data.filter(d => d.parent == "") : data).map((x,idx) => {
            var sublist = list.filter(c => c.parent == x.id);
            if (sublist.length > 0) {
                children.push(
                    <li key={idx}
                        style={{
                            backgroundImage: "url(/assets/img/line1.gif)",
                            backgroundRepeat: "repeat-y"
                        }}>
                        {<RayTreeviewParent item={x} callback={(item) => toggleShowHide(x.id)} />}
                        <RayTreeviewItem item={x} click={(e) => itemSelected(x)} isParent={true} selected={selected} />
                        <ul id={`tree-child-${x.id}`} style={{ "display": "block", paddingLeft: 18 }}>
                            {CreateTree(sublist, true, level + 1)}
                        </ul>
                    </li>
                );
            } else {
                children.push(
                    <li key={idx}
                        style={{
                            backgroundImage: "url(/assets/img/line1.gif)",
                            backgroundRepeat: "repeat-y"
                        }}>
                        <RayTreeviewItem item={x} click={(e) => itemSelected(x)} selected={selected} />
                    </li>
                );
            }
        })
        return children;
    }

    const toggleShowHide = (id) => {
        var elem = "tree-child-" + id;
        if (document.getElementById(elem).style.display === 'block') {
            document.getElementById(elem).style.display = "none";
        } else {
            document.getElementById(elem).style.display = "block";
        }
    }

    const itemSelected = (item) => {
        setSelected(item.id);        
        onClickItem && onClickItem({ ...item, id: item.id === "0" ? ("0") : item.id });
    }

    const drawTreeView = () => {        
        return list.length > 0 ? CreateTree(list) : "";
    }

    return (
        <div className="card raytreeview p-1" style={{ "maxHeight": vHg - 160, "overflow": "auto" }}>
            <ul>
                {drawTreeView()}
            </ul>
        </div>
    );
}

const mapState = (state) => {
    var user = state.AuthReducer.user;
    var filter = state.AdminUserReducer.filter;
    var companies = state.CompanyReducer.companies;
    return { companies, filter, user };
};

const mapDispatch = (dispatch) => ({
    //GetCompany: () => dispatch(CompanyAction.GetCompany())
});

export default connect(mapState, mapDispatch)(RayTreeview);