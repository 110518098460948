export const AdminUserConstant = {
	GET_USERLIST: 'GET_USERLIST',
	GETUSERINFO_SUCCESS: 'GETUSERINFO_SUCCESS',
	GETUSERINFO_BY_ADMINID: 'GETUSERINFO_BY_ADMINID',
	UPDATE_USERINFO: 'UPDATE_USERINFO',
	REGIST_USERINFO: 'REGIST_USERINFO',
	DELETE_USER: 'DELETE_USER',
	UPDATE_LISTFILTER: 'UPDATE_LISTFILTER',
	CLEAR_UPDATERES: 'CLEAR_UPDATERES',
	LOADING: 'LOADING',
	LOADED: 'LOADED',
};
