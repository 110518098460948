import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../history';
import { Link } from 'react-router-dom';
import ProductPartAdd from '../../components/productpart/ProductPartAdd';
import { Translate as t } from '../../config/Translate';

const AdminProductPartInfo = ({ productpart }) => {

	const callback = () => {
		history.push("/Admin/AdminProductPart");
	}

	return <>
		<div className="extraHeader">
			<h2 className="mb-0">{productpart.code ? productpart.code : t('addsparepart')}</h2>
			<div className="ray-right">
				<Link to="/Admin/AdminProductPart" className="btn btn-default btn-sm mt-0">List</Link>
			</div>
		</div>
		<div id="appCapsule" className="extra-header-active full-height">
			<ProductPartAdd callback={() => callback()} />
		</div>
	</>
};

const mapState = (state) => {
	const productpart = state.ProductPartReducer.item;
	return { productpart };
};
export default connect(mapState)(AdminProductPartInfo);