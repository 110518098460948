import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { CustomFieldConstant } from './constants/CustomFieldConstant';
import { AlertAction } from './AlertAction';

const GetAll = () => async (dispatch) => {
	dispatch({ type: CustomFieldConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/cust', JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: CustomFieldConstant.GET_LIST, items: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: CustomFieldConstant.LOADED });
};

const GetOne = (_id) => async (dispatch) => {
	dispatch({ type: CustomFieldConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/cust/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: CustomFieldConstant.GET, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: CustomFieldConstant.LOADED });
};

const Delete = (_id) => async (dispatch) => {
	dispatch({ type: CustomFieldConstant.LOADING });
	const data = {
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cust/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: CustomFieldConstant.DELETE, _id: _id });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: CustomFieldConstant.LOADED });
};

const Create = (info) => async (dispatch) => {
	dispatch({ type: CustomFieldConstant.LOADING });
	const data = {
		type: 'create',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cust', data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: CustomFieldConstant.CREATE, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: CustomFieldConstant.LOADED });
};

const AddUpdate = (info) => async (dispatch) => {
	dispatch({ type: CustomFieldConstant.ADDUPDATE, item: info });
};

const Update = (_id, info) => async (dispatch) => {
	dispatch({ type: CustomFieldConstant.LOADING });
	const data = {
		type: 'update',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/cust/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Updated!"));
		dispatch({ type: CustomFieldConstant.UPDATE, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: CustomFieldConstant.LOADED });
};

export const CustomFieldAction = {
	GetAll,
	GetOne,
	Create,
	Update,
	Delete,
	AddUpdate
};
