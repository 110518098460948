import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../history';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { AppConfig, JWT } from '../../config';
import { ErrorCodeAction, AlertAction, AuthAction } from '../../actions';
import ErrorCodeLookup from '../../pages/errorcode/ErrorCodeLookup';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { ProductConstant } from '../../actions/constants/ProductConstant';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';

const ProductErrorCard = ({ item, showErrorActionsId, favList, _sn, check = null, checked = false, byService = false }) => {
	// const [t] = useTranslation();
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const dispatch = useDispatch();
	//const [ isSet, setIsSet ] = useState(false);
	const [show, setShow] = useState(false);
	const [lookupCheck, setLookupCheck] = useState(false);
	const [showLookup, setShowLookup] = useState(false);
	const isFav = favList ? favList.includes(item.sn) : false;

	useEffect(() => {
		//if(showErrorActionsId == item.errs._id){
		//	getService();
		//}
	}, [showErrorActionsId]);

	useEffect(() => {
		if (show && !lookupCheck) {
			setLookupCheck(true);
			getErrorCode();
		}
	}, [show]);

	const showDateTime = (r, short = false, css = "") => {
		var rList = [];
		if (!short) {
			rList.push(moment.utc(r).local().fromNow());
			rList.push(<small className={css}>({moment.utc(r).local().format('YYYY-MM-DD HH:mm')})</small>);
		} else {
			rList.push(<small className={css}>{moment.utc(r).local().format('YYYY-MM-DD HH:mm')}</small>);
		}
		return rList;
	}

	const getErrorCode = async () => {
		if (item.model && item.errs.code) {
			const list = await ErrorCodeAction.GetOneOnlyErrorCode(item.model, item.errs.code);
			setShowLookup(list.length > 0);
		}
	}

	const removeArr = (sn) => {
		const idx = favList.indexOf(sn);
		if (idx > -1) {
			favList.splice(idx, 1);
		}
		return favList;
	}

	const SiteInfo = () => {
		history.push("/Site/" + item.siteId);
	}

	const getService = async () => {
		await axios.get(AppConfig.API_SERVER_PATH + '/service/getbyerrorid/' + item.errs._id, JWT()).then((ret) => {
			setIsSet(true)
			if (ret.data.status == "success") {
				if (ret.data.data) {
					setService(ret.data.data);
				} else {
					setService({});
				}
			}
		});
	}

	const CreateService = () => {
		history.push({
			pathname: "/ServiceAddNew",
			state: {
				refer: "PRODUCTERROR",
				item: item.errs,
				extraItem: item,
			},
		});
	}

	const showErrLookup = (eCode) => {
		dispatch({ type: ProgramConstant.ERRORCODELOOKUP_SHOW, eCode })
	}

	const goEdit = () => {
		dispatch({ type: ProductConstant.SET_ERROREDIT, err: item, errshow: true })
	}
	const isDOA = () => {
		return item.installedDate > moment().add(-14, 'days').valueOf() ?
			<ion-icon name="alert" className="text-danger md hydrated" size="small"></ion-icon> :
			"";
	}

	const isOnline = () => {
		return item.isConnected ?
			<ion-icon name="wifi" className="text-primary md hydrated" style={{ pointerEvents: 'none' }}></ion-icon> :
			<ion-icon name="wifi-outline" className="text-muted md hydrated" style={{ pointerEvents: 'none' }} ></ion-icon>;
	}

	const hasService = () => item.errs.serviceKey ? <ion-icon name="build-outline"></ion-icon> : "";

	return (
		<div className="accordion round-box mb-1" style={_sn && { boxShadow: "4px 4px 4px darkgrey" }}>
			<div className="accordion-item">
				{item.errs &&
					<>
						<h2 className="accordion-header">
							<button className="accordion-button">
								<div className="fl">
									<div onClick={() => check(item.errs)}>
										{(check && !checked) && <ion-icon name="ellipse-outline" size="large"></ion-icon>}
										{(check && checked) && <ion-icon name="checkmark-circle-outline" size="large"></ion-icon>}
									</div>
								</div>
								<div className="in fl" onClick={() => setShow(!show)}>
									<div>
										<h3 className="mb-1 mt-1"><div className="in">
											{item.errs.code}{isDOA()}{hasService()}</div>
										</h3>
										{item.sitename && <p>{item.sitename}</p>}
									</div>
								</div>
								<span onClick={() => setShow(!show)} className="float-end badge badge-danger">{showDateTime(item.errs.begin, true, "text-white ps-2 pe-2")}</span>
							</button>
						</h2>
						{show &&
							<div className="accordion-collapse">
								<div className="accordion-body mt-0">
									<hr className="mt-0" />
									<Link to={isAdmin ? "/Admin/AdminProduct/" + item.sn : "/Product/" + item.sn} className="item">
										<div className="in">
											<div className="text-primary">
												<strong>{isOnline()} {item.sn} {item.model && " / " + item.model}</strong>
											</div>
										</div>
									</Link>
									{item.option && <p className="mb-0 mt-1">{t('option')} : {item.option} </p>}
									{item.errs.end && <p>Closed : {showDateTime(item.errs.end, true)}</p>}
									<p className="text-muted">{item.errs.description == item.errs.code ? "" : item.errs.description}</p>
									{!byService && <>
										{(!item.errs.end && !item.errs.serviceKey) && <button className="btn btn-block btn-primary mt-1 mb-1" onClick={() => CreateService()}>{t('createservice')}</button>}
										{item.errs.serviceKey && <Link to={isAdmin ? "/Admin/AdminService/" + item.errs.serviceKey : "/Service/" + item.errs.serviceKey} className="btn btn-block btn-primary mt-1 mb-1" >View Service</Link>}
										{/*<button className="btn btn-block btn-outline-primary mt-0 mb-1" onClick={() => SiteInfo()}>Site Information</button>*/}
										{(!_sn && showLookup) && <button className="btn btn-block btn-primary mt-0 mb-1" onClick={() => showErrLookup(item.errs.code)}>{t('errorcodelookup')}</button>}
										{(!item.errs.end && !item.errs.serviceKey) && <button className="btn btn-block btn-outline-primary mt-0 mb-1" onClick={() => goEdit()}>{t('edit')} / {t('issueclose')}</button>}
									</>}
								</div>
							</div>
						}
					</>
				}
			</div>
		</div>
	)
};

const mapState = (state) => {
	const favList = state.FavoriteReducer.sn;
	const showErrorActionsId = state.ProgramReducer.showErrorActionsId;
	return { favList, showErrorActionsId };
}

const mapDispatch = dispatch => ({
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
})

export default connect(mapState, mapDispatch)(ProductErrorCard);
