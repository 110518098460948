import { connect } from 'react-redux';
import { RayTable, AdminServiceAdminListHeader2, AdminMinServiceAdminListHeader } from '../../components/common';
import { ServiceAction } from '../../actions/ServiceAction';
import React, { useEffect, useState } from 'react';

const ServiceAdminList = ({ warranties, sn, GetServiceAll, services, totalservicecount, filterOptions, filterDateOptions, minServiceList = false }) => {
	const currentWarranty = warranties && Math.max.apply(null, warranties.map(x => x.period));
	const [serviceList, setServiceList] = useState([]);

	useEffect(() => {
		const changedService = [];

		services.forEach(service => {
			changedService.push({
				...service,
				replacements: service.replacements ? service.replacements.map(x => `${x.title} (${x.oldsn} -> ${x.newsn})\n`) : [],
				warrantyInOut: service.createDate > currentWarranty ? 'Out' : 'In'
			});
		});

		setServiceList(changedService);
	}, [services])

	const filterpage = {
		filters: {},
		match: { sn: sn },
		sort: { updateDate: -1 },
		searchKey: "",
		page: 1,
		itemperpage: 30
	};

	const options = {
		search: true,
		keyCol: 'ticketKey',
		defaultSort: Object.keys(filterpage.sort)[0],
		defaultSortType: Object.values(filterpage.sort)[0],
		linkto: '/Admin/AdminService',
		getList: GetServiceAll,
		linkkey: 'summary',
		filterpageoption: filterpage,
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalservicecount,
		filterOptions: filterOptions,
		search: false
	};

	return (
		<div className="section">
			<div className="card">
				<div className="card-body">
					{minServiceList
						? <RayTable
							data={services}
							columns={AdminMinServiceAdminListHeader}
							options={options}
							onlytable={true} />
						: <RayTable
							data={serviceList}
							columns={AdminServiceAdminListHeader2}
							options={options} />
					}
				</div>
			</div>
		</div>
	);
};

const mapState = (state) => {
	var services = state.ServiceReducer.services;
	var totalservicecount = state.ServiceReducer.totalservicecount;
	var filterDateOptions = state.ServiceReducer.filterDateOptions;
	var filterOptions = state.ServiceReducer.filterOptions;
	return { services, totalservicecount, filterOptions, filterDateOptions };
};

const mapDispatch = (dispatch) => ({
	GetServiceAll: (filter) => dispatch(ServiceAction.GetServiceAll(filter)),
});

export default connect(mapState, mapDispatch)(ServiceAdminList);
