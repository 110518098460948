import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ProductAction } from '../../actions';
import ProductInfo from './ProductInfo'
import { ProductComments } from '../../components/product'
import ProductDIM from '../../components/product/ProductDIM';
import ProductDOA from '../../components/product/ProductDOA';
import ProductFavInfo from '../../components/product/ProductFavInfo';
import ProductHistory from '../../components/product/ProductHistory';
import WdigetItemCountCard from '../../widgets/WdigetItemCountCard';
import ServiceAdminList from '../../components/service/ServiceAdminList';
import { Translate as t } from '../../config/Translate';

const ProductInfoSummary = ({ productInfo, user, editable, cancelcallback, codes }) => {
	const [showDIADOA, setshowDIADOA] = useState("COMMENT");
	const [emsVersion, setEMSVersion] = useState('');

	useEffect(() => {
		const ev = productInfo.emsver;
		if (ev)
			setEMSVersion('V' + ev.split('.')[0] + '.' + ev.split('.')[1]);
	}, [productInfo]);

	return <div className="row">
		<div className="col-4 pe-0">
			<ProductInfo productInfo={productInfo} editable={editable} cancelcallback={cancelcallback} />
			<div className="section mt-2 mb-2">
				<div className="card">
					<ul className="nav nav-tabs lined">
						<li className="nav-item">
							<a className={showDIADOA == "COMMENT" ? "nav-link active" : "nav-link"} onClick={() => { setshowDIADOA("COMMENT"); }} >{t('comment')}</a>
						</li>

						{user.adminType == 'S' &&
							<>
								<li className="nav-item">
									<a className={showDIADOA == "DIM" ? "nav-link active" : "nav-link"} onClick={() => { setshowDIADOA("DIM"); }} >DIM</a>
								</li>
								<li className="nav-item">
									<a className={showDIADOA == "DOA" ? "nav-link active" : "nav-link"} onClick={() => { setshowDIADOA("DOA"); }} >DOA</a>
								</li>
							</>
						}
						<li className="nav-item">
							<a className={showDIADOA == "HISTORY" ? "nav-link active" : "nav-link"} onClick={() => { setshowDIADOA("HISTORY"); }} >{t('history')}</a>
						</li>
					</ul>
					{showDIADOA == "COMMENT" && <ProductComments product={productInfo} />}
					{showDIADOA == "DIM" && <ProductDIM product={productInfo} />}
					{showDIADOA == "DOA" && <ProductDOA product={productInfo} />}
					{showDIADOA == "HISTORY" && <ProductHistory product={productInfo} />}
				</div>
			</div>
		</div>
		<div className="col-8 pe-2">
			{!productInfo.orisn && <>
				<div className="row mt-1">
					<div className="col ps-1">
						<WdigetItemCountCard
							title={t('service')}
							subtitle={t('total')}
							aggregate={{
								type: 'service',
								aggregate: [
									{ $match: { 'sn': productInfo.sn } },
									{ $count: 'count' },
								]
							}}
							fontsize="30"
						/>
					</div>
					<div className="col ps-0">
						<div className={"mb-2"}>
							<div className={"card bg-default text-default"}>
								<div className="card-header text-center separator">
									<div className="card-title">
										<div className="text-right"><a className="btn btn-sm mt-0"></a></div>
										<h2 className="mb-0">{t('warranty')}</h2>
										<span className="small mb-0">&nbsp;</span>
									</div>
								</div>
								<div className={"card-body text-default"}>
									<h1 className="text-center" style={{ fontSize: "30px" }}>
										{productInfo.waranties[0] &&
											moment.utc(productInfo.waranties.filter(x => x.dest == "warproduct_product")[0].period).local().format("YYYY-MM-DD")}
										{productInfo.waranties.length === 0 && <div style={{ color : '#8d929e', fontStyle: 'italic' }}>{t('N/A')}</div>}
									</h1>
								</div>
							</div>
						</div>
					</div>
					{codes.find(x => x.group === productInfo.model)?.category === 'Extra-oral Imaging' && <div className="col ps-0">
						<div className={"mb-2"}>
							<div className={"card bg-default text-default"}>
								<div className="card-header text-center separator">
									<div className="card-title">
										<div className="text-right"><a className="btn btn-sm mt-0"></a></div>
										<h2 className="mb-0">{t('emsversion')}</h2>
										<span className="small mb-0">&nbsp;</span>
									</div>
								</div>
								<div className={"card-body text-default"}>
									<h1 className="text-center" style={{ fontSize: "30px" }}>{emsVersion}</h1>
								</div>
							</div>
						</div>
					</div>}
				</div>
				<div className="row mb-2">
					<ServiceAdminList sn={productInfo.sn} minServiceList={true}></ServiceAdminList>
				</div>
			</>}			
			<ProductFavInfo productInfo={productInfo} className="mb-1" />
		</div>
	</div>
};

const mapState = (state) => {
	const prd = state.ProductReducer.product;
	const user = state.AuthReducer.user;
	return { prd, user };
};

const mapDispatch = (dispatch) => ({
	UpdateProductInfo: (_id, data) => dispatch(ProductAction.UpdateProductInfo(_id, data)),
});

export default connect(mapState, mapDispatch)(ProductInfoSummary);
