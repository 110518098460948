import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RayTable, InstallationListHeader } from '../../components/common';
import { InstallationAction } from '../../actions/InstallationAction';
import { CountryCode } from '../../config';
import { Translate as t } from '../../config/Translate';

const AdminInstallationList = ({ user, GetInstallationList, installations, totalInstallationCount, filterOptions, filterDateOptions }) => {
	const [rowSel, setrowSel] = useState({});
	const filterpage = {
		filters: {},
		linkto: '/Admin/AdminInstallationInfo',
		linkkey: 'name',
		filterOptions: ['isSubmit'],
		filterDateOptions: filterDateOptions || [
			{ code: 'createDate', label: 'Registerd' },
		],
		sort: { createDate: -1 },
		searchCols: ['site.sitename', 'sn', 'operator'],
		searchKey: "",
		page: 1,
		itemperpage: user.settings.dashboardtablelistcount || 30
	};

	const getCountryName = (code) => {
		const c = CountryCode.filter(x => x.code == code);
		return c.length > 0 ? c[0].title : code;
	}

	const genData = () => {
		return installations.map((u) => {
			u.siteCountry = getCountryName(u.siteCountry);
			u.customername = u.customerFirstName && u.customerFirstName + ' ' + u.customerLastName;
			u.address = u.siteAddress;
			u.installationStep = u.isSubmit ? 11 : u.installationStep;
			return u;
		});
	}

	const options = {
		search: true,
		keyCol: "_id",
		linkto: '/Admin/AdminInstallationInfo',
		linkkey: 'sn',
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		filterpageoption: filterpage,
		getList: GetInstallationList,
		useapi: true,
		totalitemcount: totalInstallationCount,
		filterOptions: filterOptions,
		isFalse: true,
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{`${t('installation')} ${t('reports')}`}</h2>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className="section mt-1 mb-1">
					<div className="wide-block pt-2 pb-2">
						<RayTable
							data={genData()}
							columns={InstallationListHeader}
							options={options} />
					</div>
				</div>
			</div>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var installations = state.InstallationReducer.installations;
	var totalInstallationCount = state.InstallationReducer.totalInstallationCount;
	var filterOptions = state.InstallationReducer.filterOptions;
	var filterDateOptions = state.InstallationReducer.filterDateOptions;
	return { user, installations, totalInstallationCount, filterOptions, filterDateOptions };
}

const mapDispatch = dispatch => ({
	GetInstallationList: (filter) => dispatch(InstallationAction.GetInstallationList(filter))
})

export default connect(mapState, mapDispatch)(AdminInstallationList)