import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../history';
import { Link } from 'react-router-dom';
import { Translate as t } from '../../config/Translate';

import RayCodeAdd from '../../components/raycode/RayCodeAdd';

const AdminRayCodeInfo = ({ raycode }) => {

	const callback = () => {
		history.push("/Admin/AdminRayCode");
	}

	return <>
		<div className="extraHeader">
			<h2 className="mb-0">{raycode.name ? raycode.name : "Add RayCode"}</h2>
			<div className="ray-right">
				<Link to="/Admin/AdminRayCode" className="btn btn-default btn-sm mt-0">List</Link>
			</div>
		</div>
		<div id="appCapsule" className="extra-header-active full-height">
			<RayCodeAdd callback={() => callback()} />
		</div>
	</>
};

const mapState = (state) => {
	const raycode = state.RayCodeReducer.item;
	return { raycode };
};
export default connect(mapState)(AdminRayCodeInfo);