import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { NotificationAction } from '../../actions/NotificationAction';

const HomeAlaram = ({ list, GetAll, size = "", css = "headerButton", callback = "" }) => {

	const [isAdmin, setIsAdmin] = useState(location.pathname.toLowerCase().startsWith('/admin/'));
	const [hasNew, setHasNew] = useState(false);

	useEffect(() => {
		GetAll();
	}, []);

	useEffect(() => {
		setHasNew(list.filter(x => !x.isRead).length > 0);
	}, [list]);

	const go = () => {
		history.push(isAdmin ? "/Admin/AdminNotification" : "/Notification");
		callback && callback();
	}

	return (
		<a onClick={go} className={css}>
			<ion-icon className="icon md hydrated"
				size={size}
				name="notifications-outline" role="img" aria-label="notifications outline"></ion-icon>
			{hasNew && <span className="badge badge-primary badge-empty"></span>}
		</a>
	)
}

const mapState = (state) => {
	const list = state.NotificationReducer.list;
	return { list };
}

const mapDispatch = (dispatch) => ({
	GetAll: () => dispatch(NotificationAction.GetAll())
});

export default connect(mapState, mapDispatch)(HomeAlaram);