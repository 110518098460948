import React, { useState, useEffect } from 'react';
import moment from 'moment';

export const ServiceJiraComments = ({ issueInfo }) => {

	const getMsg = (msg) => {
		var ret = [];
		msg.map(x => {
			x.content.map(y => {
				console.log(y);
				if(y.type == "text"){
					ret.push(y.text)
				}else if(y.type == "media"){
					ret.push("[ATTACHED MEDIA FILE]")
				}
			})
		})
		return nl2br(ret.join("\n"));
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
			})
		} else {
			return "N/A";
		}
		return html;
	}

	return (
		<>
			{issueInfo.fields.comment.comments.length > 0 &&
				<div>
					<div className="border-top">
						<div className="timeline ms-3">
							{issueInfo.fields.comment.comments.map((c, idx) =>
								<div key={idx} className="item">
									<div className="dot bg-primary"></div>
									<div className="content">
										<div className="title">
											{c.author.displayName} / {moment(c.created).fromNow()}
										</div>
										<h4 className="text">{getMsg(c.body.content)}</h4>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			}
			{issueInfo.fields.comment.comments.length == 0 && <div className="text-muted text-center mt-1 mb-1">No Jira Comments</div>}
		</>
	);
};