import React, { useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';

const ActionSheet = ({ isShow = false, title = "Title", buttons = [], closed }) => {

	const [show, setShow] = useState(isShow);
	const [style, setStyle] = useState({ display : "" });

	const clicked = (x) => { close(); x.callback && x.callback(); }
	const close = () => closed && closed();

	//useEffect(() => {
	//	setStyle(isShow ? { display : "block", background: "#0000004D" } : { display : "", });
	//	setShow(isShow);
	//}, [isShow])

	return (
		<Modal show={show} onHide={closed()}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-sm btn-secondary" onClick={() => close()}>
					Close
				</button>
				<button className="btn btn-sm btn-primary" onClick={() => close()}>
					Save Changes
				</button>
			</Modal.Footer>
		</Modal>
	)
}

export default ActionSheet;