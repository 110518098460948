import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { history } from '../../history';

const HomeCardProducts = ({ user, errCount, docCount,
	homeListTotalPrdCount, homeListTotalActivePrdCount,
	recentInstallCount,
	recentInstalldays, callback }) => {

	const dispatch = useDispatch();
	const beforDays = user.rguardSettingHomeErrorScope || 1;

	// const [t] = useTranslation();
	const [search, setSearch] = useState("");

	const chkform = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (search) {
				dispatch({ type: ProgramConstant.SETSEARCHFILTERS, filters: { search: search, model: "" } })
				history.push("/MySearch");
			}
		}
	}

	const gourl = (url) => {
		history.push(url);
	}

	return (
		<div className="user-card">
			<div className="sch">
				<div className="mt-1 text-muted">{t('calsearchcomment')}</div>
				<div className="mt-1">
					<form className="search-form">
						<div className="form-group searchbox">
							<input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} onKeyPress={(e) => chkform(e)} />
							<i className="input-icon">
								<ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
							</i>
						</div>
					</form>
				</div>
			</div>

			<div className="uc-footer">
				<div className="item">
					<a onClick={() => callback("err")} data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
						<div className="icon-wrapper bg-secondary mb-1">
							<ion-icon name="close-outline" role="img" className="md hydrated" aria-label="close outline"></ion-icon>
							<span className="badge badge-primary">{errCount}</span>
						</div>
						<strong>{t('error')}</strong><p>{beforDays} Days</p>
					</a>
				</div>
				<div className="item">
					<a onClick={() => callback("recentInstall")} data-bs-toggle="modal" data-bs-target="#sendActionSheet">
						<div className="icon-wrapper bg-secondary mb-1">
							<ion-icon name="settings-outline" role="img" className="md hydrated" aria-label="calendar outline"></ion-icon>
							<span className="badge badge-primary">{recentInstallCount}</span>
						</div>
						<strong>{t('newinstalled')}</strong><p>{recentInstalldays} Days</p>
					</a>
				</div>
				{/*
				<div className="item">
					<a href onClick={() => gourl("/MySearch/product")} data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
						<div className="icon-wrapper bg-secondary mb-1">
							<ion-icon name="sync-outline" role="img" className="md hydrated" aria-label="sync outline"></ion-icon>
							<span className="badge badge-primary">{homeListTotalActivePrdCount.toLocaleString()}</span>
						</div>
						<strong>Active</strong>
					</a>
				</div>
				*/}
				<div className="item">
					<a onClick={() => callback("doc")} data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
						<div className="icon-wrapper bg-secondary mb-1">
							<ion-icon name="documents-outline"></ion-icon>
							<span className="badge badge-primary">{docCount.toLocaleString()}</span>
						</div>
						<strong>Documents</strong>
					</a>
				</div>
			</div>
		</div>
	)
}

const mapState = (state) => {
	var user = state.AuthReducer.user;
	const homeListTotalPrdCount = state.ProgramReducer.homeListTotalPrdCount;
	const homeListTotalActivePrdCount = state.ProgramReducer.homeListTotalActivePrdCount;

	return { user, homeListTotalPrdCount, homeListTotalActivePrdCount };
};

export default connect(mapState, null)(HomeCardProducts);