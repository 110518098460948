import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

const HomeCardHello = ({ user, overDue, serviceCount, schedCount, callback }) => {
    // const [t] = useTranslation();
    const beforDays = user.rguardSettingHomeErrorScope || 1;
    const overdueDays = user.rguardSettingHomeOverdueScope || 7;
    const schedScope = user.rguardSettingHomeUpcomingScope || 7;

    useEffect(() => {
    }, []);

    return (
        <div className="user-card">
            <div className="tit">
                <div className="left">
                    <span className="title">Have a nice day<i>!</i></span>
                    <h1 className="username">{user.adminid}
                        {(user.firstName || user.lastName) && " (" + user.firstName + " " + user.lastName + ")"}
                    </h1>
                </div>
                <div className="right">
                    <Link to="/MyInfo"><img src={user.avatar || "/assets/img/avatar_default_none.png"} alt="image" className="imaged w64" /></Link>
                </div>
            </div>
            <div className="uc-footer">
                <div className="item">
                    <a onClick={() => callback("service")} data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                        <div className="icon-wrapper bg-info mb-1">
                            <ion-icon name="receipt-outline" role="img" className="md hydrated" aria-label="receipt outline"></ion-icon>
                            <span className="badge badge-primary">{serviceCount}</span>
                        </div>
                        <strong>{t('service')}</strong><p>{t('total')}</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={() => callback("overdue")} data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                        <div className="icon-wrapper bg-danger mb-1">
                            <ion-icon name="alert-outline" role="img" className="md hydrated" aria-label="close outline"></ion-icon>
                            <span className="badge badge-primary">{overDue}</span>
                        </div>
                        <strong>{t('serviceoverdue')}</strong><p>{overdueDays} Days</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={() => callback("sched")} data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                        <div className="icon-wrapper bg-warning mb-1">
                            <ion-icon name="calendar-outline" role="img" className="md hydrated" aria-label="alert outline"></ion-icon>
                            <span className="badge badge-primary">{schedCount}</span>
                        </div>
                        <strong>Schedule</strong><p>{schedScope / 7} {schedScope / 7 > 1 ? t('weeks') : t('week')}</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

const mapState = (state) => {
    var user = state.AuthReducer.user;
    return { user };
};

const mapDispatch = dispatch => ({
    GetList: (filter, append) => dispatch(ProductAction.GetErrors(filter, append)),
})

export default connect(mapState, mapDispatch)(HomeCardHello);