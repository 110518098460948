import RLib from "./RLib"

const RayFormValidation = (formControls, inputs) => {
    var errs = [];
    formControls.map(x => x.items.map(y => {
        if (y.name.indexOf(".") > -1) {
            var arr = y.name.split(".");
            const child = inputs[arr[0]];
            if (y.required && child[arr[1]] == "") {
                errs.push({ name: y.name, msg: y.label + " is required" });
            } else {

            }
        } else {
            // console.log(inputs[y.name] + " - " + y.required);

            if ((inputs[y.name] == "" || inputs[y.name] == undefined)) {
                if (y.required)
                    errs.push({ name: y.name, msg: y.label + " is required" });
            } else {
                y.val = inputs[y.name];
                const errCheckResult = CheckValidateValue(y);
                if (errCheckResult)
                    errs.push(errCheckResult);
            }
        }
    }));
    return errs;
}
const CheckValidateValue = (obj) => {
    var err = null;
    if (obj.val == "") {
        if (obj.required == true) {
            err = { name: obj.name, msg: obj.label + " is required" };
            return err;
        }
        return null;
    }
    switch (obj.type) {
        case "email":
            if (!RLib.ValidateEmail(obj.val))
                err = { name: obj.name, msg: "Please enter a valid email address" };
            break;
        case "tel":
            if (!RLib.ValidateTel(obj.val))
                err = { name: obj.name, msg: "Please enter a valid " + obj.label };
            break;
        case "password":
            if (!RLib.ValidatePasswordStrength(obj.val))
                err = { name: obj.name, msg: "The length is 8 characters or more and has a combination of symbols, caps, text." };
            break;
    }
    return err;
}
export default RayFormValidation;
export { CheckValidateValue }