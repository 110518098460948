
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { LoginFooter } from '../../components/common/CommonViews';
import { history } from '../../history';
import { AlertAction, AuthAction } from '../../actions';

const ForgotPassword = ({ AlertError }) => {
    const [email, setEmail] = useState("");
    const [errMsg, seterrMsg] = useState("");

    const handleEmailChange = (e) => {
        seterrMsg("")
        setEmail(e.target.value);
    }

    const handleSendEmailClick = () => {        
        AuthAction.RequestForgotPassword({ email }).then((ret) => {
            if (ret.status == "success") {
                history.push("/ForgotPasswordSent");
            }
            else {
                AlertError(ret.errors);
                if (ret.errors) {
                    if (ret.errors.type == "invalidEmail")
                        seterrMsg("Email does not exist!")
                }
            }
        });
    }

    return (
        <div className="login">
            <div className="appHeader no-border transparent position-absolute">
                <div className="left">
                    <Link to="/Login" className="headerButton goBack">
                        <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
                    </Link>
                </div>
            </div>
            <div id="appCapsule" className="no-bottom">
                <div className="section mt-5 text-center">
                    <h1>Forgot Password</h1>
                    <p>Please enter your email address and <br /> we’ll send you a link to get back into your account.</p>
                </div>
                <div className="section mb-5 p-2">
                    <form>
                        <div className="card">
                            <div className="card-body pb-1">
                                <div className="form-group basic mb-2">
                                    <div className="input-wrapper">
                                        <label className="label">Email</label>
                                        <input type="email" className="form-control empty"
                                            value={email}
                                            name="email" onChange={handleEmailChange} />
                                    </div>
                                    {errMsg && <div className="input-info">{errMsg}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 transparent mb-5">
                            <button type="button"
                                onClick={handleSendEmailClick}
                                className="btn btn-primary btn-block waves-effect waves-classic">Send Login Link</button>
                        </div>
                    </form>
                </div>
                <LoginFooter />
            </div>
        </div>
    )
}

const mapDispatch = dispatch => ({
    AlertError: message => dispatch(AlertAction.Error(message)),
})

export default connect(null, mapDispatch)(ForgotPassword);