import React from 'react';
import { Modal } from 'react-bootstrap';
import { Translate as t } from '../../config/Translate';

export const ModalConfirm = ({ info, callbackClose, isActionSheet = false }) => {
	return (
		<Modal show={info.showModal} onHide={() => callbackClose()} className={isActionSheet ? "action-sheet" : ""} >
			<Modal.Header>
				<h2 className="modal-title">{info.title}</h2>
			</Modal.Header>
			<Modal.Body className={isActionSheet ? "p-2" : ""}>
				{info.content}
			</Modal.Body>
			<Modal.Footer>
				{info.actions && info.actions.map(x => {
					return <button
						className={x.css || "btn btn-block btn-success"}
						onClick={() => x.callback()}>
						{x.title}
					</button>
				})}
				<button className="btn btn-block btn-dark" onClick={() => callbackClose()}>{t('close')}</button>
			</Modal.Footer>
		</Modal>
	);
}