import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { InputBasicText, InputSelect2Search } from '../../components/common/Inputs';
import { AppConfig, JWT } from '../../config';
import { history } from '../../history';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AdminSparePartsOrderFree = ({ sparePartsList, groupList, GetSparePartsList, GetGroupList, SubmitOrder }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const initialData = {
        companyId: '',
        companyName: '',        
        orderList: [],
        address: '',
        tel: '',
        email: '',
        orderer: '',
        type: 'FREE'
    }
    
    const [data, setData] = useState(initialData);
    const [order, setOrder] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [receiveInfo, setReceiveInfo] = useState({});
    const [modelList, setModelList] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');

    useEffect(() => {
        if (sparePartsList.length == 0)
            GetSparePartsList();

        if (groupList.length == 0)
            GetGroupList();        
    }, []);

    useEffect(() => {
        setModelList([...new Set(sparePartsList.map(x => x.model).flat())]);
     }, [sparePartsList]);

    useEffect(() => {
        const userCompanyId = user.companyId;
        const filtered = groupList.filter(x => x.company.includes(userCompanyId));

        if (filtered.length > 0)
            setData({
                ...data,
                companyId: user.companyId,
                companyName: user.companyInfo.name,
                address: filtered[0].address,
                tel: filtered[0].tel,
                email: user.email,
                orderer: user.adminid
            });
    }, [groupList]);

    useEffect(() => {
        console.log('order================================');
        console.log(order);
    }, [order]);

    useEffect(() => {
        if (editMode)
            setReceiveInfo({ address: data.address, tel: data.tel });
    }, [editMode]);

    const selectParts = async (e) => {
        const selectedParts = sparePartsList.filter(x => x.serviceCode === e.target.value)[0];

        const _id = selectedParts?._id;

        const seq = order.length === 0 ? 1 : (Math.max.apply(Math, order.map(x => {
            return x.seq
        })) || 0) + 1;

        const picture = selectedParts?.picture;
        const serviceCode = selectedParts.serviceCode;
        const servicePartName = selectedParts.servicePartName;
        const price = selectedParts.price?.usd || 1;

        const quantity = 1;
        const amount = price * quantity;

        setOrder([...order, { _id, seq, picture, serviceCode, servicePartName, price, quantity, amount, memo: '' }]);
    }

    const changeQuantity = (e, seq) => {
        if (!isNaN(e.target.value)) {
            setOrder(order.map(x => {
                if (x.seq === seq)
                    return { ...x, quantity: e.target.value, amount: x.price * e.target.value };
                else
                    return x;
            }));
        }
    }

    const setMemo = (e, seq) => {
        setOrder(order.map(x => {
            if (x.seq === seq)
                return { ...x, memo: e.target.value };
            else
                return x;
        }));
    }

    const removeParts = (seq) => {
        setOrder(order.filter(x => x.seq != seq));      
    }

    const onChangeReceiveInfo = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const cancelEdit = () => {
        setData({ ...data, address: receiveInfo.address, tel: receiveInfo.tel });
        setEditMode(false);
    }

    const orderSubmit = () => {
        const submitData = ({
            ...data, orderList: order.map(x => {
                return {
                    partsId: x._id,
                    serviceCode: x.serviceCode,
                    servicePartName: x.servicePartName,
                    price: x.price,
                    quantity: x.quantity,
                    memo: x.memo
                }
            })
        });
        SubmitOrder(submitData);
        history.push('/Admin/AdminSparePartsOrderList');
    }

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">Spare Parts Order (Free)</h2> 
                <div className="ray-right">
                    <Link to={"/Admin/AdminSparePartsCompany"} className="btn btn-primary btn-sm">Back</Link>
                </div>              
            </div>
            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section mt-1 mb-1">
                    <div className='row p-1'>
                        <div className='col-8 m-0'>
                            <div className='card p-3'>
                                <div className='pb-1'>
                                    <InputSelect2Search
                                        options={{
                                            list: modelList.map(x => { return { code: x, title: x } }),
                                            isMulti: false,
                                            label: 'Model'
                                        }}
                                        editable={true}
                                        onchange={(e) => setSelectedModel(e.target.value)}
                                    />
                                </div>

                                <div className='pb-1'>
                                    <InputSelect2Search
                                        options={{
                                            list: !selectedModel ? sparePartsList.map(x => { return { code: x.serviceCode, title: x.servicePartName } }) : sparePartsList.filter(x => x.model.includes(selectedModel)).map(x => { return { code: x.serviceCode, title: x.servicePartName } }),
                                            isMulti: false,
                                            label: 'Spare parts'
                                        }}
                                        editable={true}
                                        onchange={selectParts}                                        
                                    />
                                </div>
                                
                                <table className='sp-order-table'>
                                    <thead>
                                        <tr>
                                            <td className='text-center'>IMAGE</td>                                            
                                            <td className='text-center'>SERVICE CODE</td>
                                            <td className='text-center'>SERVICE PARTS NAME</td>
                                            <td className='text-center'>Q'TY</td>
                                            <td className='text-center'>MEMO</td>
                                            <td className='text-center'>-</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            order.length === 0 &&
                                            <tr>
                                                <td colSpan={7}><i>No Parts Selected.</i></td>
                                            </tr>
                                        }

                                        {
                                            order.length > 0 &&
                                            order.map((x, idx) => {
                                                return (
                                                    <tr key={idx}>    
                                                        <td>
                                                            {<PartsImage s3path={x?.picture?.path} />} 
                                                        </td>
                                                        <td>{x.serviceCode}</td>
                                                        <td>{x.servicePartName}</td>
                                                        <td>
                                                            <a onClick={() => {
                                                                if (x.quantity - 1 >= 1) {
                                                                    setOrder(order.map(y => {
                                                                        if (y.seq == x.seq)
                                                                            return { ...y, quantity: parseInt(y.quantity - 1), amount: parseInt(y.price) * (parseInt(y.quantity) - 1) };
                                                                        else
                                                                            return y;
                                                                    }));
                                                                }
                                                            }} style={{ cursor: 'pointer' }}>
                                                                <ion-icon name="remove-circle-outline" style={{ fontSize: '23px', verticalAlign: 'middle', marginRight: '2px' }}></ion-icon>
                                                            </a>
                                                            
                                                            <input type='text' value={x.quantity} onChange={(e) => changeQuantity(e, x.seq)} style={{ width: '60px', textAlign: 'center' }} />

                                                            <a onClick={()=>{
                                                                setOrder(order.map(y => {
                                                                    if (y.seq == x.seq)
                                                                        return { ...y, quantity: parseInt(y.quantity) + 1, amount: parseInt(y.price) * (parseInt(y.quantity) + 1) };
                                                                    else
                                                                        return y;
                                                                }));
                                                            }} style={{ cursor: 'pointer' }}>
                                                            <ion-icon name="add-circle-outline" style={{ fontSize: '23px', verticalAlign: 'middle', marginLeft: '2px' }}></ion-icon>
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <input type="text" onChange={(e) => setMemo(e, x.seq)} />
                                                        </td>
                                                        <td>
                                                            <a onClick={() => removeParts(x.seq)} style={{ cursor: 'pointer' }}>
                                                                <ion-icon name="remove-circle" style={{ fontSize: '25px', color: 'red', cursor: 'pointer' }} ></ion-icon>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                                }
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='col-4 m-0'>
                            <div className='card p-2 mb-2'>
                                <div>
                                    <div className='d-inline-block align-top h5' style={{ width:'calc(100% - 200px)', height: '40px', lineHeight: '40px', fontWeight: 'bold' }}>Receive Information</div>
                                    <div className='d-inline-block' style={{ width: '200px', textAlign: 'right' }}>
                                        {!editMode && <button className='btn btn-primary btn-sm align-top' onClick={() => setEditMode(true)}>Edit</button>}
                                        {
                                            editMode &&
                                            <>
                                                <button className='btn btn-primary btn-sm mx-1' onClick={() => setEditMode(false)}>OK</button>
                                                <button className='btn btn-secondary btn-sm' onClick={() => cancelEdit()}>CANCEL</button>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label">Company</label>
                                        <div className="form-content multi">{user.companyInfo.name}</div>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <InputBasicText options={{ name: 'address', label: 'Address', val: data.address }} editable={editMode} onchange={onChangeReceiveInfo} />
                                </div>

                                <div className="form-group basic">
                                    <InputBasicText options={{ name: 'tel', label: 'Tel', val: data.tel }} editable={editMode} onchange={onChangeReceiveInfo} />
                                </div>
                            </div>
                            <button type='button' className='btn btn-primary btn-block' onClick={() => orderSubmit()}>SUBMIT</button>
                        </div>                      
                    </div>
                </div>
            </div>
        </>
    )
}

const PartsImage = ({ s3path }) => {
    const [imageURL, setImageURL] = useState('');

    useEffect(() => {
        getUrl();
    }, [s3path])

    const getUrl = async () => {
        if (s3path) {
            const ret = await axios.post(AppConfig.API_SERVER_PATH + "/file/geturl", {
                path: s3path,
                ctype: "image/" + s3path.slice((s3path.lastIndexOf(".") - 1 >>> 0) + 2)
            }, JWT());

            if (ret.data.status == "success")
                setImageURL(ret.data.data);
        }
    }

    return (
        <div>
            {imageURL && <a href={imageURL} target='_blank'><img src={imageURL} /></a>}
        </div>
    )
}

const mapState = (state) => {
    const sparePartsList = state.SparePartsReducer.spareParts;
    const groupList = state.SparePartsReducer.groupList;
    return { sparePartsList, groupList };
};

const mapDispatch = (dispatch) => ({
    GetSparePartsList: () => dispatch(SparePartsAction.GetList({})),
    GetGroupList: () => dispatch(SparePartsAction.GetGroupList()),
    SubmitOrder: (data) => dispatch(SparePartsAction.SubmitOrder(data))
});

export default connect(mapState, mapDispatch)(AdminSparePartsOrderFree);