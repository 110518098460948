import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { SiteSurveyAction, ErrorCodeAction, ProductAction } from '../../actions';
import { AppHeader } from '../../components/common/LeftMenu';
import { SiteSurveyConstant } from '../../actions/constants/SiteSurveyConstant';
import { useTranslation } from 'react-i18next';
import SurveyStep0 from '../../components/sitesurvey/SurveyStep0';
import SurveyStep1 from '../../components/sitesurvey/SurveyStep1';
import SurveyStep2 from '../../components/sitesurvey/SurveyStep2';
import SurveyStep3 from '../../components/sitesurvey/SurveyStep3';
import SurveyStep4 from '../../components/sitesurvey/SurveyStep4';
import SurveyStep5 from '../../components/sitesurvey/SurveyStep5';
import { Translate as t } from '../../config/Translate';

const SiteSurvey = ({ Get, Update, siteSurvey }) => {

	const dispatch = useDispatch();
	// const [t, i18n] = useTranslation();
	const { _id, view } = useParams();
	const [step, setStep] = useState(1);
	const [gostep, setGoStep] = useState({
		curStep: 100,
		goStepNo: 0
	});

	useEffect(() => {
		if (_id) {
			Get(_id);
		} else {
			dispatch({ type: SiteSurveyConstant.NEW });
		}
	}, [])

	useEffect(() => {
		setStep(siteSurvey.siteSurveyStep);
		setGoStep({
			curStep: 100,
			goStepNo: 0
		});
	}, [siteSurvey])

	const getStepTitle = (index) => {
		return index;
	}

	const getHeaderStep = () => {
		var rows = [];
		for (var i = 1; i <= 6; i++) {
			if (step == i) {
				rows.push(<a className="badge badge-primary me-05" href onClick={(e) => goStep(e.target.title, '')} title={i}>{' '}</a>);
			} else {
				rows.push(<a className="badge badge-secondary me-05" href onClick={(e) => goStep(e.target.title, '')} title={i}>{' '}</a>);
			}
		}
		return rows;
	}

	const goStep = (num, addminus) => {
		//Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep : num + addminus });
		if (num != 6) {
			setGoStep({
				curStep: step,
				goStepNo: num
			});
		}
	}

	return (
		<>
			<div className="appHeader no-border bg-white">
				<div className="left">
					<a href onClick={() => history.goBack()} className="headerButton goBack">
						<ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
					</a>
					<div className="pageTitle">
						{t('sitesurvey')}
						{(siteSurvey._id && siteSurvey.siteSitename) && " : " + siteSurvey.siteSitename}
					</div>
				</div>
				<div className="right">
					{/*
					<a href onClick={(e) => setSearchView(!searchView)} className="headerButton">
						<ion-icon name="search-outline"></ion-icon>
					</a>
					*/}
				</div>
			</div>
			<div className="extraHeader full-width step-title">
				{siteSurvey._id && <div className="section-title fl">
					{(view != "view" && step > 1) && <a onClick={() => goStep(step, -1)} className="text-primary btn-sm"><ion-icon name="caret-back-outline" role="img" className="md hydrated" aria-label="caret back outline"></ion-icon></a>}
				</div>}
				<div className="text-center step-badge">
					<h3 style={{ marginBottom: "5px" }}>Step {step}</h3>
					{getHeaderStep()}
				</div>
				{siteSurvey._id && <div className="section-title fr">
					{(view != "view" && step < 6) && <a onClick={() => goStep(step, 1)} className="text-secondary btn-sm"><ion-icon name="caret-forward-outline" role="img" className="md hydrated" aria-label="caret forward outline"></ion-icon></a>}
				</div>}
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{view == "view" && <SurveyStep4 info={siteSurvey} view="view" />}
				{view != "view" &&
					<>
						{step === 1 && <SurveyStep0 info={siteSurvey} gostep={gostep.curStep == 1 && gostep} />}
						{step === 2 && <SurveyStep1 info={siteSurvey} gostep={gostep.curStep == 2 && gostep} />}
						{step === 3 && <SurveyStep2 info={siteSurvey} gostep={gostep.curStep == 3 && gostep} />}
						{step === 4 && <SurveyStep3 info={siteSurvey} gostep={gostep.curStep == 4 && gostep} />}
						{step === 5 && <SurveyStep4 info={siteSurvey} gostep={gostep.curStep == 5 && gostep} />}
						{step === 6 && <SurveyStep5 info={siteSurvey} gostep={gostep.curStep == 6 && gostep} />}
					</>
				}
			</div>
		</>
	);
};

const mapState = (state) => {
	const siteSurvey = state.SiteSurveyReducer.siteSurvey;
	return { siteSurvey };
}

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(SiteSurveyAction.UpdateSiteSurvey(_id, data)),
	Create: (data) => dispatch(SiteSurveyAction.CreateSiteSurvey(data)),
	Get: (_id) => dispatch(SiteSurveyAction.GetSiteSurvey(_id)),
});

export default connect(mapState, mapDispatch)(SiteSurvey);