import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { AlertAction } from './AlertAction';

const GetList = async (model, contentsType, lang) => {
	const data = {
		model : model,
		contentsType : contentsType,
		lang : lang
	}
    const ret = await axios.post(AppConfig.API_SERVER_PATH + '/guides', data, JWT());
    if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return AlertAction.Error(ret.data);
	}
}

export const GuideAction = {
    GetList
};