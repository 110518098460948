import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthAction } from '../../actions/AuthAction';
import { RayTable } from '../../components/common';
import { connect } from 'react-redux';
import { CompanyAction, ProductAction, RayCodeAction } from '../../actions';
import moment from 'moment';
import CompanyModalAlias from '../../components/company/CompanyModalAlias';
import { Translate as t } from '../../config/Translate';

const AdminProductImport = ({ BulkUpdateEachProductInfo, products, companies, clist }) => {

	const [isLoading, setIsLoading] = useState(false);
	const [view, setView] = useState("raw");
	const [rawData, setRawData] = useState("");
	const [list, setList] = useState([]);
	const [forms, setForms] = useState([]);
	const [col, setCols] = useState([]);
	const [codeList, setCodeList] = useState([]);
	const [subtitle, setSubTitle] = useState("");
	const [isProcess, setisProcess] = useState(false);
	const [item, setItem] = useState({});
	const [appliable, setAppliable] = useState(false);
	const [reason, setReason] = useState("");
	const [times, setTimes] = useState([]);
	const [showModal, setShowModal] = useState(false);

	const updateColumnName = "S/N";
	const updateColumnNameShip = "SN";

	const options = {
		filterColumns: []
	}

	useEffect(() => {
		//GetCompany();
		setCodes();
	}, [clist]);

	useEffect(() => {
		if (isProcess) {
			setView("done");
		}
	}, [products])

	useEffect(() => {
		if (codeList.length > 0)
			setTimes(codeList.filter(x => x.datatype == "DATETIME").map(y => y.code));
	}, [codeList])


	const setCodes = () => {
		console.log('AdminProductImport -> ', clist?.filter(x => x.type === 'PRODUCTEXTRAKEY'));
		//var list = await RayCodeAction.GetAllByFilter({ type: "PRODUCTEXTRAKEY" });
		var list = clist?.filter(x => x.type === 'PRODUCTEXTRAKEY');
		list.push({ code: 'installedDate', name: "Installeddate", alias: ["설치일"] });
		list.push({ code: "shipmentdate", name: "Shipmentdate", alias: ["선적일"] });
		setCodeList(list);
	}

	const process = () => {
		setisProcess(true);
		setView("process");
		var plist = [...list];
		plist.map(x => {
			times.map(t => {
				if (x.hasOwnProperty(t)) {
					x[t] = moment(x[t]).valueOf()
				}
			})
			return x;
		})

		plist = makeDataforUpdate(plist);
		BulkUpdateEachProductInfo(plist);
	}

	const makeDataforUpdate = (plist) => {
		var tmpList = [];
		plist.map(x => {
			var obj = {};
			obj["sn"] = x.sn;
			if (x.hasOwnProperty("shipmentdate"))
				obj["shipmentdate"] = moment(x.shipmentdate).valueOf();
			if (x.hasOwnProperty("installedDate"))
				obj["installedDate"] = moment(x.installedDate).valueOf();
			if (x.hasOwnProperty("companyId"))
				obj["companyId"] = x.companyId;
			obj["extradata"] = x;
			tmpList.push(obj);
		})
		return tmpList;
	}

	const goPreview = () => {
		setView("convert");
		var lines = rawData.split('\n');
		if (lines.length > 0) {
			if (lines[0].split('\t').length > 40) {
				setSubTitle("Manufacture");
				manParser();
			} else {
				setSubTitle("Shipment");
				shipParser();
			}
		}
	}

	const setObject = (header, type) => {
		var columnHeaders = [];

		header.split('\t').map((x, idx) => {
			if (x.trim().toUpperCase() == (type == "MAN" ? updateColumnName : updateColumnNameShip)) {
				columnHeaders.push({
					code: (type == "MAN" ? updateColumnName : updateColumnNameShip),
					idx: idx,
				})
			} else {
				var n = codeList.filter(y =>
					y.name.toUpperCase() == x.trim().toUpperCase() ||
					y.alias.map(m => m.toUpperCase()).includes(x.trim().toUpperCase()));
				if (n.length > 0) {
					columnHeaders.push({
						code: n[0].code,
						idx: idx,
					})
				}
			}
		})
		return columnHeaders;
	}

	const setLine = (headers, item, type) => {
		var tp = (type == "MAN" ? updateColumnName : updateColumnNameShip)
		var obj = {};
		var arr = item.split('\t');
		headers.map((x, idx) => {
			var n = arr.filter((y, hidx) => x.idx == hidx);
			if (n.length > 0) {
				if (arr[x.idx]) {
					if (x.code == tp) {
						if (arr[x.idx] != "")
							obj[x.code == tp ? "sn" : x.code] = arr[x.idx];
					} else {
						obj[x.code == tp ? "sn" : x.code] = arr[x.idx];
					}
				}
			}
		});
		return obj;
	}

	const manParser = () => {
		var nlist = []
		var lines = rawData.split('\n');

		const headers = setObject(lines[0], "MAN");

		const obj = {};

		headers.forEach(x => obj[x.code === "S/N" ? "sn" : x.code] = "");

		setCols(makeCol(obj));

		lines.map((x, idx) => {
			if (idx > 0 && x.trim() != "")
				nlist.push(setLine(headers, x, "MAN"))
		});
		setAppliable(true);
		setList(nlist);
	}

	const shipParser = () => {
		var nlist = []
		var lines = rawData.split('\n');
		var headers = setObject(lines[0], "SHIP");
		lines.map((x, idx) => {
			if (idx > 0 && x.trim() != "") {
				var prd = setLine(headers, x, "SHIP");
				prd = searchCompanyId(prd);
				if (prd.sn != "") {
					var sns = prd.sn.split(",")
					sns.map(y => {
						if (y) {
							var nitem = JSON.parse(JSON.stringify(prd));
							nitem["sn"] = y.trim();
							nlist.push(nitem)
						}
					})
				}
			}
		});
		setList(nlist);
		setAppliable(nlist.filter(x => x.companyId == "").length == 0);
		setReason(nlist.filter(x => x.companyId == "").length == 0 ? "" : "Company Name not assigned")
		if (nlist.length > 0)
			setCols(makeCol(nlist[0]));
	}

	const searchCompanyId = (x) => {
		x["companyId"] = "";
		x["companyName"] = "";
		var com = companies.filter(y => y.name?.trim().toUpperCase() == x["dealer"]?.trim().toUpperCase());

		if (com.length > 0) {
			x["companyId"] = com[0].companyId;
			x["companyName"] = com[0].name;
		} else {
			var comAlias = companies.filter(y => {
				if (y.alias) {
					if (y.alias.includes(x.dealer.trim().toUpperCase()))
						return y
				}
			});
			if (comAlias.length > 0) {
				x["companyId"] = comAlias[0].companyId;
				x["companyName"] = comAlias[0].name;
			}
		}

		return x;
	}

	var titleCase = s => s
		.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
		.replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);

	const makeCol = (item) => {
		var nlist = [];
		Object.keys(item).map(y => {
			var keyname = y;
			if (typeof item[y] === "object") {
				Object.keys(item[y]).map(n => {
					nlist.push({ name: y + "." + n, label: titleCase(y) + " " + titleCase(n), type: times.includes(y + "." + n) ? "DT" : "" });
				})
			} else {
				if (keyname == "companyId") {
					nlist.push({
						name: keyname, 
						// label: titleCase(keyname),
						label: codeList.find(x => x.code === keyname)?.name,
						options: {
							title: "Assign",
							paramType: 'callback'
						},
						type: "BUTTON"
					});
				} else {
					nlist.push({
						name: keyname,
						// label: titleCase(keyname),
						label: keyname === "sn" ? "S/N" : codeList.find(x => x.code === keyname)?.name,
						type: times.includes(keyname) ? "DT" : ""
					});
				}
			}
		});

		return nlist;
	};

	const callBackData = (data) => {
		setItem(data);
		setShowModal(true);
	}

	const callBackUpdateAlias = (item) => {
		setShowModal(false);
		shipParser();
	}

	return (
		<>
			{isLoading && <div className="">Loading...</div>}
			{!isLoading &&
				<>
					<div className="extraHeader">
						<h2 className="mb-0">{t('importdevices')}</h2>
						<div className="ray-right">
							<Link to="/Admin/AdminProductImportSmall" className="btn btn-default btn-sm mt-0 me-1" >소장비</Link>
							<Link to="/Admin/AdminProductList" className="btn btn-default btn-sm mt-0 me-1" >List</Link>
						</div>
					</div>
					<div id="appCapsule" className="extra-header-active full-height">
						{view == "raw" && <>
							<div className="section mt-1 mb-1">
								<div className="section-title">
									{t('writerawdata')}
									<div className="float-end">
										{rawData != "" && <button
											onClick={() => goPreview()}
											className="btn btn-primary">Convert</button>}
									</div>
								</div>
								<div>
									<textarea className="form-control" rows="20"
										autoComplete="off" style={{ height: 400 }}
										defaultValue={rawData}
										onChange={(e) => setRawData(e.target.value)}></textarea>
								</div>
							</div>
						</>}
						{view == "convert" && <>
							<div className="section mt-1 mb-1">
								<div className="section-title">
									Preview Data - {subtitle}{reason && <> <span className="alert alert-warning">{reason}</span></>}
									<div className="float-end">
										<button className="btn btn-secondary me-1" onClick={() => setView("raw")}>{t('cancel')}</button>
										<button className={appliable ? "btn btn-primary" : "btn btn-primary disabled"} onClick={() => process()}>{t('apply')}</button>
									</div>
									<div className="clearfix" />
								</div>
							</div>
							<div className="card section mt-1 mb-1 p-3">
								<RayTable
									css="small table table-bordered mb-1"
									calllback={(data) => callBackData(data)}
									onlytable={true}
									data={list}
									columns={col}
									options={options} />
							</div>
						</>}
						{view == "process" && <>
							<div className="section mt-1 mb-1">
								<div className="card">
									<div className="card-body text-center" style={{ padding: 60 }}>
										<h3>Please wait a minute...</h3>
									</div>
								</div>
							</div>
						</>}
						{view == "done" && <>
							<div className="section mt-1 mb-1">
								<div className="section mt-1 mb-1">
									<div className="card">
										<div className="card-body text-center" style={{ padding: 60 }}>
											<h3>All updates are complete!</h3>
											<Link to="/Admin/AdminProductList" className="btn btn-primary">Product List</Link>
										</div>
									</div>
								</div>
							</div>
						</>}
					</div>
					<CompanyModalAlias
						info={item}
						showModal={showModal}
						companies={companies}
						callbackClose={(e) => {
							callBackUpdateAlias(e)
						}} />
				</>
			}
		</>
	);
};

const mapState = (state) => {
	return {
		companies: state.CompanyReducer.companies,
		codelist: state.RayCodeReducer.items,
		products: state.ProductReducer.products,
		clist : state.RayCodeReducer.items
	};
};

const mapDispatch = (dispatch) => ({
	BulkUpdateEachProductInfo: (list) => dispatch(ProductAction.BulkUpdateEachProductInfo(list)),
	//GetCompany: () => dispatch(CompanyAction.GetCompany())
});

export default connect(mapState, mapDispatch)(AdminProductImport);
