import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ServiceAction, AdminUserAction, CustomFieldAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';
import { Translate as t } from '../../config/Translate';

const CustomFieldValues = ({ customs, model, data, update }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [controls, setContols] = useState([]);
	const [vals, setVals] = useState({});
	const [cnt, setCnt] = useState(0);
	const [editable, setEditable] = useState(false);

	useEffect(() => {
		setVals(data)
	}, [data]);

	useEffect(() => {
		var cf = [];
		customs.filter(m => m.group.includes(model)).map(x => {
			if (x.valuetype == "FIXLIST") {
				var mList = [{ code: "", title: "Not Assigned" }];
				x.listvals.map(l => mList.push({ code: l, title: l }));
				cf.push({ type: getType(x.valuetype), label: x.title, name: x.code, val: vals[x.code] || "", isEditable: editable, list: mList });
			} else if (x.valuetype == "BOOLEAN") {
				cf.push({
					type: getType(x.valuetype), label: x.title, name: x.code,
					val: (vals[x.code] === true || vals[x.code] === false) ? vals[x.code] : "",
					isEditable: editable
				});
			} else {
				cf.push({ type: getType(x.valuetype), label: x.title, name: x.code, val: vals[x.code] || "", isEditable: editable });
			}
		});
		setCnt(cf.length);
		setContols([{ title: "", items: cf }]);
	}, [customs, editable, vals]);

	const getType = (type) => {
		switch (type) {
			case "STRING": return "input";
			case "FIXLIST": return "select2";
			case "NUMBER": return "number";
			case "DATETIME": return "datetime";
			case "BOOLEAN": return "checkbox";
			case "DTONLY": return "dtonly";
		}
	}

	const handleUpdateData = (e) => {
		const { name, value } = e.target;
		update(name, value);
	}

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		var tt = customs.filter(x => x.code == name);
		console.log(tt);
		if (tt[0].valuetype == "BOOLEAN")
			update(name, value);
	}

	return <div className="card mb-1">
		<h3 className="card-header">
			{t('customfield')}
			<div className="float-end pb-0" style={{ margin: -4 }}>
				{cnt > 0 && <button className='btn btn-sm mt-0 small'
					onClick={() => setEditable(!editable)}>
					{editable ? t('complete') : t('edit')}
				</button>}
			</div>
		</h3>
		{cnt > 0 && <RayForm
			controls={controls}
			showAllFields={true}
			nocard={true}
			handleChangeData={handleChangeData}
			handleUpdateData={handleUpdateData} />}
		{cnt == 0 && <div className="card-body text-center">{t('nocustomfield')}</div>}
	</div>
};

const mapState = (state) => {
	const customs = state.CustomFieldReducer.items;
	return { customs };
}

const mapDispatch = dispatch => ({
	//GetAll: () => dispatch(CustomFieldAction.GetAll())
	//UpdateStatus : (_id, data) => dispatch(ServiceAction.UpdateStatus(_id, data)),
})

export default connect(mapState, mapDispatch)(CustomFieldValues);