import { RayForm } from "../../components/common";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../history';
import { SparePartsAction } from '../../actions/SparePartsAction';
import { useEffect, useState } from "react";
import moment from "moment";

const AdminSparePartsOutDetail = ({ contents, GetOutContentsById, OutContentsUpdate }) => {
    const { _id } = useParams();
    const [data, setData] = useState({});
    const [outInfo, setOutInfo] = useState({ outType: '', chargeOrFree: '' });

    useEffect(() => {
        GetOutContentsById(_id);
    }, []);

    useEffect(() => {
        if (contents.dealerOut) {
            setOutInfo({ outType: '딜러출고', chargeOrFree: contents.dealerOut?.chargeOrFree });
            setData({
                ...contents,
                orderDate: contents.dealerOut?.orderDate || 0,                
                piPublishTime: contents.dealerOut?.piPublishTime || 0,
            });
        } else {
            const orderId = contents?.orderId;
            if (orderId) {
                SparePartsAction.GetOneOrderByIdLocal(orderId).then(ret => {
                    if (ret.data.status === 'success') {
                        if (ret.data.data.length > 0) {
                            let chargeOrFree = '';

                            if (ret.data?.data[0]?.type === "CHARGE")
                                chargeOrFree = "유상";

                            if (ret.data?.data[0]?.type === "FREE")
                                chargeOrFree = "무상";

                            setOutInfo({ outType: '일반출고', chargeOrFree });
                            setData({
                                ...contents,
                                orderDate: ret.data?.data[0]?.orderDate || 0,                                
                                piPublishTime: ret.data?.data[0]?.piPublishTime || 0
                            });
                        }
                    }
                });
            }
        }
    }, [contents]);

    useEffect(() => {
        const result = SparePartsAction.CalculateSparePartsTimes(data.orderDate, data.piPublishTime, data.shipmentDate);
        setData({
            ...data,
            partsSecuringTime: result.hTime ? result.hTime + '시간' : 0,
            partsProcureDuration: result.jTime ? result.jTime + '일' : 0,
            partsProcureTime: result.cTime || 0
        });
    }, [data.orderDate, data.shipmentDate, data.piPublishTime])

    useEffect(() => { 
        console.log('data=======================');
        console.log(data);
    }, [data]);

    const handleChangeData = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const save = () => {
        OutContentsUpdate(data);
        alert('저장되었습니다.');
    }

    const swList = [
        { code: '', title: '' },
        { code: '박태영', title: '박태영' },
        { code: '김주영', title: '김주영' },
        { code: '유태선', title: '유태선' },
        { code: '원선우', title: '원선우' },
        { code: '정보상', title: '정보상' },
        { code: '정현석', title: '정현석' },
        { code: '백미영', title: '백미영' },
    ];

    const faList = [
        { code: '', title: '' },
        { code: 'TNT', title: 'TNT' },
        { code: 'DHL', title: 'DHL' },
        { code: 'Fedex', title: 'Fedex' },
        { code: 'UPS', title: 'UPS' },
        { code: 'ETC', title: 'ETC' },
    ];

    const basic_controls = [
        { title: "기본정보", items: [{ type: "input", name: "country", label: "국가", isEditable: false, val: contents.country }] },
        { title: "", items: [{ type: "input", name: "companyName", label: "업체", isEditable: false, val: contents.companyName }] },
        { title: "", items: [{ type: "input", name: "address", label: "배송지 주소", isEditable: false, val: contents.address }] },
        { title: "", items: [{ type: "input", name: "outType", label: "출고구분", isEditable: false, val: outInfo.outType }] },
        { title: "", items: [{ type: "input", name: "chargeOrFree", label: "유상/무상", isEditable: false, val: outInfo.chargeOrFree }] },
    ];

    const product_controls = [
        { title: "장비정보", items: [{ type: "input", name: "productSN", label: "SN", isEditable: false, val: contents.productSN }] },
        { title: "", items: [{ type: "input", name: "model", label: "모델", isEditable: false, val: contents.model }] },
        { title: "", items: [{ type: "input", name: "option", label: "옵션", isEditable: false, val: contents.option }] },
        { title: "", items: [{ type: "input", name: "installedDate", label: "설치일", isEditable: false, val: contents.installedDate && moment(contents.installedDate).format("YYYY-MM-DD") }] },
    ];

    // 딜러출고 주문일, PI Publish Date
    // 무상주문 PI Publish Date
    const release_controls = [
        { title: "불출정보", items: [{ type: "datetime", name: "orderDate", onlyDate: true, label: "주문일", val: data.orderDate, isEditable: (outInfo.outType === '딜러출고' ? true : false) }] },
        { title: "", items: [{ type: "datetime", name: "receiveDate1", onlyDate: true, label: "접수일(무상,고불처)", val: data.receiveDate1, isEditable: true }] },
        { title: "", items: [{ type: "datetime", name: "receiveDate2", onlyDate: true, label: "접수일(자재출고)", val: data.receiveDate2, isEditable: true }] },
        { title: "", items: [{ type: "datetime", name: "shipmentDate", onlyDate: true, label: "선적일", val: data.shipmentDate, isEditable: true }] },        
        { title: "", items: [{ type: "select", name: "shipmentWorker", list: swList, label: "선적작업자", isEditable: true, val: data.shipmentWorker }] },
        { title: "", items: [{ type: "datetime", name: "piPublishTime", onlyDate: true, label: "PI발행시간", val: data.piPublishTime, isEditable: ((outInfo.outType === '딜러출고' || outInfo.chargeOrFree == "무상") ? true : false) }] },        

        { title: "", items: [{ type: "input", name: "partsSecuringTime", label: "자재확보시간", val: data.partsSecuringTime, isEditable: false }] },
        { title: "", items: [{ type: "input", name: "partsProcureTime", label: "자재조달시간", val: data.partsProcureDuration, isEditable: false }] },
        { title: "", items: [{ type: "input", name: "partsProcureDuration", label: "자재출하기간분류", val: data.partsProcureTime, isEditable: false }] },
        { title: "", items: [{ type: "input", name: "partsProcureDelayReason", label: "자재조달지연사유", val: data.partsProcureDelayReason, isEditable: true }] },
        { title: "", items: [{ type: "select", name: "forwardingAgent", list: faList, label: "운송사", isEditable: true, val: data.forwardingAgent }] },
        {
            title: "",
            items: [
                {
                    type: "input",
                    name: "invoiceNumber",
                    label: "송장번호",
                    isEditable: true,
                    val: data.invoiceNumber,
                    directLink:
                        ((data.forwardingAgent === 'TNT' && `http://www.tnt.com/webtracker/tracking.do?searchType=CON&cons=${data.invoiceNumber}`) ||
                            (data.forwardingAgent === 'DHL' && `http://www.dhl.com/en/express/tracking.html?AWB=${data.invoiceNumber}&brand=DHL`) ||
                            (data.forwardingAgent === 'Fedex' && `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${data.invoiceNumber}`) ||
                            (data.forwardingAgent === 'UPS' && `http://www.ups.com/WebTracking/track?InquiryNumber1=${data.invoiceNumber}`)) || ''
                }
            ]
        },
        { title: "", items: [{ type: "input", name: "workingDepartment", label: "불출구분", isEditable: true, val: data.workingDepartment }] },
        { title: "", items: [{ type: "input", name: "ccrNumber", label: "고불처번호", isEditable: true, val: data.ccrNumber }] },
        { title: "", items: [{ type: "input", name: "draftNumber", label: "기안번호", isEditable: true, val: data.draftNumber }] },
    ];

    return (
        <>
            <div className="extraHeader">
                <h2 className="mb-0">출고정보</h2>
                <div className="ray-right">
                    <button className="btn btn-sm btn-primary" onClick={() => {
                        OutContentsUpdate(data);
                        history.push({ pathname: '/Admin/AdminSparePartsCIPublish', state: { ...data, title: (outInfo.chargeOrFree === '유상' ? 'COMMERCIAL ' : '') + 'INVOICE' } })
                    }}>CI발행</button>&nbsp;
                    <button className="btn btn-sm btn-primary" onClick={() => save()}>저장</button>&nbsp;
                    <button className="btn btn-sm btn-primary" onClick={() => history.push('/Admin/AdminSparePartsOutList')}>목록</button>
                </div>
            </div>
            <div id="appCapsule" className="extra-header-active">
                <div className="row m-1 p-0">
                    <div className="col-4 m-0 p-1">
                        <RayForm controls={basic_controls} isForm={false} />
                        <RayForm controls={product_controls} isForm={false} />
                    </div>

                    <div className="col-5">
                        <div className="rayform section mt-1">
                            <div className="section-title">파트정보</div>
                            <div className="card">
                                <div className="card-body pb-1 pt-1">
                                    <table className="table table-bordered mt-1 mb-1">
                                        <thead>
                                            <tr>
                                                <th>서비스코드</th>
                                                <th>서비스파트명</th>
                                                <th>수량</th>
                                                <th>SN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                contents.partsList?.map(x => {
                                                    return (
                                                        <tr>
                                                            <td>{x.serviceCode}</td>
                                                            <td>{x.servicePartName}</td>
                                                            <td>{x.quantity}</td>
                                                            <td>{x.sn}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-3">
                        <RayForm controls={release_controls} isForm={false} handleChangeData={(e) => handleChangeData(e)} />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapState = (state) => {
    const contents = state.SparePartsReducer.outContents;
    return { contents };
};

const mapDispatch = (dispatch) => ({
    GetOutContentsById: (_id) => dispatch(SparePartsAction.GetOutContentsById(_id)),
    OutContentsUpdate: (data) => dispatch(SparePartsAction.OutContentsUpdate(data))
});

export default connect(mapState, mapDispatch)(AdminSparePartsOutDetail);
